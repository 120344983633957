import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Link, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sx, ...other }, ref) => {
    const theme = useTheme();

    const PRIMARY_MAIN = theme.palette.primary.main;

    // OR using local (public folder)
    // -------------------------------------------------------
    // const logo = (
    //   <Box
    //     component="img"
    //     src="/logo/logo_single.svg" => your path
    //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
    //   />
    // );

    const logo = (
      <Box
        ref={ref}
        component="div"
        sx={{
          width: 60,
          height: 60,
          display: 'inline-flex',
          ...sx,
        }}
        {...other}
      >
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
             width="100%" height="100%" viewBox="0 0 3400.000000 3176.000000"
             preserveAspectRatio="xMidYMid meet">

          <g transform="translate(0.000000,3176.000000) scale(0.100000,-0.100000)"
             fill={PRIMARY_MAIN} stroke="none">
            <path d="M0 15880 l0 -15880 17000 0 17000 0 0 15880 0 15880 -17000 0 -17000
0 0 -15880z m12256 13864 c-3 -8 1 -11 10 -7 7 3 14 9 14 13 0 12 288 11 295
-1 4 -5 12 -9 18 -9 9 0 9 2 -1 8 -6 5 19 8 56 8 74 -1 71 1 63 -58 -1 -4 -9
-5 -18 -1 -19 7 -18 5 11 -17 18 -13 19 -13 11 0 -5 9 0 8 13 -4 17 -14 22
-16 22 -5 0 11 2 11 10 -1 7 -11 10 -12 10 -2 0 10 4 10 16 -2 9 -9 14 -28 12
-43 -3 -26 -1 -27 40 -26 41 1 54 -8 30 -23 -6 -4 -8 -3 -5 3 3 5 -8 9 -26 8
-18 -1 -37 3 -44 7 -23 18 -22 -10 2 -32 14 -13 25 -20 25 -15 0 5 13 -12 29
-37 16 -25 35 -49 43 -54 11 -6 10 -5 -1 9 -7 9 -9 17 -3 17 5 0 15 -7 23 -16
10 -13 10 -17 -2 -25 -9 -6 -10 -9 -2 -9 7 0 24 -17 38 -37 20 -31 32 -38 58
-38 30 0 32 -2 32 -36 0 -26 8 -44 30 -67 16 -17 32 -29 37 -26 12 8 9 24 -4
24 -7 0 -21 7 -30 14 -15 11 -14 13 16 12 28 -1 32 -4 31 -27 -1 -14 -5 -31
-10 -39 -5 -9 -4 -11 3 -6 7 4 12 3 12 -3 0 -5 12 -8 28 -5 19 3 23 2 13 -5
-20 -13 -22 -38 -5 -52 12 -9 18 -9 27 0 9 10 8 11 -5 6 -16 -6 -16 -5 -2 24
9 17 17 31 18 31 1 0 2 -20 1 -45 -1 -26 2 -42 8 -38 5 3 7 0 5 -8 -3 -8 4
-24 15 -36 18 -21 21 -21 32 -6 8 11 14 13 19 5 4 -7 2 -12 -3 -12 -6 0 -11
-5 -11 -11 0 -5 5 -7 10 -4 6 3 10 2 10 -3 0 -6 8 -8 19 -5 10 3 22 -1 27 -8
5 -9 2 -11 -8 -6 -9 3 -24 -1 -35 -9 -13 -10 -14 -14 -3 -12 8 2 14 -3 12 -10
-1 -8 1 -11 6 -8 5 3 18 -12 30 -33 23 -41 52 -70 52 -53 0 6 -6 13 -12 15
-10 4 -10 6 0 6 7 1 19 -14 27 -33 8 -19 20 -38 26 -42 8 -4 9 -3 5 4 -4 7 -2
12 5 12 6 0 9 -8 6 -20 -3 -10 -2 -19 2 -18 23 3 32 -4 27 -20 -7 -22 27 -58
45 -46 7 4 10 3 6 -4 -9 -13 2 -44 13 -37 5 3 11 -4 15 -14 3 -11 20 -32 36
-46 17 -14 27 -25 23 -25 -4 0 1 -9 11 -20 14 -15 21 -17 32 -9 7 7 16 9 20 5
4 -3 -1 -12 -10 -19 -15 -11 -14 -13 1 -27 14 -13 15 -12 9 5 -6 18 -5 18 5 4
7 -9 19 -14 26 -12 6 3 12 -2 12 -11 0 -10 -6 -13 -17 -10 -17 5 -17 5 -1 -9
10 -8 14 -18 10 -22 -4 -5 -2 -5 4 -2 7 4 17 0 24 -8 7 -8 9 -15 5 -15 -4 0
-2 -7 5 -15 7 -8 17 -12 22 -9 5 4 4 13 -4 22 -8 10 -13 27 -12 39 0 13 -3 20
-9 16 -5 -3 -8 0 -5 7 9 28 24 19 26 -17 3 -46 6 -48 45 -48 l32 0 0 -61 c0
-59 17 -100 35 -89 5 3 13 -9 19 -25 11 -33 16 -36 27 -18 4 7 3 8 -5 4 -6 -4
-11 -4 -10 1 1 4 -1 16 -4 26 -4 9 -9 24 -12 32 -2 9 -15 20 -27 25 -22 8 -22
9 -2 9 11 1 25 -4 32 -11 7 -7 12 -8 12 -2 0 5 -5 15 -11 21 -9 9 -7 12 7 11
21 -2 28 -38 10 -50 -7 -5 -5 -8 4 -8 10 0 11 -3 3 -8 -15 -10 4 -37 35 -46
18 -6 22 -4 22 13 0 22 14 28 25 11 10 -17 -37 -50 -53 -38 -7 6 -16 7 -20 3
-4 -4 -1 -10 6 -12 6 -3 12 -11 12 -19 0 -17 17 -19 23 -1 2 6 8 8 13 4 5 -5
2 -14 -8 -21 -13 -10 -15 -15 -6 -24 17 -17 33 -15 19 2 -8 10 -8 16 2 25 11
9 14 6 12 -17 -3 -41 12 -63 40 -58 26 5 37 -11 39 -55 1 -14 10 -35 21 -46
18 -19 22 -20 39 -7 11 8 15 14 9 14 -7 0 -15 -5 -19 -11 -4 -8 -9 -7 -15 2
-13 20 -11 35 3 21 7 -7 17 -8 28 -2 15 8 17 4 16 -35 0 -24 -3 -39 -7 -33 -4
7 -5 16 -3 20 3 4 0 8 -5 8 -23 0 1 -45 32 -60 20 -10 21 -10 4 3 -11 9 -14
18 -8 21 5 4 17 -4 25 -16 21 -29 20 -39 -1 -31 -15 6 -16 5 -3 -9 11 -11 17
-12 22 -4 7 10 12 2 9 -16 0 -4 19 -6 44 -3 27 3 47 0 51 -7 5 -7 2 -9 -9 -5
-11 4 -14 2 -10 -8 3 -8 9 -12 15 -9 5 3 9 -2 8 -12 -4 -36 0 -46 15 -40 16 6
21 -9 5 -19 -5 -3 -18 3 -30 15 -24 24 -25 21 -5 -16 8 -16 15 -23 15 -16 0 7
5 10 10 7 6 -4 17 -1 25 5 18 15 18 14 6 -27 -7 -24 -6 -35 5 -44 12 -10 15
-10 12 2 -2 8 3 13 10 11 9 -1 11 2 6 10 -6 10 -4 10 9 0 9 -8 20 -11 25 -8 5
3 20 -6 32 -19 13 -14 19 -25 13 -25 -6 0 -13 5 -15 11 -6 18 -65 21 -72 3 -3
-8 -2 -13 3 -10 4 3 14 -5 20 -18 14 -26 47 -29 61 -6 6 10 8 0 6 -33 -3 -53
8 -65 55 -62 24 2 26 -1 25 -37 -1 -21 2 -38 6 -38 4 0 7 -4 5 -8 -3 -12 105
-132 114 -126 12 7 11 34 -1 34 -5 0 -7 -8 -3 -17 5 -15 3 -15 -14 2 -11 11
-16 23 -11 26 7 5 49 5 67 0 2 -1 -3 -9 -10 -18 -10 -11 -10 -14 -1 -9 6 4 12
2 12 -3 0 -6 -5 -11 -12 -11 -6 0 -4 -8 6 -19 10 -11 27 -18 38 -16 11 3 18 2
14 -2 -3 -3 -1 -12 5 -19 14 -18 -8 -29 -27 -13 -8 6 -17 9 -21 5 -3 -3 -1 -6
6 -6 7 0 10 -4 7 -9 -3 -4 4 -17 17 -27 21 -19 21 -19 7 0 -14 18 -13 19 2 13
11 -4 21 0 26 10 8 13 10 11 10 -10 0 -15 -4 -25 -8 -22 -3 2 -7 -2 -7 -10 0
-8 4 -12 9 -9 4 3 8 1 8 -3 0 -5 7 -18 15 -29 14 -17 14 -16 1 13 -9 17 -13
34 -8 36 18 9 66 1 70 -13 5 -13 3 -13 -11 -1 -23 18 -54 7 -37 -14 7 -8 10
-24 7 -35 -3 -12 1 -24 11 -30 10 -5 13 -10 7 -10 -15 0 54 -60 70 -60 8 0 19
8 24 18 9 14 10 15 11 1 0 -9 -9 -20 -21 -25 -20 -9 -20 -10 -3 -24 10 -8 15
-10 11 -3 -4 6 -3 14 2 18 6 3 8 -5 5 -20 -5 -26 6 -36 49 -40 36 -4 37 -5 37
-16 0 -5 -4 -8 -9 -5 -5 4 -11 3 -14 -1 -2 -5 -2 -2 -1 5 4 13 -8 16 -19 5
-11 -10 13 -32 28 -26 10 3 13 -1 10 -16 -2 -12 0 -21 6 -21 5 0 8 -3 6 -8 -1
-4 3 -8 8 -10 6 -2 13 -12 17 -23 4 -10 13 -19 20 -19 9 0 9 3 -2 10 -13 9 -7
14 13 11 4 0 7 4 7 10 0 6 -7 9 -15 6 -8 -4 -17 -2 -20 4 -4 5 -11 6 -17 3 -6
-4 -8 -2 -4 4 3 6 19 10 34 9 31 -3 42 -37 21 -63 -10 -12 -9 -18 4 -32 12
-12 20 -13 28 -5 8 8 6 11 -6 10 -10 -1 -20 5 -23 13 -4 12 -2 13 9 4 8 -7 28
-11 46 -9 29 2 31 1 29 -29 -2 -17 1 -37 7 -44 7 -9 5 -11 -9 -6 -10 4 -16 4
-12 0 3 -4 12 -8 20 -10 7 -1 19 -5 26 -10 11 -6 10 -10 -3 -20 -14 -10 -9
-19 44 -69 33 -31 56 -57 53 -57 -13 -1 33 -24 52 -27 24 -4 22 -2 30 -33 l7
-25 -17 23 c-9 12 -21 22 -27 22 -6 0 3 -15 20 -32 16 -18 39 -48 49 -66 10
-17 26 -35 36 -38 10 -3 15 -10 12 -16 -4 -6 -3 -9 2 -6 5 3 15 0 22 -8 7 -8
9 -14 5 -14 -4 0 0 -5 10 -11 9 -6 14 -15 11 -21 -4 -7 -2 -8 4 -4 7 4 12 2
12 -3 0 -6 5 -11 10 -11 7 0 7 6 1 18 -8 16 -6 18 12 17 19 -2 22 -7 22 -49 0
-40 4 -50 25 -61 14 -8 21 -14 17 -15 -4 0 4 -12 18 -27 22 -24 28 -26 45 -15
14 9 20 9 20 1 0 -8 6 -7 17 2 10 8 14 9 10 2 -4 -6 -3 -14 2 -17 11 -7 11
-58 0 -72 -5 -7 1 -20 17 -35 18 -17 23 -19 18 -6 -3 9 -10 17 -14 17 -5 0 -6
8 -4 18 7 23 63 27 71 6 3 -9 0 -20 -8 -25 -12 -7 -12 -10 -1 -18 8 -4 14 -12
15 -17 1 -5 10 -8 20 -7 10 2 16 -1 13 -6 -3 -4 5 -13 17 -20 19 -10 22 -20
21 -67 -1 -59 4 -66 55 -69 22 -1 26 -6 27 -32 0 -18 -3 -30 -8 -27 -5 3 -5
10 -2 16 4 7 2 8 -5 4 -6 -4 -11 -4 -10 1 3 20 -2 29 -20 29 -11 0 -17 -4 -15
-8 3 -4 12 -8 20 -8 8 0 13 -4 9 -9 -3 -6 8 -20 24 -32 17 -12 27 -27 24 -32
-3 -6 -1 -7 5 -3 5 3 13 0 16 -6 4 -7 5 -4 3 7 -3 15 2 20 19 21 20 2 23 -3
22 -33 -1 -18 -4 -29 -8 -22 -4 6 -5 21 -3 33 4 18 3 18 -9 -4 -13 -22 -12
-26 4 -38 10 -7 16 -17 13 -22 -3 -4 1 -12 8 -18 14 -11 48 -12 41 -1 -2 5
-11 6 -18 3 -8 -3 -17 -1 -20 4 -3 6 1 12 9 16 8 3 17 1 21 -5 4 -7 10 -6 19
1 17 14 32 -7 17 -22 -8 -8 -6 -18 5 -34 8 -13 11 -24 5 -24 -5 0 -12 9 -15
20 -3 11 -10 20 -16 20 -8 0 -9 -6 -1 -19 15 -28 27 -33 49 -22 15 8 20 7 25
-5 3 -8 2 -12 -3 -9 -22 13 -18 -14 4 -35 14 -13 25 -19 25 -13 0 6 -7 14 -16
17 -8 3 -12 11 -8 17 4 8 9 7 15 -2 6 -10 9 -10 9 -2 0 6 -5 15 -10 18 -6 4
-8 11 -5 16 12 18 22 8 20 -21 -2 -41 7 -52 46 -54 22 -1 34 -7 35 -16 1 -9
-1 -10 -3 -2 -8 22 -20 12 -15 -13 2 -14 8 -25 13 -25 5 0 8 -6 6 -12 -1 -8
11 -13 37 -13 26 0 41 -5 44 -15 4 -12 2 -13 -9 -2 -7 6 -20 12 -28 12 -10 0
-5 -7 11 -18 21 -12 26 -22 21 -37 -7 -22 -7 -30 1 -60 2 -11 5 -23 5 -27 1
-5 8 -8 16 -8 8 0 15 -7 15 -16 0 -8 5 -12 10 -9 6 4 8 10 5 15 -3 4 3 7 12 6
20 -3 27 -42 10 -53 -5 -3 1 -14 14 -25 13 -11 17 -16 9 -12 -8 4 -11 3 -7 -1
12 -14 36 -14 31 0 -3 8 3 12 18 11 19 -1 23 -7 24 -33 1 -18 -3 -30 -7 -28
-4 3 -6 17 -3 30 3 15 0 25 -7 25 -7 0 -9 -3 -6 -7 4 -3 2 -12 -3 -19 -13 -15
34 -56 56 -47 8 3 14 0 14 -7 0 -6 -4 -9 -9 -6 -15 10 -7 -19 14 -46 11 -14
26 -34 33 -44 7 -11 11 -14 8 -9 -3 6 4 17 15 23 18 12 19 11 11 -5 -5 -10 -7
-24 -4 -32 3 -8 0 -11 -7 -7 -17 11 -13 0 6 -15 14 -12 16 -12 9 0 -6 9 -5 12
5 9 8 -3 13 -12 12 -20 -4 -21 63 -83 72 -67 5 8 4 10 -3 5 -19 -12 -14 6 9
30 21 22 21 23 2 21 -11 0 -20 -8 -19 -15 1 -8 -4 -17 -10 -19 -7 -2 -11 4
-10 14 0 11 -5 18 -16 17 -11 -1 -14 4 -11 13 3 8 0 19 -7 23 -19 12 -21 37
-4 51 11 9 14 8 15 -2 7 -72 8 -73 52 -74 26 0 33 -5 35 -23 2 -13 -3 -31 -10
-40 -8 -9 -13 -23 -12 -29 1 -7 2 -18 3 -23 1 -7 7 -6 15 2 8 7 13 18 13 23
-3 17 66 12 71 -5 4 -12 2 -13 -9 -2 -7 6 -20 12 -28 12 -10 0 -6 -7 11 -17
21 -13 26 -23 23 -51 -3 -47 9 -88 26 -84 8 2 11 0 7 -3 -12 -13 11 -45 27
-38 13 5 13 7 -2 18 -10 8 -15 17 -10 21 4 4 7 2 7 -4 0 -6 6 -9 14 -6 15 6
16 -12 2 -34 -11 -18 7 -34 38 -34 14 0 23 -4 20 -9 -3 -5 -1 -9 5 -9 5 0 8
-9 6 -20 -2 -12 2 -23 10 -27 8 -3 13 -9 10 -13 -3 -5 6 -7 20 -4 14 2 25 0
25 -6 0 -6 -4 -9 -8 -6 -5 3 -9 -1 -9 -9 0 -22 15 -18 22 6 4 11 12 18 18 16
17 -5 21 -37 6 -51 -9 -8 -10 -8 -5 1 5 9 2 11 -8 7 -13 -5 -12 -10 4 -36 10
-16 32 -39 50 -49 19 -12 30 -25 27 -33 -7 -17 28 -52 37 -38 3 5 -1 15 -9 22
-8 7 -15 19 -15 26 0 8 -6 14 -12 15 -10 0 -10 2 0 6 6 2 12 9 12 14 0 6 -4 8
-9 4 -5 -3 -13 0 -17 6 -5 8 -3 9 6 4 9 -5 11 -4 6 4 -9 15 -28 4 -20 -12 3
-7 -1 -4 -9 6 -8 9 -19 17 -26 17 -6 0 -11 5 -11 10 0 7 7 7 18 2 9 -4 27 -7
40 -6 12 0 20 -1 17 -4 -2 -3 0 -11 7 -19 6 -7 8 -16 5 -20 -4 -3 -2 -14 3
-23 7 -12 20 -17 43 -16 25 2 34 -1 34 -13 0 -25 -18 -42 -38 -36 -27 9 -23
-7 7 -36 l26 -24 -7 28 c-5 18 -3 27 5 27 7 0 10 -6 7 -14 -5 -11 3 -14 37
-12 l43 3 -1 -41 c-1 -45 -23 -64 -43 -37 -20 25 -15 36 7 16 16 -14 20 -15
20 -4 0 8 -15 23 -32 34 -32 18 -32 18 -18 0 14 -17 13 -18 -7 -13 -20 5 -19
2 9 -25 20 -20 39 -31 52 -29 21 4 21 4 1 -12 -16 -13 -17 -15 -2 -10 13 5 17
1 17 -15 0 -19 15 -27 43 -22 11 2 8 -14 -5 -28 -11 -11 -9 -11 9 0 12 7 19
17 17 21 -3 4 -1 8 4 8 5 0 9 -16 9 -35 0 -21 -4 -31 -8 -23 -4 6 -5 16 -2 22
3 5 1 6 -6 2 -13 -9 -9 -17 37 -75 34 -41 36 -43 50 -25 13 18 14 18 7 -3 -8
-29 19 -74 29 -48 3 9 2 13 -3 10 -5 -3 -12 -2 -14 3 -3 4 12 7 32 7 35 0 37
-2 36 -27 -2 -39 9 -52 46 -53 20 0 34 -6 37 -15 3 -13 2 -13 -7 0 -9 13 -11
12 -11 -5 -1 -19 -1 -19 -14 -2 -7 9 -17 17 -21 17 -4 0 -2 -7 5 -15 7 -8 9
-15 4 -15 -13 0 -41 20 -29 20 6 0 2 5 -7 10 -11 7 -15 19 -12 37 4 23 3 25
-11 13 -13 -11 -15 -10 -9 4 3 9 2 16 -3 16 -5 0 -9 -9 -10 -20 -1 -12 4 -20
14 -20 9 0 13 -3 10 -6 -9 -10 32 -64 49 -64 8 0 14 -4 14 -10 0 -5 8 -10 17
-10 15 0 15 1 -1 13 -16 12 -16 13 3 13 15 0 21 -7 21 -21 0 -19 6 -21 39 -20
38 1 38 1 31 -26 -10 -34 4 -48 51 -51 l35 -3 1 -54 1 -54 -18 27 c-16 26 -18
26 -23 7 -3 -12 0 -19 7 -18 6 1 11 -6 11 -15 0 -9 4 -20 9 -25 5 -4 6 -3 2 5
-4 6 -5 12 -1 12 3 0 10 -12 15 -26 6 -14 14 -22 20 -19 5 3 10 -1 10 -9 0 -9
4 -16 10 -16 5 0 7 7 3 16 -5 14 -3 13 15 -2 24 -21 29 -29 10 -18 -10 6 -10
4 0 -7 6 -7 12 -17 12 -22 0 -4 -6 -5 -12 -1 -7 4 -3 -3 9 -15 24 -23 39 -28
27 -9 -5 7 -3 8 5 4 7 -5 10 -14 7 -22 -7 -18 6 -25 24 -13 8 5 10 9 4 9 -6 0
-11 6 -11 13 0 8 6 14 12 14 15 0 17 -54 3 -63 -7 -5 -8 -2 -3 6 5 9 4 11 -4
6 -16 -10 71 -112 92 -108 11 2 14 -3 10 -18 -3 -11 -1 -21 3 -21 51 -7 64 -7
64 1 0 5 -11 7 -24 4 -14 -2 -28 0 -31 6 -3 5 -1 10 5 10 6 0 9 4 6 8 -2 4 5
8 17 9 12 0 16 -1 9 -4 -6 -3 -10 -9 -7 -13 3 -5 13 -6 21 -3 13 5 15 -1 12
-28 -3 -33 -2 -33 36 -34 22 0 41 -5 44 -13 2 -6 -1 -12 -8 -12 -6 0 -9 4 -6
8 6 11 -45 10 -61 -1 -10 -6 -10 -12 -1 -27 11 -17 13 -18 21 -4 6 11 5 19 -4
25 -10 6 -7 8 9 6 23 -1 65 -37 44 -37 -6 0 -14 5 -17 10 -9 14 -25 3 -25 -17
0 -16 68 -96 84 -100 5 -1 19 -19 32 -40 19 -33 28 -39 53 -37 17 1 28 -2 25
-7 -3 -5 1 -9 8 -9 10 0 9 -3 -1 -9 -10 -7 -19 -4 -29 7 -8 9 -6 4 3 -13 19
-31 50 -65 60 -65 3 0 3 6 -1 13 -6 9 -3 8 8 -1 9 -8 14 -21 12 -30 -5 -14 -2
-13 10 2 12 16 13 22 3 28 -9 6 -8 8 4 8 11 0 15 -10 15 -37 0 -20 5 -38 12
-41 7 -2 9 -9 5 -15 -4 -7 1 -6 10 2 10 8 20 11 24 7 3 -3 1 -6 -5 -6 -20 0
-5 -50 24 -77 16 -15 29 -30 29 -34 0 -4 4 -11 9 -16 5 -5 6 -3 2 5 -4 7 -2
12 5 12 6 0 19 9 29 20 16 19 17 18 18 -14 1 -29 -2 -33 -18 -29 -11 3 -20 0
-20 -6 0 -5 6 -8 14 -5 17 7 30 -12 15 -22 -7 -4 2 -19 25 -43 19 -20 47 -49
62 -66 14 -16 21 -22 14 -12 -16 21 3 23 21 1 10 -13 10 -17 -2 -25 -9 -6 -10
-9 -2 -9 7 0 24 -17 38 -37 20 -30 31 -38 57 -38 19 0 33 -6 35 -15 4 -12 3
-13 -5 -1 -5 7 -15 11 -20 7 -14 -8 -4 -36 11 -33 6 1 13 -7 15 -18 2 -11 9
-24 14 -30 8 -7 9 -6 4 2 -4 7 1 11 13 10 14 -1 17 -5 10 -13 -6 -8 2 -25 28
-55 20 -24 34 -37 31 -28 -5 12 1 15 23 15 16 0 29 -2 30 -5 0 -3 2 -20 3 -37
2 -29 0 -31 -16 -20 -10 6 -14 12 -8 14 16 6 12 20 -5 18 -29 -4 -26 -15 10
-39 20 -14 36 -29 36 -33 -1 -5 6 -17 15 -28 15 -18 17 -18 35 10 18 27 19 28
19 7 0 -13 -5 -23 -10 -23 -6 0 -8 -12 -6 -26 5 -28 13 -39 26 -39 5 0 16 -9
25 -20 9 -11 13 -14 9 -6 -5 11 -3 13 10 8 14 -6 15 -3 5 14 -6 11 -8 23 -5
27 10 10 18 -6 20 -40 1 -24 7 -34 21 -36 11 -3 26 -9 34 -15 11 -10 13 -8 7
9 -4 13 -2 21 6 21 7 0 14 -11 14 -24 1 -13 -3 -23 -10 -23 -7 0 -6 -3 2 -9
10 -6 10 -10 -3 -21 -12 -10 -15 -10 -15 2 0 8 -7 23 -16 34 -13 15 -15 16
-10 2 3 -12 1 -15 -9 -11 -8 3 -12 10 -8 17 3 6 3 8 -2 4 -4 -4 -15 0 -23 10
-12 15 -12 14 0 -8 8 -14 24 -29 35 -34 11 -5 25 -22 32 -38 7 -16 15 -27 18
-24 7 7 33 -20 33 -33 0 -6 7 -8 16 -4 8 3 13 12 10 19 -8 21 8 17 22 -6 9
-14 9 -20 1 -20 -8 0 -8 -5 1 -15 7 -9 10 -18 7 -22 -4 -3 -2 -14 4 -24 7 -13
17 -18 34 -15 14 3 25 0 25 -5 0 -6 4 -8 8 -5 4 2 9 -4 9 -15 1 -12 -5 -19
-15 -19 -12 0 -7 -6 13 -20 17 -11 35 -18 42 -15 7 2 10 -2 7 -9 -3 -7 2 -13
10 -13 8 0 13 4 10 8 -2 4 -1 10 4 13 4 2 9 -8 10 -24 2 -29 -8 -33 -38 -15
-9 5 -8 0 4 -13 10 -12 23 -19 29 -15 6 4 8 0 4 -10 -8 -21 11 -33 26 -18 9 9
7 11 -8 6 -13 -4 -16 -4 -8 2 7 5 25 8 40 7 25 -1 28 -4 28 -36 0 -20 -3 -34
-7 -32 -3 2 -4 -5 -1 -16 3 -11 10 -23 16 -27 6 -3 7 -1 3 5 -4 7 -1 12 8 12
9 0 16 5 16 10 0 6 5 10 11 10 5 0 7 -5 3 -12 -5 -7 -3 -8 7 -2 23 14 68 12
81 -4 11 -13 11 -14 -2 -7 -18 11 -59 18 -45 8 6 -5 11 -14 10 -22 0 -16 -41
-25 -50 -11 -3 5 0 7 8 4 8 -3 18 -1 22 5 4 7 -2 10 -17 7 -13 -3 -27 -5 -31
-5 -4 -1 2 -11 15 -24 21 -21 24 -22 41 -7 10 8 19 13 20 10 1 -3 13 5 26 17
13 12 20 26 17 31 -4 6 3 8 16 4 16 -4 19 -2 12 9 -12 20 -12 58 1 56 5 -1 9
3 8 9 -1 7 11 11 32 9 l33 -2 -3 41 -4 41 75 1 c74 1 102 13 87 38 -4 5 1 7
10 3 11 -4 17 -1 17 9 0 9 5 12 12 8 7 -4 8 -3 4 4 -5 8 -13 9 -25 3 -12 -7
-18 -6 -18 2 0 8 14 13 34 14 21 0 31 -3 26 -11 -4 -7 -1 -6 7 1 7 7 13 30 12
53 -2 34 1 40 19 40 11 0 18 -4 15 -9 -4 -5 0 -12 6 -14 8 -4 7 -6 -4 -6 -9
-1 -19 -5 -22 -10 -8 -12 37 -8 46 4 4 5 2 15 -4 22 -12 15 3 18 18 3 6 -6 9
2 9 23 -1 20 3 34 10 34 6 0 12 -6 12 -14 0 -7 -4 -13 -8 -13 -5 0 -6 -7 -3
-15 4 -8 10 -12 15 -9 5 3 7 10 4 15 -4 5 0 9 7 9 9 0 9 3 -2 10 -13 9 -7 14
12 11 4 0 5 6 2 14 -4 8 -10 15 -16 15 -5 0 -7 -4 -4 -9 4 -5 -3 -7 -14 -4
-12 3 -18 8 -15 12 10 9 85 9 79 -1 -3 -4 -10 -6 -15 -3 -5 3 -6 -1 -3 -10 6
-14 9 -14 26 2 11 10 21 16 22 13 1 -3 16 7 34 21 17 15 28 30 25 33 -4 3 1 6
11 6 9 0 19 7 22 15 4 8 12 15 20 15 13 0 19 13 15 34 -1 5 5 4 13 -4 10 -8
16 -8 16 -2 0 6 -7 15 -16 20 -9 5 -14 15 -10 21 4 6 12 9 17 5 5 -3 6 -10 3
-16 -4 -6 -1 -8 7 -6 8 3 13 11 11 17 -2 9 3 9 16 2 15 -8 25 -4 47 19 24 25
28 59 4 45 -5 -3 -6 1 -2 10 5 13 7 14 14 3 7 -11 11 -10 20 2 9 13 10 12 5
-3 -4 -12 -2 -17 6 -15 7 3 12 24 13 53 0 44 2 48 27 51 20 2 27 -3 32 -19 l5
-21 26 24 c14 13 25 20 25 16 0 -5 26 13 57 40 127 108 128 109 91 111 -13 0
-17 -1 -10 -4 16 -6 15 -20 -2 -26 -9 -4 -13 2 -12 16 1 18 7 22 34 23 17 0
32 -4 32 -10 0 -17 55 47 55 64 1 8 -4 18 -10 23 -5 4 2 5 18 3 26 -5 32 2 28
32 -1 6 3 12 9 12 5 0 10 5 10 11 0 5 -4 8 -8 5 -5 -3 -9 1 -9 8 0 10 12 13
39 11 24 -2 38 1 38 8 0 9 2 9 8 1 12 -19 40 -12 67 16 24 25 24 26 4 27 -22
2 -42 -12 -52 -37 -3 -9 -6 -6 -6 10 -1 20 -3 22 -11 10 -14 -22 -11 1 3 25 7
11 23 21 37 23 19 2 22 0 10 -8 -12 -8 -11 -10 8 -10 13 0 21 4 18 8 -2 4 12
9 32 10 20 2 44 10 54 19 20 18 23 33 7 33 -5 0 -8 -4 -4 -9 3 -5 2 -12 -3
-15 -4 -2 -9 4 -9 15 -1 10 4 19 10 19 8 0 7 3 -2 9 -8 5 5 8 37 7 31 -1 48 3
44 9 -10 15 -10 55 0 49 4 -3 8 2 8 10 0 9 7 13 20 11 11 -2 27 3 35 11 13 14
15 14 15 0 0 -8 -8 -17 -17 -19 -10 -3 -3 -4 14 -3 18 2 41 10 50 18 10 9 33
19 51 23 26 5 31 10 22 20 -14 17 -48 26 -55 14 -3 -4 -12 -6 -19 -3 -7 3 -21
-2 -30 -12 -9 -9 -19 -14 -22 -12 -2 3 5 13 17 24 18 16 40 18 202 20 169 1
179 0 144 -13 -20 -8 -37 -19 -37 -24 0 -13 18 -11 22 2 3 9 9 9 26 0 13 -7
19 -17 16 -26 -6 -16 19 -21 83 -16 17 1 34 -3 38 -9 3 -5 11 -10 16 -10 6 0
7 5 4 10 -3 6 -2 10 4 10 15 0 24 -20 9 -21 -10 0 -10 -2 1 -6 11 -5 11 -7 0
-14 -12 -7 -12 -9 0 -9 11 0 11 -3 1 -15 -16 -19 1 -42 21 -29 7 4 34 9 59 10
32 2 39 0 25 -6 -16 -7 -17 -9 -3 -9 9 -1 20 4 23 9 3 6 16 10 28 9 13 0 16
-3 8 -6 -11 -5 -11 -7 0 -14 12 -7 11 -9 -1 -9 -13 0 -13 -1 0 -10 12 -8 10
-10 -8 -10 -13 0 -20 4 -17 10 3 6 -4 10 -17 10 -22 -1 -22 -1 -4 -15 11 -8
29 -15 40 -15 12 0 17 -4 11 -8 -15 -11 -18 -35 -3 -26 18 11 129 13 153 2 14
-6 9 -6 -15 -3 -33 6 -34 5 -15 -10 11 -8 25 -13 32 -11 7 3 13 1 13 -4 0 -6
-9 -10 -19 -10 -14 0 -19 -6 -18 -20 1 -21 23 -35 32 -20 8 14 42 13 51 -1 4
-7 0 -9 -14 -5 -12 4 -23 2 -28 -5 -4 -8 -3 -9 4 -5 7 4 12 3 12 -3 0 -6 7 -7
17 -4 11 4 14 3 9 -5 -13 -22 50 -49 109 -47 38 1 56 -2 59 -12 4 -9 1 -11 -6
-7 -17 11 -68 12 -68 2 0 -7 8 -10 26 -7 4 0 11 -5 15 -13 7 -10 9 -10 9 0 0
6 5 12 12 12 6 0 8 -3 5 -7 -8 -7 21 -43 36 -43 5 0 22 -9 38 -21 16 -11 33
-18 38 -15 5 4 11 0 14 -6 2 -7 8 -1 12 15 8 31 30 35 44 8 9 -16 7 -17 -14
-13 -27 5 -35 -15 -10 -24 8 -4 12 -11 8 -17 -3 -6 -1 -7 5 -3 7 4 12 3 12 -3
0 -6 7 -7 17 -4 12 5 14 3 8 -7 -6 -10 -4 -11 6 -4 17 11 129 12 123 2 -3 -5
-1 -8 3 -8 12 0 14 -42 2 -56 -7 -9 8 -11 65 -8 42 2 78 -1 81 -6 3 -6 -1 -7
-9 -4 -9 4 -16 1 -16 -6 0 -8 9 -10 25 -6 14 4 25 2 25 -4 0 -5 -6 -10 -12
-11 -10 0 -10 -2 0 -6 6 -2 12 -13 12 -23 0 -16 7 -18 53 -16 56 4 77 -1 77
-16 0 -5 -10 -7 -22 -3 -22 6 -22 5 -4 -9 12 -10 24 -12 35 -6 17 9 17 8 23
-52 1 -17 8 -23 25 -23 22 0 22 1 6 14 -16 12 -16 12 6 6 16 -5 21 -4 17 4 -4
6 0 11 9 11 13 0 14 -4 5 -20 -7 -14 -7 -20 0 -20 5 0 10 -4 10 -9 0 -5 -10
-7 -22 -4 -28 7 -24 3 25 -25 21 -12 35 -18 32 -12 -10 16 4 11 27 -9 11 -11
27 -17 37 -14 9 4 13 3 9 -2 -9 -10 10 -35 25 -35 6 0 2 7 -9 16 -17 14 -17
15 -1 10 10 -3 23 -6 28 -6 5 0 8 -3 8 -7 -4 -25 6 -28 71 -27 46 1 70 -2 70
-10 0 -6 5 -4 10 4 7 12 9 10 7 -8 -1 -23 -8 -27 -21 -14 -4 4 -17 8 -29 8
-12 1 -32 4 -44 7 -13 3 -26 1 -29 -5 -4 -7 -2 -8 4 -4 7 4 12 1 12 -6 0 -9 3
-9 10 2 9 13 14 7 11 -12 0 -5 2 -7 7 -7 20 3 43 -2 38 -9 -2 -4 5 -14 17 -21
18 -11 20 -11 10 0 -17 18 -16 29 3 29 8 0 13 -4 9 -9 -3 -5 -1 -12 5 -16 6
-4 8 -11 5 -16 -4 -5 5 -5 20 1 17 7 24 7 20 0 -9 -14 8 -12 24 3 11 9 13 9 7
0 -8 -15 10 -18 19 -3 7 12 35 13 35 2 0 -5 -16 -13 -35 -19 -19 -6 -33 -8
-31 -5 2 4 -7 8 -21 8 -29 2 -30 3 27 -28 57 -31 83 -41 75 -28 -3 6 -2 10 4
10 5 0 12 -4 15 -8 3 -5 19 -8 35 -7 17 1 28 -2 26 -6 -3 -4 2 -10 11 -13 8
-3 12 -2 9 4 -7 11 -5 12 41 16 31 2 32 1 30 -37 0 -21 2 -39 7 -39 4 0 7 -7
7 -15 0 -9 6 -12 15 -9 8 4 12 10 9 15 -3 5 5 5 17 0 29 -10 37 -31 12 -31
-15 0 -16 2 -3 10 13 8 12 10 -2 10 -16 0 -19 -5 -17 -27 0 -5 7 -7 16 -5 8 2
12 -1 9 -7 -7 -10 31 -27 53 -22 7 1 10 -2 6 -8 -6 -11 7 -15 33 -12 7 1 11
-3 10 -10 -2 -6 3 -14 11 -17 8 -2 11 -1 8 4 -8 14 16 41 36 41 9 0 17 -4 17
-9 0 -4 -6 -8 -12 -8 -9 0 -9 -3 -3 -8 6 -4 11 -15 12 -24 0 -10 1 -22 2 -26
0 -4 31 -7 67 -7 36 1 68 -3 71 -8 3 -5 9 -3 14 4 6 10 8 7 6 -9 -1 -23 -8
-27 -21 -15 -12 13 -63 11 -63 -1 0 -7 9 -13 19 -13 10 -1 18 2 18 8 0 5 5 9
10 9 7 0 7 -6 1 -18 -7 -14 -5 -21 12 -32 19 -12 20 -11 10 2 -13 15 -8 28 9
28 5 0 7 -4 3 -9 -3 -5 -1 -12 5 -16 6 -4 7 -11 4 -17 -5 -7 -2 -8 6 -3 15 9
150 17 150 9 0 -3 -10 -9 -22 -15 l-23 -10 23 5 c25 5 26 2 12 -25 -6 -10 -18
-19 -28 -18 -9 0 -12 3 -6 5 13 5 -4 54 -19 54 -5 0 -5 -4 -2 -10 3 -5 -1 -10
-9 -10 -9 0 -16 -4 -16 -9 0 -12 46 -41 65 -41 10 0 13 -5 9 -12 -4 -7 -3 -8
5 -4 6 4 9 12 6 17 -4 5 3 6 17 1 13 -5 35 -7 51 -6 21 2 27 -1 27 -17 0 -10
7 -19 15 -19 8 0 15 -4 15 -9 0 -8 -20 -8 -60 -2 -3 1 2 6 10 12 13 9 12 10
-2 5 -12 -3 -15 -1 -11 10 3 7 0 14 -6 14 -6 0 -11 -5 -11 -11 0 -8 -4 -8 -14
0 -11 9 -16 9 -22 0 -4 -8 -3 -9 4 -5 7 4 12 3 12 -2 0 -9 39 -30 51 -28 4 0
16 -8 28 -18 20 -18 21 -18 21 -1 0 18 14 13 16 -6 3 -19 4 -20 16 -12 7 4 29
8 49 8 34 1 36 0 34 -29 -4 -37 10 -57 32 -49 12 5 14 3 8 -8 -7 -11 -6 -12 6
-3 19 14 59 16 59 2 0 -6 -11 -8 -25 -6 -32 7 -32 4 8 -23 21 -16 27 -18 18
-6 -12 15 -11 17 9 15 13 -2 25 3 27 10 3 6 0 12 -7 12 -6 0 -8 -4 -5 -10 3
-5 1 -10 -4 -10 -6 0 -11 7 -11 15 0 9 9 15 23 15 17 0 23 -6 25 -26 1 -14 -2
-23 -7 -20 -5 4 -8 -2 -6 -11 2 -10 4 -21 4 -25 1 -4 8 -4 18 -1 11 4 14 3 9
-5 -4 -7 -2 -12 3 -12 6 0 11 4 11 8 0 5 14 9 30 10 18 0 28 -3 24 -10 -3 -5
-14 -8 -24 -5 -12 3 -17 0 -13 -9 2 -7 12 -10 20 -7 12 5 14 3 8 -7 -5 -9 -4
-11 4 -6 6 4 9 12 6 17 -7 11 -3 11 19 3 15 -6 15 -7 0 -19 -14 -9 -14 -14 -4
-20 9 -5 11 -4 6 3 -7 12 4 16 34 13 12 -1 11 1 -2 12 -10 8 -15 17 -11 21 4
4 -1 4 -12 0 -12 -4 -17 -3 -12 2 5 5 18 9 30 9 19 1 22 -5 23 -42 0 -30 -3
-39 -8 -28 -5 8 -14 15 -19 15 -6 0 -4 -7 5 -15 8 -9 17 -14 19 -13 2 2 11 -3
19 -10 9 -7 24 -9 33 -5 14 5 16 3 10 -8 -6 -11 -5 -12 6 -1 16 15 49 16 49 1
0 -6 -9 -8 -20 -4 -27 9 -26 -16 1 -33 18 -11 20 -11 16 0 -2 7 2 21 10 30 14
15 13 18 -13 23 -51 12 -50 14 3 12 53 -1 62 -12 31 -35 -16 -12 -22 -56 -6
-46 5 3 6 9 3 14 -3 4 2 14 11 21 13 11 15 11 14 -2 -5 -29 1 -34 37 -34 36 0
38 -2 38 -33 0 -38 15 -51 53 -45 23 3 24 2 7 -5 -17 -8 -16 -10 13 -14 35 -5
55 7 25 15 -10 3 0 4 23 3 37 -1 40 -3 40 -28 0 -16 -4 -28 -8 -28 -5 0 -8 8
-6 18 2 12 -3 16 -17 14 -11 -2 -26 -4 -33 -4 -9 -1 -9 -5 -2 -12 7 -7 14 -6
22 2 7 7 17 12 22 12 6 0 5 -5 -1 -11 -21 -21 17 -48 59 -44 23 3 41 -1 48
-11 12 -14 0 -20 -14 -7 -10 10 -37 13 -31 3 3 -5 19 -11 35 -15 17 -4 30 -11
30 -15 0 -5 -8 -7 -17 -3 -19 7 -18 5 11 -17 17 -13 18 -13 11 -1 -6 10 -4 13
6 9 18 -7 44 18 26 26 -9 3 -8 7 3 15 13 9 12 11 -4 11 -14 0 -17 -5 -13 -21
7 -26 -13 -20 -23 7 -7 17 -4 20 24 19 l31 0 1 -65 c2 -103 1 -99 29 -94 21 4
25 0 25 -18 0 -18 2 -20 9 -8 6 8 7 19 4 25 -4 5 -2 6 3 2 12 -12 14 -213 2
-262 -5 -22 -17 -46 -26 -54 -16 -13 -16 -13 2 -7 14 5 17 2 12 -9 -3 -8 -2
-23 1 -32 6 -15 8 -14 14 7 l7 25 1 -27 c1 -32 -31 -47 -57 -27 -9 7 -14 10
-10 5 4 -5 -8 -25 -27 -45 -31 -32 -33 -37 -18 -48 13 -11 14 -17 5 -38 -6
-14 -7 -25 -2 -25 5 0 12 9 15 21 4 11 9 18 11 16 13 -12 -19 -52 -43 -54 -19
-1 -22 1 -12 8 11 7 11 9 -3 9 -9 0 -20 -4 -23 -10 -8 -13 -25 -13 -25 1 0 5
5 7 10 4 6 -3 10 -1 10 4 0 16 -11 13 -33 -6 -15 -13 -18 -14 -12 -3 6 11 4
11 -10 -2 -10 -9 -18 -32 -19 -50 0 -20 -6 -35 -13 -35 -7 0 -10 4 -7 9 3 4
-4 8 -16 8 -12 0 -18 5 -15 10 6 10 -9 15 -30 10 -5 -1 -28 -4 -49 -6 -22 -3
-51 -11 -65 -18 -14 -8 -48 -14 -76 -14 -41 0 -45 2 -25 9 22 8 22 8 -5 3 -34
-6 -88 -10 -160 -13 -35 -2 -44 -5 -31 -10 30 -12 6 -19 -44 -14 -54 5 -51 5
-125 -3 -33 -3 -76 -6 -95 -5 -26 0 -31 -3 -20 -10 12 -8 10 -10 -7 -6 -13 3
-23 1 -23 -4 0 -13 27 -11 90 6 68 18 95 19 55 0 -16 -7 -23 -14 -15 -14 8 0
26 6 40 14 30 17 66 19 75 5 4 -7 -2 -9 -18 -5 -15 4 -36 0 -54 -10 -15 -9
-19 -13 -8 -10 40 13 126 15 119 3 -5 -8 2 -9 22 -4 19 4 24 3 15 -3 -11 -7
-360 -8 -381 0 -3 1 -9 0 -15 -1 -35 -8 -95 -7 -95 2 0 6 -13 8 -32 4 -18 -3
-84 -8 -146 -11 -63 -3 -117 -7 -122 -10 -5 -3 -6 -13 -3 -22 5 -12 3 -14 -7
-8 -8 5 -37 7 -65 5 -27 -2 -79 -3 -115 -3 -75 1 -56 -11 35 -22 33 -4 83 -10
110 -15 45 -7 41 -8 -32 -6 -46 0 -83 4 -83 7 0 4 -21 9 -47 13 -27 3 -63 8
-80 12 -39 7 -103 -3 -103 -17 0 -6 7 -6 19 1 11 5 39 9 63 8 40 -1 41 -1 8
-5 -19 -3 -39 -8 -45 -13 -5 -4 -52 -8 -103 -7 -53 0 -87 4 -80 8 9 6 6 9 -9
9 -12 0 -25 -5 -28 -10 -3 -6 -23 -9 -43 -9 -20 1 -62 1 -92 0 -43 -2 -50 -5
-35 -12 14 -6 2 -8 -42 -6 -36 2 -59 -1 -56 -6 3 -5 -4 -6 -17 -2 -15 5 -20 4
-16 -4 4 -6 26 -9 54 -6 l47 4 -45 -10 c-62 -15 -74 -19 -69 -23 7 -7 113 15
124 25 17 17 35 10 31 -13 l-3 -23 -242 0 c-134 0 -245 3 -247 7 -3 5 -17 8
-32 7 -24 0 -25 -1 -7 -9 16 -7 12 -8 -17 -8 -22 1 -35 5 -31 11 4 7 -1 7 -13
2 -10 -4 -59 -10 -109 -12 -49 -2 -92 -9 -96 -14 -3 -6 -24 -8 -47 -6 -29 3
-39 0 -35 -9 2 -7 19 -11 41 -9 22 1 27 0 12 -3 -25 -5 -25 -5 5 -13 24 -6 20
-7 -17 -4 -51 3 -71 12 -63 26 4 5 -9 9 -27 8 -31 -1 -32 -1 -8 -9 21 -7 22
-8 5 -9 -11 -1 -30 -7 -43 -14 -21 -12 -21 -13 15 -14 30 0 34 1 18 8 -13 6
-7 7 20 2 22 -3 60 -9 85 -13 25 -3 -115 -6 -310 -6 -344 -1 -407 -5 -394 -25
3 -5 11 -6 17 -2 7 3 3 -4 -8 -17 -17 -20 -18 -23 -2 -17 15 6 17 0 16 -46 -1
-45 -2 -48 -9 -22 l-8 30 -1 -32 c0 -18 3 -33 8 -33 11 0 9 -1026 -2 -1036 -4
-4 -7 -2 -7 5 0 9 -5 9 -20 1 -19 -10 -20 -9 -15 17 l6 28 -15 -26 c-8 -14
-16 -36 -18 -50 -2 -13 -7 -30 -12 -38 -6 -8 -5 -12 0 -8 5 3 10 -17 10 -45 1
-43 -1 -47 -9 -27 -6 13 -9 29 -8 35 3 19 -27 24 -41 7 -11 -14 -11 -16 4 -11
15 6 30 -18 25 -40 -3 -13 -52 -8 -63 7 -10 13 -16 12 -47 -9 -20 -14 -33 -27
-29 -30 4 -3 7 -16 7 -30 1 -22 -4 -25 -35 -27 -19 0 -28 1 -20 4 8 3 19 12
23 20 5 7 14 10 20 6 7 -4 6 0 -2 10 -14 18 -23 22 -18 10 1 -5 -1 -8 -4 -8
-4 0 -17 -9 -29 -20 -12 -11 -24 -19 -26 -17 -2 1 -4 0 -4 -3 0 -3 0 -14 0
-26 0 -11 4 -18 8 -15 4 2 9 -4 9 -15 2 -24 -23 -30 -99 -26 -32 2 -58 0 -58
-5 0 -5 -4 -6 -10 -3 -5 3 -10 2 -10 -4 0 -6 -17 -19 -38 -30 -21 -11 -39 -26
-40 -33 -7 -58 -6 -63 7 -60 12 3 12 0 1 -12 -14 -17 -25 -22 -15 -7 3 6 -4
15 -15 21 -14 8 -18 15 -12 23 5 7 12 21 16 32 5 14 -1 11 -21 -11 -16 -17
-35 -30 -44 -29 -9 2 -16 -4 -16 -11 0 -8 4 -14 9 -14 4 0 8 4 8 8 0 4 9 8 21
9 14 1 19 -3 15 -12 -3 -8 3 -16 12 -18 10 -3 5 -5 -11 -6 -17 -1 -26 3 -22 9
3 6 2 10 -4 10 -5 0 -13 -5 -17 -11 -5 -8 -8 -8 -13 0 -5 7 -14 4 -29 -9 -22
-21 -37 -27 -27 -11 3 5 0 13 -6 17 -7 4 -9 3 -5 -4 3 -5 0 -13 -6 -15 -8 -4
-4 -8 10 -13 19 -5 20 -9 10 -22 -7 -9 -8 -13 -2 -9 6 3 11 1 11 -6 0 -8 -17
-13 -46 -13 -25 -1 -40 1 -33 3 6 3 10 9 6 14 -7 12 3 11 26 -1 14 -8 18 -6
16 7 -2 9 -8 19 -14 21 -7 2 -10 9 -6 15 3 7 -1 4 -9 -5 -8 -10 -17 -16 -20
-13 -4 3 -5 2 -2 -2 6 -8 -25 -43 -38 -43 -6 0 -2 10 9 22 19 22 19 23 -1 8
-11 -8 -23 -19 -27 -25 -4 -5 -8 -6 -8 -2 0 4 -10 -1 -22 -13 -28 -26 -32 -60
-6 -55 10 2 18 0 18 -5 0 -4 -17 -6 -37 -5 -21 2 -42 4 -47 4 -5 1 -1 11 10
24 17 20 16 20 -9 -2 -15 -14 -27 -21 -27 -16 0 5 -10 -1 -23 -13 -13 -12 -26
-20 -29 -17 -2 3 -4 -5 -3 -17 2 -26 37 -34 59 -13 24 24 31 17 8 -8 -20 -21
-35 -24 -94 -19 -12 1 -15 7 -11 18 5 15 7 15 15 0 5 -9 14 -16 20 -16 7 0 7
4 -1 13 -6 8 -8 17 -5 20 4 4 -4 4 -17 2 -22 -5 -33 -16 -29 -31 1 -4 -4 -10
-12 -15 -7 -5 -13 -23 -13 -41 0 -32 -1 -33 -46 -34 -44 -1 -45 -1 -35 21 6
14 16 21 25 17 10 -4 11 -7 3 -10 -7 -2 -12 -7 -12 -12 0 -5 14 -2 30 7 25 12
29 18 19 29 -15 19 -23 18 -15 -2 4 -10 2 -14 -6 -11 -7 2 -12 10 -10 16 2 9
-3 9 -18 1 -11 -6 -20 -17 -20 -25 0 -8 -5 -15 -11 -15 -5 0 -7 5 -3 11 4 8 0
8 -15 0 -24 -13 -28 -23 -6 -15 8 4 15 2 15 -3 0 -5 -9 -9 -20 -10 -19 -2 -90
-40 -90 -48 0 -2 13 -2 29 0 23 3 29 0 27 -13 -3 -18 -36 -31 -36 -14 0 13
-44 24 -64 16 -9 -3 -13 -10 -10 -15 3 -5 -2 -6 -10 -3 -11 4 -16 0 -16 -10 0
-16 -10 -20 -40 -17 -11 1 -12 0 -2 -7 7 -5 14 -18 16 -29 4 -18 0 -20 -28
-18 -18 1 -37 4 -42 8 -6 3 -9 -15 -8 -44 l2 -49 -86 3 c-69 2 -86 -1 -89 -13
-4 -12 -3 -13 5 -1 7 9 9 2 8 -26 0 -23 -6 -40 -13 -40 -7 0 -9 7 -3 22 7 19
5 21 -16 17 -13 -2 -24 -8 -24 -13 0 -5 8 -5 17 -2 16 6 16 5 4 -11 -10 -12
-16 -14 -21 -5 -6 8 -9 8 -14 0 -4 -6 -13 -11 -19 -11 -7 0 -4 5 6 11 26 15
20 29 -7 16 -13 -7 -22 -15 -19 -20 3 -5 -1 -7 -8 -6 -8 2 -24 -1 -37 -5 -16
-5 -20 -11 -13 -20 20 -24 6 -51 -27 -52 -30 -1 -30 -1 -14 25 l16 26 -22 -19
c-13 -11 -28 -21 -35 -22 -18 -2 -92 -54 -78 -54 6 0 11 -8 11 -17 0 -15 2
-16 10 -3 5 8 12 11 16 7 12 -12 -4 -20 -48 -23 -29 -2 -38 0 -28 6 12 7 10 9
-7 7 -12 0 -19 -5 -17 -9 3 -4 0 -8 -6 -8 -6 0 -8 -4 -5 -10 3 -5 1 -10 -4
-10 -6 0 -11 6 -11 13 0 9 -2 9 -9 -1 -4 -8 -12 -14 -17 -14 -26 -1 -153 -88
-128 -88 5 0 10 -11 10 -24 1 -18 -3 -24 -16 -23 -10 1 -16 5 -14 9 3 4 1 8
-5 8 -5 0 -13 -5 -16 -10 -4 -7 -13 -6 -27 1 -14 8 -23 8 -31 0 -6 -6 -7 -11
-2 -11 4 0 0 -9 -10 -20 -10 -11 -25 -20 -33 -20 -10 0 -7 7 9 21 20 17 18 17
-11 -2 -19 -13 -40 -24 -46 -25 -7 -1 -32 -14 -56 -29 -24 -15 -33 -23 -19
-20 16 5 21 4 17 -4 -4 -6 -15 -11 -24 -12 -10 0 -12 -3 -5 -5 7 -3 21 0 32 6
18 9 21 8 21 -10 0 -11 -7 -20 -15 -20 -8 0 -15 -4 -15 -9 0 -5 -29 -8 -65 -7
-36 1 -65 -1 -65 -6 0 -4 -6 -8 -14 -8 -8 0 -30 -14 -48 -30 -18 -17 -39 -28
-45 -25 -7 2 -19 -4 -25 -15 -7 -11 -20 -20 -28 -20 -12 -1 -11 -4 4 -15 10
-7 15 -18 12 -24 -3 -5 2 -12 11 -15 10 -3 -3 -4 -28 -2 -60 4 -91 -8 -82 -30
4 -12 3 -15 -5 -10 -7 4 -12 1 -12 -8 0 -18 -16 -21 -25 -6 -3 6 1 13 10 16 9
4 14 13 11 21 -3 8 -1 11 4 8 6 -3 10 -4 10 -1 0 18 -24 11 -59 -18 -23 -19
-38 -37 -33 -40 4 -2 14 -1 22 5 8 5 20 9 25 9 6 0 3 -5 -5 -10 -11 -7 -9 -9
10 -8 14 1 31 6 38 11 8 7 12 4 12 -7 0 -15 -11 -16 -85 -13 -90 5 -101 2 -84
-20 14 -17 -3 -17 -20 0 -10 10 -11 9 -6 -6 4 -9 14 -17 22 -17 8 0 12 -4 8
-10 -3 -5 -13 -7 -23 -4 -9 3 -7 -1 5 -8 21 -12 17 -14 -37 -14 -53 -1 -58 0
-42 13 11 9 4 8 -18 -3 -19 -9 -40 -23 -45 -31 -6 -8 -18 -15 -27 -16 -9 -2
-24 -9 -33 -17 -9 -8 -24 -15 -32 -16 -8 -2 -22 -9 -32 -18 -9 -8 -28 -19 -41
-25 -15 -6 -19 -10 -9 -10 9 -1 28 8 41 19 14 10 28 16 32 13 3 -3 6 0 7 8 1
8 5 -2 9 -21 5 -19 7 -36 6 -38 -2 -1 -15 2 -30 8 -29 11 -76 4 -76 -12 0 -11
33 -6 42 7 4 5 10 6 14 2 12 -11 -3 -20 -38 -23 -85 -7 -98 -9 -98 -17 0 -4
-5 -5 -12 -1 -6 4 -8 3 -5 -3 4 -6 -5 -14 -21 -18 -26 -6 -26 -7 -8 -20 10 -8
23 -12 27 -9 19 11 46 11 46 0 0 -8 -16 -12 -43 -12 -24 0 -41 3 -38 7 9 15
-30 19 -46 4 -9 -8 -21 -15 -27 -15 -7 0 -9 -17 -7 -42 3 -33 1 -39 -7 -25 -5
9 -7 22 -4 27 3 6 4 10 1 10 -3 0 -10 -10 -17 -21 -8 -15 -8 -19 0 -15 6 4 11
2 11 -3 0 -6 -12 -11 -27 -11 -25 0 -26 0 -8 20 10 11 13 20 7 20 -7 0 -12 -5
-12 -11 0 -5 -5 -7 -12 -3 -7 4 -8 3 -4 -4 4 -7 3 -12 -3 -12 -5 0 -8 -8 -7
-17 2 -10 -6 -24 -16 -32 -26 -18 -32 -9 -10 15 20 24 27 47 12 42 -7 -2 -10
-6 -9 -8 6 -13 -24 -50 -41 -50 -10 0 -22 -5 -25 -10 -4 -6 -11 -7 -17 -4 -6
4 -5 10 3 15 10 6 10 9 2 9 -16 0 -26 -17 -13 -25 12 -7 4 -25 -11 -25 -6 0
-7 6 -3 13 6 10 4 10 -7 0 -16 -16 -33 -17 -23 -1 4 7 3 8 -5 4 -9 -6 -9 -11
0 -22 7 -9 8 -14 1 -14 -6 0 -13 4 -17 10 -3 5 -13 6 -21 3 -14 -5 -12 -8 7
-19 13 -7 26 -11 30 -9 3 2 6 -2 6 -10 0 -8 -5 -15 -11 -15 -7 0 -6 -3 1 -8 7
-4 -8 -8 -32 -8 -28 0 -46 5 -50 14 -2 7 0 10 5 7 6 -3 10 -1 10 4 0 6 -2 11
-5 11 -9 0 -50 -25 -57 -35 -4 -5 -8 -7 -8 -3 0 4 -9 0 -20 -10 -12 -9 -24
-16 -28 -15 -11 3 -75 -38 -92 -59 -9 -10 -25 -20 -37 -21 -12 0 -20 -4 -17
-8 4 -7 -35 -22 -51 -19 -5 1 -4 -4 3 -12 11 -11 14 -10 22 2 9 13 10 13 10 0
0 -10 7 -7 20 10 l20 25 -2 -26 c-2 -24 -5 -25 -55 -24 -29 1 -50 -1 -48 -5 3
-4 -3 -14 -12 -21 -15 -12 -16 -12 -9 6 6 17 4 16 -21 -5 -15 -14 -32 -28 -36
-31 -5 -4 -3 -10 4 -14 7 -5 10 -4 6 2 -4 6 0 13 8 17 9 3 15 0 15 -9 0 -9 11
-13 31 -13 20 1 26 4 17 9 -10 7 -10 9 0 9 6 0 12 5 12 12 0 6 -7 4 -18 -5
-29 -26 -38 -20 -12 8 31 33 36 32 37 -10 l1 -34 -84 2 c-64 2 -85 0 -89 -11
-7 -20 -6 -34 3 -28 4 2 8 -6 8 -18 0 -20 -5 -23 -38 -22 -21 0 -38 4 -38 7 0
4 -7 10 -16 13 -14 5 -15 4 -5 -7 9 -12 8 -14 -6 -14 -42 0 -53 -4 -53 -19 0
-10 -6 -14 -15 -10 -8 3 -15 0 -15 -6 0 -6 -3 -8 -6 -5 -10 10 -45 -13 -38
-25 4 -5 19 -7 35 -4 15 3 31 1 34 -4 3 -5 -20 -7 -55 -6 -33 2 -57 -1 -54 -5
3 -5 -3 -12 -13 -15 -76 -26 -89 -42 -42 -55 21 -6 19 -7 -8 -6 -22 1 -33 6
-33 16 0 11 -3 10 -13 -4 -8 -10 -18 -16 -23 -13 -5 3 -25 -5 -44 -18 -31 -22
-32 -24 -12 -24 28 0 62 -17 62 -31 0 -5 -10 -3 -22 5 -26 17 -110 14 -116 -3
-2 -6 -10 -11 -17 -11 -8 0 -12 -8 -10 -20 4 -22 -11 -28 -18 -7 -2 6 -3 4 -2
-5 3 -20 -27 -50 -46 -46 -10 2 -14 -6 -12 -27 0 -16 -1 -24 -4 -17 -2 6 -9 9
-15 6 -5 -4 -3 -13 6 -23 15 -17 12 -18 -53 -17 -38 1 -67 4 -65 8 3 4 -4 6
-15 5 -11 -1 -17 -6 -14 -14 3 -7 0 -20 -7 -28 -10 -12 -10 -15 1 -16 11 0 11
-2 1 -6 -8 -3 -10 -9 -3 -16 7 -9 -4 -12 -40 -13 -26 0 -46 2 -43 5 3 4 9 6
12 6 4 1 21 4 37 9 29 8 30 8 7 17 -14 5 -20 13 -16 20 4 8 3 9 -5 5 -9 -6 -9
-11 0 -22 10 -13 7 -14 -24 -12 -20 1 -43 -4 -49 -11 -11 -10 -10 -11 5 -6 9
4 17 3 17 -3 0 -6 -11 -9 -25 -7 -25 2 -28 -2 -28 -38 0 -12 -5 -16 -17 -12
-13 4 -20 -1 -25 -15 -3 -11 -11 -20 -16 -20 -13 0 -11 12 7 40 15 25 15 25
-3 4 -10 -11 -24 -19 -31 -16 -6 2 -12 -1 -12 -8 0 -6 5 -8 12 -4 7 4 8 3 4
-5 -6 -9 -11 -9 -22 0 -11 9 -14 9 -14 0 0 -7 -4 -10 -8 -7 -13 8 -37 -12 -37
-30 0 -10 -7 -19 -15 -21 -13 -4 -13 -3 0 6 8 6 10 11 4 11 -5 0 -16 -6 -22
-12 -7 -7 -17 -14 -22 -14 -6 -1 -11 -2 -12 -4 -2 -1 -10 -3 -18 -5 -21 -4
-96 -55 -81 -55 7 0 9 -5 6 -10 -4 -6 5 -5 20 2 16 7 24 17 20 26 -4 11 0 13
16 9 16 -4 19 -2 14 11 -3 9 -3 13 1 9 4 -4 13 -2 21 4 7 6 13 8 13 4 0 -4 7
-2 15 5 11 9 15 9 15 1 0 -7 4 -10 9 -6 5 3 12 1 15 -4 3 -4 -2 -8 -12 -8 -45
0 -62 -4 -62 -14 0 -15 -42 -48 -61 -49 -9 0 -19 -3 -23 -7 -10 -11 -48 -14
-41 -4 3 5 1 12 -4 16 -6 3 -15 -4 -21 -15 -7 -13 -17 -19 -26 -16 -8 3 -13 2
-9 -3 3 -5 2 -11 -2 -14 -5 -2 -3 -2 4 -1 20 5 27 -18 10 -31 -9 -7 -14 -14
-11 -17 2 -3 -7 -5 -22 -5 -26 0 -27 0 -9 13 10 8 25 14 32 14 7 0 13 5 13 10
0 6 -13 4 -31 -5 -21 -11 -34 -13 -43 -6 -8 7 -15 4 -25 -10 -7 -12 -18 -18
-27 -15 -8 3 -17 -1 -20 -9 -4 -8 -11 -12 -18 -8 -6 3 -6 1 2 -8 17 -18 15
-46 -3 -53 -8 -3 -15 -1 -15 4 0 6 -5 10 -11 10 -5 0 -7 -5 -3 -12 5 -8 2 -9
-10 -5 -10 4 -22 7 -27 7 -6 0 -7 6 -4 13 4 6 0 4 -8 -5 -8 -10 -17 -14 -21
-10 -4 4 -6 1 -4 -7 2 -7 -3 -16 -11 -19 -9 -3 -11 0 -6 9 6 11 5 11 -4 3 -10
-9 -10 -16 1 -33 7 -11 17 -21 22 -21 4 0 3 7 -4 15 -15 18 1 20 18 3 9 -9 12
-9 12 0 0 7 -4 12 -10 12 -5 0 -7 7 -4 15 4 8 9 15 13 15 10 0 7 -67 -3 -77
-5 -5 -6 -3 -2 5 8 14 -10 17 -19 2 -3 -5 -16 -10 -28 -10 -18 0 -19 2 -7 10
12 8 12 10 -5 10 -16 0 -17 2 -5 10 12 7 11 9 -6 8 -11 -1 -21 -8 -21 -15 0
-6 4 -10 9 -7 4 3 8 1 8 -5 0 -5 -9 -9 -20 -8 -13 1 -19 6 -15 15 3 8 0 12 -7
9 -7 -2 -12 -9 -11 -14 1 -6 -8 -10 -20 -9 -13 1 -22 -5 -22 -14 0 -8 -10 -16
-22 -18 -13 -2 -23 -8 -23 -13 0 -6 -3 -8 -7 -6 -5 3 -19 0 -33 -5 -20 -7 -22
-11 -10 -19 11 -7 9 -8 -10 -6 -14 3 -38 -4 -55 -14 -31 -19 -28 -27 4 -10 14
8 17 5 17 -24 0 -32 -1 -33 -38 -31 -33 3 -38 0 -38 -19 0 -36 -38 -61 -95
-60 -28 1 -56 5 -63 9 -7 4 -12 2 -12 -4 0 -6 -5 -8 -12 -4 -7 4 -8 3 -4 -5 4
-6 10 -9 14 -7 4 2 8 -7 8 -20 1 -13 -2 -24 -8 -24 -5 0 -7 4 -4 9 3 4 -2 15
-12 24 -15 14 -19 14 -41 -4 -14 -11 -18 -19 -10 -19 11 0 11 -2 -1 -9 -8 -6
-18 -7 -24 -4 -5 3 -6 1 -2 -5 5 -9 13 -9 27 -1 12 6 23 8 27 5 3 -3 -1 -6 -8
-6 -7 0 -10 -3 -7 -6 4 -4 14 -1 22 6 13 11 19 11 31 -2 13 -13 6 -15 -65 -13
-45 1 -81 -2 -81 -7 0 -4 -3 -7 -7 -7 -5 1 -15 1 -23 0 -13 -1 -13 -2 0 -11
13 -8 12 -10 -3 -10 -25 0 -88 -36 -79 -45 4 -5 1 -5 -5 -1 -7 4 -21 2 -32 -3
-14 -8 -18 -19 -16 -45 4 -33 3 -34 -31 -33 -20 1 -29 4 -21 9 6 4 -2 6 -18 4
-17 -2 -28 -7 -25 -12 3 -5 8 -8 12 -7 4 2 6 -15 5 -37 -1 -34 -5 -40 -23 -40
-12 0 -27 6 -33 13 -8 11 -5 17 15 26 15 6 24 14 22 17 -3 2 -18 -2 -34 -11
-16 -8 -37 -15 -47 -15 -22 0 -69 -35 -60 -44 3 -3 1 -6 -5 -6 -7 0 -12 6 -12
13 0 9 -2 9 -9 -1 -4 -8 -12 -14 -17 -14 -16 0 -78 -36 -101 -57 -16 -15 -20
-29 -17 -53 4 -27 2 -30 -6 -15 -6 9 -8 22 -5 27 4 6 -3 9 -15 7 -24 -4 -42
-26 -33 -41 3 -5 1 -6 -6 -2 -6 4 -9 12 -6 17 3 5 -1 6 -10 3 -11 -4 -12 -10
-6 -18 14 -17 -16 -58 -37 -52 -54 14 -73 16 -67 6 3 -5 19 -10 35 -10 22 0
28 -4 24 -15 -7 -17 -44 -21 -44 -4 0 5 -5 7 -12 3 -7 -4 -24 -9 -38 -10 -24
-2 -24 -1 5 12 17 7 23 13 15 13 -8 0 -32 -10 -54 -23 -34 -21 -35 -24 -13
-23 14 1 23 6 19 11 -3 5 0 6 6 2 17 -10 10 -55 -9 -61 -32 -11 -114 -15 -99
-6 8 6 18 7 22 5 4 -3 5 2 2 10 -5 13 -8 13 -22 0 -9 -8 -19 -13 -22 -12 -3 1
-15 -6 -27 -17 -12 -10 -28 -20 -35 -21 -22 -3 -100 -51 -98 -60 2 -11 -32
-23 -44 -16 -5 3 -6 0 -2 -6 4 -7 3 -12 -2 -12 -5 0 -9 -7 -9 -16 0 -15 1 -15
12 0 16 21 24 20 22 -1 -1 -14 -11 -19 -45 -20 -26 -2 -54 -10 -65 -20 -20
-17 -20 -17 -13 2 6 17 4 17 -21 -5 -15 -14 -32 -28 -36 -31 -5 -4 -3 -10 4
-14 7 -5 10 -4 6 2 -4 6 0 13 8 17 9 3 15 0 15 -9 0 -9 11 -13 31 -13 20 1 26
4 17 9 -10 7 -10 9 0 9 6 0 12 5 12 12 0 6 -7 4 -18 -5 -29 -26 -38 -20 -12 8
31 33 36 32 37 -10 l1 -35 -64 2 c-51 2 -63 6 -59 17 4 10 -1 9 -15 -4 -11
-10 -20 -14 -20 -9 0 14 -20 -19 -20 -34 0 -7 3 -11 8 -9 4 3 7 -5 7 -18 0
-18 -5 -22 -24 -20 -14 1 -30 11 -38 21 -7 10 -13 13 -13 5 0 -7 -5 -9 -10 -6
-12 7 -80 -32 -80 -46 0 -4 -6 -6 -12 -4 -7 3 -24 -4 -37 -14 -18 -14 -21 -20
-11 -26 8 -5 11 -4 7 2 -4 6 0 13 8 17 18 6 30 -10 13 -18 -7 -3 -3 -3 10 0
12 3 22 1 22 -4 0 -6 -24 -9 -54 -8 -30 1 -53 -1 -50 -6 3 -4 -2 -10 -10 -13
-9 -3 -24 -8 -33 -12 -10 -3 -20 -7 -23 -8 -25 -8 -40 -19 -40 -26 0 -6 10 -5
21 2 12 6 37 9 56 6 27 -5 32 -3 24 6 -7 9 -5 16 8 24 18 12 31 8 31 -8 0 -5
-8 -6 -17 -3 -17 5 -17 5 0 -9 18 -15 24 -32 7 -22 -6 4 -17 2 -25 -5 -9 -7
-15 -8 -20 0 -3 5 -18 10 -33 9 l-27 -1 25 -8 c22 -8 21 -8 -10 -7 -19 0 -41
5 -49 11 -12 9 -13 8 -6 -3 6 -11 4 -13 -7 -9 -8 4 -28 -4 -44 -18 -26 -21
-27 -23 -9 -24 17 -1 17 -3 -5 -11 -14 -6 -32 -8 -41 -6 -24 6 -67 -25 -63
-45 3 -21 -13 -25 -18 -5 -2 11 -8 11 -29 -2 -22 -14 -24 -18 -13 -29 12 -11
16 -9 22 9 5 13 12 18 15 12 8 -13 -26 -45 -44 -41 -9 2 -13 -5 -12 -17 1 -13
-8 -27 -25 -38 -17 -10 -21 -17 -11 -17 8 0 21 6 28 13 11 10 13 9 9 -3 -5
-17 -98 -23 -98 -7 0 5 -7 4 -15 -3 -9 -7 -18 -10 -21 -7 -8 8 28 25 48 22 10
-2 15 2 12 10 -3 7 2 15 10 18 9 4 16 12 16 18 0 8 -7 6 -18 -4 -10 -10 -22
-14 -26 -10 -4 4 -4 -1 0 -12 7 -17 5 -18 -7 -8 -9 8 -20 9 -29 3 -8 -6 -11
-10 -6 -10 5 0 1 -9 -10 -21 -10 -11 -19 -35 -19 -52 0 -24 -5 -33 -20 -35
l-20 -4 20 16 c20 15 20 15 -2 9 -13 -3 -23 -9 -23 -13 0 -10 -61 -6 -79 4
-10 6 -12 5 -7 -2 4 -7 1 -12 -6 -13 -43 -2 -83 -23 -73 -39 7 -11 -24 -22
-38 -14 -9 5 -9 4 -1 -5 9 -10 17 -10 32 -2 26 14 42 14 22 1 -11 -8 -7 -9 14
-4 19 5 27 3 23 -4 -4 -5 -38 -10 -75 -9 -70 0 -97 -7 -87 -24 5 -8 -16 -14
-37 -10 -5 0 -8 -4 -8 -9 0 -6 4 -10 10 -10 5 0 7 -7 4 -15 -4 -8 -14 -15 -23
-14 -10 0 -11 2 -3 6 6 2 9 9 6 14 -10 17 -32 -10 -26 -33 4 -17 2 -20 -12
-15 -10 4 -22 7 -27 7 -6 0 -7 5 -4 11 4 6 -2 5 -13 -2 -12 -7 -20 -15 -18
-17 3 -3 38 -9 69 -11 4 -1 7 -6 7 -12 0 -6 -8 -8 -17 -5 -17 6 -17 5 0 -8 15
-12 6 -13 -68 -12 -75 2 -91 -1 -126 -22 -38 -23 -39 -25 -24 -42 16 -17 16
-18 0 -16 -9 1 -21 9 -27 17 -8 12 -9 11 -5 -2 4 -12 -2 -16 -24 -16 -16 0
-35 -6 -42 -13 -10 -10 -9 -11 3 -6 8 4 6 0 -4 -8 -18 -14 -18 -17 -3 -33 14
-17 13 -18 -19 -21 -21 -1 -33 2 -29 8 3 5 11 8 18 6 7 -1 9 -1 4 1 -4 3 -5 9
-2 13 3 5 1 11 -4 14 -5 4 -12 -3 -16 -14 -3 -11 -14 -19 -23 -17 -9 1 -18 -2
-20 -8 -6 -19 -35 -45 -50 -45 -10 0 -7 7 9 21 23 20 23 20 -8 -1 -37 -24 -40
-34 -15 -50 14 -8 12 -9 -13 -6 -38 4 -66 -10 -59 -29 4 -9 0 -15 -7 -15 -11
0 -11 -3 1 -11 11 -7 6 -8 -20 -3 -19 3 -41 11 -47 16 -9 8 -13 6 -13 -5 0
-16 26 -30 41 -21 5 3 17 1 26 -5 13 -7 -2 -9 -57 -8 -60 3 -79 0 -94 -15 -10
-9 -24 -14 -30 -11 -6 3 -7 2 -3 -3 5 -5 13 -10 18 -10 5 -1 15 -2 22 -3 6 -1
1 -7 -12 -12 -19 -8 -27 -6 -34 6 -10 19 -28 10 -21 -10 4 -8 10 -12 15 -9 5
3 9 0 9 -6 0 -7 -7 -9 -16 -6 -9 3 -20 6 -25 6 -6 0 -7 5 -4 10 10 17 0 11
-39 -19 -20 -16 -32 -32 -27 -35 16 -10 41 5 41 25 0 10 5 19 11 19 15 0 6
-42 -11 -50 -8 -4 -26 -12 -39 -19 -13 -7 -35 -15 -50 -18 -33 -6 -34 -6 -35
-45 -1 -30 -4 -33 -36 -35 -26 -1 -30 0 -15 6 11 5 27 6 35 4 13 -4 13 -4 0 6
-10 7 -11 11 -2 11 6 0 12 5 12 12 0 6 -7 4 -18 -5 -10 -10 -26 -17 -35 -17
-9 0 -17 -4 -17 -9 0 -5 -21 -8 -47 -7 -31 1 -48 -3 -51 -13 -3 -8 0 -11 5 -8
5 3 9 -9 9 -27 0 -19 -5 -33 -12 -33 -7 0 -10 7 -6 16 4 12 0 11 -18 -4 -16
-14 -22 -16 -18 -5 2 8 14 20 25 25 17 9 18 13 7 23 -11 10 -14 10 -14 -3 0
-13 -11 -19 -31 -17 -4 1 -5 -9 -2 -21 4 -19 3 -20 -7 -4 -6 9 -16 17 -22 17
-7 0 -7 -5 1 -14 10 -12 7 -14 -15 -11 -15 1 -34 -5 -44 -15 -10 -10 -21 -16
-24 -13 -7 8 -51 -26 -51 -40 -1 -6 -7 -11 -13 -9 -10 2 -10 -3 -2 -17 14 -26
13 -31 -5 -25 -8 4 -12 11 -9 16 4 6 -2 7 -16 3 -17 -5 -21 -4 -15 6 5 8 -3 4
-17 -8 -13 -13 -29 -23 -36 -23 -18 0 -73 -64 -60 -71 23 -15 -4 -27 -57 -25
-73 2 -101 -16 -100 -65 1 -32 -2 -35 -27 -35 -35 0 -35 2 0 20 15 8 21 15 13
16 -8 0 -11 6 -7 16 3 8 2 12 -4 9 -6 -3 -10 -13 -10 -21 0 -8 -5 -14 -11 -14
-5 0 -7 4 -4 10 4 6 -1 7 -11 3 -10 -4 -13 -8 -7 -10 7 -3 9 -11 6 -19 -4 -12
-8 -12 -20 3 -8 10 -12 13 -9 7 3 -7 -1 -17 -9 -24 -8 -7 -15 -19 -15 -26 0
-8 -5 -14 -12 -14 -6 0 -23 -10 -36 -22 -14 -13 -22 -17 -18 -10 8 14 -10 17
-19 2 -3 -5 -11 -10 -18 -10 -6 0 -3 6 8 13 14 11 15 16 5 22 -9 5 -11 4 -6
-3 6 -10 -4 -16 -25 -14 -3 1 -5 -10 -3 -23 3 -28 -8 -32 -26 -10 -18 21 -38
2 -35 -33 2 -26 0 -28 -39 -29 -41 -1 -85 -9 -93 -16 -2 -3 3 -7 12 -11 11 -4
14 -2 9 7 -4 6 -3 8 1 4 12 -10 15 -37 5 -37 -5 0 -12 5 -15 10 -3 6 -14 10
-23 10 -15 0 -15 -2 -2 -11 12 -8 12 -10 -5 -8 -30 2 -66 -12 -58 -23 3 -6 0
-8 -8 -6 -27 10 -47 -10 -45 -45 3 -32 2 -33 -36 -36 -26 -1 -37 2 -33 9 3 6
10 8 15 5 13 -8 41 6 33 18 -3 6 -1 7 5 3 7 -4 12 -2 12 3 0 13 -3 13 -24 5
-9 -3 -13 -10 -10 -15 3 -5 0 -9 -5 -9 -6 0 -11 6 -11 13 0 8 -3 8 -9 -2 -5
-8 -16 -15 -23 -17 -19 -2 -94 -54 -79 -54 7 0 10 -4 6 -9 -3 -5 -1 -12 5 -16
5 -3 10 -1 10 4 0 6 5 11 11 11 6 0 9 -6 7 -12 -6 -16 -78 -19 -95 -5 -14 10
-14 9 -17 -40 -2 -38 -12 -50 -40 -50 -18 0 -24 5 -23 18 1 11 5 19 10 19 4 0
5 4 2 10 -4 6 -13 5 -25 -2 -10 -7 -16 -16 -13 -22 4 -5 -1 -7 -10 -3 -11 4
-14 2 -10 -9 4 -10 0 -15 -8 -14 -38 6 -50 0 -44 -20 5 -15 2 -20 -12 -20 -14
0 -15 2 -4 9 9 6 10 11 2 15 -6 4 -11 3 -11 -2 0 -4 -16 -16 -35 -25 -31 -15
-34 -20 -25 -37 13 -24 2 -27 -14 -4 -10 13 -12 13 -13 -5 -1 -15 4 -21 18
-21 10 0 19 -4 19 -10 0 -5 -6 -7 -13 -4 -7 2 -21 -3 -32 -11 -19 -15 -18 -16
15 -9 19 3 32 3 27 -2 -9 -10 -138 -13 -132 -3 3 4 -3 6 -12 4 -10 -2 -21 -4
-25 -4 -5 -1 -8 -6 -8 -12 0 -8 6 -7 17 2 14 12 14 10 -1 -13 -10 -16 -14 -28
-8 -28 6 0 10 -8 9 -18 -1 -15 -11 -18 -61 -18 -102 -2 -166 -20 -151 -44 7
-11 -24 -22 -38 -14 -9 5 -9 4 -1 -5 16 -17 65 6 57 26 -5 13 -3 13 15 2 19
-12 18 -13 -20 -32 -34 -17 -44 -18 -60 -8 -15 9 -18 10 -13 0 5 -7 3 -10 -6
-7 -20 7 -51 -11 -43 -24 5 -7 1 -9 -9 -5 -10 3 -17 2 -17 -4 0 -6 -6 -7 -13
-3 -7 5 -7 1 3 -12 22 -29 24 -30 17 -11 -4 10 -2 17 6 17 7 0 13 -8 13 -18 0
-13 -9 -17 -34 -18 -18 0 -29 2 -23 5 6 4 7 12 1 21 -8 12 -10 12 -10 -4 0
-12 -5 -16 -14 -13 -8 3 -25 -2 -38 -12 -13 -10 -30 -21 -38 -24 -13 -5 -12
-6 1 -6 9 -1 22 5 28 13 20 24 46 15 47 -16 1 -23 -2 -27 -17 -23 -9 3 -21 0
-25 -6 -5 -8 -2 -10 8 -7 8 4 21 2 29 -3 10 -6 7 -9 -13 -9 -15 0 -30 5 -33
10 -3 6 -11 10 -16 10 -6 0 -8 -4 -5 -9 3 -4 -19 -7 -50 -6 -54 2 -76 -7 -53
-21 8 -5 9 -3 4 7 -5 8 -4 10 0 6 19 -17 13 -35 -11 -33 -15 0 -22 6 -19 14 2
7 -10 1 -29 -13 -18 -14 -36 -22 -39 -18 -4 3 -7 1 -7 -5 0 -6 9 -10 19 -9 11
0 17 5 14 11 -4 5 1 7 11 3 10 -4 15 -3 11 3 -11 17 11 11 29 -9 16 -18 15
-18 -31 -17 -26 1 -53 5 -60 9 -6 4 -14 3 -18 -2 -8 -13 -236 -12 -253 2 -9 7
-16 7 -24 -1 -7 -7 -19 -12 -27 -11 -11 0 -12 2 -3 6 6 2 12 9 12 14 0 4 -7 5
-17 2 -12 -5 -14 -3 -8 8 6 10 5 11 -4 3 -14 -13 -61 -7 -61 8 0 5 4 7 9 3 5
-3 13 -1 16 5 4 6 2 9 -4 8 -5 -2 -16 6 -25 17 -14 19 -14 17 -6 -17 5 -26 5
-36 -3 -31 -6 3 -7 1 -3 -6 4 -6 18 -9 30 -7 13 2 29 0 37 -5 9 -5 -2 -8 -32
-6 -46 2 -46 2 -44 35 1 18 -2 35 -7 38 -4 3 -6 10 -3 15 4 5 2 9 -4 9 -5 0
-11 -5 -13 -11 -2 -7 -9 -5 -17 5 -11 14 -11 16 3 16 14 0 15 4 6 20 -6 11
-15 18 -21 15 -17 -11 -31 5 -18 21 11 14 10 15 -13 18 -7 0 -19 12 -26 26
-13 25 -14 25 -9 2 7 -28 -1 -52 -15 -52 -6 0 -6 6 1 14 9 10 9 16 1 21 -6 4
-8 15 -5 25 4 12 -1 23 -12 30 -13 8 -14 13 -5 16 6 3 12 10 12 15 0 6 -4 8
-8 5 -5 -3 -8 42 -8 99 1 57 3 102 5 99 4 -3 51 55 51 64 0 2 -4 1 -9 -2 -5
-4 -11 -3 -14 1 -2 5 -2 2 0 -6 4 -15 -14 -41 -28 -41 -5 0 -9 7 -9 16 0 8 5
12 10 9 6 -3 10 -1 10 4 0 6 -3 11 -8 11 -10 0 -9 86 2 97 5 4 5 -2 2 -14 -5
-14 -3 -23 3 -23 6 0 11 5 11 11 0 6 7 9 15 5 18 -7 20 10 3 18 -7 4 -3 4 10
2 12 -2 22 0 22 6 0 6 7 7 16 4 10 -4 13 -2 8 6 -4 7 -3 8 5 4 6 -4 9 -12 5
-17 -3 -5 -10 -6 -16 -3 -7 5 -9 1 -5 -9 4 -11 2 -14 -9 -10 -7 3 -14 1 -14
-5 0 -14 24 -9 42 8 15 16 21 53 6 44 -9 -6 -11 10 -2 19 12 11 33 -5 27 -21
-3 -9 3 -6 13 8 22 30 29 56 10 41 -7 -6 -17 -7 -21 -3 -5 4 -5 2 -2 -4 4 -7
-2 -9 -20 -6 -15 3 -23 1 -20 -5 7 -11 -9 -40 -18 -32 -3 4 -1 18 6 32 9 20
18 25 43 24 30 -2 31 0 31 37 0 22 6 41 13 44 8 2 10 -3 6 -15 -5 -17 -5 -17
9 0 11 13 16 15 20 5 3 -10 9 -9 25 5 13 12 22 15 27 8 3 -6 -1 -11 -9 -11 -9
0 -15 -6 -13 -12 1 -7 -4 -12 -10 -10 -7 1 -19 3 -25 5 -7 1 -12 -4 -10 -12 1
-7 -2 -19 -8 -25 -5 -7 -7 -16 -4 -19 11 -11 24 3 24 26 0 15 3 18 8 10 6 -9
17 -4 40 20 17 17 31 35 32 40 0 4 0 12 0 17 0 6 4 10 10 10 5 0 7 -7 3 -16
-3 -9 8 -3 24 12 17 16 28 33 25 38 -2 4 -8 2 -13 -5 -5 -8 -9 -10 -9 -5 0 5
-8 2 -17 -5 -14 -12 -16 -12 -9 1 5 9 2 8 -7 -2 -11 -14 -16 -15 -26 -5 -7 7
-12 8 -11 2 4 -19 0 -35 -9 -35 -4 0 -8 13 -7 28 1 26 5 29 36 29 57 1 60 2
60 23 0 13 5 18 15 14 8 -4 15 -2 15 4 0 5 -9 12 -19 15 -15 4 -21 -1 -24 -22
-4 -19 -4 -16 -3 8 1 30 4 34 33 37 33 4 55 -13 33 -26 -8 -5 -7 -9 4 -13 11
-5 20 1 28 18 6 13 14 22 18 20 9 -6 43 32 46 51 1 8 -1 12 -6 9 -4 -3 -10 2
-14 10 -3 8 -1 15 4 15 6 0 10 8 10 18 0 25 35 55 64 57 14 0 22 -3 20 -7 -3
-4 0 -8 5 -8 6 0 11 4 11 8 0 4 10 8 23 9 20 2 19 0 -6 -13 -15 -8 -32 -12
-37 -9 -6 3 -10 1 -10 -6 0 -6 -7 -17 -17 -24 -9 -7 -13 -17 -8 -24 5 -9 13
-5 26 16 10 15 19 24 19 20 0 -8 15 3 55 39 17 16 23 30 20 51 -3 28 -2 30 30
28 42 -3 54 10 50 52 -3 33 11 39 43 20 7 -5 12 -3 12 3 0 6 4 9 8 6 5 -3 19
8 31 25 12 16 25 28 29 25 4 -2 14 3 22 12 15 17 17 17 -34 -4 -7 -3 -16 2
-19 10 -4 11 0 14 15 11 31 -6 57 11 51 35 -3 12 1 22 11 26 9 3 14 9 11 14
-2 4 11 6 30 4 l35 -2 -3 41 c-2 38 0 42 23 45 19 2 22 1 10 -7 -8 -5 -17 -8
-20 -6 -9 5 -4 -44 5 -52 4 -5 4 -2 0 6 -6 11 -4 13 10 7 15 -5 16 -4 5 9 -17
20 -5 32 15 15 8 -7 15 -8 15 -4 0 5 10 14 23 19 12 6 21 18 21 27 -1 10 10
21 27 27 16 5 29 12 29 15 0 3 -11 1 -24 -4 -13 -5 -27 -6 -30 -3 -6 7 -10
-12 -7 -36 2 -7 -2 -10 -8 -6 -6 3 -11 2 -11 -3 0 -6 -8 -13 -17 -16 -15 -6
-16 -5 -1 11 8 10 14 28 12 41 -3 19 0 22 21 18 19 -4 25 0 25 12 0 15 2 15
10 2 7 -11 10 -11 10 -2 0 10 5 10 20 2 18 -10 24 -7 21 13 0 4 5 7 12 7 15 0
55 40 41 40 -5 0 -1 5 9 11 9 5 17 18 17 28 0 11 7 22 16 25 9 4 14 9 11 13
-4 3 -14 0 -23 -8 -16 -13 -17 -12 -12 9 5 19 4 21 -8 11 -9 -7 -14 -8 -14 -1
0 17 41 26 51 11 7 -11 11 -10 22 5 8 11 17 15 20 9 3 -5 -7 -24 -23 -41 -16
-18 -25 -32 -20 -32 6 0 15 9 22 20 7 11 19 20 26 20 7 0 11 4 8 8 -8 14 38
63 61 65 15 1 20 6 17 21 -3 15 -1 17 8 8 8 -8 18 -6 40 9 15 11 28 26 28 32
0 8 -3 7 -9 -2 -6 -9 -8 -4 -8 16 2 27 4 30 34 27 33 -2 61 23 45 41 -4 5 -1
5 6 1 7 -4 9 -13 5 -19 -4 -7 1 -6 11 2 9 8 21 22 27 32 7 15 6 17 -6 13 -8
-3 -13 -10 -10 -14 3 -5 1 -12 -5 -15 -6 -4 -9 -1 -8 7 2 7 -2 12 -8 11 -6 -1
-11 5 -11 12 0 11 4 12 13 4 8 -7 14 -7 19 1 4 6 14 9 22 6 10 -4 32 9 59 34
24 22 39 40 33 40 -6 0 -23 -13 -39 -28 l-28 -28 1 31 c1 27 5 31 24 29 50 -5
63 5 58 46 l-4 37 43 -2 44 -3 -4 47 c-1 25 0 40 2 33 3 -6 11 -9 17 -5 24 15
44 10 39 -10 -3 -12 -7 -15 -12 -7 -10 15 -21 15 -31 -2 -13 -20 -11 -38 6
-38 8 0 15 5 15 11 0 5 -5 7 -12 3 -7 -4 -8 -3 -4 5 4 6 12 9 17 5 11 -7 80
51 72 60 -4 3 -1 6 6 6 8 0 11 7 7 15 -4 10 1 14 17 12 13 -1 22 4 22 13 0 8
8 14 18 14 9 -1 14 2 11 7 -3 5 0 9 5 9 6 0 11 5 11 11 0 6 -14 1 -30 -11 -17
-12 -28 -26 -25 -31 10 -15 -12 -10 -25 6 -11 13 -10 14 4 9 12 -5 19 0 23 15
3 11 9 19 13 16 4 -2 16 5 26 18 l19 22 -22 -19 c-13 -11 -24 -17 -24 -15 -6
19 -4 47 3 43 4 -3 6 -11 2 -17 -3 -7 1 -4 9 6 10 12 17 14 22 8 3 -6 11 -11
16 -11 6 0 7 5 4 10 -4 6 -3 10 2 9 22 -4 33 3 33 19 0 10 5 23 12 30 7 7 8
12 2 12 -6 0 -15 -5 -21 -11 -9 -9 -12 -7 -10 9 1 21 19 25 45 8 11 -6 11 -11
-1 -24 -8 -10 -18 -27 -21 -39 -4 -13 -13 -22 -19 -20 -7 1 -11 -3 -10 -10 2
-7 -4 -10 -12 -7 -8 4 -15 2 -15 -3 0 -24 17 -19 62 19 27 22 46 43 43 47 -4
3 -13 -3 -21 -13 -8 -11 -14 -15 -14 -9 0 18 38 55 42 41 3 -8 7 -6 11 6 4 9
3 15 -1 12 -4 -2 -14 0 -22 6 -12 8 -11 9 3 4 15 -5 18 -1 15 17 -2 15 5 28
22 39 17 12 20 18 9 18 -8 0 -21 -6 -28 -13 -11 -10 -13 -9 -9 3 3 10 18 15
46 15 39 1 56 -9 31 -17 -7 -2 -6 -8 4 -16 12 -10 17 -10 23 -1 4 8 3 9 -4 5
-14 -8 -15 -4 -6 18 6 15 8 15 20 -1 13 -17 14 -17 7 2 -3 11 -3 17 2 13 11
-11 47 11 39 24 -4 5 0 14 7 19 10 6 11 9 2 9 -7 0 -13 8 -12 18 0 11 2 12 6
4 4 -10 8 -10 19 -1 11 9 18 8 31 -2 9 -8 14 -9 10 -2 -4 6 -2 13 4 15 6 2 12
16 12 31 -1 24 3 27 21 21 17 -6 19 -4 9 7 -10 12 -8 15 7 15 10 0 16 -5 14
-11 -3 -5 3 0 14 13 21 23 38 30 26 10 -4 -7 -3 -8 4 -4 6 4 9 13 6 20 -3 9 1
13 12 13 9 -1 19 5 21 13 3 9 1 11 -4 5 -4 -5 -18 -11 -30 -12 -16 -2 -21 3
-20 17 1 13 5 16 11 10 13 -13 29 -13 21 0 -4 7 3 7 20 0 14 -5 24 -6 21 -1
-6 8 18 23 39 25 18 2 10 31 -9 34 -14 3 -17 0 -13 -12 3 -8 2 -18 -3 -21 -11
-7 -13 25 -4 49 3 9 10 16 16 16 5 0 6 -5 2 -12 -5 -7 -3 -8 6 -3 8 5 18 2 26
-9 8 -11 14 -13 19 -7 3 6 1 11 -4 11 -6 0 -11 4 -11 10 0 5 7 6 17 3 12 -5
14 -3 8 7 -6 10 -4 12 8 7 22 -8 126 94 124 121 -1 14 -7 19 -20 16 -11 -2
-22 -6 -26 -10 -3 -4 2 -5 11 -1 18 6 34 -18 18 -28 -6 -4 -10 -2 -10 4 0 6
-7 8 -15 5 -11 -5 -14 0 -13 18 2 18 8 25 23 25 58 1 64 3 58 19 -4 11 -2 15
7 12 7 -2 14 -12 16 -21 1 -9 -1 -15 -5 -12 -5 2 -17 -2 -27 -10 -13 -10 -14
-14 -4 -12 8 2 12 2 8 0 -5 -3 -8 -10 -8 -16 0 -16 47 32 53 54 3 12 1 17 -6
12 -6 -4 -8 0 -4 10 3 9 2 19 -3 22 -20 13 -9 24 20 20 27 -3 30 0 32 23 0 15
1 36 0 47 -2 18 0 18 11 -3 7 -12 16 -20 20 -16 4 4 2 12 -3 19 -7 9 0 12 26
13 35 1 45 -17 13 -26 -11 -3 -11 -2 1 7 11 8 12 11 2 11 -8 0 -16 -9 -19 -20
-3 -11 -9 -20 -14 -20 -5 0 -9 -5 -9 -12 0 -6 7 -4 18 5 29 26 38 20 12 -8
-26 -27 -39 -25 -41 8 -1 18 -2 18 -6 4 -3 -10 1 -25 9 -34 11 -15 11 -16 -4
-10 -10 3 -17 3 -17 -1 3 -19 -2 -23 -16 -12 -10 9 -15 9 -15 1 0 -6 4 -11 10
-11 5 0 6 -8 3 -17 -4 -10 19 6 50 36 31 30 54 58 51 62 -5 8 11 13 19 7 5 -4
37 29 37 37 0 4 6 13 14 21 8 8 12 23 9 36 -5 21 -4 22 8 6 11 -13 18 -15 29
-7 13 9 12 10 -2 5 -10 -3 -18 -1 -18 4 0 5 15 10 33 12 28 3 32 6 29 31 -2
17 2 27 10 27 9 0 9 3 -2 10 -11 7 -2 9 30 7 38 -1 45 1 42 16 -2 10 3 26 10
35 10 12 10 14 1 8 -7 -4 -13 -2 -13 3 0 6 8 12 18 12 44 3 82 17 82 30 0 6
-4 8 -9 4 -12 -7 -8 42 4 53 4 4 5 1 1 -5 -4 -7 -2 -13 3 -13 6 0 11 5 11 10
0 6 5 10 11 10 5 0 8 -4 5 -9 -3 -5 1 -11 9 -14 9 -4 12 -1 8 9 -3 8 -2 14 3
13 26 -4 43 3 49 22 4 11 9 18 12 15 6 -6 20 5 82 63 25 24 36 38 24 32 -29
-15 -36 -14 -29 4 3 8 1 15 -4 15 -6 0 -10 -4 -10 -9 0 -5 -6 -11 -13 -14 -7
-3 -13 3 -13 12 0 19 46 28 56 11 3 -5 16 -7 28 -3 12 3 19 9 16 14 -3 5 1 9
8 9 21 1 62 36 50 44 -6 3 -26 -7 -45 -24 l-34 -30 1 35 c1 28 3 32 12 20 6
-8 14 -12 17 -8 4 3 1 13 -5 22 -10 13 -10 14 2 4 18 -16 34 -17 24 -1 -5 7
-3 8 6 3 7 -4 16 -2 22 6 8 11 9 10 4 -3 -10 -31 18 -20 62 26 27 28 40 49 36
59 -3 9 -1 25 6 36 6 13 7 21 1 21 -5 0 -13 -9 -16 -21 -4 -11 -9 -18 -11 -16
-13 12 19 52 43 54 19 1 22 -1 12 -8 -11 -7 -11 -9 3 -9 21 0 18 -22 -7 -52
-19 -22 -19 -22 7 1 15 13 25 29 21 34 -4 7 1 8 13 4 18 -7 18 -6 2 7 -10 7
-18 16 -18 20 0 9 24 3 32 -9 5 -6 8 -5 8 3 0 7 4 11 9 8 5 -3 8 2 7 12 0 9 6
17 14 17 9 0 25 10 38 23 12 12 18 22 14 22 -4 0 -14 -6 -21 -12 -10 -11 -11
-10 -6 5 4 10 18 17 34 17 14 1 40 12 56 26 18 15 23 22 11 18 -14 -6 -17 -4
-11 6 5 9 4 11 -4 6 -6 -4 -9 -11 -6 -16 3 -5 1 -11 -4 -15 -6 -3 -9 7 -8 24
2 23 6 29 22 28 11 -1 22 -5 25 -9 3 -4 17 2 30 14 14 11 19 17 13 13 -9 -4
-12 3 -10 26 2 29 6 33 34 34 18 1 30 -2 27 -7 -3 -5 -9 -6 -14 -3 -5 3 -12 0
-16 -6 -4 -8 -9 -7 -15 2 -6 10 -8 8 -7 -8 2 -20 28 -22 28 -1 0 5 4 7 9 3 6
-3 21 11 35 32 15 21 26 32 26 25 0 -7 5 -10 11 -6 8 4 7 9 -2 15 -9 5 -18 3
-25 -6 -8 -11 -11 -8 -11 15 0 17 4 30 9 30 5 0 7 -4 4 -9 -3 -5 3 -11 14 -14
22 -6 28 8 8 16 -7 3 1 4 18 2 29 -3 30 -2 30 38 0 35 3 41 24 44 19 2 22 1
10 -7 -8 -5 -13 -10 -10 -11 3 0 15 -2 28 -5 17 -3 35 7 67 38 24 23 40 45 35
48 -6 4 -6 19 0 39 5 19 7 38 3 44 -4 6 -1 7 6 3 7 -5 10 -15 7 -23 -4 -8 -1
-11 5 -7 6 3 8 15 5 27 -4 15 -2 17 7 8 14 -14 30 -15 22 -1 -3 6 1 7 9 4 11
-4 16 0 16 14 0 12 5 24 12 28 8 5 9 -2 5 -27 -5 -24 -4 -30 3 -19 5 8 10 11
10 6 0 -12 80 66 80 78 0 5 -8 2 -18 -7 -10 -10 -24 -17 -31 -17 -7 0 -9 -5
-6 -10 3 -6 -1 -18 -10 -27 -16 -15 -17 -15 -15 3 5 41 0 53 -17 48 -15 -5
-15 -4 1 6 14 10 20 10 26 0 9 -15 46 -8 42 9 -1 7 2 10 8 6 6 -3 10 4 10 17
0 18 -2 20 -10 8 -14 -22 -11 1 3 25 10 16 23 20 69 20 31 0 55 3 53 6 -2 3 5
13 16 22 11 10 17 22 14 27 -3 6 -1 10 5 10 7 0 9 -9 5 -22 -5 -18 -4 -20 4
-8 6 8 8 22 6 30 -4 11 3 16 21 18 38 3 43 9 37 47 -5 32 -4 34 26 33 26 -1
28 -3 14 -13 -10 -8 -12 -14 -5 -19 13 -8 42 31 42 56 0 10 5 18 11 18 5 0 7
-4 4 -10 -10 -16 1 -11 16 7 10 11 10 14 2 9 -8 -4 -13 -2 -13 4 0 6 -4 9 -8
6 -5 -3 -9 1 -9 7 0 7 9 14 19 14 10 1 16 -2 14 -6 -3 -4 5 -7 17 -6 12 1 23
9 25 18 2 10 8 14 14 11 6 -4 6 1 -1 14 -14 26 -14 32 -1 32 6 0 14 -8 18 -17
6 -16 9 -16 32 6 14 13 19 20 11 16 -10 -5 -12 -4 -7 4 4 6 11 9 16 6 5 -3 18
3 28 13 15 15 16 21 6 30 -20 18 -28 14 -13 -5 12 -14 12 -16 0 -8 -10 6 -12
4 -6 -6 5 -8 4 -11 -1 -6 -5 5 -9 21 -10 36 -1 25 2 28 32 26 18 0 37 -5 41
-10 4 -4 3 -6 -2 -2 -15 8 -40 -8 -26 -17 6 -4 11 -2 11 3 0 6 6 10 13 8 6 -1
11 6 9 15 -2 13 2 16 15 12 17 -5 17 -5 0 9 -16 13 -16 15 -2 21 9 3 13 2 10
-4 -4 -6 1 -7 11 -3 13 5 15 3 9 -7 -5 -9 -4 -11 4 -6 16 10 -7 39 -25 32 -18
-7 -29 13 -15 27 8 8 16 5 31 -11 11 -12 27 -19 35 -16 21 8 19 17 -2 10 -15
-5 -14 -3 2 10 11 8 19 20 18 25 -4 14 15 11 19 -3 3 -8 9 -4 17 10 13 24 6
46 -11 36 -7 -4 -6 -9 2 -14 9 -5 9 -9 1 -14 -6 -4 -11 -2 -11 3 0 6 -5 10
-12 8 -7 -1 -13 7 -14 19 -1 15 4 20 20 19 12 -1 30 1 42 6 24 8 53 -4 35 -15
-6 -4 -11 -2 -11 4 0 6 -5 8 -11 4 -8 -4 -7 -9 2 -15 10 -6 11 -9 2 -9 -7 0
-13 -5 -13 -12 0 -6 13 0 28 15 26 25 41 64 20 51 -9 -6 -11 10 -2 19 10 10
33 -4 28 -18 -3 -7 18 9 48 36 29 27 52 54 51 59 -2 6 2 11 7 11 6 1 15 3 20
4 6 1 13 3 16 4 3 0 2 6 -1 11 -5 8 -11 7 -21 -1 -9 -7 -14 -8 -13 -3 1 5 0
11 -1 14 -1 3 -3 17 -4 31 -1 18 3 25 12 22 7 -3 10 -13 7 -24 -4 -11 -2 -19
4 -19 6 0 11 7 11 15 0 8 5 15 11 15 6 0 8 -9 3 -22 -6 -19 -2 -18 35 11 43
35 51 44 26 35 -8 -4 -15 -10 -15 -16 0 -5 -4 -7 -9 -4 -5 4 -12 0 -14 -6 -3
-8 -6 -5 -6 5 -1 13 -6 17 -20 12 -11 -3 -21 0 -24 9 -4 11 6 13 54 12 52 -2
64 1 89 23 23 20 27 30 19 41 -8 12 -9 12 -7 -4 2 -10 -1 -21 -7 -22 -5 -2
-11 7 -11 19 -2 21 2 24 32 22 34 -3 61 16 50 34 -4 6 2 8 12 6 12 -2 18 3 19
17 2 16 -1 18 -10 9 -15 -15 -24 -14 -24 3 0 9 13 13 41 12 l41 -1 -3 39 c-2
31 0 37 13 33 8 -4 15 -1 15 5 0 7 6 4 13 -5 11 -16 14 -15 40 14 19 22 22 29
10 25 -17 -6 -17 -5 0 8 20 15 22 27 5 23 -8 -1 -13 7 -12 20 1 13 -3 20 -9
16 -6 -4 -8 0 -5 9 4 8 2 21 -4 29 -8 11 -9 10 -4 -3 3 -10 1 -18 -4 -18 -5 0
-7 -8 -4 -17 5 -15 4 -16 -5 -3 -5 8 -8 26 -5 40 4 19 3 22 -5 10 -5 -8 -13
-12 -16 -8 -4 3 3 16 14 27 20 20 17 28 -5 13 -19 -13 -22 -37 -8 -57 18 -25
18 -31 -3 -31 -16 0 -18 11 -19 81 -1 44 3 86 8 93 7 8 7 12 -1 12 -7 0 -11 6
-9 13 1 6 -2 22 -6 35 -6 17 -11 20 -21 12 -9 -7 -16 -6 -25 5 -7 8 -16 12
-21 9 -14 -9 -10 6 6 22 8 9 15 21 14 27 0 9 -2 8 -6 0 -2 -7 -8 -13 -13 -13
-10 0 -7 51 4 69 7 10 6 12 -4 6 -9 -5 -10 -2 -6 13 6 18 3 20 -36 18 -43 -3
-43 -3 -44 33 -1 23 0 27 3 11 l5 -25 14 30 c8 17 11 20 9 9 -6 -24 20 -58 37
-48 6 4 2 11 -10 18 -16 8 -18 15 -11 29 7 12 7 17 -1 17 -6 0 -8 5 -4 11 5 8
1 8 -13 0 -26 -13 -34 -5 -27 26 5 18 -2 31 -30 58 -20 18 -36 37 -36 42 0 4
4 1 10 -7 5 -8 12 -12 15 -8 3 3 -1 12 -9 21 -13 13 -13 17 -2 26 11 9 15 6
20 -14 4 -14 11 -25 17 -25 8 0 8 4 0 13 -6 8 -8 16 -5 20 3 3 -3 12 -15 19
-12 7 -21 16 -21 21 0 4 4 6 9 3 5 -4 7 4 4 15 -6 21 -6 21 -19 -2 -16 -29
-18 -42 -4 -34 13 8 13 2 -4 -30 -13 -26 -14 -21 -12 60 3 100 -6 118 -54 108
-23 -4 -30 -2 -30 10 0 20 16 31 25 17 8 -13 25 -13 25 1 0 5 -4 7 -10 4 -5
-3 -10 -1 -10 4 0 17 18 13 29 -6 6 -10 11 -12 11 -5 0 6 -9 21 -20 32 -21 21
-27 35 -10 25 6 -4 3 9 -6 29 -10 19 -21 33 -26 30 -4 -3 -8 0 -8 5 0 6 -9 11
-20 11 -20 0 -27 14 -12 23 4 2 1 2 -5 1 -7 -2 -13 5 -13 15 0 26 29 22 36 -6
4 -13 10 -23 15 -23 5 0 1 16 -8 35 -12 24 -19 31 -25 23 -7 -12 -12 -3 -9 15
1 5 -4 7 -11 5 -9 -3 -12 -19 -9 -49 1 -24 -1 -40 -5 -36 -11 11 -14 87 -3 87
5 0 9 5 9 11 0 5 -3 8 -7 6 -5 -3 -9 25 -11 62 -1 36 -7 68 -12 69 -6 2 -7 9
-3 15 5 9 3 9 -8 0 -8 -7 -25 -11 -37 -10 -20 1 -22 6 -21 49 2 46 12 62 22
36 3 -8 6 -6 6 5 1 9 -6 17 -15 17 -12 0 -15 7 -12 25 3 14 1 25 -3 25 -5 0
-9 6 -9 14 0 7 -6 19 -14 25 -11 9 -15 8 -20 -5 -4 -9 -4 -19 -1 -22 3 -3 5 0
5 7 0 7 5 9 10 6 16 -10 12 -21 -8 -22 -10 -1 -24 8 -31 19 -11 18 -11 20 4
14 13 -4 16 -2 11 9 -3 8 -1 25 5 37 9 21 9 21 4 -4 -4 -21 -2 -26 9 -22 11 4
12 12 4 35 -5 16 -12 29 -14 29 -3 0 -11 -15 -19 -32 l-15 -33 5 39 c6 56 -7
75 -48 71 -31 -3 -34 -1 -34 26 0 21 3 26 9 17 6 -8 8 0 5 23 -4 24 0 44 9 58
17 24 7 29 -11 5 -9 -13 -11 -7 -10 32 2 71 -8 86 -53 79 -26 -5 -33 -4 -22 4
8 5 18 7 24 4 5 -3 6 -1 2 5 -3 6 0 17 9 25 13 13 13 16 -1 27 -12 11 -13 10
-9 -2 3 -8 -2 -22 -12 -30 -17 -16 -18 -15 -20 9 -1 19 1 22 8 12 7 -12 9 -11
9 2 0 9 -3 19 -7 23 -7 6 -11 36 -16 98 -1 9 -5 14 -10 11 -5 -3 -6 -10 -4
-17 3 -8 -7 -12 -27 -13 l-33 -1 -1 54 c0 31 4 52 9 48 5 -3 7 -23 4 -45 -3
-24 -1 -38 4 -34 6 3 10 13 10 23 1 38 5 51 13 46 5 -3 6 -9 3 -14 -3 -4 3
-14 13 -21 15 -13 16 -12 3 4 -7 9 -10 17 -7 17 4 0 2 9 -4 20 -6 11 -14 19
-18 19 -27 -4 -32 3 -27 40 3 28 0 45 -11 55 -8 9 -13 19 -11 23 3 5 0 17 -4
28 -9 20 -9 20 -6 0 3 -15 -2 -21 -14 -22 -22 -2 -32 20 -34 74 -1 37 1 41 14
30 13 -10 12 -7 -2 15 -9 15 -17 35 -17 44 0 11 -4 14 -12 7 -7 -5 -26 -9 -43
-10 -16 -1 -22 1 -12 3 12 4 15 12 11 30 -5 19 -3 23 10 18 9 -3 16 -1 16 5 0
6 -5 11 -11 11 -8 0 -7 6 2 18 13 15 12 16 -4 3 -9 -7 -21 -28 -26 -45 -7 -27
-8 -23 -8 27 0 39 4 57 12 57 8 0 11 8 8 19 -3 13 -12 18 -24 16 -10 -2 -25 3
-33 10 -12 13 -15 12 -20 0 -8 -22 -22 -18 -23 7 -2 19 0 20 8 8 8 -11 9 -7 3
15 -4 17 -8 64 -10 105 -1 41 -3 81 -3 88 -1 8 -13 11 -41 7 l-39 -5 4 85 c2
54 0 85 -7 85 -5 0 -7 7 -4 16 3 8 2 12 -4 9 -6 -3 -13 1 -16 9 -5 12 -9 13
-25 3 -10 -6 -19 -15 -19 -20 0 -4 4 -5 10 -2 6 3 10 1 10 -4 0 -6 5 -11 11
-11 8 0 8 5 -1 15 -8 10 -9 15 -2 15 6 0 16 -8 21 -18 9 -16 6 -19 -23 -19
-31 0 -33 2 -31 33 0 18 5 37 10 41 4 4 5 2 1 -4 -4 -7 -2 -13 3 -13 6 0 11 8
11 18 0 14 2 15 10 2 8 -13 10 -13 10 2 0 9 -5 20 -10 23 -6 4 -7 11 -4 17 4
7 2 8 -4 4 -7 -4 -12 -14 -12 -22 0 -8 -4 -14 -9 -14 -5 0 -8 11 -8 25 0 13 3
22 8 19 5 -3 9 -1 9 5 0 10 -22 18 -69 23 -27 3 -42 28 -17 28 9 0 16 -4 16
-10 0 -5 9 -10 20 -10 11 0 20 7 20 16 0 8 5 12 11 8 8 -5 8 -1 0 14 -6 12
-19 26 -29 31 -10 7 -12 11 -4 11 7 0 10 5 6 11 -4 7 -9 8 -17 0 -7 -7 -5 -18
6 -35 11 -17 13 -27 6 -31 -6 -4 -11 -5 -12 -4 -35 67 -42 108 -22 126 4 5 5
1 0 -7 -5 -8 -5 -12 0 -8 4 4 15 2 24 -5 14 -10 14 -10 4 4 -7 9 -9 19 -5 23
4 4 0 6 -8 4 -10 -2 -15 4 -14 19 0 12 -3 20 -7 18 -3 -3 -6 6 -6 20 0 13 -10
39 -22 57 -15 24 -21 28 -21 15 0 -11 -5 -15 -11 -11 -8 5 -8 1 0 -14 14 -27
14 -28 -6 -28 -16 0 -18 11 -18 78 0 43 -4 78 -9 78 -5 0 -18 8 -28 17 -18 16
-18 16 -12 -2 5 -13 4 -16 -4 -11 -7 4 -13 17 -13 29 -1 17 -3 15 -9 -8 -4
-17 -6 17 -5 80 1 117 -6 148 -31 138 -12 -4 -14 0 -9 23 7 29 7 29 -7 5 -24
-44 -33 -34 -33 36 1 90 -22 160 -57 175 -24 11 -25 14 -12 32 19 28 17 46 -2
21 -16 -20 -16 -20 -9 3 7 23 7 23 -60 26 -11 1 -21 9 -23 21 l-4 20 16 -20
c18 -24 21 -13 4 18 -6 12 -13 32 -14 45 -2 21 -1 22 14 10 9 -8 13 -8 9 -1
-4 6 -3 22 2 35 7 19 6 25 -5 25 -10 0 -12 -7 -8 -20 4 -13 2 -17 -5 -12 -7 4
-10 22 -7 45 3 21 1 51 -4 67 -4 15 -9 23 -9 18 -1 -14 -39 -16 -47 -3 -4 6 3
18 14 27 l22 16 -27 13 c-20 9 -24 9 -15 0 14 -14 16 -32 3 -24 -5 4 -8 -5 -6
-19 1 -14 -1 -25 -6 -25 -12 0 -4 73 10 95 10 17 12 17 7 2 -4 -11 -2 -16 5
-12 14 9 6 30 -12 30 -7 0 -15 9 -16 20 -2 11 0 14 3 8 3 -7 10 -13 16 -13 6
0 4 8 -5 18 -10 10 -14 22 -10 27 5 4 2 4 -6 0 -10 -6 -13 -4 -9 7 4 8 2 21
-4 29 -8 11 -9 10 -4 -3 3 -10 1 -18 -4 -18 -5 0 -7 -8 -4 -17 5 -15 4 -16 -5
-3 -6 8 -8 23 -5 32 4 14 1 16 -12 11 -14 -5 -15 -3 -5 15 6 11 16 23 23 26 7
3 3 3 -9 0 -17 -4 -21 -2 -17 9 3 8 1 17 -3 20 -14 9 -10 -76 5 -91 17 -19 15
-28 -5 -28 -16 0 -18 11 -19 81 -1 44 3 86 8 93 7 8 7 12 -1 12 -7 0 -11 6 -9
13 1 6 -2 22 -6 35 -6 17 -11 20 -21 12 -9 -7 -16 -6 -25 5 -7 8 -16 12 -21 9
-14 -9 -10 6 6 22 15 15 14 59 -1 73 -4 3 -4 -3 0 -14 6 -16 5 -17 -5 -8 -10
10 -9 16 2 29 11 14 11 16 0 10 -10 -7 -12 -4 -7 12 6 18 3 20 -36 18 -43 -3
-43 -3 -45 33 -1 30 0 32 6 11 l7 -25 7 28 c4 15 5 33 1 39 -4 5 0 15 7 20 12
8 11 9 -1 5 -8 -2 -14 -12 -13 -21 0 -10 -2 -15 -5 -11 -4 3 -7 22 -8 41 0 22
2 30 7 22 11 -17 11 -5 0 24 -5 13 -10 28 -11 33 -1 6 -6 16 -12 23 -6 6 -7
12 -2 12 5 0 -2 7 -14 16 -21 15 -32 42 -12 29 6 -3 7 1 3 10 -8 23 -24 -3
-18 -30 3 -11 2 -14 -1 -6 -4 9 -9 10 -13 2 -4 -5 -8 8 -8 31 -1 23 3 49 10
57 9 12 9 13 0 7 -9 -5 -13 0 -13 17 0 25 -23 42 -41 31 -5 -3 -9 0 -9 5 0 6
-5 11 -11 11 -5 0 -7 -4 -4 -10 3 -5 2 -10 -3 -10 -5 0 -8 33 -8 74 1 63 3 71
13 53 7 -14 8 -28 2 -39 -9 -17 4 -27 14 -10 2 4 2 0 -1 -8 -10 -34 -6 -45 16
-48 12 -2 22 -1 22 3 0 17 -60 135 -69 135 -6 0 -11 5 -11 11 0 5 5 7 11 3 6
-3 3 9 -7 27 -10 19 -18 43 -19 53 -1 11 -7 22 -14 24 -6 2 -9 8 -5 13 3 5 3
9 0 9 -14 0 -14 -20 -1 -34 12 -12 14 -20 6 -33 -10 -17 -10 -17 -11 0 0 10
-4 16 -10 12 -13 -8 0 -37 15 -31 7 2 18 -1 24 -7 13 -13 15 -31 2 -22 -5 3
-13 0 -16 -5 -5 -7 -12 -5 -20 7 -13 16 -13 16 -8 -7 3 -15 2 -22 -3 -17 -12
11 -13 87 -1 87 5 0 6 8 2 18 -4 9 -9 40 -11 67 -4 60 -22 107 -37 98 -6 -4
-8 0 -4 11 4 9 3 15 -2 11 -5 -3 -11 1 -14 7 -2 7 -8 10 -12 6 -10 -10 30 -59
41 -50 5 4 5 3 1 -3 -4 -5 -18 -10 -31 -12 -22 -3 -23 -1 -21 76 1 62 -2 84
-16 102 -10 13 -21 20 -25 16 -4 -4 1 -13 11 -21 18 -13 17 -13 -9 -13 -24 0
-27 3 -21 21 4 11 8 28 9 36 1 9 6 22 11 29 6 10 4 12 -5 6 -10 -6 -12 -4 -7
9 5 11 3 15 -5 10 -8 -5 -9 -2 -5 10 4 11 3 15 -4 11 -6 -4 -7 -14 -4 -24 14
-33 14 -52 0 -84 -12 -26 -14 -28 -15 -10 0 12 5 25 10 28 6 3 10 11 10 16 0
6 -4 8 -9 5 -9 -6 -8 65 3 82 3 5 -10 29 -29 52 -19 23 -35 37 -35 31 0 -6 14
-25 30 -43 38 -39 38 -45 0 -18 -16 12 -34 36 -40 53 -10 32 -10 32 -5 -7 2
-22 10 -43 17 -47 8 -5 6 -8 -8 -7 -19 2 -21 8 -19 48 1 25 5 54 10 64 5 12 5
17 -2 13 -6 -4 -9 12 -8 42 2 26 -4 60 -11 77 -8 16 -12 21 -10 12 5 -18 -15
-15 -43 6 -12 9 -13 7 -7 -9 4 -11 4 -16 -1 -12 -12 12 -12 66 0 74 6 3 7 1 3
-6 -11 -17 22 -47 37 -32 8 8 8 14 -1 23 -9 9 -12 9 -12 -2 0 -19 -13 -3 -17
21 -2 11 1 18 7 14 5 -3 5 7 -1 25 -11 31 -24 43 -15 13 3 -10 2 -24 -3 -32
-6 -9 -8 3 -7 32 1 26 -3 43 -8 39 -5 -3 -7 2 -3 11 4 11 2 14 -9 10 -7 -3
-14 0 -14 6 0 6 5 11 12 11 6 0 3 9 -7 20 -14 16 -20 17 -27 8 -6 -10 -8 -10
-8 -1 0 7 8 17 18 22 9 6 15 11 12 11 -37 8 -38 8 -36 -35 2 -47 16 -75 16
-32 0 15 5 27 11 27 6 0 8 -7 5 -16 -4 -9 1 -25 9 -34 15 -16 13 -17 -17 -16
l-33 1 -2 87 c0 49 3 86 7 83 12 -7 -13 57 -27 73 -8 8 -13 9 -13 3 0 -6 -10
-10 -23 -9 -20 2 -22 7 -23 62 0 34 1 55 4 48 2 -6 8 -12 14 -12 5 0 0 15 -11
33 -11 19 -17 38 -13 42 4 5 1 5 -7 0 -10 -6 -11 -2 -6 16 7 21 6 22 -9 10 -9
-7 -16 -20 -16 -27 0 -8 -9 -14 -22 -13 -17 1 -19 2 -5 6 9 2 17 12 17 21 0
13 -2 14 -9 3 -14 -21 -20 0 -18 62 1 32 3 53 5 47 2 -5 8 -10 13 -10 6 0 5 8
-1 20 -8 14 -18 19 -41 16 -24 -3 -33 1 -36 15 -3 10 -9 16 -13 14 -10 -6 -7
42 3 52 10 9 8 25 -2 19 -5 -3 -8 5 -8 19 0 14 3 22 8 20 15 -10 20 15 7 37
-6 13 -13 29 -14 37 -2 8 -10 19 -20 24 -13 8 -14 11 -3 18 11 7 10 9 -5 9
-13 0 -16 5 -12 16 4 10 -1 25 -10 35 -9 10 -13 23 -8 30 4 7 3 10 -4 5 -14
-8 -8 -71 8 -91 7 -9 11 -18 8 -20 -14 -15 -33 34 -34 87 0 33 3 53 8 46 5 -9
8 -9 8 -1 0 6 -7 19 -16 27 -8 9 -12 21 -8 27 4 7 3 9 -4 5 -6 -3 -15 2 -21
11 -6 10 -11 14 -11 8 0 -5 -5 -3 -11 5 -9 13 -10 12 -5 -2 3 -10 1 -18 -4
-18 -6 0 -10 -5 -10 -12 0 -9 3 -9 12 0 18 18 32 14 17 -5 -12 -15 -12 -16 4
-10 9 3 17 2 17 -3 0 -5 -13 -8 -28 -7 -25 2 -29 6 -28 34 1 42 19 61 41 43
20 -16 19 -3 -1 17 -13 14 -17 14 -25 2 -10 -16 -10 -15 -14 21 -2 14 2 34 7
45 9 16 8 18 -3 8 -19 -19 -33 -16 -15 2 17 17 12 41 -10 49 -10 4 -13 13 -9
26 3 10 0 30 -7 42 -7 13 -11 26 -10 30 1 5 -1 7 -6 7 -19 -3 -28 3 -28 20 0
11 4 17 9 14 5 -3 4 4 -2 16 -7 11 -22 22 -34 23 -12 0 -29 1 -37 2 -10 1 -15
13 -16 39 -1 44 8 54 30 31 18 -18 21 -34 6 -34 -5 0 -10 6 -10 14 0 29 -15
23 -16 -6 -1 -17 2 -24 6 -18 6 9 10 9 16 -1 5 -8 17 -15 26 -15 16 -2 15 2
-3 37 -10 22 -24 39 -29 39 -5 0 -8 6 -6 13 1 6 1 9 -1 5 -10 -18 -19 -5 -20
30 0 20 1 27 4 15 2 -13 8 -23 13 -23 5 0 4 13 -1 30 -6 17 -16 27 -22 23 -7
-4 -8 0 -4 11 4 9 3 15 -2 11 -6 -3 -14 3 -20 12 -6 11 -11 13 -11 5 0 -7 7
-24 16 -37 18 -28 8 -34 -12 -7 -7 9 -14 34 -14 55 0 23 -6 40 -14 43 -8 3
-13 16 -12 29 0 13 4 21 9 18 4 -2 7 0 7 6 0 5 -5 13 -10 16 -6 4 -10 12 -8
18 3 19 -12 57 -23 57 -6 0 -8 -4 -5 -8 3 -5 -7 -8 -22 -8 -24 0 -27 3 -27 37
0 20 4 35 9 31 6 -3 6 3 2 14 -20 47 -15 55 8 14 14 -25 25 -40 25 -35 1 6
-15 37 -35 70 -19 33 -33 52 -30 42 4 -11 2 -16 -5 -11 -6 3 -9 21 -7 39 2 19
-1 32 -7 32 -7 0 -10 -11 -7 -25 3 -17 1 -23 -7 -18 -6 4 -9 20 -6 37 3 16 1
29 -4 29 -5 0 -12 -15 -14 -32 -4 -27 -4 -25 -3 7 0 23 6 40 13 40 6 0 15 3
19 7 3 4 -3 6 -14 3 -18 -3 -21 1 -20 21 1 13 7 24 12 24 5 0 6 3 3 6 -4 4
-13 2 -20 -5 -15 -12 -47 -5 -47 11 0 5 8 7 19 3 22 -7 37 21 21 40 -5 6 -8
14 -5 16 2 3 -5 5 -15 6 -25 2 -25 -1 3 -28 l22 -22 -22 6 c-13 3 -23 2 -23
-2 0 -4 -6 -8 -13 -8 -9 0 -13 19 -14 64 0 35 3 63 9 63 5 0 7 -4 4 -9 -3 -4
3 -18 13 -30 20 -23 41 -30 22 -8 -6 8 -7 18 -3 22 4 5 1 5 -7 0 -11 -6 -13
-3 -9 13 7 24 -17 51 -25 30 -5 -11 -12 53 -8 75 0 4 -4 7 -10 7 -5 0 -7 6 -4
13 4 6 -2 3 -12 -8 -37 -41 -48 -27 -47 58 0 68 -2 77 -18 77 -10 0 -18 6 -19
13 0 10 -2 10 -6 0 -7 -20 -23 -15 -16 5 6 15 5 15 -10 3 -16 -12 -17 -9 -15
37 1 28 6 46 10 39 4 -6 5 -23 2 -37 -5 -23 -4 -24 7 -5 7 11 17 23 22 26 5 4
5 14 -2 26 -5 11 -8 22 -6 25 3 3 -2 5 -10 5 -8 0 -15 -5 -15 -10 0 -6 -2 -8
-5 -6 -2 3 -1 20 4 37 6 20 6 32 0 32 -5 0 -9 11 -9 25 0 16 -6 25 -16 25 -11
0 -15 6 -12 18 3 11 0 29 -7 42 -12 22 -13 21 -11 -15 1 -23 -4 -39 -12 -41
-7 -1 -8 0 -2 3 16 8 12 26 -5 19 -17 -6 -21 9 -5 19 6 3 10 15 10 25 0 10 5
20 12 22 7 3 5 7 -6 11 -10 4 -15 3 -11 -3 3 -6 1 -10 -4 -10 -13 0 -14 10 -2
29 6 9 4 19 -5 29 -9 8 -12 22 -9 30 5 12 1 12 -15 -3 -23 -21 -26 -14 -5 18
10 16 11 27 4 37 -9 13 -10 13 -8 0 1 -8 1 -22 0 -30 -1 -13 -2 -13 -11 0 -8
12 -10 10 -10 -11 0 -15 -6 -26 -13 -26 -10 0 -14 17 -16 55 -2 45 0 54 13 49
21 -8 30 9 16 30 -9 15 -10 14 -6 -7 3 -14 3 -19 0 -12 -3 6 -9 10 -13 8 -4
-3 -6 8 -5 23 1 21 -7 35 -32 55 -19 15 -34 22 -34 17 0 -5 5 -13 10 -16 6 -3
10 -10 10 -16 0 -5 -8 -4 -18 2 -19 12 -28 79 -11 79 5 0 9 10 10 23 0 21 1
21 9 2 8 -19 9 -19 9 3 1 12 -4 22 -9 22 -6 0 -14 -10 -19 -22 -6 -17 -9 -8
-9 36 -1 56 -14 82 -34 69 -5 -2 -16 1 -25 9 -12 9 -15 9 -9 1 4 -7 3 -13 -1
-13 -5 0 -9 18 -10 40 -1 22 2 38 7 35 5 -3 7 -12 4 -19 -8 -20 8 -26 29 -11
11 8 14 16 8 19 -5 4 -15 -2 -21 -11 -7 -12 -10 -13 -7 -3 17 59 18 65 8 50
-13 -18 -26 -6 -26 25 -1 20 2 22 15 13 8 -6 3 1 -12 16 -27 26 -39 54 -18 41
6 -3 7 1 3 10 -4 12 -8 13 -13 5 -4 -7 -10 -8 -13 -3 -3 5 -2 15 3 23 11 17
-3 46 -27 54 -17 6 -17 5 0 -8 21 -16 22 -30 1 -22 -8 3 -12 2 -9 -4 3 -6 2
-10 -2 -10 -5 0 -9 20 -9 44 -1 36 2 43 15 38 13 -5 13 -3 -1 12 -10 9 -18 26
-18 36 0 29 -22 70 -37 70 -7 0 -13 -4 -13 -10 0 -5 3 -9 8 -7 4 1 7 -2 7 -8
0 -5 -7 -10 -17 -10 -13 -1 -14 3 -7 22 13 34 11 43 -6 37 -19 -8 -19 5 0 20
11 8 12 15 4 26 -9 12 -10 11 -4 -5 5 -16 4 -17 -6 -8 -10 10 -9 16 2 29 11
14 11 16 0 10 -10 -7 -12 -3 -6 13 6 19 3 21 -20 17 -26 -5 -26 -4 -11 19 24
36 19 43 -6 9 -27 -34 -44 -40 -44 -14 0 10 4 16 8 14 4 -3 8 0 8 5 0 6 -4 11
-8 11 -4 0 -8 23 -9 51 0 34 2 47 8 38 7 -10 8 -8 6 8 -2 12 -7 26 -12 30 -4
5 -5 2 -1 -4 4 -7 2 -13 -3 -13 -6 0 -11 9 -11 20 0 11 -5 20 -10 20 -19 0
-59 54 -47 62 9 6 8 11 -3 18 -8 5 -10 10 -4 10 6 0 17 -7 24 -15 9 -11 9 -15
1 -15 -7 0 -9 -5 -5 -11 5 -8 10 -6 16 6 7 11 6 21 -1 28 -6 6 -11 23 -11 38
0 15 -5 31 -10 34 -7 4 -9 -3 -6 -19 3 -14 1 -28 -3 -30 -4 -3 -7 16 -6 43 1
30 -4 55 -13 67 -9 12 -13 13 -10 4 4 -11 -2 -15 -23 -15 -18 -1 -29 5 -32 17
-3 10 -1 18 4 18 5 0 9 -5 9 -11 0 -6 6 -9 13 -6 16 5 17 85 0 91 -8 4 -11 17
-8 38 4 23 3 27 -3 14 -8 -19 -9 -19 -16 -1 -3 10 -3 21 2 24 12 8 22 51 12
51 -5 0 -10 -8 -12 -17 -2 -10 -4 11 -4 47 -1 37 2 54 6 40 5 -20 7 -22 12 -9
4 9 0 26 -8 38 -9 13 -11 21 -4 21 8 0 8 6 0 20 -7 14 -7 20 0 20 5 0 10 -6
10 -14 0 -8 11 -13 27 -13 l26 0 -24 13 -24 14 27 -4 c22 -4 28 1 38 25 15 34
68 104 80 104 5 0 12 4 17 9 5 5 3 6 -4 2 -7 -4 -13 -2 -13 4 0 6 -11 5 -30
-5 -16 -9 -30 -20 -30 -26 0 -8 5 -7 15 1 10 8 15 9 15 2 0 -6 -9 -16 -19 -22
-16 -8 -20 -6 -24 13 -2 12 -3 6 -2 -14 2 -20 -1 -42 -5 -49 -4 -7 -7 16 -6
50 1 44 5 65 15 68 8 3 11 0 7 -7 -4 -6 -2 -11 3 -11 6 0 11 4 11 8 0 5 18 7
40 5 21 -1 50 1 64 7 20 7 24 14 20 39 -3 16 0 32 7 36 8 5 9 1 5 -13 -8 -25
10 -38 29 -22 12 10 15 10 15 -1 0 -9 3 -10 8 -3 4 6 21 16 37 23 l30 11 -30
-6 c-16 -4 -37 -7 -45 -7 -8 0 -21 -4 -27 -10 -9 -6 -13 -6 -13 2 0 6 5 11 11
11 5 0 8 3 6 8 -3 4 77 7 177 7 175 1 182 0 184 -19 0 -11 -4 -27 -11 -35 -8
-10 -8 -13 -1 -8 7 4 14 1 17 -7 3 -8 14 -12 25 -10 10 3 24 -2 31 -10 9 -11
10 -16 2 -16 -6 0 -11 5 -11 10 0 6 -5 10 -12 10 -6 0 -3 -9 8 -21 10 -11 20
-16 22 -10 2 7 10 8 22 1 11 -6 17 -17 14 -25 -4 -8 -2 -15 2 -16 5 0 20 -2
34 -5 18 -3 21 -2 10 5 -8 5 -17 8 -21 5 -4 -2 -10 0 -14 6 -3 5 -1 10 5 10
18 0 0 20 -26 30 -13 5 -24 14 -24 20 0 12 17 13 22 2 2 -5 17 -15 33 -23 17
-8 30 -19 30 -24 1 -14 24 -52 25 -40 0 14 60 16 60 2 0 -6 -3 -7 -7 -4 -3 4
-13 1 -21 -6 -13 -10 -14 -9 -7 3 5 9 4 12 -1 7 -12 -11 -11 -50 1 -59 16 -12
30 -9 17 4 -16 16 -15 25 6 33 13 5 14 3 5 -6 -18 -18 15 -48 49 -44 27 3 46
-19 27 -31 -6 -4 -6 -12 3 -22 10 -14 10 -15 -2 -4 -8 6 -18 12 -23 12 -9 0 5
-15 37 -40 18 -13 19 -13 12 0 -4 8 0 6 8 -4 14 -18 20 -15 17 6 0 4 8 6 19 5
22 -1 22 -16 0 -34 -13 -11 -13 -12 2 -7 12 4 17 -1 20 -18 3 -22 7 -23 71
-22 47 1 66 -1 59 -8 -7 -7 -7 -18 1 -34 7 -18 7 -28 -1 -36 -8 -8 -10 -6 -6
9 6 23 -19 48 -40 40 -10 -4 -13 -1 -9 9 3 7 0 14 -6 14 -6 0 -11 -5 -11 -11
0 -8 -5 -8 -17 1 -10 7 4 -8 32 -32 29 -27 56 -43 64 -40 8 3 12 1 8 -5 -4 -5
4 -19 16 -31 21 -20 26 -22 46 -22 5 0 11 -9 14 -20 3 -11 11 -20 19 -20 10 0
11 2 0 9 -7 4 -14 17 -15 29 0 11 -6 18 -12 14 -5 -3 -4 1 3 10 10 12 9 18 -3
28 -9 7 -18 10 -22 6 -3 -3 -2 -6 4 -6 15 0 11 -28 -5 -35 -9 -3 -12 0 -9 8 3
8 1 20 -4 28 -6 10 -9 10 -9 2 0 -23 -16 -14 -17 9 -1 13 1 18 5 12 4 -5 24
-9 45 -9 37 1 38 0 34 -32 -3 -22 0 -36 10 -41 7 -5 15 -18 16 -31 2 -27 88
-93 106 -81 7 4 12 10 12 14 -1 3 2 6 7 6 4 0 8 -13 9 -28 2 -20 0 -23 -7 -12
-5 8 -10 11 -10 6 0 -18 74 -85 85 -76 15 12 55 1 55 -16 0 -7 14 -20 30 -29
17 -9 30 -12 30 -8 0 5 11 9 25 9 18 1 23 -2 17 -10 -5 -6 -8 -22 -7 -35 2
-22 1 -23 -11 -6 -7 10 -11 22 -8 27 3 4 -3 7 -13 5 -21 -3 -25 -16 -12 -37 6
-11 8 -11 9 -2 0 7 5 10 10 7 6 -4 7 -12 4 -18 -4 -7 -4 -9 1 -5 4 4 15 2 23
-5 13 -10 14 -8 9 13 -3 14 -2 32 4 40 9 13 10 13 8 0 -5 -47 1 -56 31 -54 22
1 30 -2 31 -14 0 -13 2 -13 8 3 7 15 9 10 9 -20 0 -34 -2 -37 -13 -22 -10 14
-14 15 -18 3 -10 -25 85 -97 99 -75 4 8 3 9 -4 5 -8 -5 -10 -1 -5 11 6 16 5
16 -10 4 -15 -12 -17 -12 -11 4 6 14 3 16 -14 11 -11 -4 -23 -3 -26 2 -3 5 14
9 38 8 40 0 43 -2 42 -25 -1 -15 -5 -32 -10 -40 -6 -10 -5 -12 2 -7 7 4 12 3
12 -3 0 -6 22 -28 48 -49 45 -35 53 -38 110 -37 47 2 59 -1 52 -10 -5 -6 -7
-15 -3 -18 3 -4 12 1 19 11 10 13 12 14 12 2 -3 -47 3 -68 22 -73 11 -3 23 -1
26 4 11 18 28 3 29 -24 1 -15 1 -27 -1 -27 -1 0 -10 3 -20 7 -16 6 -16 7 2 15
14 6 11 7 -9 3 -21 -4 -27 -2 -21 6 5 9 1 10 -14 7 -12 -4 -20 -2 -16 3 3 5
-1 7 -8 6 -7 -2 -10 -2 -5 0 4 3 7 17 6 31 -1 25 -2 23 -15 -17 -3 -11 -10 -9
-29 9 -29 28 -24 42 5 15 11 -10 20 -15 20 -11 0 14 -33 56 -44 56 -6 0 -4 -5
4 -10 8 -5 10 -10 4 -10 -6 0 -14 3 -18 7 -9 9 -36 13 -35 5 1 -10 116 -108
120 -103 6 6 49 -18 48 -27 -3 -19 2 -23 16 -12 13 11 14 10 8 -6 -6 -17 -5
-17 10 -5 20 16 23 2 5 -18 -10 -11 -10 -13 0 -7 6 4 12 3 12 -3 0 -16 38 -33
45 -21 4 6 3 10 -2 9 -20 -3 -30 2 -30 16 0 11 4 12 11 5 20 -20 29 -10 10 12
-13 16 -14 19 -2 10 13 -10 17 -10 21 0 3 7 4 -8 2 -34 -3 -39 0 -46 13 -41
11 5 13 3 7 -7 -5 -9 -4 -11 4 -6 7 5 22 9 35 10 19 2 22 -2 23 -32 0 -23 -2
-30 -8 -21 -5 8 -9 9 -9 3 0 -6 10 -19 22 -30 12 -10 18 -13 14 -6 -4 6 -3 12
3 12 5 0 12 -6 15 -12 5 -10 7 -10 12 0 3 6 10 12 15 12 6 0 7 -4 4 -10 -3 -5
-1 -10 4 -10 6 0 11 -8 10 -17 0 -11 -3 -13 -6 -5 -2 6 -10 9 -15 6 -7 -5 -8
-2 -3 6 6 9 4 11 -4 6 -9 -6 -8 -13 6 -26 10 -9 21 -16 25 -13 10 5 45 -19 47
-32 0 -5 4 -10 9 -10 4 -1 23 -16 42 -35 20 -19 48 -45 63 -57 24 -20 27 -21
28 -5 1 16 2 16 6 0 2 -10 12 -18 21 -17 11 0 12 2 5 6 -7 2 -13 9 -13 14 0 6
4 8 8 5 5 -3 9 1 9 9 0 12 -3 12 -16 2 -12 -10 -13 -9 -7 1 7 11 4 11 -12 1
-16 -10 -24 -9 -45 8 -19 15 -23 23 -15 31 7 7 8 15 3 19 -4 5 -10 2 -12 -4
-3 -7 -9 -13 -15 -13 -6 0 -8 6 -5 14 5 14 -26 31 -48 26 -5 -1 -7 2 -2 6 10
10 48 13 42 3 -8 -12 49 -18 58 -6 5 7 5 -3 2 -20 -5 -23 -2 -38 9 -49 9 -8
16 -11 16 -6 0 5 12 8 28 7 25 -1 27 -4 27 -49 0 -37 5 -50 20 -58 11 -6 24
-17 28 -25 4 -7 13 -13 20 -13 7 0 6 4 -3 10 -13 9 -13 10 0 10 13 0 13 1 0
11 -11 8 -12 12 -2 16 6 2 9 10 6 15 -4 6 -2 8 3 5 6 -4 10 -24 9 -44 -3 -45
3 -61 15 -42 7 11 12 12 29 1 12 -7 20 -8 20 -2 0 5 4 10 8 10 4 0 8 -11 8
-25 l0 -25 -23 22 c-13 12 -25 19 -28 16 -5 -5 60 -68 70 -68 2 0 25 -20 51
-44 30 -30 52 -43 63 -39 9 4 13 2 8 -2 -9 -10 22 -47 34 -39 5 3 9 0 9 -5 0
-6 5 -10 12 -8 7 1 12 -3 13 -10 0 -6 14 -22 30 -34 17 -13 39 -31 49 -41 10
-10 23 -15 28 -12 4 3 8 -1 8 -8 0 -7 9 -19 20 -25 16 -11 18 -10 14 2 -4 8
-11 15 -18 15 -7 0 -6 3 2 9 11 6 9 9 -6 13 -15 4 -19 12 -15 34 13 86 4 125
-28 117 -15 -4 -20 -3 -15 5 4 7 2 12 -3 12 -6 0 -10 15 -10 33 1 22 3 25 6
10 2 -12 11 -27 19 -34 13 -10 13 -8 4 15 -13 35 -13 45 1 26 9 -12 9 -11 4 5
-5 11 -10 32 -13 48 -4 24 -12 31 -32 29 -3 0 -6 8 -6 18 0 10 5 16 10 13 5
-4 7 1 3 11 -15 40 -18 64 -8 70 6 4 11 12 11 17 0 6 -5 7 -10 4 -7 -4 -9 2
-4 19 6 24 4 25 -37 21 -30 -3 -39 -1 -31 7 8 8 7 19 -4 39 -8 16 -10 29 -5
29 5 0 14 12 19 28 9 22 11 23 11 7 1 -12 -4 -26 -10 -32 -8 -8 -8 -13 0 -17
6 -4 11 -1 11 6 0 7 9 0 20 -17 12 -18 20 -24 20 -14 0 9 -3 19 -7 23 -4 3 -8
16 -10 28 -2 11 -11 39 -21 62 -13 28 -16 50 -11 69 4 15 4 27 -1 27 -13 0
-21 -60 -10 -80 7 -13 6 -28 -4 -50 -12 -29 -13 -18 -14 111 0 186 0 179 -17
179 -8 0 -15 8 -16 18 0 12 -3 10 -10 -7 -5 -13 -12 -21 -16 -18 -3 4 0 20 7
36 6 17 10 36 7 43 -4 9 -6 8 -6 -3 -1 -9 -7 -32 -15 -50 l-15 -34 1 42 c0 27
4 40 11 36 6 -4 8 0 5 8 -18 48 -20 61 -7 54 9 -6 10 -4 4 11 -13 27 -15 159
-3 142 7 -9 8 -8 3 7 -3 10 -9 38 -12 62 -3 24 -14 50 -26 60 -20 17 -20 17
-14 0 4 -12 3 -16 -4 -11 -6 3 -8 18 -5 33 5 21 4 23 -5 11 -9 -12 -10 -11 -4
5 16 44 16 52 1 64 -10 8 -16 9 -16 3 0 -6 5 -14 10 -17 7 -4 6 -13 -1 -27 -6
-11 -9 -28 -6 -38 2 -10 1 -24 -4 -31 -5 -8 -8 20 -6 68 1 46 3 83 4 83 1 0
12 -10 24 -22 19 -20 20 -21 12 -3 -6 11 -8 25 -6 32 6 17 -18 28 -28 12 -5
-8 -9 -9 -9 -3 0 6 7 17 15 24 14 12 14 15 0 30 -10 12 -15 32 -13 56 3 35 1
39 -19 36 -14 -2 -23 2 -23 10 0 10 -3 9 -11 -2 -9 -12 -10 -11 -6 8 3 12 3
22 1 22 -3 0 -9 -10 -13 -22 -8 -19 -9 -17 -8 14 1 21 5 38 9 38 3 0 2 15 -2
33 -11 38 -8 63 8 79 9 9 12 7 12 -8 0 -21 29 -67 30 -47 0 6 -5 15 -11 18 -6
4 -8 13 -5 21 3 7 1 16 -4 19 -6 4 -10 16 -10 28 0 12 -5 29 -10 37 -7 11 -8
6 -4 -16 3 -17 2 -34 -3 -37 -5 -3 -10 10 -10 29 -1 19 3 37 8 40 5 3 9 15 8
27 0 21 -1 21 -8 2 -6 -14 -8 -2 -8 36 0 39 -6 64 -18 80 -11 15 -15 17 -12 7
4 -10 2 -18 -3 -18 -6 0 -11 8 -11 18 -1 16 -2 16 -6 0 -7 -26 -33 -22 -32 5
0 21 1 21 9 2 7 -15 9 -10 9 23 1 23 -3 42 -7 42 -5 0 -9 14 -9 30 0 42 13 38
22 -7 5 -28 9 -33 17 -22 7 10 8 4 5 -21 -3 -19 -3 -29 0 -21 5 11 7 11 15 0
5 -9 6 -3 2 16 -3 17 -13 35 -21 42 -8 6 -12 17 -8 23 4 7 -2 18 -14 26 -22
16 -30 41 -9 28 8 -5 9 -2 5 10 -15 40 -18 64 -8 70 6 4 11 12 11 17 0 6 -4 7
-10 4 -6 -4 -9 4 -7 17 2 21 -2 23 -38 21 -35 -1 -41 2 -42 20 0 12 1 16 4 10
3 -7 9 -13 14 -13 5 0 4 10 -2 23 -7 16 -6 31 5 57 11 25 15 29 16 15 0 -12
-5 -26 -11 -32 -8 -8 -8 -13 0 -17 6 -4 11 -1 11 6 0 7 9 0 20 -17 12 -18 20
-24 20 -14 0 9 -3 19 -7 23 -4 3 -8 13 -9 21 -5 33 -26 85 -35 85 -6 0 -8 -4
-5 -9 3 -5 0 -24 -8 -42 l-14 -34 1 51 c1 40 5 50 16 46 9 -3 12 0 9 9 -3 8
-10 13 -15 12 -5 -1 -9 36 -8 85 1 99 -2 112 -21 112 -8 0 -14 10 -15 23 l0
22 -9 -22 c-13 -32 -22 -29 -15 5 6 27 6 27 -8 -3 -13 -28 -14 -22 -13 77 1
71 5 111 14 120 14 14 16 34 3 42 -5 4 -7 -3 -4 -14 3 -11 1 -20 -4 -20 -5 0
-10 39 -10 87 -1 54 -5 89 -12 91 -6 2 -18 19 -27 38 -9 19 -19 32 -22 30 -3
-3 0 -11 5 -18 18 -23 21 -55 6 -55 -8 0 -12 6 -8 15 3 8 -1 24 -8 36 -10 16
-13 62 -12 183 1 90 5 166 9 170 4 4 4 -1 0 -12 -6 -17 -5 -18 7 -7 8 7 13 30
13 53 -1 29 1 37 8 28 8 -12 9 -12 6 0 -2 8 -11 15 -20 17 -10 1 -15 -3 -12
-10 3 -7 1 -23 -4 -37 -7 -18 -9 -6 -8 46 l2 70 34 1 c19 1 36 6 38 13 2 6 9
11 15 11 6 0 0 -11 -13 -25 -17 -18 -26 -22 -31 -13 -4 7 -12 9 -16 4 -4 -5
-3 -11 4 -13 7 -2 10 -10 7 -18 -7 -17 12 -29 22 -14 4 8 3 9 -4 5 -7 -4 -12
-2 -12 3 0 6 7 11 15 11 8 0 15 6 15 14 0 8 5 16 11 18 5 2 12 12 13 23 2 11
9 19 16 17 8 -2 10 3 6 13 -4 11 -1 15 11 13 10 -2 18 3 18 10 0 6 2 11 5 10
13 -4 50 24 44 34 -5 7 -2 9 9 5 11 -4 14 -2 10 9 -3 7 -17 14 -32 13 -17 0
-21 -3 -11 -7 20 -9 23 -29 5 -36 -19 -7 -28 10 -21 37 4 19 12 22 51 23 33 0
44 -3 41 -12z m-110 -75 c3 -5 -2 -6 -10 -3 -8 3 -19 -3 -26 -15 -11 -22 -30
-28 -30 -10 0 5 5 7 10 4 6 -3 10 -1 10 4 0 6 -5 11 -11 11 -5 0 -8 4 -5 9 7
11 55 11 62 0z m670 -92 c8 3 14 -1 14 -8 0 -10 2 -11 8 -2 5 8 12 8 26 -3 11
-7 17 -17 13 -20 -4 -4 -15 -1 -24 7 -16 13 -17 12 -4 -3 7 -10 9 -18 3 -18
-5 0 -13 7 -16 16 -3 8 -10 13 -15 10 -4 -3 -17 4 -27 17 -10 12 -12 17 -5 10
7 -6 20 -9 27 -6z m61 -54 c-4 -3 -2 -12 4 -19 7 -8 7 -14 -1 -19 -5 -3 -10 1
-10 9 0 9 -5 16 -11 16 -5 0 -7 5 -4 10 3 6 11 10 17 10 6 0 8 -3 5 -7z m73
-28 c4 3 7 -14 5 -37 -3 -54 -2 -53 -20 -38 -11 9 -12 17 -5 30 12 23 13 33 2
26 -4 -2 -14 0 -22 5 -13 8 -12 12 5 25 18 13 20 13 23 -1 2 -8 7 -13 12 -10z
m211 -338 c-10 -9 -11 -8 -5 6 3 10 9 15 12 12 3 -3 0 -11 -7 -18z m115 -55
c1 -7 0 -8 -3 -2 -8 16 -21 12 -19 -6 0 -9 -6 -19 -14 -21 -10 -3 -11 -2 -3 4
8 6 9 14 3 23 -7 10 -10 11 -10 3 0 -7 -5 -13 -11 -13 -8 0 -8 4 0 14 14 17
53 16 57 -2z m-1263 -47 c-7 -21 -13 -19 -13 6 0 11 4 18 10 14 5 -3 7 -12 3
-20z m1333 -77 c-4 6 -2 12 4 12 7 0 9 -8 5 -20 -5 -14 -1 -22 12 -26 10 -3
26 -10 36 -15 16 -9 18 -7 13 9 -4 12 -2 19 7 19 9 0 12 -13 11 -37 -2 -56
-15 -69 -28 -29 -8 24 -17 34 -31 34 -13 0 -30 16 -47 43 -35 53 -35 66 -2 27
15 -16 23 -24 20 -17z m4 32 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10
10 10 6 0 10 -4 10 -10z m47 -56 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17
-2 13 -5z m103 -163 c0 10 3 10 12 1 10 -10 10 -15 -2 -22 -15 -9 -40 4 -40
22 0 6 7 5 15 -2 12 -10 15 -10 15 1z m76 -80 c8 6 14 10 15 8 0 -2 2 -20 4
-39 3 -26 2 -30 -5 -15 -5 11 -9 16 -9 11 -1 -13 -31 26 -30 39 0 6 3 4 6 -3
4 -10 8 -10 19 -1z m59 -81 c-3 -5 -1 -10 4 -10 6 0 11 4 11 10 0 5 7 7 16 3
12 -4 11 -8 -8 -17 -16 -9 -25 -9 -30 -2 -11 16 -10 26 3 26 5 0 7 -4 4 -10z
m100 -111 c11 -17 -1 -21 -15 -4 -8 9 -8 15 -2 15 6 0 14 -5 17 -11z m130
-169 c3 -5 2 -10 -4 -10 -5 0 -13 5 -16 10 -3 6 -2 10 4 10 5 0 13 -4 16 -10z
m92 -36 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-1710 -336
c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m1990 -14 l3 -29 -20 24
c-11 12 -17 27 -14 33 11 17 27 2 31 -28z m-3838 -60 c13 -12 -8 -10 -29 2
l-25 15 25 -7 c14 -3 27 -8 29 -10z m3989 -76 c17 -17 15 -29 -8 -58 l-20 -25
11 33 c8 24 8 36 -1 47 -15 18 1 20 18 3z m61 -122 c18 -22 -2 -25 -21 -4 -14
13 -15 18 -4 18 7 0 19 -6 25 -14z m-3651 -137 c-1 -22 -3 -27 -10 -16 -4 8
-5 22 -2 31 9 24 13 19 12 -15z m-48 -29 c6 -11 9 -20 7 -20 -2 0 -12 9 -22
20 -10 11 -13 20 -7 20 6 0 16 -9 22 -20z m3886 -62 l1 -39 -34 32 c-34 34
-30 50 12 47 17 -2 21 -9 21 -40z m-5002 -23 c3 -8 0 -14 -6 -12 -6 1 -13 -4
-15 -11 -3 -8 1 -10 11 -6 11 4 16 1 16 -10 0 -14 -4 -15 -20 -6 -11 6 -20 20
-20 31 0 11 5 17 10 14 6 -3 10 -1 10 4 0 16 7 13 14 -4z m1322 -60 c1 -20 -1
-33 -5 -28 -3 5 -15 15 -26 22 -19 11 -18 12 5 6 25 -5 25 -5 3 6 -30 16 -30
40 -1 35 19 -2 23 -10 24 -41z m44 -53 c0 -12 -3 -12 -15 -2 -8 7 -15 13 -15
15 0 1 7 2 15 2 8 0 15 -7 15 -15z m3797 3 c0 -8 -4 -12 -9 -9 -5 3 -6 10 -3
15 9 13 12 11 12 -6z m-3677 -70 c0 -2 -7 -8 -15 -15 -12 -10 -15 -10 -15 2 0
8 7 15 15 15 8 0 15 -1 15 -2z m52 -14 c-4 -10 -1 -13 9 -9 10 4 14 -2 14 -18
0 -29 -11 -31 -20 -5 -3 11 -15 23 -26 26 -26 9 -24 20 5 20 16 0 22 -4 18
-14z m3708 -34 c0 -4 -4 -5 -10 -2 -14 9 -12 -4 3 -19 7 -7 18 -11 24 -9 7 3
20 -5 29 -16 10 -11 22 -17 27 -14 5 3 1 -6 -8 -21 -16 -24 -44 -39 -30 -16 3
6 -1 10 -9 10 -9 0 -16 5 -16 11 0 5 -5 7 -11 3 -8 -4 -9 1 -4 16 5 15 4 20
-4 16 -6 -4 -11 -2 -11 4 0 6 -3 9 -7 7 -5 -3 -8 10 -9 28 0 29 2 32 18 22 10
-7 18 -15 18 -20z m-5170 -67 c0 -4 -6 -10 -12 -13 -8 -3 -13 4 -13 18 0 18 3
21 13 13 6 -6 12 -14 12 -18z m25 -20 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0
6 2 10 4 10 3 0 8 -4 11 -10z m1774 -191 c-7 -4 -17 -5 -23 -2 -5 3 -6 1 -2
-5 4 -6 12 -9 19 -6 7 2 13 -4 14 -16 2 -19 0 -18 -33 6 -19 15 -34 27 -34 28
0 0 16 2 36 2 22 1 31 -2 23 -7z m3766 -181 c-2 -38 7 -49 46 -51 25 -2 35 -7
37 -22 l4 -20 -16 20 c-8 11 -16 15 -16 8 0 -7 -7 -10 -17 -6 -11 4 -14 3 -9
-6 4 -7 13 -10 19 -8 25 8 39 -25 27 -63 -1 -3 -8 9 -15 25 -8 17 -14 24 -15
16 0 -8 -13 -1 -31 17 -20 19 -29 37 -26 46 4 9 2 16 -3 16 -6 0 -10 -5 -10
-11 0 -8 -5 -8 -15 1 -14 11 -13 14 1 25 12 8 14 14 6 19 -19 12 -14 22 11 22
19 0 23 -5 22 -28z m-5319 -143 c-15 -24 -15 -26 0 -24 7 1 24 -21 24 -31 0
-3 -14 -6 -30 -6 -23 -1 -28 1 -21 10 7 9 7 16 -2 25 -9 10 -7 16 12 32 31 26
37 24 17 -6z m5456 -4 c-4 -10 -1 -13 9 -9 10 4 14 -2 14 -18 0 -29 -11 -31
-20 -5 -3 11 -15 23 -26 26 -26 9 -24 20 5 20 16 0 22 -4 18 -14z m26 -88 c2
-26 1 -26 -12 -8 -8 11 -12 23 -10 27 10 16 20 7 22 -19z m-3403 7 c3 -5 -1
-10 -10 -10 -9 0 -13 5 -10 10 3 6 8 10 10 10 2 0 7 -4 10 -10z m3455 -71 c0
-5 6 -6 14 -3 10 4 14 -1 13 -14 -1 -19 -2 -19 -29 1 -33 24 -32 24 -13 24 8
0 15 -4 15 -8z m-3220 -164 c11 -13 10 -14 -4 -9 -9 3 -16 10 -16 15 0 13 6
11 20 -6z m3405 -17 c1 -14 5 -34 9 -44 4 -11 -10 -1 -32 21 -22 22 -38 42
-36 45 1 3 15 5 30 5 23 -1 28 -7 29 -27z m-3003 -93 c3 -13 1 -22 -3 -19 -12
7 -22 -42 -15 -72 5 -17 3 -23 -4 -19 -5 3 -10 29 -10 56 0 34 4 48 11 43 8
-4 9 0 4 15 -3 11 -2 21 3 21 5 0 12 -11 14 -25z m-2541 -22 c27 5 30 4 16 -4
-23 -14 -59 -8 -64 11 -4 12 -3 13 5 1 7 -10 20 -12 43 -8z m2349 -25 c0 -4 5
-8 11 -8 5 0 8 4 5 8 -3 4 12 8 32 9 31 1 33 -1 15 -8 -24 -9 -30 -24 -13 -34
6 -3 10 -11 10 -16 0 -6 -4 -7 -10 -4 -5 3 -10 1 -10 -6 0 -7 -11 -2 -25 11
-13 12 -22 28 -18 33 4 6 0 8 -10 4 -8 -3 -18 0 -21 8 -3 9 2 13 15 12 10 -1
19 -5 19 -9z m80 -29 c0 -6 -4 -7 -10 -4 -5 3 -10 11 -10 16 0 6 5 7 10 4 6
-3 10 -11 10 -16z m38 -46 c35 2 37 0 38 -30 1 -18 2 -37 3 -42 0 -5 16 -8 34
-5 33 4 34 3 33 -30 l-1 -33 -37 30 c-21 17 -38 34 -38 39 0 5 -4 6 -10 3 -5
-3 -7 0 -4 9 3 9 -2 17 -15 21 -12 3 -21 11 -21 16 0 5 -5 9 -11 9 -5 0 -7 -6
-3 -12 6 -10 4 -10 -8 0 -10 8 -14 21 -10 35 5 18 7 19 10 5 2 -13 12 -17 40
-15z m3317 -4 c11 -17 -1 -21 -15 -4 -8 9 -8 15 -2 15 6 0 14 -5 17 -11z
m-5668 -171 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m107 -194
c3 -8 2 -12 -4 -9 -6 3 -10 10 -10 16 0 14 7 11 14 -7z m6282 -540 c0 -16 4
-35 8 -42 4 -7 2 -12 -3 -12 -6 0 -11 7 -11 15 0 7 -7 18 -16 23 -11 6 -14 16
-9 31 9 31 30 21 31 -15z m-6036 -114 c0 -7 -13 -7 -38 -1 -2 1 -1 5 3 11 6
10 35 2 35 -10z m6200 -90 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11
10 2 0 4 -4 4 -10z m-6064 -247 c-1 -21 5 -45 13 -53 10 -10 11 -15 2 -18 -7
-2 -15 -1 -18 2 -7 7 -12 88 -6 99 8 14 10 7 9 -30z m6304 -17 c0 -14 -3 -27
-7 -29 -5 -3 -8 8 -8 24 0 16 3 29 8 29 4 0 7 -11 7 -24z m161 -243 c13 -16
12 -17 -3 -4 -10 7 -18 15 -18 17 0 8 8 3 21 -13z m-6292 -72 c8 -5 10 -14 6
-21 -6 -9 -4 -11 4 -6 9 6 11 -1 6 -26 -6 -29 -3 -36 17 -45 21 -11 22 -12 3
-12 -12 -1 -26 4 -33 11 -15 15 -16 48 0 48 6 0 8 3 4 7 -4 5 -13 0 -20 -9
-11 -15 -13 -12 -12 22 0 42 3 45 25 31z m-1 -130 c3 -8 -1 -12 -9 -9 -7 2
-15 10 -17 17 -3 8 1 12 9 9 7 -2 15 -10 17 -17z m6599 -104 c1 -3 -1 -16 -4
-27 -4 -16 -8 -18 -20 -9 -8 7 -18 20 -23 29 -7 13 -3 16 18 15 15 0 28 -4 29
-8z m-6427 -167 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0
10 -4 10 -10z m6960 -465 c35 7 46 -6 19 -22 -19 -10 -20 -13 -5 -11 11 2 20
-4 21 -14 6 -34 -12 -27 -50 20 -22 26 -32 41 -24 35 9 -7 26 -11 39 -8z m175
-225 c-3 -5 0 -7 8 -4 8 3 17 -2 20 -10 4 -9 12 -16 19 -16 9 0 9 3 -2 10 -11
7 -9 9 9 7 24 -1 59 -42 47 -54 -3 -3 -6 -1 -6 5 0 7 -4 12 -9 12 -5 0 -7 -9
-4 -20 3 -11 9 -18 13 -15 10 6 7 -22 -4 -32 -4 -4 -7 -3 -6 2 3 17 -12 55
-22 55 -5 0 -6 -10 -2 -22 5 -18 0 -15 -22 12 -16 19 -28 38 -26 42 1 4 -5 8
-13 8 -8 0 -15 7 -15 15 0 8 5 15 11 15 5 0 7 -4 4 -10z m183 -235 c-3 -3 -9
2 -12 12 -6 14 -5 15 5 6 7 -7 10 -15 7 -18z m3070 -56 c-19 -12 -25 -11 -25
5 0 8 8 11 20 8 14 -4 16 -7 5 -13z m289 15 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6
6 11 1 17 -2 13 -5z m-339 -25 c-10 -5 -18 -18 -18 -27 0 -14 -2 -15 -10 -2
-11 17 5 40 29 40 14 -1 14 -2 -1 -11z m-2866 -46 c-10 -4 -10 -8 -1 -19 8
-10 8 -14 0 -14 -6 0 -4 -8 6 -19 9 -11 22 -17 29 -14 8 3 14 -2 14 -11 0 -23
-11 -20 -48 13 -21 20 -27 31 -19 35 9 5 9 7 -2 12 -11 5 -11 8 0 15 8 5 19 8
24 8 6 0 4 -3 -3 -6z m3373 -63 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2
10 4 10 3 0 8 -4 11 -10z m-3242 -92 c3 -10 8 -9 23 2 17 14 18 14 6 -2 -10
-13 -10 -17 0 -21 10 -4 10 -7 1 -14 -10 -7 -9 -15 2 -36 8 -15 10 -29 4 -32
-5 -4 -9 1 -9 9 0 9 -5 16 -11 16 -6 0 -9 7 -5 15 5 13 -6 20 -18 13 -2 -2
-13 6 -25 16 -16 15 -18 22 -10 33 14 16 36 17 42 1z m2422 -48 c-10 -11 -20
-18 -23 -15 -7 6 18 35 31 35 5 0 2 -9 -8 -20z m1129 -15 c-4 -8 -8 -15 -10
-15 -2 0 -4 7 -4 15 0 8 4 15 10 15 5 0 7 -7 4 -15z m-3444 -30 c4 3 7 -14 5
-37 -3 -54 -2 -53 -20 -38 -11 9 -12 17 -5 30 12 23 13 33 2 26 -4 -2 -14 0
-22 5 -13 8 -12 12 5 25 18 13 20 13 23 -1 2 -8 7 -13 12 -10z m3519 4 c-8 -5
-9 -9 -3 -9 6 0 11 -7 11 -15 0 -9 -5 -14 -11 -12 -10 3 -11 13 -7 48 0 5 6 7
12 3 8 -4 7 -9 -2 -15z m-1402 -82 c9 -19 8 -26 -6 -34 -14 -9 -17 -6 -17 23
0 40 9 43 23 11z m1573 13 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11
10 2 0 4 -4 4 -10z m-3 -41 c2 -17 -1 -18 -12 -9 -8 7 -12 16 -9 21 9 16 20
10 21 -12z m97 -44 c5 -15 -24 -15 -46 0 -16 10 -14 12 12 12 16 0 31 -6 34
-12z m56 -35 c8 -5 12 -11 10 -14 -3 -2 -14 2 -24 10 -22 15 -10 19 14 4z
m-3670 -34 c0 -2 -7 -9 -15 -16 -13 -11 -14 -10 -9 4 5 14 24 23 24 12z m1725
-6 c3 -5 11 -10 16 -10 6 0 8 3 6 8 -3 4 10 8 29 9 37 2 37 1 -30 -57 -27 -23
-28 -24 -17 -3 6 12 20 25 32 28 11 4 18 9 16 12 -10 9 -58 -23 -70 -47 -7
-13 -18 -25 -25 -28 -11 -3 -12 0 -4 14 5 11 7 26 5 34 -4 13 -3 13 6 0 9 -13
11 -13 11 3 0 9 5 17 11 17 5 0 7 5 3 11 -4 8 -9 7 -15 -2 -4 -7 -11 -10 -16
-6 -4 5 -1 12 7 17 19 12 27 12 35 0z m-1728 -40 c-1 -12 -5 -16 -10 -11 -5 5
-6 14 -3 20 11 16 15 13 13 -9z m146 -131 c-7 -26 -21 -33 -39 -19 -17 15 -4
35 24 37 15 1 18 -3 15 -18z m1391 9 c-4 -6 1 -17 10 -24 17 -12 17 -13 -1
-22 -16 -9 -19 -7 -21 15 -2 24 4 43 14 43 3 0 2 -5 -2 -12z m-1328 -80 c5 -8
2 -9 -10 -5 -10 4 -19 1 -23 -9 -5 -12 -7 -13 -14 -3 -5 8 -3 17 4 21 17 11
35 10 43 -4z m0 -35 c-7 -7 -26 7 -26 19 0 6 6 6 15 -2 9 -7 13 -15 11 -17z
m71 -39 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m1056 -6 c2 -7
-2 -21 -11 -30 -10 -12 -11 -18 -4 -18 7 0 12 5 12 10 0 6 8 10 17 10 14 0 13
-4 -7 -25 -12 -13 -27 -22 -31 -19 -12 7 -11 54 1 54 6 0 10 5 10 12 0 9 -3 9
-12 0 -14 -14 -28 -16 -28 -3 0 8 15 15 42 20 3 0 8 -5 11 -11z m51 -5 c-10
-7 -23 -13 -28 -12 -6 0 -5 3 2 6 6 2 10 8 8 12 -3 4 4 7 15 7 19 0 19 -1 3
-13z m3000 11 c17 -7 22 -34 6 -34 -5 0 -21 5 -35 12 -18 8 -22 14 -13 20 16
9 22 10 42 2z m-4058 -102 c-22 -5 -30 3 -21 18 5 8 11 7 22 -2 15 -12 14 -13
-1 -16z m-7392 -10 c3 -5 -1 -9 -9 -9 -8 0 -15 4 -15 9 0 4 4 8 9 8 6 0 12 -4
15 -8z m11784 -74 c-1 -18 -3 -27 -5 -20 -3 6 -9 12 -15 12 -6 0 -8 -7 -4 -15
3 -9 0 -15 -9 -15 -8 0 -15 5 -15 10 0 6 -10 10 -22 11 -16 0 -10 5 17 13 22
7 39 18 38 24 -2 7 1 12 6 12 5 0 9 -15 9 -32z m-11702 -145 c4 -16 11 -34 17
-40 12 -16 -5 -17 -21 -1 -6 6 -15 8 -19 4 -4 -4 -1 -11 6 -15 9 -6 6 -8 -11
-8 -21 0 -24 4 -26 41 -2 26 1 36 6 28 8 -11 10 -11 15 2 10 25 26 19 33 -11z
m12024 -67 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2
10 -4z m30 -6 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10
-4 10 -10z m-11930 -240 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10
10 6 0 10 -4 10 -10z m12420 -10 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6
-1 10 4 10 6 0 11 -4 11 -10z m35 -25 c0 -22 -2 -23 -15 -9 -13 13 -13 16 0
24 8 5 15 10 15 10 0 0 0 -11 0 -25z m445 -287 c0 -4 -4 -8 -9 -8 -6 0 -12 4
-15 8 -3 5 1 9 9 9 8 0 15 -4 15 -9z m-12586 -490 c1 -7 5 -19 9 -25 5 -7 5
-19 1 -25 -8 -13 -21 18 -23 56 -1 21 9 16 13 -6z m35 -16 c18 -34 18 -38 3
-39 -11 -1 -17 7 -18 20 0 12 -4 30 -8 40 -15 33 4 17 23 -21z m13151 -202 c0
-5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m-84
-139 c0 -25 -2 -27 -17 -18 -17 10 -17 13 -2 29 20 23 19 23 19 -11z m-237
-158 c-9 -11 -10 -14 -2 -9 7 4 21 3 30 -3 12 -6 -5 -9 -59 -8 -42 1 -74 6
-71 10 3 5 19 7 37 6 17 -2 40 2 51 9 27 16 30 15 14 -5z m-452 -59 c-3 -3
-12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m53 -4 c0 -5 -8 -10 -17 -10
-15 0 -16 2 -3 10 19 12 20 12 20 0z m-12176 -16 c9 -22 -3 -32 -14 -14 -12
19 -12 30 -1 30 5 0 12 -7 15 -16z m11653 -20 c-3 -3 -12 -4 -19 -1 -8 3 -5 6
6 6 11 1 17 -2 13 -5z m-287 -54 c10 -7 -2 -8 -40 -4 -30 4 -57 8 -59 10 -10
9 86 3 99 -6z m-234 -17 c-4 -4 -19 -8 -34 -10 -23 -2 -24 -1 -7 7 24 11 51
13 41 3z m62 -10 c-10 -2 -26 -2 -35 0 -10 3 -2 5 17 5 19 0 27 -2 18 -5z
m-11171 -115 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m8927
-1396 c2 -4 -5 -9 -17 -9 -17 -2 -19 0 -7 7 18 12 18 12 24 2z m-136 -107 c-3
-3 -9 2 -12 12 -6 14 -5 15 5 6 7 -7 10 -15 7 -18z m-173 -95 c-3 -5 -2 -10 2
-10 5 0 10 -10 10 -22 1 -13 -1 -18 -5 -12 -12 18 -40 24 -46 9 -3 -9 -12 -12
-21 -8 -13 4 -8 11 22 28 45 28 46 28 38 15z m-68 -82 c-3 -8 -6 -5 -6 6 -1
11 2 17 5 13 3 -3 4 -12 1 -19z m-327 -158 c-12 -8 -12 -10 -1 -10 8 0 12 -3
8 -6 -9 -9 -28 6 -22 17 4 5 12 9 18 9 7 0 6 -4 -3 -10z m-102 -77 c-6 -7 -18
-13 -27 -13 -14 1 -14 2 2 11 9 5 17 18 17 27 0 14 2 15 10 2 5 -9 5 -19 -2
-27z m30 12 c-3 -3 -9 2 -12 12 -6 14 -5 15 5 6 7 -7 10 -15 7 -18z m-318
-169 c0 -2 -7 -7 -16 -10 -8 -3 -12 -2 -9 4 6 10 25 14 25 6z m-61 -45 c9 -6
5 -8 -13 -8 -14 0 -26 3 -26 8 0 11 21 11 39 0z m-6800 -83 c-6 -12 -6 -18 1
-18 5 0 10 -5 10 -11 0 -22 -25 -1 -26 22 -2 35 7 54 17 38 4 -7 3 -21 -2 -31z
m6217 -305 c-1 -1 -19 3 -39 9 l-37 11 23 16 c12 9 25 15 30 13 4 -1 7 4 8 11
1 6 5 -4 9 -23 5 -19 7 -36 6 -37z m-6108 -19 c2 -1 -8 -1 -21 -1 -16 0 -23 6
-24 19 -2 20 -1 20 20 2 12 -10 23 -19 25 -20z m6092 6 c0 -5 -4 -10 -10 -10
-5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-149 -39 c3 -1 5 -11 5
-24 0 -19 -6 -23 -33 -24 -18 0 -33 4 -33 9 0 5 5 6 12 2 7 -4 8 -3 4 4 -7 11
-31 6 -54 -11 -7 -5 -15 -7 -19 -3 -4 3 12 14 35 23 23 9 42 19 42 24 0 4 8 6
18 4 9 -2 19 -4 23 -4z m-240 -136 c4 -49 3 -75 -3 -75 -5 0 -8 6 -8 14 0 8
-5 18 -11 22 -8 4 -9 -1 -4 -15 6 -21 5 -21 -14 -9 -15 9 -24 10 -29 3 -9 -14
-50 -17 -57 -5 -3 5 -1 11 5 15 5 3 10 0 10 -7 1 -8 4 -7 10 4 6 9 18 14 28
12 19 -6 65 32 57 47 -4 5 -1 9 4 9 6 0 12 -7 12 -15z m-141 -69 c0 -2 -7 -7
-16 -10 -8 -3 -12 -2 -9 4 6 10 25 14 25 6z m-180 -116 c-13 -8 -13 -10 -1
-10 11 0 9 -4 -5 -15 -19 -15 -19 -15 1 -9 19 5 19 5 2 -6 -14 -9 -22 -9 -33
0 -11 10 -14 10 -14 1 0 -7 -5 -9 -11 -5 -16 10 10 24 30 16 14 -5 14 -4 1 13
-9 11 -10 16 -2 11 6 -4 12 -2 12 3 0 6 8 11 18 11 14 0 15 -2 2 -10z m-95
-78 c0 -16 5 -26 13 -24 17 3 15 -3 -5 -19 -10 -9 -14 -9 -10 -2 5 7 -1 16
-12 22 -16 9 -18 14 -8 35 14 29 22 24 22 -12z m22 -4 c-3 -8 -6 -5 -6 6 -1
11 2 17 5 13 3 -3 4 -12 1 -19z m-197 -68 c0 -5 -10 -15 -22 -21 l-23 -12 20
21 c23 24 25 25 25 12z m-283 -189 c0 -11 -4 -18 -8 -15 -4 2 -10 0 -13 -5 -3
-4 -17 -8 -32 -8 -16 0 -21 2 -14 7 7 4 17 14 22 21 7 11 8 10 4 -4 -4 -11 -2
-17 5 -15 6 2 10 7 9 12 -1 5 4 13 12 17 17 11 17 12 15 -10z m-97 -101 c0 -5
-4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-143 -35
c0 -9 -5 -14 -11 -12 -6 2 -17 -2 -24 -10 -8 -7 -18 -10 -23 -7 -6 3 5 14 23
24 18 11 33 19 34 19 0 1 1 -6 1 -14z m-102 -45 c-3 -5 -11 -10 -16 -10 -6 0
-7 5 -4 10 3 6 11 10 16 10 6 0 7 -4 4 -10z m-4642 -104 c-4 -5 1 -7 10 -3 10
3 17 2 17 -3 0 -6 -7 -10 -15 -10 -8 0 -15 -4 -15 -10 0 -5 8 -7 18 -3 13 5
11 -1 -8 -21 -15 -17 -31 -25 -43 -22 -13 3 -17 0 -13 -9 7 -19 -5 -27 -21
-13 -11 9 -18 8 -30 -5 -14 -14 -14 -18 -2 -33 13 -16 12 -16 -8 -4 -16 11
-19 17 -11 27 7 9 7 13 0 13 -6 0 -9 6 -6 13 5 14 31 16 51 4 6 -4 14 0 17 9
4 9 9 14 13 11 11 -11 34 45 24 57 -7 8 -4 12 9 12 10 0 16 -5 13 -10z m-33
-13 c0 -5 -5 -15 -10 -23 -7 -12 -9 -10 -7 8 1 19 17 33 17 15z m4537 -32 c-1
-13 -9 -18 -26 -18 l-24 0 24 18 c30 23 28 23 26 0z m-53 -6 c-4 -8 -10 -15
-16 -15 -5 0 -6 6 -2 13 5 8 2 8 -9 -2 -16 -13 -25 -2 -10 12 13 14 42 7 37
-8z m-207 -91 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-66 -31
c-11 -5 -11 -7 0 -14 11 -7 10 -9 -3 -9 -9 0 -19 -4 -22 -9 -4 -5 3 -7 14 -4
11 3 20 1 20 -4 0 -5 -15 -9 -34 -9 -19 0 -32 4 -30 8 3 5 0 8 -5 8 -6 0 -11
-4 -11 -10 0 -5 -10 -10 -22 -10 -21 0 -21 2 7 19 21 12 32 15 37 7 5 -7 8 -4
8 7 0 10 4 16 9 12 5 -3 12 -1 16 5 3 5 12 10 18 9 8 0 7 -3 -2 -6z m-111
-107 c0 -11 -19 -15 -25 -6 -3 5 1 10 9 10 9 0 16 -2 16 -4z m-4473 -123 c0
-17 -5 -16 -23 1 -12 13 -12 15 4 13 10 0 19 -7 19 -14z m4018 -113 c-26 -11
-35 -12 -35 -2 0 4 12 8 28 9 23 1 24 1 7 -7z m-65 -62 c0 -4 -8 -5 -17 -1
-17 6 -17 5 0 -14 23 -26 7 -39 -31 -25 -15 6 -36 13 -47 15 -16 4 -14 5 6 6
14 0 37 11 50 24 22 20 38 18 39 -5z m-164 -85 c-1 -1 -12 0 -24 4 -21 5 -21
6 -5 18 15 11 19 11 24 -3 4 -9 6 -18 5 -19z m-4091 17 c3 -5 -1 -10 -10 -10
-9 0 -13 5 -10 10 3 6 8 10 10 10 2 0 7 -4 10 -10z m3985 -50 c0 -5 -5 -10
-11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m60 6 c0 -3 -4 -8
-10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m-110 -75 c0 -5
-7 -11 -14 -14 -10 -4 -13 -1 -9 9 6 15 23 19 23 5z m-4256 -213 c-3 -5 -12
-7 -20 -4 -8 3 -14 -1 -14 -10 0 -9 5 -12 13 -8 6 4 4 -2 -6 -13 -19 -22 -57
-37 -57 -23 0 4 7 11 16 14 9 4 14 9 11 13 -4 3 -1 13 6 21 9 13 9 14 0 8 -7
-4 -13 -3 -13 3 0 6 16 10 35 9 19 -1 32 -5 29 -10z m3856 -36 c0 -4 -9 -8
-20 -9 -11 -1 -18 2 -15 8 7 11 35 12 35 1z m-240 -142 c0 -5 -4 -10 -10 -10
-5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-10 -20 c13 -8 13 -10
-2 -10 -9 0 -20 5 -23 10 -8 13 5 13 25 0z m-550 -280 c0 -5 -8 -11 -17 -11
-17 -1 -17 -2 0 -6 32 -8 12 -28 -29 -29 -44 -1 -57 3 -48 17 3 5 11 6 17 2 7
-4 25 -8 41 -9 24 -2 26 0 13 8 -10 5 -22 6 -27 3 -6 -3 -10 -2 -10 2 0 5 12
14 28 20 15 6 28 11 30 12 1 1 2 -3 2 -9z m-3662 -66 c-4 -3 -14 -7 -25 -8
-10 -2 -18 -9 -18 -16 0 -7 -6 -15 -14 -18 -9 -3 -11 0 -6 7 4 8 -1 14 -14 18
-12 3 -21 9 -21 13 0 5 9 6 20 3 11 -3 26 0 33 6 8 6 17 8 20 4 4 -3 7 0 7 7
0 11 3 11 12 2 6 -6 9 -14 6 -18z m-78 -34 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4
10 3 6 8 10 11 10 2 0 4 -4 4 -10z m3304 -152 c-3 -4 -11 -5 -19 -2 -11 5 -12
9 -2 19 12 13 31 -1 21 -17z m-3441 -11 c7 4 6 -1 -2 -10 -15 -19 -76 -50
-101 -52 -9 -1 -17 7 -18 19 -1 17 1 18 8 6 7 -10 10 -11 10 -2 0 6 5 12 11
12 5 0 7 -5 3 -11 -3 -6 8 -3 25 6 19 9 29 20 25 27 -4 7 -3 8 4 4 7 -4 12 -1
13 6 0 10 2 10 6 0 2 -6 10 -9 16 -5z m3313 -75 c-3 -25 -7 -27 -50 -28 -25 0
-46 1 -46 2 0 1 8 8 17 15 9 8 14 9 10 3 -4 -7 5 -11 23 -11 35 0 41 17 8 18
-22 1 -22 1 2 8 14 4 24 13 22 20 -1 7 2 10 7 7 5 -4 8 -19 7 -34z m-3406 -8
c0 -8 -19 -13 -24 -6 -3 5 1 9 9 9 8 0 15 -2 15 -3z m3095 -175 c2 -3 -11 -5
-30 -6 -19 0 -32 3 -30 7 5 9 51 7 60 -1z m-94 -38 c3 -1 5 -11 5 -24 0 -20
-5 -23 -44 -24 -25 -2 -43 0 -40 3 5 5 44 15 65 16 6 1 9 7 6 14 -5 14 -33 8
-33 -8 0 -5 -4 -7 -8 -4 -5 3 -21 -2 -37 -10 -15 -8 -30 -12 -33 -9 -3 3 13
13 36 22 23 9 42 19 42 24 0 4 8 6 18 4 9 -2 19 -4 23 -4z m-3401 -71 c0 -17
5 -19 28 -15 25 6 25 5 7 -6 -11 -7 -20 -13 -20 -13 0 0 -11 7 -24 16 -13 9
-20 21 -16 27 10 18 25 12 25 -9z m37 14 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6
11 1 17 -2 13 -5z m3191 -63 c3 -8 -1 -12 -9 -9 -7 2 -15 10 -17 17 -3 8 1 12
9 9 7 -2 15 -10 17 -17z m-3218 -11 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0
6 5 10 10 10 6 0 10 -4 10 -10z m3155 -61 c-3 -8 -16 -16 -28 -16 -22 -2 -22
-2 3 17 27 21 33 20 25 -1z m-3330 -49 c3 -6 -1 -7 -9 -4 -18 7 -21 14 -7 14
6 0 13 -4 16 -10z m3006 -77 c-11 -5 -11 -7 0 -14 11 -7 10 -9 -3 -9 -9 0 -19
-4 -22 -9 -4 -5 3 -7 14 -4 11 3 20 1 20 -4 0 -5 -15 -9 -34 -9 -19 0 -32 4
-30 8 3 5 0 8 -5 8 -6 0 -11 -4 -11 -8 0 -4 -10 -8 -22 -9 -19 -2 -18 0 8 16
20 12 31 15 36 7 5 -7 8 -4 8 7 0 10 4 16 9 12 5 -3 12 -1 16 5 3 5 12 10 18
9 8 0 7 -3 -2 -6z m-3081 -42 c0 -11 -30 -36 -30 -25 0 5 -5 2 -11 -6 -8 -11
-12 -12 -16 -2 -2 6 -9 9 -14 6 -6 -4 -6 -11 1 -19 7 -9 6 -16 -7 -26 -14 -12
-16 -12 -8 0 6 11 4 13 -7 9 -11 -5 -14 1 -13 20 1 23 6 27 33 27 17 0 37 6
44 13 14 14 28 16 28 3z m2970 -65 c0 -11 -19 -15 -25 -6 -3 5 1 10 9 10 9 0
16 -2 16 -4z m-376 -174 c3 -5 -1 -9 -9 -9 -8 0 -12 4 -9 9 3 4 7 8 9 8 2 0 6
-4 9 -8z m-73 -60 c-7 -2 -18 1 -23 6 -8 8 -4 9 13 5 13 -4 18 -8 10 -11z
m-237 -154 c3 -33 2 -33 -41 -34 -51 -2 -51 1 3 19 36 13 37 15 16 21 -22 7
-22 9 -5 21 22 16 24 15 27 -27z m-104 -28 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4
10 3 6 8 10 11 10 2 0 4 -4 4 -10z m-50 -69 c0 -5 -7 -11 -14 -14 -10 -4 -13
-1 -9 9 6 15 23 19 23 5z m-2924 -137 c1 -8 -4 -18 -12 -21 -8 -3 -11 -9 -8
-14 3 -5 1 -9 -4 -9 -8 0 -10 55 -3 80 2 9 25 -20 27 -36z m2519 -92 c-7 -8
-35 7 -35 18 0 6 7 6 20 -2 10 -7 17 -14 15 -16z m-163 -90 c3 -10 0 -19 -6
-19 -7 0 -12 9 -12 19 0 10 3 18 7 18 4 0 9 -8 11 -18z m-243 -129 c-13 -15
-39 -18 -39 -3 0 5 7 6 17 3 10 -4 14 -2 10 4 -3 5 0 10 8 10 11 0 12 -4 4
-14z m-44 -23 c3 -5 15 -6 28 -1 19 7 23 5 24 -11 2 -15 -1 -17 -12 -8 -8 7
-14 7 -17 1 -1 -6 -14 -1 -28 9 -14 11 -20 20 -13 20 6 0 14 -4 18 -10z m-190
-148 c0 -23 -3 -42 -7 -42 -5 0 -8 6 -8 14 0 8 -5 18 -11 22 -8 4 -9 -1 -4
-17 6 -20 5 -22 -6 -11 -7 6 -23 12 -36 12 -13 0 -23 4 -23 10 0 5 7 7 15 4 8
-4 21 -1 29 5 7 6 21 9 30 5 9 -3 16 -1 16 5 0 6 -4 11 -8 11 -5 0 -9 7 -9 15
0 9 5 14 11 12 6 -2 11 -22 11 -45z m-2385 27 c0 -5 -11 -14 -25 -19 -14 -5
-30 -20 -35 -32 l-9 -23 -1 25 c0 20 -2 22 -10 10 -13 -20 -13 -7 1 23 8 18
19 24 45 25 19 1 34 -3 34 -9z m-150 -133 c0 -8 -7 -19 -15 -26 -9 -7 -13 -15
-11 -18 9 -8 -23 -34 -34 -27 -9 6 -14 -7 -11 -27 0 -4 -6 -5 -14 -2 -11 5
-14 17 -12 42 3 28 9 36 25 37 17 1 19 -1 10 -12 -8 -10 -8 -17 0 -25 7 -7 13
-9 13 -5 1 4 3 12 4 17 1 6 3 15 4 21 0 5 6 7 11 4 9 -5 15 16 11 38 -1 5 3 6
9 2 5 -3 10 -12 10 -19z m2214 -84 c2 -4 -4 -9 -15 -9 -11 -1 -17 2 -14 8 7
11 22 12 29 1z m-80 -77 c-6 -16 -24 -21 -24 -7 0 11 11 22 21 22 5 0 6 -7 3
-15z m-2541 -210 c4 -8 2 -17 -3 -20 -6 -4 -10 3 -10 14 0 25 6 27 13 6z
m2067 -12 c0 -3 -9 -3 -20 0 -11 3 -18 1 -14 -4 3 -5 12 -9 21 -9 29 0 3 -15
-29 -16 -21 -1 -26 1 -17 7 12 7 12 9 0 9 -11 0 -10 4 4 14 17 12 55 11 55 -1z
m-96 -82 c24 -20 5 -34 -28 -21 -33 12 -69 13 -62 2 3 -5 -1 -9 -9 -9 -23 0
-18 10 13 27 33 19 64 20 86 1z m-254 -101 c0 -5 -8 -10 -17 -10 -15 0 -16 2
-3 10 19 12 20 12 20 0z m50 0 c-8 -5 -19 -10 -25 -10 -5 0 -3 5 5 10 8 5 20
10 25 10 6 0 3 -5 -5 -10z m-2131 -104 c8 -10 8 -16 -2 -25 -8 -6 -17 -7 -22
-3 -4 4 -5 -2 -1 -13 3 -11 1 -27 -4 -35 -10 -16 -10 -15 -13 37 -2 27 1 32
16 27 25 -8 21 5 -5 16 -23 9 -23 9 -2 9 12 1 27 -5 33 -13z m1946 -20 c0 -16
-4 -22 -14 -18 -10 4 -13 1 -9 -9 4 -10 -2 -14 -18 -14 -29 0 -31 11 -5 20 11
3 23 15 26 26 9 26 20 24 20 -5z m-1930 -26 c-10 -11 -20 -18 -23 -15 -7 6 18
35 31 35 5 0 2 -9 -8 -20z m1766 -60 c5 0 9 -4 9 -10 0 -5 -10 -10 -22 -10
-19 0 -20 -2 -8 -11 13 -9 13 -10 0 -6 -8 2 -14 10 -12 16 1 6 -2 11 -7 11 -5
0 -7 9 -4 20 5 17 7 17 20 5 8 -8 19 -15 24 -15z m-1954 -99 c0 -11 -5 -18 -9
-15 -4 3 -5 11 -1 19 7 21 12 19 10 -4z m1730 -4 c0 -8 -6 -14 -14 -14 -9 0
-10 4 -2 14 6 7 12 13 14 13 1 0 2 -6 2 -13z m33 -7 c0 -5 -2 -10 -4 -10 -3 0
-8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m35 0 c-3 -5 -10 -10 -16 -10
-5 0 -9 5 -9 10 0 6 7 10 16 10 8 0 12 -4 9 -10z m-245 -110 c0 -5 3 -16 7
-25 5 -14 2 -16 -18 -10 -13 4 -25 11 -27 16 -2 4 -11 5 -20 2 -10 -4 -6 2 8
14 23 19 50 21 50 3z m-10 -50 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5
10 10 10 6 0 10 -4 10 -10z m-330 -140 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10
10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-33 -14 c0 -8 -6 -12 -15 -9 -11 5 -11
7 0 14 7 5 13 9 14 9 0 0 1 -6 1 -14z m63 1 c0 -7 -12 -14 -27 -15 -26 -2 -26
-1 -7 13 24 18 34 19 34 2z m-405 -247 c-3 -5 -14 -10 -23 -10 -15 0 -15 2 -2
10 20 13 33 13 25 0z m-1103 -50 c-2 -22 -6 -40 -8 -40 -2 0 -4 18 -4 40 0 22
3 40 8 40 4 0 6 -18 4 -40z m53 -30 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6
2 10 4 10 3 0 8 -4 11 -10z m47 -52 c-8 -8 -9 -15 -1 -25 8 -9 8 -13 0 -13 -6
0 -11 5 -11 11 0 5 -4 8 -8 5 -4 -2 -9 4 -9 15 -1 11 2 17 7 14 5 -3 12 0 15
6 4 5 10 8 13 4 3 -3 0 -11 -6 -17z m598 -93 c19 -23 8 -25 -14 -4 -11 11 -15
19 -9 19 6 0 16 -7 23 -15z m-3450 -2835 c0 -7 6 -8 18 -2 20 11 148 9 159 -2
5 -4 -6 -4 -22 -1 -21 5 -27 4 -20 -3 6 -5 19 -12 30 -15 15 -4 13 -5 -7 -6
-16 0 -28 -6 -28 -12 0 -6 8 -8 19 -4 13 4 23 0 29 -11 5 -9 21 -16 35 -16 17
0 24 -5 20 -12 -5 -8 -3 -8 8 0 18 15 95 12 111 -4 9 -9 9 -12 0 -13 -7 -1
-16 -3 -22 -4 -5 -1 -18 -3 -27 -5 -10 -1 -2 -6 17 -12 19 -5 39 -16 43 -24 5
-8 26 -22 47 -30 21 -9 41 -22 44 -30 3 -8 13 -14 23 -13 16 0 16 2 -2 10
l-20 9 20 -3 c14 -2 20 -9 19 -25 -2 -24 24 -30 44 -10 6 6 14 9 17 6 10 -9
-26 -39 -37 -32 -7 4 -8 2 -4 -5 8 -12 19 -8 49 17 10 9 17 11 17 4 0 -6 -4
-14 -10 -17 -5 -3 -10 -11 -10 -16 0 -6 4 -8 8 -5 5 3 9 -1 9 -8 0 -8 -12 -13
-31 -13 -17 0 -23 2 -13 4 9 3 17 9 17 14 0 6 -8 5 -21 -1 -23 -13 -39 -47
-32 -70 4 -12 3 -13 -5 -1 -8 11 -12 10 -22 -8 -7 -13 -18 -20 -26 -17 -18 7
-33 -22 -29 -57 3 -21 -1 -27 -13 -25 -10 2 -16 -3 -15 -9 2 -7 -4 -13 -13
-13 -8 0 -13 -4 -9 -9 3 -5 1 -12 -4 -16 -6 -3 -8 -12 -4 -21 3 -9 0 -14 -8
-12 -8 2 -13 -3 -12 -9 2 -7 -2 -13 -8 -13 -8 0 -8 -4 -2 -12 6 -7 10 -38 9
-68 -1 -54 -1 -55 -33 -56 -35 -1 -40 5 -21 24 6 6 13 26 15 44 3 30 2 31 -10
12 -37 -65 -48 -86 -38 -80 7 4 10 -6 9 -29 -1 -20 -6 -39 -11 -42 -6 -4 -8 0
-4 9 4 11 1 15 -9 14 -18 -3 -18 -8 1 -49 10 -21 14 -24 11 -9 -2 12 -1 20 4
17 4 -3 8 -21 8 -41 0 -32 -1 -33 -14 -17 -13 17 -15 17 -29 -1 -8 -10 -21
-16 -29 -13 -10 4 -13 1 -10 -8 3 -7 -1 -24 -10 -37 -14 -21 -14 -27 -1 -52
22 -41 19 -66 -6 -66 -17 0 -20 5 -16 23 3 12 1 31 -4 42 -8 17 -8 16 -4 -6 3
-19 0 -29 -11 -33 -12 -5 -14 -1 -9 17 7 22 7 22 -8 2 -11 -14 -12 -22 -4 -27
8 -5 7 -11 -1 -21 -7 -8 -11 -21 -10 -28 2 -8 -4 -13 -13 -13 -11 1 -15 -5
-11 -18 3 -14 8 -16 18 -8 10 9 12 2 11 -37 -2 -46 -3 -48 -32 -48 -17 0 -37
-4 -45 -9 -13 -9 -13 -8 -1 7 7 10 9 17 3 17 -15 0 -21 -22 -10 -43 5 -10 7
-23 5 -30 -3 -7 -9 -1 -15 13 -6 14 -11 19 -11 12 -1 -7 4 -23 10 -35 7 -11
13 -38 14 -59 2 -31 0 -36 -11 -27 -11 9 -14 8 -14 -1 0 -8 -3 -10 -7 -6 -12
12 -12 66 0 73 5 3 8 2 4 -3 -3 -5 1 -18 9 -29 16 -22 18 -8 4 31 -10 26 -11
26 -24 8 -7 -10 -12 -34 -11 -51 1 -18 -2 -33 -7 -33 -4 0 -8 8 -8 18 0 14 -2
15 -10 2 -5 -8 -7 -23 -4 -34 5 -14 3 -16 -7 -10 -11 6 -12 5 -3 -7 9 -11 12
-77 5 -123 0 -4 -13 -6 -27 -3 -39 7 -51 -13 -49 -87 2 -75 -10 -95 -46 -76
-15 8 -19 9 -15 1 4 -6 0 -18 -8 -26 -8 -9 -12 -23 -9 -32 5 -12 3 -14 -7 -8
-9 6 -11 4 -5 -5 12 -19 15 -60 4 -60 -5 0 -9 -7 -9 -16 0 -8 -4 -12 -10 -9
-6 4 -3 16 7 31 12 20 13 28 4 37 -10 9 -11 8 -5 -7 6 -15 4 -17 -7 -11 -8 4
-11 5 -7 0 4 -4 3 -16 -2 -26 -7 -12 -7 -20 0 -24 5 -3 9 -21 9 -38 l-1 -32
-8 30 c-6 23 -8 25 -9 8 0 -12 -6 -25 -12 -29 -8 -4 -9 1 -5 17 l7 24 -15 -24
c-7 -14 -11 -32 -8 -40 3 -9 1 -12 -6 -7 -9 5 -9 1 0 -19 10 -22 9 -28 -2 -35
-8 -5 -17 -6 -20 -3 -3 3 1 8 8 10 6 3 12 9 12 15 0 18 -28 -12 -38 -41 -8
-23 -6 -30 13 -48 22 -18 23 -19 22 -2 -1 10 -4 16 -8 13 -7 -4 -19 19 -19 36
0 4 8 7 17 6 13 -2 17 -13 18 -42 0 -37 -2 -39 -30 -40 -16 0 -33 5 -37 10 -8
11 -12 -15 -9 -43 2 -8 -2 -10 -11 -5 -10 6 -10 4 1 -9 8 -10 11 -20 7 -24 -3
-3 -6 -1 -6 7 0 10 -3 10 -15 0 -12 -10 -13 -16 -4 -26 11 -13 5 -19 -16 -14
-5 1 -7 -2 -3 -6 4 -4 3 -13 -4 -21 -6 -7 -8 -16 -4 -20 3 -3 -2 -15 -11 -26
-17 -19 -17 -19 7 -13 23 6 23 6 8 -7 -10 -8 -18 -20 -18 -26 0 -7 -12 -23
-27 -37 -16 -14 -22 -23 -15 -19 14 7 15 4 6 -19 -3 -9 -10 -13 -15 -10 -5 3
-9 3 -9 0 0 -3 9 -10 20 -16 15 -8 18 -8 14 3 -2 7 -1 24 5 38 6 18 8 7 7 -43
-2 -38 -6 -71 -11 -75 -4 -5 -4 3 0 17 l7 25 -16 -22 c-12 -15 -23 -20 -42
-17 -22 4 -25 2 -19 -17 5 -16 4 -19 -5 -14 -9 6 -11 4 -5 -6 5 -7 10 -36 11
-64 l2 -50 -14 34 c-20 47 -28 33 -9 -15 14 -36 14 -38 -1 -16 -21 28 -38 29
-23 1 6 -12 7 -19 2 -16 -17 10 -35 -10 -37 -40 -1 -17 -6 -36 -12 -44 -20
-26 -28 -42 -16 -35 6 3 12 1 15 -6 2 -7 2 -2 0 12 -3 18 -1 21 6 11 17 -26
11 -104 -10 -124 -18 -17 -19 -17 -19 15 0 30 1 31 16 12 14 -17 15 -17 10 -1
-3 10 -6 23 -6 29 0 25 -26 -2 -30 -33 -4 -26 -9 -33 -23 -31 -15 3 -17 -1
-12 -19 5 -16 4 -19 -5 -14 -9 6 -11 4 -6 -4 5 -7 0 -21 -11 -33 -10 -11 -17
-23 -17 -27 1 -3 -1 -12 -4 -19 -6 -16 14 -39 25 -28 4 4 1 11 -7 16 -8 5 -11
12 -7 17 5 4 12 1 17 -7 7 -11 8 -5 5 18 -2 18 0 30 6 27 13 -9 11 -33 -7 -84
-16 -47 -12 -77 8 -65 9 5 9 3 -1 -9 -14 -18 -26 -23 -16 -8 3 6 -4 15 -15 21
-19 10 -19 12 -5 34 14 21 14 24 0 30 -9 3 -14 2 -11 -3 3 -5 -3 -19 -14 -31
-11 -13 -20 -28 -19 -34 0 -6 3 -6 6 2 2 6 9 12 15 12 6 0 8 -6 5 -14 -4 -9 1
-17 11 -20 9 -3 3 -4 -14 -3 -23 2 -30 -1 -31 -15 -1 -10 -9 -25 -17 -34 -9
-8 -15 -25 -15 -37 2 -22 2 -22 6 1 2 12 8 22 13 22 5 0 12 12 17 27 7 21 9 9
9 -48 l0 -76 -28 0 c-21 0 -25 3 -17 13 5 7 6 14 2 17 -17 10 -36 -28 -37 -75
-1 -14 -11 -39 -23 -54 -27 -34 -21 -47 6 -15 l20 23 -5 -51 c-3 -40 -1 -51
10 -51 7 0 16 7 20 15 3 8 15 15 28 15 21 0 21 0 2 -16 -17 -14 -17 -15 -1
-10 10 3 33 7 50 7 l32 1 -30 8 -30 8 28 1 c16 0 38 -4 49 -10 11 -6 18 -7 15
-2 -6 10 79 10 89 0 4 -4 14 -7 23 -7 14 0 14 2 1 10 -11 7 -8 8 13 6 15 -1
33 -9 40 -16 10 -10 8 -12 -9 -8 -27 7 -29 -23 -2 -30 12 -3 17 -10 14 -19 -3
-8 -2 -11 4 -8 5 3 12 -10 16 -30 7 -38 19 -47 21 -15 1 11 -2 20 -6 20 -4 0
-7 14 -6 30 0 36 8 38 23 8 13 -27 10 -61 -6 -80 -7 -10 -8 -18 0 -28 8 -12 9
-12 4 3 -3 10 -2 16 3 12 5 -3 8 -21 7 -40 -3 -31 0 -35 20 -35 13 0 24 -6 24
-12 0 -9 4 -8 10 2 14 22 12 -3 -4 -41 l-14 -32 -16 29 c-10 16 -14 20 -11 9
7 -21 13 -45 14 -58 2 -18 30 22 45 64 14 37 16 39 14 14 -2 -16 -11 -45 -20
-63 -10 -18 -18 -35 -18 -38 0 -10 27 21 32 36 4 12 3 -683 -1 -700 -2 -9 -88
-6 -97 3 -11 11 -26 9 -19 -3 3 -6 1 -10 -4 -10 -6 0 -11 6 -11 13 0 8 -3 7
-10 -3 -6 -10 -8 -5 -6 18 1 17 6 35 11 39 4 4 5 2 0 -6 -6 -10 -4 -12 5 -6
10 6 12 4 7 -9 -5 -11 -3 -15 5 -10 6 4 17 -1 25 -11 12 -17 13 -17 13 -2 0
10 -7 20 -15 23 -8 4 -12 11 -8 17 3 5 1 7 -4 4 -6 -4 -16 -1 -22 7 -7 8 -21
12 -31 9 -12 -3 -20 0 -20 6 0 7 -5 9 -12 4 -20 -13 -209 -12 -205 1 4 12 83
15 107 5 8 -4 21 -1 28 7 10 11 7 13 -15 13 -64 0 -102 2 -181 11 -70 8 -88 7
-108 -6 -17 -12 -35 -14 -61 -10 -28 5 -34 4 -23 -3 8 -6 26 -11 39 -11 13 0
21 -3 18 -7 -10 -9 -255 -14 -261 -5 -3 5 7 8 22 9 15 0 38 4 51 9 23 7 23 8
5 14 -10 4 -25 3 -31 -2 -9 -7 -18 -7 -27 1 -10 8 -16 9 -21 2 -7 -13 -16 -49
-17 -71 -1 -8 -6 -18 -12 -22 -8 -6 -8 -8 1 -8 8 0 11 -27 9 -87 l-2 -88 -29
0 c-44 0 -53 -10 -50 -55 1 -22 -2 -40 -6 -40 -5 0 -9 5 -9 12 0 6 -2 9 -5 6
-9 -8 6 -38 16 -32 5 3 9 -6 9 -20 0 -14 -4 -28 -10 -31 -6 -3 -7 1 -4 9 3 9
-1 23 -10 31 -9 9 -16 12 -16 6 0 -5 5 -13 10 -16 6 -4 10 -12 9 -18 -3 -25 1
-38 11 -32 6 4 8 -6 6 -25 -5 -41 -16 -51 -16 -14 -1 25 -2 26 -11 11 -5 -9
-8 -21 -5 -25 2 -4 -4 -8 -15 -8 -23 0 -23 1 -3 20 8 9 12 21 8 27 -15 25 -40
-66 -39 -143 1 -43 -2 -75 -6 -72 -5 3 -9 -10 -8 -28 0 -27 2 -30 9 -13 8 19
9 19 9 -4 1 -17 -3 -22 -14 -17 -8 3 -15 0 -15 -6 0 -7 -5 -6 -15 2 -17 14
-20 40 -6 40 5 0 11 -6 14 -12 2 -7 3 -6 2 3 -1 9 1 23 6 31 4 7 3 20 -2 28
-9 13 -10 13 -8 0 3 -28 -1 -41 -10 -36 -13 8 -23 -23 -15 -47 4 -13 2 -17 -5
-13 -10 7 -16 -12 -12 -41 1 -7 -3 -13 -8 -13 -5 0 -8 -10 -5 -23 3 -12 6 -53
8 -90 3 -80 -11 -101 -46 -71 -21 19 -21 19 -14 0 4 -12 2 -22 -6 -27 -7 -4
-9 -11 -5 -15 3 -4 12 -2 19 4 16 12 63 -21 53 -38 -11 -17 -25 -11 -25 11 0
15 -4 19 -16 15 -9 -3 -21 -6 -28 -6 -8 0 -12 -22 -11 -72 0 -46 -4 -73 -12
-75 -6 -2 -13 4 -15 14 -3 15 -5 14 -10 -5 -3 -12 -2 -20 3 -17 4 3 11 -6 15
-20 3 -14 10 -25 15 -25 13 0 11 -58 -2 -66 -6 -3 -15 0 -20 7 -6 10 -9 11 -9
2 0 -7 -4 -13 -9 -13 -5 0 -7 8 -4 19 3 10 -1 22 -8 26 -7 5 -10 4 -6 -2 4 -6
1 -26 -7 -44 -7 -19 -11 -40 -9 -47 3 -7 1 -18 -5 -25 -5 -7 -12 -25 -16 -42
l-6 -30 14 25 14 25 -1 -28 c-1 -15 -7 -27 -14 -27 -12 0 -7 -36 8 -55 7 -9 6
-36 -1 -55 -1 -3 -8 18 -16 45 -12 45 -13 46 -12 15 2 -63 -2 -81 -13 -74 -8
4 -7 0 1 -15 14 -27 10 -70 -9 -93 -8 -10 -11 -18 -8 -18 4 0 2 -12 -3 -27 -9
-26 -9 -27 5 -9 8 11 15 28 15 39 0 10 5 15 10 12 6 -4 10 9 10 32 0 30 3 35
11 23 9 -13 10 -13 6 1 -3 9 -1 22 5 30 6 9 8 -13 4 -70 -3 -46 -11 -90 -18
-98 -7 -9 -14 -25 -15 -37 -3 -25 13 -54 24 -43 4 5 1 13 -7 18 -12 8 -12 12
-2 16 9 3 10 9 2 18 -8 9 -7 14 3 18 10 4 13 -5 13 -33 -1 -46 -10 -57 -31
-40 -11 9 -15 9 -15 1 0 -8 -22 -11 -70 -9 -38 1 -70 7 -70 12 0 6 -5 5 -12
-2 -19 -19 -48 -15 -48 8 0 11 -5 20 -11 20 -5 0 -7 -4 -4 -10 3 -5 0 -10 -7
-10 -9 0 -10 -2 -1 -8 6 -4 -20 -7 -59 -8 -40 -1 -64 2 -56 7 10 7 10 9 1 9
-15 0 -17 41 -3 58 6 6 7 10 3 7 -3 -3 -15 2 -25 12 -10 9 -18 11 -18 5 0 -6
6 -13 13 -16 6 -3 -5 -4 -25 -3 -20 0 -34 5 -32 9 3 4 1 8 -5 8 -5 0 -11 -5
-13 -11 -2 -7 -11 -10 -18 -7 -11 4 -7 9 12 18 22 10 30 11 43 0 12 -10 15
-10 15 4 0 10 -4 14 -10 11 -6 -3 -10 -1 -10 5 0 6 -4 9 -9 6 -4 -3 -16 3 -26
14 -10 11 -24 16 -33 13 -10 -4 -13 -2 -8 5 5 8 1 10 -10 5 -11 -4 -15 -2 -11
4 4 6 -5 19 -19 28 -15 9 -23 12 -20 6 9 -14 -6 -15 -20 -1 -6 6 -10 2 -11
-13 0 -12 1 -16 4 -9 3 6 9 10 14 6 15 -9 10 -20 -11 -21 -16 -1 -20 3 -18 20
2 12 7 27 11 34 8 13 -21 33 -38 27 -6 -2 -15 6 -20 18 -6 13 -20 27 -32 33
-13 5 -27 21 -32 35 -5 14 -16 34 -25 45 -15 20 -15 20 -16 -3 0 -13 4 -20 10
-17 6 4 10 3 9 -2 -1 -4 0 -10 1 -13 1 -3 3 -15 4 -28 3 -26 -9 -29 -20 -4
-10 24 -13 214 -3 233 4 9 5 20 1 24 -4 3 -7 76 -8 161 0 85 3 148 8 141 6 -9
8 -9 8 1 0 6 5 12 10 12 6 0 8 -12 4 -28 -4 -18 -3 -23 4 -14 7 11 11 8 16
-10 5 -15 9 -18 13 -10 3 8 0 19 -6 25 -16 16 -14 37 4 37 9 0 15 9 15 24 0
13 4 26 10 28 6 2 7 16 1 38 -5 19 -8 78 -8 130 2 84 4 95 21 98 14 3 18 -1
14 -15 -3 -10 1 -24 8 -31 19 -18 26 -15 14 7 -14 28 -13 49 2 28 12 -15 13
-13 15 15 1 17 8 35 14 38 8 6 8 9 0 14 -6 4 -11 12 -11 17 0 6 5 7 10 4 6 -4
10 5 10 20 0 17 4 24 12 19 8 -5 9 -2 5 9 -3 9 -2 19 2 21 5 3 6 18 4 33 -4
22 -2 25 6 13 12 -17 13 -4 2 25 -6 14 -2 25 12 38 11 9 17 20 13 24 -4 4 -11
1 -16 -7 -7 -12 -12 -12 -21 -3 -9 9 -16 10 -26 2 -9 -8 -13 -8 -13 0 0 6 5
11 11 11 8 0 9 4 0 13 -6 8 -8 17 -4 20 12 13 23 7 23 -13 0 -21 15 -27 25 -9
4 6 14 8 22 5 11 -4 13 -2 9 9 -3 8 -1 15 4 15 15 0 16 44 2 67 -13 20 -12 23
2 23 11 0 14 -5 10 -12 -4 -7 -3 -8 5 -4 14 9 5 26 -15 26 -9 0 -14 11 -14 30
0 33 -1 34 -145 33 l-60 -1 40 12 c38 11 38 12 15 19 -14 4 -38 6 -55 5 -26
-1 -25 -2 10 -8 39 -7 39 -7 8 -9 -18 0 -33 -5 -33 -10 0 -14 -122 -11 -139 3
-9 6 -12 7 -9 1 4 -7 -14 -12 -50 -13 -31 -1 -58 -5 -60 -7 -3 -3 -5 -19 -6
-37 l-1 -33 -260 -1 c-239 -1 -262 0 -287 17 -33 24 -45 24 -22 2 17 -17 17
-18 -5 -21 -13 -2 -28 1 -34 7 -7 7 -2 8 14 5 22 -5 23 -4 5 7 -16 10 -21 9
-32 -6 -12 -16 -13 -14 -12 18 1 20 5 34 10 31 5 -3 7 -12 4 -20 -3 -8 0 -11
9 -8 8 3 11 12 9 19 -6 14 -48 45 -61 45 -4 0 -1 -5 7 -10 9 -6 10 -10 3 -10
-6 0 -15 5 -19 11 -4 8 -9 7 -15 -2 -11 -18 -21 -6 -25 32 l-4 32 20 -23 c11
-13 23 -21 25 -18 3 3 -7 16 -23 30 -16 13 -32 31 -35 39 -4 10 -6 8 -6 -6 -1
-15 -3 -17 -10 -6 -6 9 -11 10 -15 2 -12 -18 -32 -12 -32 10 0 19 1 19 14 2
12 -16 13 -15 6 12 -7 28 -6 29 11 15 10 -9 7 1 -10 25 -14 22 -31 40 -36 39
-27 -3 -31 -5 -26 -17 3 -8 -3 -13 -17 -13 -21 0 -22 4 -20 61 1 33 3 65 4 70
5 23 -2 31 -27 29 -21 -1 -31 6 -40 25 -10 20 -12 21 -7 4 2 -12 1 -24 -3 -26
-5 -3 -9 120 -10 274 l-1 279 33 -3 c24 -3 38 2 50 16 9 11 13 22 8 26 -10 7
-11 27 0 37 4 4 7 -2 7 -14 0 -11 4 -23 9 -26 10 -7 48 20 40 29 -4 3 -14 -1
-22 -10 -14 -14 -16 -13 -14 3 0 11 10 19 26 20 28 2 68 35 77 64 3 10 10 16
15 13 9 -6 11 4 11 56 1 14 7 26 14 26 14 0 14 -18 0 -28 -4 -4 -3 -9 3 -13 6
-4 13 4 17 19 3 13 9 23 13 21 10 -7 29 7 33 24 2 8 16 25 32 38 17 12 25 22
19 22 -7 0 -15 -5 -19 -12 -5 -8 -10 -6 -16 8 -7 16 -8 15 -4 -5 3 -17 2 -21
-6 -13 -5 5 -7 20 -3 32 6 17 9 19 22 8 12 -10 17 -9 24 5 6 9 15 17 21 17 6
0 5 -7 -3 -17 -7 -10 4 -2 24 16 38 36 47 53 25 48 -7 -1 -12 3 -11 10 2 7 -4
10 -12 7 -9 -4 -15 0 -15 9 0 20 16 31 25 17 4 -6 11 -7 17 -4 8 5 9 1 4 -11
-5 -14 -4 -16 7 -7 8 7 16 10 19 7 3 -3 13 4 23 15 18 20 18 20 -8 18 -21 -2
-24 -5 -14 -18 9 -12 9 -13 -3 -2 -8 6 -22 12 -32 12 -10 0 -18 4 -18 10 0 5
14 8 30 6 29 -3 36 6 31 37 -1 5 3 6 9 2 5 -3 10 -1 10 5 0 6 5 8 12 4 7 -5 5
-11 -7 -21 -11 -7 -14 -13 -8 -13 7 0 20 11 31 25 11 14 24 25 30 25 5 0 9 21
8 47 -1 43 1 46 24 48 34 3 51 -5 43 -19 -3 -6 8 2 25 18 25 23 29 32 21 48
-5 10 -15 15 -21 12 -7 -5 -6 -11 5 -19 16 -11 15 -13 -1 -29 -18 -15 -19 -14
-18 28 0 44 1 45 30 42 39 -5 71 10 85 41 15 32 14 38 -4 23 -8 -7 -13 -16
-10 -20 3 -4 -1 -12 -8 -18 -9 -8 -12 -2 -13 24 -1 34 0 34 36 29 29 -4 40 -1
58 18 22 23 22 24 3 44 -19 20 -19 20 13 20 17 1 25 -1 19 -4 -7 -3 -13 -9
-13 -15 0 -6 9 -3 20 7 11 10 20 22 20 27 0 5 6 16 14 24 8 7 12 23 9 34 -3
12 0 20 6 20 7 0 9 -4 6 -10 -3 -5 -2 -10 4 -10 5 0 14 6 18 13 4 8 16 16 26
20 13 5 16 11 9 24 -5 10 -9 41 -9 68 0 28 2 42 4 33 3 -10 12 -18 21 -18 14
0 15 2 2 10 -8 5 -11 12 -7 16 3 4 13 1 22 -6 8 -7 21 -10 28 -7 8 3 14 -1 14
-8 0 -7 -8 -10 -19 -7 -10 2 -18 -1 -18 -8 0 -7 -7 -10 -15 -6 -12 4 -14 0 -9
-22 4 -21 3 -24 -5 -12 -7 9 -11 10 -11 3 0 -25 19 -22 32 4 7 14 20 29 28 31
11 4 10 -1 -4 -19 -11 -13 10 7 47 45 36 38 64 73 61 78 -3 5 -10 6 -15 2 -5
-3 -6 -10 -3 -16 4 -7 2 -8 -5 -4 -6 4 -8 12 -5 18 4 6 1 9 -7 6 -8 -3 -13
-12 -13 -21 1 -9 -2 -15 -6 -12 -5 3 -11 1 -15 -5 -9 -14 -25 -3 -25 17 0 9 6
13 15 10 8 -4 17 -1 21 5 4 6 3 8 -2 5 -6 -3 -16 -2 -24 3 -9 5 1 8 28 6 23
-2 42 1 42 5 0 5 7 6 15 3 11 -4 14 1 12 22 -1 17 3 29 10 29 6 0 15 4 20 9 4
5 3 6 -4 2 -6 -3 -15 -1 -19 5 -5 9 -1 10 14 7 25 -7 39 8 36 39 0 12 2 20 6
18 4 -3 10 2 14 10 6 15 39 16 53 0 5 -4 2 -5 -4 -1 -8 4 -13 1 -13 -7 0 -10
-7 -13 -19 -10 -11 3 -21 -2 -24 -11 -5 -14 -3 -15 13 -6 15 8 20 8 20 -1 0
-6 8 -1 19 12 13 16 17 36 15 67 -2 46 7 51 12 5 3 -36 2 -35 14 -28 8 5 7 11
-1 21 -9 11 -8 14 2 14 9 0 10 3 4 8 -23 17 -13 30 20 26 19 -1 38 2 41 8 4 6
3 9 -2 6 -5 -4 -9 10 -10 30 0 36 1 37 39 38 22 1 33 -2 26 -7 -7 -4 -17 -5
-21 -3 -7 5 -12 -10 -9 -28 0 -5 -4 -8 -11 -8 -6 0 -8 -3 -5 -7 4 -3 23 11 42
31 33 36 45 53 27 42 -5 -3 -7 5 -6 17 1 12 3 28 3 35 1 6 6 12 12 12 5 0 7
-4 4 -10 -3 -5 -1 -10 5 -10 6 0 8 -5 5 -10 -4 -6 -11 -7 -17 -4 -7 5 -8 2 -3
-5 5 -9 17 -3 42 23 19 19 32 39 29 45 -5 8 21 26 35 23 13 -2 39 36 41 61 1
14 6 23 10 21 4 -3 8 2 8 10 0 10 7 13 25 10 31 -5 44 22 40 82 -2 35 1 43 24
57 16 10 20 17 11 17 -9 0 -22 -6 -29 -12 -11 -11 -13 -10 -9 2 7 22 62 25 83
4 16 -16 16 -18 -2 -27 -10 -5 -26 -19 -35 -31 -13 -16 -16 -17 -15 -5 2 10 9
24 16 33 16 18 1 22 -17 4 -14 -14 -16 -44 -3 -52 12 -8 77 56 73 71 -2 7 2
13 8 13 5 0 10 6 10 14 0 8 5 18 12 22 8 5 9 1 5 -12 -4 -10 11 1 32 25 22 25
38 48 35 52 -5 8 15 24 31 24 5 0 14 10 21 22 6 13 15 20 20 15 4 -4 2 2 -6
13 -21 30 -24 32 -17 13 3 -10 2 -20 -4 -23 -5 -4 -6 -11 -3 -17 5 -7 2 -8 -6
-3 -9 6 -11 4 -5 -6 6 -11 5 -12 -6 -1 -16 14 -53 16 -44 2 4 -6 11 -8 16 -5
5 4 6 -2 2 -11 -5 -13 -8 -14 -11 -5 -4 11 -6 11 -13 0 -14 -23 -20 -3 -8 24
9 20 18 25 43 24 30 -2 31 0 30 35 -1 21 2 35 7 32 5 -3 9 -1 9 4 0 6 10 10
23 10 47 2 70 9 84 28 8 10 19 19 26 19 6 0 18 12 25 28 7 15 18 27 25 27 7 0
4 5 -6 11 -9 6 -15 14 -12 19 3 5 2 11 -3 14 -5 3 -9 -7 -9 -22 -1 -56 -2 -61
-23 -57 -11 2 -18 0 -15 -6 4 -5 -1 -9 -10 -9 -9 0 -15 -6 -13 -13 3 -13 -3
-17 -24 -17 -7 0 -6 6 4 18 9 9 13 24 11 32 -4 13 -3 13 6 0 8 -12 11 -12 11
-1 0 7 6 11 15 8 18 -8 29 9 29 48 1 29 2 30 48 30 34 0 52 5 65 19 16 18 16
19 1 7 -13 -10 -18 -10 -18 -2 0 7 -5 9 -12 5 -7 -4 -8 -3 -4 5 4 6 11 9 16 6
5 -3 11 -1 15 5 3 5 0 10 -8 10 -8 0 -17 7 -21 15 -8 21 6 19 32 -5 21 -19 36
-13 37 15 0 6 5 10 12 10 6 0 15 4 20 9 4 5 3 6 -4 2 -8 -4 -15 2 -19 18 -6
27 1 46 17 46 6 0 8 -4 5 -9 -4 -5 0 -12 6 -14 8 -3 6 -6 -4 -6 -22 -1 -24
-18 -3 -18 16 0 28 24 19 39 -3 5 0 9 7 7 30 -4 40 2 35 19 -3 9 1 28 9 42 10
18 14 20 15 8 0 -9 -5 -20 -10 -23 -6 -4 -8 -11 -4 -16 8 -14 34 9 28 26 -3 7
0 16 7 21 8 4 10 3 5 -4 -5 -8 -2 -10 8 -6 9 3 16 10 16 15 0 18 46 75 58 71
6 -2 21 13 33 32 11 20 25 36 31 36 5 0 6 5 2 11 -5 8 -9 8 -14 -1 -5 -8 -10
-9 -14 -2 -8 13 10 26 24 17 10 -6 50 24 50 38 0 5 -10 3 -23 -6 -21 -13 -25
-13 -46 4 -12 10 -20 23 -16 29 10 17 25 11 25 -10 0 -23 0 -23 33 -11 20 8
24 15 21 41 -3 28 -2 31 11 20 8 -7 15 -20 15 -29 0 -14 -2 -14 -10 -1 -8 12
-10 11 -7 -5 2 -17 17 -35 17 -20 0 3 16 19 35 36 19 17 33 35 30 39 -3 4 2
13 10 20 8 7 15 9 15 4 0 -5 11 8 24 28 12 21 26 35 30 31 4 -4 9 3 13 15 3
12 9 20 13 17 4 -2 17 6 29 19 19 21 20 24 6 36 -13 11 -19 9 -39 -9 -15 -15
-26 -19 -31 -12 -3 6 -1 11 4 11 6 0 10 5 8 11 -3 15 40 21 63 9 23 -12 38 6
21 23 -9 9 -10 7 -5 -7 5 -13 4 -17 -4 -12 -6 3 -9 19 -8 34 2 15 4 32 5 39 2
19 37 11 37 -8 0 -11 -7 -16 -18 -14 -10 2 -18 0 -18 -6 0 -5 3 -9 8 -10 4 0
17 -2 29 -3 17 -2 20 1 13 13 -6 11 -3 22 7 33 8 9 16 28 17 41 1 20 4 23 18
16 10 -6 15 -16 11 -25 -5 -13 -8 -13 -15 -2 -6 9 -7 5 -3 -11 5 -17 4 -22 -5
-16 -9 6 -11 4 -5 -5 6 -9 4 -11 -5 -5 -9 6 -11 4 -5 -5 6 -9 4 -11 -5 -5 -9
6 -11 4 -6 -4 10 -15 42 11 51 40 4 11 10 17 15 14 5 -3 12 0 16 6 4 8 3 9 -4
5 -7 -4 -12 -2 -12 3 0 6 7 11 15 11 8 0 15 4 15 8 0 4 -9 8 -19 8 -11 0 -17
-4 -15 -8 8 -13 -9 -9 -30 7 -17 13 -14 14 32 12 28 -2 49 0 46 5 -3 4 2 11
10 14 9 3 14 10 11 14 -3 5 -1 11 5 15 5 3 10 0 10 -7 0 -9 3 -9 10 2 5 8 10
20 10 25 0 6 -4 4 -9 -4 -5 -8 -11 -9 -15 -3 -10 16 13 25 29 12 8 -7 15 -8
15 -3 0 5 18 28 41 51 23 25 37 47 33 54 -5 8 -1 9 11 4 18 -7 18 -6 0 14 -15
17 -16 20 -3 15 21 -8 61 13 52 27 -4 6 -3 8 3 5 15 -9 41 24 37 47 -4 27 6
41 17 25 7 -11 9 -11 9 -1 0 7 4 11 9 7 14 -8 32 30 25 52 -6 16 -5 15 10 -2
15 -19 16 -19 9 -1 -4 13 -3 17 5 13 6 -4 20 6 31 22 16 21 18 31 9 34 -7 3
-3 6 9 6 26 1 38 17 22 27 -8 5 -9 3 -4 -6 6 -10 4 -11 -11 -6 -10 5 -28 11
-39 14 -18 5 -18 3 -6 -11 11 -14 11 -18 -3 -23 -9 -3 -16 -15 -16 -25 0 -10
-4 -19 -8 -19 -4 0 -8 19 -8 43 l-1 42 189 3 c128 2 188 -1 188 -8z m-320 -48
c0 -5 -7 -17 -15 -28 -15 -19 -20 -8 -9 20 6 17 24 22 24 8z m725 -91 c4 -12
13 -17 30 -15 14 1 32 -4 39 -12 13 -12 15 -12 19 4 4 13 5 11 6 -5 1 -18 -4
-23 -25 -23 -17 0 -23 4 -18 12 5 8 2 9 -10 5 -11 -5 -15 -3 -10 5 5 8 2 9 -9
5 -9 -3 -19 -2 -22 4 -4 5 -10 7 -14 5 -3 -3 -7 4 -7 15 0 24 13 25 21 0z
m-850 -40 c-1 -42 -10 -46 -12 -5 -1 19 1 34 5 34 5 0 7 -13 7 -29z m-75 -55
c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m24
-11 c-4 -8 -11 -15 -16 -15 -6 0 -5 6 2 15 7 8 14 15 16 15 2 0 1 -7 -2 -15z
m-67 -83 c-15 -9 -27 -19 -27 -22 0 -4 -6 -14 -13 -24 -11 -15 -13 -13 -13 21
0 37 0 38 40 39 l39 2 -26 -16z m-112 -88 c-4 -42 -22 -50 -21 -9 1 19 6 35
13 35 6 0 10 -11 8 -26z m1091 -98 c-1 -35 -25 -46 -47 -20 -8 10 -8 14 0 14
6 0 11 -4 11 -10 0 -5 5 -10 10 -10 7 0 7 6 0 19 -7 14 -7 22 0 26 6 4 8 11 5
16 -3 5 0 6 8 3 8 -3 13 -18 13 -38z m-1281 -146 c4 -6 -1 -7 -11 -3 -14 5
-16 3 -11 -11 4 -9 3 -15 -1 -12 -4 2 -16 -2 -27 -10 -10 -8 -15 -14 -10 -14
5 0 0 -6 -10 -14 -21 -15 -40 -12 -29 5 3 5 10 7 15 4 5 -4 9 -1 9 5 0 11 50
59 62 60 4 0 10 -4 13 -10z m1155 -4 c0 -2 -7 -9 -15 -16 -13 -11 -14 -10 -9
4 5 14 24 23 24 12z m-1270 -55 c0 -52 -34 -103 -36 -55 -1 13 2 22 6 19 4 -3
10 4 13 16 4 16 3 18 -9 8 -14 -11 -20 1 -7 14 9 10 33 9 33 -2z m20 -40 c0
-6 -4 -13 -10 -16 -5 -3 -10 1 -10 9 0 9 5 16 10 16 6 0 10 -4 10 -9z m-78
-39 c-6 -2 -9 -9 -6 -17 8 -21 -14 -27 -38 -11 -15 11 -18 16 -8 16 11 0 12 2
3 8 -8 5 2 8 24 8 20 0 31 -2 25 -4z m7 -48 c-12 -13 -25 -21 -28 -18 -3 3 4
14 16 24 12 9 19 21 15 25 -4 5 -1 5 6 1 11 -6 9 -13 -9 -32z m1266 -14 c3 -5
1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m-1348 -16
c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m21129 -7 c5 -4 0 -5
-10 -1 -14 5 -17 4 -12 -4 5 -8 0 -11 -16 -9 -15 1 -23 -4 -25 -18 -3 -17 -2
-18 9 -5 7 8 23 16 34 17 18 2 21 -2 18 -28 -3 -27 -1 -30 17 -24 17 6 20 4
13 -6 -6 -11 -4 -12 7 -4 13 9 29 11 67 6 10 -1 10 -4 0 -15 -7 -8 -17 -12
-22 -9 -4 3 -2 10 5 15 12 8 11 9 0 6 -22 -6 -25 -27 -4 -35 10 -3 30 -14 44
-24 14 -10 28 -16 31 -14 3 3 13 -4 23 -15 10 -11 24 -20 30 -20 7 0 19 -9 27
-21 9 -15 24 -21 50 -21 40 0 68 -20 47 -34 -8 -4 -9 -3 -5 4 5 8 2 10 -9 6
-9 -4 -15 -7 -13 -8 2 -1 12 -8 22 -16 13 -10 17 -10 12 -2 -4 7 -1 12 8 12
18 0 21 11 7 26 -6 5 2 10 22 11 23 2 28 0 17 -7 -8 -5 -15 -13 -15 -17 -1 -4
-6 -18 -13 -30 -10 -18 -9 -21 6 -19 11 2 17 11 16 29 -1 15 2 27 6 27 11 0
10 -53 -1 -66 -11 -13 5 -28 38 -38 26 -8 36 4 17 22 -8 7 -14 8 -14 2 0 -14
-27 -13 -36 2 -5 8 -2 9 10 5 10 -4 15 -3 11 3 -7 11 51 8 61 -3 4 -4 1 -14
-7 -23 -20 -24 -19 -41 2 -60 24 -22 59 -26 52 -7 -4 10 -9 11 -19 2 -11 -9
-14 -8 -14 2 0 9 13 15 33 16 23 1 34 -3 34 -13 0 -11 -3 -11 -16 -1 -12 10
-14 9 -8 -6 4 -11 1 -20 -8 -24 -13 -5 -13 -7 2 -18 17 -13 16 -14 -9 -24 -16
-6 -28 -13 -28 -17 0 -3 7 -4 15 0 8 3 15 1 15 -4 0 -6 5 -10 11 -10 6 0 9 7
6 15 -4 8 -2 17 4 20 5 4 7 11 4 16 -4 5 -2 9 2 9 5 0 9 -24 9 -52 0 -29 -2
-45 -4 -35 -2 9 -8 15 -13 11 -5 -3 -7 -9 -4 -14 3 -4 -10 -10 -28 -12 -20 -2
-34 1 -34 7 0 7 12 10 26 8 l26 -3 -25 9 c-20 7 -21 9 -6 10 22 1 19 7 -6 13
-9 1 -16 0 -15 -4 3 -10 -45 -58 -53 -53 -4 2 -19 -8 -34 -24 -23 -24 -25 -29
-12 -34 12 -5 16 0 16 16 1 20 2 21 14 5 13 -18 32 -26 22 -9 -3 5 0 14 7 20
10 8 19 7 33 -4 15 -11 16 -14 4 -15 -12 0 -13 -2 -1 -9 8 -6 -2 -8 -30 -8
-33 1 -43 -2 -41 -12 2 -10 -1 -12 -10 -5 -18 14 -43 12 -43 -3 0 -7 -3 -12
-6 -10 -10 4 -39 -25 -34 -33 3 -4 -2 -11 -10 -14 -8 -3 -15 -9 -14 -13 4 -21
-2 -33 -13 -27 -7 4 -8 3 -4 -4 4 -7 2 -12 -5 -12 -7 0 -9 -3 -6 -7 4 -3 2
-12 -3 -18 -6 -7 -12 -27 -13 -44 -1 -21 -6 -30 -15 -26 -8 3 -10 0 -6 -6 4
-6 1 -15 -7 -19 -9 -6 -13 -32 -14 -84 0 -41 2 -74 5 -74 17 1 23 -3 13 -10
-8 -5 -8 -12 2 -25 12 -16 13 -15 12 10 -1 15 -5 30 -8 33 -4 3 -9 28 -12 56
-4 40 -1 58 14 80 17 27 19 27 19 7 0 -12 -4 -25 -10 -28 -13 -8 -13 -105 -1
-105 11 0 9 -59 -2 -77 -4 -7 1 -18 13 -26 11 -7 17 -17 12 -22 -4 -5 -2 -5 4
-2 6 3 22 -5 37 -19 15 -14 41 -25 60 -26 25 -2 33 -7 29 -17 -3 -9 -1 -12 5
-8 7 5 10 -2 8 -19 -1 -15 3 -28 11 -31 8 -3 11 -9 8 -14 -3 -5 1 -9 9 -9 8 0
17 -7 21 -15 3 -8 11 -14 17 -13 7 2 15 -8 18 -21 4 -14 13 -26 21 -29 12 -3
13 -2 1 6 -10 7 -4 9 21 9 30 -1 34 -4 33 -28 0 -21 -3 -25 -12 -17 -7 6 -11
14 -8 18 3 5 1 11 -5 15 -5 3 -10 1 -10 -5 0 -17 124 -154 154 -169 14 -8 27
-21 29 -30 3 -11 5 -9 6 5 1 14 -6 24 -19 27 -11 3 -20 11 -19 19 0 10 2 10 6
0 4 -10 8 -10 21 0 12 10 14 10 8 1 -4 -8 -2 -13 4 -13 7 0 10 -13 9 -34 -3
-32 21 -73 35 -59 3 4 6 -3 6 -15 0 -15 5 -20 15 -16 8 4 15 10 15 16 0 5 -4
6 -10 3 -12 -7 -12 2 0 30 l9 20 2 -20 c1 -11 3 -30 4 -42 1 -12 -2 -20 -6
-17 -5 3 -9 1 -9 -4 0 -6 7 -13 16 -16 8 -3 13 -10 10 -14 -3 -5 2 -9 10 -9
10 0 11 4 4 11 -16 16 -12 22 15 22 14 0 25 -4 25 -9 0 -4 4 -6 8 -3 11 7 9
-70 -2 -81 -5 -5 -7 -1 -3 9 3 10 2 20 -4 24 -6 4 -6 11 2 20 6 8 9 14 5 14
-3 0 -8 0 -11 0 -3 0 -5 -3 -4 -6 0 -3 -7 -11 -17 -18 -13 -10 -14 -15 -4 -21
9 -5 11 -4 6 3 -4 7 -2 12 3 12 6 0 11 -8 11 -19 0 -10 7 -24 15 -31 8 -7 13
-16 10 -20 -10 -16 25 -38 53 -34 28 5 29 4 29 -45 0 -28 -4 -48 -9 -45 -4 3
-5 10 -2 16 4 7 2 8 -5 4 -9 -6 -9 -13 2 -29 8 -12 17 -34 20 -49 8 -31 35
-61 48 -53 14 9 11 25 -5 19 -7 -3 -16 2 -19 10 -4 11 1 14 21 12 28 -2 31
-12 17 -55 -4 -13 -3 -21 1 -18 5 3 9 -21 9 -53 1 -63 -9 -69 -24 -17 -6 20
-9 25 -10 12 0 -11 3 -26 8 -33 10 -16 2 -64 -9 -57 -9 6 -5 -63 4 -72 3 -4 6
7 7 23 1 16 5 40 9 54 4 14 5 -7 3 -50 -6 -81 -20 -114 -52 -123 -18 -4 -16 0
9 23 35 33 34 30 15 66 -10 20 -15 23 -15 12 0 -28 -1 -32 -13 -48 -6 -8 -2
-6 11 4 12 11 22 14 22 8 0 -6 -11 -20 -25 -31 -23 -17 -46 -69 -26 -57 4 3 7
-22 6 -54 -3 -95 -5 -99 -26 -55 l-18 40 -1 -32 c0 -17 -4 -35 -10 -38 -6 -4
-7 1 -3 12 6 15 5 16 -9 3 -9 -8 -14 -17 -12 -19 2 -2 -2 -11 -9 -19 -9 -13
-9 -14 1 -8 17 10 15 -1 -6 -32 -9 -15 -17 -36 -16 -47 1 -11 2 -37 3 -57 0
-21 5 -38 11 -38 10 0 7 43 -6 74 -6 15 -4 17 6 11 10 -6 11 -2 7 16 -3 13 -3
30 2 37 4 7 7 -28 7 -78 0 -49 -3 -91 -6 -91 -3 -1 -20 -3 -37 -5 l-33 -4 20
20 c13 13 19 31 18 50 -3 39 -11 38 -26 -2 -10 -30 -10 -31 3 -14 12 16 15 17
15 4 0 -8 -9 -22 -19 -29 -11 -8 -22 -25 -26 -39 -4 -15 -3 -20 2 -12 6 7 9
-27 9 -86 0 -75 -3 -99 -13 -99 -7 0 -13 4 -13 8 0 4 -10 5 -22 2 -22 -4 -22
-4 -4 11 11 9 15 20 10 28 -4 7 2 4 14 -6 22 -19 22 -19 22 10 0 22 -3 25 -10
14 -17 -28 -24 1 -6 30 9 17 13 30 7 30 -5 0 -13 -9 -16 -20 -3 -11 -11 -20
-16 -20 -6 0 -7 -5 -4 -10 4 -6 -4 -27 -16 -47 -13 -20 -17 -33 -11 -29 7 4
12 2 12 -4 0 -7 8 -9 20 -5 11 3 20 2 20 -3 0 -15 -31 -24 -45 -12 -8 7 -15 3
-24 -16 -6 -15 -10 -29 -7 -32 3 -2 -4 -23 -16 -46 -16 -32 -17 -38 -4 -27 9
8 16 9 16 3 0 -6 -5 -14 -11 -18 -8 -4 -7 -9 2 -15 7 -4 10 -11 6 -15 -3 -4
-14 -1 -24 7 -11 9 -14 10 -9 2 4 -7 3 -13 -1 -13 -5 0 -14 -19 -20 -42 -7
-24 -14 -52 -17 -63 -4 -11 0 -6 8 10 14 28 15 26 13 -55 -3 -82 -3 -85 -27
-87 -14 -1 -19 0 -12 3 6 3 12 13 12 22 -1 15 -2 15 -15 -2 -8 -10 -12 -23 -9
-28 4 -5 -4 -16 -16 -25 -12 -8 -19 -21 -16 -28 2 -7 -1 -18 -7 -25 -9 -10 -9
-11 0 -6 7 4 9 -1 6 -15 -5 -16 -8 -18 -15 -7 -6 9 -7 6 -3 -9 3 -13 3 -23 -2
-23 -9 0 -34 -72 -28 -79 5 -5 29 51 26 62 -1 5 3 6 8 3 5 -4 12 2 14 11 3 10
4 5 4 -10 -1 -16 -7 -33 -14 -39 -7 -6 -13 -17 -13 -24 0 -12 -11 -18 -31 -16
-3 1 -6 -4 -7 -11 -5 -37 -9 -53 -13 -62 -3 -6 -5 -31 -4 -57 1 -26 -1 -45 -6
-43 -4 3 -15 -9 -23 -26 -19 -39 -19 -40 -3 -33 10 3 14 -4 14 -26 0 -18 5
-35 13 -40 7 -4 9 -13 5 -20 -6 -9 -9 -9 -16 1 -6 10 -9 11 -9 2 0 -7 -7 -13
-15 -13 -8 0 -15 8 -15 18 0 13 2 14 9 3 7 -10 10 -10 16 -1 4 7 2 15 -4 19
-7 5 -9 16 -5 27 5 12 4 16 -3 9 -12 -13 -33 -85 -33 -117 0 -13 -6 -30 -12
-37 -8 -9 -9 -12 -1 -7 8 4 10 -10 8 -49 -1 -30 2 -55 6 -55 5 0 9 -4 9 -10 0
-19 -21 -10 -29 13 -7 19 -10 15 -16 -25 -4 -26 -4 -55 -1 -64 7 -18 -17 -57
-29 -46 -5 4 -5 1 0 -7 6 -11 12 -9 25 10 14 17 17 35 13 68 -4 40 -3 43 11
32 11 -10 13 -18 6 -31 -7 -13 -6 -21 2 -26 7 -4 10 -4 6 1 -5 5 1 15 13 23
15 10 19 21 15 38 -5 18 -3 22 9 18 8 -3 12 -11 8 -17 -3 -6 -1 -7 5 -3 7 4
12 13 12 21 0 8 -5 17 -12 21 -7 4 -8 3 -4 -4 4 -7 2 -12 -3 -12 -6 0 -11 6
-11 13 0 9 -3 8 -9 -2 -6 -9 -11 -10 -15 -2 -4 6 -4 11 1 12 4 0 17 2 28 4 10
2 26 -2 34 -8 12 -9 13 -9 7 1 -4 7 -2 12 4 12 7 0 9 12 6 34 -3 19 -2 37 3
40 14 9 21 -20 8 -34 -10 -10 -7 -10 10 -1 12 6 25 16 29 23 4 7 3 8 -4 4 -7
-4 -12 -2 -12 3 0 6 9 11 20 11 11 0 20 5 20 12 0 6 10 23 23 36 12 14 16 22
10 18 -18 -10 -16 9 2 24 12 10 15 10 15 -1 0 -8 6 -5 14 6 8 11 12 23 10 27
-3 5 1 8 8 8 8 0 6 5 -5 11 -21 12 -38 4 -30 -16 3 -8 0 -15 -6 -15 -10 0 -15
14 -12 33 1 5 -3 6 -8 2 -7 -4 -7 -15 -1 -30 7 -17 6 -25 -1 -25 -6 0 -9 -9
-6 -20 3 -11 1 -22 -4 -25 -4 -3 -7 21 -6 53 l2 59 43 -2 c36 -2 48 3 73 28
25 25 28 32 17 45 -7 9 -8 13 -2 9 6 -3 20 6 31 21 33 45 36 52 19 46 -9 -3
-14 -10 -11 -14 3 -5 1 -12 -5 -15 -7 -4 -10 5 -8 24 1 16 6 29 11 28 5 -1 12
4 14 11 3 7 -1 11 -9 10 -8 -2 -13 7 -14 22 -1 20 4 25 24 27 16 0 20 -2 12
-7 -7 -4 -9 -16 -5 -29 6 -20 25 -20 25 0 0 6 -4 7 -10 4 -5 -3 -10 -1 -10 4
0 20 20 11 30 -11 5 -13 9 -17 9 -10 1 7 -4 22 -9 33 -13 22 -7 25 13 5 11
-12 15 -11 27 8 12 19 12 20 -3 7 -10 -7 -17 -10 -18 -5 0 5 -2 19 -3 30 -4
31 15 50 49 49 30 0 30 0 28 53 -1 28 3 52 8 52 5 0 9 5 9 11 0 5 -4 8 -9 5
-5 -3 -8 5 -7 17 1 18 7 23 29 23 23 0 26 -3 21 -20 -4 -15 -3 -17 4 -7 6 8
12 9 16 3 3 -5 -1 -15 -9 -22 -12 -10 -16 -9 -19 1 -13 40 -14 42 -15 17 -1
-35 15 -44 39 -23 16 14 17 22 9 38 -10 17 -9 19 6 13 14 -5 15 -3 8 6 -6 7
-10 26 -9 42 1 22 6 30 25 33 18 2 22 0 17 -13 -3 -8 -6 -21 -6 -27 -1 -7 -7
-1 -15 13 l-14 25 2 -32 c2 -35 14 -38 31 -9 6 11 21 30 34 44 12 14 22 28 23
31 9 62 7 72 -9 60 -14 -11 -15 -11 -4 1 13 15 17 34 4 26 -4 -2 -8 4 -8 15 0
12 4 15 10 9 6 -6 14 -3 22 8 11 14 15 15 26 3 11 -12 10 -13 -4 -8 -19 7 -25
-8 -8 -18 7 -4 7 -12 0 -25 -15 -29 -12 -37 5 -13 8 12 16 33 17 47 1 14 8 28
15 30 7 3 13 16 12 28 -1 23 -1 22 -10 -2 -6 -19 -7 -13 -4 23 2 26 7 52 10
57 3 6 1 10 -5 10 -6 0 -10 8 -8 17 2 13 13 18 39 18 28 0 35 -3 31 -15 -3 -8
-1 -11 4 -7 11 11 34 88 24 82 -4 -3 -11 2 -14 11 -3 8 -2 12 4 9 5 -3 12 6
16 20 3 14 10 25 15 25 5 0 9 5 9 11 0 8 -4 8 -14 0 -12 -10 -16 -8 -22 11
-12 38 -14 83 -2 67 7 -11 9 -9 5 7 -3 13 1 21 9 21 18 0 18 -27 0 -53 -10
-13 -11 -25 -5 -35 6 -11 9 -8 9 11 0 18 3 21 9 11 7 -11 12 -9 22 10 12 23
11 23 -6 9 -17 -14 -18 -13 -12 3 4 10 7 25 7 33 0 8 6 14 14 14 8 0 19 14 25
30 5 17 6 28 2 26 -5 -3 -8 7 -8 22 0 15 2 21 4 15 3 -7 9 -13 14 -13 5 0 4
10 -2 23 -20 35 -21 127 -2 134 34 13 38 -58 4 -84 -15 -11 -14 -13 1 -27 10
-9 13 -11 8 -4 -14 17 -12 28 3 22 13 -5 19 17 20 69 0 19 4 26 13 23 8 -3 15
1 15 9 2 24 4 29 12 43 5 7 4 12 -2 12 -6 0 -10 5 -9 12 4 18 22 37 30 31 4
-2 5 2 2 10 -3 8 2 21 10 30 13 12 13 16 2 20 -9 4 -14 19 -13 41 1 34 3 35
30 31 26 -5 28 -3 22 16 -5 16 -4 19 5 14 10 -6 11 -4 4 6 -14 22 -14 82 0 70
7 -6 18 -6 26 -1 11 7 8 10 -13 15 -15 4 -27 13 -27 20 0 20 38 29 57 13 10
-7 14 -8 10 -1 -4 6 -2 13 3 15 6 2 12 13 13 26 0 12 6 22 11 22 6 0 5 5 -2
12 -7 7 -12 17 -12 23 0 5 4 3 10 -5 5 -8 12 -12 15 -9 8 9 -6 40 -15 34 -11
-7 -6 72 5 82 4 5 6 0 2 -9 -3 -10 0 -19 10 -22 12 -5 13 -9 3 -22 -11 -12
-11 -17 0 -27 10 -9 11 -9 6 1 -4 6 -3 12 3 12 5 0 8 8 7 18 -5 26 12 64 25
56 8 -4 9 -3 5 4 -4 7 -2 12 5 12 11 0 11 2 0 13 -13 13 -27 -2 -43 -48 -4
-11 -5 -2 -2 19 5 37 4 38 -11 22 -20 -19 -28 -16 -19 7 3 9 14 14 25 12 26
-5 49 4 49 20 0 17 35 47 45 38 4 -5 5 -2 1 5 -4 6 -2 12 4 12 8 0 7 6 -1 21
-7 14 -8 24 -1 31 6 6 8 16 6 23 -3 6 2 12 10 12 11 0 12 -6 5 -25 -11 -30 -5
-41 9 -15 5 10 8 37 7 61 -1 23 2 45 6 48 5 3 9 10 9 15 0 6 -4 8 -9 5 -5 -3
-8 5 -7 18 1 30 12 25 20 -10 4 -15 2 -32 -5 -40 -9 -11 -9 -14 1 -14 10 0 10
-3 0 -15 -9 -10 -9 -15 -1 -15 10 0 12 7 10 26 0 3 8 12 18 21 10 8 12 12 6 8
-19 -9 -16 30 2 45 10 8 13 20 9 34 -6 19 -5 19 10 7 14 -12 19 -12 29 -1 9
10 9 11 0 6 -8 -4 -13 -2 -14 6 -5 71 -5 73 23 74 15 1 25 -3 22 -7 -2 -4 -11
-6 -18 -3 -17 6 -31 -12 -16 -21 6 -3 23 7 39 23 27 27 29 33 26 95 -1 37 1
70 6 73 5 3 6 -2 3 -11 -5 -11 -2 -14 10 -9 10 4 16 1 16 -7 0 -7 3 -9 6 -6 4
4 2 13 -4 20 -9 10 -8 14 3 14 20 0 28 -27 13 -46 -12 -14 -11 -15 5 -2 10 8
15 21 12 32 -3 9 -1 20 5 24 5 3 10 1 10 -6 0 -7 3 -9 6 -6 4 4 1 14 -6 22
-10 12 -10 19 -2 27 15 15 15 31 0 22 -6 -4 -8 -3 -5 3 4 6 21 9 38 7 24 -3
32 0 36 16 3 11 10 17 16 13 6 -4 7 -1 2 7 -14 22 -14 103 0 116 7 8 17 9 26
3 19 -12 32 -11 24 1 -3 6 1 7 10 4 13 -5 15 -1 10 27 -3 18 -3 30 1 26 4 -4
13 -2 21 4 13 12 19 -2 6 -15 -12 -12 -12 -37 0 -33 7 2 13 10 13 18 1 8 2 20
3 27 0 6 5 12 10 12 6 0 8 -10 6 -21 -4 -21 -4 -21 15 -4 11 10 16 23 13 29
-4 6 -9 19 -10 30 -2 13 1 17 13 12 10 -4 14 -2 11 6 -2 7 -9 12 -14 11 -5 -1
-9 6 -9 15 0 12 9 17 31 16 39 -1 67 -13 59 -26 -3 -5 3 -4 14 3 12 7 18 17
16 21 -3 4 4 8 15 8 12 0 21 6 21 13 0 10 46 36 72 41 16 3 16 13 0 13 -9 0
-20 -5 -24 -11 -5 -7 -8 -7 -8 2 0 7 -5 10 -10 7 -6 -4 -8 -11 -5 -16 4 -5 0
-9 -7 -9 -8 0 -14 8 -14 18 0 15 8 18 51 16 65 -1 134 14 114 27 -10 6 -5 10
21 15 l34 7 -34 14 -35 13 33 -5 c20 -3 31 -1 27 5 -8 14 32 11 75 -4 l35 -13
-32 -7 c-35 -8 -55 -26 -29 -26 8 0 18 5 21 10 9 15 35 12 35 -5 0 -9 -7 -13
-17 -10 -10 3 -5 -1 10 -8 15 -7 48 -12 72 -11 25 1 47 -2 50 -7 4 -5 14 -9
23 -9 15 0 15 2 2 10 -13 8 -13 10 2 10 13 0 16 -8 15 -36 -2 -29 3 -40 23
-53 20 -13 23 -14 16 -1 -7 12 -6 13 5 3 14 -14 33 -18 26 -5 -3 4 18 8 46 9
36 1 47 -1 37 -7 -9 -6 -10 -10 -3 -10 6 0 10 -11 8 -26 -3 -28 22 -43 61 -39
16 1 20 -4 20 -26 0 -20 -4 -26 -15 -21 -9 3 -5 -4 9 -15 13 -11 31 -18 39
-15 8 3 12 1 8 -5 -4 -6 0 -14 8 -17 8 -3 19 -7 23 -8 4 -2 20 -18 36 -36 27
-30 75 -65 63 -45 -3 5 -2 15 4 23 7 12 9 11 9 -5 0 -11 10 -31 21 -45 14 -16
19 -19 15 -7 -3 10 0 17 7 17 8 0 7 4 -3 9 -8 6 -18 7 -22 5 -4 -3 -8 0 -8 5
0 6 6 11 13 11 8 0 7 4 -3 10 -9 6 -10 10 -3 10 6 0 15 -5 19 -11 4 -8 9 -7
15 2 4 7 7 -7 5 -35 -1 -26 1 -49 6 -52 5 -3 6 -9 3 -14 -3 -5 0 -12 6 -16 7
-4 9 -3 6 3 -4 6 4 18 18 27 23 15 25 15 26 -1 0 -16 2 -15 9 2 6 15 8 10 6
-20 -1 -22 -5 -47 -10 -55 -7 -12 -6 -13 5 -2 18 16 46 15 52 -2 3 -8 -1 -20
-9 -27 -27 -22 10 -39 89 -40 9 0 17 -4 17 -8 0 -4 -13 -7 -30 -6 -16 0 -30 3
-30 7 0 4 -7 5 -15 1 -14 -5 -14 -8 1 -24 9 -10 20 -16 25 -14 4 3 10 1 14 -5
4 -6 11 -8 16 -5 5 4 12 -1 15 -9 3 -9 9 -14 13 -12 3 2 7 -16 8 -41 2 -52 49
-126 64 -102 5 8 6 21 3 31 -2 9 -1 19 4 22 10 6 10 -214 0 -230 -4 -5 -3 -28
2 -50 12 -51 12 -76 -1 -49 -7 15 -8 13 -3 -10 4 -16 7 -85 8 -153 1 -96 -1
-119 -10 -107 -9 12 -13 13 -17 3 -4 -9 -6 -9 -6 3 -1 8 4 20 11 27 9 9 9 12
0 12 -7 0 -12 -5 -12 -11 0 -5 -4 -7 -10 -4 -13 8 -13 -1 1 -26 5 -11 14 -17
18 -14 5 3 11 -1 14 -8 3 -9 -4 -13 -23 -12 -19 0 -26 4 -23 15 2 8 -2 4 -9
-10 -7 -14 -17 -28 -23 -32 -7 -5 -6 -8 1 -8 14 0 12 -44 -5 -93 -8 -25 -19
-37 -29 -36 -31 3 -43 -1 -37 -11 3 -5 1 -10 -4 -10 -6 0 -11 -5 -11 -11 0 -5
5 -7 10 -4 14 9 12 -8 -2 -24 -7 -8 -8 -11 -3 -8 7 3 10 -11 9 -36 -2 -58 -2
-56 -24 -54 -18 1 -18 3 -4 24 8 13 12 28 9 32 -3 5 -2 11 2 14 5 2 3 2 -4 1
-6 -2 -14 2 -17 9 -3 9 -5 9 -5 0 -1 -6 4 -15 10 -18 6 -4 0 -20 -17 -42 -16
-22 -28 -49 -28 -69 -1 -19 -5 -34 -10 -34 -4 0 -5 7 -2 16 3 9 2 12 -4 9 -6
-4 -10 -3 -9 2 6 32 -4 33 -31 3 -17 -19 -28 -40 -26 -47 3 -7 0 -15 -6 -19
-7 -5 -8 -2 -3 6 6 9 4 11 -5 5 -10 -6 -9 -11 5 -22 16 -11 17 -15 5 -26 -10
-10 -11 -9 -5 3 8 13 7 13 -8 1 -15 -12 -17 -11 -17 3 0 10 -5 14 -10 11 -6
-4 -7 -11 -4 -17 4 -7 2 -8 -4 -4 -14 8 -28 -21 -26 -54 1 -20 -4 -26 -23 -28
-12 -1 -23 3 -23 8 0 5 7 6 17 3 12 -5 14 -3 8 7 -5 9 -4 11 3 6 7 -4 12 -3
12 2 0 22 -15 19 -44 -10 -21 -22 -31 -41 -31 -62 0 -30 -1 -31 -45 -31 -47 0
-51 -6 -35 -50 6 -16 5 -17 -4 -5 -9 13 -11 13 -11 -2 0 -9 6 -18 13 -21 6 -2
-8 -4 -33 -3 l-45 1 1 -42 c1 -37 0 -40 -13 -28 -8 8 -20 15 -26 15 -7 0 -4
-6 5 -14 14 -11 8 -12 -43 -12 l-60 0 23 21 c27 25 58 38 58 25 0 -6 -8 -10
-17 -11 -11 0 -13 -2 -5 -6 19 -8 65 26 58 43 -3 8 -2 12 3 9 5 -3 13 -1 16 5
4 6 16 10 27 8 16 -2 22 3 23 20 1 20 0 20 -14 8 -9 -8 -28 -19 -44 -25 -15
-6 -27 -17 -27 -26 0 -8 -5 -15 -11 -15 -5 0 -8 4 -4 9 3 5 -8 3 -25 -5 -16
-9 -32 -20 -35 -25 -4 -5 -11 -6 -17 -3 -7 5 -8 2 -3 -6 6 -10 4 -12 -8 -7
-10 4 -16 2 -14 -4 1 -6 -11 -13 -28 -15 -16 -2 -52 -9 -80 -16 -27 -6 -53
-11 -57 -10 -5 1 -8 -3 -8 -8 0 -6 10 -8 24 -4 16 4 25 1 29 -10 4 -9 13 -16
22 -16 8 0 15 -3 15 -7 0 -5 -21 -9 -48 -10 -37 -1 -41 0 -22 7 l25 8 -27 1
c-16 1 -28 -3 -28 -9 0 -5 -13 -10 -30 -10 -20 0 -28 4 -24 13 3 8 -6 14 -28
15 -17 1 -37 7 -43 12 -14 11 -18 73 -5 65 5 -3 11 -1 14 4 3 5 -1 11 -9 15
-18 7 -19 35 -1 59 11 14 15 15 25 4 6 -8 7 -18 3 -23 -4 -5 3 -1 15 10 20 17
22 46 2 46 -13 0 -11 -18 4 -23 10 -4 10 -6 0 -6 -7 -1 -23 9 -35 21 -15 15
-20 17 -15 5 3 -9 2 -19 -3 -22 -6 -4 -10 3 -10 14 0 23 22 41 28 23 2 -7 8
-12 13 -12 6 0 8 4 5 8 -2 4 4 8 14 8 10 0 17 -4 14 -7 -2 -4 1 -11 8 -15 7
-4 10 -4 6 1 -4 4 -3 16 3 27 6 11 7 24 1 31 -4 7 -9 28 -10 47 -2 25 1 31 7
20 5 -8 7 -19 4 -25 -3 -5 0 -5 7 2 11 10 10 17 -4 37 -20 32 -21 42 0 25 14
-11 18 -11 28 2 6 8 20 18 30 22 15 6 13 2 -7 -19 -15 -15 -27 -34 -27 -43 0
-10 5 -7 13 9 8 14 19 24 26 22 7 -1 10 2 7 7 -4 5 -2 12 4 16 7 4 7 12 0 25
-11 21 2 36 23 24 8 -5 8 -3 -2 9 -7 9 -17 15 -21 12 -5 -3 -8 15 -8 40 1 24
5 42 10 39 4 -3 8 1 8 8 0 9 3 9 10 -2 8 -12 10 -12 10 -1 0 11 5 10 20 -4 18
-17 20 -17 14 -2 -3 10 -1 17 5 17 6 0 8 7 4 17 -5 12 -3 14 8 8 8 -4 11 -5 7
0 -4 4 -2 17 4 29 10 18 9 19 -3 9 -12 -10 -14 -3 -15 44 -1 35 2 50 7 42 7
-11 9 -10 9 4 0 9 5 17 10 17 6 0 10 -6 10 -12 0 -7 11 -1 24 14 20 24 23 36
20 88 -2 35 0 59 5 56 5 -4 7 -15 4 -25 -3 -10 1 -22 8 -26 8 -5 10 -4 5 3 -4
6 -2 21 4 33 15 28 22 81 10 74 -6 -4 -11 -21 -11 -38 -1 -18 -3 -24 -6 -14
-2 9 -8 15 -11 13 -4 -2 -8 18 -8 44 -1 33 3 50 12 54 8 3 14 12 14 21 0 8 5
15 10 15 15 0 12 -16 -5 -30 -9 -7 -14 -19 -11 -26 3 -7 1 -16 -4 -19 -6 -4
-10 -11 -10 -16 0 -6 7 -4 15 5 8 8 15 24 15 35 0 12 5 21 10 21 6 0 10 9 10
20 0 11 4 20 10 20 5 0 7 -8 4 -17 -5 -15 -4 -15 4 -3 6 8 9 17 6 21 -2 4 0
10 6 14 5 3 10 12 10 18 0 7 -4 6 -10 -3 -6 -9 -10 -10 -10 -3 0 6 7 18 15 27
18 18 19 30 2 23 -9 -3 -13 7 -15 37 -2 54 7 84 19 65 6 -9 7 -2 3 20 -5 23
-3 36 7 43 12 8 12 9 -1 6 -8 -3 -17 -15 -21 -29 -4 -16 -6 -9 -6 23 0 26 4
45 9 42 4 -3 8 0 8 5 0 6 -4 11 -10 11 -5 0 -10 4 -10 10 0 12 63 8 72 -5 4
-5 8 10 9 35 0 25 2 80 3 123 1 43 -1 83 -5 89 -4 6 -22 11 -41 10 -54 -1 -66
-5 -61 -18 2 -7 -3 -11 -14 -11 -12 2 -18 -5 -19 -20 -1 -12 2 -20 7 -17 4 2
5 12 2 21 -5 12 -3 14 7 8 8 -5 17 -2 25 8 10 14 12 11 11 -18 -1 -19 -5 -35
-10 -35 -4 0 -6 7 -2 15 7 19 -8 19 -24 0 -7 -9 -15 -13 -18 -11 -2 3 -14 0
-26 -6 -11 -7 -24 -11 -27 -10 -4 1 -5 -8 -2 -18 3 -11 9 -20 14 -20 5 0 6 6
3 14 -3 8 -2 16 3 19 5 3 8 -9 8 -26 -1 -29 -4 -32 -30 -32 -46 0 -53 -9 -48
-67 3 -38 1 -49 -6 -38 -5 8 -7 18 -4 23 2 4 2 15 -1 23 -5 14 -8 13 -23 -1
-16 -16 -16 -18 1 -31 18 -13 18 -15 2 -46 l-17 -33 5 32 c7 41 -6 49 -30 20
l-19 -22 23 19 c29 25 29 9 0 -21 -21 -22 -21 -24 -3 -17 11 4 17 3 12 -1 -4
-5 -17 -9 -30 -10 -22 -2 -27 -20 -5 -20 7 0 12 -11 12 -25 0 -17 -5 -25 -17
-25 -9 0 -16 5 -15 10 2 6 7 9 12 7 5 -2 3 5 -5 14 -13 16 -14 16 -17 1 -2
-10 -8 -17 -13 -17 -6 0 -8 -4 -5 -9 4 -5 1 -11 -5 -13 -7 -2 -11 -33 -12 -79
l0 -75 -36 1 c-28 0 -39 -5 -48 -24 -7 -13 -13 -28 -12 -35 0 -6 4 -2 9 9 6
15 8 10 7 -20 0 -40 -22 -79 -38 -69 -5 3 -3 11 4 18 6 8 15 12 20 9 4 -2 7 1
7 6 0 6 -5 11 -11 11 -5 0 -7 5 -4 10 3 6 1 10 -5 10 -7 0 -10 -7 -6 -15 3 -8
2 -15 -3 -15 -20 0 -45 -77 -46 -142 0 -36 -4 -70 -9 -75 -5 -5 -6 0 -2 11 6
16 4 17 -9 6 -10 -8 -12 -16 -6 -19 11 -7 3 -51 -8 -51 -15 0 -20 14 -10 26 6
7 8 18 4 24 -7 11 -26 14 -23 3 1 -5 -4 -19 -11 -32 -8 -14 -9 -21 -2 -17 6 4
11 0 11 -8 0 -9 9 -16 20 -16 11 0 20 5 20 10 0 6 7 10 16 10 9 0 14 -6 12
-12 -6 -18 -66 -22 -85 -6 -15 12 -15 11 -5 -9 7 -11 11 -52 10 -90 -1 -58 -4
-69 -19 -69 -10 0 -15 4 -13 8 3 4 1 8 -5 8 -5 0 -13 -5 -16 -10 -4 -6 -11 -7
-17 -4 -10 7 -28 -13 -28 -31 0 -5 4 -5 8 2 4 6 8 -6 8 -27 1 -22 -2 -37 -7
-34 -5 3 -9 15 -9 27 -1 20 -2 20 -16 -6 -8 -15 -12 -32 -9 -37 3 -6 0 -10 -7
-10 -8 0 -7 -5 4 -16 17 -17 39 -12 37 9 0 9 -2 9 -6 0 -8 -22 -21 -14 -19 12
0 14 4 25 7 25 14 0 27 -26 26 -50 -2 -30 -34 -36 -55 -11 -11 14 -13 14 -9 1
2 -8 -2 -21 -10 -28 -7 -8 -10 -18 -7 -24 4 -6 0 -14 -7 -19 -8 -5 -9 -9 -3
-9 6 0 9 -21 9 -50 -3 -77 -6 -89 -15 -60 l-8 25 -1 -26 c-1 -35 -11 -42 -28
-21 -12 16 -13 16 -6 -2 4 -13 3 -17 -5 -12 -7 4 -12 3 -12 -3 0 -5 5 -13 10
-16 6 -3 10 -1 10 5 0 6 5 8 11 4 6 -3 15 -1 19 6 9 15 30 5 30 -15 0 -10 -12
-12 -44 -10 -36 2 -45 -1 -49 -16 -3 -11 -1 -19 4 -19 5 0 2 -12 -7 -27 -10
-18 -11 -22 -2 -11 13 15 15 12 14 -32 -1 -26 -5 -48 -10 -47 -6 1 -12 -7 -14
-18 -2 -11 -11 -25 -19 -32 -18 -14 -9 -35 12 -26 8 3 15 16 15 28 0 13 3 26
8 28 6 4 7 -21 3 -66 -1 -5 -19 -7 -40 -4 -32 3 -40 1 -44 -14 -3 -11 -1 -17
3 -14 10 6 10 -35 0 -50 -13 -18 -21 -65 -11 -65 5 0 11 10 14 23 2 12 3 -11
3 -51 l-1 -73 -37 3 c-21 2 -39 -1 -39 -7 -3 -21 -8 -51 -14 -80 -4 -21 -3
-26 4 -15 7 10 10 5 9 -20 -1 -19 -3 -29 -5 -22 -3 6 -9 12 -14 12 -4 0 -5 -7
-2 -17 5 -12 3 -14 -7 -8 -8 5 -11 4 -7 -2 4 -6 9 -39 13 -74 3 -35 8 -66 11
-69 3 -3 3 23 0 56 -2 34 -1 68 4 75 6 9 8 -15 7 -66 -3 -78 -3 -79 -29 -80
-20 -1 -25 2 -22 15 3 10 -1 21 -8 26 -9 5 -10 2 -6 -11 4 -9 3 -23 -2 -29 -5
-6 -11 -31 -14 -56 -2 -25 -8 -54 -12 -65 -5 -13 -4 -16 2 -7 7 9 10 4 10 -17
0 -25 -3 -28 -14 -20 -16 14 -17 -3 -1 -19 9 -9 13 -69 13 -222 0 -115 -1
-207 -3 -206 -2 2 -5 30 -8 62 -2 31 -7 60 -10 63 -3 3 -5 -13 -2 -36 4 -49
-6 -56 -20 -15 -6 15 -11 20 -11 11 -1 -9 8 -28 19 -42 11 -14 20 -39 20 -57
0 -17 4 -29 8 -26 10 6 11 -37 0 -45 -5 -3 3 -16 17 -29 14 -13 25 -18 25 -11
0 8 5 7 16 -4 15 -14 18 -14 33 0 20 17 51 20 51 5 0 -21 30 -9 31 13 3 52 3
52 39 48 19 -1 38 -8 42 -15 6 -8 8 -7 8 2 0 7 4 11 8 8 5 -3 9 1 9 9 0 18
-17 20 -18 3 0 -10 -2 -10 -5 -1 -3 7 -11 10 -19 7 -8 -3 -21 -2 -30 3 -11 6
4 9 44 8 33 -1 64 -5 68 -10 5 -4 -1 -5 -12 -1 -15 5 -17 4 -8 -5 12 -12 37
-3 28 11 -3 5 -1 11 3 14 5 3 8 20 7 39 -2 32 -2 33 39 33 l41 -1 -3 42 -3 42
65 -4 c35 -1 70 -6 77 -10 7 -5 10 -4 6 2 -4 5 6 16 21 24 15 8 33 20 40 27 8
6 23 17 34 23 15 8 17 14 9 22 -16 16 -14 27 5 27 9 0 12 -5 8 -12 -5 -9 -2
-9 9 1 18 15 23 26 7 16 -5 -3 -10 -1 -10 4 0 6 -5 10 -12 8 -6 -1 -13 7 -15
18 -3 16 -2 17 6 5 7 -10 11 -11 11 -2 0 6 7 12 15 12 8 0 15 -5 15 -11 0 -5
-5 -7 -12 -3 -7 5 -8 3 -3 -6 6 -10 11 -9 21 4 8 12 14 14 19 6 5 -8 13 -5 27
9 10 12 24 18 30 15 7 -4 8 -2 4 4 -4 7 -3 12 3 12 5 0 23 11 40 25 22 19 28
32 25 50 -3 19 -1 23 11 19 8 -3 12 -11 9 -16 -10 -15 30 8 46 27 12 14 12 18
0 25 -8 5 -17 7 -21 4 -4 -2 -6 11 -5 28 4 48 5 50 16 43 8 -5 7 -11 -1 -21
-8 -10 -8 -14 0 -14 6 0 11 5 11 10 0 6 9 10 19 10 23 0 35 24 33 70 -1 26 1
31 8 20 5 -8 7 -23 4 -32 -3 -10 -1 -18 5 -18 6 0 11 -5 11 -11 0 -5 -4 -8 -9
-4 -5 3 -12 -4 -16 -15 -3 -11 -13 -20 -20 -20 -9 0 -12 -6 -8 -17 5 -12 2
-15 -10 -10 -9 3 -17 2 -17 -3 0 -21 54 -2 56 20 1 5 2 15 3 21 0 5 6 7 11 4
6 -3 10 -1 10 6 0 7 3 9 7 6 10 -11 63 44 55 57 -10 16 -9 87 2 83 5 -2 10
-15 12 -30 1 -15 -1 -25 -7 -22 -5 3 -6 -1 -3 -9 9 -22 16 -20 49 14 16 17 25
30 21 30 -5 0 -3 7 4 15 7 8 16 13 20 10 4 -2 20 9 35 25 16 17 25 30 19 30
-5 0 -1 5 8 11 9 5 15 16 12 24 -4 8 -10 12 -15 9 -5 -3 -7 -10 -3 -15 3 -6
-3 -13 -15 -16 -12 -3 -21 -9 -21 -14 0 -5 -5 -9 -11 -9 -6 0 -9 6 -6 14 3 7
1 18 -5 24 -6 6 -1 10 16 10 15 0 34 10 46 23 l19 24 1 -22 c0 -26 15 -38 26
-22 5 8 3 9 -6 4 -10 -6 -12 -3 -8 12 3 13 -1 24 -13 32 -15 8 -19 8 -19 -1 0
-6 -3 -9 -6 -5 -3 3 -2 13 4 23 6 11 11 13 16 5 4 -6 15 -11 24 -11 15 0 15 2
2 10 -13 9 -13 10 0 10 8 0 22 -4 30 -10 12 -8 12 -10 -3 -16 -9 -3 -17 -13
-17 -21 0 -11 6 -8 19 8 14 17 16 28 9 37 -13 15 4 16 20 0 9 -9 12 -9 12 0 0
7 -5 12 -12 12 -16 0 -1 13 18 16 11 2 14 -3 9 -14 -3 -9 -4 -14 -2 -12 64 69
155 186 147 188 -5 2 -8 8 -4 13 3 5 0 9 -5 9 -6 0 -11 7 -11 16 0 12 5 11 19
-7 l19 -24 42 43 c23 23 39 42 34 42 -4 0 0 6 9 13 15 10 13 12 -15 13 -18 1
-25 -1 -15 -3 9 -2 15 -8 12 -13 -3 -5 -13 -6 -23 -3 -15 5 -15 4 -2 -8 14
-12 13 -17 -11 -41 -25 -26 -28 -27 -40 -10 -11 14 -11 15 0 5 11 -9 18 -7 32
10 9 12 14 27 9 32 -4 6 -7 18 -7 29 0 15 6 17 32 13 45 -7 56 3 50 49 -3 27
-2 34 5 24 7 -11 10 -11 10 -2 0 7 5 10 11 6 8 -5 8 -9 -1 -14 -7 -4 -9 -13
-5 -20 6 -9 4 -11 -5 -5 -9 5 -11 4 -6 -3 11 -18 27 -14 24 5 -2 10 3 18 10
18 26 0 39 34 37 90 -2 42 0 55 11 55 17 0 19 17 2 22 -6 2 -13 14 -15 27 -2
13 1 20 7 16 5 -3 10 -13 10 -21 0 -22 25 -11 27 12 3 19 3 19 -5 0 -8 -17 -9
-18 -15 -3 -3 9 -1 19 4 23 7 4 7 10 0 17 -6 6 -11 22 -11 36 0 19 -3 22 -9
12 -6 -9 -8 -3 -7 20 1 29 5 34 29 36 24 2 26 0 17 -18 -11 -20 -4 -24 19 -10
8 5 10 14 6 21 -5 8 -4 11 2 7 5 -3 15 10 21 31 9 29 9 34 -1 21 -10 -13 -12
1 -13 73 0 48 4 88 8 88 5 0 8 -6 7 -12 -3 -24 1 -38 10 -38 5 0 7 8 4 19 -3
11 2 21 12 25 13 5 15 1 10 -17 -5 -20 -5 -20 9 3 8 14 10 27 5 28 -5 2 -9 8
-9 13 0 4 7 6 16 2 12 -4 14 0 8 22 -6 22 -4 26 7 20 11 -7 12 -6 3 6 -16 22
-16 78 1 102 20 29 19 47 -2 22 -14 -18 -15 -18 -9 -2 3 10 6 26 6 34 0 13 3
13 15 3 12 -10 15 -10 15 1 0 12 2 12 9 0 6 -9 4 -19 -6 -29 -12 -12 -12 -16
-1 -23 9 -6 15 -1 19 16 4 13 10 29 14 36 5 8 4 10 -3 5 -16 -10 -15 6 2 29 8
11 12 25 9 31 -2 7 0 21 6 32 7 13 7 22 -1 30 -9 9 -9 12 -1 12 6 0 14 14 18
30 7 32 14 37 32 19 17 -17 29 49 29 156 -1 80 1 91 17 91 9 0 17 -4 17 -8 0
-4 10 -12 21 -19 12 -6 19 -7 15 -1 -3 5 1 12 9 16 8 3 15 11 15 18 0 9 -3 9
-8 2 -10 -15 -42 -25 -42 -14 0 6 6 10 14 10 8 0 16 10 18 23 2 16 9 21 22 19
17 -3 18 -2 7 12 -7 9 -17 13 -23 10 -5 -3 -8 13 -6 39 2 25 6 43 10 41 4 -2
9 0 13 5 6 11 45 10 45 -1 0 -4 -4 -10 -10 -13 -5 -3 -10 -1 -10 5 0 6 -5 8
-12 4 -9 -6 -9 -9 -1 -11 7 -3 10 -10 7 -18 -7 -17 12 -29 22 -14 4 8 3 9 -4
5 -7 -4 -12 -2 -12 3 0 6 4 11 10 11 5 0 12 11 16 25 3 14 10 22 15 19 5 -3 6
3 2 12 -5 13 -3 15 7 9 8 -5 11 -4 6 3 -3 6 1 18 10 26 8 9 12 16 7 16 -4 0
-1 5 7 10 11 7 11 10 2 10 -7 0 -10 5 -7 10 4 6 11 8 16 5 5 -4 5 3 0 17 -5
13 -17 23 -27 23 -16 0 -19 -8 -19 -53 0 -28 -4 -52 -8 -52 -4 0 -7 26 -5 58
l3 57 37 1 c21 1 35 -3 32 -7 -3 -5 0 -9 5 -9 6 0 11 5 11 12 0 6 7 20 17 30
9 10 16 31 15 47 0 16 6 35 14 42 8 7 12 20 9 30 -5 14 -2 17 10 13 9 -4 14
-13 11 -20 -10 -25 3 -14 19 16 13 26 13 29 0 24 -8 -3 -12 -11 -8 -17 3 -5 2
-7 -4 -4 -6 4 -9 21 -8 39 4 48 4 48 16 48 5 0 7 -5 4 -10 -4 -6 -1 -17 5 -26
14 -16 40 -2 40 22 0 8 5 14 11 14 5 0 7 5 4 10 -3 6 -2 10 2 10 5 0 17 16 27
34 10 19 29 45 42 57 19 17 21 26 13 46 -5 14 -8 28 -5 30 2 3 7 -5 11 -16 9
-30 24 -17 20 17 -4 35 9 62 18 37 5 -10 3 -14 -5 -9 -7 5 -8 3 -4 -5 11 -18
47 16 39 37 -5 11 -3 13 7 7 9 -6 11 -4 5 6 -12 19 -14 49 -3 55 5 3 19 -2 30
-13 20 -17 21 -17 15 0 -4 10 -3 16 2 12 12 -7 73 44 66 55 -3 5 -14 7 -24 5
-10 -2 -18 2 -18 9 0 6 13 12 28 11 15 0 25 -4 22 -8 -3 -5 4 -3 16 5 11 7 23
19 26 26 2 7 1 11 -3 8 -4 -2 -9 4 -9 15 -2 17 0 18 13 4 18 -18 28 -20 18 -3
-5 8 -2 9 10 5 11 -4 15 -3 11 4 -4 6 -14 8 -24 5 -12 -5 -11 -2 3 9 22 17 40
20 32 6 -4 -5 3 -7 14 -4 11 3 20 10 20 15 0 5 -4 7 -8 4 -5 -3 -9 12 -8 32 1
32 4 38 24 40 16 1 21 -2 16 -11 -6 -9 -4 -9 7 -1 8 6 35 10 62 8 35 -2 47 1
47 12 0 8 6 14 13 15 10 0 10 2 0 6 -16 6 -17 33 -2 33 5 0 8 -3 6 -7 -3 -5
15 -6 39 -4 33 2 44 0 42 -10 -2 -7 6 -15 16 -17 19 -4 19 -4 1 14 -17 17 -17
19 -2 22 9 2 17 7 17 11 0 9 100 13 101 4 0 -5 2 -18 3 -30 3 -26 -4 -29 -22
-11 -10 10 -15 10 -20 1 -5 -8 -13 -9 -22 -3 -13 9 -13 10 0 10 11 1 11 3 -3
11 -10 6 -21 8 -24 5 -4 -3 0 -6 8 -6 10 0 11 -2 2 -8 -8 -5 -2 -13 18 -24 17
-10 27 -13 24 -8 -3 6 1 10 10 10 11 0 15 -5 12 -14 -7 -17 21 -30 37 -18 18
14 57 12 82 -4 14 -10 31 -13 46 -9 21 7 21 7 4 -11 -16 -16 -17 -19 -2 -29
11 -9 12 -15 4 -23 -8 -8 -4 -9 13 -6 19 5 21 4 9 -5 -12 -9 -11 -11 7 -11 14
0 21 5 17 13 -3 8 4 13 18 13 18 0 23 -6 23 -27 1 -19 -3 -25 -13 -21 -9 3
-10 7 -3 11 5 4 7 12 3 18 -6 9 -11 7 -18 -5 -9 -14 -4 -21 33 -44 24 -16 42
-24 39 -20 -2 4 24 8 58 7 l63 0 -4 -30 c-2 -16 -4 -35 -5 -42 -1 -7 -5 -10
-10 -7 -5 3 -6 10 -3 15 4 5 1 9 -4 9 -6 0 -11 -4 -11 -9 0 -12 26 -32 32 -25
3 2 18 7 34 9 16 2 23 2 16 -1 -6 -3 -9 -11 -5 -17 4 -7 1 -7 -9 1 -9 7 -19 9
-21 5 -3 -5 8 -18 24 -28 16 -11 29 -23 29 -27 0 -5 7 -8 15 -8 8 0 14 -5 12
-11 -1 -6 8 -12 20 -14 12 -1 20 2 17 7 -4 5 -13 7 -21 4 -8 -3 -11 -2 -8 3 3
6 18 9 33 8 24 -2 27 -6 28 -40 0 -25 8 -46 22 -60 20 -19 22 -20 22 -4 0 10
-4 16 -9 12 -5 -3 -12 -2 -14 2 -3 5 9 8 27 9 20 0 33 -4 33 -12 0 -7 -6 -10
-14 -7 -7 3 -13 0 -13 -6 0 -6 7 -11 16 -11 10 0 14 -6 11 -14 -7 -17 16 -40
27 -28 4 4 6 2 4 -3 -2 -6 5 -19 14 -30 62 -71 78 -87 78 -80 0 4 8 1 18 -6
15 -12 16 -12 5 2 -7 9 -9 20 -5 24 4 5 -1 5 -10 2 -10 -3 -20 -3 -22 2 -3 4
7 7 23 7 24 0 27 -4 28 -33 1 -30 -1 -32 -20 -22 l-22 11 21 -20 c14 -14 31
-19 55 -17 19 2 28 1 22 -2 -7 -2 -13 -11 -13 -20 0 -12 2 -12 16 1 8 9 17 16
19 16 7 0 -3 -109 -11 -122 -5 -7 -3 -8 6 -3 9 5 10 2 6 -13 -6 -18 -3 -20 34
-16 23 2 40 -1 40 -7 0 -6 -5 -7 -10 -4 -11 7 -7 -23 11 -79 13 -41 12 -79 -2
-70 -7 4 -9 0 -5 -12 13 -44 16 -133 4 -140 -6 -4 -14 -2 -16 5 -4 11 -6 11
-13 0 -14 -22 -11 8 3 34 7 12 13 31 14 42 2 11 -6 2 -16 -20 -11 -22 -20 -47
-20 -55 -1 -10 -4 -9 -10 5 -9 22 -10 8 -14 -195 0 -63 -5 -120 -8 -126 -4 -6
-19 -11 -32 -11 -21 -1 -23 2 -11 13 7 8 16 19 18 26 4 10 8 10 15 -1 8 -11
10 -8 9 12 -1 15 -5 27 -8 27 -9 0 -4 75 6 82 5 4 6 10 2 15 -10 10 -27 -16
-20 -33 2 -6 -1 -15 -7 -19 -9 -6 -9 -9 0 -15 10 -6 7 -14 -9 -31 -15 -16 -18
-25 -9 -30 8 -5 7 -12 -5 -24 -10 -9 -17 -23 -17 -31 0 -8 -5 -14 -11 -14 -8
0 -8 -4 -1 -12 5 -7 8 -35 7 -63 -3 -69 -3 -69 -20 -55 -12 10 -15 10 -15 0 0
-8 -3 -10 -6 -7 -4 3 -14 2 -24 -3 -14 -7 -16 -23 -15 -85 1 -47 -2 -70 -7
-60 -5 8 -6 20 -2 27 4 7 3 8 -5 4 -6 -4 -9 -16 -6 -27 8 -24 -2 -24 -23 -1
-10 10 -13 12 -8 5 6 -9 1 -16 -16 -22 -14 -5 -25 -16 -25 -25 0 -8 4 -13 9
-10 16 10 8 -24 -9 -37 -17 -13 -17 -13 -3 5 8 11 10 17 3 13 -6 -4 -14 -1
-17 5 -3 7 -3 0 -1 -15 3 -19 0 -27 -8 -24 -7 2 -23 -10 -37 -27 -14 -16 -19
-27 -12 -23 6 5 12 4 12 -2 0 -6 7 -8 15 -5 21 9 19 25 -2 17 -15 -5 -16 -4
-5 8 7 8 16 14 20 14 4 1 17 6 30 13 27 16 28 8 2 -20 -11 -12 -20 -27 -20
-34 0 -7 -10 -11 -24 -10 -23 3 -24 1 -20 -43 3 -36 2 -41 -6 -23 -12 29 -23
29 -14 0 6 -20 5 -21 -9 -10 -12 10 -16 10 -20 0 -2 -7 -8 -10 -12 -6 -4 4 4
21 19 38 14 17 26 26 26 19 0 -7 3 -10 6 -6 3 3 1 14 -5 24 -6 10 -11 13 -11
6 0 -6 -14 -27 -30 -45 -23 -25 -29 -39 -25 -58 6 -23 5 -24 -8 -6 -18 24 -32
8 -36 -40 -1 -10 -7 -14 -16 -10 -15 5 -20 -13 -16 -56 2 -19 -15 -33 -24 -19
-3 6 -13 8 -23 5 -14 -5 -15 -4 -2 5 11 8 11 11 2 11 -7 0 -15 -7 -18 -15 -3
-8 -15 -17 -26 -21 -17 -5 -17 -7 -4 -17 8 -7 14 -21 12 -32 -2 -15 -11 -20
-34 -21 -17 0 -29 3 -26 8 3 5 10 5 16 2 7 -5 8 -2 3 6 -5 8 -4 11 1 7 6 -3
16 -1 24 5 12 8 12 9 -2 4 -10 -3 -18 0 -18 6 0 11 -24 -13 -47 -49 -12 -17
-12 -17 -6 2 5 15 1 13 -20 -9 -15 -16 -27 -33 -27 -39 0 -5 8 1 18 13 16 19
19 20 30 7 10 -12 10 -15 -4 -13 -9 0 -19 -6 -21 -14 -4 -13 -3 -13 6 0 8 11
11 12 11 2 0 -7 5 -10 10 -7 6 3 10 1 10 -4 0 -6 -15 -10 -34 -8 -19 1 -32 5
-29 10 2 4 -1 7 -7 7 -6 0 -8 5 -5 10 4 6 -4 5 -19 -1 -14 -7 -23 -17 -19 -22
4 -6 0 -8 -9 -5 -24 10 -39 -6 -42 -43 l-3 -34 -54 -1 c-30 0 -60 3 -66 9 -9
6 -13 5 -13 -3 0 -7 -6 -10 -14 -7 -8 3 -17 -1 -20 -9 -3 -8 -10 -14 -16 -14
-6 0 -8 5 -4 11 4 8 0 8 -15 0 -12 -7 -25 -10 -29 -7 -4 3 -14 1 -22 -4 -10
-6 5 -8 42 -7 44 2 55 0 47 -9 -6 -7 -25 -11 -47 -8 l-37 4 34 -9 c19 -5 40
-7 48 -4 7 3 13 0 13 -6 0 -6 -3 -11 -7 -12 -5 0 -28 -2 -53 -4 -33 -2 -39 -1
-20 5 l25 8 -27 1 c-16 1 -28 -3 -28 -9 0 -6 -41 -9 -105 -7 -79 1 -105 5
-105 15 0 8 7 11 19 7 10 -3 46 1 80 9 50 12 61 12 61 1 0 -7 -8 -11 -17 -9
-10 2 -31 0 -48 -5 -23 -7 -16 -8 34 -5 36 2 70 9 75 15 8 8 5 9 -11 4 -24 -7
-33 9 -10 19 13 5 -59 -3 -83 -10 -9 -2 -32 4 -52 15 -22 11 -36 14 -35 7 1
-6 11 -14 21 -19 25 -10 13 -21 -13 -12 -12 3 -21 15 -21 26 0 18 -10 23 -39
20 -7 -2 -10 2 -6 8 3 6 -1 11 -10 11 -9 0 -14 -4 -11 -8 3 -4 -17 -8 -45 -8
-34 0 -49 4 -49 14 0 7 5 10 12 6 7 -5 8 -3 2 7 -13 22 -13 36 1 36 7 0 11 -6
8 -13 -6 -17 10 -24 23 -10 8 9 3 18 -23 35 -20 12 -33 18 -30 13 4 -5 -13 -8
-37 -7 l-43 1 5 -51 5 -50 -29 25 -29 25 24 -27 c29 -34 35 -88 9 -88 -20 0
-24 15 -8 25 6 3 10 12 10 18 0 7 -5 4 -11 -6 -8 -14 -14 -15 -24 -7 -19 16
-27 -30 -28 -154 0 -56 -3 -92 -7 -81 -6 17 -8 15 -15 -13 -4 -18 -4 -36 -1
-39 3 -4 6 1 6 10 0 9 3 17 8 17 4 0 6 -20 4 -45 -2 -25 -7 -45 -12 -45 -4 0
-5 11 -2 25 2 15 0 25 -7 25 -6 0 -11 -5 -11 -12 0 -9 -3 -9 -12 0 -9 9 -10
15 -2 20 11 7 14 17 17 72 1 17 5 30 9 30 4 0 4 7 1 17 -4 11 -3 14 5 9 7 -4
12 -2 12 3 0 6 -5 11 -10 11 -14 0 -31 -46 -24 -65 4 -8 2 -15 -3 -15 -5 0
-10 -9 -11 -20 -1 -11 -4 -33 -6 -50 -2 -16 -4 -35 -5 -41 0 -5 -7 -7 -13 -3
-7 4 -10 4 -6 -1 4 -4 3 -16 -3 -27 -5 -10 -7 -24 -2 -30 11 -20 14 -257 2
-264 -16 -10 -23 4 -10 20 15 18 11 58 -6 63 -7 3 -9 -3 -6 -16 9 -36 -5 -70
-27 -66 -17 3 -20 -2 -21 -33 -1 -49 -4 -80 -12 -122 -4 -19 -3 -27 1 -17 4
10 8 -39 9 -112 1 -72 -1 -131 -5 -131 -4 0 -8 -12 -9 -27 -1 -20 1 -23 9 -12
7 10 8 7 5 -13 -2 -16 0 -28 5 -28 5 0 6 -4 3 -10 -3 -5 -1 -10 5 -10 6 0 8
-5 4 -12 -5 -7 -3 -8 6 -3 10 6 12 4 7 -8 -3 -9 -3 -16 1 -16 21 3 32 -3 33
-18 0 -13 2 -14 6 -4 3 8 -3 21 -13 29 -13 10 -15 16 -6 19 6 3 9 9 6 15 -4 7
-9 6 -14 -2 -6 -9 -11 -7 -19 9 -8 14 -8 21 -1 21 5 0 10 -5 10 -11 0 -6 5 -7
13 -2 25 15 33 10 32 -21 -3 -47 2 -52 48 -51 23 1 50 -2 61 -8 14 -7 16 -6
11 3 -5 8 -3 11 6 8 21 -8 48 10 45 30 -3 20 22 46 49 50 20 3 20 2 1 -13 -11
-8 -23 -15 -27 -15 -13 0 -18 -29 -7 -36 7 -5 8 -2 3 6 -6 10 -4 12 8 7 9 -3
19 -1 23 5 4 7 3 8 -4 4 -7 -4 -12 -3 -12 3 0 5 9 12 19 16 11 3 22 19 26 34
8 39 47 70 85 69 17 -1 33 -6 37 -11 3 -6 1 -7 -5 -3 -6 3 -13 2 -17 -4 -3 -5
-11 -10 -16 -10 -6 0 -8 4 -5 9 10 15 -32 0 -47 -17 -8 -10 -18 -28 -22 -41
-6 -21 -5 -22 8 -10 8 8 13 16 11 18 -7 8 29 41 38 35 5 -3 1 -12 -9 -19 -10
-7 -16 -16 -13 -19 4 -3 18 6 32 19 14 13 28 21 31 18 3 -3 11 4 18 15 7 11
19 18 26 15 9 -4 11 0 6 13 -4 12 -3 15 5 10 7 -4 12 -1 12 9 0 9 -4 14 -8 11
-5 -3 -9 1 -9 9 0 8 5 11 10 8 6 -4 8 0 5 8 -4 10 1 15 15 17 17 1 19 -1 7 -8
-8 -5 -10 -10 -5 -10 6 0 5 -4 -2 -8 -8 -5 -9 -13 -3 -22 7 -11 10 -12 10 -2
0 7 4 11 9 8 17 -11 35 27 36 72 0 31 5 47 15 49 12 4 12 3 2 -5 -10 -7 -4
-11 22 -16 20 -4 34 -11 31 -17 -4 -6 -13 -5 -25 1 -10 5 -25 10 -32 9 -7 0
-3 -4 10 -9 22 -10 31 -30 11 -30 -5 0 -7 5 -4 10 3 6 2 10 -3 10 -13 0 -23
-28 -13 -35 12 -7 86 54 85 71 -3 56 -4 59 -14 44 -14 -22 -11 1 3 25 9 16 23
20 64 20 28 0 49 -4 46 -8 -3 -5 7 -1 21 8 16 11 25 24 22 32 -3 8 -2 12 3 9
4 -3 19 6 31 19 13 14 29 25 36 25 7 0 16 5 20 12 4 6 3 9 -2 6 -5 -4 -10 10
-11 30 -2 26 0 33 6 22 5 -8 8 -17 5 -21 -2 -4 1 -11 7 -15 8 -4 9 -3 5 4 -6
11 6 16 27 13 4 0 7 4 7 10 0 6 -9 8 -22 3 -13 -3 -19 -3 -15 2 4 4 20 8 35 9
25 0 27 3 24 38 -3 25 1 37 10 38 7 1 23 3 35 4 16 2 20 -2 15 -14 -4 -13 -1
-12 16 3 17 15 19 22 11 33 -16 18 -3 29 16 13 12 -10 15 -10 15 4 0 9 5 16
10 16 6 0 10 5 10 11 0 8 -4 9 -13 0 -8 -6 -18 -7 -22 -3 -5 4 -4 0 0 -8 8
-13 6 -13 -8 -1 -9 7 -15 16 -13 20 6 9 48 7 64 -3 12 -7 13 -5 18 49 1 17 2
34 3 38 1 5 5 6 11 2 5 -3 6 -15 0 -29 -6 -17 -5 -23 6 -23 10 0 12 7 8 21 -6
19 -5 20 12 6 18 -14 18 -14 5 3 -18 23 -7 34 16 16 10 -7 15 -10 11 -5 -4 5
3 17 15 27 12 10 17 18 10 19 -6 0 -14 -6 -16 -12 -4 -10 -6 -10 -6 -1 -1 6 4
15 9 18 6 3 10 11 10 16 0 6 -4 8 -8 5 -5 -3 -9 1 -9 9 0 11 4 12 13 4 9 -7
19 -7 35 1 17 10 20 9 14 -2 -3 -7 0 -4 9 6 8 10 13 23 9 29 -3 6 -1 7 5 3 7
-4 12 -2 12 5 0 7 3 9 7 6 10 -10 64 44 56 56 -3 6 -1 7 4 4 6 -4 17 1 24 10
12 14 11 15 -8 9 -17 -6 -21 -3 -19 17 0 15 8 24 20 25 18 1 18 0 0 -21 -15
-17 -16 -19 -2 -8 9 7 22 10 27 6 7 -4 8 1 4 11 -4 11 -3 15 5 11 6 -4 17 6
26 24 18 36 19 42 8 35 -5 -3 -9 1 -9 7 0 7 11 13 23 13 12 0 20 -4 17 -9 -3
-5 4 -4 16 2 11 7 21 16 21 20 0 5 13 23 29 40 16 17 25 31 20 31 -6 0 -5 9 1
20 6 11 16 20 22 20 6 0 5 -7 -3 -17 -13 -16 -12 -17 3 -5 16 13 16 15 1 39
-17 26 -17 26 6 13 20 -10 26 -9 43 8 15 15 17 21 7 27 -7 5 -10 4 -5 -3 3 -6
1 -14 -5 -17 -17 -11 -22 1 -9 25 9 18 14 19 26 9 13 -10 19 -8 39 17 14 16
24 35 25 42 0 7 5 10 12 6 6 -4 8 -3 5 3 -4 6 5 18 19 28 22 14 23 19 12 33
-9 11 -10 14 0 8 13 -8 17 6 5 18 -4 3 -8 17 -9 29 -2 12 0 16 2 10 3 -7 10
-13 15 -13 6 0 7 4 4 10 -3 6 -2 10 4 10 5 0 13 -9 16 -21 l7 -21 15 21 c8 12
15 41 16 65 1 25 5 42 9 40 4 -3 8 0 8 5 0 6 -4 11 -9 11 -6 0 -9 9 -8 19 0
11 5 17 9 15 4 -3 8 -1 8 5 0 5 -6 11 -12 14 -7 2 6 3 30 2 45 -2 60 -9 43
-20 -6 -3 -11 -1 -11 4 0 6 -4 11 -10 11 -5 0 -9 -3 -9 -7 4 -26 -3 -34 -22
-28 -18 6 -19 5 -8 -13 13 -20 13 -20 27 -1 7 10 18 19 24 19 6 0 4 -8 -5 -18
-10 -10 -17 -22 -17 -27 0 -4 9 4 20 18 11 14 20 30 20 36 0 5 12 22 27 36 30
27 25 33 -10 11 -21 -14 -22 -14 -24 19 -2 19 2 37 7 40 7 4 9 -2 4 -19 -3
-14 -2 -26 3 -26 12 0 33 21 33 34 0 5 -7 1 -15 -10 -8 -10 -15 -14 -15 -8 0
18 23 32 46 28 20 -3 21 0 18 54 -3 42 -1 53 7 40 14 -24 22 -22 36 9 17 36 8
41 -11 6 -16 -27 -16 -28 -14 -3 2 14 13 30 28 37 23 12 23 13 4 13 -12 0 -28
-10 -37 -22 l-14 -23 0 25 c0 25 1 25 78 26 67 1 76 -1 61 -13 -13 -10 -14
-13 -2 -11 13 3 15 -24 16 -182 2 -187 -1 -230 -17 -230 -5 0 -7 6 -5 13 1 6
1 9 -1 4 -3 -4 -10 -4 -15 -1 -7 4 -8 2 -4 -4 4 -6 3 -20 -2 -29 -5 -11 -5
-32 0 -51 8 -28 11 -32 24 -21 10 8 13 21 9 40 -3 15 -2 30 3 33 6 3 10 -18
10 -50 0 -31 -4 -51 -8 -44 -5 8 -10 9 -14 1 -10 -16 -47 25 -40 46 5 15 4 16
-5 3 -14 -20 -14 -26 5 -44 18 -19 4 -22 -15 -3 -10 8 -12 8 -7 0 3 -6 2 -15
-4 -18 -6 -4 -8 -16 -4 -28 12 -34 12 -127 2 -127 -6 0 -8 7 -4 15 3 8 1 15
-3 15 -5 0 -11 -7 -15 -15 -3 -8 -12 -12 -20 -9 -17 6 -36 -24 -28 -45 3 -9 0
-11 -8 -6 -9 6 -9 3 -1 -13 14 -26 14 -32 1 -32 -5 0 -10 8 -10 18 0 9 -15
-12 -34 -48 -23 -45 -33 -76 -31 -100 3 -35 3 -35 -10 -10 -19 33 -19 18 0
-27 17 -41 19 -67 5 -58 -6 4 -8 10 -5 15 3 5 0 22 -8 37 -14 26 -15 26 -25 6
-6 -11 -8 -23 -5 -27 3 -3 1 -6 -6 -6 -7 0 -9 -5 -6 -10 4 -6 10 -8 15 -5 5 3
11 1 15 -4 3 -6 -5 -8 -18 -6 -15 3 -27 -2 -35 -15 -7 -11 -8 -20 -3 -20 5 0
9 -29 8 -65 -1 -36 -4 -65 -7 -65 -10 1 -42 52 -36 57 3 4 12 -2 19 -12 12
-16 14 -13 13 23 0 31 -4 38 -15 34 -10 -4 -11 -7 -3 -10 7 -2 12 -8 12 -14 0
-5 -6 -8 -12 -5 -15 4 -53 -51 -49 -72 1 -7 -2 -10 -8 -7 -6 4 -8 -2 -5 -16
12 -52 14 -83 4 -68 -8 13 -10 12 -10 -4 0 -11 -7 -28 -17 -38 -9 -10 -13 -18
-10 -18 3 0 15 9 25 21 l20 20 -1 -23 c0 -19 -5 -23 -34 -26 -25 -1 -32 1 -27
10 5 10 4 10 -6 1 -11 -10 -17 -10 -29 0 -14 10 -15 10 -1 -8 14 -18 16 -28
11 -58 0 -4 -9 -3 -19 2 -18 10 -58 -10 -45 -22 3 -4 -2 -9 -11 -13 -9 -3 -13
-11 -9 -18 14 -21 8 -41 -17 -58 -26 -19 -21 -24 8 -9 15 8 18 4 17 -27 0 -37
0 -37 -41 -36 l-42 0 4 -41 c3 -41 2 -42 -26 -41 -31 1 -49 12 -25 14 8 1 22
3 30 4 20 2 10 11 -15 13 -11 1 -32 -12 -47 -28 -16 -17 -23 -27 -17 -24 7 4
15 0 18 -9 3 -8 2 -12 -4 -9 -6 3 -10 -1 -10 -9 0 -9 5 -16 11 -16 5 0 7 -5 3
-11 -4 -8 -11 -4 -18 10 l-12 21 -23 -26 c-13 -15 -25 -38 -26 -53 -2 -14 -8
-44 -14 -66 -7 -29 -7 -41 2 -47 7 -4 -1 -6 -18 -5 -16 1 -33 9 -36 17 -4 11
-16 3 -47 -29 -23 -24 -40 -48 -39 -55 1 -6 -4 -10 -11 -8 -9 2 -11 -2 -6 -10
13 -20 12 -79 0 -75 -6 3 -10 9 -9 15 2 6 -6 10 -17 9 -15 -1 -17 -4 -7 -16
10 -12 9 -13 -12 -2 -13 7 -21 8 -17 3 3 -6 1 -14 -5 -17 -5 -4 -8 -13 -5 -21
3 -8 4 -14 2 -14 -3 0 -13 -3 -23 -7 -16 -6 -17 -5 -3 13 14 19 14 19 -7 0
-26 -22 -21 -41 7 -26 13 7 21 7 26 -2 4 -7 3 -8 -4 -4 -7 4 -12 3 -12 -3 0
-5 -12 -8 -26 -6 -28 3 -43 -21 -38 -62 1 -13 -2 -23 -8 -23 -5 0 -7 9 -3 19
3 11 1 22 -4 26 -7 4 -8 -1 -4 -11 5 -14 3 -16 -12 -11 -15 7 -16 5 -4 -9 11
-13 11 -15 -2 -14 -28 4 -39 0 -39 -17 0 -10 -19 -30 -43 -45 -38 -24 -43 -32
-42 -61 1 -22 -3 -34 -11 -34 -7 0 -11 4 -8 9 3 4 0 8 -7 8 -8 0 -7 5 2 17 13
16 12 17 -3 5 -10 -8 -14 -20 -11 -29 5 -13 2 -15 -15 -11 -18 5 -22 2 -23
-20 -2 -53 -4 -57 -31 -59 -15 -1 -31 3 -34 9 -5 7 -2 9 8 5 9 -3 21 0 28 6
10 9 11 9 6 0 -4 -7 -3 -13 3 -13 5 0 9 9 8 19 -1 15 -4 17 -14 9 -7 -6 -13
-7 -13 -4 0 4 -9 2 -20 -4 -11 -6 -19 -16 -17 -23 1 -7 -4 -11 -12 -9 -12 3
-12 1 0 -14 13 -16 12 -16 -7 -6 -18 10 -25 7 -45 -15 -13 -14 -18 -23 -11
-19 6 4 12 2 12 -3 0 -6 8 -11 17 -11 15 0 14 2 -3 15 -18 13 -18 14 -1 15 11
0 15 4 11 12 -4 7 -3 8 5 4 6 -4 9 -11 6 -16 -3 -5 -1 -11 5 -15 5 -3 10 -1
10 6 0 7 2 10 5 7 14 -14 -9 -36 -36 -34 -37 3 -48 -10 -43 -51 2 -19 -1 -33
-7 -33 -6 0 -7 5 -4 10 3 6 2 10 -4 10 -5 0 -13 -4 -16 -10 -8 -13 -25 -13
-25 0 0 6 7 10 16 10 8 0 12 4 9 10 -4 6 -13 5 -25 -2 -10 -7 -17 -14 -15 -18
2 -3 -13 -5 -33 -6 l-37 -1 38 17 c37 17 38 18 13 22 -16 3 -23 1 -19 -5 4 -6
0 -8 -11 -4 -11 5 -15 3 -10 -5 5 -8 0 -9 -15 -5 -17 4 -20 3 -12 -7 9 -10
-22 -13 -152 -12 -154 1 -164 2 -187 24 -23 21 -46 31 -36 14 2 -4 -19 -8 -48
-9 -46 -2 -52 1 -53 19 -2 18 0 19 15 5 11 -10 24 -12 37 -7 22 9 21 10 -14
14 -14 2 -26 7 -28 12 -2 5 -22 9 -44 8 -35 0 -39 1 -25 12 15 10 15 12 1 18
-9 3 -14 2 -11 -3 8 -12 -22 -24 -60 -25 -32 -1 -32 0 -29 42 2 24 1 41 -2 38
-3 -2 -15 5 -28 16 -13 11 -27 20 -32 20 -4 -1 -19 9 -33 21 -23 20 -23 20 -7
1 9 -11 14 -24 10 -29 -5 -4 -2 -5 5 -1 7 4 12 2 12 -6 0 -10 2 -10 9 1 6 9
11 10 15 2 4 -6 2 -11 -4 -11 -6 0 -8 -5 -4 -11 5 -8 1 -8 -14 0 -26 14 -54
15 -33 2 9 -6 6 -8 -12 -8 -17 0 -29 8 -36 25 -7 13 -10 27 -7 29 2 2 7 -5 11
-16 5 -17 25 -31 25 -17 0 10 -30 61 -35 59 -3 -2 -15 -3 -27 -3 -19 0 -20 2
-8 10 20 13 43 13 35 0 -3 -5 0 -7 9 -4 12 5 13 9 3 19 -7 7 -15 11 -19 8 -5
-2 -8 4 -8 14 0 15 -2 15 -9 3 -6 -8 -7 -18 -4 -24 3 -5 1 -6 -6 -2 -6 4 -9
11 -6 16 3 5 1 11 -4 14 -5 4 -12 2 -15 -3 -4 -5 -2 -13 4 -16 8 -5 7 -11 -1
-21 -18 -22 -30 -16 -26 13 6 41 -8 61 -38 56 -29 -6 -31 3 -10 34 10 14 12
24 5 28 -16 10 -32 -5 -27 -25 2 -10 1 -23 -3 -30 -10 -17 -10 41 0 61 7 13 3
15 -26 13 -23 -2 -37 2 -41 11 -3 8 -8 13 -12 11 -4 -3 -8 5 -8 17 -2 16 0 18
7 8 4 -8 14 -16 22 -17 7 -1 21 -5 31 -9 9 -4 17 -4 17 -1 0 4 -12 11 -26 16
-15 6 -22 14 -18 22 5 7 3 8 -5 4 -8 -5 -11 -3 -9 7 2 9 -1 15 -7 14 -5 -1 -9
-5 -8 -9 1 -3 -10 -3 -25 1 -15 5 -34 7 -42 6 -12 -2 -16 8 -16 41 -1 24 4 44
9 44 5 0 4 6 -2 14 -6 7 -9 21 -5 30 7 17 -12 28 -54 31 -18 1 -26 7 -27 23
-1 11 2 19 7 16 4 -3 8 -1 8 5 0 5 -4 13 -10 16 -13 8 -13 35 0 35 5 0 7 -6 4
-14 -7 -18 12 -30 22 -15 5 8 3 9 -6 4 -10 -6 -12 -4 -7 8 3 8 -1 21 -8 27 -8
7 -13 15 -11 19 8 12 -8 29 -29 33 -11 1 -26 10 -33 18 -12 13 -13 13 -8 -2 3
-10 1 -18 -4 -18 -6 0 -9 15 -7 34 1 19 6 32 11 29 4 -3 5 5 1 18 -4 16 -3 20
3 11 13 -19 24 26 12 48 -5 10 -6 20 -3 24 3 3 1 6 -5 6 -15 0 -16 -26 -2 -35
6 -4 8 -11 4 -16 -12 -19 -22 3 -18 39 8 68 5 165 -5 175 -6 6 -8 2 -4 -13 4
-15 2 -21 -5 -16 -6 3 -14 1 -18 -5 -4 -7 -3 -9 4 -5 6 3 13 2 17 -4 3 -5 0
-11 -7 -11 -33 -3 -38 -4 -38 -11 0 -5 -8 -16 -19 -27 -13 -13 -17 -29 -15
-51 3 -26 1 -30 -9 -21 -10 8 -19 5 -34 -13 -18 -20 -18 -24 -4 -33 14 -9 14
-11 -1 -26 -14 -15 -17 -15 -22 -1 -3 8 -2 12 4 9 6 -3 10 -1 10 4 0 14 -13
14 -28 -1 -7 -7 -8 -17 -2 -28 6 -12 3 -20 -13 -29 -18 -11 -20 -11 -9 0 17
18 15 34 -2 16 -18 -18 -14 -50 7 -53 9 -1 14 3 11 11 -3 7 -2 15 3 18 5 3 10
-5 11 -18 1 -20 -3 -23 -24 -22 -13 1 -30 9 -35 17 -9 13 -10 12 -5 -2 3 -12
0 -18 -9 -18 -7 0 -17 -5 -21 -12 -4 -6 -3 -8 4 -4 7 4 10 -5 8 -26 -2 -21 -8
-32 -20 -34 -12 -2 -16 2 -12 12 3 8 1 14 -4 14 -6 0 -10 -7 -10 -15 0 -8 -6
-14 -12 -12 -7 1 -14 -8 -16 -21 -2 -12 -7 -20 -12 -17 -5 3 -13 -3 -19 -13
-7 -14 -6 -19 4 -19 8 0 11 7 7 16 -3 9 -1 12 4 8 31 -29 -11 -94 -64 -100
-17 -2 -35 -7 -40 -12 -4 -5 -1 -5 6 -1 7 5 12 1 12 -9 0 -10 -6 -15 -13 -12
-16 6 -24 1 -56 -35 -20 -23 -21 -26 -5 -24 11 2 19 9 19 16 0 7 8 18 18 24
14 8 20 8 27 -4 12 -19 7 -35 -11 -36 -26 -1 -59 -15 -59 -25 0 -6 4 -8 8 -5
4 2 8 -4 8 -14 0 -10 -4 -17 -7 -14 -4 2 -10 0 -13 -5 -4 -5 2 -12 11 -14 10
-3 1 -4 -19 -2 -23 1 -42 -3 -49 -11 -6 -8 -8 -14 -4 -14 5 0 0 -9 -10 -20
-20 -22 -41 -26 -47 -9 -2 6 -12 0 -23 -13 -14 -16 -15 -19 -3 -10 13 11 21
11 35 2 15 -10 15 -11 1 -6 -23 7 -36 -13 -34 -49 2 -29 1 -31 -35 -31 -21 0
-36 3 -33 8 3 4 1 8 -4 8 -14 0 -22 -12 -15 -24 11 -17 12 -35 2 -42 -14 -8
-11 -26 4 -18 6 4 9 3 4 -1 -4 -5 -18 -10 -30 -11 -12 -2 -16 0 -9 3 10 4 10
8 1 20 -10 11 -10 14 2 11 8 -2 13 3 12 10 -2 6 2 12 8 12 5 0 7 4 4 10 -12
19 -23 10 -38 -29 -9 -22 -21 -42 -27 -46 -6 -3 -7 -11 -4 -17 4 -6 -2 -8 -17
-4 -13 3 -18 3 -11 0 22 -10 12 -23 -24 -30 -25 -5 -32 -9 -22 -15 9 -6 6 -9
-11 -9 -20 0 -21 2 -11 15 7 8 21 15 32 15 14 0 18 5 14 15 -4 11 3 19 19 25
14 5 25 14 25 20 0 6 4 9 8 6 5 -3 9 1 9 9 0 18 -9 13 -74 -43 -29 -24 -53
-41 -53 -36 0 5 -6 2 -13 -6 -7 -8 -24 -21 -39 -27 -16 -7 -28 -17 -28 -23 0
-5 -8 -10 -17 -10 -15 0 -14 -2 3 -15 25 -19 40 -19 24 -1 -9 12 -8 15 7 13
10 0 19 -8 19 -17 0 -14 -20 -16 -153 -17 -85 -1 -153 3 -153 8 0 5 -5 9 -11
9 -5 0 -8 -3 -6 -7 3 -5 -6 -8 -19 -8 -13 0 -24 3 -24 6 0 13 -27 17 -41 7
-11 -8 -11 -9 3 -4 10 3 20 0 24 -6 7 -11 -46 -5 -83 9 -9 4 -14 2 -10 -3 3
-5 -15 -10 -41 -10 -26 -1 -41 1 -33 4 11 4 11 6 0 13 -10 6 -9 12 6 30 14 16
16 21 5 17 -8 -2 -21 1 -28 8 -8 8 -27 11 -53 8 -27 -3 -39 -1 -34 6 3 6 11 8
16 5 5 -4 9 -1 9 5 0 7 -8 9 -21 5 -16 -5 -19 -4 -14 5 6 9 4 11 -4 6 -7 -5
-10 -14 -8 -20 6 -14 -28 -15 -40 -1 -5 4 -3 5 4 1 15 -8 28 33 15 46 -8 8 -9
4 -5 -11 3 -12 1 -21 -6 -21 -6 0 -10 3 -10 8 4 25 -4 40 -27 50 -14 7 -23 9
-20 4 10 -16 -52 -12 -65 5 -8 9 -9 14 -4 11 6 -4 17 -2 25 4 12 8 11 9 -4 4
-14 -5 -17 -2 -12 10 6 15 4 15 -11 3 -15 -12 -16 -12 -10 4 4 11 3 16 -4 12
-6 -4 -8 -13 -5 -21 3 -8 0 -14 -6 -14 -7 0 -4 -6 6 -13 16 -12 16 -13 -4 -13
-17 0 -20 5 -16 29 3 22 -2 36 -22 56 -15 14 -27 29 -27 33 0 3 -3 10 -7 14
-4 3 -6 -3 -3 -14 2 -14 -1 -19 -10 -16 -8 3 -12 15 -9 30 4 23 -3 29 -33 25
-7 -1 -13 5 -13 14 0 8 -5 15 -11 15 -6 0 -9 -7 -6 -14 4 -10 1 -13 -8 -10 -7
3 -12 16 -10 29 1 13 -1 27 -4 32 -9 14 -54 43 -66 43 -5 -1 1 -7 14 -14 l24
-13 -25 0 c-22 0 -25 4 -23 32 1 31 -11 60 -27 63 -5 1 -7 3 -6 5 2 1 -7 16
-20 34 -20 29 -27 32 -62 28 -37 -4 -60 8 -45 23 3 3 10 0 15 -8 7 -11 10 -11
10 -2 0 9 5 10 19 2 12 -6 22 -6 26 -1 7 12 -12 24 -24 16 -5 -3 -16 4 -24 16
-14 19 -15 20 -19 2 -3 -10 -5 -1 -4 20 0 26 3 33 8 22 5 -8 13 -15 18 -15 6
0 2 9 -8 20 -9 12 -18 25 -18 30 -1 6 -2 15 -3 20 0 6 -7 10 -15 10 -8 0 -18
5 -22 12 -4 7 -3 8 4 4 7 -4 12 -3 12 3 0 17 -20 23 -26 7 -3 -8 -13 -17 -22
-21 -14 -5 -14 -3 1 13 17 19 14 52 -4 52 -5 0 -9 9 -9 21 0 15 4 19 16 15 10
-4 14 -2 11 6 -2 7 -8 12 -13 11 -5 0 -10 24 -11 55 -3 60 -12 72 -52 66 -19
-3 -22 -2 -11 5 8 5 18 7 22 5 4 -3 8 -1 8 5 0 18 -18 21 -34 5 -9 -8 -16 -22
-16 -30 0 -8 -4 -14 -10 -14 -5 0 -7 -7 -4 -15 4 -8 -1 -17 -9 -21 -9 -3 -18
-13 -21 -21 -5 -13 -4 -14 9 -3 8 7 15 9 15 4 0 -5 4 -3 8 4 5 7 8 -8 8 -36 0
-47 -1 -48 -34 -49 -32 -1 -33 0 -15 19 16 18 18 18 23 2 6 -16 7 -16 13 -1 4
10 2 17 -5 17 -7 0 -6 5 2 15 19 23 3 18 -21 -7 -20 -21 -21 -21 -15 -3 5 15
4 17 -4 9 -11 -11 -17 -32 -19 -61 -1 -7 -7 -12 -13 -10 -7 1 -12 -5 -10 -13
2 -8 -3 -19 -10 -25 -11 -8 -11 -13 4 -27 10 -10 18 -14 18 -9 0 6 -5 13 -10
16 -8 5 -7 11 0 20 7 8 8 20 4 27 -4 7 -3 9 2 5 5 -5 9 -28 9 -53 1 -34 -2
-43 -13 -39 -8 3 -12 0 -9 -7 2 -7 10 -12 16 -11 6 2 11 -4 11 -12 0 -8 -7
-15 -15 -15 -8 0 -15 6 -15 13 0 8 -4 7 -10 -3 -8 -13 -10 -12 -10 3 0 10 -4
15 -10 12 -5 -3 -10 1 -10 10 0 11 5 15 15 11 9 -3 15 0 15 9 0 22 -17 18 -34
-7 -12 -17 -13 -25 -3 -37 9 -11 9 -13 0 -8 -7 5 -17 -2 -24 -15 -10 -20 -10
-21 2 -11 11 9 14 6 16 -20 1 -24 -1 -27 -6 -13 -7 19 -8 19 -13 -2 -4 -16
-14 -22 -32 -22 -14 0 -26 -3 -27 -7 0 -5 -1 -39 -2 -78 -2 -69 -2 -70 -29
-71 -16 -1 -28 3 -28 8 0 4 11 6 25 2 15 -4 25 -2 25 5 0 6 -7 8 -15 5 -10 -4
-15 0 -15 11 0 9 4 14 9 10 5 -3 13 0 17 6 5 8 3 9 -6 4 -10 -6 -12 -4 -7 8
19 47 17 49 -16 12 -21 -24 -34 -47 -31 -57 3 -9 -1 -20 -7 -24 -8 -5 -9 -3
-4 6 5 8 4 11 -3 7 -8 -6 -10 -42 -2 -42 1 0 3 -11 5 -25 2 -14 1 -19 -1 -12
-3 6 -10 12 -15 12 -6 0 -8 -4 -5 -8 2 -4 -4 -8 -14 -9 -14 -1 -17 3 -12 15 5
16 5 16 -11 0 -9 -9 -17 -21 -17 -26 0 -6 -8 -13 -17 -17 -16 -6 -16 -8 -3
-18 8 -7 16 -21 18 -32 2 -18 1 -17 -11 3 l-13 23 -37 -37 c-36 -38 -41 -59
-9 -47 27 11 44 32 38 48 -3 8 -1 15 4 15 13 0 13 -26 0 -51 -7 -12 -7 -19 -1
-19 5 0 13 9 16 21 4 11 9 18 12 15 3 -3 -1 -16 -10 -29 -13 -21 -23 -24 -64
-23 -32 1 -48 -3 -49 -11 -3 -30 -4 -33 -14 -33 -5 0 -6 8 -3 17 4 10 -5 4
-20 -13 -15 -18 -27 -38 -27 -45 1 -8 6 -4 13 9 10 16 16 19 24 11 8 -8 15 -3
27 19 9 17 21 34 27 38 7 4 9 3 5 -4 -3 -5 -3 -12 1 -15 5 -2 2 -2 -4 -1 -7 2
-13 2 -13 0 0 -3 -3 -11 -6 -20 -3 -9 -1 -16 6 -16 6 0 8 5 5 10 -3 6 -2 10 3
10 5 0 12 -8 14 -18 5 -16 -1 -19 -36 -18 l-41 0 2 -36 c2 -29 -2 -38 -18 -43
-11 -4 -23 -5 -26 -2 -2 3 5 8 16 12 12 3 21 11 21 16 0 12 -4 11 -35 -3 -19
-9 -28 -9 -37 0 -15 15 -28 15 -28 1 0 -5 4 -8 8 -5 5 3 9 -1 9 -9 0 -12 -4
-12 -19 2 -18 16 -18 16 -12 -3 7 -16 5 -17 -8 -7 -8 7 -18 10 -22 6 -3 -4
-61 -8 -129 -10 -83 -2 -117 0 -107 7 10 6 11 10 3 10 -7 0 -13 8 -13 18 0 10
6 24 13 31 7 9 7 11 1 8 -7 -4 -15 0 -18 9 -4 9 -11 12 -18 7 -7 -4 -37 -8
-65 -9 -57 0 -64 7 -72 71 0 6 -19 10 -41 9 -22 0 -40 3 -40 8 0 4 4 8 10 8 6
0 5 11 -3 28 -14 32 -26 42 -42 36 -6 -3 -18 3 -26 12 -17 20 -20 84 -5 84 6
0 -4 10 -21 23 -18 12 -33 27 -33 33 0 7 -7 15 -16 18 -9 4 -14 9 -11 13 8 8
-54 79 -66 75 -5 -2 -7 4 -4 12 4 11 1 13 -10 9 -12 -5 -15 -2 -10 10 7 18
-18 50 -44 59 -8 3 -13 16 -11 31 1 15 -2 27 -6 27 -4 0 -7 -18 -6 -40 1 -22
-2 -42 -5 -45 -4 -2 -8 90 -8 205 -1 119 2 212 8 215 5 4 9 14 9 23 0 15 -2
15 -10 2 -8 -12 -10 -12 -10 -1 0 8 7 19 17 26 13 9 13 14 3 20 -9 5 -11 4 -6
-3 4 -7 3 -12 -2 -12 -12 0 -12 79 -1 86 5 3 6 -2 3 -10 -3 -8 0 -23 6 -33 10
-16 11 -16 6 0 -3 9 -2 17 2 17 4 0 8 15 8 33 1 17 4 47 7 65 4 18 2 44 -3 57
-7 19 -6 25 5 25 9 0 12 -6 9 -15 -10 -26 5 -16 20 13 8 15 12 40 9 56 -5 22
-3 27 7 21 9 -6 11 -4 5 5 -5 8 -10 36 -11 63 -1 33 1 45 7 36 7 -11 9 -11 9
3 0 9 -5 20 -10 23 -6 4 -9 30 -7 58 l3 52 39 -1 c38 0 55 9 55 31 0 6 -4 4
-8 -2 -4 -7 -8 40 -9 105 0 64 2 111 4 104 4 -10 8 -10 19 -1 11 9 14 8 14 -2
0 -12 2 -12 9 0 5 8 16 11 26 8 9 -4 13 -3 10 2 -3 5 -1 13 5 16 7 5 8 11 1
19 -15 18 -9 56 8 56 12 0 12 -3 3 -12 -7 -7 -12 -16 -12 -21 0 -5 7 -2 15 7
9 8 12 22 8 32 -4 12 -3 15 6 9 9 -5 9 1 -2 21 -11 20 -12 36 -5 62 5 18 7 36
4 40 -11 10 -19 -7 -19 -38 0 -19 -4 -27 -10 -21 -16 16 7 66 31 67 25 1 26 0
12 -26 -7 -13 -6 -19 5 -24 8 -3 17 0 21 6 4 7 3 8 -4 4 -7 -4 -12 -2 -12 4 0
6 6 13 13 16 7 2 13 17 12 32 0 15 6 30 15 34 12 5 12 7 -2 7 -13 1 -18 9 -18
34 0 23 3 28 9 17 6 -8 7 -18 5 -22 -3 -4 -1 -8 3 -8 5 0 14 10 22 23 13 20
13 21 -1 9 -13 -10 -17 -9 -22 10 -4 13 -9 37 -10 53 -2 17 -1 21 1 11 4 -16
7 -17 14 -5 7 11 9 11 9 -3 0 -12 8 -18 22 -18 12 0 18 3 15 7 -4 3 -2 12 4
20 6 7 8 13 4 13 -4 0 -14 -8 -21 -17 -11 -15 -13 -15 -14 -3 0 22 16 43 28
36 6 -3 13 -2 17 4 10 16 -27 11 -47 -7 -17 -15 -18 -15 -18 0 0 10 5 17 12
17 6 0 8 3 5 6 -4 4 -14 1 -23 -7 -15 -12 -17 -12 -12 1 3 9 17 15 34 15 47 0
51 8 48 87 l-3 73 29 0 c16 1 26 -3 24 -7 -3 -4 -1 -8 4 -8 6 0 15 12 20 28
23 63 24 67 14 53 -7 -10 -9 -1 -7 30 1 24 4 49 5 54 1 6 1 12 0 15 -1 3 -3
20 -5 38 -2 27 0 31 11 21 12 -9 13 -9 9 3 -7 17 23 21 42 6 8 -6 14 -2 18 12
4 12 4 24 1 27 -3 4 1 15 11 25 9 10 11 18 5 18 -6 0 -13 -6 -15 -12 -3 -7 -4
3 -2 22 4 52 4 52 15 45 10 -6 24 21 36 69 4 16 1 23 -10 23 -11 0 -13 -8 -9
-31 5 -31 5 -30 -17 8 -11 21 -19 41 -16 44 3 3 6 0 6 -6 0 -7 5 -12 11 -12 5
0 7 5 4 10 -3 6 -1 10 4 10 6 0 11 -6 12 -12 0 -10 2 -10 6 0 2 6 9 12 14 12
6 0 7 -4 4 -10 -3 -5 2 -10 11 -10 10 0 14 3 11 7 -4 3 0 17 8 29 8 13 12 31
8 40 -5 13 -3 15 7 9 8 -5 11 -4 6 3 -3 6 -2 13 4 17 13 8 13 55 0 55 -6 0 -8
-10 -4 -24 4 -17 1 -27 -12 -34 -11 -6 -15 -17 -11 -31 3 -11 1 -24 -4 -27 -6
-3 -9 11 -8 35 2 29 7 41 18 41 11 0 11 3 -4 14 -24 17 -14 46 15 41 12 -2 20
1 20 10 0 8 9 19 20 25 12 7 17 16 13 27 -4 10 -2 14 4 10 6 -4 11 3 11 18 0
14 -4 22 -9 19 -5 -3 -9 0 -9 5 0 6 5 11 10 11 6 0 10 5 10 11 0 5 -4 8 -8 5
-4 -2 -9 13 -9 36 -2 31 1 37 11 29 7 -6 16 -7 21 -3 4 4 5 0 3 -8 -3 -8 -11
-14 -17 -12 -7 1 -10 -2 -6 -7 3 -6 12 -8 20 -5 17 7 28 34 14 34 -6 0 -7 6
-3 13 5 8 2 8 -9 -2 -19 -15 -24 -2 -6 16 8 8 8 13 0 18 -6 3 -10 3 -9 -2 2
-4 -1 -9 -7 -11 -5 -2 -11 4 -13 12 -2 10 4 16 15 16 15 0 15 2 4 9 -8 5 -16
17 -17 26 -4 24 -9 27 -47 29 -33 1 -34 2 -33 44 1 23 -2 42 -6 42 -5 0 -8 6
-8 14 0 8 -13 25 -29 39 -24 20 -39 24 -74 22 -44 -3 -44 -3 -43 30 1 21 5 31
13 28 7 -3 10 -9 7 -14 -3 -5 -1 -16 4 -25 8 -14 10 -13 23 2 13 16 12 17 -5
11 -15 -6 -17 -5 -7 6 9 12 5 17 -21 28 -17 7 -42 12 -55 11 -16 -1 -23 4 -24
16 0 13 -2 14 -6 2 -3 -8 -12 -23 -21 -32 -10 -12 -11 -18 -4 -18 7 0 12 -5
12 -11 0 -5 -4 -7 -10 -4 -13 8 -13 -1 2 -25 10 -16 10 -24 0 -40 -15 -24 -15
-33 -2 -25 6 3 10 1 10 -5 0 -7 -7 -10 -15 -6 -8 3 -15 1 -15 -4 0 -5 -9 -8
-20 -7 -20 1 -17 12 3 12 7 0 12 9 12 20 0 11 -7 20 -15 20 -8 0 -15 -8 -15
-17 0 -9 -4 -20 -8 -24 -4 -4 -7 -17 -7 -29 0 -11 -6 -27 -12 -34 -11 -11 -10
-13 0 -7 8 5 11 1 9 -13 -2 -11 -6 -24 -10 -27 -4 -4 -7 -17 -6 -28 0 -21 1
-21 9 -1 8 19 9 19 9 -1 1 -15 -6 -23 -23 -26 -19 -4 -22 -1 -18 15 3 14 1 18
-9 15 -8 -3 -14 -11 -14 -19 0 -8 -5 -19 -11 -25 -6 -7 -9 -56 -8 -121 l2
-110 -25 0 c-17 0 -21 2 -12 8 7 4 10 11 6 15 -4 4 -13 2 -19 -4 -7 -7 -17
-12 -22 -12 -6 0 -5 5 2 12 7 7 12 19 12 27 0 9 5 23 10 31 9 13 12 12 25 -4
8 -11 15 -15 15 -9 0 5 -9 18 -20 28 -19 17 -20 17 -30 -11 -6 -16 -12 -31
-12 -34 0 -3 -5 -14 -11 -25 -6 -11 -12 -26 -13 -33 -1 -7 -10 -24 -18 -38 -9
-13 -12 -24 -6 -24 5 0 9 -3 9 -7 -3 -21 2 -33 12 -27 8 4 7 9 -2 16 -11 8
-11 9 1 5 21 -5 21 -74 0 -73 -8 1 -18 9 -22 18 -4 11 -13 15 -23 12 -8 -3
-12 -11 -9 -16 4 -6 10 -5 15 3 7 11 9 10 9 -5 0 -13 -5 -17 -18 -14 -15 4
-17 -3 -16 -68 1 -41 -2 -71 -7 -68 -5 3 -9 1 -9 -5 0 -5 6 -12 13 -14 6 -3
-3 -4 -21 -3 -40 4 -62 -15 -56 -48 2 -14 -1 -28 -7 -32 -8 -5 -9 -3 -4 7 5 8
4 11 -1 6 -5 -5 -9 -12 -9 -15 0 -4 -4 -13 -8 -19 -10 -16 5 -17 21 -1 9 9 12
8 12 -5 0 -9 -4 -17 -10 -17 -5 0 -10 -5 -10 -11 0 -5 4 -8 9 -5 4 3 7 -16 6
-42 -3 -60 -2 -59 -19 -59 -9 0 -12 7 -8 16 4 12 0 11 -18 -4 l-24 -20 17 27
c9 15 17 33 17 40 0 7 -9 -2 -21 -21 -12 -19 -21 -54 -23 -85 -2 -45 -5 -51
-24 -51 -22 0 -42 -19 -42 -40 0 -5 5 -3 10 5 8 12 10 10 10 -8 0 -13 -4 -21
-9 -17 -6 3 -15 -4 -21 -15 -6 -11 -7 -20 -3 -20 4 0 2 -5 -5 -12 -13 -13 -16
-32 -4 -24 4 2 7 -10 7 -27 -3 -49 -4 -52 -17 -39 -7 7 -16 12 -21 12 -5 0 2
-9 16 -20 20 -16 25 -28 23 -55 -2 -20 -7 -39 -11 -43 -4 -4 -6 12 -3 36 2 23
1 42 -2 43 -3 0 -13 2 -22 4 -10 2 -18 -1 -18 -5 0 -14 18 -21 22 -10 2 6 4
-7 4 -30 1 -41 -5 -47 -35 -30 -16 8 -18 5 -15 -21 2 -17 -1 -40 -7 -51 -8
-15 -8 -19 0 -14 6 4 11 0 11 -9 0 -13 -4 -14 -21 -5 -19 11 -20 10 -9 -10 6
-11 15 -18 19 -15 5 3 8 -6 7 -19 0 -13 -4 -22 -8 -20 -3 2 -8 -2 -11 -8 -3
-9 -10 -8 -26 6 -18 17 -21 17 -15 3 3 -9 -1 -23 -10 -32 -11 -12 -14 -30 -11
-76 5 -57 3 -63 -22 -83 -15 -11 -31 -20 -35 -19 -5 2 -8 -2 -8 -8 0 -6 9 -8
20 -4 14 4 20 2 20 -9 0 -9 -7 -13 -20 -11 -21 4 -46 -28 -35 -45 3 -6 1 -10
-6 -10 -9 0 -9 -3 0 -14 8 -10 9 -16 1 -21 -6 -3 -8 -10 -5 -15 11 -17 14 -70
4 -64 -5 3 -7 9 -4 14 7 12 -5 40 -17 40 -6 0 -5 -7 2 -15 11 -13 9 -19 -7
-38 -36 -40 -50 -96 -46 -182 4 -93 -1 -121 -22 -129 -12 -4 -14 0 -10 22 l5
27 -14 -30 c-12 -25 -14 -27 -18 -10 -3 14 -7 7 -13 -20 -4 -22 -13 -43 -19
-47 -8 -5 -7 -8 1 -8 8 0 12 -15 11 -46 -2 -36 -5 -42 -14 -30 -17 24 -24 19
-11 -8 7 -13 14 -23 17 -21 3 2 5 -14 5 -36 0 -21 -3 -39 -7 -39 -5 0 -8 6 -8
13 0 8 -3 8 -8 2 -4 -6 -16 -11 -26 -10 -21 0 -39 -45 -30 -70 3 -7 0 -16 -5
-20 -6 -3 -9 -11 -5 -16 3 -6 8 -41 10 -79 2 -51 -1 -70 -11 -74 -7 -2 -16 0
-19 7 -6 12 -2 -8 10 -55 5 -22 5 -22 -10 -4 -15 18 -16 18 -16 2 0 -9 -4 -15
-10 -11 -6 4 -6 15 1 32 5 14 7 28 5 31 -8 7 -27 -48 -20 -59 3 -5 1 -9 -4 -9
-5 0 -8 -35 -7 -77 1 -86 -2 -106 -17 -97 -6 4 -8 1 -6 -7 3 -8 10 -13 15 -12
12 2 16 -54 3 -62 -5 -3 -10 -18 -9 -33 l1 -27 8 25 8 25 1 -28 c1 -21 -3 -28
-16 -26 -56 5 -61 1 -65 -53 -2 -29 -8 -62 -12 -73 -6 -16 -6 -17 3 -5 13 18
13 7 2 -42 -5 -22 -5 -36 0 -32 12 7 11 -3 -1 -26 -7 -13 -7 -21 0 -25 6 -3 9
-37 8 -74 -1 -65 -3 -68 -25 -68 -13 0 -26 4 -29 9 -4 6 -9 5 -14 -2 -6 -9
-13 -7 -29 9 -19 19 -34 25 -45 19 -2 -2 -14 6 -27 17 -18 16 -21 17 -16 4 3
-9 0 -22 -6 -29 -10 -10 -7 -10 12 0 15 8 21 8 17 1 -4 -6 -2 -11 3 -11 6 0
11 5 11 11 0 5 5 7 11 3 8 -4 7 -9 -2 -16 -10 -7 -8 -8 8 -5 13 3 26 1 29 -4
4 -8 -42 -8 -99 0 -4 1 -5 15 -2 31 3 18 1 30 -5 30 -6 0 -8 5 -4 12 4 6 3 8
-3 5 -6 -4 -9 -18 -8 -32 2 -14 -1 -25 -7 -25 -6 0 -7 7 -4 16 4 10 -1 25 -11
36 -16 18 -16 19 3 12 14 -4 16 -4 5 4 -8 6 -20 8 -27 5 -6 -2 -15 1 -19 6 -7
13 -25 5 -25 -11 0 -5 7 -8 15 -5 8 4 17 1 21 -4 4 -7 -3 -9 -17 -7 -26 3 -33
32 -15 57 8 11 8 13 0 8 -7 -4 -14 -1 -17 7 -3 8 -16 12 -31 11 -14 -2 -24 1
-21 5 3 5 0 12 -6 15 -5 4 -8 14 -5 22 3 8 2 12 -2 9 -12 -7 -54 23 -47 34 3
4 -3 14 -12 21 -14 12 -15 11 -8 -10 6 -21 5 -22 -8 -10 -8 8 -14 24 -12 36 3
25 -18 48 -35 38 -5 -3 -10 -4 -10 -1 0 2 -3 13 -7 23 -6 16 -5 17 13 3 14
-11 14 -8 -3 11 -13 14 -23 30 -23 36 0 5 -11 19 -25 29 -23 17 -37 42 -16 30
5 -4 4 7 -2 24 -12 32 -21 37 -33 18 -5 -7 -3 -8 6 -3 10 6 12 4 7 -9 -4 -10
-3 -15 3 -11 13 8 8 -38 -5 -47 -5 -4 -6 -10 -2 -15 5 -4 11 -2 15 4 10 16 43
-9 45 -35 0 -11 5 -18 11 -15 5 3 6 1 2 -6 -4 -6 -12 -9 -17 -5 -5 3 -7 9 -5
13 10 15 -13 34 -45 35 -27 1 -33 6 -34 26 -2 14 3 31 9 39 8 11 8 13 -1 7
-18 -10 -16 14 2 38 17 23 19 36 6 36 -5 0 -12 -8 -14 -18 -3 -9 -4 11 -2 46
2 48 0 62 -11 62 -8 0 -16 -1 -17 -2 -2 -2 -12 4 -22 12 -11 9 -16 10 -11 3 4
-8 2 -13 -6 -13 -7 0 -22 -13 -33 -28 -15 -21 -15 -24 -2 -13 15 12 17 11 17
-7 0 -12 -5 -24 -10 -27 -8 -5 -7 -12 2 -23 11 -13 11 -14 -2 -10 -8 2 -17 15
-21 29 -5 17 -7 19 -8 6 -1 -10 -5 -16 -10 -13 -10 6 -45 -31 -55 -58 -6 -19
-6 -19 12 -3 10 9 26 17 34 17 9 0 18 4 20 9 2 5 12 3 22 -5 11 -8 14 -14 7
-14 -7 0 -9 -4 -6 -10 3 -5 1 -10 -4 -10 -6 0 -11 8 -12 18 0 14 -2 14 -9 -3
-5 -11 -9 -14 -9 -7 -1 19 -25 14 -39 -8 -7 -12 -18 -17 -28 -13 -10 4 -15 1
-14 -8 4 -25 0 -39 -9 -39 -6 0 -8 10 -6 21 4 21 4 21 -15 4 -11 -10 -18 -20
-15 -23 3 -3 0 -11 -7 -19 -9 -11 -8 -13 3 -11 10 2 14 -7 13 -32 0 -34 -2
-35 -37 -35 -25 0 -37 -4 -37 -14 0 -11 -5 -9 -22 5 -12 10 -18 13 -14 7 4 -8
2 -13 -5 -13 -22 0 -68 -51 -62 -68 5 -11 2 -14 -9 -9 -23 8 -50 -24 -37 -43
9 -12 10 -12 5 3 -7 23 11 22 26 -3 16 -25 24 -25 24 -1 0 10 -7 18 -15 17 -7
-1 -16 5 -19 13 -3 9 0 11 8 6 8 -5 19 1 30 15 13 17 19 20 25 10 5 -9 4 -11
-3 -6 -12 7 -13 4 -16 -46 -1 -35 -12 -44 -32 -27 -14 12 -19 10 -37 -11 -17
-20 -19 -21 -13 -5 4 11 -8 3 -28 -19 -19 -22 -33 -43 -30 -48 3 -4 -2 -8 -11
-8 -10 0 -13 5 -9 13 5 7 1 7 -10 -2 -14 -12 -22 -12 -37 -3 -13 8 -19 9 -19
1 0 -5 -4 -8 -9 -5 -5 3 -12 -1 -15 -10 -3 -8 -2 -12 4 -9 6 3 10 1 10 -4 0
-14 -13 -14 -27 0 -13 13 -33 4 -33 -15 0 -9 -9 -12 -26 -8 -21 4 -25 2 -19
-8 6 -10 4 -12 -8 -7 -10 4 -14 2 -11 -6 3 -9 -23 -12 -101 -13 -58 0 -105 3
-105 7 0 4 -15 3 -32 -2 -40 -11 -47 -11 -30 0 9 6 -1 12 -33 20 -26 7 -50 9
-53 6 -3 -3 -16 2 -30 11 -22 15 -25 14 -43 -5 -19 -20 -13 -27 16 -21 25 5
88 8 71 3 -15 -4 -16 -7 -5 -14 11 -7 11 -9 -3 -9 -10 0 -18 5 -18 10 0 6 -5
10 -11 10 -5 0 -8 -4 -5 -8 3 -5 -17 -9 -45 -9 -27 -1 -47 2 -45 6 3 4 -6 14
-19 21 -18 9 -23 19 -19 32 6 20 3 20 -63 20 l-42 0 24 22 23 22 -28 16 c-27
15 -28 15 -22 0 5 -14 3 -15 -9 -6 -11 9 -17 9 -28 -3 -12 -13 -11 -14 6 -9
11 3 27 -1 39 -10 18 -15 18 -15 -9 -9 -19 4 -29 1 -33 -10 -4 -8 -9 -15 -13
-15 -9 0 -7 53 3 69 6 10 5 12 -2 7 -7 -4 -12 -3 -12 1 0 5 -17 8 -37 8 -34 0
-38 3 -39 27 -1 15 3 25 8 22 4 -3 5 -11 1 -17 -5 -8 -2 -8 9 0 8 7 18 10 21
6 10 -9 9 -25 -1 -19 -4 2 -14 1 -22 -4 -11 -7 -6 -9 14 -7 43 3 41 18 -9 55
-25 18 -45 28 -45 22 0 -6 -5 -8 -10 -5 -6 4 -8 10 -5 14 4 8 -33 48 -41 44
-11 -8 -24 -1 -24 11 0 8 -7 17 -16 20 -12 4 -15 2 -10 -9 3 -8 1 -15 -3 -15
-15 0 -20 27 -8 41 7 8 -1 3 -17 -11 l-30 -25 16 26 c16 26 16 27 -11 53 -15
14 -30 23 -34 19 -4 -3 -7 0 -7 8 0 19 -59 81 -73 76 -25 -9 10 -77 39 -77 7
0 15 6 18 13 3 6 4 1 3 -13 -1 -16 3 -24 11 -22 10 2 12 -5 7 -28 -4 -20 -3
-30 5 -30 7 0 10 7 7 14 -4 10 -1 13 9 9 7 -3 14 -9 14 -14 0 -11 -50 -32 -58
-24 -3 3 0 5 8 5 9 0 10 3 1 14 -6 7 -9 21 -5 30 4 10 -1 18 -12 23 -40 15
-66 50 -69 92 -2 35 0 39 11 30 8 -7 17 -9 21 -5 3 3 2 6 -3 6 -5 0 -18 13
-27 30 -10 17 -24 30 -30 30 -7 0 -6 -4 3 -10 9 -6 10 -10 3 -10 -6 0 -15 4
-18 10 -3 5 -11 10 -16 10 -6 0 -8 -4 -5 -8 3 -5 -1 -9 -7 -9 -9 0 -13 16 -12
46 1 54 -4 73 -16 65 -5 -3 -9 2 -9 11 0 10 5 13 12 9 7 -4 8 -3 4 4 -4 6 -14
8 -24 5 -14 -6 -15 -4 -5 9 11 12 10 21 -4 49 -10 19 -18 40 -19 47 -5 27 -11
22 -12 -8 0 -19 3 -28 10 -24 6 4 9 1 7 -5 -9 -27 -24 -4 -26 39 0 25 -2 53
-2 63 -1 11 -5 15 -13 11 -8 -5 -9 -2 -4 11 3 10 4 27 0 38 -6 20 -7 20 -22 1
-14 -18 -14 -18 -9 3 3 13 0 26 -5 30 -6 3 -8 1 -5 -4 4 -6 0 -16 -8 -23 -8
-7 -15 -21 -15 -31 0 -10 -11 -30 -25 -45 -32 -34 -33 -45 -1 -15 l24 22 0
-55 c0 -60 -20 -90 -61 -91 -18 0 -22 -5 -21 -32 2 -32 -9 -43 -21 -23 -4 6
-1 17 5 25 9 11 9 15 1 15 -6 0 -11 -6 -11 -12 0 -9 -4 -8 -10 2 -9 13 -10 13
-11 0 -1 -8 -1 -26 -1 -38 1 -21 -1 -22 -15 -10 -10 8 -14 8 -10 1 4 -7 0 -16
-9 -21 -12 -7 -13 -11 -5 -17 9 -5 9 -10 0 -21 -6 -8 -7 -14 -2 -14 5 0 10 -7
10 -15 0 -8 -9 -15 -19 -15 -10 0 -18 5 -18 11 0 5 5 7 12 3 7 -4 8 -3 4 4 -4
6 -13 9 -22 5 -12 -4 -12 -8 -2 -21 11 -13 10 -15 -4 -9 -12 4 -21 -1 -28 -16
-11 -22 -11 -22 6 -2 16 20 16 20 11 -3 -6 -24 23 -47 31 -24 2 7 8 10 13 6 5
-3 9 2 9 10 0 10 -6 13 -15 10 -8 -4 -15 -10 -15 -15 0 -5 -5 -9 -10 -9 -6 0
-5 8 2 20 17 28 43 21 44 -11 1 -22 -3 -26 -29 -27 -17 0 -39 4 -49 9 -10 5
-18 6 -18 1 0 -4 -15 -23 -33 -41 -29 -30 -33 -40 -31 -85 0 -28 -1 -45 -3
-38 -3 6 -9 12 -14 12 -6 0 -7 -5 -4 -10 4 -6 -8 -10 -29 -10 -19 0 -38 -5
-42 -12 -4 -6 -3 -9 2 -6 5 4 10 -9 11 -27 2 -29 -1 -33 -21 -31 -12 1 -27 5
-33 9 -16 10 -42 -2 -53 -24 -8 -14 -18 -17 -43 -14 -21 3 -25 2 -12 -3 21 -7
32 -32 15 -32 -5 0 -10 4 -10 8 0 4 -24 8 -54 8 -30 -1 -49 -4 -43 -8 21 -14
-16 -8 -42 6 -21 12 -21 14 -6 20 10 3 27 4 39 1 19 -5 19 -5 3 5 -9 6 -27 8
-38 5 -15 -5 -19 -4 -15 4 7 11 -7 13 -86 13 -21 0 -40 4 -44 9 -3 5 -11 9
-17 9 -7 0 -6 -4 3 -11 12 -8 12 -10 1 -7 -8 3 -16 19 -17 38 -2 18 -7 36 -12
39 -6 3 -41 6 -79 6 -48 -1 -70 3 -70 11 0 7 7 10 16 7 11 -3 10 0 -4 12 -11
9 -19 18 -17 20 5 11 0 31 -13 49 -12 16 -16 16 -34 4 -12 -8 -18 -18 -14 -21
3 -4 1 -7 -5 -7 -6 0 -10 9 -9 19 0 10 6 17 12 14 7 -2 12 3 12 12 0 9 -6 14
-14 11 -8 -3 -11 -1 -8 4 3 6 -5 14 -19 20 -14 5 -26 12 -26 15 0 4 6 3 13 -1
6 -4 2 4 -11 17 -19 21 -24 22 -33 9 -9 -13 -10 -12 -5 2 6 20 -21 51 -38 45
-7 -3 -21 2 -31 10 -16 13 -17 14 -3 10 10 -3 20 -2 23 3 4 6 -4 10 -16 10
-16 0 -20 5 -16 20 4 15 0 20 -13 20 -17 0 -35 25 -32 43 1 4 -3 7 -9 7 -5 0
-8 4 -4 9 9 15 -16 25 -60 26 -28 0 -40 4 -41 15 0 9 1 10 3 3 8 -23 21 -14
15 10 l-5 22 22 -20 23 -20 -13 28 c-7 15 -16 27 -21 27 -4 0 -8 8 -8 18 0 15
2 15 11 2 8 -12 10 -12 7 4 -6 36 -27 29 -30 -11 -2 -21 -4 -3 -4 39 0 42 4
74 8 72 10 -6 11 10 1 20 -4 4 -8 29 -9 57 -2 37 1 49 12 49 16 0 19 -37 3
-42 -7 -2 -7 -11 0 -28 l10 -25 0 28 c1 15 6 27 12 27 6 0 8 9 4 20 -4 11 -2
20 4 20 6 0 11 -6 11 -12 1 -9 4 -8 9 2 5 8 11 47 14 85 2 39 8 79 12 89 6 14
5 17 -3 12 -9 -5 -11 13 -8 66 3 75 12 108 27 108 15 0 10 -27 -6 -34 -8 -3
-15 -11 -15 -18 0 -9 3 -8 10 2 5 8 10 11 10 5 0 -5 4 -4 9 4 11 17 7 82 -8
131 -6 19 -11 35 -11 36 0 1 11 0 25 -1 14 -2 28 2 32 8 3 5 6 51 6 101 -1 50
3 97 7 105 4 8 6 -9 5 -38 -3 -57 16 -68 20 -12 2 23 0 29 -6 20 -6 -10 -8 -3
-7 21 2 24 -1 34 -9 33 -7 -2 -13 4 -13 13 0 14 2 15 17 3 15 -12 16 -12 11 2
-4 9 -1 22 5 28 6 6 8 14 4 18 -4 3 -13 -1 -20 -10 -11 -16 -12 -15 -12 6 0
24 23 32 37 12 5 -6 8 -5 8 3 0 6 5 12 11 12 8 0 8 -4 0 -14 -7 -8 -16 -27
-20 -43 -7 -25 -6 -29 13 -30 11 -1 15 1 9 4 -15 5 -17 39 -4 47 5 4 12 0 15
-6 3 -7 3 -3 0 10 -3 14 -1 22 7 22 7 0 9 3 6 7 -4 3 -1 13 6 21 6 9 7 13 1 9
-7 -4 -11 5 -11 23 0 25 2 27 12 13 10 -13 11 -11 7 16 -2 17 1 37 7 45 14 16
2 29 -14 16 -10 -8 -12 1 -11 42 1 52 2 52 34 54 28 1 31 0 20 -14 -11 -13
-10 -14 2 -10 17 5 32 72 23 103 -5 17 -4 18 10 7 13 -11 15 -10 10 8 -3 11 1
28 9 38 18 21 30 62 17 62 -5 0 -9 7 -9 16 0 8 -5 12 -11 8 -8 -4 -8 -9 0 -17
17 -17 13 -36 -11 -65 -14 -18 -19 -21 -14 -8 10 28 -10 45 -47 39 -18 -3 -34
-9 -35 -14 -2 -5 -9 -6 -14 -3 -7 5 -8 2 -3 -6 4 -7 9 -34 11 -59 3 -46 3 -46
-10 -16 -10 22 -10 34 -2 44 9 12 8 12 -11 1 -16 -10 -19 -18 -12 -26 7 -8 6
-14 -1 -19 -5 -3 -10 0 -10 7 0 7 -9 0 -19 -16 -11 -17 -18 -33 -15 -38 3 -4
0 -8 -6 -8 -7 0 -8 -6 -1 -18 5 -10 9 -47 9 -83 -1 -55 -4 -65 -18 -65 -16 0
-17 3 -4 20 8 11 13 32 13 46 l-1 25 -7 -25 c-8 -34 -33 -70 -48 -70 -7 0 -13
-5 -13 -12 0 -9 -3 -8 -12 1 -10 10 -10 15 -1 18 27 10 63 46 63 63 0 10 5 22
11 26 8 4 8 9 0 17 -15 15 -14 30 1 21 7 -4 8 -3 4 5 -10 16 -29 3 -22 -15 3
-8 -1 -17 -10 -21 -12 -4 -13 -8 -3 -14 17 -10 -9 -62 -28 -55 -17 7 -35 -28
-26 -50 4 -11 3 -15 -5 -10 -6 3 -16 -2 -22 -13 -7 -14 -7 -22 2 -27 6 -4 8
-3 5 2 -3 6 -2 15 2 22 5 9 11 6 18 -11 10 -21 8 -23 -20 -25 -34 -3 -48 -17
-39 -40 3 -9 1 -13 -5 -9 -6 4 -14 0 -17 -9 -4 -12 -2 -15 9 -10 13 5 14 2 4
-17 -16 -29 -24 -37 -15 -13 5 16 4 17 -9 6 -9 -7 -13 -15 -11 -18 3 -2 0 -14
-6 -26 l-11 -21 24 20 c13 11 27 24 31 30 4 5 6 -4 5 -20 -2 -26 -7 -30 -37
-32 -42 -4 -48 -13 -45 -69 2 -45 -22 -83 -44 -69 -6 3 -11 2 -11 -4 0 -5 6
-12 13 -14 6 -3 2 -6 -9 -6 -25 -1 -32 -14 -29 -58 1 -19 -4 -34 -12 -37 -11
-5 -11 -1 -2 15 8 15 8 19 0 15 -6 -4 -16 -16 -22 -28 -8 -15 -8 -19 0 -14 21
13 11 -13 -11 -28 -12 -9 -18 -16 -13 -16 5 0 21 11 35 25 l26 25 -1 -24 c0
-13 -4 -28 -9 -33 -5 -5 -6 -3 -2 5 4 6 2 12 -3 12 -6 0 -11 -4 -11 -8 0 -4
-10 -8 -22 -9 -25 -2 -38 -20 -30 -41 4 -11 2 -13 -9 -7 -12 7 -12 6 -1 -7 7
-9 11 -35 10 -60 -2 -36 -7 -44 -23 -46 -13 -1 -16 1 -7 7 10 6 10 10 -2 20
-11 9 -16 9 -22 0 -4 -8 -3 -9 4 -5 21 12 13 -13 -10 -36 -13 -13 -21 -29 -20
-36 2 -9 -1 -11 -10 -6 -9 5 -8 2 2 -11 14 -17 13 -18 -2 -12 -12 4 -15 2 -11
-9 3 -7 0 -14 -7 -14 -6 0 -9 -4 -5 -9 3 -5 -2 -17 -11 -25 -8 -9 -12 -16 -8
-16 4 0 -1 -9 -10 -19 -13 -15 -14 -20 -4 -24 10 -5 9 -8 -4 -17 -14 -9 -16
-22 -13 -71 3 -36 0 -57 -5 -54 -6 4 -10 1 -10 -5 0 -6 -4 -9 -9 -6 -5 3 -14
-1 -21 -9 -7 -8 -16 -12 -21 -9 -5 3 -6 -2 -2 -11 5 -13 7 -14 14 -3 7 11 11
10 20 -2 8 -12 9 -9 4 10 -7 24 -7 24 9 5 8 -11 16 -23 16 -26 0 -8 -53 -4
-68 5 -7 4 -11 4 -10 -1 1 -4 -6 -23 -15 -40 -16 -30 -16 -33 -2 -33 9 0 13 4
10 10 -3 5 -2 18 4 27 7 13 10 -3 8 -59 -1 -43 -4 -78 -5 -78 -2 0 -18 10 -35
22 l-32 22 19 -22 c19 -22 19 -22 -7 -22 -19 0 -25 -4 -21 -15 4 -8 1 -15 -6
-15 -9 0 -9 -4 -3 -12 12 -15 13 -49 2 -43 -4 3 -10 -2 -13 -11 -3 -8 -10 -12
-16 -9 -5 3 -10 4 -10 1 0 -3 9 -10 20 -16 17 -9 19 -8 13 7 -3 10 -2 15 3 10
13 -12 14 -68 1 -60 -6 4 -8 0 -4 -10 4 -10 2 -17 -5 -17 -7 0 -8 12 -4 32 l6
32 -30 -29 c-16 -16 -28 -31 -25 -34 3 -2 12 4 20 15 10 14 14 15 15 5 0 -8
10 -25 21 -37 l22 -23 -27 -7 c-15 -3 -30 -4 -33 0 -3 3 -13 0 -21 -7 -13 -10
-14 -9 -3 11 7 13 10 26 7 29 -10 10 -27 -49 -18 -61 11 -13 9 -102 -1 -99
-27 6 -31 12 -17 22 8 5 11 13 7 17 -4 4 -1 14 6 22 10 12 9 14 -2 10 -8 -2
-18 -15 -22 -29 -4 -13 -12 -39 -18 -57 -6 -19 -6 -30 0 -27 5 4 9 -1 9 -9 0
-10 -7 -13 -20 -11 -11 2 -18 0 -15 -6 4 -5 2 -9 -4 -9 -5 0 -11 6 -14 13 -4
9 -7 9 -18 -1 -16 -15 -40 -16 -37 -1 3 12 -47 31 -56 21 -14 -13 -3 -22 37
-33 39 -11 137 -21 109 -12 -6 3 -10 9 -6 14 3 5 13 5 25 -1 10 -6 19 -9 19
-6 0 2 3 13 6 23 5 15 4 15 -5 3 -6 -9 -11 -10 -11 -3 0 24 13 43 29 43 14 0
14 -2 1 -10 -9 -6 -11 -13 -5 -18 6 -4 11 -20 12 -37 2 -25 1 -27 -6 -10 -5
11 -10 15 -10 9 -2 -27 -158 -42 -200 -19 -14 8 -21 14 -14 15 8 0 7 9 -3 30
-16 31 -34 48 -34 32 0 -6 -28 -9 -62 -9 -50 2 -62 5 -64 19 -1 13 5 18 21 18
13 0 27 4 30 10 4 6 -1 7 -11 3 -11 -4 -15 -2 -11 4 4 6 0 14 -9 17 -12 4 -15
2 -10 -9 3 -8 1 -15 -4 -15 -6 0 -10 7 -10 15 0 8 -5 15 -11 15 -5 0 -8 4 -4
9 3 5 1 12 -4 15 -5 4 -13 1 -17 -5 -5 -8 -3 -9 7 -4 8 5 11 5 7 0 -4 -4 -24
-9 -45 -10 -21 -1 -29 0 -18 3 l20 5 -20 9 c-19 8 -19 8 1 4 12 -2 28 0 35 5
10 6 3 9 -21 10 -30 0 -32 2 -15 10 19 9 19 9 -2 5 -23 -4 -43 11 -40 29 1 6
-14 18 -33 27 -19 10 -42 28 -52 40 -9 13 -20 19 -24 15 -4 -4 -2 -7 4 -7 6 0
9 -12 7 -30 -4 -34 9 -47 30 -30 11 9 15 9 15 1 0 -6 5 -11 11 -11 5 0 7 6 3
13 -5 7 -1 7 12 -3 10 -8 20 -15 21 -15 2 -1 -22 -2 -52 -3 l-55 -1 3 35 c5
39 -4 48 -47 48 -26 1 -30 5 -31 29 -1 16 3 27 11 27 10 0 11 -6 4 -27 -9 -26
-9 -27 5 -9 13 17 15 18 15 3 0 -19 8 -21 30 -7 8 5 10 10 4 10 -12 0 -64 45
-64 56 0 3 -16 21 -35 38 -19 18 -33 35 -29 38 3 3 2 8 -3 11 -4 3 -9 -10 -10
-28 -1 -21 4 -35 13 -39 12 -5 12 -2 3 17 -12 21 -11 21 8 4 26 -23 25 -45 -3
-43 -12 0 -20 4 -17 9 2 4 -1 7 -8 7 -7 0 -9 10 -6 26 3 16 0 28 -9 31 -8 3
-14 9 -14 15 0 5 4 6 10 3 6 -4 7 1 3 11 -5 13 -3 15 8 9 8 -5 3 4 -10 18 -24
27 -25 27 -43 7 -21 -22 -24 -38 -5 -22 8 7 18 7 27 2 13 -8 13 -12 -2 -26
-23 -23 -43 -12 -46 26 -2 23 1 29 10 24 10 -6 10 -4 2 7 -11 14 -11 163 0
209 5 21 5 21 -3 5 -6 -14 -9 -1 -9 48 -1 47 2 66 10 61 8 -5 8 -3 2 7 -5 8
-9 29 -9 47 0 29 1 31 13 15 12 -16 13 -14 7 16 -5 23 -4 31 4 27 13 -8 13 1
1 29 -8 21 -14 14 -23 -30 -2 -11 -4 33 -3 97 0 64 4 112 8 105 4 -6 7 -40 7
-75 0 -69 11 -101 12 -36 0 32 2 35 10 19 13 -29 4 31 -10 68 -14 36 -15 34
16 33 21 -1 28 5 34 29 5 16 7 50 5 75 -1 25 2 51 7 58 8 10 7 16 -4 23 -12 8
-12 10 1 19 10 6 11 13 5 18 -6 4 -11 30 -12 57 -1 28 1 44 4 36 5 -11 7 -11
14 0 13 21 12 -18 -2 -43 -7 -13 -7 -24 -1 -30 6 -6 12 7 16 36 4 25 12 46 17
46 6 0 8 -9 5 -20 -16 -57 -18 -65 -7 -50 21 28 33 95 35 178 1 58 5 85 15 91
10 7 11 13 2 30 -15 27 -20 27 -15 -1 l5 -23 -10 22 c-7 15 -16 20 -33 17 -13
-2 -17 -2 -10 2 17 8 15 25 -3 18 -8 -4 -15 -2 -15 4 0 20 25 18 42 -2 11 -13
17 -16 18 -8 0 8 5 10 12 6 8 -5 9 -2 5 9 -11 28 2 44 36 44 18 0 24 -2 15 -4
-10 -3 -19 -14 -19 -26 -2 -22 -2 -22 -6 1 -2 12 -9 20 -14 17 -5 -4 -5 -14 2
-26 5 -11 7 -29 3 -41 -4 -15 -3 -18 6 -13 15 9 3 -37 -13 -46 -5 -4 -7 -11
-3 -17 3 -6 2 -13 -4 -17 -5 -3 -10 -11 -10 -17 0 -6 6 -6 15 2 8 7 14 18 14
24 -1 6 3 22 8 37 6 14 9 38 6 55 -3 20 -2 25 5 16 7 -11 10 -11 15 3 4 9 3
13 -3 10 -15 -9 -12 6 4 24 7 9 17 27 20 41 6 20 5 22 -4 10 -9 -12 -10 -11
-5 6 5 21 -36 79 -49 67 -3 -3 -11 -1 -18 5 -9 7 -17 6 -25 -3 -8 -9 -9 -16
-3 -20 5 -3 8 -24 6 -45 -2 -22 1 -40 5 -40 5 0 9 -5 9 -11 0 -5 -5 -7 -11 -3
-8 4 -9 0 -4 -15 9 -28 -3 -27 -14 1 -5 14 -5 28 2 36 18 22 -9 40 -30 19 -11
-11 -13 -17 -5 -17 21 0 13 -27 -16 -52 -32 -26 -59 -64 -29 -39 15 12 17 11
17 -3 0 -9 -5 -16 -10 -16 -7 0 -7 -7 -2 -17 13 -27 9 -116 -5 -120 -7 -3 -13
3 -13 12 0 15 -2 15 -15 2 -8 -9 -20 -17 -27 -19 -6 -2 -12 -24 -13 -49 -1
-24 -6 -55 -10 -69 -5 -19 -4 -22 4 -10 9 13 11 11 11 -7 0 -15 -6 -21 -18
-20 -14 1 -17 8 -15 34 2 34 -10 45 -21 17 -3 -8 -10 -12 -16 -9 -6 4 -7 -1
-3 -11 4 -10 2 -15 -4 -11 -6 4 -9 -27 -9 -86 l1 -92 -30 -1 c-16 -1 -34 -2
-40 -4 -5 -1 -14 -2 -18 -1 -5 1 -5 -4 -1 -11 13 -20 15 -50 4 -43 -5 3 -10
14 -11 23 0 11 -4 9 -10 -8 -8 -18 -7 -35 1 -58 9 -25 9 -36 0 -47 -15 -19
-50 -16 -42 4 4 11 7 11 10 3 3 -10 7 -10 18 -1 8 6 14 16 14 21 0 6 -7 5 -15
-2 -18 -15 -20 1 -3 18 7 7 8 12 2 12 -16 0 -44 -67 -37 -86 5 -13 3 -15 -7
-9 -10 6 -11 4 -4 -6 11 -18 14 -110 3 -103 -5 3 -9 1 -9 -5 0 -5 6 -12 13
-15 6 -3 -1 -4 -18 -3 -25 2 -30 6 -28 27 2 22 2 23 -8 7 -6 -10 -8 -21 -5
-24 3 -3 -2 -12 -10 -20 -8 -8 -12 -19 -9 -24 4 -5 1 -9 -6 -9 -9 0 -9 -3 0
-14 8 -10 9 -16 1 -21 -5 -3 -10 -11 -10 -16 0 -6 3 -8 8 -6 4 3 7 -23 6 -56
-2 -84 -1 -83 -25 -84 l-22 -2 22 15 c11 8 21 21 21 27 0 7 -7 3 -15 -7 -8
-11 -18 -17 -22 -13 -3 4 2 16 12 27 10 11 13 20 7 20 -7 0 -12 -4 -12 -10 0
-5 -5 -10 -11 -10 -6 0 -8 9 -4 23 7 22 7 22 -8 2 -9 -11 -11 -21 -6 -23 12
-5 12 -42 -1 -42 -5 0 -7 8 -4 18 5 14 4 14 -4 2 -5 -8 -13 -26 -16 -40 -6
-19 -5 -21 3 -10 9 13 11 13 11 -2 0 -10 -7 -18 -15 -18 -9 0 -12 -6 -8 -16 4
-11 8 -12 13 -4 4 6 7 -14 6 -44 0 -39 -4 -56 -13 -56 -7 0 -13 3 -13 8 0 4
-8 17 -18 29 l-17 23 -1 -24 c-1 -17 2 -23 12 -19 8 3 14 0 14 -5 0 -6 -10 -9
-23 -7 -21 4 -23 2 -19 -31 3 -24 0 -39 -9 -45 -8 -5 -9 -9 -3 -9 6 0 12 -11
12 -25 0 -13 -4 -22 -9 -19 -5 3 -9 1 -9 -5 0 -5 3 -11 8 -14 4 -2 1 -2 -6 -1
-7 2 -15 -4 -18 -14 -3 -9 -10 -25 -15 -34 -5 -10 -5 -18 -1 -18 5 0 15 11 22
25 7 13 17 22 22 19 4 -3 1 -15 -7 -27 -20 -28 -19 -48 1 -22 l16 20 -4 -20
c-2 -12 -12 -20 -23 -21 -65 -3 -63 -2 -60 -39 2 -19 0 -33 -5 -30 -5 3 -7 12
-4 19 3 8 0 17 -7 22 -8 4 -10 3 -5 -5 4 -6 2 -19 -6 -28 -11 -14 -11 -16 1
-9 9 6 12 5 9 -5 -3 -8 -10 -13 -16 -12 -5 1 -13 -4 -16 -13 -3 -8 -2 -13 3
-10 4 3 18 -5 30 -18 13 -13 18 -26 14 -29 -5 -3 -15 2 -23 13 -13 18 -45 17
-34 -1 3 -5 9 -7 14 -4 5 3 12 1 15 -5 4 -6 3 -10 -2 -9 -33 6 -43 -3 -43 -35
0 -18 -7 -39 -15 -46 -9 -7 -14 -19 -11 -27 3 -7 1 -13 -4 -13 -5 0 -7 -6 -4
-14 3 -8 1 -17 -5 -21 -6 -3 -11 -12 -11 -18 0 -7 4 -6 11 3 8 12 9 11 6 -5
-2 -11 -9 -19 -15 -17 -14 2 -15 -8 -3 -28 7 -10 4 -18 -10 -28 -10 -7 -19
-18 -19 -24 0 -6 11 -1 24 11 14 13 23 28 20 35 -2 6 2 20 11 31 15 19 15 19
9 -10 -4 -21 -3 -26 4 -16 7 10 9 5 9 -15 0 -16 -2 -23 -4 -17 -3 8 -15 1 -33
-19 -16 -17 -27 -35 -24 -40 3 -4 -2 -8 -11 -8 -10 0 -13 5 -9 12 5 7 3 8 -6
3 -20 -12 -72 -16 -65 -5 3 6 2 10 -4 10 -5 0 -13 -5 -16 -10 -3 -5 -11 -10
-17 -10 -7 0 -7 4 1 14 9 11 6 12 -14 8 -14 -2 -23 -8 -20 -11 2 -4 -18 -8
-44 -8 -39 0 -48 3 -46 16 2 9 4 22 4 29 1 7 5 11 11 7 5 -3 7 -12 3 -20 -7
-20 7 -19 22 2 12 17 51 11 41 -6 -3 -5 4 -7 15 -4 20 6 20 7 -7 21 -16 8 -38
22 -49 30 -12 10 -15 11 -7 2 22 -25 13 -31 -20 -14 -18 10 -46 18 -63 18 -27
1 -30 4 -28 30 1 16 -2 35 -8 42 -6 7 -7 19 -3 25 4 8 3 9 -4 5 -7 -4 -12 -1
-12 9 0 9 -4 14 -9 11 -5 -3 -7 -16 -4 -27 4 -16 2 -20 -8 -17 -16 5 -19 28
-6 45 11 16 -1 36 -19 29 -7 -3 -16 -1 -19 4 -3 5 -21 7 -40 5 -37 -3 -43 3
-22 24 10 11 10 13 0 7 -19 -11 -16 5 5 21 9 8 12 12 5 9 -6 -4 -14 -2 -18 4
-3 5 0 10 7 10 10 0 10 2 0 9 -8 4 -13 12 -12 17 1 5 -3 10 -9 12 -6 2 -11 -1
-11 -8 0 -6 4 -8 10 -5 6 3 7 -1 4 -10 -5 -13 -9 -12 -25 6 -25 29 -24 38 4
32 22 -5 22 -5 2 10 -11 9 -17 17 -13 17 4 0 0 9 -9 19 -9 11 -21 17 -26 14
-6 -3 -7 -1 -3 5 13 22 -11 47 -45 47 -25 0 -33 5 -35 22 -2 12 1 25 8 29 7 4
8 3 4 -4 -4 -7 -2 -12 5 -12 8 0 8 -4 -1 -15 -9 -10 -9 -15 -1 -15 6 0 11 5
11 11 0 5 4 8 8 5 5 -3 9 1 9 9 0 8 -4 12 -9 9 -5 -4 -8 0 -7 7 2 8 -5 31 -14
53 -9 21 -17 42 -17 47 0 5 -6 9 -14 9 -8 0 -17 7 -20 16 -9 24 -7 36 5 28 7
-4 6 3 -2 17 -7 13 -12 25 -11 26 4 6 -13 39 -24 48 -18 14 -43 92 -33 102 5
5 0 9 -10 9 -12 0 -17 -5 -13 -15 3 -9 1 -12 -6 -7 -6 3 -14 1 -18 -5 -4 -7
-3 -9 3 -6 6 4 14 0 17 -9 3 -8 10 -13 15 -9 6 3 9 -3 8 -13 -1 -13 -9 -18
-28 -17 -18 0 -25 3 -19 9 18 18 10 26 -9 9 -11 -9 -17 -21 -14 -27 4 -5 7
-36 8 -69 0 -32 -1 -54 -3 -48 -2 6 -8 8 -14 5 -5 -4 -2 -14 7 -24 16 -18 15
-19 -26 -17 -35 2 -43 -1 -45 -18 -2 -11 -7 -21 -13 -23 -5 -2 -7 -10 -4 -18
5 -13 7 -13 15 -2 8 10 9 10 5 -2 -3 -14 -33 -18 -67 -9 -12 3 -15 -5 -13 -40
1 -30 -2 -40 -8 -30 -7 12 -9 11 -9 -2 0 -9 3 -19 7 -22 13 -13 9 -55 -5 -50
-7 3 -10 13 -7 24 4 11 2 19 -4 19 -6 0 -11 -7 -11 -15 0 -8 -5 -15 -11 -15
-6 0 -8 9 -4 23 5 18 1 16 -24 -12 -26 -30 -28 -35 -15 -48 9 -9 12 -23 8 -37
-8 -27 -12 -31 -21 -23 -4 4 -1 13 5 19 7 7 12 17 12 23 -1 12 -30 -25 -30
-37 0 -5 10 -13 23 -18 22 -10 22 -10 -3 -7 -35 3 -41 16 -24 49 8 16 10 28 4
28 -5 0 -10 -7 -10 -16 0 -8 -5 -12 -10 -9 -6 4 -9 1 -8 -6 2 -7 -2 -13 -9
-14 -6 0 -15 -4 -20 -9 -5 -5 -3 -6 5 -2 6 4 12 2 12 -4 0 -6 5 -8 10 -5 15 9
12 -4 -4 -21 -9 -8 -13 -27 -11 -46 3 -21 1 -28 -4 -19 -6 9 -11 10 -15 2 -4
-6 -25 -9 -48 -7 -23 3 -37 1 -31 -2 6 -4 13 -18 17 -32 4 -14 4 -20 -1 -13
-6 8 -15 6 -33 -8 l-25 -20 35 7 c19 3 32 3 28 -1 -10 -10 -78 -15 -78 -5 0 5
13 17 29 26 17 10 27 22 24 27 -3 5 -9 3 -14 -4 -4 -7 -11 -10 -15 -6 -4 3 -1
14 7 24 11 14 9 13 -13 -2 -14 -11 -22 -21 -18 -23 5 -2 4 -14 -2 -28 -9 -18
-19 -24 -45 -24 -18 0 -33 5 -33 11 0 6 11 8 29 4 20 -4 30 -2 34 9 8 20 -18
40 -40 31 -26 -11 -101 -16 -84 -6 9 6 10 11 2 15 -6 4 -15 5 -20 1 -5 -3 -12
-1 -16 5 -3 5 -11 10 -17 10 -6 0 -6 -6 2 -15 7 -9 19 -14 26 -11 8 3 11 -4 9
-19 -1 -14 2 -22 7 -19 20 13 68 22 68 13 0 -5 -12 -9 -27 -10 l-28 -1 25 -8
c21 -7 19 -8 -17 -9 -38 -1 -43 2 -44 22 l0 22 -11 -23 c-16 -33 -53 -26 -53
10 -1 15 5 30 12 32 7 3 13 11 13 18 0 9 -3 9 -12 0 -10 -10 -16 -8 -31 10
-14 17 -17 18 -13 5 6 -18 -9 -24 -19 -7 -3 6 -11 10 -16 10 -6 0 -8 -3 -5 -8
2 -4 -17 -7 -42 -7 -45 0 -47 1 -48 32 -1 32 9 43 21 23 3 -6 1 -10 -4 -10 -6
0 -11 -5 -11 -11 0 -8 5 -8 15 1 10 8 15 9 15 2 0 -6 -8 -16 -17 -21 -24 -14
-7 -14 19 0 15 8 22 8 28 -1 5 -8 10 -9 14 -2 3 6 -5 23 -19 39 -19 21 -23 23
-18 8 6 -18 5 -18 -10 -6 -9 7 -15 17 -12 21 2 4 -7 5 -20 2 -18 -3 -25 0 -25
11 0 9 -4 19 -10 22 -14 9 -32 -4 -24 -17 4 -6 10 -5 16 3 8 12 9 12 6 0 -5
-20 -45 -24 -45 -6 0 8 4 15 8 15 4 0 10 7 13 16 6 15 4 15 -11 3 -9 -8 -14
-9 -11 -4 9 15 -1 27 -42 48 -27 15 -31 15 -15 2 20 -16 20 -16 -5 -13 -14 2
-30 7 -37 11 -6 4 -13 3 -15 -3 -2 -6 -9 -1 -15 10 -12 18 -11 19 17 13 17 -3
30 -2 30 3 0 5 -23 31 -49 57 -45 44 -50 46 -61 30 -10 -16 -10 -16 -5 3 4 14
0 24 -10 28 -9 3 -14 10 -11 15 6 9 -32 48 -43 44 -3 -2 -6 2 -6 7 0 6 -4 10
-9 9 -5 0 -13 5 -17 13 -5 8 -9 9 -9 2 0 -6 7 -17 15 -24 8 -7 15 -17 15 -22
0 -6 -6 -6 -14 1 -10 9 -15 8 -19 -3 -6 -15 29 -16 53 -1 9 6 11 4 5 -5 -6 -9
-4 -11 6 -5 8 5 11 4 6 0 -4 -5 3 -19 15 -32 14 -15 17 -23 8 -23 -9 0 -8 -6
5 -20 10 -11 23 -20 29 -20 6 0 5 4 -2 9 -11 7 -10 9 3 14 9 4 14 3 12 -1 -7
-10 19 -32 27 -24 4 3 4 -1 0 -11 -7 -19 -25 -24 -22 -5 2 7 -10 12 -27 13
-50 3 -55 5 -55 16 0 8 6 7 17 -2 16 -13 16 -12 1 16 -9 17 -19 38 -22 48 -3
9 -10 17 -16 17 -6 0 -9 -4 -6 -8 3 -5 -7 -9 -23 -9 -29 0 -37 20 -19 44 5 7
6 18 2 24 -11 19 -13 60 -2 53 5 -3 4 3 -4 15 -13 21 -25 25 -60 22 -21 -3
-20 -7 -22 96 -1 62 1 80 10 72 8 -7 13 -3 18 13 9 35 6 57 -4 33 -7 -16 -9
-10 -11 25 l-2 45 -6 -35 c-5 -26 -7 -15 -8 42 -1 61 2 75 13 71 8 -3 14 -9
14 -15 0 -5 -5 -6 -11 -2 -7 4 -10 -1 -7 -11 1 -10 9 -20 16 -23 7 -2 10 2 6
12 -3 9 -2 16 2 16 10 0 10 57 1 67 -4 3 -8 -5 -8 -18 -1 -19 -2 -20 -6 -5 -4
15 -7 16 -14 5 -7 -12 -9 -11 -9 1 0 26 21 40 42 29 14 -7 18 -6 18 5 0 8 -5
18 -12 22 -7 4 -8 3 -4 -4 4 -7 5 -12 1 -12 -3 0 -10 10 -15 23 -6 16 -9 17
-9 5 -1 -9 -5 -19 -10 -23 -6 -3 -9 20 -8 57 l2 63 38 3 c37 3 42 11 39 55 -1
10 3 28 9 39 8 15 8 19 0 14 -15 -10 -13 4 4 34 8 14 14 30 14 35 0 6 -6 -1
-14 -15 -13 -24 -14 -22 -12 33 0 31 4 57 9 57 4 0 8 12 7 28 0 24 -1 25 -9 7
-7 -16 -9 -11 -9 20 0 31 2 36 9 20 6 -13 9 -15 9 -5 1 8 13 26 26 39 14 12
22 27 19 32 -3 5 1 9 9 9 20 0 29 27 17 50 -13 25 -13 33 2 24 8 -5 8 -3 2 7
-11 17 -14 150 -3 143 4 -2 10 2 12 8 3 7 6 4 6 -8 1 -11 8 -29 16 -40 14 -18
16 -16 19 19 1 6 5 15 10 18 5 4 10 56 11 115 2 109 9 144 27 144 5 0 6 -4 3
-10 -3 -6 -1 -10 4 -10 6 0 11 5 11 10 0 6 4 10 10 10 5 0 7 -8 4 -17 -5 -16
-4 -16 6 0 7 11 12 34 12 53 0 19 4 34 9 34 6 0 5 8 -1 19 -13 25 -13 31 0 31
6 0 10 -8 11 -17 0 -15 2 -15 9 2 11 25 12 54 2 40 -10 -14 -32 -17 -32 -5 0
5 7 13 16 18 15 8 15 10 0 15 -10 4 -14 3 -11 -3 3 -5 -3 -8 -14 -7 -15 1 -17
4 -8 19 9 13 8 21 -2 30 -11 11 -13 11 -6 0 8 -15 -23 -55 -36 -47 -15 10 -22
-18 -22 -89 -1 -72 -1 -72 -28 -72 -15 1 -31 5 -36 10 -4 5 -3 6 3 3 7 -4 14
0 18 8 3 8 1 15 -4 15 -10 0 -24 -19 -27 -39 -1 -8 -8 -18 -15 -22 -7 -5 -8
-9 -3 -9 6 0 11 -29 12 -64 l1 -65 -29 1 c-20 1 -29 6 -29 17 0 14 2 14 10 1
7 -11 10 -11 10 -2 0 7 4 11 9 8 14 -9 22 23 11 44 -5 10 -7 26 -3 36 4 11 2
15 -4 11 -14 -9 -23 -57 -10 -54 5 1 12 -4 15 -12 3 -8 0 -11 -7 -7 -6 4 -11
3 -11 -3 0 -5 -9 -8 -19 -6 -11 2 -22 -3 -25 -11 -3 -8 -1 -12 4 -9 6 3 7 -1
4 -9 -3 -9 -11 -13 -18 -9 -6 4 -5 -1 3 -10 12 -15 12 -17 -1 -12 -9 4 -15 0
-15 -9 0 -8 5 -14 10 -13 5 1 11 -25 12 -58 2 -33 1 -49 -2 -35 -3 14 -9 30
-15 35 -7 7 -8 -1 -3 -24 5 -21 4 -31 -3 -27 -5 3 -12 23 -14 45 -3 35 -1 39
17 35 20 -3 20 -3 1 11 -26 20 -38 2 -30 -43 4 -21 2 -32 -6 -32 -7 0 -9 8 -5
23 6 21 6 21 -9 3 -12 -15 -13 -22 -4 -27 10 -7 10 -11 0 -21 -9 -9 -12 -9
-12 2 0 9 -6 6 -16 -8 -13 -18 -13 -26 -3 -38 6 -8 9 -18 5 -21 -4 -4 -11 -1
-16 6 -6 10 -12 5 -24 -20 -10 -19 -11 -27 -4 -19 7 8 22 16 33 18 16 3 15 -1
-9 -21 -17 -14 -26 -29 -22 -35 11 -18 36 -14 36 6 0 14 -2 15 -10 2 -5 -8
-12 -12 -15 -8 -4 4 4 16 18 28 21 19 24 28 20 63 -2 23 -1 38 4 34 10 -11 13
-118 3 -112 -4 3 -10 -5 -13 -16 -5 -17 -11 -20 -31 -17 -28 6 -28 5 -30 -63
-1 -36 -20 -69 -38 -69 -6 0 -5 7 2 15 7 9 10 18 6 22 -3 3 -6 1 -6 -5 0 -7
-4 -12 -10 -12 -16 0 -12 14 10 38 24 25 24 29 5 29 -8 0 -12 -4 -9 -9 3 -4 0
-8 -7 -8 -7 0 -9 -3 -6 -7 4 -3 0 -15 -10 -25 -9 -11 -11 -18 -5 -18 7 0 12
-6 12 -14 0 -24 20 -29 40 -11 18 16 19 16 17 -3 -1 -16 -8 -19 -35 -18 -18 1
-36 -2 -38 -6 -3 -4 -11 -5 -19 -1 -11 4 -13 1 -8 -13 5 -13 3 -15 -7 -9 -8 5
-11 4 -6 -3 3 -6 -1 -18 -10 -26 -8 -9 -12 -16 -8 -16 5 0 18 12 30 27 l21 27
0 -70 c-1 -60 -4 -70 -19 -71 -16 -2 -16 0 -3 20 7 12 13 37 13 55 l-1 33 -20
-30 c-21 -33 -33 -41 -23 -15 6 14 4 15 -9 4 -13 -11 -13 -15 -3 -22 10 -6 10
-10 -2 -18 -12 -8 -12 -10 -1 -10 11 0 11 -3 0 -16 -12 -14 -14 -14 -19 0 -4
12 -8 9 -17 -10 -10 -21 -9 -24 1 -14 11 8 15 5 20 -16 8 -30 4 -37 -24 -39
-23 -2 -27 10 -5 19 8 3 15 0 15 -6 0 -6 3 -9 6 -5 14 13 -7 27 -27 17 -14 -8
-18 -17 -13 -34 3 -14 0 -27 -7 -32 -9 -5 -10 -2 -6 10 4 10 3 15 -4 11 -5 -4
-7 -16 -3 -27 4 -15 3 -18 -6 -13 -9 5 -11 4 -6 -3 4 -7 2 -12 -5 -12 -7 0 -8
-4 -2 -12 13 -17 15 -68 3 -68 -5 0 -7 8 -4 18 5 13 4 14 -4 3 -5 -7 -14 -11
-19 -8 -5 3 -10 -11 -11 -31 -3 -40 -19 -65 -35 -55 -5 3 -7 1 -4 -4 4 -6 0
-16 -8 -23 -8 -7 -12 -16 -9 -21 3 -5 0 -9 -7 -9 -8 0 -9 -3 0 -13 6 -8 8 -16
5 -19 -3 -3 -1 -9 4 -12 6 -3 9 4 8 17 -2 12 1 32 5 42 4 12 7 -12 6 -56 -1
-51 -6 -76 -14 -76 -7 0 -10 4 -7 9 3 5 3 20 0 32 -7 29 -35 43 -34 18 0 -9
-3 -26 -7 -37 -5 -14 -4 -16 3 -7 7 11 10 11 15 -1 4 -12 0 -14 -19 -11 -22 4
-25 2 -19 -17 5 -16 4 -19 -5 -14 -9 6 -11 4 -4 -6 4 -8 9 -29 10 -47 0 -21
-2 -28 -7 -20 -6 9 -9 9 -9 1 0 -6 5 -15 10 -18 6 -3 10 -15 10 -26 0 -16 -6
-19 -36 -17 -43 3 -53 -10 -49 -71 3 -49 -10 -67 -35 -48 -8 6 -12 8 -8 3 4
-4 -2 -24 -13 -43 -18 -33 -19 -37 -5 -52 11 -10 16 -11 16 -3 0 7 -4 12 -10
12 -18 0 21 66 41 67 10 1 19 -2 19 -8 0 -8 -13 -12 -26 -6 -8 3 -27 -34 -20
-41 3 -3 13 3 20 14 8 10 18 15 22 11 5 -4 2 -12 -5 -16 -10 -7 -10 -13 -2
-29 14 -25 14 -43 1 -22 -7 11 -10 11 -10 2 0 -17 -41 -12 -58 8 -11 13 -12
11 -6 -9 4 -15 2 -21 -4 -17 -10 6 -26 -23 -17 -32 3 -3 5 1 6 9 0 8 5 1 9
-15 11 -37 4 -49 -20 -36 -16 8 -18 8 -14 -5 3 -8 10 -12 15 -9 5 3 9 0 9 -6
0 -6 4 -9 9 -5 6 3 9 -7 8 -22 -2 -17 -9 -29 -20 -31 -12 -3 -15 1 -11 13 6
13 9 14 16 3 8 -10 9 -10 5 2 -2 8 -10 14 -16 12 -6 -1 -11 2 -11 8 0 5 -5 10
-11 10 -8 0 -8 -4 1 -15 6 -8 9 -19 5 -26 -6 -8 -10 -8 -15 2 -10 16 -31 -56
-23 -78 5 -12 3 -14 -7 -8 -9 6 -11 4 -5 -6 5 -7 10 -25 11 -38 2 -19 1 -22
-6 -11 -5 8 -12 12 -15 8 -3 -3 1 -13 10 -21 14 -14 13 -16 -7 -14 -13 1 -29
7 -36 13 -10 10 -8 14 7 19 11 3 25 13 33 22 8 11 -5 7 -35 -11 -26 -15 -53
-25 -60 -22 -7 2 -4 5 6 5 22 1 22 27 1 35 -9 4 -26 4 -37 0 -13 -4 -17 -2
-13 5 7 10 -27 16 -63 12 -8 -2 -10 2 -5 11 6 10 4 10 -7 2 -23 -17 -82 -12
-85 8 -1 10 2 18 7 18 5 0 9 -4 9 -10 0 -5 7 -6 17 -3 12 5 14 3 8 -7 -5 -9
-4 -11 3 -6 17 10 15 26 -2 26 -8 0 -26 10 -39 22 -19 18 -36 23 -78 23 -36
-1 -58 4 -67 15 -12 13 -13 13 -8 -3 3 -10 2 -15 -4 -12 -5 3 -8 16 -6 28 3
12 5 24 5 26 1 3 8 -4 16 -15 13 -16 14 -16 9 -1 -5 13 -2 16 12 11 18 -6 17
-5 -1 9 -10 8 -25 13 -32 10 -7 -3 -13 -1 -13 5 0 17 -53 63 -67 58 -8 -3 -11
-1 -8 4 4 6 -3 14 -14 18 -11 4 -20 10 -20 15 -1 4 -3 7 -6 7 -3 0 -10 0 -16
0 -5 0 -7 -4 -4 -10 3 -6 -1 -7 -10 -4 -14 6 -14 8 2 19 10 8 14 17 10 21 -4
5 -13 2 -18 -6 -9 -13 -10 -13 -6 0 6 20 -11 52 -24 44 -6 -3 -13 3 -16 15 -5
20 -15 26 -37 22 -5 -1 -6 6 -4 16 3 10 -1 24 -9 31 -7 7 -13 9 -13 5 0 -5
-13 -10 -29 -11 -28 -2 -29 -1 -27 36 1 21 6 45 11 53 7 11 6 12 -6 4 -8 -6
-30 -10 -49 -10 l-35 0 -1 73 c-1 39 2 75 7 78 5 3 9 15 8 27 0 21 -1 21 -9 2
-8 -19 -9 -19 -9 3 -1 14 5 22 17 22 12 0 13 3 5 8 -10 7 -10 14 0 35 6 15 18
27 25 27 6 0 12 5 12 10 0 6 -4 10 -10 10 -5 0 -10 5 -10 11 0 6 7 9 15 6 8
-4 16 -3 19 1 3 5 6 80 7 167 2 88 7 166 12 173 6 8 6 12 -2 12 -6 0 -11 9
-11 20 0 11 5 20 10 20 7 0 7 7 0 21 -7 11 -9 23 -7 26 3 2 8 -4 11 -15 4 -11
13 -23 21 -26 19 -7 19 8 0 24 -8 7 -15 21 -15 32 0 10 -4 16 -10 13 -5 -3
-10 -2 -10 4 0 16 40 8 40 -9 1 -8 8 -26 16 -40 l15 -25 -5 27 c-3 14 -9 34
-12 45 -5 17 -5 17 9 0 13 -16 15 -15 16 10 1 16 2 42 2 58 0 21 2 26 8 16 7
-10 9 -7 8 12 -1 14 -5 23 -9 20 -5 -2 -8 1 -8 6 0 6 7 11 15 11 8 0 15 5 15
10 0 6 -5 9 -11 8 -7 -2 -13 14 -14 39 -3 39 -2 42 16 33 10 -6 19 -18 19 -28
0 -14 -2 -15 -10 -2 -5 8 -11 12 -14 10 -2 -3 2 -13 9 -23 12 -16 14 -15 23
13 10 28 8 34 -10 31 -5 0 -8 6 -8 14 0 9 -6 12 -16 8 -13 -5 -15 2 -12 38 3
43 3 44 41 46 30 1 37 -2 33 -14 -3 -8 -1 -11 4 -8 6 3 7 17 4 30 -3 13 0 37
8 52 15 30 13 57 -3 47 -5 -3 -9 -1 -9 4 0 17 13 21 33 11 14 -8 18 -8 12 0
-4 7 -1 19 7 28 7 9 8 14 2 10 -7 -4 -15 0 -18 9 -8 21 2 33 13 15 10 -16 37
52 32 82 -1 9 2 17 7 17 15 0 22 42 11 59 -8 13 -9 12 -5 -4 3 -11 1 -27 -5
-35 -9 -13 -10 -13 -6 2 7 19 -18 56 -29 45 -5 -4 -2 -12 6 -17 13 -8 12 -10
-2 -10 -21 0 -22 6 -7 48 6 18 16 29 21 26 6 -4 8 -1 6 7 -3 8 -11 13 -17 11
-7 -1 -10 2 -6 7 3 6 12 8 20 4 18 -6 19 1 5 28 -8 13 -7 19 0 19 6 0 8 5 4
11 -9 16 -24 -10 -16 -29 4 -11 1 -13 -8 -7 -9 5 -11 4 -6 -3 4 -7 2 -12 -4
-12 -7 0 -9 -7 -6 -16 10 -25 -3 -56 -20 -50 -8 3 -13 2 -10 -3 3 -5 -1 -11
-9 -15 -9 -3 -12 -12 -8 -22 5 -13 3 -15 -8 -9 -11 6 -12 5 -1 -6 6 -7 12 -28
12 -46 0 -23 4 -31 14 -27 7 3 21 -2 30 -11 24 -25 19 -39 -6 -15 -16 15 -24
17 -30 8 -4 -7 -8 -9 -8 -5 0 5 -5 5 -11 1 -8 -4 -8 -9 1 -18 8 -8 13 -7 20 4
5 8 12 11 17 7 4 -5 1 -12 -7 -18 -10 -5 -11 -12 -5 -16 17 -13 18 -43 1 -37
-9 3 -12 12 -9 21 4 11 2 14 -5 9 -7 -4 -12 -1 -12 9 0 8 -6 18 -14 21 -22 9
-29 35 -8 32 13 -2 17 5 19 35 1 20 -2 37 -7 37 -5 0 -6 -17 -4 -37 4 -32 3
-35 -8 -23 -7 8 -12 13 -11 11 2 -2 -2 -10 -9 -18 -8 -10 -8 -17 0 -25 6 -6 8
-17 5 -24 -4 -10 0 -12 11 -8 9 3 16 1 16 -5 0 -6 -11 -10 -25 -9 -20 2 -24 6
-19 23 3 11 -5 4 -17 -15 -13 -19 -22 -41 -20 -49 1 -7 -2 -10 -7 -7 -22 13
-19 -24 4 -58 14 -20 23 -42 20 -49 -2 -7 -5 -3 -6 8 -1 18 -1 19 -11 2 -7
-12 -18 -16 -39 -12 -16 3 -32 0 -36 -7 -4 -6 -3 -9 2 -5 5 3 9 -30 10 -76 0
-45 -1 -67 -3 -49 -3 18 -7 30 -11 28 -4 -2 -7 6 -7 19 0 13 3 21 8 18 9 -5 8
6 -3 32 -8 20 -9 19 -9 -1 -1 -12 -8 -27 -16 -34 -14 -12 -14 -15 3 -34 24
-28 22 -50 -5 -54 -18 -3 -21 0 -17 13 6 13 8 14 15 3 10 -15 12 -7 3 16 -5
12 -9 13 -19 3 -7 -7 -11 -15 -8 -19 2 -5 -1 -8 -8 -8 -7 0 -10 -3 -6 -6 3 -4
-1 -13 -9 -21 -8 -8 -11 -19 -8 -25 4 -6 10 -5 15 3 6 10 9 5 9 -15 l0 -29
-22 19 c-13 10 -18 12 -12 4 7 -8 10 -26 9 -40 -2 -19 -7 -24 -24 -22 -21 2
-22 1 -5 -15 14 -14 18 -15 22 -4 4 11 6 11 13 0 6 -9 7 -2 5 19 -2 22 0 30 6
24 5 -5 8 -20 6 -33 -2 -19 -10 -24 -33 -25 -53 -3 -55 -4 -48 -21 5 -12 3
-14 -8 -8 -12 7 -12 6 -1 -7 11 -14 11 -21 -3 -42 -10 -14 -21 -24 -25 -21 -4
2 -12 -7 -18 -20 -8 -18 -8 -24 0 -22 6 1 13 -4 15 -11 3 -7 -1 -10 -10 -7 -8
4 -21 -5 -32 -21 -9 -14 -13 -24 -8 -21 5 3 9 -24 9 -59 0 -42 -3 -60 -8 -49
-5 8 -6 20 -2 27 5 7 3 8 -5 4 -7 -5 -10 -14 -7 -22 4 -10 -4 -16 -24 -21 -23
-4 -30 -11 -30 -29 0 -13 -5 -24 -11 -24 -6 0 -7 -6 -2 -12 9 -16 11 -88 2
-82 -8 4 -12 -8 -23 -61 -4 -18 -3 -25 2 -17 7 10 11 10 21 -1 10 -12 5 -14
-34 -12 l-45 2 5 -75 c5 -64 4 -74 -10 -69 -8 4 -15 2 -15 -3 0 -5 8 -11 18
-14 9 -2 4 -4 -13 -3 -16 1 -35 5 -41 9 -16 12 -20 -12 -19 -117 1 -70 -3 -93
-15 -102 -23 -19 -35 -15 -18 5 9 9 16 33 16 52 0 19 3 47 6 62 3 15 2 30 -4
33 -10 6 -14 -14 -11 -44 2 -7 -2 -11 -6 -8 -5 3 -10 -3 -10 -14 -1 -11 2 -17
8 -14 19 13 8 -7 -18 -30 -16 -13 -22 -25 -15 -25 7 0 15 7 18 15 4 8 10 12
16 9 5 -3 0 -17 -13 -30 -20 -22 -21 -24 -4 -30 10 -4 27 -2 37 5 16 10 19 10
16 -1 -3 -7 -23 -14 -47 -16 -33 -3 -41 0 -36 10 7 11 5 11 -6 0 -11 -10 -11
-15 -2 -20 19 -12 12 -68 -9 -68 -14 0 -14 3 -2 20 16 24 18 36 4 36 -6 0 -9
-3 -8 -7 1 -5 -3 -18 -10 -30 -7 -13 -10 -23 -6 -23 3 0 0 -8 -7 -17 -12 -14
-12 -15 0 -9 10 7 11 3 6 -15 -7 -22 -6 -22 9 -10 9 8 15 20 12 28 -3 7 0 13
7 13 7 0 12 -14 12 -32 0 -26 -4 -33 -20 -33 -11 -1 -26 8 -33 20 -7 11 -11
13 -9 5 3 -9 -1 -23 -7 -32 -7 -10 -12 -42 -11 -72 1 -31 -1 -54 -4 -52 -9 6
-35 -17 -39 -32 -4 -21 15 -13 21 9 10 37 25 10 24 -40 -2 -40 -5 -49 -19 -48
-21 1 -21 2 -8 28 7 13 7 19 1 19 -6 0 -13 -11 -17 -24 -4 -16 -11 -23 -20
-19 -16 6 -15 6 -17 -92 l-1 -70 -97 -2 c-75 -2 -98 1 -102 12 -7 18 23 29 37
14 8 -9 8 -10 -1 -5 -7 4 -13 2 -13 -3 0 -6 7 -11 15 -11 31 0 12 26 -43 60
-31 19 -61 35 -65 35 -4 0 -12 7 -17 15 -5 8 -12 11 -17 7 -4 -5 -1 -12 6 -16
15 -10 6 -31 -12 -30 -8 0 -8 2 1 6 10 4 10 8 1 19 -9 11 -8 18 2 31 7 10 10
15 6 12 -5 -4 -24 8 -42 27 -33 32 -53 45 -40 25 3 -5 1 -12 -4 -16 -6 -3 -8
4 -6 16 3 14 -4 31 -19 46 -22 21 -25 22 -39 8 -19 -19 -24 -19 -24 0 0 8 4
12 9 9 10 -6 11 10 1 20 -5 4 -9 12 -9 17 -1 5 -2 13 -3 18 0 4 -19 7 -41 6
-42 -3 -48 2 -27 24 6 8 7 11 2 8 -6 -3 -11 3 -11 13 0 14 3 16 13 8 7 -6 13
-19 13 -29 0 -12 5 -16 15 -13 11 5 12 9 2 19 -28 29 -51 60 -48 65 1 2 -3 6
-10 9 -6 2 -9 -1 -5 -7 7 -12 -12 -28 -21 -18 -4 3 2 16 13 28 10 12 13 18 7
14 -7 -4 -13 -2 -13 3 0 6 -4 11 -9 11 -5 0 -9 -6 -8 -12 1 -34 -4 -49 -15
-46 -9 3 -13 26 -13 66 -1 34 3 56 7 49 15 -23 8 -1 -12 41 -11 23 -25 42 -30
42 -5 0 -6 8 -3 18 6 15 5 15 -11 3 -13 -11 -14 -16 -4 -22 7 -4 9 -11 6 -15
-4 -3 -13 0 -20 7 -16 17 -19 130 -3 121 6 -3 5 1 -2 9 -7 9 -11 48 -11 103 1
51 5 81 9 70 6 -16 8 -15 8 8 1 14 -5 29 -11 31 -9 4 -9 8 -3 14 6 4 11 13 11
18 1 6 4 26 8 45 4 25 3 31 -4 20 -5 -8 -10 -12 -10 -8 0 3 -3 14 -7 24 -5 13
-3 16 10 11 21 -8 22 9 2 29 -20 20 -19 89 1 124 13 22 14 33 6 56 -11 28 -11
27 -6 -8 4 -22 2 -38 -3 -38 -6 0 -10 32 -9 70 0 70 15 100 17 38 2 -32 2 -32
6 -5 3 16 0 34 -5 40 -5 7 -12 26 -15 42 l-7 30 17 -32 c9 -18 19 -33 23 -33
3 0 4 -16 2 -36 -3 -27 -1 -35 8 -29 7 4 9 17 5 32 -4 19 -3 23 7 17 11 -6 11
-5 0 8 -14 17 -7 68 11 79 5 4 11 20 14 35 5 38 5 38 -2 122 -4 44 -2 72 4 72
5 0 13 15 17 33 4 17 9 26 11 19 5 -12 23 -7 23 6 0 21 -38 8 -48 -16 l-12
-27 6 30 c3 17 13 36 22 44 15 12 15 13 0 8 -11 -4 -18 1 -22 16 -3 12 -1 24
5 26 7 2 6 6 -2 12 -7 4 -10 11 -6 15 10 11 36 -16 30 -32 -3 -8 0 -14 5 -14
6 0 12 -6 15 -12 2 -7 3 -3 1 9 -2 11 -7 29 -11 39 -4 11 -3 15 4 11 16 -10
45 22 38 41 -3 8 0 21 6 28 9 11 8 14 0 14 -8 0 -7 5 3 15 8 9 13 27 11 43 -2
15 -4 31 -4 35 -1 5 -4 6 -8 4 -5 -3 -8 25 -9 61 -1 44 2 58 7 42 7 -24 7 -24
8 6 1 27 4 31 27 28 27 -3 35 9 37 53 1 16 6 31 11 34 5 4 5 13 0 20 -13 19
-12 129 1 130 5 1 19 0 30 -1 20 -2 20 -1 1 13 -13 9 -17 21 -13 36 3 11 1 21
-4 21 -9 0 -13 -22 -10 -47 1 -7 -3 -13 -8 -13 -6 0 -10 17 -8 39 1 28 6 38
17 35 8 -1 18 -3 22 -3 4 -1 5 -16 1 -33 -6 -30 -5 -32 10 -20 9 8 13 20 10
29 -3 8 -1 18 5 22 5 3 12 20 15 38 3 17 8 37 13 44 4 7 3 10 -2 6 -6 -3 -10
12 -10 34 0 22 3 35 7 28 11 -19 30 1 28 29 -1 15 -1 38 0 52 l3 25 9 -25 c7
-20 7 -17 3 13 -6 43 3 67 18 46 5 -9 6 -4 2 15 -5 19 -3 27 3 23 6 -4 13 2
16 13 3 11 2 18 -2 15 -5 -2 -8 28 -8 68 0 67 1 73 21 74 11 1 16 -1 10 -3
-14 -6 -6 -44 8 -44 5 0 6 10 3 21 -6 16 -4 20 6 14 11 -6 11 -5 2 5 -10 11
-9 16 3 25 14 10 14 13 -2 26 -15 12 -16 12 -9 0 6 -11 4 -13 -8 -8 -10 4 -20
0 -26 -10 -5 -9 -15 -18 -22 -19 -63 -13 -76 -17 -90 -30 -8 -8 -19 -12 -22
-9 -4 3 -4 1 0 -4 5 -5 17 -11 28 -14 16 -4 15 -5 -6 -6 -14 0 -30 4 -36 10
-8 8 -23 4 -54 -12 -24 -13 -49 -24 -56 -25 -7 -1 -26 -9 -44 -18 -17 -9 -35
-14 -39 -11 -3 2 -7 -2 -7 -10 0 -19 6 -19 45 5 39 24 52 25 52 5 0 -8 -10
-17 -22 -19 -55 -12 -60 -15 -19 -11 25 2 40 0 34 -6 -15 -15 -56 -16 -82 -2
-35 17 -53 16 -21 -2 24 -14 24 -14 3 -15 -12 0 -25 5 -28 10 -3 6 -11 10 -16
10 -6 0 -8 -4 -5 -8 8 -13 -27 -11 -43 2 -12 9 -13 8 -6 -3 6 -11 3 -13 -13
-9 -27 7 -28 0 -27 -160 1 -72 -2 -127 -6 -120 -5 7 -6 24 -3 38 4 19 1 17
-10 -9 -9 -18 -13 -38 -10 -43 4 -6 0 -14 -7 -19 -10 -6 -11 -9 -1 -9 6 0 12
-9 12 -19 0 -11 5 -23 10 -26 14 -8 13 28 -1 42 -8 8 -7 13 2 18 8 5 10 4 5
-3 -4 -7 -2 -12 3 -12 14 0 10 -102 -4 -112 -5 -4 -11 -12 -11 -18 -4 -31 -6
-33 -14 -15 l-9 20 -1 -20 -1 -20 -8 20 c-14 35 -28 -40 -25 -135 2 -64 0 -78
-7 -55 -5 17 -7 41 -4 55 4 21 4 22 -5 8 -8 -12 -8 -35 -1 -70 5 -29 7 -57 4
-62 -4 -5 -1 -6 6 -2 9 6 11 -4 9 -34 -2 -38 -4 -41 -27 -37 -41 8 -51 -2 -51
-52 0 -29 -3 -41 -9 -33 -6 11 -9 9 -13 -6 -4 -14 -2 -19 7 -16 10 4 13 -15
12 -83 0 -53 -4 -78 -8 -63 l-7 25 -1 -27 c-1 -16 4 -28 10 -28 24 0 2 -35
-24 -37 -27 -2 -38 12 -14 20 6 2 9 7 5 11 -8 10 -48 -11 -48 -25 0 -6 6 -7
13 -4 6 4 4 0 -5 -8 -10 -8 -18 -21 -18 -30 0 -9 -5 -19 -11 -23 -7 -4 -7 -10
-1 -16 7 -7 4 -17 -9 -31 -20 -22 -26 -43 -9 -32 5 3 15 -5 21 -19 15 -32 1
-51 -42 -60 -17 -3 -28 -10 -24 -15 3 -6 13 -8 23 -5 12 4 13 3 4 -3 -7 -5
-14 -23 -15 -40 -1 -17 -5 -29 -9 -26 -8 4 -12 -32 -9 -73 2 -19 -2 -22 -34
-18 -33 3 -35 1 -29 -18 5 -16 3 -19 -7 -12 -11 6 -12 5 -2 -7 13 -16 10 -116
-3 -127 -4 -4 -5 0 -1 10 3 9 -2 23 -14 34 -18 16 -19 16 -19 -7 0 -19 2 -21
10 -9 8 12 10 10 10 -9 0 -16 -4 -21 -15 -17 -26 10 -41 -19 -40 -77 1 -31 -2
-54 -6 -52 -4 3 -13 -3 -20 -12 -12 -15 -12 -16 3 -10 10 3 20 2 22 -2 3 -5
-12 -8 -32 -7 -31 2 -41 -3 -49 -21 -6 -13 -8 -21 -4 -19 3 2 7 -24 7 -58 0
-44 -3 -63 -12 -63 -9 0 -10 5 -3 19 11 21 3 42 -13 32 -6 -4 -8 -1 -6 7 3 8
10 13 15 12 5 -1 10 7 10 18 1 10 -3 19 -9 19 -6 0 -9 -3 -5 -6 3 -4 -1 -13
-9 -21 -16 -17 -13 -39 5 -36 6 2 11 -1 11 -5 0 -10 -40 -48 -46 -44 -11 6
-24 1 -19 -8 3 -5 1 -10 -6 -10 -8 0 -9 -3 0 -13 8 -11 6 -18 -10 -33 -22 -19
-22 -19 -10 6 l12 25 -19 -24 c-23 -28 -19 -59 4 -40 12 10 13 8 8 -7 -5 -13
-4 -16 4 -9 5 6 12 19 15 30 3 11 5 6 4 -12 -1 -17 -5 -29 -9 -27 -4 3 -8 -3
-8 -14 0 -14 -7 -18 -30 -18 -24 0 -27 2 -18 14 8 9 14 10 16 3 2 -6 8 -9 13
-5 11 6 12 24 2 23 -21 -3 -33 2 -27 13 4 7 3 8 -4 4 -6 -4 -8 -14 -5 -23 4
-10 2 -14 -4 -10 -6 4 -9 -13 -8 -49 1 -36 -2 -51 -8 -45 -7 7 -17 6 -30 0
-14 -8 -18 -8 -13 0 12 19 0 12 -25 -16 -13 -14 -18 -23 -11 -19 6 4 12 2 12
-3 0 -6 -6 -11 -14 -11 -14 0 -73 -70 -69 -82 2 -4 -9 -16 -24 -27 -14 -12
-23 -21 -20 -21 3 0 13 -3 23 -7 13 -5 16 -3 11 10 -4 12 0 18 17 23 13 3 30
20 40 41 23 49 30 36 8 -15 -13 -31 -21 -40 -34 -36 -13 4 -18 0 -19 -13 -1
-10 -2 -22 -4 -28 -1 -5 -3 -17 -3 -25 -2 -17 -38 -24 -47 -9 -4 5 -1 10 4 10
6 1 16 2 23 3 6 0 13 8 15 16 4 13 3 13 -6 0 -10 -14 -15 -8 -12 15 1 6 -7 0
-18 -13 -11 -12 -24 -22 -29 -22 -5 0 -12 -7 -16 -15 -3 -8 -11 -15 -19 -15
-26 0 -53 -53 -51 -102 1 -34 -2 -48 -11 -48 -21 0 -28 19 -11 26 23 9 20 33
-3 31 -14 -1 -17 -5 -10 -13 7 -8 6 -14 -1 -19 -6 -3 -8 -11 -5 -16 4 -5 1 -9
-4 -9 -6 0 -11 5 -11 12 0 6 -6 3 -14 -7 -11 -15 -17 -17 -27 -8 -11 9 -20 6
-41 -12 -18 -16 -23 -25 -15 -29 7 -4 2 -4 -10 -2 -15 3 -20 0 -16 -10 4 -10
10 -11 24 -3 11 5 25 7 32 4 6 -4 4 0 -6 8 -10 9 -14 18 -8 21 13 8 43 -23 35
-36 -4 -7 -2 -8 4 -4 9 5 9 13 1 27 -6 12 -8 23 -5 27 3 3 6 0 6 -7 0 -7 3
-10 8 -8 4 3 7 -8 7 -23 1 -25 -3 -29 -26 -27 -15 1 -25 6 -21 11 3 6 -3 6
-14 2 -30 -12 -64 -14 -58 -4 3 5 -3 4 -15 -4 -19 -11 -23 -22 -20 -44 0 -4
-5 -11 -13 -15 -10 -7 -10 -9 0 -9 6 0 12 -3 12 -7 0 -5 -83 -8 -185 -9 -102
0 -180 3 -173 8 9 5 10 8 1 8 -6 0 -14 -4 -17 -9 -6 -9 -118 -13 -158 -5 -29
6 -32 22 -9 40 8 6 10 13 4 17 -19 12 -93 34 -93 28 0 -4 -18 -6 -39 -6 -22 0
-37 3 -35 7 7 11 -1 10 -28 -3 -16 -7 -31 -7 -48 1 -22 9 -21 10 10 4 l35 -5
-25 20 c-18 14 -27 16 -32 8 -5 -7 -7 -3 -5 10 4 28 17 43 17 20 0 -10 4 -17
9 -17 6 0 9 9 8 19 0 11 -6 19 -12 18 -5 -1 -9 4 -7 11 1 8 -2 11 -8 7 -5 -3
-13 1 -16 9 -3 9 -10 14 -14 11 -5 -3 -12 -1 -15 5 -5 8 -11 7 -21 -1 -11 -9
-15 -6 -20 21 -4 17 -14 34 -23 38 -9 3 -25 16 -36 29 -11 13 -26 23 -33 23
-8 0 -10 5 -6 12 4 7 3 8 -5 4 -6 -4 -24 -9 -39 -11 -15 -2 -21 -2 -14 1 10 5
10 9 1 20 -9 12 -9 18 0 27 10 9 11 8 6 -6 -7 -17 5 -24 18 -10 9 8 -13 34
-23 28 -5 -3 -11 4 -15 15 -3 11 -11 20 -16 20 -6 0 -7 -4 -4 -10 3 -6 -3 -10
-14 -10 -12 0 -21 6 -21 13 0 8 -3 7 -10 -3 -7 -11 -8 -8 -6 13 1 15 7 26 12
25 5 -2 8 -6 7 -10 -2 -4 4 -5 11 -2 11 4 7 16 -17 50 -24 34 -33 41 -44 33
-16 -14 -17 -29 -2 -29 5 0 7 5 3 11 -4 8 0 8 15 0 11 -7 21 -17 21 -24 0 -9
-2 -9 -8 -1 -4 7 -19 10 -34 9 -33 -4 -35 4 -7 39 18 23 18 27 5 32 -9 3 -13
11 -9 17 3 6 1 7 -6 3 -6 -4 -9 -12 -6 -17 4 -5 1 -9 -6 -9 -8 0 -8 -4 -1 -13
7 -8 7 -19 -1 -34 -10 -18 -12 -12 -13 42 0 35 4 61 9 58 5 -3 3 4 -5 16 -13
21 -20 23 -65 24 -23 0 -30 11 -14 21 5 4 12 0 15 -6 4 -9 5 -8 5 2 -2 31 1
41 13 34 7 -5 8 -3 4 5 -6 9 -12 9 -25 -3 -16 -15 -17 -11 -16 50 0 35 -3 70
-7 77 -4 7 -8 8 -8 2 0 -5 -7 1 -17 14 -15 22 -15 25 1 36 15 12 15 14 1 28
-8 9 -15 26 -15 39 0 18 2 20 10 8 6 -9 10 -10 10 -3 0 17 -14 23 -30 13 -12
-8 -12 -14 3 -44 9 -20 13 -36 8 -36 -20 0 -15 -36 8 -58 19 -17 20 -22 7 -22
-9 0 -16 6 -16 13 0 8 -4 7 -10 -3 -8 -13 -10 -12 -10 3 0 9 -4 17 -8 17 -4 0
-7 12 -6 28 1 15 7 26 14 24 6 -1 10 3 7 10 -2 7 -9 12 -14 11 -6 -1 -9 34 -8
93 2 85 -2 117 -9 67 -2 -12 -12 -18 -29 -19 -19 -1 -24 3 -20 15 3 11 0 10
-11 -4 -8 -11 -20 -26 -26 -33 -8 -9 -7 -12 2 -12 7 0 0 -12 -17 -26 -16 -14
-25 -23 -19 -20 18 9 24 -21 11 -51 -8 -18 -20 -28 -34 -29 -27 -1 -27 0 -7
31 9 14 12 25 8 25 -5 0 -15 -12 -21 -27 -7 -16 -26 -43 -42 -60 -16 -18 -25
-35 -20 -38 5 -4 9 -1 9 5 0 6 5 8 11 4 8 -4 7 -9 -2 -15 -7 -4 -17 -5 -22 -2
-11 7 -48 -27 -41 -38 3 -5 -2 -12 -11 -15 -13 -6 -14 -8 -3 -15 18 -12 31
-11 23 1 -9 15 1 12 14 -4 10 -11 5 -13 -24 -11 -40 2 -56 -12 -50 -43 3 -16
-1 -22 -15 -22 -18 0 -84 -61 -86 -81 -1 -5 -2 -14 -3 -20 0 -5 -6 -7 -12 -4
-6 4 -13 -2 -16 -14 -3 -11 -9 -19 -13 -16 -10 6 -93 -90 -96 -111 -1 -8 2
-12 7 -8 5 3 7 9 4 13 -2 5 4 16 15 26 21 19 28 7 8 -13 -8 -8 -8 -12 -1 -12
15 0 24 26 16 46 -5 14 -3 14 16 3 11 -8 21 -20 21 -27 0 -7 -4 -10 -10 -7
-13 8 -13 -8 0 -21 7 -7 -2 -11 -27 -12 -21 -1 -48 -2 -60 -3 -14 -1 -43 -23
-74 -58 -29 -31 -65 -64 -81 -74 -16 -9 -26 -22 -23 -27 3 -6 1 -10 -5 -10
-18 0 -70 -43 -70 -57 0 -8 -4 -12 -9 -9 -4 3 -17 -4 -27 -17 l-19 -22 23 19
c12 11 22 14 22 8 0 -6 -5 -14 -10 -17 -6 -3 -10 -11 -10 -16 0 -13 25 11 33
31 4 12 6 12 6 -2 1 -9 -3 -20 -9 -23 -13 -8 -13 -25 -1 -25 5 0 13 9 16 21 4
11 9 18 11 16 3 -2 0 -16 -7 -29 -7 -17 -19 -26 -36 -26 -18 0 -21 3 -12 9 11
7 10 9 -3 9 -9 0 -20 -4 -23 -10 -7 -11 -41 -8 -52 5 -5 4 -2 5 6 0 10 -6 12
-4 6 5 -6 10 -11 10 -20 -3 -7 -9 -15 -14 -19 -10 -4 4 -4 -1 0 -11 6 -15 4
-17 -7 -11 -11 7 -12 6 -2 -6 7 -8 11 -24 11 -34 -2 -17 -9 -20 -47 -19 -63 1
-65 2 -50 22 12 15 12 16 -2 5 -9 -7 -20 -9 -24 -5 -5 4 -6 0 -3 -9 5 -14 -8
-16 -107 -15 -65 0 -111 4 -108 9 3 5 -4 4 -15 -2 -26 -14 -69 -14 -77 -1 -3
5 -1 10 6 10 7 0 10 2 7 5 -2 3 -27 -2 -54 -10 -73 -22 -85 -20 -82 14 1 21 6
27 17 23 10 -4 11 -7 3 -10 -21 -7 -13 -32 11 -31 20 0 20 1 3 8 -19 7 -19 8
0 15 19 7 19 8 0 22 -11 8 -28 12 -38 8 -11 -3 -20 0 -23 9 -5 10 -27 13 -99
12 -77 -2 -93 1 -89 12 3 9 12 11 22 7 28 -12 88 -11 88 2 0 6 -9 9 -21 7 -17
-4 -18 -3 -6 6 10 7 11 11 3 11 -7 0 -16 0 -20 1 -5 0 -19 10 -32 22 l-24 22
12 -22 c14 -27 14 -33 1 -33 -5 0 -10 7 -10 15 0 8 -5 15 -11 15 -6 0 -8 -8
-4 -19 3 -11 0 -21 -7 -24 -9 -3 -13 6 -13 29 0 18 2 32 5 32 15 -1 21 3 15
12 -4 6 -19 4 -42 -5 -41 -17 -78 -21 -53 -6 8 6 18 7 22 5 4 -3 5 2 2 11 -5
11 -2 14 12 9 14 -4 15 -3 6 4 -8 5 -13 12 -12 16 0 4 -17 21 -39 37 -23 16
-41 26 -41 23 0 -4 -8 0 -17 7 -10 8 -14 9 -10 2 4 -6 4 -14 -1 -17 -4 -2 -9
7 -10 21 -3 36 -10 44 -38 39 -13 -3 -24 0 -24 5 0 5 5 9 11 9 5 0 7 5 3 12
-6 9 -9 9 -12 0 -2 -7 -8 -10 -13 -7 -5 4 -6 -1 -3 -9 3 -9 1 -16 -5 -16 -6 0
-11 7 -11 16 0 9 -8 23 -17 31 -17 13 -16 13 6 7 l24 -6 -28 36 c-23 31 -30
34 -43 24 -10 -9 -13 -9 -9 -1 8 13 -10 69 -20 59 -3 -3 -11 4 -18 17 -6 12
-18 21 -26 20 -9 -2 -15 7 -16 23 0 14 -7 31 -13 38 -10 8 -12 7 -7 -5 7 -20
-22 -22 -44 -2 -18 15 -24 78 -7 67 6 -4 8 -3 5 3 -12 19 -30 68 -29 75 1 5
-3 8 -9 8 -5 0 -7 5 -4 10 3 6 2 10 -4 10 -5 0 -13 -5 -16 -10 -3 -6 -1 -10 5
-10 6 0 8 -5 4 -12 -4 -6 0 -16 8 -22 8 -6 17 -18 20 -28 10 -26 -27 -29 -51
-4 -17 18 -19 33 -17 113 3 94 -3 121 -24 108 -7 -4 -16 -2 -21 6 -12 19 -11
49 1 49 6 0 10 -8 8 -17 -2 -11 5 -19 17 -21 17 -4 18 -2 6 11 -7 9 -10 21 -6
27 3 5 -1 10 -9 10 -9 0 -16 8 -16 18 0 13 -2 14 -9 3 -5 -8 -8 1 -7 27 1 40
16 49 16 10 0 -12 3 -18 7 -14 13 12 10 30 -7 44 -13 12 -13 13 2 8 12 -3 15
-1 11 10 -3 9 -10 11 -17 6 -21 -17 -43 -20 -43 -7 0 8 7 11 16 8 13 -5 14 -3
4 7 -10 11 -15 11 -27 0 -9 -7 -14 -16 -11 -20 3 -4 -1 -10 -8 -13 -15 -5 -21
-53 -8 -62 5 -3 7 5 4 17 l-4 23 13 -23 c11 -17 16 -20 26 -10 9 9 12 9 12 -3
1 -7 5 -33 9 -56 6 -31 5 -43 -4 -43 -7 0 -10 11 -8 28 4 34 -7 51 -37 57 -13
2 -27 10 -32 17 -7 12 -12 4 -9 -14 0 -5 -4 -8 -11 -8 -6 0 -9 -3 -6 -6 3 -3
-13 -24 -36 -47 -42 -42 -42 -43 -42 -112 l1 -71 -40 1 c-39 0 -40 -1 -39 -32
0 -18 -3 -30 -8 -27 -4 3 -8 -1 -9 -8 0 -7 -7 1 -15 17 l-15 30 5 -32 c4 -24
2 -33 -9 -33 -7 0 -17 4 -20 10 -4 6 1 7 12 3 14 -5 15 -3 6 7 -12 13 -37 5
-28 -10 3 -4 -11 -20 -30 -34 -21 -17 -32 -32 -29 -41 4 -9 0 -15 -9 -15 -8 0
-18 -7 -21 -16 -3 -8 -2 -13 2 -10 4 2 7 -9 7 -24 1 -25 -2 -28 -30 -27 -26 1
-41 -8 -81 -49 -41 -42 -49 -57 -48 -84 1 -18 -1 -29 -5 -24 -19 27 -69 8 -75
-28 -3 -23 -17 -39 -28 -32 -7 4 -8 2 -4 -5 6 -9 11 -9 22 0 11 9 14 8 14 -3
0 -9 -10 -14 -29 -13 -17 0 -27 4 -23 10 3 5 -4 1 -17 -9 -12 -11 -19 -26 -16
-34 4 -12 2 -12 -8 -3 -11 9 -17 8 -27 -4 -8 -9 -9 -15 -4 -14 23 4 41 -2 41
-14 0 -14 -31 -16 -43 -4 -4 4 -14 1 -22 -6 -13 -10 -14 -9 -7 3 8 13 7 13 -8
1 -17 -13 -25 -37 -9 -27 4 2 8 -11 8 -29 0 -33 -1 -34 -36 -31 -20 2 -42 6
-48 10 -9 5 -9 0 -2 -19 13 -34 13 -35 -5 -29 -8 4 -12 11 -9 16 4 6 -2 7 -16
3 -17 -6 -21 -4 -15 6 6 9 2 8 -11 -2 -10 -9 -27 -19 -36 -22 -10 -4 -18 -12
-18 -19 0 -9 3 -9 12 0 17 17 24 15 25 -6 1 -15 -7 -17 -57 -17 -33 0 -56 4
-53 9 4 5 0 6 -7 1 -8 -5 -59 -9 -113 -10 -57 -1 -93 1 -85 6 10 7 9 9 -4 9
-9 0 -25 3 -35 6 -16 6 -16 5 2 -9 19 -16 18 -16 -21 -13 -22 2 -49 9 -60 15
-15 10 -16 11 -2 7 10 -3 25 -9 33 -12 9 -4 15 -1 15 7 0 7 -3 9 -6 6 -4 -3
-15 -2 -25 3 -9 6 -25 7 -35 3 -16 -6 -16 -7 0 -18 14 -10 6 -12 -41 -10 -69
3 -66 3 -58 15 3 5 15 6 28 1 33 -12 51 -3 32 16 -8 8 -12 20 -8 26 4 7 -1 6
-11 -2 -13 -11 -14 -16 -4 -22 10 -7 10 -9 -2 -9 -8 0 -22 5 -30 10 -13 8 -13
10 1 10 9 0 19 7 23 15 7 18 -5 20 -23 2 -10 -10 -11 -9 -6 3 4 13 -8 15 -80
15 -47 0 -85 3 -85 7 0 5 10 8 23 9 21 1 22 1 1 7 -13 5 -20 14 -18 25 1 10
-4 21 -12 24 -8 3 -14 12 -14 20 0 8 -5 11 -10 8 -6 -4 -8 -9 -6 -13 2 -4 -17
-8 -42 -8 -26 -1 -41 1 -34 3 6 3 12 9 12 14 0 4 -7 6 -15 3 -8 -4 -15 -1 -15
5 0 6 -5 11 -11 11 -6 0 -8 -9 -4 -21 5 -14 3 -19 -4 -15 -6 4 -9 24 -8 44 2
36 1 37 -32 34 -23 -3 -31 0 -26 8 4 6 15 8 25 5 10 -3 22 -1 25 6 6 8 10 8
16 -2 4 -7 11 -11 14 -7 3 3 -12 20 -35 38 -22 17 -40 28 -40 24 0 -4 -7 1
-16 12 -15 19 -16 19 -9 -1 5 -15 3 -17 -6 -8 -6 6 -18 9 -26 6 -8 -3 -13 0
-11 7 2 7 -3 15 -11 18 -9 3 -11 0 -6 -8 5 -8 4 -11 -3 -6 -5 3 -13 0 -15 -6
-4 -9 -6 -10 -6 -1 -1 6 4 15 9 18 6 3 10 11 10 16 0 6 -4 7 -10 4 -5 -3 -10
-1 -10 4 0 13 13 14 24 3 4 -4 13 -7 19 -7 7 -1 11 -6 10 -13 -3 -14 13 -16
22 -2 7 11 -93 62 -107 54 -4 -3 -7 -1 -6 4 2 6 -1 10 -7 10 -5 0 -9 -3 -8 -7
1 -4 -4 -8 -11 -8 -8 0 -13 16 -13 44 -1 26 4 43 11 43 6 0 4 5 -4 10 -8 5
-11 10 -7 10 5 0 -6 20 -24 45 -19 26 -38 44 -46 41 -7 -3 -13 -1 -13 4 0 6 5
10 10 10 6 0 5 8 -2 19 -9 15 -21 19 -51 16 -22 -2 -36 -1 -33 2 3 4 16 7 28
8 12 1 24 8 26 16 2 8 0 10 -5 4 -8 -10 -42 -14 -44 -5 0 3 3 14 7 24 7 19 8
19 15 0 6 -16 8 -16 8 -2 1 25 -16 31 -28 11 -8 -15 -10 -14 -7 7 1 14 6 32
11 39 6 10 4 12 -5 6 -10 -6 -12 -4 -7 8 3 9 0 22 -6 29 -7 7 -16 21 -20 32
-6 15 -10 16 -22 6 -8 -7 -12 -16 -9 -21 6 -10 -9 -12 -19 -2 -4 3 3 18 15 31
21 23 21 24 3 14 -17 -9 -21 -7 -21 8 0 11 6 16 16 13 10 -3 9 1 -4 11 -12 10
-15 16 -7 16 13 0 12 14 -4 68 -5 14 -3 22 4 22 7 0 9 -4 6 -10 -3 -5 -1 -18
4 -27 8 -15 10 -11 11 19 0 29 -3 36 -15 32 -11 -5 -15 1 -15 17 0 20 2 21 11
9 9 -12 10 -11 4 5 -13 36 -18 162 -6 145 8 -12 11 -6 11 23 0 23 -4 36 -10
32 -13 -8 -13 15 0 35 8 13 10 12 10 -4 0 -11 2 -17 5 -14 4 3 3 18 -1 33 -5
20 -9 23 -15 13 -6 -9 -9 -9 -9 -1 0 6 5 15 10 18 7 4 7 11 2 18 -10 13 -9 63
1 73 4 4 3 12 -1 18 -3 6 -7 67 -7 136 -1 69 0 115 3 102 2 -12 8 -22 13 -22
6 0 8 8 5 18 -4 13 -1 19 9 19 8 0 15 -6 15 -14 0 -15 -21 -55 -34 -64 -5 -4
-7 -12 -4 -19 6 -20 77 126 74 154 -2 15 1 29 5 32 11 7 12 54 1 54 -5 0 -9 7
-9 15 0 12 3 12 16 2 12 -10 13 -9 7 2 -5 8 -3 17 5 22 10 6 11 9 2 9 -7 0
-13 8 -14 18 -1 11 -4 9 -9 -8 -7 -23 -8 -23 -9 -3 -1 27 18 41 30 22 5 -8 9
-9 10 -4 0 6 2 21 5 35 3 21 2 22 -7 8 -13 -23 -30 -23 -22 -1 4 11 3 14 -5 9
-8 -5 -12 1 -12 17 0 14 7 28 16 31 8 3 12 11 8 17 -4 8 -9 8 -13 1 -4 -6 -6
7 -6 28 0 21 3 33 7 27 5 -9 8 -8 8 3 0 13 1 13 10 0 6 -10 10 -11 10 -2 0 6
7 12 15 12 8 0 15 -5 15 -11 0 -5 4 -8 8 -5 5 3 8 34 6 70 -2 58 -1 64 16 61
13 -3 16 1 13 16 -3 11 0 19 7 19 9 0 9 4 2 13 -7 8 -7 18 -2 27 8 13 10 13
10 1 0 -8 2 -12 5 -9 3 3 1 17 -4 32 -9 23 0 38 12 19 2 -5 2 -3 1 4 -2 7 1
15 5 18 5 2 11 14 14 25 4 17 1 19 -26 16 -17 -2 -33 -9 -35 -15 -2 -6 -9 -11
-15 -11 -6 0 0 11 12 24 13 14 29 22 36 20 7 -3 16 0 20 5 3 6 11 9 16 6 5 -4
9 0 9 9 0 8 3 22 7 32 4 11 3 15 -4 11 -15 -9 -22 24 -11 53 9 24 10 24 24 6
13 -19 14 -18 10 12 -2 18 1 32 7 32 5 0 4 7 -3 15 -7 9 -10 19 -6 22 4 4 11
2 15 -6 6 -10 10 -9 15 5 3 9 4 19 1 22 -3 3 -5 -1 -5 -9 0 -11 -2 -11 -10 1
-15 23 -12 29 10 25 11 -2 18 0 15 5 -3 5 -1 11 5 15 6 4 7 13 1 23 -9 17 -12
166 -3 190 7 20 21 14 15 -8 -3 -11 0 -20 6 -20 6 0 11 6 11 14 0 8 5 18 12
22 8 5 9 2 3 -12 -4 -10 -9 -34 -11 -52 -1 -18 -5 -36 -10 -40 -4 -4 -4 1 -1
11 4 9 2 17 -3 17 -16 0 -12 -30 6 -47 12 -13 15 -13 13 -2 -2 13 13 58 28 82
3 6 1 7 -5 3 -7 -4 -12 -3 -12 3 0 5 8 15 17 20 10 6 12 11 5 11 -7 0 -4 8 9
21 11 11 18 24 14 27 -3 4 -10 1 -14 -6 -5 -8 -8 0 -8 20 0 30 22 67 33 56 9
-9 -8 -38 -19 -31 -6 3 -7 1 -3 -5 12 -19 21 -14 33 18 6 17 7 28 2 24 -5 -3
-9 0 -9 5 0 6 5 11 11 11 6 0 9 7 5 15 -3 9 0 15 9 15 8 0 16 5 16 10 3 19 5
25 17 48 7 12 10 26 7 31 -3 5 0 12 5 14 6 3 5 4 -1 3 -7 -1 -13 -12 -14 -24
-3 -37 -12 -34 -13 3 -1 19 3 35 9 35 6 0 6 6 0 18 -7 14 -6 19 4 19 8 0 14
-3 13 -6 -2 -19 3 -31 13 -31 8 0 7 6 -2 17 -12 15 -12 16 6 10 l20 -7 -20 15
c-36 26 -45 36 -45 48 0 8 3 8 9 -2 5 -7 14 -10 20 -7 6 4 11 0 12 -11 0 -15
2 -14 11 5 6 12 18 22 27 22 12 0 12 3 3 12 -9 9 -15 8 -27 -8 -12 -16 -14
-16 -15 -3 0 18 26 33 40 24 5 -3 7 17 5 45 -2 27 1 53 6 56 5 3 9 10 9 15 0
6 -3 8 -7 6 -5 -3 -9 6 -10 20 -1 19 1 22 8 12 6 -10 9 -10 9 -1 0 6 5 12 12
12 9 0 9 -3 0 -12 -20 -20 -14 -33 7 -14 10 9 25 14 32 11 12 -5 15 10 14 82
l0 88 30 0 c17 0 35 -7 42 -15 9 -12 11 -8 8 23 -1 20 1 37 7 37 5 0 7 -4 3
-9 -3 -5 0 -13 6 -17 8 -4 9 -3 5 4 -4 7 -2 12 5 12 8 0 9 3 0 13 -13 17 -5
27 17 20 14 -4 15 -3 2 5 -8 6 -18 8 -23 5 -5 -3 -7 8 -6 25 2 16 -2 36 -9 44
-10 13 -8 15 14 15 14 0 22 -2 17 -6 -5 -3 -10 -19 -11 -35 -2 -28 -1 -29 12
-11 8 11 13 23 10 26 -2 3 7 16 20 28 14 13 26 18 30 12 4 -5 -3 -16 -13 -23
-11 -8 -25 -27 -31 -43 -11 -29 -11 -29 9 -11 11 10 18 22 16 26 -2 4 4 15 15
25 11 10 17 22 14 26 -3 5 -1 9 4 9 5 0 9 12 8 27 -1 14 5 28 12 30 8 3 10 -1
6 -14 -6 -16 -5 -16 8 1 9 11 13 27 11 37 -3 9 0 20 6 23 7 5 9 0 5 -11 -4
-14 -3 -14 6 2 7 11 19 25 27 32 12 9 12 16 3 33 -6 12 -14 19 -18 16 -3 -4 0
-12 6 -18 8 -8 9 -15 1 -25 -15 -18 -36 -16 -36 2 0 9 6 14 12 11 8 -3 10 4 7
22 -5 24 -6 25 -13 7 -6 -15 -7 -13 -6 11 2 29 4 31 42 30 38 -1 40 0 39 31
-1 20 3 30 9 26 6 -4 8 0 5 8 -4 11 1 15 22 15 16 -1 32 -5 37 -10 5 -5 3 -6
-4 -2 -8 4 -13 2 -13 -5 0 -8 -4 -8 -13 -1 -17 14 -39 -18 -26 -39 7 -11 14
-8 36 16 22 23 28 39 28 75 0 45 0 45 35 45 25 0 40 7 55 25 11 14 23 27 27
28 5 2 6 8 3 13 -4 5 -12 3 -19 -4 -8 -6 -12 -15 -10 -19 2 -4 0 -9 -6 -13 -5
-3 -10 1 -10 9 0 8 5 18 11 22 8 5 8 9 -1 14 -7 4 4 7 23 6 19 0 33 -4 30 -8
-12 -21 7 -4 22 18 9 15 12 23 6 20 -6 -4 -11 -2 -11 3 0 6 6 11 13 11 9 0 8
3 -2 9 -20 13 -7 26 29 29 18 1 29 -2 25 -7 -3 -6 -12 -8 -20 -4 -16 5 -21
-12 -6 -21 16 -10 44 21 45 52 0 18 5 32 10 32 5 0 4 6 -2 13 -8 9 -2 12 24
13 27 1 34 -3 29 -14 -3 -10 0 -12 10 -8 8 3 15 0 15 -7 1 -7 10 -1 20 12 11
13 26 21 32 19 7 -2 24 7 38 20 23 23 24 24 3 13 -15 -8 -23 -8 -23 -1 0 5 6
10 13 10 7 0 8 3 2 8 -26 19 -9 27 61 28 81 1 84 -5 14 -27 -34 -11 -40 -15
-22 -17 12 -2 20 -8 17 -13 -4 -5 2 -6 11 -2 11 4 13 8 6 11 -27 9 3 22 50 22
28 0 49 -3 45 -6 -3 -3 -22 -6 -42 -6 -20 0 -34 -3 -31 -6 3 -3 22 -5 43 -4
20 1 42 -3 50 -9 21 -18 15 5 -8 30 -12 12 -27 20 -35 17 -8 -3 -14 -1 -14 4
0 15 42 11 64 -5 14 -11 16 -15 5 -15 -12 0 -12 -2 1 -10 12 -8 13 -13 3 -29
-11 -17 -10 -19 4 -14 10 4 14 2 10 -4 -3 -5 0 -10 8 -10 8 0 12 3 10 7 -2 4
12 7 32 8 36 1 41 -7 7 -12 -10 -2 -20 -9 -22 -17 -3 -11 -1 -11 7 1 10 14 12
13 15 -11 0 -6 16 -17 34 -25 18 -7 30 -9 27 -4 -3 6 -1 10 4 10 6 0 11 -7 11
-16 0 -12 6 -15 21 -11 14 4 19 1 16 -7 -3 -7 5 -18 16 -25 18 -11 20 -11 10
0 -15 16 -17 39 -3 39 5 0 9 -3 8 -7 -3 -16 15 -43 25 -36 5 3 7 2 4 -3 -3 -6
-3 -14 1 -19 11 -15 47 -41 39 -29 -4 8 13 11 55 9 l62 -1 2 -45 c0 -24 -1
-38 -3 -31 -3 6 -12 12 -20 12 -14 0 -14 3 2 20 10 11 13 20 7 20 -6 0 -16 -8
-21 -17 -6 -10 -11 -13 -11 -7 0 7 -8 15 -18 18 -9 3 1 -10 22 -29 40 -36 56
-43 56 -25 0 14 37 13 46 -1 4 -8 9 -7 15 2 6 10 8 7 6 -8 -1 -18 -6 -21 -32
-16 -22 3 -26 2 -15 -5 8 -6 17 -9 21 -6 4 2 17 -10 29 -26 16 -23 25 -28 35
-20 22 19 45 1 40 -31 -6 -32 12 -45 58 -43 15 0 27 -3 27 -8 0 -5 -6 -8 -13
-7 -32 4 -40 -1 -27 -16 7 -8 18 -15 24 -14 7 0 6 2 -1 6 -7 2 -13 9 -13 15 0
6 6 6 15 -2 8 -7 13 -16 10 -20 -8 -12 25 -41 38 -33 6 4 8 0 4 -10 -3 -8 0
-20 7 -26 14 -11 26 -5 26 13 0 6 -4 5 -9 -3 -6 -9 -11 -10 -15 -2 -4 6 -2 11
4 11 6 0 8 5 5 10 -3 6 -2 10 4 10 11 0 26 -39 17 -47 -10 -10 15 -44 26 -37
7 5 9 2 5 -9 -3 -9 -2 -16 2 -14 5 1 10 -2 11 -8 2 -17 42 -55 51 -49 5 3 9
-2 9 -11 0 -10 -5 -13 -12 -9 -8 4 -10 3 -5 -2 5 -5 13 -10 18 -10 5 -1 13 -2
18 -3 5 0 7 -9 4 -20 -6 -22 12 -37 23 -20 5 8 3 9 -6 4 -9 -5 -10 -2 -5 14 4
14 1 23 -9 27 -9 3 -16 12 -16 20 0 17 20 19 21 2 0 -10 2 -10 6 0 12 30 20 6
19 -58 0 -43 -3 -62 -8 -50 -4 11 -10 20 -12 20 -7 0 4 -31 15 -46 5 -6 6 -14
3 -17 -4 -3 5 -4 20 -1 15 2 26 0 26 -6 0 -6 -5 -8 -10 -5 -6 3 -10 -1 -10 -9
0 -24 19 -19 30 7 5 13 9 16 10 7 0 -12 2 -12 8 -2 5 8 8 -1 8 -22 -1 -19 -5
-31 -10 -28 -6 3 -6 -2 -1 -14 5 -10 10 -41 11 -69 l3 -50 -8 51 c-9 57 -17
74 -32 74 -6 0 -3 -10 5 -22 9 -13 16 -43 16 -70 0 -27 4 -48 8 -48 4 0 9 -13
10 -30 1 -16 -2 -30 -8 -30 -5 0 -10 -10 -10 -22 0 -19 2 -20 10 -8 8 13 10
12 10 -2 0 -10 -7 -18 -15 -18 -8 0 -15 7 -15 15 0 9 -9 15 -22 15 -23 -1 -23
-1 -3 -17 20 -15 20 -15 -2 -10 -19 4 -22 0 -27 -47 -9 -76 -9 -79 -1 -103 5
-15 3 -23 -4 -23 -7 0 -9 5 -5 12 4 7 3 8 -4 4 -9 -5 -9 -13 -2 -27 7 -13 7
-25 0 -38 -12 -24 -13 -31 -1 -31 5 0 12 8 15 18 2 9 3 -5 1 -31 -3 -48 -15
-79 -15 -39 0 31 -10 52 -26 52 -17 0 -17 -4 0 -38 13 -25 13 -28 -3 -28 -13
0 -17 6 -13 21 2 11 -5 4 -16 -15 -11 -19 -16 -37 -12 -40 5 -3 8 -33 7 -67
l-1 -61 -34 0 c-29 0 -31 2 -16 12 10 8 14 18 9 26 -5 8 -3 11 6 8 8 -3 13
-13 12 -23 -1 -11 2 -14 9 -7 7 7 6 17 -2 32 -7 12 -8 20 -3 17 4 -3 8 6 8 19
0 13 -3 24 -7 24 -5 0 -8 -8 -8 -19 0 -10 -6 -24 -14 -31 -8 -6 -12 -19 -9
-27 4 -10 2 -14 -3 -10 -6 3 -17 -9 -26 -28 -8 -19 -12 -33 -9 -30 6 3 7 -71
2 -111 -1 -13 -49 -16 -56 -4 -3 4 4 10 15 13 11 3 20 0 20 -6 0 -7 5 -5 10 3
14 22 12 28 -10 40 -14 7 -20 7 -20 0 0 -5 7 -10 15 -10 8 0 15 -5 15 -11 0
-5 -4 -7 -10 -4 -14 9 -43 -10 -48 -31 -2 -11 -4 -47 -5 -81 0 -34 -4 -60 -8
-57 -4 2 -7 25 -5 50 1 25 -2 48 -7 51 -6 3 -7 1 -4 -4 4 -6 0 -14 -9 -17 -9
-4 -14 -9 -10 -13 3 -3 -1 -14 -10 -24 -12 -13 -13 -22 -5 -36 10 -17 10 -17
11 2 0 23 16 52 24 44 9 -9 -19 -76 -30 -72 -6 2 -19 -8 -28 -22 -9 -14 -12
-25 -6 -25 5 0 10 -5 10 -12 0 -7 -7 -5 -17 6 -13 13 -14 20 -6 23 7 2 9 11 6
20 -5 12 -3 14 7 8 9 -5 11 -4 6 4 -6 9 -11 9 -22 0 -7 -6 -12 -15 -9 -18 2
-4 -16 -27 -40 -51 -25 -24 -45 -50 -45 -58 0 -8 -5 -10 -12 -6 -7 4 -10 4 -6
-1 4 -4 1 -13 -7 -19 -8 -6 -16 -26 -17 -43 -1 -18 -7 -33 -13 -33 -5 0 -4 -4
2 -8 8 -5 -4 -8 -28 -9 -30 -1 -47 -7 -63 -24 -12 -13 -20 -23 -17 -22 2 2 10
-2 18 -8 8 -8 13 -8 13 -1 0 6 -4 12 -9 14 -5 2 -3 9 5 16 13 12 14 9 12 -20
-2 -34 -2 -34 -52 -35 -40 0 -50 -3 -52 -18 -4 -24 -20 -40 -28 -26 -4 6 -2
11 3 11 6 0 11 5 11 12 0 6 -9 3 -20 -7 -24 -21 -26 -37 -5 -29 10 4 13 2 10
-8 -4 -9 -20 -15 -38 -15 -26 -1 -28 1 -12 7 11 5 15 9 8 9 -21 2 -63 -22 -64
-36 -1 -7 -2 -23 -3 -35 -1 -16 -8 -23 -24 -24 -15 -1 -19 2 -13 10 6 7 4 16
-4 23 -9 8 -16 9 -20 3 -3 -6 -1 -10 6 -10 8 0 8 -4 -1 -15 -9 -11 -16 -12
-25 -5 -10 8 -15 8 -20 0 -4 -7 -21 -10 -37 -8 -25 4 -29 2 -25 -14 3 -11 -3
-24 -14 -32 -17 -12 -18 -14 -4 -20 9 -3 13 -2 10 4 -3 6 -1 10 4 10 6 0 11
-6 11 -13 0 -9 -32 -13 -132 -13 -123 -1 -133 4 -36 19 10 2 21 7 25 11 5 5
-8 4 -27 0 -19 -5 -57 -8 -85 -7 -30 0 -42 -2 -31 -6 15 -6 16 -9 5 -13 -8 -3
-20 1 -27 8 -10 11 -10 14 0 14 9 0 10 2 2 8 -7 4 -13 16 -15 27 -4 18 -12 20
-82 19 -42 0 -77 3 -77 8 0 4 5 8 11 8 8 0 8 4 0 14 -7 9 -8 17 -2 20 5 3 11
1 13 -6 3 -8 7 -6 11 5 4 11 -4 23 -25 39 -23 16 -30 18 -26 7 7 -19 -20 -22
-42 -4 -27 21 -44 29 -36 16 4 -6 1 -23 -8 -36 -25 -38 -19 -43 10 -10 14 17
22 23 17 15 -4 -8 -6 -23 -4 -34 1 -11 -2 -16 -8 -12 -6 4 -11 2 -11 -3 0 -6
4 -11 9 -11 5 0 9 -22 9 -50 0 -27 -4 -50 -9 -50 -5 0 -6 8 -2 18 5 14 3 14
-9 -2 -15 -19 -16 -19 -22 2 -7 20 -28 33 -24 15 0 -5 -1 -15 -5 -23 -7 -19
-18 -72 -17 -85 5 -95 5 -222 -1 -213 -4 7 -11 9 -15 5 -4 -3 -1 -13 6 -22 7
-8 8 -15 2 -15 -12 0 -38 39 -44 65 -3 16 -5 15 -10 -7 -3 -15 -1 -25 3 -22
13 8 11 -9 -4 -31 -17 -25 -28 -67 -16 -59 5 3 9 1 9 -4 0 -6 -7 -13 -16 -16
-13 -5 -15 -14 -9 -44 4 -20 11 -42 17 -49 5 -7 8 -18 5 -25 -4 -9 -6 -8 -6 3
-1 9 -9 23 -18 30 -10 7 -15 9 -11 5 4 -5 3 -15 -2 -21 -11 -14 6 -65 22 -65
5 0 2 10 -7 23 -17 27 -8 46 10 19 19 -27 18 -111 0 -119 -23 -8 -33 7 -14 20
16 12 22 47 8 47 -4 0 -11 -9 -14 -21 -6 -17 -9 -18 -22 -7 -14 12 -15 11 -5
-9 15 -27 15 -36 2 -28 -13 8 -18 -8 -24 -75 -2 -30 -7 -64 -11 -75 -6 -17 -5
-17 5 -5 13 17 9 -31 -9 -112 -7 -32 -7 -55 0 -70 9 -22 9 -22 5 2 -3 14 -1
41 4 60 7 29 8 25 5 -25 -2 -33 -6 -73 -9 -90 -5 -24 -4 -26 3 -10 9 19 10 19
10 -2 1 -12 -3 -25 -9 -28 -5 -3 -10 -12 -10 -18 0 -7 4 -6 10 3 8 12 10 8 11
-15 1 -16 0 -37 -1 -45 -1 -10 3 -9 13 5 19 24 21 22 44 -40 18 -48 20 -50 56
-50 20 0 37 4 37 9 0 4 -17 5 -37 2 -28 -5 -34 -4 -23 4 17 11 79 10 86 -2 3
-4 10 -8 15 -8 6 0 7 5 3 11 -4 7 -1 10 7 7 23 -8 48 10 44 32 -2 11 1 20 7
20 5 0 8 -7 5 -15 -4 -8 -1 -17 5 -21 7 -5 8 -2 3 6 -6 9 -4 12 6 8 8 -3 33 5
55 18 21 13 33 24 26 24 -8 0 -11 12 -10 33 2 23 -1 31 -11 29 -12 -3 -12 0
-1 12 14 17 25 22 15 7 -3 -6 3 -15 14 -20 14 -8 20 -7 24 4 4 8 -1 16 -11 18
-10 3 -5 5 11 6 16 0 26 -2 24 -6 -5 -9 13 -27 30 -30 6 -1 13 4 16 11 4 10 0
12 -14 9 -10 -3 -19 -1 -19 5 0 5 11 10 25 11 49 3 54 4 47 15 -11 19 -13 54
-2 61 5 3 10 1 10 -5 0 -6 5 -8 13 -3 19 12 36 12 44 1 3 -7 1 -8 -5 -4 -8 5
-10 2 -6 -8 5 -12 9 -13 19 -3 7 7 10 17 6 22 -4 6 -8 26 -8 45 -1 29 3 36 19
37 18 2 19 1 4 -10 -14 -10 -13 -12 10 -13 17 -1 24 2 21 11 -4 8 3 11 19 9
25 -2 33 10 12 20 -7 4 -3 4 9 2 12 -2 24 1 26 8 2 6 8 8 12 4 12 -11 -26 -50
-42 -44 -8 3 -16 -1 -19 -9 -3 -8 -15 -17 -26 -21 -13 -4 -17 -11 -14 -22 5
-13 2 -12 -9 2 -12 16 -15 17 -15 4 0 -26 23 -27 50 -2 14 13 42 37 62 55 21
17 35 35 32 40 -3 5 1 6 9 3 19 -7 28 8 16 26 -5 8 -9 10 -9 4 0 -6 -7 -11
-15 -11 -8 0 -15 5 -15 12 0 18 42 16 57 -3 12 -16 13 -16 7 0 -4 12 1 17 18
20 19 2 22 8 20 31 -2 17 2 27 10 27 9 0 9 3 -2 10 -10 6 -5 9 19 8 l33 -1
-26 -16 c-14 -9 -26 -20 -26 -25 0 -4 6 -3 13 3 8 6 18 7 22 3 5 -4 5 -1 1 6
-4 6 -3 12 2 12 5 0 22 11 38 25 26 23 27 27 13 38 -14 10 -14 14 -2 25 7 7
26 12 41 10 25 -3 27 -1 27 37 0 36 3 40 25 40 14 0 31 -4 38 -9 11 -8 18 11
13 38 -1 5 5 3 14 -4 13 -11 15 -10 9 4 -5 13 -2 15 12 10 11 -4 16 -4 12 0
-3 4 -14 9 -24 10 -9 2 -18 10 -20 19 -3 20 16 18 33 -3 11 -13 12 -13 7 3 -4
12 -1 17 11 17 10 0 13 -4 9 -12 -6 -10 -4 -10 8 0 11 8 15 26 13 51 -2 39 -2
39 34 39 20 0 30 -3 23 -5 -6 -3 -10 -8 -7 -13 3 -5 19 5 35 22 17 16 28 34
25 39 -4 5 1 6 9 3 17 -6 22 11 7 20 -9 6 -34 -18 -27 -25 2 -2 -2 -9 -9 -16
-10 -9 -12 -5 -10 20 3 33 26 44 53 24 19 -14 27 0 27 46 0 27 5 42 15 44 13
4 13 3 0 -6 -13 -9 -13 -11 2 -11 9 0 20 5 23 10 4 6 12 9 18 8 7 -1 24 8 39
21 25 22 27 26 13 41 -15 16 -13 17 15 14 22 -3 30 1 30 12 0 9 5 12 13 8 7
-5 7 -2 -2 10 -12 14 -12 17 -1 14 8 -2 16 5 18 15 2 9 -2 17 -8 17 -6 0 -8
-4 -5 -10 3 -5 1 -10 -5 -10 -7 0 -10 6 -7 13 2 6 18 12 35 12 25 0 37 7 52
29 10 17 16 34 13 40 -4 5 0 7 10 3 10 -4 21 3 33 21 9 15 11 22 4 15 -7 -6
-19 -9 -26 -6 -8 3 -14 0 -14 -7 0 -6 -4 -9 -9 -5 -6 3 -8 -4 -6 -15 2 -11 0
-20 -4 -20 -12 0 -12 63 0 80 8 11 9 9 4 -10 -6 -20 -5 -22 5 -13 7 6 19 9 26
6 21 -8 17 7 -8 28 -13 10 -17 18 -10 19 6 0 12 -4 12 -10 0 -5 7 -10 15 -10
8 0 15 -4 15 -10 0 -5 5 -10 10 -10 15 0 51 40 45 50 -3 5 -1 11 5 15 5 3 10
1 10 -6 0 -7 6 -4 14 7 21 28 3 45 -37 33 -40 -11 -39 -11 -35 3 6 16 69 20
86 6 19 -16 25 -3 10 21 -11 18 -11 23 0 30 8 4 10 15 6 26 -7 18 -8 18 -15
-1 -4 -11 -7 5 -7 41 l1 60 18 -50 c16 -43 18 -25 3 26 -3 12 2 16 20 14 14
-2 30 3 37 11 6 8 8 14 5 14 -4 0 -2 9 4 20 6 11 7 20 3 20 -5 0 -8 41 -8 90
0 50 3 90 7 90 5 0 8 -6 8 -12 0 -9 3 -10 8 -2 4 6 15 10 24 8 22 -3 28 6 30
43 1 15 6 34 11 41 5 7 11 27 13 45 3 23 1 28 -6 17 -11 -17 -10 -20 -16 47
-3 47 -2 52 16 50 11 -1 19 -6 17 -12 -14 -37 -17 -54 -9 -59 6 -3 9 2 8 12 0
9 5 16 12 14 10 -2 11 4 7 19 -5 15 -4 19 4 15 6 -4 11 -2 11 3 0 6 -6 11 -12
11 -7 0 -4 7 7 15 14 11 19 27 19 59 0 37 2 41 13 27 16 -21 17 -6 1 22 -12
23 -20 117 -9 117 3 0 12 -4 20 -9 12 -8 12 -12 2 -25 -7 -9 -11 -23 -7 -31 3
-8 0 -15 -7 -15 -8 0 -6 -4 3 -10 8 -5 11 -10 7 -10 -5 0 -5 -5 -1 -12 5 -7
10 -3 16 12 6 16 5 26 -4 31 -9 6 -9 11 1 23 7 9 18 13 23 10 6 -4 5 4 -2 16
-7 13 -8 20 -1 16 13 -8 29 22 26 51 -1 15 3 19 13 15 9 -3 13 -1 9 4 -3 6 -2
16 3 24 5 9 2 25 -8 44 -16 32 -27 120 -11 94 6 -9 9 -10 9 -1 0 8 12 12 33
10 33 -4 57 7 57 26 0 6 -6 3 -13 -7 -11 -15 -13 -7 -13 61 l0 78 33 1 c36 0
41 -7 18 -26 -13 -11 -14 -10 -9 4 7 19 -3 21 -21 3 -11 -11 -10 -15 6 -25 20
-13 36 -12 31 1 -1 4 4 15 12 22 8 8 12 25 9 37 -4 19 -3 20 6 8 9 -12 11 -9
11 15 0 23 -3 27 -10 15 -11 -17 -13 -10 -4 14 3 9 9 14 13 12 3 -2 16 7 27
19 19 19 20 23 6 41 -14 18 -14 18 -9 -3 3 -13 1 -23 -4 -23 -5 0 -9 8 -9 18
0 14 -2 15 -10 2 -6 -10 -6 -16 0 -18 16 -6 12 -22 -5 -22 -16 0 -18 12 -9 47
5 17 12 22 28 19 11 -2 31 0 43 5 26 10 58 65 43 74 -6 4 -8 11 -4 16 3 5 0 8
-6 7 -7 -2 -17 4 -23 13 -11 18 -3 49 13 49 6 0 8 -6 4 -12 -5 -9 -2 -8 10 1
14 12 19 12 29 1 10 -10 9 -12 -5 -7 -12 4 -15 2 -12 -8 4 -10 0 -13 -16 -10
-13 2 -20 1 -16 -2 4 -4 12 -8 19 -9 7 -1 19 -3 27 -5 11 -3 15 4 14 24 -1 21
2 27 14 22 12 -4 13 -3 2 10 -12 14 -7 20 14 16 4 -1 14 6 21 16 10 14 11 21
2 32 -10 12 -9 14 5 8 12 -4 15 -2 11 9 -3 9 1 14 13 14 14 0 15 3 6 12 -10
10 -15 10 -22 -2 -7 -12 -12 -12 -21 -3 -9 9 -16 10 -26 2 -9 -8 -13 -8 -13 0
0 6 5 11 11 11 8 0 9 4 0 13 -6 8 -8 17 -4 20 12 13 23 7 23 -13 0 -22 10 -26
28 -8 8 8 15 8 28 -3 16 -12 17 -12 9 1 -13 20 -15 41 -5 35 15 -9 48 17 42
33 -3 10 -1 13 6 8 6 -3 13 -2 16 3 4 5 2 12 -3 15 -5 4 -13 2 -16 -4 -5 -8
-11 -7 -22 1 -12 11 -14 10 -13 -2 4 -25 0 -40 -9 -35 -5 3 -8 16 -8 29 0 21
4 23 41 22 43 -2 68 17 58 44 -3 9 -1 12 6 7 8 -5 10 -2 6 8 -10 25 -23 19
-19 -9 1 -14 -2 -28 -7 -31 -5 -4 -8 8 -6 27 4 31 7 34 38 34 38 0 50 21 44
73 -3 30 4 40 19 25 4 -3 2 -12 -5 -20 -8 -10 -8 -17 1 -26 10 -9 11 -8 5 7
-4 12 -4 17 2 13 5 -3 11 6 12 20 1 15 -1 24 -4 20 -10 -10 -34 20 -28 36 7
21 62 16 68 -5 2 -10 1 -18 -4 -18 -4 0 -11 -8 -15 -17 -4 -11 5 -7 24 11 17
17 31 35 31 40 0 6 -5 4 -10 -4 -11 -17 -12 -12 -14 46 -1 37 2 41 20 39 11
-1 30 2 42 6 18 7 21 14 17 38 -6 32 10 50 28 32 13 -13 30 -15 22 -2 -3 6 4
9 15 8 18 -1 18 -3 -7 -23 -18 -14 -23 -23 -15 -28 7 -4 12 -2 12 3 0 6 3 10
8 8 4 -1 9 3 11 8 2 6 12 16 22 23 11 7 19 20 19 28 0 8 5 14 11 14 6 0 8 -7
5 -17 -6 -14 -3 -14 15 3 11 10 24 18 28 17 3 -2 7 2 7 7 3 23 5 24 13 12 6
-10 10 -10 21 3 12 14 10 15 -18 10 -17 -3 -33 -1 -36 3 -3 5 26 9 64 9 84 2
115 10 101 26 -7 9 3 12 35 13 24 1 41 -2 38 -7 -3 -5 1 -6 9 -3 8 3 18 1 22
-5 3 -6 11 -11 16 -11 6 0 8 4 5 9 -4 5 0 12 6 15 7 3 4 5 -7 4 -11 0 -27 2
-35 5 -8 3 -22 8 -30 10 -8 3 8 5 36 4 50 0 52 -1 47 -27 -5 -34 9 -38 101
-35 57 3 65 1 55 -11 -6 -8 -7 -14 -2 -14 6 0 10 -8 10 -17 0 -36 4 -39 46
-37 59 2 75 -6 46 -23 -24 -13 -23 -13 6 -7 28 6 29 5 11 -8 -15 -12 -16 -17
-6 -23 9 -5 11 -4 6 3 -15 24 10 11 34 -17 14 -17 32 -31 40 -33 8 -2 19 -8
25 -13 5 -6 9 -8 8 -6 -2 2 2 10 7 16 8 9 4 12 -14 10 -17 -2 -23 1 -20 10 3
8 14 13 25 12 21 -1 21 -1 23 -39 1 -15 5 -30 8 -33 4 -4 36 -7 72 -8 46 0 63
-4 56 -12 -5 -6 -5 -17 0 -25 6 -8 10 -22 10 -30 0 -8 -8 -1 -16 17 -11 22
-21 30 -32 26 -15 -4 -15 -3 -2 6 12 8 11 10 -6 9 -28 -3 -26 -23 5 -50 l26
-23 -20 24 c-15 17 -16 23 -5 19 8 -2 20 -14 25 -25 17 -33 37 -53 46 -47 5 3
9 -1 9 -9 0 -17 34 -49 43 -40 4 3 7 0 7 -8 0 -20 34 -39 50 -29 9 6 19 2 28
-8 13 -16 13 -16 8 1 -3 9 -1 17 5 17 6 0 9 -8 8 -17 -3 -34 1 -39 31 -37 16
1 27 -2 24 -7 -3 -5 -1 -9 5 -9 5 0 11 6 14 13 2 6 4 -1 4 -16 0 -21 -3 -27
-14 -23 -9 4 -13 1 -10 -6 2 -7 11 -12 18 -10 11 3 11 1 2 -8 -17 -18 -36 -1
-29 26 5 18 3 22 -8 18 -17 -6 -68 24 -61 36 3 5 1 11 -4 14 -5 4 -12 2 -15
-2 -3 -5 10 -27 30 -48 19 -22 32 -32 29 -24 -4 8 3 3 15 -12 18 -23 27 -27
56 -22 29 4 34 2 34 -15 0 -23 19 -38 29 -22 4 6 7 -5 8 -24 1 -19 -3 -32 -8
-29 -5 3 -9 1 -9 -5 0 -5 5 -13 10 -16 8 -5 7 -12 -2 -23 -11 -13 -11 -14 1
-7 10 6 13 4 9 -6 -3 -8 1 -24 9 -34 12 -15 14 -16 10 -3 -6 18 10 28 39 24
17 -3 19 -14 20 -136 1 -79 -2 -125 -7 -112 -5 12 -10 22 -11 22 -2 0 -3 -27
-3 -59 0 -55 1 -57 12 -33 11 24 12 21 8 -33 -2 -33 -4 -61 -4 -63 -1 -2 -5
-1 -10 2 -5 3 -12 -1 -15 -9 -4 -11 0 -15 12 -13 12 2 17 -4 17 -21 0 -13 -6
-25 -13 -27 -7 -1 -8 0 -2 3 5 2 10 12 10 20 0 11 -6 8 -19 -8 -18 -22 -18
-25 -3 -30 9 -4 19 -5 22 -4 3 2 5 -16 6 -40 0 -24 -4 -42 -8 -39 -4 3 -8 18
-8 35 0 16 -5 29 -10 29 -12 0 -4 -42 17 -82 7 -16 10 -29 6 -29 -53 -9 -60
-6 -43 21 8 13 10 12 10 -2 0 -10 5 -18 10 -18 13 0 13 17 0 25 -5 3 -10 16
-10 28 0 12 -5 29 -11 37 -8 11 -9 6 -4 -20 4 -23 3 -30 -3 -22 -7 11 -10 11
-15 -3 -4 -9 -3 -13 3 -10 16 10 11 -5 -10 -32 -13 -17 -17 -30 -11 -37 5 -6
9 -38 8 -72 -1 -58 -2 -60 -29 -62 l-29 -2 16 30 c11 22 13 35 6 48 -10 16
-11 15 -12 -3 -1 -11 -1 -26 0 -33 1 -7 -3 -11 -10 -10 -12 3 -18 -21 -22 -87
-1 -22 -6 -46 -10 -52 -5 -7 -4 -13 0 -13 5 0 9 -8 9 -18 0 -13 -8 -18 -28
-18 -15 0 -28 1 -28 2 0 1 3 10 6 19 5 13 9 14 15 4 4 -8 11 -10 16 -6 4 5 2
11 -4 15 -16 10 -20 44 -5 49 8 3 10 -2 6 -14 -5 -14 -4 -15 4 -4 10 14 0 51
-14 51 -3 0 -3 -4 0 -9 3 -5 -1 -12 -10 -15 -10 -4 -13 -13 -9 -27 5 -14 0
-29 -17 -46 -12 -15 -17 -23 -11 -19 9 5 11 2 7 -8 -3 -8 -13 -17 -21 -20 -14
-6 -14 -9 2 -26 17 -18 16 -19 -7 -25 -13 -3 -28 -5 -34 -4 -19 3 -33 -1 -49
-17 -12 -12 -13 -19 -4 -30 6 -8 16 -11 21 -7 6 3 9 0 6 -7 -2 -8 -10 -16 -18
-18 -7 -3 -10 0 -6 6 3 5 -1 13 -10 16 -12 5 -15 2 -10 -10 5 -13 3 -14 -6 -7
-9 8 -19 5 -33 -10 -11 -11 -14 -17 -8 -13 9 5 11 2 7 -8 -3 -9 -10 -13 -15
-10 -6 3 -27 -13 -48 -35 -21 -22 -42 -37 -46 -33 -5 4 -5 1 -1 -5 4 -7 2 -13
-4 -13 -6 0 -8 -5 -4 -12 6 -9 9 -9 12 0 2 7 9 9 14 6 6 -4 8 -2 4 4 -3 6 -3
13 2 16 4 2 9 -4 9 -15 1 -10 -4 -19 -10 -19 -7 0 -6 -4 3 -11 12 -9 12 -10
-3 -5 -10 3 -28 6 -38 6 -13 0 -18 4 -13 12 4 8 3 10 -2 5 -5 -5 -10 -13 -10
-18 -1 -5 -3 -11 -4 -14 -1 -3 -3 -10 -4 -16 0 -7 -16 -13 -34 -15 -34 -4 -78
-23 -55 -24 8 0 12 -14 10 -39 -1 -26 -6 -37 -14 -34 -16 6 -16 16 -1 31 10 9
8 16 -7 30 -16 14 -17 14 -6 0 7 -10 8 -19 2 -22 -5 -3 -11 0 -14 6 -3 10 -9
8 -24 -5 -11 -9 -23 -14 -28 -10 -4 5 -5 3 -1 -4 3 -6 2 -14 -4 -18 -5 -3 -10
-1 -10 5 0 7 -6 5 -15 -4 -8 -8 -15 -18 -15 -22 0 -5 5 -3 12 4 7 7 17 8 29 1
15 -7 17 -6 13 5 -3 8 3 19 15 25 25 14 31 14 31 1 0 -5 -7 -10 -15 -10 -8 0
-15 -9 -15 -19 0 -14 -6 -19 -20 -18 -11 1 -20 5 -20 10 0 4 -6 5 -12 0 -21
-12 -39 -12 -31 0 4 7 -1 6 -10 -2 -14 -11 -17 -11 -17 0 0 11 -2 11 -10 -1
-5 -8 -12 -11 -16 -8 -3 4 -19 1 -34 -7 -15 -8 -30 -12 -32 -9 -3 3 -24 -3
-48 -12 -39 -16 -44 -16 -76 -1 -19 9 -33 13 -33 9 4 -21 -3 -34 -14 -29 -8 3
-14 -1 -14 -8 0 -10 5 -11 19 -4 12 6 18 6 18 -1 0 -9 -5 -11 -42 -14 -7 -1
-13 11 -13 27 -2 23 1 27 19 22 12 -3 15 -2 8 1 -6 3 -10 10 -7 15 4 5 0 6 -8
3 -8 -3 -14 2 -14 11 0 8 4 15 9 15 4 0 8 5 8 12 0 6 8 21 17 32 10 12 14 18
9 14 -5 -4 -15 -3 -23 3 -8 8 -13 8 -13 1 0 -6 5 -13 10 -15 6 -3 5 -4 -2 -3
-8 2 -13 20 -13 48 l-1 45 43 -2 c24 -2 46 2 50 8 3 6 1 7 -5 3 -8 -5 -12 3
-11 26 1 30 2 31 8 8 7 -24 7 -24 13 -4 3 11 -1 29 -8 39 -17 22 -18 54 -3 75
8 11 9 7 5 -18 -4 -20 -2 -31 4 -27 6 3 10 2 10 -3 0 -6 7 -12 14 -15 10 -4
13 -1 9 9 -3 7 0 14 7 14 10 0 10 3 0 15 -13 15 -5 39 11 30 10 -7 13 20 10
73 -2 21 3 34 13 38 19 7 22 25 4 21 -6 -1 -14 6 -16 16 -2 9 1 17 7 17 6 0 7
-7 4 -17 -4 -10 -2 -14 5 -9 6 3 13 2 17 -4 4 -6 12 -7 18 -4 7 3 0 -5 -15
-18 -28 -25 -38 -58 -18 -58 6 0 10 5 10 10 0 6 0 12 0 15 0 3 9 14 20 25 24
24 26 40 6 40 -8 0 -17 4 -20 9 -3 4 6 6 19 3 25 -4 31 7 26 47 -2 8 2 10 10
5 9 -5 9 -3 3 7 -5 8 -9 52 -9 100 0 74 2 85 17 86 10 1 18 -2 18 -7 0 -5 9
-14 20 -20 24 -13 26 -7 3 16 -16 16 -16 16 7 9 14 -4 22 -4 18 0 -4 5 -3 14
3 22 9 9 8 13 0 13 -14 0 -15 47 -1 55 6 3 10 1 10 -5 0 -6 5 -8 10 -5 6 4 8
15 5 25 -3 10 1 25 10 33 17 17 20 31 5 22 -5 -3 -10 1 -10 9 0 9 -4 16 -10
16 -5 0 -10 -7 -10 -15 0 -8 4 -15 9 -15 5 0 4 -11 -2 -25 -7 -14 -15 -25 -19
-25 -11 0 -10 18 2 25 6 3 10 11 10 16 0 6 -5 7 -12 3 -11 -7 -10 -4 9 44 3 6
0 12 -6 12 -6 0 -11 8 -11 18 0 15 7 18 33 17 33 -3 61 11 52 26 -3 4 0 18 7
29 9 14 10 23 3 28 -13 9 -14 257 -1 269 5 5 6 -4 2 -20 -5 -20 -3 -27 5 -22
8 5 9 -1 4 -24 -8 -39 2 -78 18 -62 16 16 17 64 2 88 -8 12 -15 30 -15 40 0
11 6 6 17 -15 8 -18 18 -30 21 -28 3 3 0 11 -5 18 -6 7 -13 29 -16 48 -3 19
-3 29 0 22 2 -6 9 -9 14 -6 6 3 8 -6 4 -23 -4 -22 -3 -24 4 -8 12 29 14 205 2
205 -6 0 -8 7 -5 15 4 8 1 15 -5 15 -6 0 -11 -4 -11 -8 0 -4 -13 -13 -29 -18
-25 -10 -28 -15 -25 -48 4 -27 1 -36 -11 -36 -8 0 -15 5 -15 11 0 5 6 7 13 3
9 -6 9 -4 -1 7 -16 17 -16 32 1 23 6 -4 8 -3 4 1 -19 21 -75 6 -61 -16 9 -15
24 -10 18 6 -4 8 -2 15 4 15 5 0 12 -7 16 -15 3 -8 1 -15 -5 -15 -6 0 -8 -4
-4 -9 3 -6 -7 -8 -22 -6 -36 4 -73 -28 -65 -57 4 -15 7 -17 13 -6 7 10 9 10 9
1 0 -7 4 -13 9 -13 5 0 7 9 4 20 -3 11 0 20 6 20 6 0 9 -10 7 -22 -4 -28 -42
-33 -56 -9 -5 9 -10 14 -10 11 -1 -3 -4 -16 -7 -30 -3 -14 -6 -48 -7 -77 0
-28 -4 -47 -7 -41 -5 7 -8 7 -13 0 -3 -6 -2 -13 4 -17 21 -13 9 -23 -25 -20
-19 2 -35 -1 -35 -7 0 -5 -5 -6 -10 -3 -6 4 -7 12 -3 18 3 7 -3 4 -15 -6 -12
-11 -18 -25 -15 -35 5 -11 3 -13 -8 -7 -8 4 -11 5 -7 0 4 -4 2 -15 -5 -23 -9
-13 -9 -14 0 -8 7 4 13 2 13 -3 0 -6 -5 -11 -11 -11 -6 0 -17 -7 -25 -15 -9
-8 -11 -15 -5 -15 12 0 9 -35 -4 -47 -4 -5 -5 -2 -1 5 4 6 2 12 -3 12 -13 0
-13 -3 -5 -24 3 -9 9 -14 13 -11 4 2 8 -11 8 -28 0 -25 -5 -33 -19 -34 -16 -2
-17 0 -3 13 19 20 19 30 -1 38 -12 5 -14 1 -9 -16 6 -18 5 -19 -8 -7 -8 8 -12
18 -9 23 3 5 0 6 -6 2 -9 -5 -9 -13 -2 -26 11 -21 -2 -36 -22 -24 -6 4 -8 3
-4 -4 3 -6 2 -13 -4 -17 -6 -4 -7 -11 -4 -17 7 -11 15 -78 9 -78 -2 0 -8 14
-14 30 -7 22 -7 29 1 24 7 -5 8 -3 3 5 -8 15 -25 2 -29 -22 -1 -8 -8 -22 -15
-31 -7 -8 -10 -20 -6 -26 3 -5 1 -10 -6 -10 -7 0 -9 -3 -6 -6 11 -11 37 17 37
39 0 15 3 18 10 11 12 -12 3 -44 -12 -44 -5 0 -6 -5 -3 -10 4 -6 3 -10 -2 -9
-30 5 -40 -2 -35 -26 3 -18 0 -25 -9 -23 -8 2 -14 -4 -14 -12 -1 -8 -7 -25
-14 -38 -14 -26 -9 -52 11 -52 9 0 9 3 1 11 -12 12 -6 49 9 49 6 0 7 7 4 16
-3 8 -2 13 3 10 5 -3 9 -17 9 -31 0 -13 -4 -22 -9 -19 -5 3 -9 1 -9 -5 0 -5 5
-13 10 -16 19 -12 10 -21 -20 -21 -16 0 -30 4 -30 10 0 17 -19 -7 -23 -29 -2
-11 -8 -30 -15 -41 -6 -12 -8 -24 -5 -28 3 -3 2 -6 -4 -6 -6 0 -13 -7 -16 -14
-4 -10 -1 -13 9 -9 7 3 14 9 14 15 0 5 4 7 9 4 4 -3 8 -36 8 -74 -1 -61 -3
-68 -21 -70 -14 -1 -17 1 -8 7 7 4 15 25 17 47 4 31 3 34 -4 14 -14 -42 -34
-68 -48 -63 -7 3 -13 0 -13 -7 0 -6 -5 -8 -11 -4 -8 4 -8 1 -1 -12 11 -21 11
-74 1 -74 -13 0 -17 28 -6 39 8 8 7 14 -2 24 -9 8 -12 8 -7 0 3 -6 -1 -19 -10
-27 -8 -9 -11 -16 -6 -16 5 0 12 -9 15 -21 3 -12 10 -18 16 -14 6 3 11 1 11
-4 0 -20 -31 -11 -39 11 -6 16 -9 18 -13 6 -4 -9 4 -21 23 -32 37 -22 37 -33
0 -33 -22 0 -27 4 -23 19 2 10 -1 18 -7 18 -7 0 -9 -4 -6 -10 3 -5 2 -10 -3
-10 -11 0 -12 -6 -15 -127 -1 -57 -3 -103 -5 -103 -1 0 -7 10 -12 23 l-9 22 0
-22 c-1 -12 3 -25 9 -28 15 -9 12 -22 -4 -22 -8 0 -12 7 -9 16 4 13 2 13 -10
-2 -8 -9 -21 -17 -30 -17 -9 0 -21 -9 -27 -20 -6 -12 -7 -20 -1 -20 5 0 12 6
14 13 3 6 5 0 4 -15 -1 -27 -17 -41 -17 -16 0 6 -3 9 -6 6 -3 -4 0 -16 6 -28
17 -33 20 -127 3 -127 -7 0 -13 4 -13 8 0 4 -10 5 -23 2 -20 -4 -21 -3 -4 10
21 17 22 34 2 34 -8 0 -13 -3 -10 -5 3 -3 0 -11 -5 -17 -16 -19 -1 -47 21 -40
24 8 25 -10 2 -19 -11 -4 -22 -1 -29 8 -8 12 -10 1 -9 -44 2 -33 -1 -60 -6
-60 -5 0 -9 -5 -9 -11 0 -5 4 -8 8 -5 4 2 9 -15 10 -40 1 -24 -2 -43 -6 -41
-8 2 -12 -7 -12 -25 0 -9 4 -8 10 2 8 13 10 12 10 -2 0 -10 -2 -18 -4 -18 -2
0 -11 -3 -20 -6 -12 -4 -16 0 -16 15 0 12 -4 21 -10 21 -5 0 -10 8 -11 18 0
12 -3 10 -11 -8 -6 -14 -12 -79 -14 -145 -3 -149 -1 -138 -19 -131 -12 4 -14
0 -10 -19 18 -88 18 -82 1 -60 -13 16 -15 17 -10 3 3 -10 1 -18 -5 -18 -6 0
-11 5 -11 12 0 29 -24 -15 -25 -45 -1 -18 -5 -39 -10 -46 -6 -10 -4 -12 4 -6
9 5 12 2 9 -11 -2 -11 -10 -18 -16 -17 -16 4 -15 -5 3 -24 16 -15 18 -120 3
-129 -4 -3 -8 -17 -7 -32 l1 -27 8 25 c6 19 7 14 6 -22 0 -25 -4 -44 -8 -41
-5 2 -8 -6 -8 -19 0 -18 2 -20 10 -8 8 13 10 13 10 -2 0 -9 -4 -20 -10 -23 -5
-3 -10 -14 -10 -23 0 -13 2 -14 9 -3 5 8 8 -36 7 -107 0 -66 -5 -124 -10 -129
-5 -4 -7 -1 -4 7 3 8 1 23 -4 33 -5 10 -5 25 2 37 6 11 8 20 6 20 -3 0 -13
-14 -22 -31 -8 -17 -11 -28 -6 -25 14 9 22 -20 9 -33 -8 -8 -8 -11 2 -11 7 0
9 -5 5 -12 -5 -8 -2 -9 10 -5 14 5 17 3 12 -8 -5 -15 30 -42 42 -31 3 3 -1 6
-8 6 -7 0 -11 2 -8 5 3 3 18 -1 33 -9 25 -13 33 -13 64 0 19 8 41 12 48 10 7
-3 13 -1 13 5 0 6 5 7 10 4 6 -3 10 -1 10 5 0 6 -8 9 -18 6 -15 -5 -18 0 -19
29 0 29 2 32 13 20 8 -8 23 -11 36 -7 22 6 21 7 -7 13 -23 5 -18 7 23 6 29 0
56 -5 60 -11 5 -8 15 -4 29 11 12 13 19 23 15 23 -4 0 -8 8 -9 19 -1 14 3 17
19 13 13 -3 16 -7 8 -12 -7 -4 -2 -7 14 -7 14 0 33 9 43 20 10 11 13 18 7 14
-7 -4 -11 5 -11 23 0 16 5 27 10 23 5 -3 4 4 -3 17 -11 20 -10 21 9 10 12 -6
21 -7 21 -1 0 5 -6 12 -12 14 -10 4 -10 6 -1 6 6 1 15 -4 18 -9 3 -6 11 -10
16 -10 6 0 8 3 6 8 -3 4 6 8 19 8 13 1 24 -2 24 -7 0 -17 48 14 55 35 11 33
11 41 1 48 -5 3 9 5 32 2 33 -3 42 0 42 12 0 9 5 12 12 8 8 -5 8 -3 2 7 -17
27 -11 55 12 55 13 0 20 -4 18 -8 -3 -4 0 -8 5 -8 6 0 11 -4 11 -9 0 -5 -12
-8 -26 -6 -23 2 -24 1 -11 -12 17 -17 32 -11 48 19 9 16 8 19 -3 12 -11 -6
-11 -5 1 9 15 19 36 23 25 5 -4 -6 -3 -8 4 -4 6 3 9 16 7 27 -3 16 0 19 11 15
21 -8 40 4 24 14 -18 11 -48 0 -53 -19 -3 -11 -5 -9 -6 7 -1 14 5 22 17 23 14
0 14 2 -3 9 -16 6 -14 8 12 7 18 0 30 -4 27 -9 -3 -4 -1 -8 4 -8 14 0 25 29
25 66 1 28 4 31 33 30 18 -1 36 -4 40 -8 4 -5 12 -8 18 -8 6 0 4 5 -4 10 -12
8 -10 14 15 42 17 18 23 27 15 21 -13 -10 -18 -8 -30 10 -12 20 -12 21 3 8 14
-11 17 -11 17 0 0 10 3 10 12 1 15 -15 31 -16 22 0 -4 6 -3 8 4 4 5 -3 12 0
15 6 4 10 7 11 18 1 11 -11 10 -13 -4 -13 -9 0 -16 -6 -14 -12 1 -7 -6 -12
-15 -10 -18 3 -24 -9 -11 -22 4 -3 21 8 39 25 23 22 31 38 28 55 -3 17 -1 22
10 18 9 -3 12 -12 9 -22 -5 -11 0 -9 14 5 12 12 23 29 25 40 2 10 10 17 17 15
7 -1 10 2 7 7 -4 5 -2 12 4 16 5 3 10 1 10 -6 0 -7 6 -4 14 6 8 11 12 23 10
27 -5 8 11 12 29 9 5 -1 6 3 2 9 -3 5 0 10 7 11 11 0 11 2 0 6 -7 3 -22 -3
-33 -13 -18 -16 -19 -16 -13 -1 3 9 10 17 15 17 5 0 4 12 -1 26 -9 22 -8 25 4
21 9 -4 18 -1 22 5 4 6 3 8 -2 5 -6 -3 -16 -2 -22 2 -8 4 6 8 31 7 28 0 44 -5
44 -13 0 -7 -5 -9 -10 -6 -6 4 -7 1 -2 -7 5 -9 4 -11 -4 -6 -6 4 -8 12 -5 18
4 6 1 9 -7 6 -8 -3 -13 -12 -13 -21 1 -9 -2 -15 -6 -12 -5 3 -11 1 -15 -4 -3
-6 3 -8 15 -6 11 2 20 -1 20 -7 0 -6 9 1 21 16 14 17 18 33 13 44 -4 9 -8 31
-10 47 -2 27 1 31 22 31 13 0 24 -4 24 -8 0 -4 5 -8 11 -8 5 0 7 6 3 13 -6 9
-3 8 8 -1 13 -10 22 -11 38 -2 12 6 20 13 18 14 -1 1 -11 8 -20 15 -15 11 -17
10 -13 -9 2 -13 1 -19 -3 -16 -4 4 -7 26 -8 49 0 32 2 37 10 23 31 -50 30 -50
72 -9 21 21 40 46 42 56 3 19 -13 23 -24 5 -5 -7 -3 -8 5 -3 10 6 11 3 1 -16
-18 -34 -24 -38 -15 -10 4 13 3 21 -2 18 -5 -3 -9 3 -9 13 0 10 4 16 8 14 4
-3 8 0 8 5 0 6 -6 11 -12 12 -10 0 -10 2 -1 5 6 3 22 -2 36 -10 19 -13 26 -14
41 -3 9 7 18 19 19 27 2 8 6 21 10 28 5 7 4 12 -2 12 -6 0 -11 -4 -11 -10 0
-5 -4 -10 -10 -10 -11 0 -8 35 3 46 4 4 14 1 24 -7 9 -8 14 -9 10 -3 -4 7 2
10 17 7 21 -4 22 -2 18 28 -4 25 -2 30 9 24 11 -6 12 -5 1 6 -16 17 -15 26 2
26 8 0 15 -3 14 -6 0 -3 0 -15 1 -26 1 -18 5 -16 32 13 17 18 28 36 25 39 -3
3 -1 12 5 20 9 10 8 13 -1 13 -8 0 -11 3 -7 6 3 3 11 3 17 -1 8 -5 7 -11 -2
-23 -12 -15 -11 -16 4 -4 10 8 20 22 23 33 4 11 10 17 15 14 16 -10 26 29 24
85 -2 31 1 49 5 41 6 -9 10 -6 15 13 3 14 11 26 17 26 8 0 7 -5 -1 -15 -7 -8
-10 -26 -8 -40 3 -15 1 -25 -6 -25 -7 0 -12 -10 -13 -22 l-2 -23 20 24 c11 14
17 31 14 42 -4 11 -2 19 3 19 5 0 8 14 6 31 -2 18 0 28 6 24 6 -3 7 1 4 10 -4
9 2 25 11 35 10 11 15 20 10 20 -4 0 -1 8 6 17 8 9 9 14 4 11 -7 -4 -11 10
-13 38 -1 24 2 44 7 44 4 0 6 -11 4 -24 -3 -13 0 -27 6 -31 8 -5 10 1 5 21 -5
19 -4 25 5 20 6 -4 11 -4 11 -1 0 9 -27 35 -31 30 -2 -2 -4 10 -4 25 -1 23 2
27 13 18 7 -6 12 -7 11 -2 -1 5 -1 18 0 29 1 16 3 17 11 5 8 -12 10 -12 16 3
3 9 11 14 17 10 6 -3 7 -1 3 6 -6 9 -11 9 -22 0 -11 -9 -14 -8 -14 2 -1 8 -7
3 -15 -11 -8 -14 -15 -23 -15 -20 -10 39 -6 50 18 45 47 -8 60 8 56 72 -3 43
-1 54 7 43 8 -12 9 -9 4 10 -4 14 -8 45 -9 70 -1 38 1 46 16 46 13 0 16 -6 12
-18 -3 -10 0 -26 6 -35 8 -15 9 -13 4 10 -5 22 -3 26 9 21 9 -3 13 -14 11 -27
-4 -21 -4 -21 4 -2 5 11 7 26 4 34 -3 8 -1 12 4 9 5 -4 8 27 8 73 -1 45 2 77
7 73 5 -3 12 2 15 11 4 10 13 13 25 10 20 -7 40 21 31 44 -3 9 0 11 8 6 10 -6
11 -4 4 6 -4 8 -9 20 -10 27 -3 17 14 15 18 -3 2 -10 7 -7 14 10 9 19 9 25 -2
29 -9 3 -11 -1 -7 -12 5 -14 3 -15 -7 -5 -7 7 -14 23 -16 37 -2 22 1 24 27 21
16 -2 27 0 24 5 -3 5 4 14 15 20 11 6 17 16 14 22 -4 6 -1 16 7 22 20 16 50
72 42 80 -3 3 0 6 7 6 7 0 10 3 7 7 -4 3 -3 11 3 18 14 17 0 55 -21 55 -11 0
-19 -4 -19 -10 0 -5 -7 -10 -16 -10 -33 0 -5 25 34 31 27 3 39 2 34 -5 -3 -6
2 -5 11 3 10 7 17 19 16 25 -3 25 2 37 13 30 7 -5 8 -3 3 6 -11 18 -36 -16
-28 -38 4 -9 2 -13 -5 -8 -15 9 -8 61 9 62 8 0 22 -4 33 -9 13 -6 16 -6 12 2
-4 6 -3 11 1 11 5 0 14 9 21 21 8 12 10 19 5 16 -5 -3 -9 5 -9 19 0 13 3 24 6
24 4 0 15 18 25 40 14 30 23 39 36 35 9 -3 21 0 25 6 4 7 3 9 -4 5 -6 -3 -9 0
-8 9 3 18 14 30 29 30 6 0 16 10 23 22 6 13 19 33 30 44 l19 22 -27 -18 c-16
-9 -28 -22 -28 -27 0 -5 -5 -6 -11 -3 -7 5 -4 14 7 26 21 23 12 26 -10 3 -20
-20 -21 -44 0 -44 8 0 13 -4 10 -9 -4 -5 -14 -8 -23 -7 -28 4 -23 57 7 78 17
12 20 18 10 18 -9 0 -21 -5 -28 -12 -9 -9 -12 -9 -12 0 0 7 7 15 15 18 21 8
67 -4 60 -16 -3 -6 1 -7 10 -4 11 5 13 10 6 17 -7 7 -2 16 14 28 26 21 40 25
29 7 -4 -7 -3 -8 5 -4 6 4 9 11 6 16 -3 4 6 15 18 23 21 14 36 54 15 41 -5 -3
-6 -9 -3 -14 3 -5 1 -11 -3 -14 -11 -6 -9 60 1 71 4 4 15 8 23 9 13 2 13 -1
-2 -17 -17 -19 -17 -19 2 -16 10 1 19 5 19 7 0 3 15 19 32 36 18 17 30 34 26
37 -3 4 -1 7 5 7 7 0 12 7 12 17 0 9 3 13 7 10 11 -12 32 14 26 31 -3 9 -3 14
2 10 9 -8 35 16 35 32 0 7 6 10 14 7 9 -4 23 8 38 29 12 18 27 34 31 34 5 0 5
5 1 12 -5 8 -2 9 9 5 20 -7 32 9 32 43 0 26 4 30 33 30 13 -1 11 -4 -8 -15
-24 -14 -24 -16 3 -19 24 -4 54 38 51 72 -2 30 5 40 19 26 3 -4 2 -15 -3 -25
-12 -21 1 -32 26 -22 16 6 16 7 -2 20 -21 16 -11 17 19 2 22 -10 22 -10 2 6
-20 16 -19 16 5 10 28 -7 84 17 72 30 -4 4 3 5 16 2 12 -3 22 -2 22 2 0 4 12
8 26 9 15 1 41 9 57 17 17 8 40 15 51 15 16 1 12 5 -14 17 -21 9 -30 17 -22
20 8 3 19 0 25 -6 14 -14 71 -15 62 -1 -4 6 6 10 22 10 23 0 25 -2 13 -11 -11
-8 -8 -9 13 -4 33 7 70 -2 62 -15 -3 -5 4 -8 16 -7 21 1 21 1 1 18 -19 15 -16
16 46 15 37 0 58 -2 48 -4 -14 -3 -15 -6 -6 -12 7 -4 21 -5 32 -2 15 4 17 7 6
14 -10 8 -10 9 2 5 8 -2 16 -13 18 -25 4 -27 -18 -29 -54 -5 -23 15 -26 15
-42 0 -9 -10 -11 -17 -5 -17 7 0 15 5 18 10 4 6 11 8 16 4 5 -3 7 -10 4 -15
-3 -5 2 -6 11 -2 11 4 15 2 11 -4 -4 -6 4 -8 21 -5 15 3 24 1 20 -3 -8 -9 19
-38 38 -41 8 -1 16 2 20 7 3 5 0 9 -7 9 -7 0 -9 3 -6 6 4 4 14 1 24 -7 11 -9
14 -10 9 -1 -4 7 -1 12 9 12 9 0 14 -4 11 -8 -3 -5 8 -23 24 -40 18 -20 30
-27 33 -19 4 9 6 9 6 0 2 -27 -23 -23 -55 8 -28 27 -36 31 -47 20 -10 -11 -9
-13 3 -9 13 3 64 -37 65 -52 0 -3 4 -4 8 -2 4 1 18 -4 30 -13 13 -9 16 -15 7
-15 -9 0 -3 -11 15 -30 15 -16 31 -28 34 -27 11 3 51 -24 51 -35 0 -6 -6 -5
-17 3 -15 13 -15 12 -2 -9 8 -12 15 -18 17 -12 2 6 8 8 13 4 14 -8 11 16 -3
30 -7 7 -22 15 -32 19 -23 9 -15 19 19 22 18 2 30 -4 42 -21 8 -14 13 -27 9
-31 -3 -3 -6 -1 -6 5 0 7 -4 12 -9 12 -5 0 -7 -9 -4 -20 3 -11 9 -17 14 -14 5
3 4 -7 -1 -21 -13 -34 -13 -38 2 -29 8 5 9 2 5 -9 -4 -10 1 -21 12 -29 10 -8
28 -29 38 -48 18 -31 24 -35 64 -35 36 0 44 -4 47 -20 l4 -20 -17 20 c-9 11
-14 14 -10 8 3 -7 1 -21 -5 -30 -9 -16 -10 -15 -5 4 5 20 3 23 -19 21 -13 -1
-22 -6 -20 -10 3 -5 10 -5 16 -2 7 5 9 1 5 -9 -3 -9 0 -18 9 -21 9 -3 12 -10
9 -16 -3 -6 1 -13 9 -16 9 -3 13 -11 9 -17 -3 -6 -1 -7 5 -3 8 5 8 15 2 32
-13 34 -12 41 3 21 6 -9 27 -17 46 -19 34 -3 58 -28 27 -28 -9 0 -16 5 -16 10
0 20 -38 10 -43 -12 -7 -25 17 -66 28 -48 4 6 2 17 -4 24 -6 7 -8 16 -4 19 24
25 39 -30 15 -57 -9 -11 -11 -16 -4 -12 6 4 13 2 13 -3 2 -12 5 -26 9 -38 1
-5 2 -11 2 -14 0 -3 9 -16 21 -30 12 -14 19 -20 14 -13 -11 17 2 42 21 39 9
-1 16 -10 16 -19 -1 -8 3 -13 8 -10 5 3 7 12 4 20 -3 8 -1 14 4 14 12 0 10
-62 -3 -77 -5 -8 -5 -13 0 -13 5 0 9 -26 10 -57 1 -81 5 -130 10 -115 3 6 9
10 14 6 13 -8 11 -74 -3 -74 -6 0 -9 -3 -6 -6 4 -3 16 -1 27 6 14 7 21 7 21 0
0 -5 10 -10 23 -9 l22 0 -22 9 c-13 5 -23 17 -24 27 0 16 -1 16 -9 -2 -8 -18
-9 -17 -9 7 -1 20 3 26 13 22 11 -4 13 0 9 18 -4 18 -3 20 6 8 6 -8 8 -27 6
-41 -5 -25 -2 -27 41 -33 25 -3 58 -3 72 0 22 5 25 4 15 -6 -12 -13 -29 -17
-88 -20 -30 -1 -30 -1 -6 -11 16 -6 35 -6 53 0 16 6 36 8 46 5 15 -5 15 -4 2
6 -12 9 -9 11 15 7 27 -5 28 -4 10 9 -25 18 -12 18 25 -1 16 -8 31 -11 34 -6
3 4 25 11 49 14 23 3 56 13 72 21 17 9 39 16 50 15 l20 0 -20 -9 c-16 -7 -17
-9 -3 -9 9 -1 20 3 23 9 3 5 18 10 33 10 19 0 23 3 13 9 -15 10 -87 3 -113
-11 -12 -5 -18 -5 -18 2 0 5 6 10 13 10 8 0 7 3 -3 10 -29 18 3 25 118 26 70
0 103 -2 82 -6 -33 -7 -32 -8 13 -7 26 0 47 4 47 9 0 5 4 7 8 4 15 -9 31 60
18 75 -8 9 -7 11 7 6 11 -4 17 -1 18 8 2 23 4 29 11 41 4 6 12 22 17 36 7 19
6 25 -5 25 -8 0 -12 -7 -9 -16 2 -9 1 -21 -3 -28 -4 -6 -8 20 -8 58 -1 38 3
69 7 69 8 0 13 -23 12 -57 0 -6 6 -9 13 -6 16 6 19 23 3 23 -5 0 -7 5 -4 10 4
6 10 8 15 5 4 -3 11 2 14 11 3 8 2 12 -4 9 -6 -3 -10 4 -11 17 -1 23 -1 23 -9
-2 -6 -19 -8 -20 -9 -6 -1 12 -6 16 -16 12 -17 -6 -19 2 -5 24 9 13 10 13 10
0 0 -9 3 -11 8 -5 12 17 41 14 34 -3 -5 -14 -4 -15 10 -2 8 8 13 21 11 27 -3
7 0 13 7 13 6 0 9 3 6 8 -12 20 -16 92 -5 92 8 0 8 4 -1 15 -14 16 -10 18 15
9 14 -6 14 -8 1 -23 -11 -13 -13 -24 -7 -46 l9 -30 1 30 c1 17 7 37 14 44 10
10 8 15 -7 27 -11 7 -16 17 -13 21 4 4 13 1 19 -5 7 -7 16 -12 22 -12 5 0 6 3
3 7 -4 3 -1 13 6 21 10 13 8 14 -13 8 -23 -6 -23 -6 -7 7 9 8 17 22 17 31 0 9
5 16 11 16 5 0 7 5 4 11 -9 13 24 51 38 42 6 -3 7 -1 3 6 -11 17 2 62 25 91
17 21 20 21 14 5 -5 -16 -4 -17 6 -8 8 8 9 16 2 25 -12 17 -15 58 -3 58 5 0 7
-11 4 -25 -3 -15 0 -25 7 -25 7 0 9 3 6 7 -4 3 1 12 11 20 16 12 16 13 -2 6
-14 -4 -17 -3 -12 6 5 7 1 17 -9 25 -11 8 -15 22 -13 41 l4 30 12 -35 12 -35
-3 38 c-2 20 1 37 5 37 4 0 9 -12 9 -27 1 -21 3 -23 6 -8 3 11 12 24 20 30 8
6 15 25 17 43 1 17 6 35 11 39 4 4 5 2 1 -4 -4 -7 -2 -13 3 -13 6 0 11 4 11 9
0 6 9 9 19 8 11 0 17 -6 14 -12 -4 -5 2 -3 13 6 10 9 26 19 34 23 9 4 7 5 -6
2 -17 -3 -21 2 -21 25 0 25 3 28 26 23 20 -4 23 -3 11 5 -18 12 -4 13 25 1 21
-8 16 -12 -30 -24 -11 -3 -5 -4 13 -2 17 1 32 -1 32 -6 0 -4 -7 -8 -15 -8 -8
0 -15 -5 -15 -11 0 -5 5 -7 11 -3 6 3 15 1 19 -6 5 -8 49 -12 143 -13 131 -2
159 -8 96 -22 -23 -5 -28 -9 -18 -16 8 -5 21 -9 29 -9 13 0 13 1 0 11 -11 7
-4 8 25 3 50 -8 51 -10 34 -31 -8 -9 -9 -14 -2 -10 6 4 14 3 18 -2 3 -6 13 -7
22 -4 12 5 14 3 8 -7 -5 -9 -4 -11 3 -6 7 4 20 9 31 11 15 3 18 -1 14 -18 -4
-18 -3 -19 6 -7 10 13 15 8 12 -13 0 -4 2 -6 6 -6 30 5 55 -3 50 -15 -3 -8 5
-18 19 -25 14 -6 23 -8 20 -3 -2 4 8 9 24 10 16 1 32 -2 35 -8 4 -6 11 -8 16
-5 5 4 17 8 27 10 16 3 19 -3 17 -31 -1 -19 2 -37 7 -40 14 -9 9 -24 -6 -18
-7 3 -13 15 -12 26 0 12 -6 23 -14 25 -12 4 -12 3 -1 -5 8 -6 9 -12 3 -16 -5
-3 -15 1 -22 8 -9 12 -7 17 8 25 11 6 23 7 27 3 5 -4 5 -1 1 6 -5 8 -13 9 -25
2 -10 -5 -22 -6 -26 -2 -5 4 -5 1 -1 -5 4 -7 3 -13 -3 -13 -16 0 -22 18 -9 23
7 3 3 4 -9 4 -13 -1 -23 -2 -23 -3 0 -1 25 -22 56 -47 35 -30 61 -44 71 -40
12 5 14 3 8 -7 -5 -9 -4 -11 4 -6 8 6 9 12 2 20 -8 10 -4 13 16 11 l27 -1 2
-89 c3 -123 -2 -146 -29 -141 -12 3 -25 5 -29 5 -4 1 -4 9 -1 18 6 15 5 16 -9
3 -11 -11 -12 -17 -4 -22 11 -8 1 -12 -18 -9 -5 1 -5 -4 1 -11 13 -16 13 -49
0 -65 -6 -9 -6 -13 1 -13 5 0 10 -17 10 -37 0 -28 -2 -33 -8 -18 -4 11 -5 25
-3 31 2 7 -1 15 -8 20 -9 5 -11 -2 -6 -30 5 -34 4 -36 -10 -24 -14 11 -16 11
-9 -1 6 -9 5 -12 -4 -9 -8 2 -14 14 -15 27 -1 15 3 20 12 17 9 -4 11 -1 7 6
-4 6 1 17 11 24 10 8 15 17 11 21 -10 11 -55 -49 -48 -66 3 -8 1 -12 -5 -8
-15 9 -25 -21 -14 -41 11 -21 10 -103 -1 -96 -4 3 -5 20 -3 38 4 24 2 33 -8
33 -9 0 -13 -8 -11 -21 3 -12 2 -15 -2 -8 -3 6 -10 9 -15 6 -5 -3 4 -20 21
-37 32 -33 36 -42 19 -52 -14 -9 -38 25 -32 45 3 10 1 9 -7 -3 -6 -9 -17 -15
-23 -11 -7 3 -1 -4 13 -16 22 -19 22 -20 3 -11 -30 15 -30 15 -42 -20 -16 -45
-12 -61 4 -19 12 33 13 31 10 -45 -2 -44 -4 -81 -4 -83 -1 -2 -16 7 -33 19
-33 23 -33 23 -14 1 20 -23 9 -31 -13 -9 -10 8 -12 8 -7 0 3 -6 2 -14 -3 -18
-6 -3 -7 -13 -4 -22 5 -12 3 -14 -7 -8 -9 6 -11 4 -5 -5 12 -19 17 -80 7 -80
-5 0 -8 14 -6 30 3 25 1 28 -10 18 -8 -8 -11 -21 -6 -33 4 -11 6 -26 4 -33 -1
-7 0 -8 3 -2 2 5 9 7 15 4 5 -3 0 -13 -11 -21 -12 -8 -21 -21 -21 -29 0 -12
-18 -19 -42 -15 -3 0 -5 -7 -4 -17 2 -9 -6 -33 -17 -52 -30 -53 -65 -153 -59
-169 3 -9 0 -11 -8 -6 -9 6 -11 4 -5 -5 9 -15 19 -90 12 -90 -19 0 -35 14 -30
27 5 12 7 12 14 1 6 -9 9 -9 9 -1 0 20 -18 24 -29 6 -7 -13 -5 -23 7 -37 16
-18 16 -18 -8 -12 -19 6 -21 5 -10 -3 8 -6 16 -16 16 -23 1 -7 3 -20 4 -28 1
-8 8 3 15 25 l13 40 -1 -47 c-2 -44 -4 -47 -28 -45 -17 1 -25 7 -22 15 3 6 -1
12 -8 12 -10 0 -10 3 -1 12 7 7 12 16 12 22 0 5 -6 1 -14 -9 -8 -11 -12 -23
-9 -28 2 -4 -3 -19 -11 -32 -9 -14 -13 -25 -8 -25 4 0 9 -26 9 -57 2 -75 7
-96 24 -101 11 -3 11 -1 -1 7 -17 12 -16 12 22 15 15 2 25 -1 22 -5 -3 -5 2
-6 10 -3 8 3 17 12 20 20 3 8 2 13 -2 10 -11 -7 -8 31 3 41 4 5 5 3 1 -4 -3
-6 -1 -15 5 -19 8 -4 9 -3 5 4 -4 7 -1 12 6 13 7 0 4 5 -8 10 -16 8 -20 19
-20 52 l1 42 45 1 c25 0 39 -1 33 -3 -7 -3 -13 -12 -13 -21 1 -14 3 -14 13 3
6 11 12 22 12 25 0 3 5 12 10 20 12 19 15 61 3 54 -4 -3 -8 0 -8 6 0 16 31 25
40 11 5 -9 9 -8 13 4 4 10 3 14 -4 9 -8 -4 -9 1 -4 17 5 21 2 20 -27 -11 l-34
-36 -1 36 c0 19 2 29 4 23 7 -19 27 -16 43 7 11 15 17 17 28 8 11 -10 18 -7
33 12 20 27 25 55 10 64 -4 3 -6 -6 -3 -21 3 -19 1 -24 -7 -18 -7 4 -10 13 -7
21 3 7 0 16 -6 20 -7 5 -8 2 -3 -6 5 -9 4 -11 -3 -6 -7 4 -12 12 -12 17 0 10
42 5 61 -7 8 -4 9 -3 5 4 -4 7 -4 12 1 12 4 0 16 11 26 25 12 17 14 26 5 31
-6 4 -8 3 -4 -4 3 -6 3 -13 -2 -16 -4 -2 -7 11 -7 29 0 18 3 31 8 28 11 -6 9
11 -3 23 -6 6 -8 17 -5 26 5 13 17 15 70 9 6 -1 3 -5 -5 -11 -9 -5 -19 -5 -27
2 -7 6 -13 8 -13 6 0 -3 10 -13 23 -24 12 -10 19 -15 15 -10 -4 5 -1 14 7 20
17 12 30 60 14 51 -13 -9 -11 19 3 33 7 7 8 12 2 12 -5 0 -9 15 -10 33 -1 17
1 26 3 19 3 -6 9 -9 15 -6 5 3 2 15 -9 27 -18 21 -18 21 8 5 23 -15 27 -15 46
1 16 12 20 24 17 52 l-4 37 39 -1 c23 -1 37 -5 34 -10 -3 -6 -1 -7 5 -3 7 4
12 12 12 18 0 7 9 21 20 33 19 20 19 21 1 21 -10 0 -17 -6 -14 -12 2 -7 0 -15
-5 -18 -5 -3 -9 23 -9 62 1 60 3 68 20 70 15 1 17 -2 8 -11 -6 -6 -11 -16 -11
-21 0 -6 5 -5 12 2 7 7 21 11 32 11 18 -1 18 -2 3 -6 -17 -4 -53 -61 -44 -70
3 -2 21 10 40 27 38 33 40 59 3 61 -16 1 -17 2 -3 6 9 2 17 13 17 24 0 12 4
17 11 14 6 -4 8 -13 6 -19 -3 -7 -1 -19 4 -27 6 -9 8 -3 5 19 -2 19 -9 39 -15
45 -19 19 -12 24 34 22 l44 -3 -5 38 c-3 21 -1 41 4 44 6 4 9 -8 8 -29 -2 -19
1 -32 6 -29 5 3 6 15 2 26 -5 17 -3 20 11 14 14 -5 15 -4 7 7 -8 10 -6 13 10
14 28 2 37 19 33 60 -1 18 1 33 5 33 14 0 19 -27 7 -41 -10 -11 -9 -11 5 0 13
10 15 18 8 31 -6 12 -5 23 5 36 14 18 15 17 11 -8 -3 -16 -1 -28 4 -28 10 0
43 50 33 50 -5 0 0 5 10 11 9 6 15 14 12 19 -3 5 4 12 16 15 16 5 18 10 9 18
-10 9 -14 9 -17 -1 -3 -6 -9 -10 -13 -7 -5 3 -7 -4 -5 -15 4 -20 -5 -24 -35
-20 -11 1 -9 -3 5 -15 19 -15 18 -15 -12 0 -26 12 -31 12 -27 2 3 -8 -1 -19
-8 -25 -10 -8 -13 -4 -13 17 0 26 3 28 38 27 35 -2 42 3 38 32 -1 8 3 20 10
28 9 11 9 14 -1 14 -8 0 -10 3 -7 7 4 3 16 -3 28 -15 20 -23 29 -22 62 6 19
17 22 32 6 32 -5 0 -8 -4 -5 -9 8 -13 -21 -34 -35 -25 -8 5 -9 3 -4 -6 5 -9 4
-11 -3 -6 -20 12 -13 26 11 23 19 -2 22 2 21 35 -1 21 2 35 7 33 4 -3 9 1 12
7 2 7 7 10 11 7 4 -4 0 -14 -9 -22 -8 -9 -11 -18 -7 -21 5 -3 15 7 22 22 8 16
14 22 17 14 3 -7 -1 -19 -7 -28 -7 -8 -10 -17 -6 -21 3 -3 6 -2 6 4 0 17 92
108 106 105 7 -1 15 2 19 8 3 6 0 7 -9 4 -9 -3 -16 -1 -16 4 0 16 171 12 177
-4 2 -7 11 -15 19 -18 12 -4 12 -2 4 14 -6 11 -7 20 -1 20 8 0 25 -29 23 -41
0 -3 14 -3 31 0 l32 4 -33 9 c-40 11 -33 28 11 27 l32 -1 -30 -8 -30 -8 36 -1
c19 0 40 4 46 10 9 9 12 4 11 -20 -1 -26 -3 -29 -16 -18 -13 10 -15 9 -9 -5 7
-19 -7 -25 -18 -8 -3 6 -11 10 -17 10 -17 0 10 -27 32 -33 11 -3 20 -1 20 4 0
5 10 5 23 0 12 -4 31 -7 43 -6 11 1 21 -5 21 -12 0 -16 -9 -17 -25 -1 -9 9
-12 9 -12 1 0 -7 9 -15 20 -18 11 -4 18 -11 15 -16 -4 -5 1 -9 9 -9 9 0 16 -7
16 -15 0 -9 6 -12 17 -8 12 5 14 3 8 -8 -7 -11 -6 -12 6 -3 18 14 49 14 46 0
-1 -5 7 -11 18 -13 15 -3 16 -2 7 5 -11 7 -11 10 3 15 9 4 13 3 10 -3 -3 -6
-2 -10 4 -10 5 0 12 6 15 13 2 6 3 -4 0 -25 -6 -41 5 -53 50 -53 27 0 29 -2
30 -43 1 -37 0 -40 -11 -26 -7 10 -13 13 -13 7 0 -6 7 -16 15 -23 8 -7 15 -9
15 -4 0 4 7 -1 16 -12 14 -18 15 -18 4 4 -17 34 3 26 23 -10 12 -20 15 -34 8
-36 -7 -2 -5 -13 5 -28 9 -13 20 -22 24 -19 5 3 11 1 15 -5 3 -5 1 -10 -6 -10
-8 0 -7 -5 2 -17 8 -9 14 -26 15 -37 1 -12 4 -28 8 -37 5 -10 2 -16 -8 -16 -8
0 -13 4 -10 9 3 4 0 8 -5 8 -10 0 -16 -35 -12 -67 1 -6 -3 -14 -9 -18 -8 -5
-7 -12 2 -23 12 -13 10 -14 -9 -4 -30 17 -41 15 -17 -2 15 -12 17 -17 7 -27
-16 -16 -17 -29 -3 -29 6 0 10 6 10 14 0 8 4 18 10 21 6 3 9 -4 8 -18 -2 -18
-8 -23 -30 -23 -31 0 -34 6 -16 24 9 9 9 15 -1 25 -8 7 -11 7 -8 1 4 -7 -4
-28 -18 -48 -13 -19 -20 -38 -15 -41 13 -8 13 -35 0 -35 -5 0 -6 8 -3 17 6 15
5 16 -9 3 -11 -10 -12 -17 -4 -26 6 -7 13 -10 16 -9 3 2 5 -14 5 -36 0 -35 -2
-39 -22 -36 -12 2 -25 6 -29 10 -4 3 3 4 15 2 14 -2 21 0 19 7 -5 14 -48 21
-48 8 0 -6 1 -12 3 -13 1 -2 -3 -10 -9 -18 -6 -8 -10 -46 -10 -84 l1 -70 -30
-1 c-16 -1 -24 1 -17 3 15 6 16 29 0 39 -7 5 -8 3 -4 -5 5 -7 1 -18 -9 -25
-14 -10 -17 -31 -18 -113 -1 -95 -3 -103 -24 -114 -12 -7 -20 -16 -17 -21 6
-10 1 -10 -20 -2 -9 3 -13 11 -9 18 4 6 -1 5 -10 -3 -9 -7 -13 -18 -9 -25 9
-14 12 -91 5 -129 -4 -23 -9 -27 -33 -25 -25 2 -27 5 -18 23 5 11 -1 7 -16
-10 -14 -16 -23 -37 -20 -45 10 -28 9 -35 -4 -32 -8 1 -11 -2 -8 -7 3 -5 -1
-14 -10 -21 -8 -7 -11 -16 -6 -19 5 -3 13 -17 17 -29 6 -20 4 -23 -12 -20 -11
2 -17 11 -15 23 2 16 1 17 -7 5 -5 -8 -7 -18 -5 -22 3 -4 0 -8 -5 -8 -6 0 -11
-7 -11 -15 0 -9 -6 -28 -14 -44 -10 -19 -12 -31 -4 -42 8 -12 9 -11 5 2 -3 9
-1 22 4 30 6 9 8 -4 5 -36 -2 -27 -4 -51 -5 -53 -1 -1 -9 2 -19 7 -13 7 -19 6
-20 -2 -6 -51 -14 -80 -25 -91 -18 -19 -10 -87 9 -79 9 3 14 -2 14 -16 0 -22
14 -28 25 -11 4 6 15 8 25 5 19 -6 35 19 23 38 -4 7 -10 2 -14 -13 -5 -16 -6
-7 -4 23 4 36 1 49 -10 53 -8 4 -13 10 -10 15 3 4 3 20 -1 34 -5 20 -9 24 -18
15 -8 -8 -7 -13 3 -19 14 -9 9 -41 -7 -41 -10 0 -8 67 2 77 11 11 36 -26 36
-53 0 -12 7 -27 15 -34 9 -7 14 -19 11 -26 -9 -22 9 -24 23 -2 7 11 8 17 3 14
-6 -3 -12 1 -15 9 -4 9 -1 12 7 9 19 -7 46 23 39 43 -5 12 -3 14 7 8 10 -6 11
-4 4 6 -5 8 -9 34 -10 60 0 37 3 45 18 46 10 1 16 -2 13 -8 -9 -13 9 -11 24 4
11 10 11 8 2 -10 -6 -13 -9 -23 -6 -23 13 0 32 64 29 97 -2 28 1 33 13 29 18
-7 46 12 37 25 -3 5 0 9 7 9 7 0 10 3 7 6 -4 3 -18 -2 -32 -11 -14 -10 -27
-13 -27 -9 -7 43 -5 50 12 50 16 0 17 -2 3 -17 -13 -15 -12 -15 21 -5 27 8 31
12 17 18 -14 5 -13 7 5 7 25 1 37 16 27 33 -14 23 -11 68 4 68 12 0 12 -3 2
-15 -11 -13 -10 -14 4 -9 21 8 21 -5 -1 -22 -17 -13 -17 -14 0 -8 10 4 25 20
33 36 9 17 11 27 5 24 -11 -7 -15 6 -12 33 2 7 -3 10 -9 6 -6 -4 -8 -15 -5
-26 5 -14 2 -17 -9 -13 -9 3 -12 12 -9 21 4 11 2 14 -5 9 -7 -4 -12 -1 -12 6
0 9 13 14 39 13 25 0 36 -4 30 -10 -5 -5 -9 -13 -9 -18 0 -5 7 -2 15 6 8 9 13
27 10 46 -2 17 1 31 6 31 6 0 7 5 3 12 -17 28 -12 55 14 71 17 10 21 17 11 17
-8 0 -21 -6 -28 -12 -11 -11 -13 -10 -9 2 3 10 18 15 44 15 35 0 39 2 36 23
-2 12 2 22 9 22 9 0 9 3 0 14 -9 11 -8 15 6 20 10 4 19 2 21 -4 3 -7 9 -7 20
2 8 7 18 12 21 12 3 -1 5 2 3 7 -1 5 2 9 8 9 5 0 10 8 10 18 0 11 -3 13 -8 5
-4 -6 -8 17 -10 51 l-2 62 32 -1 c41 -1 58 10 53 35 -2 11 1 22 6 25 5 4 6 11
3 17 -4 7 -2 8 5 4 9 -6 9 -11 1 -21 -9 -11 -7 -14 11 -12 31 2 59 52 54 97
-3 27 0 37 11 37 17 0 18 -9 2 -26 -11 -10 -10 -11 2 -6 9 3 21 15 29 27 11
18 11 20 -3 15 -21 -8 -20 3 5 33 l20 25 -6 -24 c-5 -16 -3 -22 5 -19 8 2 13
24 14 55 1 28 5 55 10 60 5 5 6 3 2 -4 -9 -15 10 -18 19 -3 4 6 11 8 16 4 6
-3 22 6 36 20 14 14 22 26 18 26 -4 0 -2 6 4 14 7 9 8 17 2 20 -5 3 -11 1 -13
-6 -3 -8 -7 -6 -11 5 -5 12 -1 17 13 17 10 0 21 4 25 10 3 5 11 7 18 4 7 -2
24 16 40 42 15 25 22 42 16 38 -7 -4 -10 -1 -7 7 3 8 12 13 20 11 8 -1 23 8
34 20 13 14 14 19 4 14 -8 -4 -6 0 4 8 15 12 16 16 5 16 -9 0 -15 9 -15 24 0
14 4 22 8 20 4 -3 8 0 8 6 0 6 6 10 13 9 29 -4 43 2 52 24 6 12 16 21 22 20 7
-2 15 6 18 17 4 11 10 18 15 15 10 -6 19 7 25 32 2 10 7 27 11 37 5 14 4 17
-5 11 -10 -6 -11 -2 -6 16 8 26 48 69 64 69 5 0 11 6 14 13 2 6 4 0 4 -15 0
-19 -4 -25 -14 -21 -7 3 -23 -3 -33 -12 -21 -19 -27 -45 -10 -45 6 0 3 -12 -6
-27 -14 -24 -11 -22 16 7 44 48 63 77 56 89 -4 5 7 24 23 42 17 18 28 36 25
41 -3 4 -1 8 5 8 23 0 19 -21 -9 -54 -21 -24 -27 -37 -19 -42 7 -4 10 -3 7 2
-6 12 38 54 48 45 4 -3 4 2 1 12 -4 9 -2 17 3 17 6 0 10 -6 10 -12 1 -7 8 -2
16 12 11 18 14 20 10 5 -5 -18 -5 -18 6 -4 11 13 33 16 115 15 56 -1 105 -4
109 -9 12 -11 26 -8 18 5 -4 6 -3 8 4 4 11 -7 9 -37 -4 -58 -4 -7 -3 -8 4 -4
7 4 12 3 12 -2 0 -12 80 -46 99 -43 8 1 10 -2 5 -10 -4 -7 -3 -10 4 -5 6 3 18
-4 26 -16 9 -12 16 -18 16 -13 1 6 12 -3 25 -20 19 -23 27 -27 37 -17 14 14
32 16 23 2 -3 -5 5 -19 19 -31 14 -12 23 -26 20 -31 -3 -5 -2 -7 3 -6 5 2 24
-14 42 -34 18 -21 36 -36 40 -35 7 4 40 -28 42 -40 4 -22 37 -50 56 -47 17 3
21 -1 19 -20 0 -13 -4 -22 -8 -19 -5 2 -8 -1 -8 -6 0 -6 4 -11 8 -11 13 0 11
-33 -2 -49 -8 -11 -8 -13 2 -7 6 4 12 2 12 -3 0 -6 -7 -11 -15 -11 -20 0 -59
-76 -43 -86 7 -4 8 -3 4 4 -10 17 -1 15 13 -2 10 -12 8 -14 -11 -12 -17 2 -27
-4 -35 -21 -6 -13 -7 -20 -1 -17 7 5 6 -97 -1 -123 -3 -11 -20 1 -29 21 -9 21
-8 26 6 30 9 3 18 17 18 30 2 20 -1 22 -11 13 -7 -7 -10 -18 -7 -26 3 -9 1
-12 -6 -7 -12 7 -16 -5 -13 -33 2 -8 -2 -10 -10 -5 -8 4 -10 3 -5 -5 4 -6 12
-9 17 -6 5 4 9 1 9 -5 0 -7 -8 -9 -20 -5 -22 7 -22 7 -25 -65 -1 -41 -4 -45
-25 -42 -19 2 -21 0 -10 -12 6 -9 9 -21 5 -28 -4 -7 0 -5 8 5 18 22 26 22 24
-1 -1 -14 -9 -19 -34 -19 -42 0 -44 -3 -46 -51 -1 -22 -5 -44 -10 -48 -9 -10
-9 -27 1 -21 4 3 8 -25 8 -61 1 -45 -1 -59 -6 -42 -5 15 -8 18 -9 8 -1 -9 -6
-20 -13 -24 -8 -5 -9 -2 -5 9 3 9 3 27 -2 39 -7 19 -8 19 -14 4 -4 -10 -11
-14 -17 -11 -6 4 -7 -1 -3 -11 4 -10 2 -15 -3 -11 -6 3 -15 -7 -21 -24 -6 -16
-8 -29 -3 -29 5 0 10 6 13 13 3 6 5 -16 5 -50 -1 -48 -5 -66 -19 -79 -11 -10
-19 -12 -19 -7 0 6 6 16 14 22 16 13 17 74 2 88 -5 4 -6 0 -3 -9 4 -10 3 -18
-1 -18 -4 0 -7 -14 -8 -30 -1 -33 -10 -48 -21 -36 -4 3 -1 12 5 18 7 7 12 17
12 23 0 5 -9 -3 -20 -20 -11 -16 -20 -43 -20 -58 0 -18 -4 -26 -11 -22 -6 4
-5 -2 3 -14 10 -17 10 -23 0 -33 -11 -11 -12 -10 -7 5 4 10 2 17 -4 17 -6 0
-10 -3 -9 -7 1 -5 -3 -19 -10 -33 -12 -23 -12 -24 2 -6 9 11 16 14 16 7 0 -7
5 -9 10 -6 6 3 10 2 10 -3 0 -5 -9 -12 -21 -15 -11 -3 -19 -11 -18 -19 5 -24
-11 -55 -34 -66 -22 -11 -22 -11 -1 -11 15 -1 27 10 38 32 10 20 16 25 16 14
0 -22 -46 -70 -59 -62 -5 3 -13 0 -17 -6 -4 -7 -3 -9 3 -6 6 4 10 -23 10 -73
l-1 -80 -33 4 c-41 5 -46 -3 -49 -78 -1 -34 -5 -74 -8 -91 -5 -23 -4 -25 3
-10 7 16 9 12 8 -20 -1 -36 -4 -40 -27 -40 -14 0 -30 3 -36 7 -12 8 -14 -6
-14 -64 1 -21 -4 -38 -10 -38 -6 0 -8 5 -4 12 4 7 3 8 -4 4 -12 -7 -5 -36 9
-36 10 0 5 -117 -6 -128 -4 -4 -4 4 0 18 l7 25 -25 -24 c-14 -12 -30 -20 -36
-16 -11 6 -13 1 -15 -50 0 -16 -6 -29 -12 -28 -8 1 -9 -10 -2 -40 13 -64 15
-97 6 -97 -4 0 -8 -6 -8 -13 0 -9 -11 -13 -30 -12 -33 2 -32 5 -34 -110 -1
-42 -5 -59 -14 -57 -14 2 -6 -49 9 -58 12 -8 -7 -33 -20 -26 -14 9 -14 4 3
-29 l14 -27 5 23 c3 19 5 18 6 -8 1 -26 -2 -30 -18 -26 -19 5 -39 54 -32 78 1
6 -3 4 -10 -2 -10 -10 -10 -18 -1 -38 17 -36 14 -54 -4 -30 -11 14 -15 15 -14
5 1 -8 2 -33 1 -55 -1 -31 -4 -37 -12 -25 -9 12 -9 11 -3 -5 4 -11 6 -31 5
-45 -1 -14 -2 -46 -3 -72 -1 -26 -5 -45 -10 -42 -4 3 -8 12 -8 20 0 17 -16 19
-17 2 0 -7 -3 -26 -6 -42 -5 -21 -3 -26 5 -18 12 12 41 4 32 -9 -3 -5 -9 -7
-13 -4 -5 2 -16 -4 -25 -14 -17 -18 -17 -20 5 -31 19 -11 21 -10 15 5 -4 12
-2 16 8 12 11 -4 13 -1 9 10 -4 10 -2 14 4 10 6 -4 16 -1 22 7 14 17 -4 33
-37 30 -33 -2 -17 9 18 13 17 1 30 -2 30 -8 0 -8 5 -7 15 1 8 7 15 18 15 25 0
7 11 20 25 29 14 9 24 22 22 29 -1 6 4 11 12 9 12 -3 12 -1 0 14 -12 16 -12
17 3 11 10 -3 22 1 30 11 7 9 8 13 2 9 -7 -4 -15 0 -18 9 -3 9 -2 12 5 9 15
-10 39 14 32 32 -4 11 -2 14 5 9 7 -4 12 -2 12 3 0 6 -4 11 -8 11 -5 0 -8 15
-8 33 1 22 9 37 26 49 17 12 20 18 9 18 -8 0 -20 -5 -27 -12 -9 -9 -12 -9 -12
0 0 7 7 15 15 18 18 7 65 -3 64 -14 -3 -16 2 -23 11 -17 7 4 7 12 0 25 -7 13
-7 21 2 26 6 4 8 3 4 -4 -3 -6 -2 -13 3 -17 10 -5 64 47 62 59 -2 9 -16 7 -16
-2 0 -16 -34 -42 -40 -32 -3 6 1 13 10 16 8 4 14 14 13 22 -2 11 4 17 17 17
11 0 26 4 34 9 12 8 12 11 -2 21 -9 6 -14 20 -13 31 2 15 11 19 37 18 24 -1
38 5 48 19 21 30 23 73 5 91 -18 19 -6 21 17 3 9 -7 14 -19 12 -27 -3 -8 4 -4
15 9 11 14 17 31 14 39 -4 12 -8 11 -19 -4 -12 -16 -15 -17 -23 -4 -5 8 -6 18
-3 24 4 5 -1 7 -11 3 -9 -4 -15 -3 -11 2 3 6 14 9 25 8 14 -1 16 -4 8 -14 -15
-18 2 -16 23 3 13 12 19 12 25 3 5 -8 3 -10 -6 -4 -9 5 -11 4 -6 -4 6 -9 11
-9 22 0 7 6 12 15 9 19 -3 5 -1 10 4 12 5 1 3 17 -5 33 -8 17 -13 23 -11 15 3
-8 1 -24 -4 -35 -6 -15 -7 -12 -6 14 2 28 7 35 25 36 15 1 19 -2 12 -10 -5 -6
-5 -16 0 -25 8 -13 10 -13 10 1 0 9 6 19 14 22 8 3 20 16 26 28 7 12 8 19 3
16 -5 -3 -9 25 -9 62 l-1 67 34 0 c28 0 32 -3 28 -21 -3 -11 -2 -14 1 -7 3 6
9 10 13 8 3 -3 14 9 23 25 15 25 15 29 2 29 -8 0 -13 -4 -10 -8 2 -4 1 -14 -3
-20 -4 -8 -8 6 -7 31 0 28 5 44 13 44 7 0 10 -4 7 -9 -3 -4 0 -8 5 -8 6 0 11
5 11 10 0 6 5 10 10 10 7 0 7 -6 0 -20 -16 -29 -5 -24 16 8 12 18 19 48 20 92
2 80 2 80 14 80 7 0 7 -6 0 -19 -14 -26 -13 -29 10 -41 14 -7 20 -7 20 0 0 5
-7 10 -15 10 -8 0 -15 5 -15 11 0 5 4 7 10 4 12 -8 52 13 45 24 -2 5 7 21 20
35 27 30 29 35 16 54 -7 11 -9 10 -14 -3 -4 -9 -3 -13 3 -10 12 7 12 3 4 -19
-13 -33 -21 -14 -20 49 1 66 9 84 20 42 3 -14 13 -23 25 -23 11 0 17 4 15 8
-3 4 0 8 6 8 6 0 9 4 5 9 -3 5 -1 12 5 16 6 4 8 10 5 15 -3 5 1 22 8 37 8 15
13 45 12 65 -3 30 0 36 13 31 13 -4 14 -2 4 4 -10 8 -10 12 3 23 8 7 15 18 15
24 0 6 -5 4 -11 -4 -8 -11 -9 -8 -4 14 5 26 4 28 -9 17 -13 -11 -16 -11 -16 3
0 11 9 16 30 16 31 0 36 11 30 64 -1 10 2 16 8 13 5 -4 9 0 9 8 0 8 -3 12 -7
10 -3 -2 -6 48 -6 111 1 63 4 114 9 114 4 0 8 -8 8 -17 1 -17 2 -17 6 0 2 9
12 17 21 17 14 0 15 -2 3 -9 -12 -8 -12 -12 -2 -25 7 -9 9 -21 5 -28 -5 -7 -9
-22 -11 -33 -1 -11 7 -1 17 23 11 23 16 50 13 59 -4 10 -2 14 4 10 7 -5 10 6
8 30 -3 28 2 44 18 62 12 13 16 21 10 17 -8 -5 -13 -1 -13 9 0 12 5 15 18 10
14 -5 15 -4 5 6 -15 15 -17 33 -3 24 6 -3 7 1 3 10 -4 13 -8 14 -14 4 -4 -8
-11 -10 -15 -6 -11 10 -13 67 -3 73 5 3 6 -3 3 -13 -5 -16 -4 -16 8 -3 17 20
48 22 41 3 -3 -8 -12 -12 -19 -9 -11 4 -11 0 -1 -21 7 -15 13 -20 15 -12 2 8
12 35 23 61 20 48 25 73 10 50 -4 -7 -7 21 -6 66 1 43 5 84 10 92 6 9 4 11 -5
5 -8 -5 -11 -3 -8 5 7 21 27 23 35 4 4 -10 0 -22 -11 -29 -10 -8 -13 -16 -7
-19 12 -8 36 33 32 53 -2 12 0 12 11 3 18 -14 18 -14 20 126 1 56 6 102 11
102 4 0 6 3 3 8 -2 4 9 8 25 8 25 1 28 -1 18 -13 -9 -10 -14 -11 -19 -3 -10
16 -21 -15 -14 -35 4 -9 0 -15 -7 -15 -11 0 -11 -3 1 -10 11 -7 11 -10 2 -10
-7 0 -10 -5 -6 -11 4 -8 9 -7 15 2 7 12 9 12 9 0 0 -8 3 -12 6 -8 3 3 2 11 -2
18 -14 23 -14 58 0 53 8 -3 22 10 35 31 19 33 31 66 13 38 -5 -8 -8 1 -9 22
-1 19 3 35 9 35 5 0 6 -4 3 -10 -3 -5 -2 -10 2 -10 5 0 14 9 21 20 7 12 8 20
2 20 -5 0 -10 5 -10 11 0 5 4 8 9 4 5 -3 13 0 17 6 5 8 3 9 -6 4 -8 -5 -11 -3
-8 6 3 8 12 13 19 11 8 -2 11 0 7 4 -4 4 -3 13 3 21 6 7 8 13 4 13 -4 0 -13
-7 -20 -17 -11 -14 -13 -13 -17 11 -4 20 -2 26 8 22 8 -3 14 -1 14 5 0 16 -31
23 -40 8 -5 -7 -12 -10 -17 -6 -11 12 19 28 44 24 29 -5 41 10 38 50 -1 18 2
30 7 27 4 -3 8 7 8 23 0 15 5 35 11 43 8 11 9 7 4 -16 -5 -25 -2 -33 12 -38
14 -6 16 -5 6 5 -14 14 -18 33 -5 26 4 -3 13 1 19 9 7 7 9 15 4 19 -5 3 -12 0
-14 -5 -3 -6 -4 -5 -3 2 2 7 9 13 17 13 11 0 14 17 13 81 -1 58 2 81 11 81 7
0 10 -8 7 -19 -6 -25 15 -23 23 3 5 16 9 18 15 9 14 -23 30 0 25 36 -3 28 -1
35 10 30 18 -6 46 31 38 51 -4 10 0 14 13 11 12 -2 23 5 29 20 5 12 18 25 27
28 10 3 15 11 11 20 -3 8 1 14 10 14 8 0 17 -5 19 -11 3 -9 59 -26 113 -34 11
-1 33 -8 48 -14 39 -15 49 -12 13 3 -27 12 -22 13 56 13 78 -1 84 -2 83 -21
-1 -11 -10 -23 -21 -27 -15 -6 -12 -8 13 -8 17 -1 33 -6 35 -12 2 -6 15 -13
28 -14 40 -4 41 -5 34 -17 -5 -7 -3 -8 5 -4 7 5 21 0 32 -10 10 -9 19 -14 19
-10 0 4 -10 16 -22 26 -22 18 -22 18 44 17 57 0 67 -3 71 -19 4 -12 1 -18 -6
-15 -7 3 -11 10 -9 17 2 8 -3 10 -15 5 -10 -4 -26 -8 -35 -9 -10 0 -18 -4 -18
-9 0 -5 12 -7 26 -4 19 3 24 1 20 -10 -4 -10 -1 -13 13 -8 13 4 23 0 30 -13 7
-14 7 -19 -4 -19 -7 0 -11 6 -8 14 3 7 1 13 -5 13 -6 0 -9 -8 -8 -17 2 -10 8
-18 15 -18 6 0 16 -7 22 -15 8 -12 9 -11 7 5 -2 16 3 20 30 20 17 1 32 -3 32
-7 0 -4 -2 -8 -4 -8 -2 0 -12 -3 -22 -7 -12 -4 -15 -3 -10 5 4 7 2 12 -3 12
-19 0 -12 -16 14 -36 14 -10 25 -24 25 -30 0 -6 17 -10 39 -9 35 1 39 -1 39
-24 l0 -24 -13 23 c-8 15 -15 19 -19 11 -4 -6 -2 -11 3 -11 6 0 11 -4 11 -10
0 -21 33 -47 56 -44 16 1 24 -3 24 -12 0 -9 5 -12 10 -9 6 4 10 -5 8 -22 -1
-22 -7 -28 -26 -30 -18 -1 -21 1 -11 7 8 6 0 8 -23 7 -20 -1 -34 -4 -32 -6 2
-2 -4 -14 -13 -25 -18 -21 -18 -21 3 -4 20 18 20 18 21 -61 0 -77 0 -78 -24
-78 -17 0 -21 2 -12 8 7 4 10 11 6 15 -4 4 -13 2 -19 -4 -19 -19 -29 -14 -18
8 5 11 15 29 20 40 13 25 13 30 -1 30 -5 0 -8 -4 -5 -9 3 -5 -1 -14 -9 -21 -8
-7 -12 -16 -9 -21 3 -5 0 -9 -6 -9 -6 0 -9 -4 -6 -8 5 -9 -37 -104 -45 -100
-2 2 -3 1 -1 -1 1 -2 -5 -13 -15 -23 -9 -11 -11 -18 -5 -18 7 0 12 -9 12 -21
0 -15 -4 -19 -19 -14 -24 7 -32 -4 -24 -34 4 -14 8 -18 13 -11 6 9 6 -48 1
-117 -1 -7 -10 -10 -22 -8 -30 6 -79 -24 -71 -43 3 -9 1 -13 -4 -9 -6 3 -10
-10 -11 -31 0 -20 1 -27 4 -14 2 12 10 34 18 48 12 23 13 19 12 -45 l-1 -71
-42 5 c-35 3 -43 1 -47 -16 -3 -10 0 -19 5 -19 6 0 5 -6 -2 -15 -10 -11 -10
-15 0 -15 12 0 9 -48 -6 -84 -5 -14 -4 -17 5 -12 9 6 9 3 -1 -13 -15 -25 -30
-28 -21 -5 6 15 -11 34 -32 34 -5 0 -4 -4 3 -8 9 -7 8 -14 -8 -32 -20 -23 -20
-25 -4 -30 12 -5 14 -9 6 -14 -7 -4 -17 -4 -24 0 -8 5 -8 3 -2 -7 11 -17 13
-139 2 -133 -12 7 -10 -10 2 -22 8 -8 -1 -11 -26 -12 -21 -1 -40 -6 -44 -12
-4 -6 -6 -56 -5 -110 1 -80 -1 -100 -12 -100 -8 0 -11 4 -8 10 3 5 -1 20 -9
32 -11 17 -12 27 -5 38 9 13 10 12 6 -2 -3 -9 1 -22 9 -29 12 -10 14 -8 11 7
-3 23 -16 44 -26 44 -4 0 -12 -15 -18 -34 -9 -30 -8 -34 5 -29 10 4 7 -4 -9
-26 -16 -21 -24 -47 -25 -74 0 -23 -4 -53 -9 -66 -4 -14 -3 -21 2 -18 6 4 8
-13 6 -40 -3 -32 -1 -43 7 -38 6 4 9 13 6 21 -3 7 0 16 6 20 6 4 8 3 5 -2 -3
-6 3 -15 14 -22 21 -13 26 -3 7 16 -16 16 4 16 22 -1 8 -6 12 -8 8 -3 -4 4 -2
14 5 20 7 8 12 41 12 84 l0 71 22 -21 c23 -21 20 -53 -3 -44 -8 3 -14 1 -14
-4 0 -6 6 -10 13 -10 8 0 7 -3 -3 -10 -8 -5 -11 -12 -8 -16 4 -3 16 9 27 27
18 30 19 36 6 55 -14 21 -13 22 8 19 15 -3 27 2 35 15 7 11 8 20 4 20 -5 0 -8
29 -8 65 1 36 3 65 4 65 2 0 11 -8 20 -18 10 -9 14 -13 10 -8 -15 19 -8 25 22
21 16 -2 32 0 35 6 4 5 2 9 -2 9 -5 0 -9 33 -9 74 l-1 73 41 -1 c23 -1 40 -5
39 -9 -5 -24 -12 -29 -13 -12 -1 18 -2 18 -15 -5 -8 -14 -14 -32 -15 -41 0 -9
-7 -20 -16 -23 -8 -3 -12 -10 -9 -16 9 -15 40 19 40 44 0 12 5 21 12 21 18 0
24 10 28 46 3 23 2 29 -4 20 -7 -10 -9 -4 -8 20 2 30 5 34 32 35 41 2 39 1 53
25 8 12 9 18 3 15 -6 -4 -9 7 -8 29 1 19 6 35 11 35 5 0 4 6 -1 13 -15 17 -12
44 4 44 9 0 10 -4 3 -11 -22 -22 -8 -28 15 -6 14 13 25 20 25 16 0 -5 6 -3 13
3 8 6 18 7 22 3 5 -4 4 0 -1 8 -13 24 -13 37 2 37 10 0 11 -4 2 -14 -8 -11 -7
-12 6 -7 9 3 16 10 16 14 0 12 -26 31 -33 23 -4 -3 -6 12 -4 34 1 27 6 39 15
36 15 -6 16 -20 2 -28 -5 -3 -10 -11 -10 -17 0 -6 7 -5 17 3 15 12 16 12 10
-3 -11 -29 7 -27 32 5 23 28 23 29 4 18 -27 -14 -33 -14 -33 -1 0 6 7 10 15
10 8 0 15 8 15 18 0 10 3 22 8 26 4 4 7 22 7 39 0 28 3 32 25 29 13 -1 27 -6
30 -10 3 -4 4 -6 1 -5 -2 1 -13 -3 -24 -9 -17 -9 -19 -8 -14 6 4 10 3 15 -3
11 -10 -6 -8 -61 3 -72 7 -7 11 12 7 36 -1 13 1 13 14 2 14 -12 16 -11 10 4
-5 12 -4 16 3 12 12 -8 45 37 35 48 -4 3 -7 19 -8 34 -1 23 3 27 17 24 10 -3
16 -10 13 -17 -4 -6 7 3 23 19 16 17 26 34 23 38 -10 11 -40 -3 -40 -19 0 -8
-4 -14 -10 -14 -15 0 -12 26 5 40 8 7 24 10 35 7 17 -4 20 -1 18 21 -2 17 1
26 10 25 6 -2 12 4 12 12 0 8 -5 14 -12 12 -7 -1 -13 7 -14 19 0 11 5 21 12
21 11 0 11 -4 2 -16 -7 -9 -8 -13 -4 -9 15 13 34 -11 29 -35 -4 -19 -3 -20 7
-5 8 13 8 23 0 38 -6 11 -9 20 -8 21 20 5 45 10 56 11 6 1 12 6 12 11 0 5 -6
7 -13 4 -8 -3 -12 6 -13 27 0 29 2 31 34 33 51 3 67 11 54 26 -12 14 -12 58
-1 51 4 -2 9 2 12 8 2 7 7 10 11 7 9 -9 -4 -40 -15 -33 -5 3 -6 -2 -3 -11 5
-12 9 -13 20 -4 8 7 12 14 9 17 -2 3 8 16 22 30 l28 25 -30 -6 c-25 -6 -28 -5
-14 5 9 7 19 25 23 41 3 15 10 27 15 27 10 0 9 -19 -2 -37 -4 -6 -3 -14 2 -17
13 -9 24 12 17 31 -4 10 -2 14 8 10 14 -5 69 44 67 60 -1 4 5 8 12 8 6 0 13 6
13 13 3 29 4 32 14 32 5 0 7 -8 3 -17 -3 -10 4 -6 16 10 13 15 28 24 34 20 6
-4 8 -1 5 8 -9 24 93 24 148 -1 50 -22 71 -26 40 -9 -24 15 -4 29 41 29 43 0
65 -38 40 -69 -9 -12 -9 -13 1 -7 7 4 12 2 12 -4 0 -6 5 -8 10 -5 6 3 10 0 10
-7 0 -8 3 -9 8 -3 4 6 35 11 68 11 l61 -1 -3 -32 c-3 -37 7 -46 51 -48 27 -1
30 -5 32 -35 1 -20 -2 -28 -7 -21 -4 7 -10 9 -13 4 -3 -4 11 -19 31 -32 21
-14 31 -18 25 -9 -11 13 -8 15 19 16 18 0 28 -2 22 -6 -6 -4 -14 -16 -17 -27
l-7 -20 16 20 c16 20 16 20 11 -4 -9 -36 7 -50 50 -44 34 5 35 4 20 -12 -9 -9
-23 -13 -29 -9 -10 5 -10 4 -1 -6 9 -10 16 -10 25 -2 11 9 14 5 16 -15 1 -15
7 -30 13 -33 5 -4 9 -11 7 -16 -5 -15 29 -57 37 -45 3 6 1 11 -5 11 -6 0 -8 5
-4 12 6 9 9 9 12 0 2 -7 7 -10 11 -8 4 3 7 -39 7 -93 1 -73 -3 -98 -12 -98 -7
0 -11 4 -8 9 8 12 -19 44 -36 43 -8 0 -14 -8 -14 -18 1 -9 -2 -17 -7 -17 -12
0 -28 -42 -22 -54 3 -4 9 -2 14 5 12 20 4 -53 -9 -78 -7 -12 -10 -28 -7 -35 4
-10 -2 -14 -20 -14 -23 0 -24 1 -10 23 8 12 15 26 15 30 0 4 -5 0 -11 -10 -6
-9 -15 -14 -21 -11 -7 4 -8 2 -4 -4 4 -7 3 -12 -1 -12 -5 0 -8 -42 -6 -92 2
-63 0 -88 -6 -78 -6 8 -8 25 -4 38 3 12 -1 30 -8 39 -10 12 -10 15 -1 9 14 -8
12 28 -3 41 -4 5 -5 2 -1 -4 4 -7 2 -13 -4 -13 -6 0 -8 -4 -5 -10 3 -5 1 -10
-5 -10 -7 0 -5 -7 4 -16 21 -20 21 -41 1 -24 -11 9 -15 9 -15 1 0 -7 -5 -9
-11 -5 -7 4 -7 -2 1 -17 18 -34 24 -38 16 -11 -5 13 -3 22 3 22 6 0 11 -4 11
-9 0 -5 3 -16 6 -24 8 -22 -25 -15 -43 9 -13 18 -14 18 -12 -1 5 -47 -1 -90
-10 -76 -5 8 -7 21 -4 30 4 13 3 13 -5 2 -6 -8 -8 -21 -4 -29 4 -11 2 -13 -9
-7 -12 7 -12 6 -1 -7 10 -13 10 -18 1 -24 -8 -4 -9 -3 -5 4 4 7 2 12 -4 12 -7
0 -10 -7 -6 -15 3 -8 -2 -21 -10 -30 -9 -8 -12 -15 -7 -15 4 0 1 -5 -7 -10
-12 -7 -10 -9 7 -8 12 1 19 7 16 15 -8 19 14 16 26 -4 6 -10 11 -13 11 -7 0 5
-5 15 -11 21 -8 8 -7 17 5 34 12 18 16 20 16 7 0 -9 -5 -20 -10 -23 -8 -5 -7
-11 1 -21 15 -18 6 -28 -29 -30 -37 -2 -36 -1 -50 -25 -8 -12 -9 -18 -3 -14 7
3 11 -3 11 -16 0 -16 -3 -19 -13 -11 -10 9 -13 0 -12 -43 2 -57 -6 -68 -25
-37 -8 14 -9 12 -4 -10 4 -20 1 -29 -14 -37 -23 -12 -37 -45 -33 -76 2 -17 0
-18 -9 -5 -10 13 -11 13 -6 -2 3 -10 -1 -24 -9 -32 -9 -8 -12 -22 -8 -32 4
-11 3 -15 -4 -11 -13 8 -22 -12 -15 -31 4 -11 6 -11 14 0 8 10 9 10 5 -2 -2
-8 -9 -14 -15 -13 -6 2 -16 -6 -22 -17 -8 -14 -8 -20 0 -20 6 0 8 -5 5 -10 -4
-6 -12 -8 -18 -4 -7 3 -4 -1 6 -9 20 -17 22 -38 5 -55 -9 -9 -9 -12 1 -12 7 0
9 -5 6 -10 -5 -8 -11 -7 -21 1 -7 6 -11 15 -8 20 3 5 1 9 -4 9 -6 0 -13 -7
-16 -16 -3 -8 -2 -13 2 -10 4 3 14 -4 21 -16 7 -11 8 -18 4 -15 -16 10 -63
-41 -56 -60 5 -12 3 -14 -8 -8 -11 6 -12 5 -1 -6 14 -16 16 -49 2 -49 -5 0
-10 -5 -10 -11 0 -5 5 -7 12 -3 8 5 9 2 5 -9 -3 -10 -2 -17 3 -17 5 0 16 -13
25 -30 17 -33 20 -65 4 -37 -10 17 -12 17 -23 1 -11 -15 -15 -8 -26 47 -10 50
-14 59 -21 44 -11 -28 -11 -43 1 -25 8 12 10 11 10 -7 0 -12 -4 -25 -10 -28
-5 -3 -10 -1 -10 6 0 7 -2 10 -5 8 -2 -3 -2 -15 2 -27 5 -21 6 -21 20 -5 13
17 14 17 11 -2 -2 -12 -12 -21 -26 -23 -17 -3 -21 1 -17 15 3 10 -1 5 -9 -12
-8 -16 -12 -34 -9 -39 3 -4 -1 -12 -8 -17 -11 -7 -11 -9 -1 -9 7 0 10 -5 7
-10 -4 -6 -12 -7 -18 -4 -7 4 -9 4 -5 -1 4 -4 3 -14 -4 -22 -6 -7 -8 -16 -4
-20 3 -3 6 0 6 8 1 8 7 1 16 -16 l14 -30 -6 30 c-5 28 -5 29 9 10 11 -14 12
-25 6 -37 -6 -12 -6 -18 1 -18 5 0 10 -5 10 -11 0 -6 -7 -8 -16 -5 -14 5 -15
4 -5 -8 8 -9 8 -16 2 -20 -5 -4 -12 3 -16 14 -8 24 -25 27 -25 4 0 -8 4 -13 9
-9 18 10 23 -34 7 -57 -9 -12 -16 -29 -15 -38 0 -10 2 -11 6 -2 2 6 9 10 13 7
5 -3 12 0 16 6 4 8 3 9 -4 5 -19 -12 -14 9 7 28 11 10 17 25 14 34 -4 9 -2 13
5 8 6 -3 14 -1 18 5 4 8 3 9 -4 5 -14 -9 -15 4 -2 24 8 12 10 12 10 1 0 -8 3
-12 6 -9 9 10 11 98 2 98 -4 0 -8 -10 -8 -22 -1 -22 -1 -22 -12 -3 -6 11 -16
23 -21 26 -5 4 9 7 31 7 40 1 41 0 32 -24 -13 -34 -12 -43 2 -19 7 11 11 22
10 25 0 3 6 16 15 30 13 19 13 22 2 13 -12 -10 -14 -7 -13 15 2 53 3 54 18 40
11 -11 11 -15 -3 -25 -9 -7 -11 -13 -5 -13 18 0 39 31 36 52 -4 23 26 60 40
52 6 -4 5 3 -2 15 -6 12 -9 29 -6 39 3 9 2 23 -3 31 -5 8 -5 27 0 44 6 21 5
35 -4 46 -10 14 -10 14 5 3 9 -8 23 -10 29 -6 7 4 5 -3 -4 -17 -9 -13 -17 -36
-17 -51 0 -22 4 -26 18 -21 16 6 16 5 -1 -15 -15 -16 -16 -25 -8 -39 10 -17
10 -16 11 4 0 12 7 29 16 37 8 9 11 16 5 16 -15 0 -14 27 1 36 8 5 9 2 4 -11
-5 -15 -4 -16 9 -5 9 7 12 16 8 19 -4 4 -11 10 -15 15 -3 4 -13 -5 -22 -21
-13 -25 -15 -26 -16 -7 0 28 27 51 41 37 8 -8 17 -6 30 5 21 18 25 32 8 32 -5
0 -8 -4 -5 -9 3 -5 -1 -12 -10 -15 -12 -5 -15 -2 -10 10 3 8 6 27 6 40 0 14 4
23 10 19 5 -3 7 -12 4 -20 -6 -15 20 -15 33 0 4 4 2 5 -6 0 -9 -5 -12 -3 -9 6
3 8 11 13 17 11 7 -1 10 2 6 8 -4 7 -11 8 -18 1 -8 -6 -16 -4 -24 4 -10 11 -6
13 23 11 39 -4 52 11 49 59 0 17 3 41 7 54 5 14 4 28 -2 37 -9 10 -3 12 29 10
29 -2 41 1 41 10 0 8 6 14 13 15 10 0 10 2 0 6 -7 2 -13 9 -13 14 0 6 6 7 13
3 8 -5 8 -2 -2 9 -16 20 -2 23 19 4 8 -6 5 0 -8 14 -26 30 -28 45 -6 53 8 3
12 11 8 17 -4 8 -9 7 -16 -2 -8 -12 -9 -11 -6 0 7 23 26 24 38 1 13 -24 4 -44
-16 -36 -15 6 -19 -9 -5 -19 15 -9 63 39 65 66 1 13 7 23 14 22 7 -2 12 2 12
7 0 21 2 25 16 33 8 5 12 12 9 16 -2 5 2 15 11 22 18 14 49 70 46 82 -1 4 2 7
7 7 5 0 8 11 5 24 -2 15 1 26 10 29 11 4 13 1 10 -11 -4 -12 -2 -11 7 3 8 12
14 51 14 95 l0 75 30 1 c17 0 37 4 45 9 9 5 7 0 -4 -13 -14 -17 -23 -19 -37
-11 -15 7 -17 6 -13 -6 3 -8 11 -12 18 -8 6 4 5 -1 -3 -10 -13 -17 -4 -24 15
-9 5 4 2 0 -8 -9 -9 -9 -14 -19 -11 -23 10 -10 42 35 36 50 -3 8 -2 14 3 12 5
-2 10 1 11 5 0 5 13 21 27 36 15 15 23 30 19 34 -3 4 -2 7 4 7 6 0 18 12 28
26 9 15 13 24 8 21 -5 -3 -10 4 -10 16 -2 16 0 18 6 8 5 -8 14 -10 21 -6 9 6
6 13 -11 27 -13 11 -19 17 -12 14 6 -4 14 -2 17 3 3 5 -1 11 -9 15 -15 6 -16
39 0 53 4 5 5 2 1 -4 -9 -15 10 -18 19 -3 4 6 10 8 15 5 15 -9 70 24 70 41 0
9 -9 18 -20 21 -26 7 -26 22 -1 20 11 0 17 -5 14 -11 -4 -5 1 -7 11 -3 10 4
15 3 11 -3 -3 -6 1 -7 9 -4 9 3 14 10 11 14 -3 5 -1 11 4 15 6 3 7 13 4 23 -6
15 -5 16 12 2 11 -9 16 -10 11 -2 -4 6 -2 12 3 12 6 0 11 4 11 9 0 5 -10 7
-22 3 -21 -5 -22 -4 -8 13 13 17 13 18 0 10 -13 -8 -13 -7 -1 8 17 21 31 22
31 3 0 -8 5 -18 10 -21 6 -4 10 -3 9 2 -4 21 3 31 21 36 14 4 18 10 13 23 -4
11 -3 15 5 10 6 -3 14 -1 18 5 4 7 3 9 -3 6 -13 -8 -33 39 -31 69 2 18 8 22
35 21 32 -1 45 -19 23 -32 -5 -3 -15 -15 -20 -25 -8 -16 -8 -18 4 -14 9 3 14
11 13 17 -1 6 5 11 13 12 9 0 14 8 12 18 -2 9 1 16 5 15 4 -2 8 0 8 3 0 14 28
38 38 32 6 -3 7 -1 3 5 -3 6 2 15 11 21 10 6 13 11 7 11 -6 0 -19 -9 -29 -20
-10 -11 -21 -17 -26 -14 -4 2 -6 -5 -4 -17 2 -12 1 -19 -2 -15 -4 4 -9 18 -10
31 -3 18 0 23 11 19 8 -3 17 0 22 7 4 7 3 10 -2 7 -5 -3 -15 0 -22 8 -15 16
-8 18 18 4 13 -7 21 -7 25 0 4 6 11 8 16 5 9 -6 11 0 13 49 1 17 7 31 16 33
13 4 13 3 0 -6 -12 -8 -12 -11 -1 -11 11 0 11 -3 1 -15 -11 -13 -9 -15 9 -12
12 2 27 10 34 17 10 12 9 12 -5 1 -15 -11 -17 -10 -15 8 1 12 6 20 12 19 5 0
18 2 28 7 14 6 16 10 6 16 -8 4 -10 11 -6 15 3 4 14 1 24 -7 15 -12 16 -12 10
5 -5 13 -3 17 7 13 16 -6 40 16 29 26 -3 4 -2 7 4 7 5 0 9 17 9 38 l-1 37 124
2 c84 1 119 -2 110 -9 -10 -7 -6 -8 14 -3 16 3 27 2 24 -2 -3 -4 1 -14 9 -20
8 -8 15 -9 19 -3 3 6 2 10 -3 10 -5 0 -9 7 -9 15 0 17 13 20 22 5 3 -5 12 -10
18 -10 9 0 8 3 -1 8 -8 5 4 9 30 9 25 0 32 -2 16 -4 -16 -2 -28 -8 -28 -13 0
-5 -8 -10 -17 -13 -10 -2 2 -8 26 -12 35 -6 40 -10 26 -17 -11 -7 -21 -6 -28
1 -6 6 -20 11 -32 11 -13 -1 -5 -8 25 -22 44 -21 83 -31 75 -19 -2 4 11 7 28
8 l31 1 -27 -14 c-30 -16 -32 -20 -15 -30 7 -5 8 -3 3 6 -6 10 -4 12 9 7 11
-5 15 -3 10 5 -5 7 -3 8 5 4 7 -5 10 -14 7 -22 -3 -8 -2 -13 2 -13 22 4 33 -3
29 -15 -6 -15 16 -29 27 -18 12 11 45 9 50 -4 5 -13 -2 -24 -16 -24 -5 0 -6 5
-2 12 5 7 3 8 -6 3 -13 -8 5 -27 63 -62 15 -10 27 -21 27 -26 0 -5 7 -4 15 3
19 16 28 8 28 -24 0 -14 6 -31 13 -37 8 -6 14 -8 14 -3 0 4 7 -1 16 -12 15
-18 15 -18 5 2 -10 20 -8 22 22 20 30 -2 32 -4 33 -41 0 -34 3 -40 20 -38 15
2 24 -7 34 -32 8 -20 17 -30 20 -25 4 6 6 -13 6 -41 l-1 -52 -39 -3 c-26 -1
-34 1 -25 7 9 6 10 9 2 9 -20 0 -36 -39 -23 -55 13 -16 6 -91 -10 -110 -12
-15 -40 -9 -40 7 0 6 7 4 16 -3 15 -12 16 -11 10 11 -5 17 -4 20 2 12 7 -11
10 -11 15 3 4 9 3 13 -3 10 -6 -3 -10 -2 -10 4 0 5 5 13 10 16 6 3 10 15 10
25 0 14 -5 18 -16 13 -9 -3 -14 -13 -11 -24 3 -11 1 -19 -5 -19 -5 0 -7 -4 -3
-9 3 -5 -3 -16 -12 -23 -11 -8 -19 -30 -22 -58 -4 -53 -6 -57 -24 -42 -11 9
-17 4 -30 -24 -19 -38 -22 -58 -7 -34 6 9 10 10 10 3 0 -7 -8 -16 -17 -22 -16
-9 -15 -10 5 -8 14 1 22 8 22 19 0 9 5 20 11 24 8 4 7 9 -2 15 -7 4 -10 12 -5
16 5 4 11 2 13 -5 3 -6 10 -9 16 -5 7 4 4 -5 -5 -19 -10 -15 -15 -34 -12 -42
4 -11 0 -14 -14 -11 -23 4 -66 -28 -54 -39 4 -4 7 -21 8 -37 0 -23 -2 -26 -13
-17 -22 18 -38 -5 -21 -31 16 -25 30 -28 21 -3 -4 13 -3 14 6 6 22 -20 2 -42
-35 -39 -36 2 -37 -1 -35 -55 0 -14 -2 -19 -5 -12 -10 21 -22 4 -16 -24 3 -19
7 -22 13 -12 5 8 8 1 6 -19 -1 -20 -7 -33 -16 -33 -8 0 -11 6 -7 15 5 13 2 13
-19 -1 -14 -9 -23 -20 -20 -24 3 -5 1 -11 -5 -15 -6 -4 -5 -11 4 -20 8 -8 16
-20 19 -27 3 -10 10 -8 23 6 19 18 18 18 -4 11 -22 -6 -22 -5 -10 14 11 18 11
23 -2 31 -10 7 -11 10 -2 10 7 0 15 -7 19 -16 4 -10 8 -12 13 -4 4 6 6 -6 6
-27 0 -36 -2 -39 -31 -38 -16 0 -36 -4 -42 -9 -17 -11 -17 6 -1 22 6 6 9 15 5
18 -18 19 -22 -10 -14 -101 3 -27 3 -44 0 -37 -9 22 -20 12 -20 -18 0 -30 12
-41 20 -17 2 7 3 6 3 -3 -2 -17 -43 -21 -66 -5 -8 5 -12 6 -8 1 4 -5 1 -15 -7
-23 -8 -8 -15 -20 -15 -26 0 -8 3 -7 9 2 6 9 8 2 8 -22 -1 -20 -5 -34 -9 -31
-4 3 -5 12 -1 22 3 9 -4 2 -16 -16 -23 -35 -26 -46 -12 -38 11 7 23 -22 15
-36 -3 -5 -12 -1 -21 9 -8 10 -11 12 -8 5 4 -7 -1 -23 -12 -35 -17 -21 -17
-21 1 -7 23 18 46 22 46 6 0 -20 -30 -56 -48 -56 -13 0 -12 3 6 16 12 8 22 20
22 25 0 6 -7 3 -16 -5 -8 -9 -20 -16 -25 -16 -5 0 -7 -27 -4 -60 3 -48 1 -58
-10 -54 -11 5 -12 11 -5 25 8 15 7 17 -5 13 -8 -4 -15 -14 -15 -23 0 -9 -6
-23 -12 -30 -10 -11 -10 -13 0 -7 15 9 16 -10 1 -19 -6 -4 -8 -14 -5 -22 3 -8
2 -11 -3 -8 -14 8 -24 -14 -23 -49 0 -20 -2 -26 -8 -17 -5 10 -8 6 -7 -12 1
-14 5 -23 9 -21 3 2 6 -29 5 -69 -1 -62 -4 -73 -19 -74 -19 -2 -24 12 -8 22 6
3 10 14 10 23 -1 12 -5 10 -15 -8 -10 -17 -14 -20 -15 -9 0 9 9 28 21 43 21
27 20 56 -2 56 -8 0 -8 -4 1 -15 11 -13 9 -19 -9 -37 -18 -16 -22 -28 -17 -50
5 -24 3 -29 -9 -24 -14 5 -20 -9 -16 -41 1 -7 -3 -13 -10 -13 -8 0 -8 -4 1
-16 8 -8 9 -14 4 -12 -9 3 -14 -4 -14 -23 0 -5 5 -7 12 -3 7 5 8 3 4 -5 -5 -7
-15 -10 -23 -6 -13 4 -14 3 -3 -10 7 -8 15 -13 19 -10 4 2 7 -14 7 -36 0 -21
-4 -39 -8 -39 -4 0 -8 6 -9 13 -1 6 -2 21 -3 31 0 11 -6 21 -12 23 -11 4 -15
-10 -16 -64 -1 -18 -5 -30 -9 -27 -17 10 -26 -36 -24 -128 1 -53 -2 -95 -7
-92 -5 3 -6 30 -3 59 3 33 0 57 -6 61 -8 4 -9 -1 -4 -16 4 -13 3 -20 -2 -17
-5 3 -7 -9 -5 -28 2 -18 -1 -36 -7 -39 -6 -4 -11 -13 -11 -19 0 -7 4 -6 11 3
8 12 9 10 7 -9 -2 -15 3 -26 12 -28 13 -4 13 -3 0 7 -13 10 -13 11 0 7 8 -2
15 -15 15 -30 1 -34 -5 -34 -30 2 -17 26 -20 28 -31 13 -7 -10 -10 -29 -7 -43
3 -18 0 -28 -11 -32 -9 -4 -16 -14 -15 -24 0 -16 1 -15 8 2 5 13 8 0 8 -37 0
-32 -4 -58 -7 -58 -10 0 -20 -52 -12 -64 3 -5 9 -1 13 10 6 14 8 10 8 -18 1
-20 -4 -40 -11 -44 -7 -5 -9 0 -6 14 5 16 2 20 -13 17 -30 -6 -41 -16 -34 -33
3 -8 0 -19 -7 -24 -10 -6 -10 -10 1 -17 7 -5 11 -13 7 -19 -3 -5 3 -12 14 -15
28 -7 35 10 10 23 -17 9 -18 14 -7 30 17 28 26 25 24 -7 -1 -20 2 -27 12 -25
12 3 12 1 0 -14 -12 -15 -12 -16 8 -10 20 6 22 5 15 -12 -4 -11 -22 -37 -41
-57 -24 -27 -30 -40 -22 -48 10 -10 12 -8 10 9 0 3 12 17 27 31 16 14 29 32
30 40 2 8 5 22 9 31 5 14 8 14 15 3 6 -11 18 1 46 45 21 32 52 74 70 92 18 18
40 48 48 67 9 18 21 35 28 37 6 2 12 27 13 56 1 28 4 55 8 59 10 9 9 25 -1 19
-5 -3 -9 1 -9 8 0 8 16 12 49 12 38 -1 45 -3 34 -12 -9 -6 -12 -15 -8 -19 4
-4 -3 -3 -15 4 -27 14 -33 14 -33 0 0 -5 5 -7 10 -4 6 3 10 1 10 -4 0 -6 0
-14 0 -18 0 -5 -5 -8 -11 -8 -7 0 -15 -10 -20 -22 -5 -14 -5 -23 1 -23 6 0 10
7 10 15 0 8 6 15 14 15 7 0 25 17 39 39 19 29 23 41 14 50 -6 6 -12 25 -13 42
-2 28 1 30 30 29 52 -3 53 -2 50 45 -2 25 1 45 7 45 6 0 7 -5 3 -12 -4 -7 -3
-8 4 -4 8 4 10 2 6 -7 -3 -8 -1 -18 4 -21 10 -6 21 22 27 66 2 24 1 25 -12 12
-12 -12 -12 -18 -3 -29 9 -11 9 -15 1 -15 -6 0 -14 8 -17 18 -3 9 -10 25 -15
34 -13 25 -12 25 21 24 34 -1 51 -20 42 -45 -3 -9 -2 -13 2 -9 4 3 9 12 10 20
1 7 8 23 14 36 10 16 10 22 1 20 -6 -2 -13 2 -16 9 -4 12 14 12 34 0 9 -6 11
-3 6 9 -4 12 -3 15 6 10 7 -5 15 3 22 23 6 17 16 31 22 31 8 0 8 3 -1 8 -9 6
-10 16 -1 40 10 30 26 37 40 19 3 -5 9 -1 12 8 4 9 3 14 -2 11 -10 -7 -24 22
-16 33 2 4 -2 8 -10 8 -10 0 -11 -5 -4 -18 10 -20 12 -35 2 -26 -3 4 -12 2
-19 -4 -12 -10 -13 1 -5 53 0 5 11 6 24 4 12 -3 25 -5 29 -5 4 -1 5 -9 2 -18
-5 -15 -4 -15 4 -3 6 8 9 17 6 21 -2 4 0 10 5 14 6 3 8 31 5 63 -5 72 7 116
17 57 l6 -40 2 38 c0 23 6 37 14 37 21 0 12 -51 -13 -77 -20 -20 -21 -26 -9
-34 23 -15 29 -11 29 20 0 17 4 32 9 35 5 3 7 15 4 25 -3 10 0 22 6 25 6 4 11
17 11 30 0 12 5 27 11 33 7 7 8 17 3 25 -5 7 -9 63 -10 125 -1 109 -1 111 17
90 17 -21 18 -21 19 -3 0 11 3 17 6 13 10 -9 -6 -46 -19 -43 -7 1 -9 1 -4 -1
11 -7 9 -31 -4 -35 -7 -2 -7 -12 0 -31 8 -20 7 -27 -2 -28 -9 0 -9 -2 1 -6 10
-4 10 -8 0 -21 -11 -13 -11 -14 2 -10 11 4 14 18 13 54 -1 35 1 43 7 29 7 -17
9 -17 9 -2 1 9 5 17 10 17 5 0 11 11 13 25 3 13 1 22 -3 19 -15 -9 -10 7 11
33 11 14 20 31 20 37 0 6 -4 5 -9 -3 -5 -8 -8 22 -8 77 1 50 5 89 9 86 4 -3 8
2 8 10 0 8 -4 18 -10 21 -15 9 -12 22 4 22 8 0 17 -13 20 -29 4 -19 10 -27 17
-22 7 4 10 2 8 -5 -3 -7 -8 -11 -13 -9 -10 4 -40 -52 -31 -60 3 -3 8 4 12 16
3 12 8 20 12 17 4 -2 16 10 26 27 10 17 13 28 6 24 -6 -4 -11 -1 -11 8 0 9 5
16 12 16 6 0 8 3 4 7 -4 5 -14 -1 -22 -11 -13 -18 -14 -18 -14 -2 0 10 9 20
20 23 19 5 24 19 20 56 -1 10 6 24 15 31 11 8 15 22 12 41 l-4 30 -2 -32 c0
-18 -5 -33 -9 -33 -6 0 -7 21 -3 62 1 10 31 6 50 -6 9 -6 12 -5 7 2 -3 5 -3
12 1 14 5 1 16 21 26 43 9 22 11 32 4 23 -11 -14 -13 -9 -13 39 1 36 6 58 14
60 6 3 12 0 12 -7 0 -6 -5 -8 -11 -4 -8 4 -8 0 0 -15 13 -23 26 -29 18 -7 -3
7 0 19 6 26 9 10 9 14 0 17 -7 3 -13 12 -13 21 -1 15 -3 14 -15 -2 -13 -17
-15 -18 -15 -3 0 9 2 17 4 17 2 0 11 3 20 6 10 4 16 1 16 -9 0 -27 27 -27 39
1 10 24 10 25 -2 10 -18 -23 -23 -23 -30 2 -3 12 -1 20 6 19 31 -4 47 3 47 19
0 12 -3 13 -8 5 -5 -7 -8 21 -8 61 -1 81 -4 77 58 67 4 0 5 -8 2 -16 -6 -14
-8 -13 -20 2 -7 10 -14 13 -14 6 0 -6 5 -15 10 -18 6 -4 7 -11 4 -17 -4 -6
-12 -1 -20 13 -7 13 -13 19 -14 12 0 -22 12 -43 26 -43 15 0 47 54 37 64 -4 3
-1 6 5 6 6 0 9 7 5 17 -5 12 -3 14 8 8 11 -7 12 -6 2 6 -15 19 -11 49 10 64
25 18 21 28 -5 15 -21 -10 -21 -10 -5 3 9 8 17 18 17 22 0 4 -8 4 -17 0 -15
-5 -16 -4 -5 7 15 15 64 19 55 4 -4 -7 -2 -7 8 1 9 8 14 30 13 70 -1 32 3 64
9 71 8 10 7 16 -2 22 -9 5 -2 7 18 6 30 -1 39 -21 10 -21 -10 0 -13 -11 -12
-38 1 -26 -2 -35 -10 -31 -9 5 -8 3 0 -6 7 -8 14 -12 15 -9 2 2 11 19 21 38
14 27 15 32 2 21 -10 -9 -15 -9 -15 -1 0 6 11 16 25 21 14 5 25 12 25 15 0 4
-5 3 -12 -1 -8 -5 -8 -3 -2 7 5 8 9 23 9 34 0 12 6 19 14 17 23 -5 42 18 31
38 -7 13 -5 21 5 30 18 15 20 40 3 40 -6 0 -8 3 -5 7 4 3 17 -2 29 -13 13 -11
18 -13 13 -4 -5 8 -10 29 -11 47 l-1 32 18 -22 c15 -18 20 -20 31 -9 7 7 9 12
3 10 -5 -2 -20 7 -33 20 l-23 23 25 -4 c14 -1 29 -7 33 -12 5 -4 2 -5 -6 0 -9
5 -12 4 -7 -3 7 -11 37 -5 33 6 -1 4 20 35 46 69 30 39 41 60 30 56 -16 -6
-16 -5 -4 10 7 9 18 14 24 10 6 -4 7 -1 2 7 -6 10 -4 12 9 7 9 -4 15 -3 12 2
-3 5 1 14 9 21 8 7 12 16 9 21 -3 5 0 9 7 9 22 0 47 30 42 50 -3 12 -10 17
-17 13 -6 -4 -5 1 3 10 19 24 33 21 26 -5 -5 -18 -4 -20 4 -8 6 8 11 11 11 6
0 -5 7 -3 15 4 8 7 13 16 10 20 -2 4 7 13 20 20 14 7 25 19 25 27 0 7 3 12 8
11 4 -2 18 5 32 14 19 14 22 20 12 30 -7 7 -12 21 -12 33 0 15 2 17 9 6 7 -11
11 -9 21 9 6 12 8 25 4 28 -4 4 -9 1 -11 -6 -3 -6 -9 -10 -14 -6 -13 7 -1 24
17 24 25 0 28 -30 5 -54 -20 -21 -20 -24 -5 -30 17 -7 48 15 65 45 7 14 5 16
-11 12 -15 -4 -20 0 -19 13 0 11 3 14 6 7 2 -7 9 -13 14 -13 6 0 8 3 5 8 -2 4
9 8 25 8 16 1 27 -2 24 -6 -3 -4 3 -7 13 -6 9 0 21 7 25 14 4 6 13 12 20 12 7
0 6 -4 -3 -10 -9 -6 -10 -10 -3 -10 6 0 19 7 27 16 9 8 20 12 26 9 5 -3 10 -2
10 4 0 16 -20 24 -33 13 -15 -12 -62 22 -52 38 11 17 25 11 25 -11 0 -15 5
-19 19 -14 10 3 30 0 45 -6 32 -15 79 2 70 25 -5 12 2 14 33 13 26 -2 39 -8
41 -20 2 -9 -1 -17 -7 -17 -6 0 -11 -5 -11 -11 0 -5 5 -7 10 -4 6 3 10 -1 10
-9 0 -9 9 -16 21 -16 11 0 18 -4 15 -9 -3 -4 12 -3 34 3 31 8 43 7 52 -3 11
-11 7 -13 -22 -8 -19 3 -29 3 -21 0 11 -5 11 -7 0 -15 -11 -7 -9 -8 5 -4 24 8
58 -2 50 -15 -4 -6 -11 -6 -20 2 -8 6 -14 7 -14 2 0 -5 12 -15 26 -21 14 -7
23 -9 20 -4 -2 4 0 14 5 22 9 13 10 13 8 0 -3 -29 1 -41 11 -35 6 3 10 2 10
-4 0 -6 10 -15 21 -22 15 -8 19 -8 15 0 -4 6 -2 11 4 11 6 0 9 3 7 8 -3 4 8 8
24 9 40 3 45 -12 9 -27 l-30 -12 21 -19 c12 -10 25 -16 29 -14 4 3 11 -2 14
-11 5 -13 9 -14 22 -3 8 7 14 8 13 3 -4 -20 2 -35 11 -29 5 3 7 12 3 20 -3 10
2 15 16 15 12 0 21 -5 21 -11 0 -5 4 -8 8 -5 5 3 9 -1 9 -8 0 -8 -9 -11 -25
-8 -17 3 -23 1 -18 -7 4 -6 12 -8 18 -5 6 4 8 2 5 -3 -12 -20 24 -36 83 -38
63 -1 100 -22 100 -57 0 -10 7 -18 18 -18 14 0 15 -2 2 -10 -13 -8 -13 -10 2
-10 9 0 18 5 20 11 2 5 10 7 19 4 10 -4 16 -20 17 -46 1 -27 -2 -38 -9 -33 -6
3 -9 20 -7 36 3 16 0 32 -5 35 -6 3 -7 1 -3 -5 5 -8 -3 -12 -23 -12 -16 0 -33
5 -36 10 -3 6 -14 10 -23 9 -12 0 -13 -2 -3 -6 7 -3 26 -20 42 -37 16 -17 29
-25 29 -18 0 6 -4 12 -10 12 -5 0 -10 5 -10 10 0 22 22 8 31 -20 6 -16 15 -30
21 -30 5 0 7 -4 4 -9 -3 -5 1 -14 9 -21 14 -12 18 -9 13 8 -2 4 -6 6 -10 5 -9
-3 -4 25 7 34 4 4 5 3 2 -3 -11 -19 19 -24 42 -8 22 16 29 6 9 -14 -9 -9 -9
-12 2 -12 7 0 10 -3 7 -6 -15 -16 13 -41 42 -38 34 4 48 -18 28 -47 -13 -17
-16 -16 -52 21 -22 22 -36 40 -32 40 4 0 3 10 -4 22 -10 19 -12 20 -21 4 -10
-18 -2 -54 9 -42 3 3 11 -4 17 -15 6 -12 15 -20 19 -18 5 2 15 -9 22 -26 7
-16 14 -24 14 -17 1 7 6 10 12 7 6 -4 8 -14 5 -24 -5 -14 -4 -14 6 -1 17 20
90 20 96 0 4 -12 2 -13 -9 -2 -7 6 -20 12 -28 12 -10 0 -7 -6 10 -17 22 -13
26 -22 25 -62 -1 -25 -5 -52 -9 -58 -8 -13 3 -17 31 -14 14 2 16 -25 2 -34 -6
-4 -7 1 -3 12 5 14 3 15 -7 6 -9 -8 -9 -16 -2 -30 10 -16 12 -15 25 15 13 31
14 31 29 15 12 -15 15 -44 14 -155 -1 -85 -6 -141 -13 -150 -18 -21 -34 -68
-24 -68 5 0 13 14 16 31 5 23 9 28 16 18 5 -8 9 -23 9 -34 0 -17 -2 -17 -10
-5 -8 13 -10 12 -10 -2 0 -10 5 -18 10 -18 7 0 9 -24 6 -63 -7 -82 -12 -96
-37 -90 -24 7 -50 -14 -42 -33 3 -8 0 -14 -6 -14 -6 0 -11 -5 -11 -11 0 -5 4
-8 9 -5 5 4 7 -3 4 -15 -5 -16 -8 -18 -15 -7 -7 9 -8 8 -4 -5 3 -10 6 -24 6
-30 0 -8 3 -8 9 1 12 20 6 -68 -7 -100 -14 -34 -26 -37 -18 -5 l6 22 -12 -23
c-8 -16 -17 -21 -31 -16 -13 4 -17 2 -13 -5 4 -6 2 -11 -3 -11 -6 0 -11 -5
-11 -11 0 -5 5 -7 10 -4 6 3 10 -1 10 -10 0 -9 -4 -13 -10 -10 -5 3 -10 1 -10
-5 0 -6 -4 -8 -10 -5 -9 5 -14 -8 -7 -19 1 -2 -6 -13 -16 -24 -16 -17 -16 -21
-2 -32 12 -10 15 -10 15 1 0 8 6 5 15 -7 8 -10 15 -14 15 -7 0 6 -5 15 -11 19
-8 4 -8 9 0 17 6 6 11 18 11 26 0 13 -2 13 -9 2 -6 -9 -11 -10 -15 -2 -4 6 -5
11 -2 11 2 0 11 3 19 6 12 4 15 -5 15 -44 0 -49 0 -49 -33 -48 -19 1 -31 5
-28 11 3 6 0 5 -7 -2 -8 -7 -13 -36 -14 -72 0 -33 -3 -56 -7 -50 -4 7 -9 4
-13 -6 -8 -21 2 -38 16 -29 7 5 8 2 3 -5 -5 -9 -10 -10 -18 -2 -14 14 -30 14
-22 1 3 -6 -1 -7 -9 -4 -11 4 -19 -2 -24 -17 -8 -20 -9 -20 -15 -4 -6 14 -10
15 -22 5 -8 -7 -12 -16 -9 -21 3 -5 -1 -9 -8 -9 -10 0 -9 -3 2 -9 19 -13 34
-3 20 14 -7 8 -7 16 -2 19 9 5 17 -11 27 -50 2 -8 -11 -12 -34 -12 -28 0 -32
2 -16 9 15 6 16 8 4 8 -24 1 -40 -23 -35 -53 3 -19 0 -26 -11 -26 -13 0 -13 2
-2 17 13 15 12 15 -8 4 -14 -7 -20 -17 -17 -26 4 -8 10 -12 15 -9 5 3 9 2 8
-3 -4 -24 4 -34 11 -13 l8 25 1 -26 c1 -35 -33 -64 -70 -61 -17 2 -29 -1 -29
-8 0 -6 3 -9 8 -6 4 2 8 -14 9 -37 1 -28 -1 -37 -7 -27 -5 8 -7 24 -4 35 6 20
6 20 -9 0 -10 -13 -12 -24 -6 -31 7 -8 1 -11 -17 -11 -18 0 -22 2 -13 8 11 7
10 9 -3 9 -21 0 -32 16 -17 26 8 4 9 3 5 -4 -10 -16 19 -15 32 1 6 7 10 23 9
35 -1 21 -3 20 -33 -9 -18 -18 -38 -45 -45 -60 -6 -16 -16 -29 -21 -29 -5 0
-6 5 -2 12 4 6 -9 -1 -29 -16 -36 -27 -50 -52 -24 -43 6 2 11 10 9 17 -1 6 3
10 10 7 7 -2 12 -10 11 -16 -2 -6 2 -11 7 -11 6 0 10 7 10 15 0 8 4 15 9 15 5
0 11 -7 15 -15 3 -8 11 -15 18 -15 10 0 10 2 -1 9 -8 5 -10 14 -6 21 5 9 4 11
-3 6 -14 -8 -15 -5 -6 19 6 13 9 14 16 3 6 -9 7 -5 2 12 -3 14 -3 21 2 17 5
-5 9 -28 9 -52 l1 -44 -69 3 c-56 3 -72 0 -94 -17 -21 -17 -23 -22 -12 -31 25
-19 18 -32 -16 -32 -18 0 -31 4 -29 8 3 5 10 5 16 2 7 -5 8 -2 3 7 -8 12 -6
12 8 0 9 -7 17 -9 17 -3 0 14 -28 23 -35 11 -4 -5 -11 -7 -16 -3 -5 3 -9 2 -8
-3 7 -41 -3 -60 -36 -70 -19 -6 -37 -7 -40 -3 -3 5 5 10 17 12 21 3 21 4 -4 9
-15 4 -25 2 -22 -2 7 -11 -43 -42 -56 -34 -5 3 -10 1 -10 -4 0 -6 -5 -11 -11
-11 -5 0 -7 5 -3 11 5 8 1 8 -13 0 -11 -6 -23 -8 -26 -5 -3 3 -19 -2 -37 -11
-17 -9 -38 -14 -46 -11 -7 3 -16 1 -19 -4 -4 -6 -23 -10 -43 -10 -37 -1 -92
-28 -61 -31 47 -5 86 -3 82 5 -3 4 -21 6 -39 3 -21 -4 -32 -2 -29 4 8 13 52
11 78 -3 12 -6 36 -14 52 -18 17 -4 -42 -6 -130 -5 l-160 1 -2 27 c-2 32 7 34
31 8 18 -20 56 -34 56 -21 0 4 -9 10 -21 13 -16 4 -19 11 -14 26 4 13 2 21 -5
21 -6 0 -8 -4 -5 -10 3 -5 -1 -10 -9 -10 -9 0 -16 5 -16 10 0 6 -8 10 -17 10
-15 0 -16 2 -3 10 9 6 10 10 3 10 -6 0 -14 -4 -18 -9 -3 -6 -14 -9 -25 -8 -10
1 -16 5 -13 10 3 4 12 6 20 3 8 -3 11 -1 8 4 -9 14 -21 12 -39 -6 -9 -8 -16
-11 -16 -5 0 6 -5 11 -11 11 -5 0 -8 -3 -6 -7 3 -5 -8 -9 -24 -10 -29 -2 -47
11 -29 22 5 3 10 15 10 26 0 19 0 19 20 -1 21 -21 55 -27 64 -11 3 4 -7 6 -22
4 -18 -2 -22 -1 -10 4 14 7 12 11 -10 27 -15 11 -21 13 -14 6 20 -22 14 -35
-7 -16 -11 10 -24 15 -29 12 -15 -9 -22 20 -9 33 9 9 6 13 -11 17 -12 4 -20
10 -17 13 2 4 -2 8 -10 8 -11 0 -12 -4 -4 -14 6 -7 19 -13 28 -13 14 0 14 -2
1 -10 -10 -6 -21 -4 -35 8 -19 16 -19 16 -5 -3 8 -11 19 -23 25 -27 5 -4 0 -6
-12 -5 -12 0 -20 4 -17 8 2 4 -1 11 -8 15 -7 4 -10 4 -6 -1 4 -4 1 -17 -7 -27
-7 -11 -11 -27 -8 -34 3 -8 0 -14 -7 -14 -6 0 -8 -5 -3 -12 13 -23 13 -43 -3
-54 -9 -7 -12 -18 -7 -29 6 -18 7 -17 14 1 4 11 6 -4 5 -34 -3 -66 -17 -83
-47 -56 -22 19 -22 19 -15 0 4 -12 2 -22 -6 -27 -7 -4 -9 -11 -5 -15 3 -4 12
-2 19 4 16 12 63 -21 53 -38 -11 -17 -25 -11 -25 11 0 15 -4 19 -16 15 -9 -3
-22 -6 -30 -6 -8 0 -14 -6 -13 -12 1 -7 -3 -26 -9 -41 -16 -39 -14 -49 3 -12
13 29 13 29 10 -20 -2 -27 -6 -59 -11 -69 -4 -11 -4 -17 2 -13 5 3 9 -18 10
-48 0 -30 -4 -51 -8 -49 -5 3 -8 15 -7 27 3 38 -1 57 -11 57 -7 0 -7 -6 0 -19
7 -14 7 -22 0 -26 -6 -4 -8 -15 -5 -25 5 -14 2 -17 -13 -13 -28 7 -35 -11 -34
-84 0 -37 -3 -61 -9 -58 -5 4 -9 -6 -8 -22 0 -24 1 -25 9 -8 5 14 7 8 6 -22
-1 -23 -6 -46 -11 -50 -4 -4 -5 -3 -2 4 9 14 -2 43 -15 43 -6 0 -5 -7 2 -15
11 -13 10 -18 -6 -30 -16 -13 -17 -14 -1 -9 13 5 17 1 18 -17 0 -22 1 -22 8
-4 5 12 8 -11 9 -60 1 -55 -1 -75 -8 -65 -6 9 -10 10 -10 3 0 -6 5 -15 10 -18
16 -10 11 -25 -6 -18 -10 4 -17 -1 -21 -16 -7 -28 -23 -27 -24 2 -2 32 -18
-51 -18 -96 -1 -21 3 -36 7 -33 12 7 31 -32 36 -72 5 -46 12 -48 13 -4 1 20
-5 47 -13 60 -13 19 -13 22 1 22 8 0 15 -6 15 -12 0 -9 3 -8 10 2 6 10 9 -6 8
-48 0 -34 -5 -65 -9 -68 -5 -3 -9 -13 -9 -22 0 -15 2 -15 10 -2 13 20 13 4 0
-20 -7 -13 -7 -21 0 -25 16 -10 11 -25 -6 -18 -10 3 -14 13 -11 25 3 11 -2 38
-12 61 -10 23 -16 47 -14 54 3 7 0 20 -6 30 -17 28 -4 -64 14 -100 17 -35 18
-40 6 -33 -5 4 -7 -3 -4 -14 3 -12 21 -33 40 -47 27 -21 46 -27 84 -26 29 0
56 6 67 15 9 9 23 13 29 11 7 -3 16 2 19 11 3 9 10 16 16 16 5 0 6 -4 3 -10
-9 -15 2 -12 19 6 9 8 16 21 16 27 0 7 -4 6 -10 -3 -5 -8 -10 -10 -10 -4 0 18
26 35 44 28 9 -3 13 -11 10 -16 -4 -6 -10 -5 -15 3 -4 7 -10 11 -13 8 -3 -2
-1 -11 5 -19 8 -13 12 -12 28 7 10 12 21 20 24 17 3 -4 16 1 29 10 12 9 17 16
10 16 -6 0 -12 4 -12 10 0 5 7 7 15 4 8 -4 15 -1 15 7 0 7 -3 9 -7 6 -3 -4
-13 -1 -21 6 -11 8 -14 8 -9 0 4 -6 4 -14 -1 -17 -4 -2 -8 5 -8 18 0 22 26 30
42 13 12 -12 26 -8 18 6 -5 9 -4 9 6 0 14 -13 40 -7 40 9 0 6 -7 8 -15 4 -8
-3 -15 -1 -15 4 0 6 5 10 10 10 7 0 7 7 0 20 -14 26 -5 40 26 40 14 0 22 -4
19 -10 -3 -5 1 -10 9 -10 9 0 16 4 16 9 0 4 16 7 36 6 35 -1 63 22 46 40 -4 5
1 5 10 2 20 -7 45 18 28 28 -6 4 -9 3 -8 -2 2 -4 0 -9 -4 -11 -17 -5 -25 -1
-25 11 0 16 37 20 54 5 9 -7 14 -6 19 6 3 9 10 14 14 11 9 -6 63 55 56 62 -3
3 -17 -4 -32 -16 -15 -11 -21 -21 -14 -21 7 0 11 -3 8 -6 -15 -14 -23 -7 -24
24 l-2 33 46 -5 c38 -4 51 -1 69 16 12 11 20 25 17 29 -3 5 -15 -3 -28 -18
l-23 -27 2 39 c1 45 18 55 46 29 19 -17 20 -17 13 2 -7 17 -6 17 9 5 14 -11
20 -11 32 -1 9 7 14 19 11 26 -3 7 8 17 25 23 16 5 28 16 27 23 -2 7 3 12 10
10 8 -1 11 2 7 8 -5 8 -12 7 -23 -2 -13 -10 -13 -8 1 9 11 13 14 23 8 27 -8 5
-12 46 -4 46 1 0 7 -10 14 -21 9 -17 8 -20 -2 -14 -7 5 -10 4 -5 -3 4 -6 14
-8 24 -5 14 6 15 4 5 -8 -10 -13 -10 -16 2 -16 8 0 12 3 10 7 -2 3 0 12 6 20
7 13 9 13 9 1 0 -11 4 -9 14 4 9 13 11 26 6 36 -10 18 -7 66 5 77 4 4 5 1 1
-5 -4 -8 -2 -13 5 -13 10 0 10 -3 1 -12 -7 -7 -12 -17 -12 -22 0 -6 7 0 16 12
8 12 20 22 26 22 7 0 5 -7 -4 -17 -25 -30 -29 -41 -16 -49 7 -4 10 -4 6 0 -9
10 34 55 46 48 5 -3 6 0 3 5 -8 13 17 35 29 25 4 -4 2 1 -5 11 -11 14 -11 21
-3 29 9 9 12 8 12 -5 0 -23 13 -21 35 3 10 11 15 20 10 20 -4 0 -2 7 5 15 7 8
17 12 24 8 6 -3 7 -2 3 3 -5 5 -14 9 -20 9 -7 0 -12 15 -12 35 0 34 1 35 40
36 29 1 39 5 37 15 -3 11 -1 12 10 1 8 -8 9 -15 3 -20 -6 -4 -11 -13 -13 -21
-3 -12 0 -12 12 -2 14 12 15 29 7 81 -1 3 -5 -1 -10 -9 -7 -10 -9 -7 -8 10 2
18 8 26 23 27 28 2 40 -27 18 -43 -12 -8 -14 -14 -6 -19 15 -9 34 2 27 15 -4
5 -2 9 3 9 4 0 18 14 31 30 12 17 18 30 14 29 -22 -4 -29 4 -29 30 0 29 13 36
28 16 5 -6 8 -5 8 4 0 7 6 11 15 8 18 -8 29 9 29 48 1 27 4 30 31 30 44 1 54
11 49 49 -4 31 -2 33 26 32 19 -1 28 -5 26 -13 -3 -7 1 -10 10 -6 8 3 13 8 10
10 -2 3 9 17 25 31 19 18 26 31 21 40 -7 10 -13 4 -25 -20 l-17 -33 3 35 c2
28 7 36 25 38 12 2 22 -2 22 -8 0 -24 28 18 33 50 6 36 22 58 34 46 4 -4 1
-11 -7 -16 -8 -6 -10 -10 -5 -10 13 0 26 10 45 34 14 18 13 18 -10 12 -23 -6
-23 -6 -7 7 9 8 17 21 17 30 0 9 4 18 9 21 13 9 42 -4 35 -16 -4 -5 -10 -5
-18 1 -8 7 -12 4 -13 -12 -2 -17 0 -19 7 -7 5 8 10 11 10 6 0 -5 9 -1 20 9 13
12 17 23 11 34 -9 18 -4 41 10 41 5 0 8 -3 7 -7 -2 -5 2 -9 7 -10 6 -2 10 0
11 5 1 10 22 63 32 82 5 8 5 26 1 40 -4 14 -8 18 -9 10 -12 -111 -27 -125 -26
-27 l0 87 44 -5 c36 -3 43 -2 38 10 -3 8 0 15 6 15 6 0 5 6 -3 17 -8 9 -9 14
-3 10 12 -7 29 19 25 37 -4 18 11 46 21 40 7 -5 8 -2 3 5 -12 21 -48 -25 -40
-52 4 -15 4 -18 -3 -8 -5 7 -10 31 -10 52 -1 35 2 39 21 37 12 -2 28 -7 36
-12 9 -6 12 -5 7 2 -3 5 -1 12 4 14 6 2 12 10 13 18 2 8 6 21 10 28 5 7 3 12
-5 13 -7 0 -4 4 7 9 11 5 14 9 8 9 -7 1 -13 6 -13 12 0 6 6 9 14 6 15 -6 30
19 20 34 -3 5 0 9 6 9 6 0 8 5 5 11 -4 6 -12 8 -19 6 -22 -9 -41 30 -31 64 9
27 22 23 28 -8 5 -26 4 -26 -9 -8 -10 13 -14 15 -14 4 0 -8 9 -20 20 -27 11
-7 20 -10 20 -8 0 2 3 12 7 22 4 12 3 15 -5 10 -7 -4 -12 -1 -12 7 0 16 27 30
40 22 7 -4 19 13 25 35 8 30 18 50 25 50 5 0 6 7 3 17 -5 12 -3 14 7 8 10 -6
12 -4 7 8 -3 10 -3 17 1 17 4 0 8 10 9 22 1 11 7 24 14 28 8 6 8 9 0 14 -6 4
-11 12 -11 17 0 6 6 7 13 3 8 -5 8 -2 -2 9 -16 20 -2 23 18 5 8 -8 11 -8 8 -2
-4 7 0 17 8 24 8 6 19 28 26 48 6 20 17 43 23 51 6 8 6 12 1 9 -7 -4 -11 16
-12 53 0 32 2 59 5 60 4 0 18 3 32 5 19 4 22 2 12 -8 -6 -6 -12 -16 -12 -22 0
-5 11 5 24 23 14 18 22 39 19 47 -3 8 1 14 8 14 9 0 10 3 4 8 -5 4 -11 18 -12
32 -2 22 -1 23 10 8 12 -15 14 -15 24 2 10 15 8 21 -10 36 l-22 19 19 -22 c11
-13 14 -23 8 -23 -16 0 -26 21 -28 56 0 24 6 35 25 47 17 11 20 17 11 17 -9 0
-21 -5 -28 -12 -9 -9 -12 -9 -12 0 0 32 75 25 77 -7 1 -11 -5 -22 -13 -25 -8
-3 -14 -13 -14 -23 0 -15 -2 -16 -10 -3 -6 10 -4 20 7 32 9 10 11 18 5 18 -6
0 -16 -10 -23 -21 -9 -18 -7 -25 11 -43 13 -11 20 -15 16 -8 -4 6 -2 12 4 12
6 0 9 4 6 9 -3 5 1 14 10 21 8 7 13 19 10 27 -3 7 0 13 6 13 7 0 6 5 -2 15
-11 13 -10 14 5 8 18 -6 18 -5 2 17 -21 30 -22 47 -2 30 12 -10 18 -10 30 0
18 15 20 31 3 28 -6 -2 -13 4 -15 13 -6 25 15 69 32 69 14 0 13 -4 -4 -26 -25
-31 -26 -41 -7 -41 8 0 12 6 8 15 -3 10 -1 13 6 8 8 -5 10 -1 5 10 -5 13 -3
15 7 9 9 -5 11 -4 6 3 -4 7 -2 12 5 12 10 0 10 3 1 12 -7 7 -12 27 -12 45 l0
33 28 -17 27 -18 -27 33 c-31 37 -34 48 -15 55 9 4 8 8 -3 16 -11 7 -7 10 16
9 28 -1 30 -3 20 -24 -6 -12 -14 -20 -18 -18 -5 3 -8 1 -8 -5 0 -19 20 -22 27
-4 3 10 13 28 21 41 12 18 17 20 24 10 7 -10 9 -9 5 4 -3 9 4 29 16 44 12 16
16 25 9 20 -8 -4 -12 -1 -12 10 0 10 3 14 7 11 10 -10 42 23 37 38 -3 7 2 15
10 18 9 4 16 12 16 19 0 10 -3 9 -9 -1 -6 -9 -8 0 -6 26 1 22 6 46 10 53 5 8
4 11 -3 6 -6 -3 -13 -2 -17 4 -3 5 -1 10 6 10 9 0 9 3 -1 14 -14 18 -40 10
-40 -13 1 -14 3 -13 15 3 8 11 15 16 15 10 0 -20 -16 -32 -40 -30 l-25 1 -1
113 c0 64 4 112 9 112 5 0 4 7 -3 15 -9 11 -9 20 2 42 10 22 11 37 2 73 -12
47 -5 84 7 40 8 -28 9 -27 14 11 2 20 -1 26 -13 25 -20 -1 -26 24 -17 81 5 32
10 42 17 35 7 -7 17 -7 33 0 25 11 36 55 30 121 -3 35 -2 37 11 20 13 -16 13
-16 8 0 -5 13 -2 16 12 11 16 -5 16 -4 4 5 -8 6 -16 15 -16 21 -3 23 -6 24
-14 5 -6 -15 -8 -9 -7 21 l1 41 43 -2 c24 -2 46 2 50 8 3 5 2 7 -3 4 -6 -3
-10 25 -10 69 -1 41 2 75 5 75 3 1 20 3 38 5 37 3 47 -14 26 -41 -15 -19 -15
-19 -10 5 6 24 5 24 -9 6 -10 -14 -11 -23 -3 -30 18 -19 49 33 38 62 -5 14 -8
27 -5 29 2 3 7 -5 11 -17 5 -17 10 -19 21 -9 15 12 9 33 -7 24 -11 -7 -11 -3
-3 19 5 12 10 14 19 6 10 -8 15 -8 21 1 4 7 3 9 -4 5 -6 -3 -13 -2 -17 4 -4 6
-11 8 -16 5 -5 -4 -9 -1 -9 5 0 6 14 8 34 6 27 -3 39 1 58 21 12 15 17 23 11
19 -8 -4 -13 -2 -13 5 0 8 -4 8 -15 -1 -13 -11 -15 -9 -11 11 3 21 8 24 43 23
40 -2 40 -2 38 35 -1 20 -1 39 1 42 5 10 301 5 310 -4z m-19836 -59 c0 -18 -2
-20 -9 -8 -6 8 -7 18 -5 22 9 14 14 9 14 -14z m19994 -46 c-3 -42 -5 -44 -28
-26 -17 12 -17 14 -4 14 9 0 20 5 23 10 4 7 -3 9 -22 5 l-28 -6 24 14 c18 10
20 16 10 20 -8 3 -5 5 7 4 17 -1 20 -7 18 -35z m-21340 3 c-4 -8 -8 -15 -10
-15 -2 0 -4 7 -4 15 0 8 4 15 10 15 5 0 7 -7 4 -15z m-64 -53 c0 -5 -6 -16
-13 -27 -13 -17 -14 -16 -11 6 3 31 2 29 14 29 6 0 10 -4 10 -8z m21488 -17
c1 -19 0 -19 -10 -1 -8 15 -12 16 -19 5 -7 -12 -11 -11 -25 3 -16 16 -15 17
17 15 27 -1 35 -6 37 -22z m-698 -6 c0 -5 -4 -9 -10 -9 -5 0 -10 7 -10 16 0 8
5 12 10 9 6 -3 10 -10 10 -16z m-21000 -219 c8 -5 11 -10 5 -10 -5 0 -17 5
-25 10 -8 5 -10 10 -5 10 6 0 17 -5 25 -10z m21927 -147 c-4 -3 -2 -12 5 -20
14 -17 7 -25 -11 -13 -13 8 -9 40 5 40 4 0 4 -3 1 -7z m-47 -33 c0 -5 -5 -10
-11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m-33 -80 c7 -8 12
-19 12 -25 0 -5 -6 -2 -13 8 -12 15 -15 16 -26 2 -11 -13 -10 -14 2 -10 8 3
21 -1 28 -10 11 -13 9 -15 -16 -13 -16 1 -35 9 -42 18 -11 13 -11 13 -6 -2 3
-10 1 -18 -4 -18 -6 0 -10 -16 -8 -38 3 -33 0 -38 -21 -40 -27 -2 -29 9 -7 43
9 14 12 25 8 25 -5 0 -2 5 6 10 8 5 15 14 15 20 0 5 3 9 6 8 11 -5 39 24 33
33 -9 15 20 5 33 -11z m-22193 -131 c-3 -5 -10 -7 -15 -4 -5 4 -10 1 -10 -4
-1 -6 -2 -15 -3 -20 0 -5 -5 -13 -10 -18 -5 -5 -6 -2 -1 5 5 10 2 13 -11 10
-10 -2 -24 -14 -30 -26 -17 -34 -28 -28 -31 19 l-2 40 19 -23 c11 -13 19 -17
20 -10 0 6 -4 12 -10 12 -5 0 -10 4 -10 9 0 5 12 9 26 9 18 0 22 -3 13 -9 -11
-7 -11 -9 1 -9 8 0 21 9 28 20 7 12 17 18 22 15 6 -4 8 -11 4 -16z m5380 -10
c-6 -11 -41 -12 -47 -1 -3 4 8 7 24 8 15 1 26 -3 23 -7z m85 1 c-9 -5 -28 -6
-45 0 -27 8 -26 8 16 9 32 0 40 -2 29 -9z m8948 4 c-3 -3 -12 -4 -19 -1 -8 3
-5 6 6 6 11 1 17 -2 13 -5z m98 -4 c-17 -7 -17 -9 -2 -9 9 -1 17 -6 17 -12 0
-6 -7 -8 -16 -5 -9 3 -20 6 -25 6 -6 0 -7 5 -4 10 3 6 -1 10 -9 10 -28 0 -12
-25 24 -37 l35 -11 -29 -1 c-16 -1 -37 6 -46 14 -12 11 -27 13 -49 9 -28 -6
-30 -8 -16 -17 15 -8 14 -11 -11 -28 -16 -10 -23 -19 -16 -19 7 0 10 -5 7 -10
-4 -6 -11 -8 -16 -4 -5 3 -17 0 -25 -7 -12 -10 -12 -14 -2 -20 11 -7 11 -9 0
-9 -7 0 -11 -4 -7 -9 3 -5 1 -12 -4 -16 -6 -3 -8 4 -6 17 4 22 4 22 -10 4 -14
-19 -15 -19 -13 6 1 15 5 25 9 22 4 -2 10 0 13 5 3 5 -1 12 -10 15 -22 9 -9
21 24 22 15 1 33 2 40 3 9 1 11 12 7 39 -3 20 -2 31 1 25 6 -14 51 -8 69 8 5
5 27 9 50 8 31 0 36 -2 20 -9z m4722 4 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11
1 17 -2 13 -5z m83 -15 c11 -6 8 -9 -13 -14 -17 -5 -33 -3 -40 4 -6 6 -7 11
-1 11 5 0 2 5 -6 10 -17 12 35 3 60 -11z m7395 10 c-7 -11 -75 -12 -91 -1 -7
4 12 8 42 9 32 1 52 -2 49 -8z m-25068 -53 c-6 -20 -27 -17 -27 3 0 18 -4 20
-27 15 -27 -6 -27 -6 -8 9 17 13 22 13 42 -2 12 -9 21 -20 20 -25z m3730 28
c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m15720 -46 c-3 -8 -6
-5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-19490 6 c-3 -3 -12 -4 -19 -1 -8
3 -5 6 6 6 11 1 17 -2 13 -5z m25248 -4 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10
0 6 2 10 4 10 3 0 8 -4 11 -10z m-23980 -10 c3 -5 2 -10 -4 -10 -5 0 -13 5
-16 10 -3 6 -2 10 4 10 5 0 13 -4 16 -10z m409 -11 c-32 -13 -50 -9 -24 5 14
7 29 10 34 8 5 -3 1 -9 -10 -13z m1846 11 c-18 -12 -43 -13 -36 -2 2 4 15 8
28 9 18 2 19 0 8 -7z m27 -12 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1
-19z m2679 1 c12 -10 11 -13 -9 -24 -37 -19 -66 -24 -71 -11 -3 7 -8 6 -15 -4
-9 -13 -11 -13 -11 2 0 11 10 20 25 24 14 3 25 10 25 15 0 6 -8 5 -19 -1 -29
-15 -38 -12 -15 5 21 16 67 13 90 -6z m81 15 c-3 -3 -12 -4 -19 -1 -8 3 -5 6
6 6 11 1 17 -2 13 -5z m19079 -6 c-10 -5 -23 -5 -33 1 -15 8 -11 10 15 9 26
-1 30 -3 18 -10z m-23680 -23 c-11 -8 -34 -15 -50 -14 l-31 1 33 8 c18 4 36
10 39 14 4 3 11 6 17 6 6 0 2 -7 -8 -15z m7047 8 c-7 -2 -19 -2 -25 0 -7 3 -2
5 12 5 14 0 19 -2 13 -5z m-5228 -13 c-3 -5 -11 -10 -16 -10 -6 0 -7 5 -4 10
3 6 11 10 16 10 6 0 7 -4 4 -10z m49 2 c2 -4 -5 -9 -17 -9 -17 -2 -19 0 -7 7
18 12 18 12 24 2z m13891 -22 c-10 -11 -23 -20 -28 -20 -6 0 -2 9 8 20 10 11
23 20 28 20 6 0 2 -9 -8 -20z m-15748 -12 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13
3 -3 4 -12 1 -19z m1793 2 c0 -5 -7 -7 -15 -4 -8 4 -15 8 -15 10 0 2 7 4 15 4
8 0 15 -4 15 -10z m-3553 -40 c-1 -24 -7 -42 -16 -45 -11 -4 -11 -2 -3 8 7 8
9 17 6 20 -7 6 3 57 11 57 2 0 3 -18 2 -40z m1837 15 c-4 -8 -10 -12 -15 -9
-11 6 -2 24 11 24 5 0 7 -7 4 -15z m2402 -22 c-1 -18 -2 -33 -4 -33 -10 0 -27
28 -30 47 -2 16 2 21 16 20 14 -1 18 -9 18 -34z m1871 22 c-8 -8 -14 -9 -18
-3 -4 7 -14 6 -28 -1 -17 -9 -26 -9 -41 2 -18 12 -14 14 39 14 48 1 58 -2 48
-12z m682 8 c14 10 14 10 4 -4 -10 -12 -10 -18 -1 -27 7 -7 9 -12 5 -12 -4 0
-16 9 -27 20 -11 11 -20 23 -20 27 0 4 5 2 12 -5 9 -9 15 -9 27 1z m2167 -13
c0 -9 -9 -16 -21 -17 -11 -1 -14 1 -7 4 6 3 12 9 12 14 0 4 -6 6 -14 3 -8 -3
-16 -2 -19 3 -3 4 7 8 22 8 18 1 27 -4 27 -15z m8490 7 c14 -13 -27 -19 -44
-6 -15 12 -14 11 1 -8 18 -20 18 -21 1 -19 -10 2 -21 11 -26 22 -7 16 -8 16
-4 -3 2 -13 1 -25 -3 -27 -4 -3 -8 8 -9 24 -2 27 -1 28 38 26 22 -2 43 -6 46
-9z m604 3 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4
-10z m106 -30 c3 -23 0 -30 -14 -30 -13 0 -14 2 -3 9 8 5 9 11 3 15 -6 4 -15
-1 -20 -9 -8 -15 -10 -14 -23 1 -8 10 -9 15 -2 11 7 -4 19 3 30 17 21 28 24
27 29 -14z m4354 30 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6
0 11 -4 11 -10z m-2033 -32 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1
-19z m-3110 -39 c-15 -11 -25 -25 -22 -29 3 -5 0 -12 -6 -16 -7 -4 -9 -3 -6 2
3 6 -3 15 -14 22 -11 7 -19 8 -19 2 0 -5 -4 -10 -8 -10 -5 0 -9 6 -9 14 0 9
10 12 31 11 17 -2 40 3 51 11 31 22 33 16 2 -7z m-13112 -17 c-3 -3 -11 0 -18
7 -9 10 -8 11 6 5 10 -3 15 -9 12 -12z m2596 4 c-6 -7 -4 -18 6 -31 15 -20 15
-20 9 0 -3 11 -1 27 4 35 7 11 9 3 7 -25 -4 -57 -14 -71 -38 -49 -12 11 -17
24 -13 36 3 12 0 18 -10 18 -8 0 -17 4 -20 9 -4 5 4 7 16 4 17 -3 19 -2 9 5
-10 7 -6 10 13 10 18 0 24 -4 17 -12z m15519 6 c0 -4 -11 -8 -24 -8 -14 0 -22
4 -20 8 3 4 14 8 25 8 10 0 19 -4 19 -8z m29 -6 c9 -11 9 -16 -1 -22 -7 -4
-10 -4 -6 1 4 4 3 14 -3 22 -6 7 -9 13 -6 13 2 0 10 -6 16 -14z m-8879 -26 c0
-5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m11230
-5 c-14 -17 -30 -20 -30 -4 0 5 4 8 9 4 5 -3 12 -1 16 5 3 5 9 10 12 10 3 0 0
-7 -7 -15z m-7456 -34 c3 -5 -2 -15 -12 -22 -15 -12 -16 -12 -5 2 7 9 10 19 6
22 -3 4 -4 7 0 7 3 0 8 -4 11 -9z m-19160 -13 c-3 -5 -9 -6 -14 -3 -5 3 -11 1
-15 -5 -4 -6 -11 -8 -16 -4 -15 9 -2 20 25 20 13 0 22 -4 20 -8z m6342 -12 c0
-14 -5 -21 -9 -17 -4 4 -5 15 -1 24 9 24 12 21 10 -7z m1386 10 c-4 -6 -7 -14
-7 -18 0 -18 -11 -14 -13 6 -1 14 4 23 13 23 8 0 11 -5 7 -11z m2314 -53 c-3
-6 0 -13 6 -15 6 -2 5 -8 -4 -15 -12 -10 -14 -3 -15 45 0 48 1 52 8 27 5 -16
7 -35 5 -42z m24 55 c0 6 4 12 9 12 5 0 4 -11 -2 -25 -10 -21 -13 -22 -19 -7
-10 26 -9 39 2 22 6 -10 10 -11 10 -2z m1540 0 c0 -5 -7 -8 -14 -5 -10 4 -13
1 -9 -9 3 -9 -1 -14 -12 -14 -9 0 -14 -4 -10 -9 3 -5 2 -12 -3 -15 -4 -2 -8 6
-8 19 0 13 4 21 8 19 4 -3 8 -1 8 5 0 5 -6 11 -12 14 -7 2 2 4 20 5 17 0 32
-4 32 -10z m1497 3 c20 18 15 1 -7 -21 -22 -22 -39 -27 -21 -7 7 9 7 18 1 25
-7 9 -7 9 2 2 7 -6 16 -6 25 1z m2893 -18 c0 -9 -16 -30 -35 -49 -19 -18 -35
-36 -35 -41 0 -4 4 -1 10 7 5 8 11 12 14 9 3 -3 -5 -15 -18 -27 -24 -20 -25
-20 -46 -2 -13 11 -20 26 -16 35 6 17 26 21 26 4 0 -5 -4 -7 -10 -4 -5 3 -10
2 -10 -4 0 -6 8 -15 19 -20 16 -9 18 -7 15 15 -1 14 3 24 9 23 28 -3 34 3 31
28 -1 16 4 32 14 39 20 14 32 9 32 -13z m45 15 c3 -3 -1 -11 -10 -18 -8 -7
-15 -11 -16 -9 -6 31 -5 37 7 35 7 -1 16 -5 19 -8z m3074 -28 c-10 -19 -39
-48 -64 -65 -24 -16 -45 -36 -45 -44 0 -11 5 -10 20 4 21 19 24 17 14 -9 -3
-9 -10 -14 -14 -11 -4 3 -13 -9 -19 -25 -5 -17 -12 -30 -13 -30 -2 0 -4 31 -4
68 l0 68 40 0 c34 -1 41 2 42 19 1 11 5 29 10 39 5 14 4 17 -4 12 -7 -4 -12
-3 -12 3 0 5 15 9 34 8 l33 -2 -18 -35z m3299 17 c22 -29 9 -40 -21 -17 -15
12 -25 26 -22 31 9 13 28 7 43 -14z m2890 -1 c0 -12 -2 -16 -6 -10 -10 17 -69
27 -77 14 -3 -5 -11 -7 -17 -4 -18 11 15 23 58 22 32 -2 40 -6 42 -22z m2318
-10 c0 -29 -3 -32 -17 -23 -14 8 -15 15 -6 34 14 32 23 29 23 -11z m-9681 4
c-3 -5 -13 -10 -21 -10 -8 0 -14 5 -14 10 0 6 9 10 21 10 11 0 17 -4 14 -10z
m7336 -7 c20 -12 27 -23 24 -37 -2 -12 -4 -23 -4 -25 -1 -5 -71 69 -71 75 0 9
24 3 51 -13z m-18891 -47 c24 2 40 -1 40 -7 0 -6 -7 -8 -16 -5 -8 3 -12 2 -9
-4 9 -14 -8 -12 -25 3 -12 10 -12 9 -1 -6 9 -12 21 -15 35 -11 19 6 20 5 6
-11 -11 -14 -12 -17 -1 -11 9 6 12 5 9 -4 -2 -7 -11 -14 -20 -16 -11 -1 -15 3
-11 12 4 10 0 12 -13 7 -11 -4 -15 -3 -10 5 6 10 -10 27 -26 27 -8 0 -9 3 -7
33 1 14 2 15 6 1 3 -12 14 -15 43 -13z m11560 4 c0 -5 -2 -10 -4 -10 -3 0 -8
5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m-10297 -17 c-3 -7 -8 -13 -12
-13 -4 0 -8 6 -8 13 0 8 6 14 13 14 7 0 10 -6 7 -14z m5357 2 c-7 -9 -15 -13
-17 -11 -7 7 7 26 19 26 6 0 6 -6 -2 -15z m615 -17 c1 -15 -1 -28 -3 -28 -1 0
-15 11 -30 25 -28 26 -48 33 -36 13 6 -10 4 -10 -7 -2 -8 6 -21 14 -29 16 -8
3 12 5 45 5 59 -1 60 -1 60 -29z m819 -7 c-4 -17 -9 -30 -11 -27 -3 2 -2 19 2
36 9 41 17 33 9 -9z m3082 20 c1 -6 0 -7 -2 -2 -4 7 -12 6 -24 -1 -21 -13 -36
-6 -25 11 8 14 48 8 51 -8z m8930 -16 c2 -33 -2 -32 -14 4 -8 22 -8 29 1 25 7
-2 13 -15 13 -29z m-23662 -10 c-4 -8 -10 -12 -15 -9 -11 6 -2 24 11 24 5 0 7
-7 4 -15z m10258 -14 c-23 -24 -38 -14 -16 10 9 10 20 16 25 13 5 -2 1 -13 -9
-23z m-11347 -62 c0 -36 -1 -38 -13 -22 -13 17 -14 16 -7 -4 4 -14 2 -23 -5
-23 -6 0 -9 4 -6 9 4 5 1 11 -5 13 -6 2 -2 13 10 27 16 19 18 27 10 38 -9 10
-9 12 2 8 9 -3 14 -20 14 -46z m4799 32 c-3 -3 -9 -6 -12 -6 -4 -1 -17 -6 -28
-13 -18 -9 -24 -9 -33 2 -10 11 -8 15 6 18 28 6 73 5 67 -1z m1076 -1 c12 -8
12 -10 -3 -16 -9 -3 -15 -9 -13 -13 3 -4 1 -13 -3 -19 -4 -8 -8 2 -8 23 0 37
3 40 27 25z m1327 -28 c1 -18 -3 -32 -8 -32 -6 0 -8 4 -5 9 3 5 -1 14 -9 21
-20 17 -19 39 3 36 12 -2 18 -13 19 -34z m4783 28 c0 -14 -28 -19 -33 -6 -3 6
4 13 14 13 10 1 19 -2 19 -7z m4480 6 c0 -2 -7 -6 -15 -10 -8 -3 -15 -1 -15 4
0 6 7 10 15 10 8 0 15 -2 15 -4z m2 -29 c-12 -13 -19 -28 -15 -32 5 -5 0 -3
-10 5 -17 13 -17 14 7 29 14 9 26 20 26 25 0 5 3 6 7 3 3 -4 -3 -18 -15 -30z
m3241 16 c3 9 15 15 26 14 24 -2 28 -15 6 -23 -8 -4 -15 -10 -15 -15 0 -4 8
-5 18 -2 15 6 15 5 3 -10 -15 -19 -31 -14 -31 11 0 10 -3 13 -10 6 -6 -6 -6
-21 -1 -40 6 -20 5 -36 -3 -46 -8 -12 -11 1 -12 56 0 39 2 62 6 52 6 -17 7
-17 13 -3z m1157 5 c0 -9 4 -8 10 2 8 13 10 12 10 -3 0 -12 -6 -17 -17 -15
-18 3 -31 28 -14 28 6 0 11 -6 11 -12z m2860 7 c0 -7 -30 -13 -34 -7 -3 4 4 9
15 9 10 1 19 0 19 -2z m939 -14 c1 24 31 19 32 -5 0 -20 1 -20 9 2 11 26 16
27 24 6 3 -8 2 -12 -4 -9 -6 3 -10 -1 -10 -9 0 -9 -4 -16 -9 -16 -5 0 -12 -8
-15 -17 -4 -10 -14 -28 -24 -41 -17 -22 -18 -22 -18 -2 0 11 3 20 7 20 10 0
33 60 26 66 -3 4 -12 -9 -20 -27 l-14 -34 0 45 c0 32 2 39 8 25 5 -14 8 -16 8
-4z m-16386 -21 c-15 -16 -29 -30 -30 -30 -7 0 13 37 26 47 30 23 33 13 4 -17z
m3762 20 c3 -5 -1 -10 -10 -10 -9 0 -13 5 -10 10 3 6 8 10 10 10 2 0 7 -4 10
-10z m2078 -20 c-15 -16 -29 -30 -30 -30 -7 0 13 37 26 47 30 23 33 13 4 -17z
m2361 -14 c-7 -18 12 -30 51 -30 28 -1 30 -3 31 -43 0 -23 -4 -39 -8 -36 -5 3
-7 10 -5 17 2 6 0 22 -4 35 -7 17 -15 22 -29 18 -26 -7 -55 16 -47 37 4 9 9
16 11 16 3 0 3 -6 0 -14z m2221 -5 c3 -5 2 -12 -3 -15 -5 -3 -9 1 -9 9 0 17 3
19 12 6z m-7481 -36 c3 -8 1 -15 -4 -15 -6 0 -10 7 -10 15 0 8 2 15 4 15 2 0
6 -7 10 -15z m26 5 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0
11 -4 11 -10z m850 0 c-19 -13 -30 -13 -30 0 0 6 10 10 23 10 18 0 19 -2 7
-10z m70 -22 c0 -6 -9 -3 -20 7 -24 21 -25 17 -6 -20 9 -18 16 -22 20 -13 3 7
3 2 0 -10 -5 -21 -3 -23 18 -17 18 5 20 4 8 -4 -13 -9 -13 -11 3 -11 9 0 17 4
17 9 0 6 8 9 19 8 13 -1 18 -8 17 -24 -1 -20 -3 -21 -12 -8 -7 9 -18 13 -28 9
-19 -7 -66 21 -66 40 0 7 -7 19 -15 26 -22 19 0 43 26 29 10 -5 19 -15 19 -21z
m11678 1 c1 -17 -4 -29 -15 -33 -23 -9 -37 8 -19 23 8 7 13 18 10 25 -3 7 1
13 9 13 7 0 14 -12 15 -28z m2302 20 c0 -5 3 -8 8 -6 4 3 8 -6 8 -19 l2 -24
-19 24 c-21 27 -24 36 -9 36 6 0 10 -5 10 -11z m-26147 -16 c-7 -19 -32 -42
-46 -43 -5 0 4 14 20 30 33 35 35 36 26 13z m12127 -46 c0 -3 -11 4 -24 16
-13 12 -22 25 -19 30 4 7 43 -34 43 -46z m553 5 c2 -10 -2 -23 -8 -29 -10 -9
-14 -7 -19 10 -3 12 -6 27 -6 34 0 18 27 5 33 -15z m-11774 -28 c-7 -8 -6 -14
2 -20 8 -5 9 -3 4 6 -5 8 -4 11 3 6 15 -9 5 -33 -13 -33 -10 0 -15 10 -15 27
0 17 5 27 15 27 11 0 12 -4 4 -13z m1653 7 c2 7 4 -5 5 -25 1 -30 -1 -35 -13
-25 -8 6 -12 15 -9 19 3 5 1 11 -5 15 -5 3 -10 11 -10 17 0 7 5 7 14 -1 11 -9
15 -9 18 0z m715 3 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z
m1686 -1 c-7 -2 -13 -12 -13 -20 0 -9 -3 -14 -6 -10 -10 10 4 37 18 36 10 0
10 -2 1 -6z m742 -3 c-3 -5 1 -10 10 -10 9 0 13 5 9 11 -4 7 1 10 12 7 20 -3
22 -23 3 -23 -6 0 -17 -8 -24 -18 -15 -21 -26 -11 -20 19 5 25 4 24 11 24 3 0
2 -4 -1 -10z m4940 -31 c1 -19 4 -39 8 -46 4 -6 2 -15 -4 -19 -8 -4 -9 -3 -5
4 4 7 2 12 -4 12 -7 0 -9 8 -5 19 3 11 1 22 -5 26 -6 3 -8 11 -5 16 4 5 1 9
-5 9 -6 0 -8 5 -5 11 13 20 30 2 30 -32z m6514 7 c-6 -21 -5 -32 4 -38 6 -4 8
-8 2 -8 -5 0 -4 -4 4 -9 9 -6 10 -11 2 -15 -6 -4 -11 -2 -11 3 0 6 -6 10 -12
9 -8 -2 -13 10 -14 30 -1 17 2 30 7 26 5 -3 9 0 9 5 0 6 -4 11 -10 11 -5 0
-10 4 -10 8 0 4 8 8 19 8 16 0 17 -5 10 -30z m3888 17 c0 -6 -3 -11 -6 -10 -3
1 -7 -2 -7 -8 -1 -5 2 -9 7 -7 5 1 9 -2 9 -8 0 -15 -16 -12 -30 5 -7 8 -9 15
-4 15 4 0 0 6 -9 13 -15 11 -14 12 11 12 16 1 29 -5 29 -12z m2881 -13 c-1
-30 -11 -39 -23 -20 -4 6 -10 8 -15 5 -5 -3 -11 -1 -15 5 -4 6 -10 8 -15 5 -5
-3 -11 -2 -14 3 -8 13 28 11 36 -2 5 -7 8 -7 8 2 0 7 5 10 10 7 6 -3 13 1 16
9 9 24 13 19 12 -14z m700 18 c-2 -6 -10 -13 -16 -14 -7 -1 -8 0 -2 3 5 2 8 9
4 14 -3 5 0 9 6 9 7 0 10 -5 8 -12z m-10943 -72 c0 -36 -4 -68 -9 -73 -5 -4
-6 5 -2 22 6 28 5 29 -9 11 -8 -11 -18 -17 -22 -13 -3 4 2 16 12 27 10 11 13
20 7 20 -6 0 -12 -6 -15 -12 -2 -7 -8 -10 -12 -6 -13 12 15 37 33 31 14 -5 15
-3 5 7 -8 9 -9 19 -2 32 5 10 11 18 12 18 1 0 2 -29 2 -64z m3505 60 c0 -3 -4
-8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m-9823 -38
c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m4866 7 c9 -4 17 -11 17
-17 0 -5 -4 -7 -9 -4 -5 4 -12 0 -14 -6 -3 -8 -6 -6 -6 5 -1 9 -6 17 -12 17
-5 0 -7 -4 -4 -10 10 -17 -1 -11 -19 9 -17 19 -17 20 5 17 13 -2 32 -7 42 -11z
m442 4 c11 -17 -1 -21 -15 -4 -8 9 -8 15 -2 15 6 0 14 -5 17 -11z m-9425 -19
c0 -5 -6 -10 -12 -10 -7 0 -5 -5 5 -11 14 -8 15 -14 7 -24 -6 -8 -18 -11 -26
-8 -11 4 -12 9 -3 19 9 11 8 14 -1 14 -10 0 -10 3 0 15 14 17 30 20 30 5z
m5970 -4 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10
-4z m-1854 -40 c-1 -19 -5 -32 -9 -29 -5 3 -6 7 -3 10 3 3 1 15 -5 26 -8 14
-8 18 0 13 6 -4 11 -2 11 3 0 6 2 11 3 11 2 0 3 -15 3 -34z m3360 -16 c2 -39
1 -41 -6 -15 l-8 30 -1 -27 c0 -16 -5 -28 -9 -28 -12 0 -32 22 -32 35 0 5 7 1
15 -9 11 -14 15 -16 15 -5 0 8 -7 23 -15 33 -21 28 -19 35 13 33 25 -2 27 -5
28 -47z m397 34 c-3 -14 -23 -17 -23 -3 0 8 -7 3 -16 -9 -8 -12 -12 -27 -8
-34 5 -7 3 -8 -6 -3 -10 6 -12 4 -7 -8 3 -8 -3 -25 -14 -38 -19 -22 -19 -22
-19 -1 0 11 6 22 12 24 8 3 12 21 12 46 l-1 41 36 -3 c20 -1 35 -7 34 -12z
m4000 9 c4 8 6 6 6 -4 1 -11 -8 -16 -31 -16 -18 0 -27 2 -20 5 6 2 9 8 6 14
-4 6 1 6 14 -1 15 -8 22 -8 25 2z m357 -13 c0 -24 4 -25 33 -5 21 13 23 13 23
-5 0 -10 -4 -16 -9 -13 -5 3 -13 -1 -18 -8 -6 -11 -11 -11 -20 -2 -9 9 -16 10
-26 2 -9 -8 -13 -8 -13 0 0 6 5 11 11 11 8 0 9 4 0 13 -6 8 -8 17 -4 20 12 13
23 7 23 -13z m37 14 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z
m1206 -15 c-4 -15 -9 -17 -17 -9 -8 8 -7 13 4 20 8 5 16 10 17 10 0 0 -1 -9
-4 -21z m7820 7 c3 -8 -3 -22 -13 -31 -13 -12 -17 -23 -11 -34 9 -16 -1 -55
-16 -64 -5 -3 -9 3 -9 13 0 10 3 17 7 14 11 -6 18 21 9 36 -6 10 -9 10 -13 -1
-2 -8 -2 -3 0 11 3 14 11 29 19 33 8 4 14 13 14 20 0 7 -4 6 -10 -3 -5 -8 -10
-10 -10 -4 0 24 25 32 33 10z m-23720 -66 c3 -11 1 -23 -4 -26 -5 -3 -9 6 -9
20 0 31 6 34 13 6z m3423 -12 c4 -6 3 -8 -3 -5 -6 4 -13 0 -17 -8 -3 -8 -11
-15 -17 -15 -7 0 -5 8 6 20 21 23 22 24 31 8z m3134 2 c0 -5 -2 -10 -4 -10 -3
0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m3620 1 c0 -3 -7 -14 -14
-24 -13 -17 -14 -16 -12 6 0 13 7 24 14 24 6 0 12 -3 12 -6z m20 -8 c0 -22
-17 -50 -24 -42 -5 5 15 59 22 59 1 0 2 -8 2 -17z m2858 5 c-2 -6 -10 -14 -16
-16 -7 -2 -10 2 -6 12 7 18 28 22 22 4z m5686 -3 c-4 -8 -9 -15 -13 -15 -12 0
-5 -51 8 -60 8 -5 9 -10 3 -14 -17 -10 -35 51 -25 87 2 9 11 17 18 17 9 0 12
-6 9 -15z m1611 4 c11 -17 2 -18 -25 -4 -24 14 -24 14 -3 15 12 0 25 -5 28
-11z m958 4 c-7 -2 -13 -9 -13 -14 0 -4 7 -6 15 -3 8 4 15 2 15 -4 0 -17 -28
-22 -34 -6 -10 26 -7 34 12 33 10 0 12 -3 5 -6z m1841 -4 c-2 -4 0 -10 6 -14
5 -3 10 -11 10 -17 0 -7 -9 -2 -20 10 -24 25 -24 29 -5 29 8 0 12 -4 9 -8z
m-13272 -18 c8 11 9 10 4 -3 -3 -10 -1 -20 4 -23 6 -4 10 2 10 11 0 10 3 15 6
11 3 -3 3 -16 -1 -28 l-7 -22 -19 24 c-10 13 -18 29 -18 34 0 6 3 4 6 -4 5
-11 7 -11 15 0z m-12862 -16 c0 -13 -44 -43 -56 -38 -12 4 -11 8 3 18 10 7 21
11 25 9 4 -2 9 0 13 6 6 10 15 14 15 5z m5310 -40 c5 3 12 0 16 -6 5 -8 3 -9
-5 -5 -8 5 -11 3 -9 -6 2 -7 -1 -16 -7 -18 -5 -3 -10 9 -11 25 -1 17 0 24 3
17 3 -6 9 -10 13 -7z m9097 -5 c1 -11 -1 -22 -5 -24 -4 -2 -8 7 -8 20 -1 13 1
24 5 24 4 0 7 -9 8 -20z m-7897 -10 c0 -11 -2 -20 -4 -20 -2 0 -6 9 -9 20 -3
11 -1 20 4 20 5 0 9 -9 9 -20z m1220 13 c0 -5 -9 -21 -19 -37 l-19 -28 3 33
c2 19 3 35 4 37 3 6 31 1 31 -5z m14887 -25 c5 -7 13 -21 16 -30 5 -15 1 -17
-26 -15 -25 2 -32 7 -33 24 -1 13 2 20 7 18 4 -3 10 -1 14 5 3 5 -1 10 -9 10
-9 0 -16 5 -16 10 0 13 31 -2 47 -22z m-8228 10 c-3 -26 1 -39 11 -33 11 7 14
-8 4 -18 -10 -9 -42 35 -37 50 7 17 24 17 22 1z m12222 -12 c5 3 9 1 9 -5 0
-5 -5 -13 -10 -16 -6 -4 -8 -10 -6 -14 3 -4 1 -12 -3 -19 -4 -7 -7 6 -7 29 0
23 2 37 4 30 2 -6 8 -9 13 -5z m-14991 1 c0 -2 -12 -14 -26 -28 -25 -22 -26
-23 -15 -2 9 18 19 26 39 32 1 1 2 0 2 -2z m5621 -11 c5 3 9 2 9 -2 0 -11 -43
-45 -49 -38 -3 3 2 10 11 15 10 5 16 15 14 22 -1 6 -1 9 1 4 3 -4 9 -5 14 -1z
m319 -6 c0 -4 -6 -10 -14 -13 -12 -5 -14 2 -7 31 2 7 21 -10 21 -18z m4810 10
c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z
m6197 -12 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-3297 -18 c0
-5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m-25470
-30 c0 -16 5 -19 24 -14 14 3 27 1 31 -7 5 -8 4 -10 -4 -5 -6 4 -17 3 -24 -2
-15 -12 -62 22 -52 38 10 17 25 11 25 -10z m37 14 c-3 -3 -12 -4 -19 -1 -8 3
-5 6 6 6 11 1 17 -2 13 -5z m10458 -15 c6 -19 -21 -76 -32 -66 -2 3 -6 23 -8
44 -4 32 -1 39 15 40 11 1 22 -7 25 -18z m13902 -54 c7 11 13 15 13 10 0 -13
-20 -35 -32 -35 -4 0 -7 24 -6 53 l1 52 6 -50 c5 -45 7 -48 18 -30z m4 32 c-8
-8 -11 -5 -11 9 0 27 14 41 18 19 2 -9 -1 -22 -7 -28z m-17664 -17 c-2 -35
-17 -41 -17 -7 0 21 7 37 16 37 2 0 2 -13 1 -30z m18753 -8 c0 -15 -2 -15 -10
-2 -8 12 -10 12 -10 1 0 -8 -2 -12 -5 -9 -3 3 -3 13 1 22 7 19 24 11 24 -12z
m-19043 -67 c1 -14 -5 -21 -17 -22 -20 -2 -35 23 -20 32 6 4 10 -2 10 -12 1
-17 1 -17 12 1 8 15 7 21 -6 28 -9 5 -14 14 -10 19 8 13 30 -19 31 -46z m3413
45 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z
m3350 -3 c0 -14 -20 -37 -32 -37 -4 0 -7 11 -6 25 2 17 8 25 20 25 10 0 18 -6
18 -13z m2900 -1 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0
10 -2 10 -4z m1298 -43 c0 -21 -2 -23 -10 -9 -5 9 -6 21 -3 26 10 16 13 12 13
-17z m202 -44 c-16 -10 -30 -24 -30 -31 1 -8 6 -6 14 5 7 9 17 17 21 17 5 0 0
-9 -10 -20 -21 -24 -29 -19 -31 18 -1 24 2 27 32 28 l34 1 -30 -18z m-17701
-13 c8 -10 8 -16 -3 -25 -11 -9 -13 -7 -13 14 0 29 1 30 16 11z m7876 -5 c3
-5 0 -13 -6 -16 -5 -4 -8 -12 -6 -19 3 -7 -3 -13 -13 -13 -16 -1 -16 2 -7 28
12 30 21 36 32 20z m-48 -23 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1
-19z m11193 6 c0 -8 -5 -12 -10 -9 -6 4 -8 11 -5 16 9 14 15 11 15 -7z
m-15973 -39 c9 -24 -1 -27 -15 -5 -8 13 -8 20 -2 20 6 0 13 -7 17 -15z m10193
5 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z
m-1745 -24 c0 -16 -4 -22 -14 -18 -10 4 -13 1 -9 -9 4 -10 -2 -14 -18 -14 -29
0 -31 11 -5 20 11 3 23 15 26 26 9 26 20 24 20 -5z m-3659 -8 l-6 -23 11 23
c7 12 16 22 21 22 11 0 -6 -32 -25 -48 -11 -9 -14 -8 -14 6 -2 22 5 42 12 42
4 0 4 -10 1 -22z m17835 -65 c-8 -15 -10 -15 -11 -2 0 17 10 32 18 25 2 -3 -1
-13 -7 -23z m-17866 7 c3 -5 -1 -10 -10 -10 -9 0 -13 5 -10 10 3 6 8 10 10 10
2 0 7 -4 10 -10z m11115 0 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10
10 10 6 0 10 -4 10 -10z m-7500 -53 c0 -5 -6 -5 -12 -1 -10 6 -10 5 -1 -7 6
-8 11 -30 11 -49 -1 -19 -3 -29 -6 -22 -3 9 -8 10 -17 2 -19 -16 -39 13 -26
37 6 10 16 31 22 48 8 23 12 26 19 15 6 -8 10 -19 10 -23z m6104 -18 c-2 -4 0
-10 6 -14 7 -4 7 -12 0 -24 -5 -10 -6 -21 -3 -25 3 -3 -1 -6 -10 -6 -10 0 -14
-5 -11 -15 4 -10 0 -15 -15 -15 -11 0 -25 -9 -31 -19 -15 -30 -12 -38 6 -13 8
12 20 22 26 22 6 0 2 -10 -10 -23 -12 -13 -19 -27 -15 -32 5 -4 2 -5 -4 -1
-12 6 -18 -5 -14 -26 0 -5 -6 -8 -15 -8 -13 0 -14 3 -5 14 8 10 9 16 1 21 -5
3 -9 14 -9 23 1 15 2 15 6 0 7 -27 21 -22 16 5 -5 25 10 42 42 50 16 4 15 5
-6 6 -17 1 -32 -7 -42 -21 l-14 -23 0 27 c0 15 2 26 6 24 4 -3 22 -1 41 3 33
6 35 9 33 42 -2 25 2 36 12 36 8 0 12 -4 9 -8z m4241 -6 c3 -10 1 -19 -4 -21
-6 -2 -11 -8 -11 -13 0 -6 5 -7 10 -4 6 3 10 1 10 -5 0 -7 -6 -10 -12 -7 -7 2
-13 18 -13 35 0 35 9 42 20 15z m37 -5 c-2 -10 -9 -21 -15 -25 -6 -3 -7 -1 -3
6 4 6 2 18 -4 26 -9 11 -8 14 8 12 13 -1 18 -7 14 -19z m-19665 -10 c-3 -8 -6
-5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m3225 -36 c-7 -19 -15 -6 -19 30
-3 33 -3 33 10 8 8 -14 12 -31 9 -38z m9512 31 c-10 -40 -14 -41 -14 -5 0 18
5 32 10 32 6 0 8 -11 4 -27z m7584 -3 c-1 -18 -6 -30 -15 -30 -16 0 -16 2 -1
35 15 32 17 32 16 -5z m-16078 -19 c0 -5 -7 -11 -14 -14 -10 -4 -13 -1 -9 9 6
15 23 19 23 5z m18925 -13 c6 -4 2 -8 -7 -8 -10 0 -18 7 -18 17 0 9 3 12 8 7
4 -5 12 -12 17 -16z m27 0 c-7 -7 -12 -8 -12 -2 0 14 12 26 19 19 2 -3 -1 -11
-7 -17z m-25616 -19 c3 -5 0 -9 -6 -9 -6 0 -8 -5 -4 -12 5 -7 3 -8 -7 -2 -8 5
-18 9 -23 9 -5 0 -11 5 -13 11 -4 13 45 16 53 3z m3238 -6 c-5 -15 -3 -15 10
1 9 11 16 15 16 9 0 -6 -9 -21 -19 -34 l-20 -24 3 30 c4 34 4 35 11 35 2 0 2
-8 -1 -17z m23679 -3 c-3 -12 -8 -19 -11 -16 -5 6 5 36 12 36 2 0 2 -9 -1 -20z
m2109 -2 c-7 -7 -12 -8 -12 -2 0 14 12 26 19 19 2 -3 -1 -11 -7 -17z m-17219
-54 c-8 -22 -18 -16 -21 12 -2 22 0 24 12 14 8 -6 12 -18 9 -26z m2887 11 c-7
-8 -16 -15 -21 -15 -5 0 -1 9 8 20 9 11 19 17 21 15 3 -3 -1 -11 -8 -20z
m-3990 11 c0 -2 -7 -7 -16 -10 -8 -3 -12 -2 -9 4 6 10 25 14 25 6z m1033 -41
c-3 -9 -8 -14 -10 -11 -3 3 -2 9 2 15 9 16 15 13 8 -4z m5805 -10 c0 -14 -4
-28 -9 -30 -6 -4 -6 39 0 53 4 9 9 -2 9 -23z m4413 -27 c-9 -20 -10 -18 -10
17 0 31 2 36 9 23 6 -10 7 -25 1 -40z m49 33 c-12 -24 -43 -94 -51 -116 -5
-14 -7 -9 -6 17 1 20 7 36 12 35 6 -1 9 9 7 22 -2 15 0 21 7 17 13 -9 14 0 2
23 -8 14 -5 17 14 17 18 0 22 -4 15 -15z m2524 -12 c-10 -16 -24 -9 -24 12 0
18 2 19 15 9 8 -7 12 -16 9 -21z m1366 11 c0 -17 5 -19 28 -15 25 6 25 5 7 -6
-11 -7 -20 -13 -20 -13 0 0 -11 7 -24 16 -13 9 -20 21 -16 27 10 18 25 12 25
-9z m37 14 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-6827 -14
c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z
m5077 -28 c0 -19 -6 -28 -18 -29 -16 -2 -16 0 -1 28 10 16 17 29 18 29 0 0 1
-13 1 -28z m-13652 -2 c3 -5 17 -10 31 -10 13 0 24 -4 24 -8 0 -4 -32 -8 -71
-8 l-72 -1 34 18 c40 21 46 22 54 9z m16132 -9 c0 -11 -5 -18 -9 -15 -4 3 -5
11 -1 19 7 21 12 19 10 -4z m-13522 -10 c-3 -6 -11 -11 -17 -11 -6 0 -6 6 2
15 14 17 26 13 15 -4z m8482 -3 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12
1 -19z m1552 -28 c0 -10 -2 -11 -6 -2 -2 6 -8 10 -12 8 -4 -3 -8 7 -9 22 -2
25 -2 25 13 6 8 -10 14 -26 14 -34z m1348 29 c-4 -15 -2 -19 9 -15 11 4 10 -1
-3 -22 -9 -15 -17 -28 -18 -29 0 -1 -2 18 -3 42 -1 27 3 45 9 45 7 0 9 -9 6
-21z m-24633 -8 c3 -4 -1 -16 -8 -26 -13 -18 -14 -18 -26 4 -8 15 -8 21 -1 17
6 -4 11 -2 11 3 0 13 17 15 24 2z m-49 -20 c8 -20 -7 -31 -33 -25 -23 7 -31
24 -9 24 8 0 7 4 -3 10 -12 8 -9 9 12 8 15 -1 30 -9 33 -17z m17848 -1 c3 -11
1 -18 -4 -14 -5 3 -9 12 -9 20 0 20 7 17 13 -6z m9177 8 c0 -4 -9 -8 -21 -8
-11 0 -18 4 -15 8 3 5 12 9 21 9 8 0 15 -4 15 -9z m-1323 -20 c-3 -8 -6 -5 -6
6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-13821 3 c-4 -5 -1 -11 5 -13 7 -2 6 -8
-4 -16 -12 -10 -14 -8 -12 10 3 27 3 28 11 28 3 0 3 -4 0 -9z m-5216 -41 c0
-5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m10958 -16
c0 -17 -3 -22 -8 -13 -5 6 -6 18 -3 26 8 19 11 16 11 -13z m-9528 2 c0 -3 -4
-8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m2666 -27 c-5
-25 -3 -28 7 -17 7 7 16 22 20 32 4 10 10 17 13 14 5 -5 -15 -58 -26 -68 -5
-5 -11 -34 -11 -55 0 -5 3 -21 7 -35 6 -24 6 -24 -9 -5 -19 23 -22 51 -7 60 6
3 10 11 10 16 0 6 -4 8 -8 5 -4 -2 -8 15 -8 40 0 24 4 44 9 44 5 0 7 -14 3
-31z m-5071 11 c3 -5 -1 -10 -10 -10 -9 0 -13 5 -10 10 3 6 8 10 10 10 2 0 7
-4 10 -10z m15980 -9 c-3 -6 -11 -11 -17 -11 -6 0 -6 6 2 15 14 17 26 13 15
-4z m-5519 -24 c0 -14 -8 -24 -18 -24 -17 -2 -17 -1 0 22 21 30 20 30 18 2z
m1462 -18 c-10 -6 -18 -17 -18 -26 0 -14 2 -14 14 3 13 17 13 14 3 -23 -6 -24
-15 -43 -20 -43 -5 0 -7 7 -3 15 3 8 1 15 -4 15 -12 0 -7 39 9 69 10 18 16 20
24 11 9 -8 8 -14 -5 -21z m4439 -26 c5 3 9 -2 9 -12 0 -27 -31 -20 -33 8 -1
15 2 19 7 11 4 -7 12 -10 17 -7z m4580 -43 c1 -15 -4 -20 -20 -19 -21 0 -21 1
-2 9 13 6 15 9 5 9 -13 1 -13 2 0 11 8 5 15 10 15 10 1 0 1 -9 2 -20z m-11889
-48 c-2 -40 -5 -48 -22 -50 -15 -1 -17 1 -8 7 8 4 16 27 19 50 8 56 13 52 11
-7z m-14914 17 c3 -3 -6 -18 -19 -33 -15 -18 -25 -23 -25 -15 0 8 5 20 12 27
9 9 9 12 -1 12 -7 0 -10 4 -7 9 6 9 31 9 40 0z m3436 -6 c0 -8 -3 -12 -7 -10
-5 3 -9 -8 -10 -24 -1 -16 -6 -37 -11 -45 -8 -14 -9 21 -3 87 2 13 31 5 31 -8z
m9877 -13 c1 -17 -1 -30 -6 -30 -4 0 -8 14 -9 30 -1 39 12 39 15 0z m2026 -12
c8 -5 8 -13 0 -30 -6 -13 -9 -40 -8 -59 2 -20 0 -41 -4 -47 -4 -7 -7 32 -7 86
0 53 1 88 4 77 2 -11 9 -23 15 -27z m11776 18 c-8 -8 -13 -8 -16 2 -3 6 -10 9
-15 6 -6 -4 -8 -2 -5 4 4 5 16 8 28 5 15 -4 17 -8 8 -17z m-19325 -1 c-4 -8
-11 -15 -16 -15 -6 0 -5 6 2 15 7 8 14 15 16 15 2 0 1 -7 -2 -15z m11525 -34
c-1 -27 -3 -30 -6 -11 -3 14 -9 29 -14 35 -14 14 -11 25 6 19 10 -4 15 -18 14
-43z m-13499 12 c0 -5 -5 -15 -10 -23 -8 -12 -10 -11 -10 8 0 12 5 22 10 22 6
0 10 -3 10 -7z m7625 -13 c-3 -5 -12 -10 -18 -10 -7 0 -6 4 3 10 19 12 23 12
15 0z m-6319 -45 c1 -14 -2 -22 -7 -19 -11 6 -12 44 -1 44 4 0 8 -11 8 -25z
m18954 15 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11
-10z m-22640 -10 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0
11 -4 11 -10z m15877 -28 c0 -19 -6 -28 -18 -29 -16 -2 -16 0 -1 28 10 16 17
29 18 29 0 0 1 -13 1 -28z m9839 -10 c0 -10 -6 -19 -13 -19 -14 0 -18 19 -6
30 11 12 19 8 19 -11z m-21456 -4 c0 -9 4 -8 10 2 8 13 10 12 10 -2 0 -12 -8
-18 -24 -18 -28 0 -43 16 -24 23 22 9 28 8 28 -5z m11991 -21 c-8 -8 -11 -7
-11 4 0 20 13 34 18 19 3 -7 -1 -17 -7 -23z m-14226 13 c3 -5 1 -10 -4 -10 -6
0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m17348 -76 c-7 -18 -9 -16 -17
21 -6 28 -5 29 8 12 8 -11 12 -25 9 -33z m26 3 c0 -21 -1 -21 -9 -2 -12 28
-12 37 0 30 6 -3 10 -16 9 -28z m-17445 -16 c-2 -2 -10 2 -19 9 -10 8 -15 9
-15 1 0 -6 -4 -11 -9 -11 -8 0 -8 13 -2 38 1 2 12 -5 25 -15 13 -10 22 -20 20
-22z m12286 25 c0 -11 -19 -15 -25 -6 -3 5 1 10 9 10 9 0 16 -2 16 -4z
m-12364 -40 c-10 -15 -12 -27 -6 -31 22 -14 9 -33 -21 -31 -30 1 -30 1 -10 17
11 9 18 19 15 22 -5 5 26 47 34 47 3 0 -3 -11 -12 -24z m9984 3 c0 -5 -4 -7
-10 -4 -5 3 -10 1 -10 -6 0 -7 -3 -10 -6 -6 -8 7 4 27 17 27 5 0 9 -5 9 -11z
m3925 -7 c14 -9 17 -50 4 -57 -5 -3 -6 2 -3 12 5 16 4 16 -10 -1 -14 -17 -16
-17 -16 -4 0 9 5 20 10 23 8 5 7 11 -1 21 -12 15 -1 19 16 6z m9770 -60 c-3
-3 -5 0 -5 6 0 7 -6 12 -14 12 -8 0 -13 8 -11 18 2 9 4 19 4 22 1 3 8 -8 16
-24 9 -16 13 -31 10 -34z m-4295 22 c0 -8 -4 -12 -10 -9 -5 3 -10 13 -10 21 0
8 5 12 10 9 6 -3 10 -13 10 -21z m-21330 -4 c-12 -12 -18 -25 -15 -30 7 -12
-12 -50 -25 -50 -6 0 -9 19 -8 42 1 24 3 37 6 30 2 -6 9 -12 15 -12 5 0 7 3 4
7 -9 9 12 33 29 33 10 0 8 -6 -6 -20z m4156 -8 c-13 -13 -17 -13 -32 -1 -15
13 -15 14 7 20 33 8 44 0 25 -19z m34 3 c0 -8 -4 -15 -10 -15 -5 0 -7 7 -4 15
4 8 8 15 10 15 2 0 4 -7 4 -15z m2957 -7 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3
-3 4 -12 1 -19z m-2446 -37 l1 -26 7 24 c4 14 12 26 19 27 7 1 8 0 2 -3 -5 -2
-10 -13 -10 -22 0 -24 -36 -65 -45 -51 -3 5 -1 10 4 10 6 0 11 7 11 16 0 8 -3
13 -7 11 -5 -3 -8 5 -8 17 0 32 25 28 26 -3z m9628 13 c-6 -8 -15 -14 -19 -14
-4 0 -10 6 -13 14 -4 9 2 14 19 14 20 0 22 -3 13 -14z m3813 -6 c-7 -7 -12 -8
-12 -2 0 14 12 26 19 19 2 -3 -1 -11 -7 -17z m5508 -11 c0 -5 -4 -5 -10 -2 -5
3 -10 14 -10 23 0 15 2 15 10 2 5 -8 10 -19 10 -23z m-19580 -27 c0 -5 -5 -10
-11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m-4090 -14 c0 -15
-14 -35 -21 -30 -15 9 -10 34 6 34 8 0 15 -2 15 -4z m9877 -12 c-3 -3 -12 -4
-19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m1999 -6 c4 -7 3 -8 -4 -4 -7 4 -12 2
-12 -3 0 -6 -5 -11 -11 -11 -8 0 -8 5 1 15 15 18 17 18 26 3z m6810 1 c5 -8 3
-9 -6 -4 -21 13 -42 -23 -28 -49 11 -20 10 -20 -13 -5 -13 9 -25 25 -26 35 -2
14 -1 14 7 -3 5 -13 11 -23 13 -23 6 0 2 34 -5 46 -4 6 5 11 22 12 16 1 32 -3
36 -9z m7434 7 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10
-2 10 -4z m-22266 -27 c-4 -7 0 -9 13 -5 15 5 19 1 19 -19 0 -25 -19 -34 -31
-15 -3 6 2 10 12 11 16 0 16 1 -2 8 -19 8 -39 -5 -30 -19 2 -4 -23 -7 -55 -7
-33 0 -60 4 -60 9 0 5 12 7 28 4 15 -3 41 -2 57 4 29 10 29 10 -6 5 -35 -5
-97 10 -87 21 3 2 17 -1 31 -7 21 -9 33 -9 64 4 46 19 56 21 47 6z m14216 1
c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m4777 4
c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m0 -20 c-3 -3 -12 -4
-19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-13971 -31 c-4 -4 -12 -1 -19 7 -9
10 -9 11 1 6 7 -4 12 -2 13 6 0 11 2 11 6 0 3 -7 2 -16 -1 -19z m11971 -29 c1
-24 -3 -46 -8 -49 -5 -3 -6 2 -2 11 4 11 2 15 -4 11 -7 -4 -10 5 -7 26 1 17 3
38 3 46 2 33 16 -4 18 -45z m1814 46 c10 0 19 -4 19 -10 0 -5 -15 -9 -33 -7
-20 1 -34 7 -34 15 0 7 7 10 14 7 8 -3 23 -5 34 -5z m3757 -7 c0 -10 3 -27 7
-38 6 -14 5 -17 -3 -10 -10 10 -15 29 -13 53 2 19 10 14 9 -5z m-24692 -61 c1
-20 0 -29 -3 -19 -2 9 -9 17 -14 17 -6 0 -6 7 0 19 6 10 8 21 6 25 -3 3 -2 4
2 1 4 -3 8 -22 9 -43z m24318 31 c-10 -7 -29 -23 -44 -37 l-27 -24 0 24 c0 13
4 24 9 24 5 0 6 -4 3 -10 -3 -5 -4 -10 -1 -10 13 0 15 20 2 33 -12 12 -8 14
30 13 38 -1 42 -3 28 -13z m-11216 -5 c-1 -7 3 -22 9 -33 10 -18 8 -20 -26
-22 -27 -2 -38 2 -38 12 0 8 4 12 8 9 4 -2 10 0 14 6 4 6 11 7 17 4 7 -4 8 -2
4 4 -4 7 -4 17 -1 22 9 14 16 12 13 -2z m3735 -18 c-15 -16 -29 -30 -30 -30
-7 0 13 37 26 47 30 23 33 13 4 -17z m-16853 -28 c0 -5 -7 -9 -15 -9 -8 0 -12
4 -9 9 3 4 9 8 15 8 5 0 9 -4 9 -8z m11571 -10 c-7 -2 -18 1 -23 6 -8 8 -4 9
13 5 13 -4 18 -8 10 -11z m972 5 c3 -8 -1 -14 -9 -14 -8 0 -14 6 -14 14 0 7 4
13 9 13 5 0 11 -6 14 -13z m11077 3 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0
6 5 10 10 10 6 0 10 -4 10 -10z m-24375 -20 c3 -6 -1 -7 -9 -4 -18 7 -21 14
-7 14 6 0 13 -4 16 -10z m3695 0 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6
-1 10 4 10 6 0 11 -4 11 -10z m18990 6 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10
4 0 6 5 11 10 11 6 0 10 -2 10 -4z m2337 -10 c4 -3 1 -6 -6 -6 -7 0 -9 -5 -5
-11 4 -7 -1 -7 -16 1 -18 10 -24 9 -36 -7 -8 -10 -12 -26 -10 -36 3 -11 2 -16
-2 -12 -4 4 -7 24 -7 45 l0 39 38 -3 c21 -2 41 -6 44 -10z m-23692 -15 c3 -5
2 -12 -3 -15 -5 -3 -9 1 -9 9 0 17 3 19 12 6z m-28 -15 c-4 -11 -26 -16 -41
-8 -10 6 10 19 30 19 8 0 13 -5 11 -11z m10973 -16 c0 -5 -4 -10 -10 -10 -5 0
-10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m4657 -4 c0 -10 -15 -13 -55
-13 -39 0 -50 2 -38 9 9 5 20 7 25 4 5 -4 20 -2 33 3 32 13 35 13 35 -3z
m-15644 -22 c-4 -12 -8 -13 -13 -3 -6 9 -9 8 -11 -4 -4 -14 -6 -14 -24 0 -12
9 -14 13 -5 11 8 -3 21 1 28 9 17 16 33 8 25 -13z m2340 -9 c-3 -9 -8 -14 -10
-11 -3 3 -2 9 2 15 9 16 15 13 8 -4z m21246 -2 c1 15 4 16 22 7 21 -12 22 -31
1 -38 -8 -2 -10 3 -6 15 5 17 5 17 -6 1 -7 -12 -8 -24 -1 -37 8 -14 7 -22 -3
-30 -18 -15 -22 -6 -24 54 0 36 2 44 8 30 8 -18 9 -18 9 -2z m-18412 -30 c-3
-10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2 -21 0 -30z m1350 -13 c-1 -39 -3 -47
-14 -39 -7 6 -10 15 -7 20 3 5 -1 9 -8 9 -8 0 -6 5 5 11 9 5 17 19 17 29 0 11
2 20 4 20 2 0 3 -22 3 -50z m11060 23 c1 -24 1 -25 -7 -8 -11 26 -12 35 -2 35
4 0 8 -12 9 -27z m1358 6 c-6 -33 -15 -36 -15 -6 0 15 4 27 9 27 6 0 8 -10 6
-21z m-1858 -33 c-6 -20 -27 -17 -27 3 0 18 -4 20 -27 15 -27 -6 -27 -6 -8 9
17 13 22 13 42 -2 12 -9 21 -20 20 -25z m-1707 25 c0 -5 8 -12 18 -14 14 -4
13 -5 -4 -6 -11 0 -29 6 -40 14 -19 14 -18 14 4 15 12 0 22 -4 22 -9z m-2763
-23 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-1667 -8 c0 -5 -2
-10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m6097 -6 c-3
-3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-8860 -31 c1 -22 -1 -24
-8 -10 -6 9 -7 22 -4 27 9 14 10 13 12 -17z m5269 11 c-3 -8 -1 -25 5 -36 6
-11 8 -22 5 -25 -9 -9 -26 45 -20 62 4 8 8 15 11 15 3 0 2 -7 -1 -16z m394 5
c0 6 4 11 8 11 5 0 9 -7 9 -15 0 -8 -2 -13 -5 -10 -3 2 -13 0 -23 -5 -20 -11
-35 2 -24 20 5 8 11 7 21 -1 10 -8 14 -8 14 0z m7327 -11 c-3 -8 -6 -5 -6 6
-1 11 2 17 5 13 3 -3 4 -12 1 -19z m-8727 -24 c0 -8 -4 -12 -10 -9 -5 3 -10
10 -10 16 0 5 5 9 10 9 6 0 10 -7 10 -16z m8747 -11 c5 -13 -76 -108 -91 -108
-6 0 -11 -5 -11 -12 0 -7 -6 -18 -12 -24 -10 -10 -12 -8 -8 10 3 14 0 21 -8
18 -7 -2 -11 -13 -9 -23 3 -18 3 -18 -12 1 -15 19 -16 19 -9 1 4 -13 3 -17 -5
-12 -7 4 -12 2 -12 -5 0 -7 -3 -9 -6 -6 -13 13 25 54 48 53 20 -1 24 5 28 38
3 27 10 40 23 42 9 1 15 -1 12 -6 -3 -4 3 -14 12 -21 15 -12 16 -12 10 3 -4
13 1 22 21 32 15 9 21 15 15 16 -7 0 -13 5 -13 11 0 13 22 7 27 -8z m-18988
-33 c-6 -5 -18 -6 -26 -3 -19 8 -16 11 13 11 17 0 22 -3 13 -8z m21815 -12
c-5 -7 -2 -9 8 -6 10 4 14 -1 14 -21 0 -22 -4 -26 -27 -26 -15 -1 -26 2 -24 6
3 3 9 18 14 33 6 14 13 26 16 26 4 0 3 -5 -1 -12z m-15017 -20 c-3 -8 -6 -5
-6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m8000 -8 c0 -26 -2 -27 -13 -12 -9 12
-9 21 -2 29 14 18 15 17 15 -17z m-19984 10 c41 0 48 -3 45 -17 -3 -15 -16
-18 -83 -20 -44 -1 -74 1 -66 4 11 5 11 7 0 15 -9 6 3 7 36 3 87 -12 95 -12
95 -4 0 5 -19 9 -42 10 -24 0 -52 5 -63 10 -17 8 -17 9 5 4 14 -2 47 -5 73 -5z
m634 -12 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m16080 0 c-3
-8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-17092 2 c3 -5 1 -10 -4
-10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m4235 -23 c0 -5 -4 -5
-10 -2 -5 3 -10 14 -10 23 0 15 2 15 10 2 5 -8 10 -19 10 -23z m-6515 13 c-3
-5 -2 -10 3 -10 15 0 23 -28 10 -36 -6 -4 -8 -3 -5 3 4 6 0 16 -8 23 -17 14
-20 30 -4 30 5 0 7 -4 4 -10z m12055 -20 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4
10 3 6 8 10 11 10 2 0 4 -4 4 -10z m8387 4 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6
6 11 1 17 -2 13 -5z m-15044 -39 c4 3 5 2 2 -2 -13 -17 -30 -8 -32 16 l-1 26
12 -23 c7 -12 15 -20 19 -17z m2365 29 c7 -2 10 -9 7 -14 -5 -8 -11 -8 -20 0
-9 8 -15 8 -20 0 -3 -5 -1 -10 5 -10 6 0 8 -5 5 -11 -9 -13 -15 -7 -19 18 -4
20 13 26 42 17z m7138 -95 c-4 -44 -12 -44 -12 0 0 22 4 37 8 35 4 -3 6 -18 4
-35z m6712 17 c-10 -7 -14 -17 -10 -21 4 -5 1 -5 -6 -1 -10 6 -10 11 -1 22 6
8 17 14 23 14 6 0 3 -7 -6 -14z m-7891 -5 c20 18 15 1 -7 -21 -22 -22 -39 -27
-21 -7 7 9 7 18 1 25 -7 9 -7 9 2 2 7 -6 16 -6 25 1z m15263 -1 c-13 -9 -13
-10 1 -10 8 0 20 5 27 12 6 6 14 9 18 6 3 -4 -8 -14 -25 -22 -19 -10 -32 -12
-36 -6 -8 13 3 30 18 30 7 0 6 -4 -3 -10z m-18662 -85 c-1 -41 -2 -74 -3 -72
0 1 -9 15 -19 31 -16 26 -17 28 -2 23 13 -5 15 -1 11 20 -4 24 3 73 10 73 2 0
3 -34 3 -75z m19262 41 c0 -5 -7 -3 -15 4 -8 7 -12 17 -9 22 7 10 24 -9 24
-26z m-13150 -11 c-7 -8 -17 -15 -23 -15 -5 0 -2 9 8 20 21 23 35 19 15 -5z
m7646 -37 c1 -35 -1 -57 -4 -49 -4 11 -6 11 -14 0 -8 -11 -9 -11 -5 2 3 9 1
21 -5 27 -7 7 -4 22 7 46 10 20 18 36 19 36 1 0 2 -28 2 -62z m3724 32 c0 -5
-4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-5245
-67 c0 -13 0 -23 -1 -23 -8 0 -32 64 -27 72 8 14 26 -20 28 -49z m1475 27 c-6
-12 -14 -19 -17 -17 -2 3 1 15 7 27 6 12 14 19 17 17 2 -3 -1 -15 -7 -27z
m2465 -10 c-3 -6 0 -16 8 -24 11 -12 15 -11 25 5 7 10 17 19 22 19 5 0 0 -11
-12 -25 -16 -19 -24 -22 -35 -14 -10 9 -13 8 -13 -4 0 -9 -4 -19 -10 -22 -6
-4 -7 2 -2 17 5 13 7 39 6 58 -3 28 -1 31 7 17 5 -9 7 -22 4 -27z m-7495 11
c0 -5 3 -16 6 -25 3 -9 1 -16 -5 -16 -6 0 -11 7 -11 15 0 8 -5 15 -11 15 -5 0
-7 5 -4 10 8 13 25 13 25 1z m3530 -39 c0 -15 -2 -15 -10 -2 -13 20 -13 33 0
25 6 -3 10 -14 10 -23z m-4613 5 c0 -8 -6 -14 -14 -14 -17 0 -17 14 0 20 6 3
12 6 13 6 0 1 1 -5 1 -12z m6113 -7 c-24 -15 -36 -11 -14 4 10 8 21 12 24 10
2 -3 -2 -9 -10 -14z m-19953 -48 c1 -28 0 -32 -7 -17 -11 25 -12 55 -2 55 4 0
8 -17 9 -38z m2399 -5 c1 -39 1 -40 -8 -14 -8 23 -6 57 4 57 2 0 4 -19 4 -43z
m1004 34 c0 -2 -7 -9 -16 -16 -15 -11 -15 -12 1 -12 10 0 20 9 23 19 2 10 9
18 15 18 6 0 7 -3 4 -7 -4 -3 -2 -12 4 -19 7 -8 6 -14 -2 -20 -8 -4 -9 -3 -5
4 4 7 3 12 -2 12 -10 0 -45 -47 -55 -74 -6 -15 -10 -16 -22 -6 -12 10 -18 10
-30 0 -16 -13 -21 -35 -7 -26 5 3 9 -2 9 -11 0 -12 -5 -11 -19 7 -22 27 -23
44 -4 37 9 -4 13 -1 10 6 -2 7 6 13 21 13 14 1 23 -2 20 -6 -3 -5 0 -12 6 -16
7 -4 9 -3 5 4 -3 6 0 17 8 25 8 8 13 17 10 20 -3 3 -7 16 -8 29 -2 17 2 24 16
24 10 0 18 -2 18 -5z m7266 -14 c-1 -11 -7 -22 -13 -24 -8 -3 -12 4 -11 20 2
28 26 32 24 4z m3367 -54 c5 -7 6 -17 2 -23 -7 -11 -35 -2 -35 12 0 5 5 6 10
3 6 -3 10 -1 10 4 0 6 -3 11 -7 11 -5 0 -9 17 -10 38 l-1 37 11 -35 c7 -19 16
-41 20 -47z m3287 66 c0 -4 -7 -15 -16 -24 -9 -8 -13 -22 -10 -30 3 -8 0 -18
-6 -22 -17 -10 -58 -10 -58 0 0 5 12 7 27 5 25 -3 27 0 29 37 1 22 2 40 3 41
6 4 31 -1 31 -7z m38 -6 c-6 -7 -7 -13 -4 -13 4 0 1 -6 -6 -14 -9 -10 -15 -11
-20 -2 -12 18 -9 26 10 26 14 0 15 2 2 10 -12 7 -10 9 7 7 17 -1 19 -4 11 -14z
m5009 6 c6 -6 9 -18 6 -25 -4 -10 0 -12 11 -8 22 9 20 1 -7 -24 l-23 -22 2 35
c2 19 -1 35 -6 35 -6 0 -10 5 -10 10 0 13 13 13 27 -1z m-11890 -31 c-3 -8 -6
-5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-13270 -10 c-3 -8 -6 -5 -6 6 -1
11 2 17 5 13 3 -3 4 -12 1 -19z m28193 -7 c8 -16 7 -19 -8 -16 -9 2 -21 4 -25
4 -6 1 12 29 20 31 1 0 7 -9 13 -19z m-19160 0 c0 -12 -20 -25 -27 -18 -7 7 6
27 18 27 5 0 9 -4 9 -9z m-9053 -27 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1
17 -2 13 -5z m9414 -3 c6 10 9 8 9 -8 0 -13 -4 -23 -10 -23 -5 0 -10 2 -10 4
0 2 -2 10 -3 18 -2 7 -2 9 1 4 2 -4 8 -2 13 5z m2729 -36 c-22 -26 -30 -16
-12 15 12 21 18 25 20 14 2 -8 -2 -21 -8 -29z m5750 30 c0 -6 -9 -11 -20 -11
-19 0 -25 7 -15 18 9 9 35 5 35 -7z m5036 0 c4 -8 10 -12 16 -9 5 3 4 -3 -3
-14 -7 -12 -15 -19 -18 -17 -2 3 -10 -3 -17 -13 -11 -15 -12 -12 -9 23 4 48 3
45 15 45 6 0 13 -7 16 -15z m2184 5 c-8 -5 -4 -9 11 -13 l22 -6 -24 -25 c-18
-19 -25 -22 -27 -11 -2 8 -8 15 -14 15 -6 0 -6 6 2 15 9 10 9 15 1 15 -6 0
-11 4 -11 9 0 5 12 9 26 9 15 0 21 -4 14 -8z m-14463 -35 c-1 -19 -2 -35 -3
-35 -6 0 -44 39 -44 46 0 4 5 3 12 -4 15 -15 32 -16 23 -2 -6 10 1 30 11 30 2
0 3 -16 1 -35z m12083 -4 c0 -42 -15 -46 -16 -5 0 19 3 34 8 34 4 0 8 -13 8
-29z m-4858 2 c-6 -2 -9 -10 -6 -15 4 -7 2 -8 -5 -4 -13 9 -5 26 12 25 9 0 8
-2 -1 -6z m-17904 -38 c0 -20 -3 -23 -14 -14 -14 11 -20 -1 -7 -14 13 -13 12
-61 -2 -86 -12 -24 -13 -20 -11 45 2 39 6 77 10 83 12 19 24 12 24 -14z
m15082 -29 c0 -25 -4 -19 -18 24 -11 32 -10 33 3 16 8 -11 15 -29 15 -40z
m-6083 -3 c-3 -10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2 -21 0 -30z m8833 -3 c5
0 10 5 10 10 0 6 -4 10 -10 10 -5 0 -10 5 -10 12 0 9 3 9 12 0 9 -9 15 -9 26
1 20 16 13 0 -23 -50 -18 -25 -36 -40 -44 -37 -10 3 -11 1 -3 -8 14 -18 -8
-48 -34 -44 -24 4 -25 28 -1 32 9 1 17 -2 17 -7 0 -6 5 -7 11 -3 8 4 7 9 -2
15 -7 4 -17 5 -21 3 -4 -3 -8 0 -8 6 0 7 -7 5 -16 -4 -19 -20 -24 -20 -24 -1
0 8 3 14 8 14 4 -1 12 0 17 1 6 1 15 3 20 5 6 1 19 3 31 3 18 2 21 7 18 45 -3
34 -2 38 6 20 5 -13 14 -23 20 -23z m2209 31 c9 -6 10 -11 2 -15 -6 -4 -11 -2
-11 3 0 6 -5 11 -11 11 -5 0 -7 -4 -4 -10 3 -5 -1 -10 -9 -10 -10 0 -13 6 -10
15 7 17 22 19 43 6z m-13984 -31 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2
10 4 10 3 0 8 -4 11 -10z m11635 -20 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3
6 8 10 11 10 2 0 4 -4 4 -10z m-5588 -75 c7 -7 8 -1 3 20 -3 16 -3 26 1 22 4
-4 9 -20 10 -36 2 -26 -1 -28 -32 -27 -32 1 -32 2 -18 23 7 12 15 30 15 40 1
10 4 7 6 -7 3 -14 9 -29 15 -35z m1241 48 c-7 -2 -13 -9 -13 -14 0 -4 6 -6 14
-3 8 3 17 1 20 -4 3 -5 -2 -9 -11 -8 -10 0 -18 -4 -18 -11 0 -30 -13 -20 -13
11 0 27 4 36 17 35 10 0 11 -2 4 -6z m6603 -8 c14 -13 13 -15 -3 -16 -10 -1
-21 -1 -25 0 -5 0 -9 -5 -9 -11 -1 -7 -2 -19 -3 -27 -3 -32 -23 -20 -23 14 0
32 2 34 26 30 25 -4 25 -3 7 10 -18 14 -18 15 -2 15 9 0 24 -7 32 -15z m1802
-80 c-8 -38 -12 -45 -18 -30 -6 18 -6 40 -1 105 l2 25 14 -25 c11 -20 11 -36
3 -75z m3188 68 l-6 -28 14 25 c15 27 33 32 54 17 8 -6 12 -5 12 2 0 7 5 9 11
6 6 -5 4 -12 -8 -20 -12 -9 -22 -10 -30 -3 -18 15 -34 0 -29 -27 3 -14 -3 -30
-13 -39 -17 -15 -19 -15 -32 1 -7 10 -9 15 -5 11 15 -13 38 13 25 28 -6 7 -8
22 -4 34 9 30 18 24 11 -7z m-21276 -23 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10
3 6 8 10 11 10 2 0 4 -4 4 -10z m24440 -19 c0 -4 -9 -7 -20 -8 -19 -2 -26 8
-14 20 7 7 34 -2 34 -12z m-14811 -14 c47 0 49 -2 20 -18 -15 -8 -19 -8 -15 0
10 15 -7 14 -22 -1 -15 -15 -16 -32 -2 -23 6 3 10 -1 10 -11 0 -14 -2 -15 -17
-3 -9 8 -19 13 -22 11 -3 -2 -7 2 -10 9 -2 6 0 9 5 6 5 -2 15 2 23 10 11 12
10 14 -5 11 -12 -2 -19 4 -22 17 -2 15 -1 17 7 6 5 -8 26 -14 50 -14z m11583
-13 c-21 -14 -22 -41 -1 -28 5 3 7 10 4 15 -4 5 1 9 9 9 8 0 18 -6 22 -12 4
-7 4 -10 0 -6 -5 4 -21 -7 -37 -23 -16 -16 -29 -25 -29 -20 0 5 5 13 11 17 8
4 8 9 0 17 -20 20 -12 43 16 45 l28 2 -23 -16z m2406 -1 c-12 -14 -38 -18 -38
-5 0 14 11 21 30 19 14 -1 16 -4 8 -14z m-10798 -37 c0 -11 -19 -15 -25 -6 -3
5 1 10 9 10 9 0 16 -2 16 -4z m-4460 -37 c0 -5 -4 -9 -10 -9 -5 0 -10 7 -10
16 0 8 5 12 10 9 6 -3 10 -10 10 -16z m-8580 1 c-18 -12 -43 -13 -36 -2 2 4
15 8 28 9 18 2 19 0 8 -7z m8596 -32 c1 -31 -1 -37 -8 -24 -8 14 -4 66 5 66 1
0 3 -19 3 -42z m1209 23 c3 -5 2 -12 -3 -15 -5 -3 -9 1 -9 9 0 17 3 19 12 6z
m3189 -20 c-5 -3 -11 -22 -12 -41 -4 -35 -20 -65 -30 -55 -3 2 1 11 8 20 7 8
10 24 7 35 -3 12 -1 18 5 14 6 -4 8 -1 6 7 -3 8 -1 23 5 34 7 14 12 16 15 6 2
-7 1 -16 -4 -20z m-12989 -1 c-3 -5 -11 -10 -16 -10 -6 0 -7 5 -4 10 3 6 11
10 16 10 6 0 7 -4 4 -10z m8574 -32 c1 -5 -9 -8 -21 -7 -13 0 -17 3 -10 6 6 2
12 14 13 26 0 21 1 21 9 2 5 -11 9 -23 9 -27z m7061 12 c0 -11 -7 -20 -15 -20
-8 0 -15 9 -15 20 0 11 7 20 15 20 8 0 15 -9 15 -20z m3612 -22 c-8 -8 -9 -4
-5 13 4 13 8 18 11 10 2 -7 -1 -18 -6 -23z m2948 19 c0 -7 -10 -13 -21 -14
-21 -2 -21 -1 -3 12 24 18 24 18 24 2z m1460 -42 c0 -5 -5 -3 -10 5 -5 8 -10
20 -10 25 0 6 5 3 10 -5 5 -8 10 -19 10 -25z m-22810 14 c0 -4 2 -14 5 -21 4
-9 -2 -14 -14 -14 -11 0 -17 4 -15 8 3 5 -2 11 -11 14 -11 5 -13 9 -5 14 14 9
40 9 40 -1z m6499 -8 c-1 -21 -17 -59 -20 -46 -7 32 -3 62 8 62 7 0 13 -7 12
-16z m5603 -3 c-7 -7 -12 -8 -12 -2 0 14 12 26 19 19 2 -3 -1 -11 -7 -17z m46
-15 c-3 -34 -6 -38 -33 -40 -27 -1 -28 -1 -7 7 14 6 25 21 28 40 9 42 15 38
12 -7z m8305 20 c-3 -7 -10 -10 -17 -6 -6 4 -17 8 -24 9 -29 5 -21 11 11 10
23 -1 33 -5 30 -13z m2322 7 c3 -5 2 -10 -4 -10 -5 0 -13 5 -16 10 -3 6 -2 10
4 10 5 0 13 -4 16 -10z m-16545 -33 c0 -5 -4 -5 -10 -2 -5 3 -10 14 -10 23 0
15 2 15 10 2 5 -8 10 -19 10 -23z m14189 14 c11 -7 10 -11 -6 -19 -15 -9 -19
-7 -20 9 -2 21 5 23 26 10z m827 -65 c-4 -15 -1 -28 6 -32 8 -5 9 -2 5 9 -3 9
-2 19 3 22 11 7 13 -9 4 -42 -4 -13 -13 -21 -24 -20 -14 1 -16 4 -9 14 6 7 8
17 4 23 -7 11 2 50 12 50 3 0 2 -11 -1 -24z m1453 -12 c-15 -18 -22 -13 -13
10 3 9 10 13 16 10 5 -3 4 -12 -3 -20z m-20392 -22 c0 -17 -6 -29 -14 -29 -16
0 -17 12 -1 37 7 11 13 20 14 20 0 0 1 -13 1 -28z m-2401 -5 c1 -19 -3 -23
-27 -23 -15 0 -26 3 -23 7 2 5 11 6 20 2 8 -3 14 -2 13 3 -3 17 1 34 9 34 4 0
8 -10 8 -23z m7734 7 c0 -8 -4 -12 -10 -9 -5 3 -10 10 -10 16 0 5 5 9 10 9 6
0 10 -7 10 -16z m6322 7 c8 11 9 10 4 -4 -3 -10 -6 -22 -6 -25 0 -4 -12 -8
-28 -8 -15 -1 -22 0 -16 3 7 2 9 14 6 26 -5 20 -4 21 12 8 15 -13 19 -13 28 0z
m9598 -31 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4
10 -10z m-22301 -24 c6 -4 9 -12 6 -17 -10 -14 -25 -11 -25 7 0 8 -4 12 -10 9
-5 -3 -18 -1 -27 4 -15 9 -12 10 13 7 17 -2 36 -6 43 -10z m958 -34 c-8 -10
-7 -16 4 -23 12 -7 11 -9 -2 -9 -22 0 -34 47 -17 67 11 13 13 13 19 -4 3 -10
2 -24 -4 -31z m20273 33 c-7 -9 -15 -13 -17 -11 -7 7 7 26 19 26 6 0 6 -6 -2
-15z m-10420 -25 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0
11 -4 11 -10z m-13273 -22 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1
-19z m-1080 -10 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m830
-45 c1 -37 -5 -39 -12 -4 -2 11 -6 25 -9 29 -3 5 1 9 7 9 8 0 13 -14 14 -34z
m237 20 c3 -7 -3 -17 -13 -23 -14 -7 -20 -6 -25 5 -3 8 -12 15 -21 15 -8 0
-15 4 -15 8 0 13 69 8 74 -5z m3361 -2 c-3 -6 -11 -11 -17 -11 -6 0 -6 6 2 15
14 17 26 13 15 -4z m12868 12 c-7 -2 -21 -2 -30 0 -10 3 -4 5 12 5 17 0 24 -2
18 -5z m7392 -3 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4
11 -10z m-7436 -21 c-8 -4 -11 -11 -7 -14 3 -3 13 1 21 10 19 19 23 19 31 -1
3 -8 2 -13 -3 -9 -13 7 -42 -5 -35 -16 3 -5 0 -9 -5 -9 -11 0 -12 1 -16 37 -2
15 2 20 12 16 12 -5 12 -7 2 -14z m1820 -39 c1 -13 0 -13 -9 0 -5 8 -9 24 -9
35 l0 20 9 -20 c5 -11 9 -27 9 -35z m2256 41 c3 -5 1 -12 -5 -16 -5 -3 -10 1
-10 9 0 18 6 21 15 7z m-9514 -26 c2 -23 4 -29 11 -40 4 -6 3 -17 -3 -25 -7
-12 -9 -9 -10 10 0 23 -1 23 -8 5 -6 -14 -8 -8 -7 23 1 39 14 61 17 27z m4502
-9 c3 -8 -1 -17 -9 -20 -13 -5 -12 -11 8 -35 13 -15 27 -26 32 -23 5 3 6 0 2
-6 -8 -13 -37 -17 -35 -4 4 21 -3 24 -16 6 -16 -21 -17 -18 -19 48 -1 29 1 34
11 26 10 -8 12 -7 7 6 -3 9 -2 16 4 16 5 0 12 -6 15 -14z m-17606 -12 c-3 -3
-12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m26999 -36 c-1 -37 -4 -43 -23
-44 -13 -1 -28 6 -35 14 -11 13 -10 15 5 9 11 -4 17 -2 15 6 -2 13 23 57 33
57 4 0 6 -19 5 -42z m-22448 25 c-10 -2 -26 -2 -35 0 -10 3 -2 5 17 5 19 0 27
-2 18 -5z m11587 -23 c-2 -11 1 -20 6 -20 6 0 8 -4 5 -9 -4 -5 0 -11 6 -14 7
-3 4 -5 -7 -4 -16 1 -21 9 -23 40 -1 23 1 36 7 33 5 -4 8 -15 6 -26z m2295 18
c0 -10 -9 -9 -35 2 -17 8 -16 8 8 7 15 -1 27 -5 27 -9z m43 5 c-7 -2 -19 -2
-25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m4997 -3 c0 -5 -2 -10 -4 -10 -3 0 -8
5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m-8889 -17 c13 -16 12 -17 -3 -4
-10 7 -18 15 -18 17 0 8 8 3 21 -13z m-1468 -36 c4 3 7 1 7 -6 0 -17 -32 -10
-38 9 -4 12 -3 13 10 3 8 -7 18 -10 21 -6z m4267 -8 c0 -14 -17 -10 -23 5 -4
10 -1 13 9 9 7 -3 14 -9 14 -14z m-11746 -24 c-8 -26 -49 -65 -69 -65 -10 0
-13 -5 -9 -12 4 -7 3 -8 -4 -4 -18 11 -9 40 23 73 35 36 68 40 59 8z m-2397
-27 c-2 -23 -7 -32 -22 -34 -11 -2 -23 2 -27 9 -8 14 10 39 23 31 5 -3 12 2
15 10 9 24 12 19 11 -16z m21359 -11 c0 -13 -7 -24 -13 -24 -16 0 -16 2 -1 27
16 25 16 25 14 -3z m-14361 -25 c-2 -2 -11 2 -19 11 -8 8 -12 18 -8 21 3 4 12
-1 19 -10 7 -10 11 -20 8 -22z m-10278 6 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3
-3 4 -12 1 -19z m1503 -33 c27 -33 20 -50 -22 -50 -26 -1 -38 4 -38 13 0 7 5
10 10 7 6 -3 10 4 10 17 0 16 -3 19 -8 10 -6 -8 -9 -2 -9 17 0 27 2 28 13 13
12 -15 12 -14 8 8 -5 23 -5 24 7 5 6 -11 19 -29 29 -40z m4175 36 c-3 -6 -11
-11 -17 -11 -6 0 -6 6 2 15 14 17 26 13 15 -4z m3108 6 c3 -8 -1 -14 -8 -14
-7 0 -11 6 -8 14 3 7 6 13 8 13 2 0 5 -6 8 -13z m4440 -25 c7 -9 6 -11 -7 -6
-9 3 -16 16 -15 28 0 14 2 16 6 6 3 -8 10 -21 16 -28z m7872 18 c3 -5 -1 -10
-10 -10 -9 0 -13 5 -10 10 3 6 8 10 10 10 2 0 7 -4 10 -10z m3502 -6 c-3 -3
-12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-40 -39 c-1 -25 -3 -31 -10
-20 -7 13 -2 55 8 55 2 0 3 -16 2 -35z m-18963 -4 c12 -19 -6 -40 -32 -37 -15
1 -32 -1 -37 -5 -6 -4 -17 -4 -25 1 -13 8 -12 10 3 10 9 0 16 6 15 13 -2 6 5
11 15 9 15 -3 22 8 18 31 -2 10 36 -9 43 -22z m13871 10 c-3 -6 -11 -11 -17
-11 -6 0 -6 6 2 15 14 17 26 13 15 -4z m3348 2 c-4 -10 -2 -14 5 -9 6 3 14 1
18 -5 4 -8 3 -9 -4 -5 -7 4 -12 1 -12 -8 0 -8 6 -16 13 -19 6 -3 3 -5 -8 -4
-15 1 -20 7 -19 29 2 34 3 38 9 38 2 0 1 -7 -2 -17z m-18937 -37 c2 -12 -4
-13 -35 -7 -2 1 2 8 9 16 14 16 23 13 26 -9z m3954 8 c-9 -3 -24 -20 -34 -39
-11 -19 -23 -32 -27 -29 -5 3 -2 12 6 20 8 9 20 24 25 35 6 10 18 18 28 18 14
-1 14 -2 2 -5z m8517 -8 c0 -8 -6 -12 -15 -9 -11 5 -11 7 0 14 7 5 13 9 14 9
0 0 1 -6 1 -14z m4803 -32 c0 -6 7 -17 15 -24 19 -16 19 -1 0 24 -8 11 -15 24
-15 30 0 6 8 -1 17 -14 9 -14 24 -31 33 -38 12 -11 12 -12 0 -6 -8 5 -3 -7 13
-25 15 -18 27 -37 27 -42 0 -6 4 -8 9 -4 5 3 13 0 18 -7 17 -28 11 -36 -26
-34 -35 1 -36 2 -36 41 0 36 -2 40 -22 37 -16 -2 -23 2 -23 12 0 9 -8 24 -17
34 -10 10 -19 29 -21 42 -3 24 -2 24 12 5 9 -11 16 -25 16 -31z m-3318 -27
c-6 -12 -39 20 -39 38 0 10 6 8 22 -8 12 -12 19 -26 17 -30z m-556 -3 c1 -34
-15 -43 -24 -14 -3 7 0 10 6 6 7 -4 8 5 5 26 -3 18 -1 29 4 25 4 -5 9 -24 9
-43z m-14159 -2 c-1 -21 -7 -29 -22 -30 -14 -1 -16 1 -7 7 8 5 14 12 14 17 1
11 9 34 13 34 1 0 2 -13 2 -28z m8868 18 c3 -5 -1 -10 -9 -10 -8 0 -18 5 -21
10 -3 6 1 10 9 10 8 0 18 -4 21 -10z m2854 -4 c9 -10 8 -15 -2 -19 -8 -3 -13
3 -14 14 -2 23 1 24 16 5z m4595 3 c3 -3 -3 -10 -14 -17 -16 -10 -18 -9 -17 7
1 17 19 22 31 10z m-11598 -22 c0 -13 -7 -24 -13 -24 -16 0 -16 2 -1 27 16 25
16 25 14 -3z m2612 0 c17 -18 19 -24 8 -24 -8 0 -12 5 -9 10 4 6 -4 7 -21 3
-25 -6 -26 -5 -16 14 13 25 12 25 38 -3z m1496 -20 c7 -6 6 -7 -4 -4 -8 2 -16
14 -17 28 -1 19 -1 20 4 4 3 -11 10 -24 17 -28z m886 18 c10 3 20 0 23 -8 4
-11 -3 -14 -26 -13 -18 1 -32 7 -31 14 2 28 7 36 11 19 3 -12 11 -16 23 -12z
m4416 10 c-11 -8 -16 -15 -11 -15 5 0 4 -5 -3 -12 -9 -9 -15 -9 -24 1 -9 9 -9
12 -1 7 7 -3 14 2 18 14 3 11 14 20 23 20 15 -1 14 -3 -2 -15z m3903 -30 c0
-5 -6 -1 -14 9 -8 11 -15 24 -15 30 0 5 7 1 15 -10 8 -10 14 -23 14 -29z
m1121 35 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z
m-2902 -12 c-2 -9 9 -12 35 -11 24 2 36 -1 32 -7 -4 -6 -24 -10 -45 -8 -35 2
-40 7 -31 36 3 9 11 0 9 -10z m-15412 -23 c1 -8 -2 -15 -7 -15 -5 0 -9 10 -9
22 0 23 12 17 16 -7z m12503 12 c8 -10 7 -14 -2 -14 -8 0 -14 6 -14 14 0 7 1
13 2 13 2 0 8 -6 14 -13z m1327 -14 c-7 -7 -26 7 -26 19 0 6 6 6 15 -2 9 -7
13 -15 11 -17z m-4480 -17 c-3 -12 1 -31 8 -42 13 -18 12 -20 -7 -22 -18 -1
-22 5 -25 34 -1 24 2 33 9 28 8 -4 9 1 4 16 -5 16 -4 20 5 15 6 -4 9 -17 6
-29z m4610 -24 c-8 -7 -25 8 -37 31 -11 21 -10 21 15 -2 14 -14 24 -26 22 -29z
m-3920 18 c0 -22 -11 -26 -22 -9 -5 9 5 22 21 29 1 0 1 -9 1 -20z m-10021 -48
c-7 -8 -44 24 -49 41 -4 15 2 13 24 -10 16 -15 27 -29 25 -31z m1449 52 c9 -3
13 -11 10 -16 -4 -6 -10 -5 -15 3 -7 11 -9 11 -9 1 0 -7 -4 -10 -10 -7 -5 3
-10 2 -10 -3 0 -6 7 -13 16 -16 8 -3 12 -2 9 4 -3 5 0 7 8 4 18 -6 18 -31 0
-31 -7 0 -13 4 -13 9 0 5 -4 6 -10 3 -5 -3 -10 -2 -10 4 0 6 -7 8 -15 5 -8 -4
-12 -11 -8 -17 3 -6 1 -7 -6 -3 -8 5 -10 -1 -6 -23 3 -18 1 -31 -5 -31 -5 0
-10 -5 -10 -11 0 -5 5 -7 10 -4 6 3 10 1 10 -4 0 -6 -4 -11 -9 -11 -17 0 -23
20 -11 35 8 10 9 15 2 15 -15 0 -33 -35 -24 -45 4 -4 -3 -3 -14 3 -12 6 -23
10 -25 9 -3 -1 4 5 15 13 11 8 23 20 28 25 13 18 82 95 84 95 1 0 9 -3 18 -6z
m9507 -32 c-6 -2 -9 -8 -5 -13 8 -14 -26 -23 -38 -11 -6 6 -8 22 -4 36 6 26 7
26 33 9 14 -9 21 -19 14 -21z m9469 -11 c0 -12 -20 -25 -27 -18 -7 7 6 27 18
27 5 0 9 -4 9 -9z m2320 -8 c0 -15 -2 -16 -10 -3 -6 10 -10 11 -10 3 0 -7 -8
-13 -17 -13 -15 0 -14 2 3 15 25 19 34 19 34 -2z m-7016 -1 c3 -5 -1 -9 -9 -9
-8 0 -12 4 -9 9 3 4 7 8 9 8 2 0 6 -4 9 -8z m-12023 -15 c-6 -8 -8 -17 -4 -20
3 -4 1 -7 -5 -7 -15 0 -15 13 0 28 16 16 23 15 9 -1z m11588 -33 c10 7 7 -32
-4 -41 -4 -5 -5 -2 -1 5 4 6 2 12 -3 12 -6 0 -11 -4 -11 -10 0 -5 -5 -10 -11
-10 -5 0 -8 4 -5 9 10 15 -25 22 -43 9 -9 -7 -5 0 9 15 13 15 30 25 37 23 7
-3 14 0 14 7 1 7 3 4 6 -6 2 -9 8 -15 12 -13z m-17527 1 c18 -7 15 -25 -3 -25
-6 0 -7 5 -4 10 11 18 -8 11 -32 -12 l-23 -21 0 25 c0 39 15 44 62 23z m5930
-20 c3 3 5 -1 5 -8 0 -8 -6 -14 -13 -14 -7 0 -11 4 -9 8 3 4 -2 9 -10 13 -8 3
-17 1 -20 -4 -4 -6 -1 -10 6 -10 7 0 10 -2 7 -5 -3 -3 -13 -3 -22 1 -15 6 -15
8 4 21 11 8 18 20 14 26 -3 7 3 2 14 -10 11 -12 22 -20 24 -18z m10333 19 c9
3 13 2 10 -3 -3 -5 0 -11 7 -14 17 -6 -32 3 -54 9 -13 4 -18 0 -18 -15 0 -22
-14 -28 -25 -11 -10 16 37 50 52 38 7 -6 20 -8 28 -4z m-11579 -9 c4 -8 12
-15 19 -15 21 0 26 -16 6 -17 -10 -1 -24 8 -30 20 l-12 22 5 -22 c2 -13 0 -23
-5 -23 -5 0 -9 11 -9 25 0 27 17 34 26 10z m-2306 -16 c5 9 10 8 18 -2 10 -11
5 -14 -23 -15 -29 -1 -33 1 -23 12 8 8 15 10 17 4 2 -6 6 -6 11 1z m11277 -11
c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m7699 -1 c3 -2 -7 -4
-21 -4 -14 0 -27 3 -30 7 -5 8 43 5 51 -3z m-17820 -33 c0 -17 -4 -23 -14 -19
-8 3 -21 0 -28 -6 -7 -6 -19 -7 -26 -3 -10 5 -11 4 0 -7 9 -10 15 -10 24 -1
14 14 32 16 23 2 -4 -6 1 -7 11 -3 11 4 15 3 11 -4 -4 -6 -23 -10 -42 -9 -21
2 -35 -1 -35 -8 0 -6 -5 -3 -11 7 -12 21 -9 27 18 32 10 1 17 8 15 15 -2 8 3
10 13 7 13 -6 19 4 16 25 0 4 5 5 12 2 7 -2 13 -16 13 -30z m12711 30 c-3 -3
-12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m40 0 c-3 -3 -12 -4 -19 -1 -8
3 -5 6 6 6 11 1 17 -2 13 -5z m-13829 -30 c1 0 -6 -8 -16 -17 -16 -15 -17 -14
-18 14 -2 26 0 28 15 17 9 -7 17 -13 19 -14z m9718 -19 c0 -29 -3 -44 -10 -42
-6 3 -9 11 -7 18 2 8 5 25 6 39 1 14 3 26 4 28 4 8 6 -3 7 -43z m8199 26 c-3
-6 -16 -16 -28 -22 -21 -11 -21 -10 2 15 23 25 40 29 26 7z m-8654 -10 c-6
-10 -7 -22 -3 -26 4 -5 2 -5 -5 -2 -9 6 -11 22 -4 45 1 1 6 2 11 2 7 0 7 -6 1
-19z m6359 -19 c-14 -5 -41 18 -35 29 3 4 15 1 26 -9 11 -9 15 -18 9 -20z
m-8970 19 c0 -15 -19 -23 -38 -16 -14 5 -14 7 -2 15 19 12 40 13 40 1z m4423
2 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m-2743 -18 c0 -17 -2
-18 -25 -9 -13 5 -14 8 -3 15 19 13 28 11 28 -6z m4506 -7 c-3 -21 -9 -23 -62
-26 -45 -2 -55 0 -44 9 12 8 10 9 -10 4 -14 -3 -46 -8 -72 -11 -38 -3 -48 -1
-48 11 0 12 5 13 26 5 17 -7 39 -7 65 0 22 6 45 8 51 4 6 -4 29 -8 52 -9 l41
-2 -30 12 c-25 11 -27 13 -10 18 39 9 45 7 41 -15z m-17806 -18 c0 -10 -20 -8
-39 4 -11 7 -11 10 0 17 13 8 39 -6 39 -21z m670 14 c0 -8 -4 -12 -10 -9 -5 3
-10 10 -10 16 0 5 5 9 10 9 6 0 10 -7 10 -16z m6651 -4 c21 -17 22 -19 6 -20
-10 0 -16 4 -13 9 3 5 -1 12 -10 15 -12 4 -15 2 -10 -9 4 -11 -1 -15 -19 -15
-24 0 -24 1 -7 19 22 25 25 25 53 1z m13244 -18 c-9 -9 -35 8 -35 24 0 16 2
16 20 -1 11 -10 18 -20 15 -23z m-8929 12 c8 3 16 0 20 -6 5 -8 -39 -8 -83 1
-2 1 -3 5 -3 11 0 6 10 6 27 -1 14 -5 32 -8 39 -5z m-1766 -9 c0 -1 -12 -2
-26 -2 -17 0 -22 2 -13 7 10 7 39 3 39 -5z m10745 5 c-3 -5 -14 -10 -23 -9
-14 0 -13 2 3 9 27 11 27 11 20 0z m-15512 -57 c-3 -7 -9 -13 -14 -13 -5 0 -9
6 -9 13 0 8 6 14 14 14 8 0 12 -6 9 -14z m14779 5 c-9 -9 -9 -15 0 -27 10 -13
10 -14 -2 -4 -10 9 -17 10 -27 2 -7 -7 -13 -9 -13 -5 0 10 34 44 45 45 5 1 4
-4 -3 -11z m-19767 -17 c3 -5 2 -12 -3 -15 -5 -3 -9 1 -9 9 0 17 3 19 12 6z
m24815 -1 c0 -5 -4 -10 -9 -10 -5 0 -18 -3 -28 -6 -16 -5 -16 -4 1 10 23 18
36 20 36 6z m-14976 -8 c3 -5 -1 -9 -9 -9 -8 0 -12 4 -9 9 3 4 7 8 9 8 2 0 6
-4 9 -8z m9026 -2 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0
11 -4 11 -10z m-8980 -52 c-13 -5 -25 -6 -28 -3 -3 3 0 5 6 5 7 0 12 5 12 11
0 5 -4 8 -8 5 -5 -3 -9 1 -9 9 0 11 5 10 25 -3 l24 -16 -22 -8z m-9809 13 c11
-13 11 -14 -3 -8 -12 4 -15 2 -11 -9 7 -20 -7 -18 -24 4 -13 15 -13 15 -4 -5
14 -28 14 -33 -2 -33 -9 0 -14 13 -15 34 -1 30 2 34 22 32 13 0 29 -7 37 -15z
m19518 -13 c8 -2 15 -11 16 -19 3 -17 -55 -21 -67 -4 -5 6 -8 6 -8 -2 0 -7 -8
-13 -17 -13 -35 0 7 25 49 29 4 1 5 9 2 19 -4 10 -3 13 3 6 4 -6 14 -13 22
-16z m5058 16 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-21293
-23 c9 -8 13 -18 8 -23 -11 -11 -69 10 -69 25 0 17 40 16 61 -2z m11208 7 c-9
-9 -9 -15 0 -27 10 -13 10 -14 -2 -4 -10 9 -17 10 -27 2 -7 -7 -13 -9 -13 -5
0 10 34 44 45 45 5 1 4 -4 -3 -11z m4535 -4 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6
6 11 1 17 -2 13 -5z m-13977 -8 c0 -11 -19 -15 -25 -6 -3 5 1 10 9 10 9 0 16
-2 16 -4z m13875 -25 c-6 -5 -25 10 -25 20 0 5 6 4 14 -3 8 -7 12 -15 11 -17z
m7552 12 c5 -15 -39 -13 -49 2 -5 6 -8 6 -8 -2 0 -8 -13 -11 -37 -8 -51 5 -53
6 -18 14 45 11 108 7 112 -6z m-7350 1 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11
1 17 -2 13 -5z" />
            <path d="M12610 29738 c0 -4 4 -8 10 -8 5 0 18 -10 30 -22 11 -12 20 -16 20
-10 0 7 6 12 14 12 8 0 16 7 20 15 5 13 2 14 -15 4 -21 -11 -54 -5 -45 9 2 4
-4 8 -15 8 -10 0 -19 -4 -19 -8z" />
            <path d="M12670 29740 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z" />
            <path d="M12724 29645 c11 -8 23 -15 28 -15 4 0 7 -4 6 -9 -2 -5 0 -9 5 -10
25 -3 29 1 12 12 -14 11 -15 16 -5 22 9 5 11 4 6 -3 -4 -7 -2 -12 4 -12 7 0
10 5 8 12 -5 15 -38 20 -38 5 0 -8 -4 -7 -12 1 -7 7 -17 12 -22 12 -6 0 -2 -7
8 -15z" />
            <path d="M13007 29344 c-3 -3 -3 -13 0 -21 5 -12 7 -12 14 -1 15 23 4 40 -14
22z" />
            <path d="M12019 29293 c-6 -107 -5 -165 2 -145 8 22 11 162 3 162 -2 0 -4 -8
-5 -17z" />
            <path d="M12040 29148 c0 -9 5 -20 10 -23 13 -8 13 5 0 25 -8 13 -10 13 -10
-2z" />
            <path d="M12056 29097 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 -10 9 -11 8 -5 -6z" />
            <path d="M12094 28821 c0 -12 3 -21 7 -21 4 0 2 -13 -3 -28 -9 -23 -8 -34 5
-53 10 -13 17 -18 17 -12 0 7 -4 14 -9 17 -10 7 -9 31 2 42 11 11 8 39 -6 58
-12 16 -13 16 -13 -3z" />
            <path d="M12130 28703 c1 -5 8 -19 16 -33 8 -14 13 -17 10 -7 -3 10 -6 25 -6
33 0 8 -4 14 -10 14 -5 0 -10 -3 -10 -7z" />
            <path d="M12175 28519 c2 -19 3 -42 4 -51 1 -10 5 -18 11 -18 6 0 6 9 0 26 -6
14 -8 30 -5 35 4 5 2 16 -3 26 -8 13 -9 8 -7 -18z" />
            <path d="M13780 28451 c-14 -26 -13 -31 6 -31 8 0 13 -4 9 -9 -3 -5 -1 -12 4
-16 6 -3 7 6 4 22 -3 16 -6 34 -6 41 -1 17 -5 15 -17 -7z" />
            <path d="M13816 28453 c-4 -9 -3 -20 1 -24 4 -4 9 3 9 17 2 28 -1 31 -10 7z" />
            <path d="M12200 28270 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5
0 -10 -4 -10 -10z" />
            <path d="M14025 28140 c-3 -5 -5 -10 -3 -11 48 -11 61 -7 29 11 -13 7 -22 7
-26 0z" />
            <path d="M12271 28094 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
            <path d="M9996 27981 c-3 -4 6 -6 19 -3 14 2 25 6 25 8 0 8 -39 4 -44 -5z" />
            <path d="M10070 27980 c-12 -8 -10 -10 8 -10 13 0 20 4 17 10 -7 12 -6 12 -25
0z" />
            <path d="M10155 27980 c-3 -5 0 -12 7 -17 20 -12 65 -9 65 5 0 7 -5 11 -11 9
-6 -2 -21 0 -33 4 -12 5 -25 4 -28 -1z m30 -18 c-3 -3 -11 0 -18 7 -9 10 -8
11 6 5 10 -3 15 -9 12 -12z" />
            <path d="M14237 27902 c-3 -4 5 -19 18 -34 18 -21 25 -24 29 -13 3 8 3 22 0
30 -7 17 -24 20 -24 4 0 -5 -5 -7 -10 -4 -6 4 -8 11 -5 16 4 5 4 9 1 9 -3 0
-7 -3 -9 -8z m43 -32 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6
0 11 -4 11 -10z" />
            <path d="M13189 27818 c7 -10 10 -18 6 -18 -4 0 -2 -6 4 -13 6 -8 7 -18 3 -22
-4 -5 -2 -5 5 -2 15 9 23 -14 11 -36 -6 -12 -6 -23 1 -31 6 -7 7 -16 3 -21 -4
-4 8 0 26 9 24 12 32 13 32 4 0 -7 13 -24 29 -38 17 -13 27 -28 24 -33 -3 -6
-1 -7 5 -3 6 3 18 -1 26 -10 9 -8 16 -10 16 -4 0 6 -19 30 -41 53 -23 23 -39
36 -35 30 4 -7 2 -13 -5 -13 -6 0 -9 8 -6 19 5 21 -34 67 -46 54 -4 -3 -7 1
-7 10 0 10 -4 17 -10 17 -5 0 -10 6 -10 13 0 8 -10 22 -22 33 -19 15 -20 16
-9 2z m87 -100 c10 -13 15 -20 10 -16 -5 4 -18 3 -28 -3 -30 -16 -50 7 -24 27
24 17 19 18 42 -8z m75 -85 c13 -16 12 -17 -3 -4 -17 13 -22 21 -14 21 2 0 10
-8 17 -17z" />
            <path d="M13250 27721 c0 -7 -4 -9 -10 -6 -5 3 -10 1 -10 -4 0 -6 4 -11 9 -11
13 0 25 20 17 27 -3 4 -6 1 -6 -6z" />
            <path d="M10505 27739 c-10 -15 1 -23 20 -15 9 3 13 10 10 16 -8 13 -22 13
-30 -1z" />
            <path d="M9625 27710 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z" />
            <path d="M13261 27668 c0 -10 -7 -18 -15 -18 -8 0 -18 -4 -21 -10 -3 -6 1 -7
9 -4 12 5 16 0 16 -17 0 -19 -2 -21 -10 -9 -6 9 -10 10 -10 3 0 -6 5 -15 10
-18 6 -3 8 -16 6 -28 -1 -12 -1 -16 1 -9 3 7 9 9 15 6 7 -4 7 0 1 12 -6 11 -7
30 -3 44 6 21 7 19 11 -18 3 -23 0 -50 -5 -60 -15 -25 -16 -41 -4 -49 7 -4 9
2 6 15 -4 12 -2 22 3 22 4 0 8 -16 7 -35 -1 -25 -4 -32 -10 -23 -7 9 -8 7 -4
-7 3 -11 8 -38 12 -60 8 -43 31 -75 42 -58 4 5 14 10 23 10 12 0 15 -12 15
-63 -1 -34 -4 -66 -8 -70 -11 -10 -10 -26 0 -20 10 6 10 -70 0 -80 -10 -10
-10 -27 0 -20 4 2 8 -3 9 -12 1 -9 2 -32 4 -49 2 -29 3 -31 15 -15 16 20 18 9
4 -18 -6 -11 -6 -21 -1 -24 5 -3 12 9 16 27 4 17 9 26 12 19 2 -6 9 -10 14 -6
10 6 12 -6 14 -98 0 -38 -3 -68 -7 -68 -4 0 -8 5 -8 10 0 6 -5 10 -10 10 -7 0
-7 -6 0 -19 13 -25 13 -34 0 -26 -6 3 -7 -1 -4 -9 3 -9 12 -16 20 -16 11 0 14
-18 12 -82 -1 -68 2 -83 17 -95 11 -7 13 -13 7 -13 -7 0 -12 -4 -12 -10 0 -19
21 -10 30 13 5 12 9 16 9 8 1 -10 4 -11 15 -2 15 13 15 3 0 -96 -3 -24 -2 -43
3 -43 5 0 10 -23 12 -51 2 -40 -1 -49 -11 -43 -10 6 -11 5 -1 -7 7 -8 12 -24
12 -34 -1 -22 5 -48 19 -84 l9 -25 5 27 c3 15 1 27 -3 27 -5 0 -9 4 -9 9 0 6
9 9 20 8 11 -1 20 -6 20 -12 0 -5 5 -3 11 5 7 10 9 11 7 2 -2 -8 -8 -13 -13
-12 -16 2 -35 -68 -25 -93 l9 -22 0 23 c1 12 7 22 14 22 11 0 13 -19 11 -82
-1 -46 -5 -92 -9 -103 -6 -16 -5 -17 4 -5 9 12 11 8 7 -18 l-5 -34 42 4 c34 2
42 0 43 -15 1 -12 1 -13 -3 -2 -2 8 -9 15 -14 15 -12 0 -11 -7 3 -33 14 -25
20 -149 7 -141 -5 3 -9 0 -9 -5 0 -6 5 -11 11 -11 5 0 7 -5 4 -11 -4 -6 -14
-8 -22 -5 -8 3 -11 1 -8 -4 3 -6 14 -10 23 -10 16 -1 16 -1 0 -11 -10 -5 -18
-15 -18 -22 0 -7 4 -6 10 3 6 9 10 10 10 3 0 -6 3 -19 7 -29 5 -13 3 -15 -7
-9 -10 6 -12 4 -6 -11 5 -10 10 -34 13 -52 2 -19 11 -44 19 -57 10 -15 13 -32
9 -46 -5 -15 -4 -19 5 -14 7 4 10 19 6 35 -3 20 -1 30 10 34 24 9 25 8 12 -13
-9 -15 -9 -25 2 -48 9 -17 15 -68 16 -133 2 -138 2 -133 19 -126 8 3 15 1 15
-3 0 -5 -5 -11 -11 -13 -6 -2 -7 -12 -1 -28 9 -25 9 -25 16 7 4 18 12 35 16
38 14 9 29 -58 16 -67 -6 -4 -12 -3 -14 2 -5 13 -32 -15 -32 -32 0 -10 2 -10
9 1 5 8 18 16 28 17 14 2 18 -4 19 -26 1 -17 -3 -30 -8 -30 -5 0 -6 -5 -3 -11
11 -16 17 -89 8 -89 -4 0 -9 -11 -9 -25 -1 -14 2 -22 7 -19 6 3 7 -4 4 -18 -6
-21 -3 -23 29 -22 40 2 41 1 26 -27 -7 -11 -7 -19 -1 -19 5 0 13 12 19 28 6
20 8 5 7 -58 -2 -89 -10 -124 -27 -114 -6 4 -5 -3 2 -16 6 -12 9 -28 6 -35 -2
-7 2 -19 10 -25 17 -15 19 -34 2 -24 -7 5 -8 3 -3 -6 5 -7 9 -25 10 -40 3 -33
32 -65 60 -65 17 0 20 -7 20 -49 -1 -39 -4 -50 -18 -52 -20 -3 -23 12 -4 19 6
2 10 7 7 10 -3 3 -12 2 -19 -2 -10 -7 -11 -15 -3 -37 6 -16 11 -23 11 -16 1 6
8 12 16 12 11 0 15 -12 15 -52 0 -28 -3 -48 -7 -45 -3 4 -6 24 -6 44 1 36 1
37 -7 10 -5 -17 -5 -40 2 -55 5 -15 12 -42 14 -61 6 -42 12 -53 26 -45 6 4 8
2 4 -4 -3 -6 -2 -13 4 -17 5 -3 10 -18 10 -33 0 -21 -2 -24 -10 -12 -6 9 -10
10 -10 3 0 -6 5 -15 11 -18 6 -4 8 -13 5 -21 -5 -12 19 -84 27 -84 4 0 1 40
-3 50 -1 3 -2 9 -1 13 1 4 -4 17 -10 29 -9 18 -9 20 4 15 10 -4 17 0 19 11 2
9 4 -22 4 -70 0 -75 -2 -84 -14 -70 -7 9 -11 11 -7 5 10 -21 23 -80 24 -117 1
-15 4 -16 16 -6 13 11 14 10 9 -5 -5 -13 -4 -16 4 -11 7 4 12 1 12 -8 0 -20
-17 -29 -24 -14 -3 7 -3 0 1 -17 3 -16 12 -38 21 -47 8 -10 12 -24 9 -32 -3
-8 -1 -17 6 -20 9 -3 9 -5 0 -5 -8 -1 -7 -16 4 -53 8 -29 17 -66 20 -81 4 -19
10 -26 19 -23 8 3 14 1 14 -3 0 -5 -4 -11 -10 -13 -5 -1 -7 -13 -3 -26 5 -22
7 -21 24 15 9 21 18 33 18 26 1 -7 -4 -23 -10 -35 -8 -18 -6 -31 5 -53 l16
-30 -2 27 c-1 15 -6 24 -11 21 -5 -3 -6 5 -2 18 15 50 22 37 22 -40 0 -45 -3
-78 -8 -75 -5 3 -9 -2 -9 -10 0 -9 5 -16 11 -16 8 0 10 -16 6 -49 -4 -37 -1
-58 14 -87 10 -21 19 -31 19 -22 0 18 27 44 37 34 10 -10 13 -76 3 -76 -4 0
-11 6 -13 13 -4 9 -6 9 -6 -1 -1 -7 6 -15 14 -18 8 -4 15 -16 14 -28 0 -20 -1
-20 -9 -1 -8 18 -9 17 -9 -7 -1 -15 4 -30 9 -33 6 -3 10 -12 10 -18 0 -7 -4
-6 -10 3 -8 13 -10 12 -10 -3 0 -10 -4 -15 -10 -12 -13 8 -13 3 5 -36 14 -30
15 -31 10 -6 -3 16 -1 27 5 27 8 0 10 -20 7 -60 -4 -43 -1 -62 9 -71 16 -13
19 -33 4 -24 -6 3 -7 -1 -3 -10 5 -13 7 -14 14 -3 5 7 6 20 4 28 -3 11 3 15
24 16 l28 1 -1 -78 c0 -43 -3 -76 -5 -73 -2 2 -10 -8 -17 -21 -13 -26 -9 -55
7 -55 6 0 5 10 -2 21 -9 17 -8 20 3 17 8 -3 13 -22 14 -51 0 -27 -3 -42 -8
-35 -12 21 -9 3 7 -33 8 -19 14 -28 15 -21 0 6 7 12 15 12 8 0 15 -6 15 -12 0
-9 4 -8 10 2 8 12 10 12 11 -5 l0 -20 9 20 c8 18 9 18 9 2 1 -29 -18 -57 -39
-57 -11 0 -20 6 -20 14 0 9 7 12 20 9 25 -7 26 8 1 24 -16 10 -20 9 -25 -10
-6 -25 2 -84 15 -108 4 -9 5 -20 1 -24 -4 -5 -1 -5 6 -1 7 4 12 1 12 -9 0 -8
6 -18 13 -20 6 -3 12 -21 13 -40 0 -31 -1 -32 -14 -17 -8 10 -7 5 2 -11 10
-15 14 -37 11 -47 -5 -15 -3 -18 9 -14 12 4 16 0 16 -16 0 -11 -4 -18 -10 -15
-13 8 -13 -5 0 -25 8 -13 10 -12 10 3 0 9 5 17 10 17 15 0 12 -28 -5 -37 -10
-6 -11 -13 -5 -23 8 -13 10 -12 10 3 0 10 5 15 10 12 6 -3 10 2 10 12 0 11 4
23 9 28 5 6 11 19 13 30 3 11 4 -4 4 -34 0 -30 -2 -49 -4 -43 -6 20 -36 -18
-38 -49 -1 -16 2 -26 7 -23 5 3 9 -2 9 -10 0 -8 7 -19 16 -24 18 -10 14 -48
-4 -44 -9 1 -8 -7 3 -29 9 -17 14 -37 11 -44 -3 -9 5 -12 30 -10 35 4 40 -1
25 -24 -7 -11 -11 -10 -20 5 -9 17 -10 17 -11 2 0 -10 5 -18 11 -18 6 0 9 -9
6 -20 -3 -11 -1 -23 4 -26 5 -3 9 1 9 9 0 8 6 30 14 48 l14 33 -1 -54 c-1 -29
-3 -48 -5 -41 -3 8 -11 4 -23 -10 -15 -17 -19 -19 -19 -6 0 9 -4 19 -10 22 -5
3 -3 -12 5 -34 7 -21 10 -43 7 -47 -4 -4 -1 -4 6 0 7 4 12 1 12 -9 0 -8 6 -18
13 -20 6 -3 13 -22 14 -43 l2 -37 -14 30 c-8 17 -14 23 -15 15 0 -9 7 -26 16
-38 8 -12 13 -31 9 -41 -3 -11 -1 -22 5 -26 5 -3 10 -1 10 4 0 6 7 11 15 11 8
0 15 -6 15 -12 0 -9 4 -8 10 2 8 13 10 12 10 -2 0 -10 3 -18 8 -18 10 0 8 -71
-3 -95 -5 -11 -16 -25 -24 -32 -10 -8 -11 -12 -4 -13 6 0 18 9 26 20 13 19 14
18 14 -11 0 -16 -3 -25 -7 -19 -10 16 -32 -14 -24 -34 7 -18 24 -22 24 -6 0 6
-4 10 -10 10 -5 0 -10 4 -10 10 0 5 7 7 15 4 17 -7 17 -51 1 -73 -9 -11 -8
-13 2 -7 10 6 12 1 9 -22 -3 -21 0 -32 9 -36 8 -3 14 -10 14 -16 0 -6 -4 -8
-10 -5 -6 3 -7 -1 -4 -10 6 -14 8 -14 20 2 16 21 18 37 4 28 -5 -3 -10 -2 -10
4 0 6 13 9 28 8 27 -2 28 -3 30 -73 2 -56 -1 -73 -13 -78 -9 -3 -15 0 -15 8 0
8 4 16 10 18 6 2 7 24 3 55 -3 29 -10 50 -14 47 -8 -5 -6 -27 8 -77 4 -15 1
-18 -16 -12 -13 4 -21 2 -21 -5 0 -6 5 -8 10 -5 6 4 10 -5 10 -20 0 -22 3 -25
20 -20 12 4 20 2 20 -5 0 -6 -4 -9 -9 -6 -19 12 -29 -19 -15 -49 l14 -30 -5
35 -5 35 11 -29 c9 -22 9 -34 0 -48 -6 -10 -8 -18 -4 -18 12 0 33 -76 28 -97
-3 -10 1 -4 10 14 28 61 48 56 51 -12 2 -51 -12 -70 -18 -25 -3 16 0 31 6 33
6 2 6 11 1 23 -7 18 -8 18 -14 -5 -11 -40 -1 -100 15 -94 10 4 14 -4 14 -31 0
-27 -3 -34 -15 -30 -9 4 -15 0 -15 -10 0 -10 6 -14 18 -10 15 5 15 4 2 -8 -9
-7 -14 -22 -12 -32 3 -15 6 -16 14 -5 8 11 9 10 4 -4 -9 -26 4 -67 20 -67 8 0
14 -10 13 -22 0 -13 -3 -17 -6 -10 -10 26 -21 10 -10 -17 6 -17 14 -28 18 -25
4 2 10 -2 12 -8 4 -8 6 -7 6 4 1 9 -3 19 -8 22 -11 7 -3 44 12 53 6 4 8 -4 3
-23 -4 -18 -3 -24 2 -16 8 11 10 11 15 -2 3 -8 1 -16 -4 -18 -6 -2 -11 -26
-11 -53 0 -28 -4 -53 -9 -56 -6 -3 -5 -17 2 -35 7 -16 11 -33 10 -37 -1 -5 3
-6 8 -3 5 3 9 -9 10 -27 2 -63 3 -68 18 -55 11 9 12 6 3 -17 -6 -15 -8 -30 -5
-33 3 -4 8 4 12 16 3 12 9 19 14 16 5 -3 6 -16 3 -30 -3 -13 -2 -24 3 -24 5 0
9 8 9 18 1 9 5 26 9 36 6 13 5 17 -3 12 -6 -3 -14 -1 -17 5 -5 7 -1 10 11 7
16 -3 18 -16 21 -111 1 -59 -1 -107 -6 -107 -4 0 -6 11 -3 25 3 16 0 25 -8 25
-9 0 -9 4 -2 13 12 15 13 69 1 61 -5 -3 -7 -15 -5 -27 1 -12 1 -16 -1 -9 -5
14 -23 16 -23 3 0 -6 5 -13 10 -16 6 -4 8 -10 5 -15 -3 -5 -1 -12 5 -15 6 -4
8 -15 5 -26 -4 -12 -1 -21 9 -25 9 -3 16 -10 16 -16 0 -5 -6 -6 -12 -2 -10 6
-10 4 0 -8 7 -9 12 -20 11 -24 -5 -27 3 -53 17 -58 9 -3 12 -2 9 4 -4 6 6 10
24 8 31 -2 31 -3 30 -53 -2 -45 -4 -51 -23 -51 -16 1 -20 -4 -18 -19 2 -11 8
-22 13 -23 5 -2 7 -8 3 -13 -3 -5 0 -9 5 -9 6 0 11 7 11 15 0 8 -4 15 -10 15
-5 0 -10 5 -10 10 0 6 7 10 15 10 20 0 20 -59 0 -66 -12 -5 -12 -8 -1 -20 8
-8 14 -33 14 -60 -1 -48 13 -68 44 -58 10 3 21 -3 27 -14 11 -22 10 -82 -1
-82 -5 0 -8 -5 -8 -11 0 -5 5 -7 10 -4 6 3 10 0 10 -9 0 -8 -4 -16 -9 -18 -4
-1 -12 -14 -16 -28 -7 -24 -6 -24 8 -6 13 18 14 15 13 -32 -2 -41 1 -49 11
-40 16 13 17 4 3 -23 -8 -14 -7 -25 5 -41 10 -16 15 -18 15 -7 0 8 -5 20 -12
27 -9 9 -8 12 5 12 12 0 17 -8 17 -26 0 -14 4 -23 10 -19 5 3 7 11 4 18 -2 7
1 20 8 30 11 15 13 -9 13 -138 0 -85 3 -155 7 -155 4 0 5 -8 2 -17 -2 -10 1
-8 7 5 7 12 17 22 23 22 6 0 5 -5 -2 -12 -7 -7 -12 -17 -12 -22 1 -6 9 -1 20
12 21 25 40 22 40 -6 0 -14 -2 -15 -9 -3 -7 11 -11 4 -17 -25 -4 -21 -11 -42
-17 -45 -5 -4 -6 -12 -2 -19 6 -9 9 -9 16 1 7 12 9 11 10 -1 0 -10 3 -9 9 5 7
14 9 -1 8 -50 -1 -38 -6 -73 -11 -76 -5 -4 -7 -15 -3 -25 4 -11 7 -21 8 -24 1
-17 21 -41 31 -37 7 3 12 -7 13 -23 1 -22 -3 -27 -15 -23 -11 3 -10 0 4 -11
11 -8 26 -20 33 -26 9 -8 12 -7 12 5 0 8 -4 15 -9 15 -10 0 -23 42 -15 49 3 3
10 -1 15 -9 8 -12 10 -10 6 8 -3 12 -2 22 3 22 4 0 7 -31 7 -69 -1 -38 -5 -67
-9 -65 -4 3 -8 1 -8 -5 0 -5 5 -13 10 -16 13 -8 13 -52 0 -60 -6 -4 -8 7 -5
27 5 32 5 32 -4 5 -7 -20 -5 -42 6 -75 9 -26 18 -53 19 -60 2 -7 9 -10 15 -6
8 5 8 1 0 -14 -7 -14 -8 -29 -1 -46 11 -31 15 -32 17 -6 1 11 -2 20 -7 20 -5
0 -4 11 2 25 8 19 14 22 20 13 6 -11 8 -10 8 0 0 7 5 10 10 7 16 -10 12 -35
-5 -29 -12 5 -15 -1 -14 -27 1 -32 2 -32 9 -9 4 14 9 21 11 15 6 -24 4 -41 -6
-47 -6 -5 -5 -11 5 -17 8 -6 11 -13 8 -16 -10 -10 -28 4 -28 21 0 8 -4 14 -9
14 -4 0 -6 -11 -3 -25 2 -14 9 -22 15 -18 6 4 8 -4 3 -23 l-6 -29 15 25 c14
24 14 24 12 -22 -3 -45 11 -67 32 -53 5 2 19 -7 31 -20 13 -14 19 -25 13 -25
-6 0 -13 5 -15 12 -2 6 -13 12 -24 12 -11 1 -25 6 -33 10 -10 7 -12 3 -6 -15
4 -13 12 -29 17 -36 5 -6 10 -26 10 -43 1 -21 4 -27 9 -18 7 10 9 9 9 -4 0 -9
3 -24 7 -34 5 -13 3 -15 -8 -9 -11 7 -12 6 -2 -7 7 -8 10 -18 6 -21 -3 -4 -1
-7 6 -7 8 0 10 -7 6 -19 -3 -11 -2 -22 3 -25 4 -3 7 14 6 37 -5 68 -10 96 -23
111 -7 8 -9 21 -5 27 4 7 -1 18 -11 26 -16 11 -16 13 -3 13 24 0 40 -24 26
-38 -8 -8 -8 -12 -1 -12 24 0 41 -163 20 -189 -10 -12 -9 -13 2 -6 11 6 13 2
7 -19 -4 -17 -3 -25 3 -22 5 3 12 -1 15 -10 8 -21 8 -26 -3 -20 -4 3 -6 -7 -3
-22 l6 -27 8 31 c10 40 34 62 50 46 9 -9 9 -12 0 -12 -7 0 -12 -8 -12 -17 0
-14 2 -15 9 -5 5 8 7 0 7 -22 -1 -19 -5 -38 -9 -42 -4 -4 -7 -17 -6 -28 0 -21
1 -21 9 -1 6 14 9 15 9 4 1 -8 -4 -20 -11 -27 -7 -7 -8 -12 -3 -12 6 0 10 -28
9 -63 0 -73 10 -107 31 -107 18 0 19 -5 5 -31 -7 -13 -7 -19 0 -19 5 0 10 -5
10 -11 0 -5 -4 -8 -9 -5 -5 4 -7 -3 -4 -14 3 -11 10 -20 15 -20 5 0 7 -9 3
-19 -3 -11 0 -23 6 -27 7 -4 9 -3 5 4 -3 5 0 12 6 15 10 3 10 8 -2 22 -19 23
-22 73 -5 105 13 24 13 24 19 -20 7 -58 3 -140 -6 -140 -5 0 -8 -8 -8 -17 0
-15 2 -16 10 -3 7 10 8 2 5 -28 -3 -34 0 -47 16 -60 21 -18 25 -38 8 -44 -14
-5 6 -57 25 -64 11 -4 13 -12 5 -31 -5 -13 -6 -30 -2 -36 4 -7 9 -21 10 -31 2
-11 8 -25 14 -33 6 -7 8 -20 5 -29 -5 -13 -2 -15 15 -11 15 4 19 2 15 -10 -3
-8 -6 -17 -6 -19 0 -3 -4 -2 -10 1 -5 3 -8 0 -6 -7 3 -7 12 -37 21 -65 16 -51
35 -71 35 -37 0 8 -4 12 -10 9 -5 -3 -10 -1 -10 4 0 6 5 11 11 11 6 0 8 9 4
21 -4 12 -3 19 2 16 5 -3 10 8 11 26 6 75 10 27 8 -100 -2 -95 2 -146 11 -164
6 -15 9 -29 6 -33 -3 -3 2 -15 12 -26 14 -15 15 -20 4 -20 -12 0 -12 -2 0 -9
8 -5 10 -14 6 -21 -5 -9 -4 -11 4 -6 19 12 37 -17 37 -63 -1 -43 -1 -43 -13
-15 -7 16 -12 24 -13 17 0 -6 5 -24 12 -40 6 -15 10 -34 9 -40 -1 -7 5 -13 13
-13 8 0 21 -3 30 -6 12 -4 16 0 16 15 0 22 14 28 25 11 10 -17 -37 -50 -53
-38 -20 17 -26 1 -7 -18 13 -14 14 -19 3 -25 -11 -7 -11 -9 1 -9 8 0 11 -6 7
-16 -4 -9 -1 -23 6 -31 12 -15 15 -24 23 -87 2 -21 7 -41 11 -45 4 -3 4 4 1
17 -3 12 -1 22 4 22 5 0 9 -19 9 -42 0 -69 2 -77 19 -70 14 5 14 4 1 -14 -12
-17 -12 -17 0 -6 8 7 16 17 16 22 1 6 2 14 3 19 1 10 20 9 37 -2 16 -10 26 4
13 20 -14 17 3 17 20 1 10 -11 12 -10 9 1 -2 7 3 16 11 19 9 3 11 0 7 -9 -4
-8 2 -4 13 9 11 13 24 21 28 19 5 -3 17 1 28 9 16 12 17 14 2 14 -9 0 -17 -5
-17 -10 0 -7 -6 -7 -19 -1 -20 11 -32 5 -25 -13 3 -7 -8 -11 -29 -12 -27 0
-33 3 -33 21 0 13 6 21 18 21 10 0 18 -4 18 -8 0 -4 -5 -8 -12 -8 -6 0 -9 -3
-6 -6 4 -3 17 1 30 9 12 8 42 14 66 13 38 -1 47 4 43 27 -1 5 3 6 9 2 6 -3 7
-12 3 -18 -4 -7 4 -4 17 5 14 9 24 21 23 27 -2 5 6 13 17 16 11 4 20 11 20 17
0 7 4 7 14 -1 10 -8 22 -5 50 14 20 14 41 25 46 25 15 0 12 16 -5 30 -9 8 -15
8 -15 2 0 -6 3 -12 8 -15 4 -2 1 -2 -6 0 -7 1 -10 -1 -7 -6 3 -5 2 -12 -2 -14
-5 -3 -9 7 -9 22 -1 26 1 28 35 27 20 -1 41 -5 47 -9 7 -4 23 2 37 13 14 11
31 20 37 20 7 0 10 7 6 16 -3 9 -6 21 -6 27 0 7 -4 6 -10 -3 -5 -8 -12 -11
-16 -7 -12 12 3 20 45 23 27 2 39 -1 37 -9 -1 -7 -1 -9 1 -4 3 4 10 4 15 1 7
-5 8 -2 3 6 -6 10 -4 12 8 7 19 -7 32 8 16 19 -8 5 -9 3 -4 -7 5 -8 4 -10 0
-6 -5 4 -10 14 -11 23 -2 14 0 14 20 0 21 -13 26 -13 50 3 14 10 23 21 19 26
-5 4 -1 6 8 3 9 -4 22 -2 30 4 11 8 10 9 -3 4 -10 -3 -18 -1 -18 4 0 6 14 10
31 10 20 0 28 4 23 12 -5 8 -2 9 9 5 10 -3 17 -2 17 3 0 6 -7 10 -16 10 -10 0
-5 9 16 26 30 26 31 26 52 8 21 -19 21 -19 16 1 -3 11 -9 27 -12 35 -4 10 3
21 21 32 17 11 22 18 13 18 -9 0 -22 -6 -29 -12 -11 -11 -13 -10 -9 2 6 20
135 22 135 3 0 -16 -9 -16 -26 0 -10 8 -12 8 -7 0 4 -8 0 -11 -15 -8 -15 3
-19 0 -16 -10 3 -7 -1 -16 -9 -19 -8 -3 -14 -11 -14 -17 0 -8 7 -6 18 4 10 10
22 14 26 10 4 -4 4 1 0 12 -6 16 -4 18 6 8 14 -13 55 1 45 16 -2 5 2 14 10 21
8 7 12 16 9 21 -8 13 10 11 24 -3 9 -9 12 -9 12 0 0 7 -5 12 -12 12 -6 0 -8 3
-4 7 4 5 14 0 21 -10 13 -18 14 -18 33 1 11 11 23 17 27 14 3 -4 3 2 -1 13 -5
12 -9 14 -12 7 -3 -10 -7 -10 -19 -1 -11 10 -13 10 -11 -1 2 -7 -3 -15 -10
-17 -7 -3 -11 1 -10 8 2 7 -8 15 -22 19 -15 4 5 7 50 7 96 0 151 13 115 27
-11 4 -14 2 -9 -6 4 -7 3 -8 -4 -4 -13 8 -5 36 10 36 5 0 6 -4 3 -10 -10 -16
22 -12 60 9 19 10 31 22 27 26 -4 4 0 5 8 3 18 -6 70 18 70 32 0 6 3 9 8 9 20
-3 29 4 40 35 7 18 9 41 6 51 -6 14 -3 16 14 11 12 -4 23 -2 28 5 5 8 2 10 -8
7 -8 -4 -21 -2 -29 2 -10 7 -7 9 11 8 35 -2 55 -18 38 -30 -9 -7 -5 -8 12 -4
14 3 31 6 38 6 7 0 10 5 7 11 -4 6 -13 8 -21 5 -7 -3 -16 -2 -18 3 -3 4 7 8
22 8 57 2 92 8 92 16 0 4 6 5 13 1 6 -4 9 -4 5 1 -5 5 2 13 14 19 12 6 23 16
25 22 4 11 -15 11 -63 0 -12 -2 -21 1 -21 8 0 14 74 16 95 2 8 -4 25 -3 41 4
25 10 28 16 25 49 -3 34 -2 37 24 38 15 1 21 -1 14 -4 -7 -2 -17 -14 -23 -26
-10 -21 -9 -21 5 -3 10 12 23 17 36 13 18 -5 19 -3 8 10 -12 15 -11 15 11 4
17 -9 26 -10 34 -2 6 6 7 11 3 11 -5 0 -1 11 7 24 15 23 15 24 -10 24 -16 0
-21 -3 -12 -8 10 -7 10 -12 0 -23 -8 -10 -8 -17 -1 -20 6 -3 5 -4 -2 -3 -12 2
-14 23 -9 71 1 13 4 13 21 3 14 -9 23 -10 28 -2 8 11 85 16 78 5 -3 -4 4 -7
14 -8 11 -1 22 2 25 8 3 5 -1 6 -11 2 -14 -5 -15 -3 -6 14 6 11 10 22 9 24 -1
2 4 -3 12 -11 10 -11 14 -12 18 -2 3 6 9 12 14 12 6 0 7 -5 4 -11 -4 -6 1 -6
12 2 11 6 20 18 20 25 0 7 -3 11 -7 9 -4 -2 -15 2 -25 9 -17 12 -17 13 1 11
11 0 25 -4 31 -8 6 -4 14 -3 18 3 3 5 11 10 18 10 6 0 2 -7 -9 -15 -10 -8 -14
-15 -7 -15 6 0 18 6 25 13 19 18 68 48 68 42 0 -4 13 2 30 11 20 12 26 20 18
25 -7 5 -9 11 -5 15 4 4 12 3 18 -3 14 -14 39 -6 39 13 0 8 10 14 23 14 12 0
18 4 12 8 -5 4 -11 17 -12 28 -2 19 3 21 35 20 20 -1 44 -6 52 -11 8 -4 12 -4
7 0 -4 5 10 17 30 27 35 17 24 17 -27 2 -12 -4 -20 -2 -20 5 0 5 7 8 15 5 8
-4 15 -1 15 5 0 6 -6 11 -13 11 -7 0 -13 10 -14 23 0 12 1 16 4 10 3 -7 11
-13 19 -13 8 0 14 -4 14 -10 0 -5 7 -10 17 -10 13 0 11 4 -7 20 -13 11 -19 20
-14 20 6 0 15 -5 22 -12 7 -7 16 -8 24 -2 16 11 38 11 57 0 10 -6 12 -5 7 2
-4 7 -1 13 8 13 32 3 35 4 51 24 9 11 14 13 10 5 -4 -8 1 -6 10 5 9 11 24 19
33 17 14 -2 15 -1 2 7 -9 7 -19 6 -27 -1 -7 -6 -13 -7 -13 -3 0 5 -9 0 -20
-10 -11 -10 -20 -14 -21 -9 0 5 -4 0 -9 -11 -6 -16 -8 -12 -7 15 l2 35 50 0
c27 -1 56 -5 64 -9 10 -6 12 -5 7 2 -5 8 0 12 13 12 12 0 21 4 21 10 0 11 60
45 73 42 5 -1 6 2 2 8 -3 5 -2 10 4 10 5 0 8 11 7 24 -2 16 1 23 9 20 7 -3 11
-16 10 -29 l-3 -24 33 22 c28 18 31 23 17 28 -9 3 -21 5 -27 3 -5 -2 -19 3
-29 11 -30 22 -6 43 29 27 31 -14 38 -15 31 -3 -7 11 55 8 75 -4 8 -4 11 -5 7
0 -9 9 9 35 24 35 6 0 4 -8 -3 -17 -9 -11 -1 -8 20 7 24 16 30 25 20 28 -8 3
-24 12 -35 21 -21 16 -20 17 21 17 35 0 41 -3 37 -18 -2 -10 1 -18 7 -18 7 0
9 5 5 11 -4 7 -1 10 7 7 8 -3 26 2 41 10 25 14 27 21 23 55 -5 36 -4 38 12 26
11 -10 14 -10 9 -1 -4 6 -3 12 3 12 14 0 32 -35 23 -44 -3 -4 1 -4 11 0 9 3
17 10 17 16 0 5 -4 7 -9 4 -5 -3 -12 1 -15 9 -4 11 0 15 12 13 12 -2 16 3 15
17 -1 13 8 27 25 38 17 10 21 17 12 17 -9 0 -21 -5 -28 -12 -9 -9 -12 -9 -12
0 0 20 27 25 70 14 23 -6 42 -6 46 -1 3 5 10 9 15 9 6 0 8 -4 5 -8 -2 -4 3 -8
12 -8 14 0 17 10 17 45 0 38 3 46 20 49 l20 4 -20 -16 c-20 -15 -20 -15 3 -9
12 3 22 9 22 13 0 4 15 7 34 7 19 -1 33 -4 31 -7 -2 -4 2 -7 10 -7 8 0 12 4 9
9 -3 4 1 8 8 9 45 2 83 24 72 41 -3 5 0 9 7 7 25 -3 39 1 39 10 0 10 -12 9
-47 -3 -14 -4 -20 -12 -16 -23 5 -13 3 -13 -15 -2 -21 13 -21 14 11 29 18 9
49 16 68 15 38 -1 72 12 64 24 -3 5 7 12 23 16 15 4 21 10 14 12 -6 3 -10 9
-6 14 3 5 0 9 -6 9 -7 0 -9 -7 -6 -16 4 -9 1 -14 -6 -12 -6 2 -12 11 -13 19
-3 20 41 23 49 4 6 -17 24 -12 82 25 38 24 51 56 18 43 -13 -5 -13 -8 -2 -15
9 -7 6 -8 -10 -5 -18 4 -22 2 -18 -10 5 -12 1 -14 -15 -10 -15 4 -19 2 -15 -9
3 -9 1 -12 -4 -9 -6 3 -10 11 -10 16 0 15 42 48 60 49 11 0 12 3 3 8 -6 4 9 8
35 8 37 1 42 0 24 -7 -19 -7 -23 -29 -6 -29 3 0 2 5 -2 12 -5 7 -2 9 9 5 9 -3
18 0 21 9 3 9 11 12 17 8 8 -5 9 -3 5 5 -6 8 -2 11 10 9 13 -2 19 3 19 14 0
10 5 17 10 15 6 -1 9 -5 8 -9 -2 -4 4 -5 12 -2 8 4 15 10 15 16 0 5 4 6 10 3
6 -4 7 1 3 11 -6 15 -7 15 -21 -2 -13 -16 -14 -17 -9 -1 7 20 6 21 -27 6 -21
-10 -24 -15 -15 -33 10 -20 10 -20 -5 -2 -20 25 -20 36 -1 36 8 0 15 3 15 8 0
4 18 8 40 9 22 1 38 -2 36 -6 -3 -4 1 -8 9 -8 8 0 11 5 8 10 -4 6 0 8 10 4 11
-4 20 1 27 13 6 11 7 20 3 20 -14 1 30 30 44 30 11 0 11 -3 1 -14 -7 -7 -18
-12 -24 -9 -6 2 -11 -3 -11 -12 0 -14 2 -14 21 0 12 8 31 20 44 25 12 5 22 14
22 20 0 6 5 8 10 5 6 -4 9 -1 8 6 -2 6 3 14 11 17 10 3 11 0 5 -9 -6 -11 -5
-12 7 -3 8 6 21 14 29 18 8 3 5 4 -7 2 -13 -2 -23 0 -23 5 0 8 20 13 41 11 3
-1 6 5 6 13 0 8 -4 12 -9 9 -4 -3 -8 -1 -8 5 0 5 9 9 20 8 12 -1 18 -7 16 -14
-8 -20 11 -15 35 10 12 12 18 15 14 7 -5 -9 -4 -12 1 -7 5 5 9 14 9 20 0 7 10
13 23 15 12 2 22 7 22 11 0 4 10 7 21 8 27 2 51 23 27 23 -9 0 -20 -5 -23 -10
-4 -6 -12 -7 -18 -3 -7 4 -25 8 -41 9 -20 1 -24 -1 -15 -7 11 -7 10 -9 -4 -9
-11 0 -17 5 -14 13 2 8 28 12 78 13 74 0 139 21 139 45 0 6 6 7 13 3 8 -5 8
-2 -2 9 -18 22 -33 21 -48 -2 -9 -15 -9 -21 -1 -23 8 -3 7 -6 -3 -10 -11 -4
-15 2 -15 26 0 27 4 32 26 34 14 1 35 -4 45 -12 17 -12 23 -12 50 7 16 12 33
22 37 22 3 0 10 4 15 9 5 5 3 6 -4 2 -7 -4 -13 -3 -13 2 0 14 28 23 32 11 2
-5 19 4 38 21 19 17 39 29 45 26 6 -2 21 5 34 15 14 11 18 19 10 19 -11 0 -11
2 1 10 11 7 9 8 -10 6 -39 -5 -59 -17 -54 -31 3 -7 1 -15 -4 -19 -10 -6 -9 30
1 40 4 4 2 13 -3 19 -8 9 5 11 52 9 90 -4 98 0 100 49 l1 42 7 -45 c4 -27 7
22 7 120 -1 132 -2 149 -8 85 -6 -71 -6 -69 -3 25 2 58 6 128 8 155 3 47 3 47
-4 10 -4 -22 -6 64 -3 190 3 127 4 237 3 245 -1 8 -4 356 -6 773 -5 704 -6
757 -22 758 -46 5 -58 4 -75 -4 -11 -5 -24 -4 -33 4 -11 9 -14 8 -14 -6 0 -11
-5 -15 -14 -12 -10 4 -13 1 -9 -9 6 -16 15 -18 29 -5 12 12 58 -24 49 -39 -10
-17 -25 -11 -25 10 0 17 -5 19 -25 14 -15 -4 -32 -1 -42 7 -9 8 -14 9 -10 3 4
-7 0 -15 -9 -18 -8 -3 -12 -10 -9 -16 3 -5 -1 -10 -10 -10 -10 0 -14 5 -9 13
5 8 4 9 -4 2 -6 -5 -12 -27 -14 -49 -3 -36 -6 -39 -36 -41 -27 -2 -30 0 -21
11 6 8 14 12 18 10 14 -9 34 12 28 29 -4 9 -12 13 -18 9 -8 -4 -7 -9 2 -15 8
-5 12 -9 9 -10 -34 -6 -43 -9 -55 -19 -8 -7 -13 -23 -11 -36 5 -26 -13 -47
-31 -36 -8 5 -9 13 -3 22 9 13 10 13 10 0 0 -13 2 -13 9 0 14 21 3 31 -19 18
-10 -7 -16 -16 -14 -20 3 -4 -1 -8 -8 -8 -11 0 -11 -2 0 -9 10 -6 10 -10 -3
-21 -9 -7 -15 -9 -13 -4 2 5 -1 9 -7 9 -5 0 -8 -4 -6 -10 8 -23 9 -75 0 -81
-9 -5 -13 9 -10 35 2 7 -2 10 -8 7 -5 -4 -12 -2 -15 3 -3 5 1 11 8 14 8 3 17
2 20 -4 2 -5 3 -4 2 3 -4 17 -27 17 -48 -1 -12 -11 -14 -19 -7 -32 10 -19 4
-26 -21 -24 -8 0 -14 7 -12 14 1 7 -2 10 -8 6 -6 -3 -7 -11 -4 -17 5 -7 1 -9
-9 -5 -12 4 -17 1 -17 -9 0 -11 -5 -14 -16 -10 -11 4 -15 2 -11 -8 4 -11 1
-13 -11 -9 -15 6 -15 4 1 -14 12 -14 15 -23 8 -28 -11 -6 -15 0 -12 20 1 6 -5
2 -12 -7 -15 -19 -33 -25 -22 -8 3 6 -1 7 -9 4 -18 -7 -17 -6 -19 -51 -1 -17
-6 -35 -11 -40 -5 -5 -6 -3 -2 5 4 7 2 12 -6 12 -10 0 -9 3 2 11 12 8 10 9 -9
4 -18 -6 -22 -4 -15 6 7 12 5 12 -9 0 -19 -15 -13 -33 8 -24 8 3 15 0 15 -7 0
-8 -9 -10 -24 -6 -28 7 -34 -4 -31 -54 2 -19 1 -29 -2 -22 -4 10 -8 10 -19 1
-11 -9 -18 -9 -29 1 -11 9 -15 9 -15 1 0 -7 -4 -10 -10 -6 -5 3 -6 13 -3 23 4
13 0 11 -16 -5 -12 -13 -26 -23 -32 -23 -5 0 -9 -7 -7 -16 2 -10 -3 -19 -11
-22 -8 -3 -11 0 -7 7 4 6 2 9 -6 6 -7 -3 -19 -15 -26 -26 -11 -17 -11 -20 0
-13 7 4 10 3 5 -1 -4 -5 -17 -10 -29 -12 -28 -4 -36 -18 -32 -51 3 -24 0 -27
-32 -29 -21 -1 -32 2 -28 8 3 6 15 8 27 5 15 -3 17 -2 9 6 -17 17 -64 -6 -56
-27 4 -8 0 -23 -7 -32 -10 -12 -10 -15 -1 -9 6 4 12 2 12 -3 0 -6 -7 -11 -15
-11 -8 0 -15 -4 -15 -10 0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 11 4 6 -2 5 -14 -3
-12 -7 -24 -21 -27 -30 -6 -19 -24 -25 -24 -8 0 6 5 10 10 10 15 0 12 17 -5
23 -8 4 -17 1 -21 -5 -4 -7 -3 -8 4 -4 7 4 12 2 12 -5 0 -6 -8 -9 -20 -6 -11
3 -23 1 -26 -4 -3 -5 3 -9 13 -9 10 0 29 -9 43 -20 30 -24 24 -25 -17 -5 -26
13 -34 13 -57 2 -14 -8 -24 -16 -21 -19 11 -11 -55 0 -69 11 -9 8 -23 10 -33
6 -16 -7 -15 -9 7 -15 23 -7 22 -8 -9 -9 -19 -1 -31 2 -28 6 9 9 -12 33 -29
33 -8 0 -14 -9 -14 -20 0 -11 -4 -20 -10 -20 -5 0 -10 8 -10 19 0 11 -9 21
-22 24 -21 5 -21 5 -5 -7 17 -13 24 -32 8 -22 -5 4 -12 0 -14 -6 -4 -10 -8 -9
-16 2 -7 9 -14 11 -18 5 -5 -5 -30 -11 -58 -12 -44 -2 -47 -1 -20 8 17 5 35 7
42 4 7 -2 14 -1 17 3 2 4 -7 9 -22 10 -15 1 -35 10 -45 20 -17 18 -16 18 25
16 29 -2 37 0 23 5 -11 5 -26 6 -33 3 -7 -2 -12 2 -10 9 2 8 -3 13 -12 11 -8
-2 -19 -4 -25 -5 -5 -1 -10 9 -10 23 0 15 -6 25 -15 25 -8 1 -14 7 -12 14 1 7
-2 10 -8 6 -8 -5 -7 -11 1 -21 6 -8 8 -14 4 -14 -4 0 -2 -6 4 -13 12 -15 5
-27 -14 -27 -7 0 -10 16 -9 45 2 43 1 45 -22 39 -13 -3 -31 -1 -38 5 -11 9
-15 9 -18 -1 -3 -7 -5 2 -5 20 l0 32 18 -27 c17 -25 19 -25 25 -8 7 18 8 18
14 0 4 -11 9 -14 13 -8 3 5 -1 19 -11 29 -99 112 -99 112 -111 92 -7 -11 -7
-18 -1 -18 5 0 10 5 10 11 0 5 5 7 12 3 8 -5 5 -11 -7 -20 -10 -8 -16 -16 -13
-19 3 -3 13 1 22 10 9 9 17 14 17 11 1 -3 2 -12 3 -18 1 -7 8 -12 15 -10 7 1
10 -2 6 -8 -3 -6 -12 -7 -18 -3 -7 4 -25 8 -41 8 -17 0 -31 7 -34 15 -3 13 -2
13 7 0 9 -13 11 -13 11 2 0 9 -4 20 -10 23 -5 3 -10 15 -10 26 0 11 5 17 10
14 6 -4 7 -12 4 -18 -4 -8 -2 -7 7 1 12 11 12 14 -1 22 -8 5 -11 10 -6 10 6 0
1 8 -10 19 -10 10 -27 28 -36 40 -19 22 -48 31 -31 9 6 -7 13 -25 17 -41 6
-26 5 -27 -14 -15 -22 14 -27 28 -9 28 6 0 5 6 -3 13 -8 8 -29 12 -52 11 -37
-3 -38 -3 -22 15 10 11 13 22 8 25 -6 3 -12 1 -14 -6 -2 -6 -4 7 -4 29 1 23 6
40 13 40 6 0 9 -9 7 -21 -2 -11 4 -28 13 -38 10 -11 12 -18 5 -18 -7 0 -12 -4
-12 -10 0 -5 7 -10 15 -10 8 0 14 3 14 8 -4 22 3 34 16 28 8 -3 1 14 -17 38
-18 24 -38 41 -45 39 -6 -3 -13 6 -15 19 -2 17 -9 23 -25 22 -13 -1 -23 3 -23
7 0 5 -5 9 -11 9 -5 0 -7 -5 -3 -12 4 -7 3 -8 -5 -4 -9 6 -9 11 1 23 8 10 14
11 16 4 2 -6 8 -11 13 -11 5 0 0 14 -13 30 -12 17 -30 30 -39 30 -10 0 -21 4
-24 10 -4 6 1 7 12 4 14 -4 15 -4 4 4 -7 5 -18 16 -23 23 -8 11 -9 10 -4 -3 3
-10 1 -18 -5 -18 -6 0 -8 -4 -5 -8 3 -5 -1 -9 -9 -9 -18 0 -19 9 -3 25 9 9 9
12 -1 12 -11 0 -11 3 -1 15 7 9 10 18 6 22 -3 3 -6 1 -6 -6 0 -8 -5 -7 -16 3
-8 9 -20 14 -25 11 -5 -4 -6 1 -2 11 5 14 3 15 -16 4 -13 -6 -25 -9 -29 -6 -3
3 0 6 8 6 8 0 9 4 3 13 -15 18 -23 57 -12 57 5 0 9 -3 10 -7 4 -43 14 -61 27
-48 4 4 1 10 -6 12 -6 3 -10 9 -7 13 3 5 -5 20 -17 32 -13 13 -22 27 -20 31 1
4 -7 3 -18 -3 -13 -7 -20 -7 -20 0 0 5 -5 10 -11 10 -6 0 -8 -7 -4 -17 5 -14
3 -15 -6 -6 -16 16 -1 36 23 30 16 -4 17 -3 7 9 -6 7 -16 11 -22 7 -7 -4 -8 0
-3 12 5 14 -5 31 -41 67 -26 26 -53 48 -60 48 -6 0 -18 9 -26 20 -13 18 -15
19 -21 5 -9 -23 -8 -25 9 -19 8 4 15 1 15 -5 0 -6 9 -11 20 -11 13 0 20 -7 20
-20 0 -11 5 -20 11 -20 5 0 7 6 3 13 -4 7 -3 9 2 4 5 -5 9 -14 9 -20 1 -7 6
-11 13 -9 15 3 16 -14 2 -23 -6 -3 -13 1 -16 9 -8 20 -21 21 -21 1 0 -8 4 -12
8 -9 4 2 9 -1 11 -7 3 -9 9 -9 25 0 17 8 24 8 29 -1 4 -7 3 -8 -4 -4 -8 5 -10
2 -6 -8 7 -18 -30 -23 -41 -6 -5 7 -11 8 -19 1 -9 -7 -12 1 -12 35 -1 54 -7
61 -39 46 -21 -10 -25 -9 -26 6 0 14 -2 14 -9 -3 -6 -15 -8 -9 -7 22 1 23 4
40 7 38 3 -2 12 5 18 16 12 19 11 19 -10 8 -21 -11 -22 -11 -9 4 11 14 11 20
0 35 -19 24 -32 32 -41 26 -4 -3 -8 1 -8 9 0 8 -9 22 -20 32 -11 10 -20 24
-20 32 0 8 -4 11 -10 8 -5 -3 -10 -2 -10 3 0 15 -28 23 -36 10 -4 -6 -3 -8 4
-4 6 3 13 2 17 -4 3 -5 -3 -10 -14 -10 -29 0 -36 18 -15 41 17 19 17 20 -2 13
-17 -7 -18 -6 -4 11 8 10 10 16 4 12 -6 -3 -16 5 -23 19 -6 14 -15 23 -20 19
-5 -3 -16 1 -24 9 -8 8 -17 12 -21 9 -3 -4 -6 3 -6 15 0 14 -5 20 -14 16 -17
-6 -31 12 -16 21 5 3 10 1 10 -5 0 -6 4 -9 9 -5 6 3 13 0 18 -7 4 -7 17 -20
28 -28 11 -8 4 3 -14 23 -39 42 -56 46 -71 17 -16 -30 -28 -24 -28 15 0 19 4
33 8 30 5 -3 11 1 14 7 3 9 5 8 3 -2 -2 -15 4 -20 23 -20 6 0 1 10 -10 23 -12
12 -27 22 -33 22 -7 0 -13 10 -15 23 -2 12 -9 22 -17 22 -7 0 -16 6 -19 13 -3
6 -3 2 0 -10 7 -28 -7 -30 -24 -3 -7 12 -8 20 -2 20 5 0 8 10 6 22 -2 14 -10
23 -21 23 -10 0 -26 14 -35 30 -10 17 -23 29 -30 27 -10 -2 -10 1 0 15 8 10
11 15 6 11 -10 -8 -47 23 -40 34 3 4 -3 5 -13 1 -15 -6 -16 -4 -5 9 10 12 10
18 -2 27 -11 9 -16 9 -22 0 -4 -7 -3 -9 4 -5 21 13 21 -12 -1 -30 -18 -16 -19
-16 -8 -1 10 13 10 17 1 17 -7 0 -10 8 -7 19 8 34 -10 51 -49 45 -34 -5 -34
-5 -29 26 3 19 0 39 -7 48 -11 13 -11 14 2 7 8 -5 4 3 -9 17 -20 21 -28 24
-42 15 -10 -6 -16 -13 -14 -16 3 -2 11 1 19 8 11 9 15 8 20 -5 9 -24 7 -29 -6
-16 -7 7 -17 8 -27 3 -13 -8 -16 -2 -17 35 0 25 3 39 7 32 4 -6 5 -16 3 -20
-3 -4 0 -8 5 -8 23 0 -1 45 -32 60 -20 10 -21 10 -4 -3 11 -9 14 -18 8 -21 -5
-4 -17 4 -25 16 -21 29 -20 39 1 31 15 -6 16 -5 3 9 -11 11 -17 12 -22 4 -7
-10 -12 -1 -9 17 0 4 -3 7 -9 7 -6 0 -9 3 -8 8 2 4 -7 20 -18 35 -17 21 -23
24 -28 12 -6 -18 2 -45 15 -45 5 0 9 5 9 12 0 14 13 2 16 -15 2 -14 -60 -17
-73 -3 -5 5 -1 7 9 3 12 -4 15 -2 11 8 -3 8 -9 12 -15 9 -5 -3 -9 2 -8 12 4
36 0 46 -15 40 -16 -6 -21 9 -5 19 5 3 18 -3 30 -15 11 -11 20 -17 20 -12 -1
12 -24 37 -39 40 -17 3 -43 -17 -35 -29 3 -5 0 -9 -6 -9 -8 0 -9 7 -5 18 24
60 24 71 -3 92 -15 12 -20 18 -12 14 8 -4 4 4 -10 18 -15 15 -29 21 -35 16 -6
-6 -3 -14 9 -24 15 -10 16 -14 5 -14 -21 0 -38 20 -32 36 3 8 -2 16 -10 20
-10 3 -14 14 -11 29 3 17 -2 27 -16 35 -11 6 -17 16 -14 22 4 7 3 8 -5 4 -6
-4 -9 -11 -6 -16 7 -12 -23 -6 -37 8 -8 8 -8 15 3 28 12 15 12 16 0 10 -9 -6
-12 -5 -9 5 3 8 -2 20 -11 27 -13 11 -13 12 -1 6 8 -4 3 6 -11 21 -26 26 -50
27 -48 2 0 -9 2 -9 6 1 2 6 9 12 15 12 7 0 6 -5 -1 -14 -8 -10 -9 -16 -1 -21
5 -3 10 1 10 10 0 9 4 14 10 10 16 -10 0 -33 -23 -31 -25 1 -33 41 -14 65 10
12 9 13 -2 6 -11 -6 -13 -4 -8 8 8 21 -11 33 -26 18 -9 -9 -7 -11 8 -6 13 4
16 4 8 -2 -7 -5 -25 -8 -40 -7 -26 1 -27 3 -29 53 0 30 -7 56 -13 61 -7 4 -12
10 -11 14 1 4 -13 23 -29 42 -17 19 -31 31 -31 27 0 -4 -7 2 -15 13 -13 18
-14 18 -8 -5 5 -18 3 -22 -6 -16 -7 4 -11 18 -8 32 3 16 -1 27 -14 33 -23 13
-29 13 -29 -1 0 -5 4 -8 9 -4 5 3 12 -5 16 -17 18 -63 18 -60 -4 -46 -12 7
-21 16 -21 21 0 4 5 5 10 2 14 -9 12 4 -3 19 -7 7 -18 11 -24 9 -7 -3 -20 5
-29 16 -10 11 -22 17 -27 14 -5 -3 -1 6 8 21 16 24 44 39 30 16 -3 -5 1 -10 9
-10 9 0 16 -5 16 -11 0 -6 -9 -9 -20 -6 -25 6 -26 -2 -3 -18 15 -11 19 -10 28
8 20 40 -42 78 -64 40 -9 -15 -10 -16 -11 -2 0 9 9 20 21 25 20 9 20 10 3 24
-10 8 -15 10 -11 3 4 -6 3 -14 -2 -18 -6 -3 -8 5 -5 20 5 26 -6 36 -49 40 -36
4 -37 5 -37 16 0 5 4 8 9 5 5 -4 11 -3 14 1 2 5 2 2 1 -4 -4 -14 8 -17 19 -6
11 10 -13 32 -28 26 -10 -3 -13 1 -10 16 2 12 -1 21 -6 21 -6 0 -8 4 -5 9 7
11 -42 57 -51 48 -3 -4 -1 -7 5 -7 7 0 11 -6 9 -12 -1 -7 4 -12 11 -10 8 1 11
-2 6 -9 -5 -8 -3 -10 7 -4 12 7 12 6 1 -6 -11 -12 -16 -10 -33 11 l-21 25 6
-24 c3 -17 2 -21 -6 -13 -7 7 -7 20 1 43 12 36 0 61 -28 57 -10 -2 -16 4 -15
14 1 10 -4 14 -12 11 -15 -5 -8 -32 10 -34 6 -1 17 -2 25 -3 8 0 17 -8 19 -16
4 -12 2 -13 -9 -4 -8 7 -28 11 -46 9 -29 -2 -31 -1 -29 29 2 17 -1 37 -7 44
-7 9 -6 11 7 6 11 -4 15 -13 11 -23 -5 -13 -4 -14 7 -3 11 11 9 16 -7 28 -11
9 -19 20 -18 25 2 5 -2 9 -8 9 -5 0 -7 5 -3 12 4 7 3 8 -4 4 -6 -4 -8 -14 -5
-24 6 -15 5 -15 -10 -3 -14 11 -15 17 -5 28 9 11 4 21 -25 51 -72 74 -169 186
-175 203 -2 5 -10 9 -18 9 -9 0 -13 4 -10 9 4 5 -7 20 -23 34 -17 14 -27 28
-24 31 4 3 1 6 -5 6 -7 0 -11 5 -9 12 1 7 -5 12 -13 13 -8 0 -15 6 -15 14 0 8
-6 16 -12 19 -7 2 -13 -1 -13 -8 0 -6 5 -8 12 -4 7 4 8 3 4 -5 -4 -6 -11 -9
-16 -6 -4 3 -11 -1 -14 -7 -3 -7 -3 -1 -1 14 4 34 -11 47 -52 43 -29 -2 -31
-1 -29 29 2 17 -4 40 -13 51 -10 13 -10 15 -1 8 8 -7 19 -13 24 -13 6 0 3 5
-7 11 -9 6 -15 14 -12 20 4 5 1 9 -5 9 -6 0 -9 4 -6 8 3 5 -10 24 -29 42 -19
19 -36 31 -36 27 -1 -4 -2 -13 -3 -19 -2 -19 -68 -17 -74 2 -5 13 -3 13 11 1
21 -17 56 -6 39 11 -7 7 -12 19 -12 28 1 12 4 11 15 -4 8 -11 15 -15 15 -9 0
5 -15 26 -32 45 -44 47 -112 129 -116 139 -2 5 -10 9 -18 9 -9 0 -13 4 -10 9
8 12 -21 34 -34 26 -5 -3 -10 -12 -9 -18 0 -9 2 -9 6 1 2 6 9 12 14 12 6 0 7
-5 3 -12 -5 -7 -2 -8 7 -3 11 6 11 5 2 -5 -21 -23 -42 -4 -39 35 1 19 -1 35
-6 35 -4 0 -7 3 -5 8 1 4 -3 7 -8 7 -5 0 -9 -3 -8 -6 1 -4 -9 -6 -22 -6 -14 0
-26 5 -27 11 0 6 -2 16 -3 21 -2 6 -3 20 -4 32 -1 18 -7 21 -38 20 -27 -2 -37
2 -39 16 -1 9 2 20 8 23 5 3 2 15 -9 27 -16 19 -16 20 0 8 11 -9 17 -10 17 -2
0 6 -4 11 -10 11 -20 0 -25 2 -38 12 -10 8 -8 13 5 24 17 12 17 12 -1 11 -10
-1 -21 4 -24 12 -3 9 0 11 9 6 12 -7 12 -5 1 6 -11 12 -14 12 -22 -1 -6 -9 -5
-17 3 -22 9 -6 9 -8 0 -8 -7 0 -13 -5 -13 -11 0 -5 4 -8 9 -5 11 7 36 -22 28
-31 -4 -3 -9 2 -13 11 -6 15 -7 15 -19 0 -10 -15 -12 -13 -10 14 2 43 -8 55
-46 56 -25 1 -35 6 -37 21 l-4 20 16 -20 c8 -11 16 -15 16 -8 0 7 7 10 17 6
11 -4 14 -3 9 6 -5 7 -14 10 -21 7 -20 -7 -39 46 -24 69 4 6 -3 11 -18 11 -29
2 -29 2 -37 34 l-7 25 20 -25 c11 -14 22 -23 25 -20 2 3 -11 22 -29 42 -18 20
-36 33 -40 30 -3 -4 -3 0 0 9 6 15 -28 59 -46 59 -6 0 -7 5 -3 12 4 6 3 9 -2
6 -5 -3 -15 0 -22 8 -7 8 -9 14 -5 14 4 0 0 5 -10 11 -9 6 -14 15 -11 21 4 7
2 8 -4 4 -7 -4 -12 -2 -12 3 0 6 -5 11 -12 11 -9 0 -9 -3 0 -12 18 -18 15 -25
-10 -23 -19 1 -23 8 -25 36 -1 27 1 30 8 16 6 -10 15 -15 21 -11 20 12 -43 42
-80 38 -33 -4 -34 -3 -29 23 3 15 1 37 -4 48 -7 13 -2 10 14 -8 13 -15 26 -25
29 -21 4 3 -4 18 -18 32 -21 23 -27 25 -44 14 -14 -9 -20 -9 -20 -1 0 8 -6 7
-17 -2 -10 -8 -14 -9 -10 -2 4 6 3 14 -1 17 -5 3 -9 24 -10 46 -1 26 2 39 8
35 6 -4 8 -15 5 -25 -3 -11 0 -20 10 -24 8 -3 14 -9 13 -13 -2 -4 2 -9 7 -10
6 -1 9 5 7 14 -2 10 1 13 10 8 7 -5 -9 15 -37 44 -54 56 -57 58 -49 36 4 -8
10 -15 14 -15 5 0 6 -9 3 -21 -4 -16 -12 -19 -37 -17 -21 2 -31 9 -32 21 -1
12 -1 13 3 2 2 -8 9 -15 14 -15 12 0 1 43 -12 48 -4 2 -5 10 -3 17 5 11 -4 13
-37 12 -30 -1 -43 2 -47 13 -4 12 -2 13 9 3 7 -7 20 -13 29 -13 10 0 7 6 -10
18 -21 14 -25 25 -24 62 0 55 -4 70 -22 70 -8 0 -14 5 -14 11 0 5 5 7 12 3 7
-4 8 -3 4 4 -10 17 -39 15 -33 -2 4 -9 0 -13 -11 -12 -18 3 -24 34 -9 53 5 8
1 15 -13 23 -11 6 -18 15 -16 19 3 4 -1 8 -8 8 -8 0 -12 -6 -9 -12 2 -8 -5
-12 -19 -11 -19 1 -24 8 -26 36 -4 34 -4 34 17 16 12 -11 21 -14 21 -9 0 6 -7
13 -15 17 -8 3 -15 12 -15 20 0 8 -5 18 -12 22 -6 4 -8 3 -5 -3 4 -6 0 -14 -9
-17 -21 -8 -24 -8 -24 5 0 5 4 8 9 5 15 -10 7 19 -14 46 -11 14 -26 34 -33 44
-7 11 -10 14 -7 8 2 -7 -3 -15 -13 -19 -28 -12 -52 -13 -53 -1 -1 6 -3 21 -4
32 -4 34 -14 44 -45 40 -16 -2 -27 0 -25 4 3 5 -1 11 -7 13 -10 4 -9 8 2 16
13 10 13 12 0 20 -12 8 -12 12 3 29 9 10 14 21 11 25 -9 9 -35 -4 -28 -14 6
-10 2 -11 -38 -14 -28 -2 -37 8 -18 20 6 4 7 11 4 17 -4 7 -9 6 -14 -1 -4 -7
-6 3 -6 22 1 19 7 41 14 50 10 12 10 17 1 23 -8 4 -9 3 -5 -4 4 -7 3 -12 -3
-12 -5 0 -11 10 -13 23 -2 16 -9 22 -25 21 -19 -1 -21 2 -12 18 9 17 8 18 -10
6 -11 -6 -17 -16 -15 -20 3 -4 0 -8 -5 -8 -12 0 -9 35 4 47 4 5 5 2 0 -6 -5
-10 -4 -12 3 -7 9 5 9 13 2 27 -10 17 -24 23 -57 22 -7 -1 -11 3 -8 7 3 4 -2
11 -11 14 -12 5 -15 2 -10 -9 3 -9 2 -13 -4 -10 -5 3 -8 24 -6 45 2 22 0 40
-5 40 -5 0 -9 7 -9 15 0 8 -5 15 -10 15 -7 0 -7 -7 1 -21 9 -17 8 -20 -3 -17
-7 3 -13 11 -13 18 0 7 -6 15 -14 18 -9 3 -11 -1 -6 -17 4 -15 2 -21 -9 -21
-11 0 -15 8 -12 31 2 16 -2 36 -10 43 -7 8 -10 16 -7 20 4 3 1 6 -5 6 -7 0
-12 -4 -12 -10 0 -5 -5 -10 -11 -10 -5 0 -7 6 -3 13 5 7 0 6 -11 -3 -11 -9
-16 -10 -11 -2 4 6 3 12 -2 12 -6 0 -9 24 -9 52 1 35 -2 53 -10 53 -6 0 -15 3
-19 8 -4 4 -2 7 4 7 6 0 0 12 -15 26 -14 14 -28 22 -31 19 -4 -3 -2 -9 4 -13
14 -9 11 -49 -5 -65 -11 -10 -12 -7 -6 11 5 17 1 29 -14 43 -11 11 -18 26 -15
34 8 21 25 19 18 -2 -5 -15 -4 -15 4 -4 12 17 -34 85 -51 74 -6 -4 -8 0 -4 11
4 9 3 15 -2 12 -4 -3 -13 6 -20 20 -6 14 -15 23 -20 20 -5 -3 -12 5 -15 19 -6
25 -20 33 -30 16 -3 -5 1 -11 9 -15 21 -8 19 -25 -2 -22 -12 2 -18 13 -18 34
0 18 3 28 10 24 5 -3 4 2 -4 12 -8 9 -21 14 -29 11 -9 -3 -12 0 -9 9 4 9 -2
17 -14 21 -10 4 -19 11 -19 16 0 6 5 7 12 3 7 -5 8 -3 3 5 -5 10 -10 10 -19 1
-8 -8 -6 -15 8 -26 17 -12 18 -16 6 -27 -10 -9 -12 -9 -6 1 4 6 3 12 -3 12 -5
0 -11 -6 -14 -12 -2 -7 -3 8 -2 35 1 26 -1 47 -5 47 -4 0 -12 10 -18 23 -6 12
-11 16 -11 9 -1 -7 -6 -10 -11 -7 -6 4 -7 11 -4 17 4 7 2 8 -5 4 -9 -6 -9 -11
-1 -21 6 -7 7 -15 3 -17 -4 -3 -3 -3 4 -2 7 2 15 -1 18 -6 7 -12 -25 -13 -40
-2 -13 9 -13 58 -1 69 5 5 6 2 1 -6 -5 -10 -4 -12 4 -7 15 10 3 29 -14 22 -7
-2 -16 2 -19 10 -3 9 -1 12 4 9 15 -9 12 5 -5 21 -8 9 -15 13 -15 9 0 -3 -8
-1 -18 4 -14 8 -18 5 -19 -11 -2 -16 1 -17 12 -8 12 10 16 9 21 -6 4 -11 8
-21 10 -24 1 -3 -7 -7 -19 -7 -17 -2 -19 0 -7 7 15 9 13 11 -12 14 -7 1 -13
15 -13 31 -1 28 -3 30 -40 29 -37 -1 -40 1 -41 28 -1 15 3 28 7 28 9 0 13 -15
10 -37 -2 -14 24 -8 48 11 11 8 13 8 8 0 -4 -7 0 -17 8 -24 8 -7 15 -9 15 -4
0 5 5 2 10 -6 7 -11 10 -11 10 -2 0 7 -9 16 -21 20 -11 5 -20 12 -20 17 1 6
-3 10 -8 10 -12 0 -26 14 -26 26 0 5 -4 9 -8 9 -4 -1 -16 7 -25 17 -9 10 -14
14 -9 9 14 -19 7 -25 -27 -24 -26 1 -37 7 -45 26 -7 13 -10 27 -7 29 2 2 7 -5
11 -16 5 -17 25 -31 25 -17 0 12 -28 55 -33 50 -3 -3 -4 1 -3 10 1 8 5 19 9
22 13 13 8 22 -48 81 -30 32 -55 61 -55 66 0 4 -5 7 -11 7 -5 0 -7 -6 -3 -12
6 -10 4 -10 -7 0 -7 6 -20 12 -28 12 -14 0 -14 -1 -1 -10 13 -8 13 -10 -1 -10
-17 0 -25 55 -9 65 4 3 6 -3 3 -14 -5 -21 32 -37 44 -19 3 7 1 8 -5 4 -7 -4
-12 -2 -12 4 0 6 -5 8 -11 5 -6 -4 -9 0 -7 10 3 16 -11 24 -49 27 -6 1 -9 6
-7 13 3 6 -3 19 -14 28 -16 15 -18 15 -18 -2 0 -10 5 -18 11 -17 5 2 7 1 2 -1
-4 -3 -5 -9 -2 -14 4 -5 -1 -6 -9 -3 -9 3 -16 21 -18 42 -2 29 -7 36 -20 34
-11 -2 -18 3 -18 12 0 9 -6 13 -13 10 -16 -6 -33 12 -40 41 -4 16 -5 17 -6 3z
m282 -285 c-4 -11 -2 -14 5 -9 7 4 12 3 12 -3 0 -5 -8 -9 -18 -8 -19 1 -27 27
-9 27 5 0 3 8 -4 18 -13 15 -12 16 3 4 10 -8 14 -20 11 -29z m172 -191 c-3 -3
0 -11 7 -19 6 -7 9 -16 6 -19 -3 -2 -12 6 -21 19 -13 20 -13 24 -1 24 8 0 12
-3 9 -5z m-278 -364 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z
m676 -92 c3 3 11 -4 17 -16 14 -26 3 -43 -11 -17 -6 9 -15 17 -22 17 -8 0 -9
-3 -1 -8 21 -14 23 -38 3 -38 -11 0 -19 4 -19 8 0 4 6 8 13 8 8 0 8 3 1 8 -5
4 -10 17 -10 29 0 15 4 19 12 13 6 -6 13 -7 17 -4z m11 -85 c3 -5 -6 -8 -19
-8 -14 0 -25 3 -25 8 0 5 9 9 19 9 11 0 22 -4 25 -9z m105 -57 c5 3 8 -2 7
-11 -2 -13 -14 -19 -41 -21 -34 -3 -37 -2 -26 12 10 11 18 13 31 6 29 -15 34
-12 13 8 -10 9 -13 13 -6 9 6 -5 16 -6 22 -3z m-633 -338 c1 -8 -7 -16 -17
-19 -13 -3 -17 -1 -12 6 4 7 8 15 9 20 1 4 2 10 3 14 2 10 15 -6 17 -21z
m1244 -381 c0 -18 -19 -40 -27 -31 -4 4 -8 20 -9 36 0 24 2 26 18 17 10 -7 18
-16 18 -22z m-1080 -322 c0 -20 -3 -24 -11 -16 -7 7 -9 19 -6 27 10 24 17 19
17 -11z m1485 -123 c3 -5 2 -10 -4 -10 -5 0 -13 5 -16 10 -3 6 -2 10 4 10 5 0
13 -4 16 -10z m255 -305 c0 -1 -7 -2 -15 -2 -8 0 -15 4 -15 8 0 5 -6 6 -14 3
-10 -4 -14 1 -13 14 1 19 2 19 29 -1 15 -11 28 -21 28 -22z m42 -70 c0 -5 -5
-11 -10 -13 -5 -1 -9 4 -9 13 0 9 4 14 9 13 5 -2 10 -8 10 -13z m3 -44 c3 -11
15 -23 26 -26 26 -9 24 -20 -5 -20 -16 0 -22 4 -18 14 4 10 1 13 -9 9 -10 -4
-14 2 -14 18 0 29 11 31 20 5z m-1628 -278 c1 -37 1 -37 -8 -9 -7 25 -5 46 5
46 2 0 3 -17 3 -37z m38 -113 c3 -5 -1 -10 -10 -10 -9 0 -13 5 -10 10 3 6 8
10 10 10 2 0 7 -4 10 -10z m2068 -122 c-2 -5 1 -8 6 -8 6 0 11 -6 11 -14 0 -8
8 -17 18 -20 11 -4 25 -17 32 -31 7 -13 16 -23 19 -21 3 2 6 -11 7 -29 0 -29
-1 -32 -18 -21 -10 6 -16 16 -14 23 3 7 1 15 -4 18 -6 4 -10 15 -10 26 0 10
-5 19 -11 19 -6 0 -19 10 -30 23 -10 12 -18 17 -19 10 0 -6 5 -15 10 -18 14
-8 13 -22 -2 -28 -9 -3 -13 11 -14 49 -1 42 1 50 11 41 7 -7 11 -15 8 -19z
m32 -78 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z
m122 -101 c-3 -11 0 -19 6 -19 8 0 8 -3 0 -11 -6 -6 -9 -18 -6 -26 4 -11 8
-10 18 4 8 10 16 15 20 11 11 -11 -8 -33 -30 -34 -18 -1 -20 5 -21 52 -1 31 3
51 8 48 6 -4 8 -15 5 -25z m41 -23 c-2 -1 -12 -8 -23 -16 -14 -10 -17 -10 -8
-1 6 7 9 21 5 30 -5 14 -4 14 11 2 10 -8 16 -14 15 -15z m-2138 -87 c0 -11 -4
-18 -9 -14 -5 3 -12 -3 -14 -12 -4 -14 -5 -13 -6 5 0 12 3 22 9 22 5 0 6 7 3
17 -4 11 -3 14 5 9 7 -4 12 -16 12 -27z m2305 -111 c5 4 5 2 2 -4 -4 -6 -18
-11 -32 -11 -21 0 -23 2 -14 13 8 10 15 11 24 4 6 -5 16 -6 20 -2z m47 -68
c18 -17 19 -20 5 -26 -22 -8 -36 2 -34 26 2 25 3 25 29 0z m71 -92 c-2 -5 1
-8 6 -8 6 0 11 -6 11 -14 0 -8 8 -17 18 -20 11 -4 25 -17 32 -31 7 -13 16 -23
19 -21 3 2 6 -11 7 -29 0 -29 -2 -32 -18 -22 -21 14 -24 27 -5 27 6 0 4 5 -5
11 -10 5 -18 19 -18 29 0 11 -5 20 -11 20 -6 0 -19 10 -30 23 -10 12 -18 17
-19 10 0 -6 5 -15 10 -18 14 -8 13 -22 -2 -28 -9 -3 -14 11 -15 49 -1 44 1 51
12 41 7 -6 11 -15 8 -19z m-2357 -42 c1 -12 4 -42 8 -68 3 -27 2 -48 -2 -48
-11 0 -46 69 -39 76 3 3 12 -7 21 -23 20 -36 20 -17 1 37 -18 51 -18 47 -2 47
6 0 13 -10 13 -21z m2389 -36 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10
4 10 3 0 8 -4 11 -10z m95 -73 c0 -4 4 -6 8 -3 5 3 17 -5 27 -16 16 -20 16
-22 1 -33 -16 -13 -23 -31 -7 -21 5 3 14 -1 21 -9 7 -8 17 -12 23 -8 5 3 7 1
3 -5 -3 -6 -19 -10 -35 -10 -27 1 -28 3 -26 49 1 34 -1 46 -9 41 -18 -14 -69
-8 -74 8 -5 13 -3 13 11 1 21 -17 56 -6 39 11 -7 7 -12 19 -12 28 1 12 4 11
15 -4 8 -11 15 -24 15 -29z m-2471 -73 c0 -31 -2 -54 -4 -52 -9 9 -14 108 -5
108 4 0 8 -25 9 -56z m2928 -499 c34 15 41 13 69 -16 15 -16 25 -29 21 -29 -4
0 -1 -11 8 -23 15 -24 15 -24 -25 -23 -22 0 -40 2 -40 3 0 1 10 7 21 14 12 6
19 7 16 2 -4 -5 0 -10 8 -10 11 0 12 4 4 14 -6 7 -15 10 -20 8 -4 -3 -6 3 -4
13 2 10 -1 25 -6 33 -6 10 -9 4 -10 -21 l-2 -35 -6 30 c-5 26 -10 30 -40 30
-22 0 -36 6 -39 15 -5 13 -3 13 11 1 10 -8 24 -10 34 -6z m367 -420 c-4 -8 -8
-15 -10 -15 -2 0 -4 7 -4 15 0 8 4 15 10 15 5 0 7 -7 4 -15z m8 -37 c2 -6 21
-12 42 -13 50 -3 58 -13 39 -55 -8 -20 -17 -29 -20 -22 -3 7 3 25 13 40 14 22
14 24 2 14 -12 -9 -18 -10 -23 -2 -4 6 -13 9 -20 6 -7 -2 -23 6 -36 20 -12 13
-18 24 -12 24 6 0 13 -5 15 -12z m193 -148 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11
10 0 6 2 10 4 10 3 0 8 -4 11 -10z m-3185 -91 c-7 -12 -8 -19 -2 -15 5 3 10
-6 10 -19 0 -14 -4 -28 -9 -31 -5 -3 -9 4 -9 15 0 10 -4 21 -10 23 -5 2 -14
18 -20 36 -7 27 -6 33 4 29 7 -3 19 -1 27 4 19 11 24 -13 9 -42z m3244 -31
c-11 -17 -27 -2 -31 28 l-3 29 20 -24 c11 -12 17 -27 14 -33z m-3177 -182 c-5
-16 -27 -19 -27 -3 0 7 7 14 15 14 9 0 14 -5 12 -11z m3443 -114 c-14 -5 -41
18 -35 29 3 4 15 1 26 -9 11 -9 15 -18 9 -20z m-3422 -2 c3 -7 0 -10 -7 -6
-14 9 -14 -7 0 -21 6 -6 8 -16 4 -23 -9 -15 -38 39 -30 58 6 16 27 12 33 -8z
m3397 -28 c-9 -9 -35 8 -35 24 0 16 2 16 20 -1 11 -10 18 -20 15 -23z m73 -18
c-3 -16 -4 -17 -20 -1 -9 9 -23 17 -30 18 -7 1 -2 4 12 8 29 9 43 0 38 -25z
m-2 -38 c10 -21 9 -24 -5 -23 -9 0 -17 11 -17 24 -2 30 8 29 22 -1z m49 8 c8
3 14 0 12 -7 -2 -14 -18 -27 -32 -27 -5 0 -4 4 3 9 10 6 9 10 -7 14 -12 3 -21
11 -21 17 0 10 3 10 15 0 8 -7 21 -10 30 -6z m51 -77 c13 16 14 16 8 1 -3 -10
-1 -18 5 -18 6 0 11 -4 11 -10 0 -5 -14 -8 -31 -6 -27 2 -33 8 -35 31 -3 28
-3 28 12 6 15 -21 17 -21 30 -4z m-3490 -183 c1 -16 -1 -33 -3 -37 -8 -13 -34
45 -28 61 9 23 30 7 31 -24z m3643 9 c31 -4 30 -20 -2 -20 -25 0 -33 5 -33 18
0 10 5 15 11 11 5 -4 16 -7 24 -9z m149 -173 c-2 -7 -10 1 -17 16 -12 27 -11
28 4 12 10 -9 16 -21 13 -28z m273 -323 c-13 -13 -26 -3 -16 12 3 6 11 8 17 5
6 -4 6 -10 -1 -17z m2096 -3 c-9 -9 -28 6 -21 18 4 6 10 6 17 -1 6 -6 8 -13 4
-17z m40 0 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-1943 -189
c3 -8 1 -15 -4 -15 -6 0 -10 7 -10 15 0 8 2 15 4 15 2 0 6 -7 10 -15z m1626
-26 c0 -14 -17 -10 -23 5 -4 10 -1 13 9 9 7 -3 14 -9 14 -14z m-1500 -131 c0
-10 -20 -11 -21 0 0 4 -2 16 -4 27 -4 20 -4 20 11 1 8 -11 14 -23 14 -28z m90
-111 c0 -4 6 -5 13 -1 9 6 9 5 0 -8 -16 -19 -75 -18 -81 2 -5 13 -3 13 11 1
21 -17 56 -6 39 11 -7 7 -12 19 -12 28 1 12 4 11 15 -4 8 -11 15 -24 15 -29z
m1020 -37 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4
-10z m15 -29 c-8 -8 -18 -11 -22 -7 -5 5 -2 11 5 13 6 3 12 12 12 21 0 15 2
15 10 2 6 -10 5 -20 -5 -29z m-51 -29 c-13 -13 -17 -13 -24 -2 -12 19 -4 29
20 23 17 -5 18 -7 4 -21z m66 6 c0 -7 -12 -14 -27 -15 -26 -1 -27 0 -8 13 23
17 35 18 35 2z m-917 -61 c-7 -11 -23 12 -23 32 0 11 4 9 14 -4 8 -11 12 -24
9 -28z m-4286 -164 c-3 -10 -5 -2 -5 17 0 19 2 27 5 18 2 -10 2 -26 0 -35z
m4418 -3 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z
m199 -15 c3 -8 1 -15 -4 -15 -6 0 -10 7 -10 15 0 8 2 15 4 15 2 0 6 -7 10 -15z
m220 0 c11 -8 15 -15 9 -15 -7 0 -15 5 -18 10 -3 6 -11 10 -16 10 -6 0 -7 -4
-4 -10 3 -5 -1 -10 -11 -10 -13 0 -14 3 -4 15 15 18 20 18 44 0z m-4837 -40
c-1 -19 -5 -35 -9 -35 -9 0 -10 17 -2 48 9 33 14 27 11 -13z m297 -1030 c-6
-16 -24 -21 -24 -7 0 11 11 22 21 22 5 0 6 -7 3 -15z m99 -375 c3 -11 1 -20
-4 -20 -5 0 -9 9 -9 20 0 11 2 20 4 20 2 0 6 -9 9 -20z m5504 -145 c-8 -9 -14
-10 -19 -2 -9 16 0 27 17 21 11 -5 12 -9 2 -19z m-79 -42 c-5 -7 -23 -11 -39
-9 -16 2 -29 0 -29 -5 0 -14 -56 -70 -63 -63 -13 14 -7 48 12 68 15 16 32 21
75 21 42 0 52 -2 44 -12z m-164 -98 c-4 -8 -10 -12 -15 -9 -11 6 -2 24 11 24
5 0 7 -7 4 -15z m-154 -73 c0 -4 -13 -13 -30 -19 -16 -6 -30 -8 -30 -4 0 4 12
12 28 18 15 6 28 11 30 12 1 1 2 -3 2 -7z m-192 -67 c4 6 18 11 32 12 l25 3
-25 -12 c-28 -14 -56 -38 -43 -38 4 0 17 7 27 15 11 8 27 15 35 14 11 0 10 -3
-4 -9 -11 -4 -30 -17 -42 -29 -12 -12 -24 -21 -25 -21 -2 0 -4 18 -4 40 0 25
3 36 8 28 5 -8 11 -9 16 -3z m-63 -75 c-28 -12 -47 -12 -40 0 3 6 18 10 33 9
24 0 25 -1 7 -9z m-330 -160 c3 -5 0 -10 -9 -10 -8 0 -16 -6 -19 -12 -3 -7 -4
-3 -4 10 1 22 21 30 32 12z m-245 -160 c-8 -5 -19 -10 -25 -10 -5 0 -3 5 5 10
8 5 20 10 25 10 6 0 3 -5 -5 -10z m-4032 -76 c0 -17 -3 -22 -8 -13 -5 6 -6 18
-3 26 8 19 11 16 11 -13z m3582 -238 c0 -9 -30 -14 -35 -6 -4 6 3 10 14 10 12
0 21 -2 21 -4z m-105 -45 c17 -10 17 -12 2 -7 -10 3 -23 6 -28 6 -5 0 -9 8 -9
18 0 10 3 13 8 6 4 -6 16 -16 27 -23z m-135 -95 c0 -2 -7 -7 -16 -10 -8 -3
-12 -2 -9 4 6 10 25 14 25 6z m-110 -26 c0 -5 -7 -10 -16 -10 -8 0 -12 5 -9
10 3 6 10 10 16 10 5 0 9 -4 9 -10z m21 -10 c-13 -11 -31 -20 -40 -19 -9 0 -4
5 12 11 15 5 25 14 21 19 -3 5 3 9 13 9 16 -1 15 -3 -6 -20z m-577 -312 c-8
-12 10 -10 24 4 9 9 12 9 12 0 0 -25 -2 -27 -17 -24 -10 2 -12 -1 -7 -9 4 -7
3 -10 -4 -5 -6 3 -13 2 -17 -4 -10 -17 -25 -1 -21 23 5 26 3 24 21 24 8 0 12
-4 9 -9z m-136 -98 c7 0 12 -4 12 -8 0 -12 -44 -10 -52 2 -4 6 -8 17 -8 25 0
11 4 11 18 -2 10 -10 24 -17 30 -17z m-2318 -66 c0 -8 -4 -12 -10 -9 -5 3 -10
10 -10 16 0 5 5 9 10 9 6 0 10 -7 10 -16z m17 -6 c-3 -8 -6 -5 -6 6 -1 11 2
17 5 13 3 -3 4 -12 1 -19z m-40 -30 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4
-12 1 -19z m39 -10 c0 -21 -20 -18 -24 4 -2 8 3 15 10 15 8 0 14 -9 14 -19z
m4 -32 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10
-4z m-3 -61 c0 -38 -5 -40 -12 -6 -2 11 -7 26 -10 31 -3 6 0 10 8 10 9 0 14
-12 14 -35z m1889 -50 c-14 -8 -30 -16 -34 -19 -5 -3 -9 2 -9 10 0 8 5 13 11
11 6 -2 17 2 24 10 8 7 18 10 24 7 5 -3 -2 -12 -16 -19z m-1826 -59 c-5 -13
-7 -26 -4 -28 2 -3 -2 -17 -10 -32 l-14 -28 -7 33 c-5 22 -4 34 4 37 6 2 11
12 11 23 0 10 7 19 15 19 11 0 12 -5 5 -24z m1601 -64 c-6 -2 -9 -8 -5 -13 7
-12 1 -11 -31 5 l-27 14 38 -1 c20 -1 32 -3 25 -5z m-38 -37 c-12 -32 -23 -14
-14 23 1 2 5 1 11 -3 5 -3 7 -12 3 -20z m-153 -45 c0 -5 -7 -10 -16 -10 -8 0
-12 5 -9 10 3 6 10 10 16 10 5 0 9 -4 9 -10z m58 -7 c-13 -16 -22 -17 -12 -2
4 7 1 10 -7 7 -8 -3 -13 -11 -11 -17 1 -6 -2 -11 -8 -11 -14 0 -12 27 3 33 6
2 20 4 29 4 13 -1 14 -4 6 -14z m-218 -93 c-19 -13 -30 -13 -30 0 0 6 10 10
23 10 18 0 19 -2 7 -10z m-1153 -105 c0 -44 -2 -53 -12 -45 -7 6 -16 22 -19
36 -4 15 -11 22 -18 18 -6 -4 -9 -1 -6 7 3 8 12 13 22 11 9 -2 16 4 16 13 0 8
4 15 8 15 4 0 8 -25 9 -55z m906 -42 c-3 -7 -9 -10 -14 -7 -14 8 -11 21 5 21
8 0 12 -6 9 -14z m-94 -74 c-7 -4 -17 -5 -21 -3 -4 3 -8 1 -8 -5 0 -5 6 -12
13 -14 7 -3 5 -6 -5 -6 -10 -1 -18 -7 -18 -13 0 -9 -3 -10 -8 -2 -4 6 -16 16
-27 22 -20 11 -20 11 2 12 12 0 24 4 27 9 3 5 17 9 32 9 17 0 22 -3 13 -9z
m-772 -75 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m563 -4 c0
-5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m16
-26 l25 -6 -23 -17 c-13 -8 -25 -14 -27 -12 -2 1 -8 -3 -12 -11 -6 -9 -9 -7
-9 9 0 12 -3 29 -7 39 -5 14 -3 16 11 11 10 -4 29 -10 42 -13z m-546 -49 c10
-12 10 -21 2 -40 -7 -14 -8 -25 -4 -25 4 0 8 -19 9 -42 1 -41 1 -42 -12 -18
-7 14 -12 36 -11 50 1 14 -1 35 -6 48 -5 15 -4 22 5 22 8 0 7 4 -3 10 -8 5
-10 10 -4 10 6 0 17 -7 24 -15z m285 -85 c-3 -5 -13 -10 -21 -10 -8 0 -12 5
-9 10 3 6 13 10 21 10 8 0 12 -4 9 -10z m31 -5 c-11 -8 -23 -15 -28 -15 -4 0
-6 -6 -4 -12 1 -7 1 -10 -1 -5 -3 4 -12 7 -21 7 -9 0 -1 8 18 19 42 23 62 26
36 6z m-281 -155 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8
-4 11 -10z" />
            <path d="M14443 22535 c-9 -24 1 -27 15 -5 8 13 8 20 2 20 -6 0 -13 -7 -17
-15z" />
            <path d="M14621 21864 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
            <path d="M20670 18919 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0
-10 -5 -10 -11z" />
            <path d="M20650 18895 c0 -8 4 -15 10 -15 5 0 7 7 4 15 -4 8 -8 15 -10 15 -2
0 -4 -7 -4 -15z" />
            <path d="M18254 17490 c0 -13 4 -16 10 -10 7 7 7 13 0 20 -6 6 -10 3 -10 -10z" />
            <path d="M15875 17059 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z" />
            <path d="M15982 16745 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z" />
            <path d="M16585 16510 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3
0 -8 -4 -11 -10z" />
            <path d="M16061 16454 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
            <path d="M12334 27657 c3 -10 6 -26 6 -35 1 -15 2 -15 11 2 9 14 7 22 -6 35
-17 15 -17 15 -11 -2z" />
            <path d="M14440 27652 c0 -12 19 -26 26 -19 2 2 -2 10 -11 17 -9 8 -15 8 -15
2z" />
            <path d="M9623 27635 c-3 -9 -3 -18 -1 -21 3 -3 8 4 11 16 6 23 -1 27 -10 5z" />
            <path d="M13290 27639 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0
-10 -5 -10 -11z" />
            <path d="M12360 27604 c-13 -33 -13 -33 8 -31 14 1 17 7 14 29 -5 35 -10 35
-22 2z" />
            <path d="M13405 27570 c10 -11 20 -20 23 -20 3 0 -3 9 -13 20 -10 11 -20 20
-23 20 -3 0 3 -9 13 -20z" />
            <path d="M14506 27581 c-7 -11 32 -47 41 -38 3 4 1 7 -7 7 -10 0 -10 3 0 15 9
10 9 15 1 15 -6 0 -11 -5 -11 -11 0 -5 -5 -7 -10 -4 -6 4 -8 11 -5 16 4 5 4 9
1 9 -3 0 -7 -4 -10 -9z" />
            <path d="M12371 27544 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
            <path d="M12411 27454 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
            <path d="M14696 27355 c10 -8 24 -14 29 -14 6 0 1 6 -9 14 -11 8 -24 15 -30
15 -5 0 -1 -7 10 -15z" />
            <path d="M13324 27337 c-12 -15 -25 -69 -14 -62 4 2 12 -7 19 -22 6 -14 15
-23 18 -19 4 3 1 12 -5 18 -16 16 -15 31 1 22 8 -6 8 -3 -2 9 -8 10 -11 27 -7
42 7 28 6 30 -10 12z" />
            <path d="M14740 27346 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21
-21 13z" />
            <path d="M13624 27276 c16 -28 36 -49 36 -37 0 5 -5 13 -11 17 -6 3 -9 14 -6
23 2 11 -2 17 -13 17 -15 0 -16 -4 -6 -20z" />
            <path d="M12447 27283 c-3 -5 1 -19 9 -33 l14 -25 -6 30 c-8 35 -10 39 -17 28z" />
            <path d="M13650 27276 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21
-21 13z" />
            <path d="M13310 27245 c0 -5 5 -17 10 -25 5 -8 10 -10 10 -5 0 6 -5 17 -10 25
-5 8 -10 11 -10 5z" />
            <path d="M11128 27212 c19 -21 27 -20 19 3 -4 8 -13 15 -22 15 -13 0 -12 -3 3
-18z" />
            <path d="M9724 27199 c-3 -6 0 -15 7 -20 21 -13 33 -11 20 4 -6 8 -8 17 -4 20
3 4 1 7 -5 7 -6 0 -14 -5 -18 -11z" />
            <path d="M13320 27193 c1 -18 18 -35 24 -24 4 6 4 16 1 21 -7 12 -25 13 -25 3z" />
            <path d="M11170 27179 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0
-10 -5 -10 -11z" />
            <path d="M11276 27101 c-19 -7 -18 -8 4 -14 25 -6 40 -2 40 14 0 11 -17 11
-44 0z" />
            <path d="M9775 27071 c-3 -6 1 -25 10 -43 13 -29 14 -30 10 -6 -3 14 -2 28 3
31 4 2 1 2 -7 0 -8 -2 -11 2 -7 12 6 17 1 20 -9 6z" />
            <path d="M12490 27021 c0 -5 7 -11 14 -14 10 -4 13 -1 9 9 -6 15 -23 19 -23 5z" />
            <path d="M12490 26993 c0 -6 6 -18 13 -25 6 -7 14 -24 15 -38 5 -36 12 -38 12
-4 0 27 -39 93 -40 67z" />
            <path d="M13408 26974 c3 -15 8 -22 14 -16 9 9 1 42 -12 42 -4 0 -5 -12 -2
-26z" />
            <path d="M13396 26920 c11 -42 16 -42 29 0 7 22 6 23 -8 12 -12 -10 -16 -10
-21 0 -3 7 -3 2 0 -12z" />
            <path d="M12538 26845 c2 -14 8 -25 12 -25 4 0 5 11 2 25 -2 14 -8 25 -12 25
-4 0 -5 -11 -2 -25z" />
            <path d="M15153 26841 c0 -11 5 -17 9 -15 11 7 10 34 -1 34 -6 0 -9 -9 -8 -19z" />
            <path d="M12573 26688 c1 -15 6 -23 11 -18 4 4 4 16 -2 26 -9 16 -10 14 -9 -8z" />
            <path d="M11878 26619 c-4 -10 1 -15 13 -16 10 -1 19 3 19 8 0 6 -4 8 -9 5 -5
-4 -11 -1 -13 6 -3 8 -6 7 -10 -3z" />
            <path d="M13490 26584 c0 -27 -1 -27 -16 -8 -15 19 -16 19 -8 -1 4 -11 7 -30
7 -43 -1 -32 9 -19 23 29 7 22 8 41 3 45 -5 3 -9 -7 -9 -22z" />
            <path d="M14267 26540 c-22 -26 -22 -26 8 -26 14 0 25 4 25 8 0 4 -5 8 -11 8
-6 0 -9 7 -5 15 7 20 4 19 -17 -5z" />
            <path d="M14322 26492 c7 -19 16 -28 21 -23 6 6 1 18 -12 32 l-20 22 11 -31z" />
            <path d="M13480 26491 c0 -7 7 -19 15 -27 19 -20 19 -5 0 20 -9 11 -15 14 -15
7z" />
            <path d="M14355 26450 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z" />
            <path d="M10046 26431 c-3 -5 1 -11 9 -15 9 -3 15 0 15 9 0 16 -16 20 -24 6z" />
            <path d="M12605 26430 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z" />
            <path d="M13490 26431 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16
-5 3 -10 2 -10 -4z" />
            <path d="M14414 26374 c3 -16 36 -42 36 -27 -1 10 -25 43 -33 43 -3 0 -4 -7
-3 -16z" />
            <path d="M15579 26385 c-1 -3 -2 -8 -3 -12 -1 -5 -5 -13 -9 -19 -4 -7 -1 -14
7 -17 9 -4 13 4 14 24 1 25 -5 40 -9 24z" />
            <path d="M10090 26340 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2
0 -4 -4 -4 -10z" />
            <path d="M12657 26309 c4 -13 8 -18 11 -10 2 7 -1 18 -6 23 -8 8 -9 4 -5 -13z" />
            <path d="M10097 26256 c-4 -10 -1 -13 9 -9 15 6 19 23 5 23 -5 0 -11 -7 -14
-14z" />
            <path d="M10128 26260 c-6 -18 8 -50 24 -50 7 0 7 4 0 13 -5 6 -12 21 -14 32
-3 15 -5 17 -10 5z" />
            <path d="M15706 26213 c-2 -3 -2 -12 1 -21 5 -11 3 -13 -8 -7 -8 4 -4 -3 8
-16 18 -19 25 -21 35 -10 11 10 10 14 -5 22 -9 6 -15 15 -11 21 4 7 10 5 16
-3 8 -12 9 -12 6 0 -4 14 -32 24 -42 14z m29 -43 c3 -5 2 -10 -4 -10 -5 0 -13
5 -16 10 -3 6 -2 10 4 10 5 0 13 -4 16 -10z" />
            <path d="M13575 26180 c-4 -6 -11 -8 -16 -5 -5 3 -6 -1 -3 -9 3 -9 11 -16 17
-16 6 0 7 3 4 7 -4 3 -2 12 4 20 6 7 9 13 6 13 -3 0 -9 -5 -12 -10z" />
            <path d="M14583 26183 c-14 -5 -7 -30 15 -55 10 -12 11 -18 4 -18 -7 0 -12 -4
-12 -10 0 -5 7 -10 16 -10 8 0 13 4 9 9 -3 5 1 16 9 24 12 12 11 17 -7 39 -12
14 -27 24 -34 21z m37 -45 c0 -14 -18 -9 -24 8 -11 28 -6 39 9 20 8 -11 15
-23 15 -28z" />
            <path d="M11077 26174 c-8 -8 4 -35 26 -59 15 -17 17 -17 12 -2 -4 9 -4 17 -1
17 10 0 51 -40 43 -41 -4 -1 -11 -3 -17 -4 -5 -1 -16 -3 -22 -4 -10 -1 -9 -4
1 -10 7 -5 11 -13 7 -19 -5 -9 28 -26 47 -23 9 1 9 -13 0 -22 -3 -4 -16 1 -27
10 -15 13 -14 9 4 -11 14 -16 19 -26 11 -21 -9 5 -12 3 -9 -6 3 -8 11 -13 18
-11 7 2 9 -5 5 -18 -3 -11 -2 -20 4 -20 5 0 11 8 14 18 2 9 4 2 4 -16 0 -23
-3 -29 -9 -20 -7 9 -8 8 -4 -5 3 -10 6 -22 6 -27 0 -4 9 -10 20 -13 11 -3 20
-13 20 -22 0 -9 -4 -14 -9 -11 -5 4 -7 -4 -3 -16 l5 -22 8 22 c17 46 41 16 44
-57 2 -26 -1 -38 -6 -30 -4 7 -11 11 -14 7 -3 -3 1 -13 10 -22 9 -9 17 -25 18
-35 0 -11 6 -17 12 -13 21 13 34 9 39 -14 4 -13 11 -24 16 -24 6 0 10 -4 10
-10 0 -5 -7 -10 -15 -10 -8 0 -15 8 -15 17 0 14 -2 15 -12 4 -11 -11 -10 -14
0 -18 10 -4 10 -8 0 -21 -12 -15 -11 -16 4 -4 9 8 22 11 28 7 15 -9 12 -45 -4
-45 -12 0 -13 -6 -5 -30 6 -16 15 -27 20 -24 5 3 14 -6 21 -20 12 -27 31 -37
22 -11 -5 11 -2 13 10 9 11 -4 16 -1 16 12 0 16 -2 17 -11 4 -8 -12 -10 -12
-7 4 2 11 8 21 14 23 6 2 10 -17 10 -51 0 -42 -2 -51 -11 -40 -12 17 -49 9
-39 -8 4 -6 10 -5 15 3 4 7 11 10 15 6 4 -3 1 -13 -6 -22 -10 -12 -10 -21 0
-45 7 -16 17 -30 22 -30 5 0 8 -6 7 -12 -5 -40 1 -46 40 -42 l39 3 -2 -82 -2
-82 41 1 c41 2 42 1 41 -31 -1 -40 -11 -54 -19 -25 -3 11 -1 20 4 20 5 0 9 5
9 12 0 9 -3 9 -12 0 -17 -17 -33 -15 -18 2 9 12 8 15 -9 13 -11 -1 -23 -7 -26
-15 -2 -7 0 -10 5 -7 6 3 13 -1 16 -9 3 -9 10 -13 15 -10 5 3 9 -2 9 -10 0 -8
8 -19 17 -25 10 -5 14 -13 10 -18 -5 -4 -17 3 -28 16 -11 13 -22 21 -25 18 -4
-3 1 -13 9 -22 9 -8 14 -23 11 -33 -5 -14 -4 -15 5 -2 9 13 11 12 12 -5 l0
-20 9 20 c8 19 9 19 9 -2 1 -12 7 -23 14 -26 6 -2 4 -3 -5 -2 -10 2 -21 -2
-25 -7 -4 -7 -1 -8 7 -3 10 6 12 4 7 -9 -4 -10 1 -23 13 -33 20 -18 29 -49 10
-38 -6 3 -7 -1 -3 -10 4 -12 8 -13 13 -5 6 9 10 8 17 -5 7 -12 13 -14 17 -6 4
6 3 11 -2 11 -6 0 -13 7 -16 16 -4 11 -2 15 8 11 8 -3 17 -1 19 6 3 7 4 0 3
-16 -2 -15 -6 -30 -9 -34 -12 -11 -8 -25 5 -17 7 5 8 3 4 -5 -6 -9 -13 -8 -29
6 -15 12 -18 13 -9 2 6 -9 8 -19 4 -23 -4 -4 -2 -7 3 -6 24 5 35 -1 35 -21 0
-12 -4 -17 -11 -13 -8 4 -8 0 0 -15 7 -11 22 -22 34 -23 12 0 29 -1 37 -2 16
-1 24 -72 9 -81 -9 -6 -39 22 -39 36 0 17 20 9 21 -8 0 -15 2 -15 9 2 13 29
12 35 -5 29 -8 -4 -15 -2 -15 3 0 5 -9 9 -19 9 -19 0 -19 -1 0 -38 11 -21 24
-38 29 -38 5 0 8 -6 6 -12 -1 -7 -1 -10 1 -5 3 4 9 7 14 7 5 0 8 -3 8 -7 -4
-27 3 -53 14 -53 18 0 38 -39 39 -78 1 -27 3 -29 13 -16 10 14 12 11 13 -20 1
-20 -3 -36 -9 -36 -6 0 -8 7 -5 17 4 10 4 14 0 10 -4 -4 -14 -1 -23 6 -13 10
-12 7 2 -15 10 -16 14 -28 11 -28 -4 0 1 -9 10 -19 10 -11 15 -24 12 -30 -3
-5 1 -16 10 -25 8 -9 12 -22 9 -28 -4 -7 0 -5 9 5 10 13 21 17 36 12 22 -7 22
-7 -6 -36 -15 -16 -24 -32 -21 -36 4 -3 7 -1 7 5 0 6 8 16 18 21 15 8 17 4 17
-35 0 -38 -2 -42 -12 -27 -10 13 -10 18 -1 24 7 5 9 11 5 16 -5 4 -12 1 -17
-7 -6 -10 -1 -28 16 -58 14 -23 22 -47 18 -54 -5 -7 -3 -8 6 -3 10 6 12 4 7
-9 -4 -9 -3 -15 2 -11 5 3 12 -1 15 -7 3 -7 3 -3 0 10 -3 12 -1 22 4 22 18 0
20 -50 3 -61 -12 -7 -13 -12 -3 -15 6 -3 12 -14 12 -24 0 -10 4 -22 10 -25 5
-3 7 0 4 8 -3 8 -1 17 4 21 10 6 9 -24 -2 -42 -4 -6 -2 -12 3 -12 6 0 11 -9
11 -19 0 -11 5 -23 10 -26 6 -4 8 -10 5 -14 -2 -5 2 -17 10 -27 8 -10 15 -14
15 -7 0 6 -5 15 -11 19 -8 4 -7 9 2 15 12 8 11 12 -2 22 -25 20 -30 44 -9 50
23 6 60 -31 59 -59 0 -19 -1 -19 -8 0 -7 18 -8 18 -14 2 -4 -10 -1 -19 8 -22
18 -7 20 -40 4 -50 -7 -4 -9 0 -5 14 4 11 2 23 -4 27 -7 4 -10 -5 -8 -28 1
-20 7 -33 13 -31 6 3 11 -6 12 -18 2 -18 0 -20 -7 -8 -14 22 -12 7 5 -33 8
-19 11 -38 7 -42 -4 -5 0 -4 8 0 8 5 26 9 41 10 22 1 26 -3 25 -26 -1 -15 -5
-24 -10 -21 -6 3 -6 -3 -2 -14 4 -10 9 -32 11 -49 l4 -30 -13 24 c-8 14 -11
32 -8 40 4 10 1 12 -8 6 -10 -6 -11 -2 -6 19 6 24 5 26 -8 14 -7 -7 -12 -16
-10 -19 7 -12 27 -64 28 -73 1 -5 15 -17 31 -26 24 -13 29 -22 27 -46 -2 -25
-1 -26 8 -11 6 9 8 27 5 38 -5 16 -3 20 11 17 9 -1 19 -10 21 -18 4 -13 3 -13
-6 0 -9 13 -11 13 -11 -2 0 -9 4 -20 10 -23 12 -8 8 -128 -5 -137 -6 -5 -5 -8
4 -8 7 0 11 -6 7 -15 -3 -8 1 -20 8 -27 7 -7 11 -20 8 -28 -2 -9 2 -6 11 5 14
19 16 19 22 3 4 -10 12 -18 17 -18 6 0 8 7 4 15 -3 8 -1 15 4 15 15 0 11 -32
-5 -54 -8 -10 -12 -23 -9 -27 3 -5 0 -9 -6 -9 -7 0 -6 -5 2 -14 7 -8 18 -11
23 -8 6 3 4 -2 -4 -11 -10 -12 -10 -17 -1 -17 6 0 10 -6 8 -12 -3 -7 2 -19 9
-27 8 -7 12 -20 9 -27 -8 -22 2 -16 15 7 6 12 7 19 1 15 -5 -3 -12 0 -15 8 -8
21 23 13 40 -10 14 -18 15 -18 8 6 -4 14 -4 22 0 18 11 -11 16 -90 6 -102 -4
-6 -11 -20 -14 -31 -7 -20 -7 -20 8 -1 17 22 20 14 5 -14 -7 -13 -5 -21 6 -31
12 -10 15 -10 12 2 -4 20 64 19 70 -1 4 -12 2 -13 -9 -2 -7 6 -20 12 -29 12
-9 0 -6 -6 11 -16 18 -12 25 -25 26 -50 1 -19 -2 -34 -7 -34 -4 0 -7 11 -7 25
0 27 -19 55 -36 55 -5 0 -2 -9 7 -19 10 -11 13 -22 8 -25 -6 -3 -12 1 -16 9
-3 8 -11 15 -17 15 -8 0 -9 -4 0 -13 6 -8 8 -17 5 -20 -4 -3 6 -4 21 -1 18 3
24 1 20 -6 -4 -6 1 -20 11 -31 10 -11 12 -19 6 -19 -7 0 -17 9 -24 20 -16 26
-28 26 -21 0 3 -11 10 -17 16 -13 6 4 8 0 4 -9 -3 -9 1 -24 8 -35 13 -17 14
-16 8 7 -3 14 -3 22 0 19 4 -3 9 -23 12 -44 5 -45 19 -56 58 -50 15 3 27 1 27
-5 0 -6 -4 -9 -9 -6 -14 9 -23 -26 -10 -38 9 -8 10 -6 6 7 -4 10 -3 16 2 12 6
-3 9 -14 8 -25 -1 -13 -5 -16 -13 -10 -6 6 -14 7 -17 3 -4 -3 -7 3 -7 15 0 23
-15 38 -24 23 -4 -5 -2 -11 3 -13 6 -1 12 -16 15 -32 3 -16 8 -31 12 -35 4 -3
4 2 1 12 -6 15 -4 16 11 8 9 -5 17 -21 17 -35 1 -21 -2 -23 -15 -13 -10 8 -9
5 3 -9 9 -11 14 -24 10 -29 -5 -4 -2 -5 5 -1 6 4 12 2 12 -3 0 -6 4 -11 10
-11 5 0 7 8 4 18 -5 14 -4 15 5 2 5 -8 8 -26 5 -40 -5 -22 -4 -23 6 -7 6 9 9
25 6 35 -4 12 -1 18 12 18 12 0 17 -9 18 -29 1 -19 -2 -24 -7 -15 -7 10 -9 9
-9 -4 0 -9 5 -20 10 -23 6 -3 10 -11 10 -17 0 -6 -7 -5 -17 3 -15 13 -15 12
-4 -9 6 -12 14 -46 17 -75 8 -90 37 -133 56 -84 9 21 28 13 27 -11 0 -14 -2
-13 -9 3 -7 16 -9 17 -9 3 -1 -9 -4 -25 -8 -35 -6 -15 -4 -16 8 -6 13 10 15 7
15 -25 0 -20 -4 -40 -10 -44 -7 -5 -6 -8 3 -8 8 0 11 -6 7 -15 -4 -12 0 -14
19 -11 14 3 25 0 25 -6 0 -5 -7 -8 -15 -5 -19 8 -26 -5 -10 -20 10 -9 15 -6
25 13 8 13 20 24 29 24 12 0 12 -3 3 -12 -7 -7 -12 -21 -11 -33 0 -20 0 -20 9
0 5 13 7 1 5 -35 -3 -72 5 -86 48 -85 l35 2 -2 -86 c-1 -47 -6 -92 -10 -100
-7 -10 -6 -12 4 -6 10 6 12 4 7 -7 -3 -9 0 -24 8 -34 7 -10 11 -24 8 -30 -2
-7 2 -11 11 -10 19 3 34 16 17 16 -7 0 -9 5 -6 10 10 16 23 11 20 -7 -2 -10
-4 -21 -4 -25 -1 -5 -9 -8 -18 -8 -17 -1 -17 -1 0 -14 9 -7 17 -22 17 -34 0
-31 11 -53 23 -45 6 4 8 -2 5 -15 -3 -12 1 -29 9 -39 13 -17 13 -16 3 5 -6 12
-7 23 -3 23 70 10 68 11 69 -37 1 -41 0 -44 -20 -38 -21 6 -21 6 -1 -12 23
-21 33 -74 15 -74 -7 0 -9 7 -6 15 6 17 -13 55 -28 55 -4 0 -3 -6 3 -13 6 -8
7 -18 3 -22 -4 -5 -1 -4 7 0 11 6 13 4 8 -10 -4 -9 -4 -23 1 -29 5 -6 16 -32
26 -58 19 -51 34 -61 43 -28 12 45 28 11 29 -58 1 -79 2 -82 32 -85 12 0 23
-10 26 -21 8 -29 -2 -33 -20 -8 -17 25 -36 30 -28 7 4 -8 11 -12 17 -8 6 4 8
0 4 -10 -3 -9 -1 -31 5 -48 10 -30 12 -31 25 -14 7 11 11 27 7 37 -3 10 0 18
6 18 6 0 9 -14 6 -37 -3 -21 -5 -42 -5 -46 -1 -5 -7 -5 -13 -2 -7 4 -2 -3 12
-14 14 -11 31 -19 38 -16 6 2 12 -3 13 -13 0 -14 2 -14 7 -2 12 26 29 20 29
-11 0 -16 -4 -25 -8 -18 -4 6 -11 9 -17 5 -6 -3 -15 2 -21 11 -5 10 -13 15
-17 11 -10 -11 17 -40 33 -34 7 3 13 -1 13 -10 0 -8 3 -13 8 -11 4 3 7 -22 8
-56 0 -33 -2 -55 -4 -49 -2 7 -8 12 -14 12 -6 0 -3 -9 7 -20 10 -11 15 -20 11
-20 -4 0 -2 -9 4 -21 9 -15 18 -19 42 -15 30 5 30 4 11 -17 -10 -12 -22 -25
-26 -28 -4 -3 -1 -12 8 -19 13 -11 15 -10 10 2 -3 8 3 22 13 32 19 16 19 16
20 -10 0 -18 -3 -22 -9 -13 -6 10 -9 10 -9 2 0 -6 4 -14 9 -17 5 -3 9 -22 8
-41 -1 -34 -1 -35 -14 -13 -7 13 -12 18 -13 13 0 -18 31 -56 41 -50 13 9 11
-9 -3 -24 -11 -11 -10 -13 5 -8 9 4 17 2 17 -3 0 -6 -5 -10 -11 -10 -6 0 -7
-5 -2 -12 4 -7 8 -19 10 -27 1 -7 7 -19 13 -25 8 -8 10 -6 5 9 -6 19 -6 19 9
1 14 -18 15 -18 13 2 0 11 -4 19 -8 16 -12 -7 -26 20 -25 50 1 22 6 30 21 31
19 1 21 -4 20 -74 0 -42 -2 -80 -5 -86 -5 -13 10 -62 27 -94 13 -24 14 -24 10
-3 -3 12 -2 22 2 22 11 0 44 -71 47 -100 1 -14 0 -19 -3 -12 -2 6 -9 12 -15
12 -5 0 0 -15 11 -33 11 -19 17 -38 13 -43 -4 -4 2 -2 12 6 18 14 19 14 8 -7
-7 -12 -12 -24 -12 -28 0 -13 22 8 30 29 7 17 9 18 9 3 1 -10 5 -16 10 -13 20
13 20 -40 1 -66 -21 -28 -15 -39 8 -16 7 7 12 9 12 6 0 -4 -7 -13 -14 -21 -13
-13 -13 -17 0 -33 14 -18 14 -18 8 1 -5 18 -5 19 6 2 8 -12 18 -16 31 -12 29
9 47 -20 46 -74 0 -32 -2 -40 -8 -26 -6 15 -8 16 -8 4 -1 -9 2 -19 7 -23 4 -4
7 -29 8 -56 2 -60 8 -70 44 -65 26 3 28 2 17 -15 -8 -14 -13 -16 -19 -7 -4 6
-10 8 -15 4 -4 -5 -2 -12 5 -16 7 -4 10 -15 6 -24 -3 -10 -1 -17 6 -17 6 0 9
4 6 9 -3 5 3 19 13 32 l19 23 0 -28 c0 -20 -3 -24 -9 -15 -7 12 -9 11 -10 -1
-1 -8 -3 -25 -4 -37 -1 -13 0 -23 2 -23 2 0 7 10 12 23 7 18 9 14 10 -23 4
-73 5 -79 14 -77 5 1 12 -4 15 -12 3 -10 0 -11 -9 -5 -12 7 -12 5 0 -9 7 -9
10 -17 7 -17 -4 0 0 -7 9 -16 9 -9 19 -14 22 -11 3 3 -2 14 -12 23 -11 11 -13
19 -5 24 7 4 4 14 -9 31 l-19 24 34 1 c27 0 34 -3 34 -19 0 -19 -1 -20 -20 -2
-11 10 -20 13 -20 7 0 -6 5 -14 11 -18 6 -3 8 -16 5 -28 -5 -20 -5 -20 9 -2 8
11 15 15 15 9 0 -7 -5 -15 -10 -18 -7 -4 -7 -12 0 -25 14 -25 14 -91 0 -70 -5
8 -10 11 -10 7 0 -15 23 -52 37 -60 9 -5 12 -16 7 -30 -6 -22 -6 -22 10 -2
l16 20 12 -20 c7 -11 13 -40 15 -65 1 -25 -1 -39 -3 -32 -3 6 -10 12 -16 12
-5 0 0 -15 11 -33 11 -19 17 -38 14 -43 -4 -5 -1 -3 7 4 22 19 35 15 47 -15
l12 -28 -5 30 c-4 23 -3 27 5 15 11 -16 15 -68 4 -57 -3 4 -13 1 -21 -6 -13
-10 -15 -9 -9 6 3 9 2 17 -3 17 -5 0 -9 -11 -8 -24 2 -19 5 -22 15 -15 7 6 17
8 23 4 15 -9 12 -45 -4 -45 -12 0 -13 -6 -5 -30 6 -16 15 -28 20 -25 4 3 16
-9 25 -27 11 -22 13 -33 5 -36 -6 -2 -8 -8 -5 -14 4 -6 10 -5 15 3 4 7 11 11
14 7 4 -3 1 -15 -6 -25 -9 -16 -8 -26 7 -54 19 -36 21 -40 23 -63 1 -9 10 -13
26 -12 36 4 50 -19 52 -88 1 -44 -1 -56 -9 -46 -8 12 -9 11 -4 -3 3 -10 6 -20
6 -22 0 -1 14 -1 32 1 30 4 30 3 14 -15 -14 -16 -15 -20 -2 -30 12 -10 14 -9
10 7 -6 20 22 50 34 38 4 -4 -1 -13 -11 -21 -14 -10 -15 -14 -3 -18 10 -4 14
-22 14 -61 -1 -51 -2 -54 -15 -36 -8 11 -11 25 -8 30 4 6 8 23 11 38 3 19 2
23 -5 12 -5 -8 -7 -18 -5 -22 11 -17 -9 -7 -28 15 l-20 22 16 -25 c9 -13 16
-32 16 -41 0 -25 26 -70 35 -61 5 4 5 -1 2 -10 -4 -11 1 -25 14 -36 13 -13 17
-26 13 -38 -6 -18 -6 -18 9 1 16 20 16 20 30 0 11 -14 14 -38 11 -82 -2 -38 1
-63 7 -63 6 0 7 -5 4 -11 -4 -6 2 -5 13 2 27 17 34 9 35 -35 1 -20 5 -36 10
-36 6 0 7 17 3 40 -3 22 -2 40 2 40 5 0 9 -29 10 -65 1 -51 -2 -65 -14 -65
-12 0 -12 -4 -4 -20 6 -10 16 -17 24 -14 7 3 20 -2 29 -10 13 -14 16 -14 21
-1 3 8 10 15 15 15 5 0 4 -11 -2 -25 -15 -31 -22 -31 -41 -3 -13 19 -18 20
-28 9 -9 -10 -10 -12 -1 -7 17 9 50 -54 33 -64 -7 -5 -8 -10 -2 -14 5 -3 17 3
26 13 10 10 13 21 7 22 -6 3 -5 10 3 21 10 14 12 10 12 -23 0 -21 -2 -37 -4
-34 -7 7 -32 -16 -32 -29 0 -6 4 -5 9 3 16 25 29 2 28 -47 -1 -62 6 -72 48
-67 34 5 34 4 18 -15 -12 -14 -19 -16 -24 -8 -5 7 -11 9 -16 5 -4 -5 -2 -12 5
-16 7 -4 10 -15 7 -23 -4 -9 1 -18 10 -22 10 -4 15 -12 11 -22 -3 -8 -2 -12 3
-9 12 7 25 -31 16 -45 -3 -5 -1 -10 5 -10 7 0 9 -7 6 -16 -3 -9 -2 -12 5 -9 6
4 8 12 6 19 -3 8 5 12 24 12 16 0 30 -2 30 -5 7 -26 4 -83 -4 -88 -6 -4 -7 5
-3 23 6 23 5 27 -5 17 -18 -18 -30 -16 -23 2 5 11 2 13 -10 9 -15 -6 -14 -9 6
-32 13 -14 17 -22 9 -17 -9 5 -12 3 -9 -6 3 -8 11 -13 18 -11 7 2 9 -5 5 -18
-3 -11 -2 -20 4 -20 5 0 12 8 14 18 3 9 3 -2 1 -25 -2 -25 1 -47 9 -55 7 -7
13 -24 14 -37 2 -26 10 -38 19 -28 4 4 1 13 -6 22 -7 8 -8 15 -3 15 5 0 15
-11 21 -25 15 -33 15 -36 -3 -29 -22 9 -18 -3 4 -16 17 -8 19 -7 16 13 -1 12
-6 31 -10 42 -6 20 -6 20 9 1 11 -13 14 -35 12 -71 -4 -66 4 -79 47 -78 l35 1
-3 -67 c-1 -36 -6 -75 -10 -86 -5 -14 -4 -16 3 -6 6 9 12 10 16 2 7 -11 61 -9
73 2 3 4 6 28 6 54 0 27 2 38 4 26 2 -13 8 -23 12 -23 5 0 6 12 3 28 -5 24 -4
26 11 13 19 -15 23 -1 6 16 -6 6 -12 24 -13 40 -2 15 -6 45 -10 66 -7 30 -11
35 -22 26 -17 -14 -19 2 -2 19 10 10 10 17 0 32 -6 11 -12 26 -12 34 0 8 -7
16 -15 20 -12 4 -13 1 -5 -14 6 -11 15 -29 20 -40 8 -16 7 -20 -5 -20 -8 0
-16 7 -16 15 -3 31 -4 35 -21 55 -23 26 -23 28 7 28 14 0 25 -5 25 -11 0 -6 3
-7 7 -4 3 4 -2 16 -11 26 -10 11 -17 28 -16 38 4 35 -1 49 -9 28 -5 -11 -5
-29 -1 -41 6 -14 4 -21 -4 -21 -9 0 -13 18 -13 58 0 34 4 56 10 52 5 -3 4 4
-3 17 -6 12 -9 28 -6 35 3 8 1 17 -4 20 -6 4 -10 1 -10 -4 0 -6 -7 -11 -15
-11 -8 0 -15 6 -15 13 0 8 -4 7 -10 -3 -8 -13 -10 -12 -10 3 0 9 -3 17 -7 17
-12 0 -14 78 -3 85 6 3 10 14 10 23 0 13 -2 14 -8 4 -6 -8 -9 -2 -9 17 0 17 3
28 7 26 3 -2 12 0 20 6 13 7 12 9 -1 9 -20 0 -29 31 -24 83 2 20 0 37 -5 37
-4 0 -6 10 -4 23 l5 22 -10 -22 c-15 -34 -20 -28 -14 16 3 24 1 41 -6 46 -9 5
-11 -2 -6 -28 4 -21 3 -37 -4 -41 -7 -5 -11 5 -11 28 0 20 -5 36 -11 36 -5 0
-7 -4 -4 -10 3 -5 1 -18 -5 -27 -8 -14 -10 -10 -8 22 2 30 7 40 20 40 9 0 20
4 25 8 4 5 2 7 -6 5 -10 -2 -17 8 -21 27 l-7 30 -5 -30 c-5 -28 -5 -27 -7 9
-1 30 2 37 14 32 9 -3 15 0 15 9 0 9 -6 12 -16 8 -13 -5 -15 -2 -9 18 18 57
18 55 6 39 -9 -12 -11 -9 -11 15 0 51 -3 64 -14 57 -17 -11 -67 -9 -63 2 5 14
30 15 39 2 8 -13 13 -8 22 21 6 18 2 25 -17 32 -14 5 -27 16 -30 23 -4 9 -6 9
-6 1 -1 -6 4 -15 9 -18 15 -9 12 -35 -4 -35 -10 0 -13 13 -13 49 1 27 5 47 9
45 4 -3 8 0 8 7 0 8 5 7 17 -2 15 -13 15 -12 5 6 -6 11 -16 27 -22 35 -9 12
-10 10 -5 -9 5 -15 4 -21 -4 -17 -5 4 -9 20 -8 35 2 23 5 27 15 19 9 -8 11 -7
6 6 -3 9 -10 16 -15 16 -5 0 -9 5 -9 11 0 5 5 7 11 3 8 -5 8 -1 0 14 -6 11
-11 32 -11 46 0 23 -3 25 -30 20 -22 -5 -30 -3 -30 7 0 11 -2 11 -9 1 -4 -7
-8 57 -8 144 -1 155 -3 165 -35 176 -4 2 -13 27 -20 56 -7 28 -15 52 -19 52
-3 0 -6 16 -7 35 -1 28 2 33 16 28 14 -5 14 -3 -2 9 -10 8 -16 20 -13 25 8 13
-18 37 -31 29 -5 -3 -20 6 -32 19 -13 14 -19 25 -13 25 6 0 13 -5 15 -11 2 -6
16 -13 31 -14 20 -3 27 1 27 15 0 9 -6 23 -14 30 -8 6 -12 19 -9 27 4 11 2 14
-5 9 -7 -4 -12 -1 -12 9 0 9 5 13 10 10 16 -10 11 11 -6 26 -10 8 -14 22 -10
40 3 18 0 32 -11 41 -14 11 -15 10 -9 -10 4 -13 3 -22 -3 -22 -6 0 -10 16 -9
35 1 25 4 32 11 23 6 -9 7 -7 2 7 -4 11 -8 32 -10 48 -1 15 -5 30 -10 35 -4 4
-5 -1 -2 -10 4 -10 2 -18 -3 -18 -6 0 -10 9 -10 20 0 11 -4 20 -9 20 -5 0 -7
-10 -4 -22 4 -20 3 -21 -7 -5 -6 9 -15 17 -20 17 -5 0 -10 -8 -12 -17 -2 -10
-4 24 -5 75 -1 50 2 92 7 92 17 0 19 49 4 79 -9 18 -13 38 -10 46 3 8 1 15 -4
15 -5 0 -9 10 -7 21 1 12 -4 24 -10 26 -7 3 -13 -2 -13 -10 0 -8 -7 -20 -16
-28 -22 -18 -33 3 -28 54 4 37 14 66 14 38 0 -16 29 -45 37 -37 3 3 0 12 -6
20 -7 8 -10 20 -6 26 3 5 -1 10 -9 10 -10 0 -16 9 -16 22 0 21 0 21 15 2 8
-10 15 -14 15 -8 0 6 -7 17 -15 24 -15 13 -15 13 -28 -30 -2 -8 -5 7 -5 35 0
27 5 53 11 57 8 5 7 10 -1 21 -7 8 -12 25 -12 38 0 45 -3 58 -14 51 -18 -11
-67 -9 -62 2 2 6 8 10 14 9 6 -2 14 7 17 18 5 16 10 18 21 8 10 -8 14 -8 14 0
0 6 -11 16 -24 21 -13 5 -26 12 -28 17 -5 9 1 -16 9 -34 3 -7 -2 -13 -10 -13
-11 0 -15 12 -14 49 1 28 3 48 5 46 3 -2 11 3 19 11 14 13 13 17 -1 33 -18 19
-22 46 -6 36 6 -4 4 11 -4 32 -8 21 -17 51 -21 67 -5 23 -9 26 -15 16 -11 -17
-14 -14 -29 25 l-11 30 5 -35 c3 -19 2 -29 -1 -22 -3 6 -9 10 -13 8 -4 -3 -8
11 -8 30 -1 23 3 32 10 28 8 -4 7 -2 0 7 -19 19 -16 31 6 24 24 -7 31 4 20 30
-7 18 -8 18 -8 2 -1 -10 -7 -17 -13 -14 -9 2 -13 31 -13 95 0 79 -3 93 -20
106 -12 10 -15 15 -7 16 7 0 10 5 6 11 -4 8 -9 7 -15 -2 -7 -11 -9 -10 -9 4 0
9 -5 17 -10 17 -7 0 -7 -6 0 -19 14 -27 13 -31 -9 -31 -15 0 -18 7 -16 43 1
23 6 50 10 60 4 9 3 17 -1 17 -5 0 -10 23 -10 52 -1 39 1 47 8 29 4 -12 5 -28
2 -34 -5 -9 -4 -9 5 -1 9 8 9 17 0 40 -7 16 -11 36 -10 43 2 13 0 13 -13 2
-19 -16 -21 -8 -4 18 9 15 9 23 -2 35 -7 9 -15 14 -18 11 -3 -2 1 -11 8 -20
10 -12 10 -15 -4 -15 -14 0 -19 11 -15 36 0 4 -5 11 -13 16 -10 7 -10 11 -1
14 7 3 9 12 6 20 -3 8 0 14 6 14 6 0 11 -11 11 -25 0 -14 4 -25 9 -25 6 0 6
12 0 32 -5 18 -9 34 -9 36 0 1 -10 1 -23 -2 -21 -4 -23 -1 -23 56 -1 33 2 55
6 49 3 -7 6 -27 5 -46 0 -26 4 -35 19 -39 13 -3 16 -2 8 6 -6 6 -8 17 -5 24 3
8 0 14 -7 14 -6 0 -8 4 -5 10 3 5 -1 23 -10 39 -8 16 -12 36 -9 44 4 11 -1 13
-20 10 -18 -4 -26 -1 -26 8 0 11 -2 11 -10 -1 -9 -13 -10 -13 -10 0 0 13 -1
13 -11 0 -11 -16 -12 -20 11 69 6 23 9 46 6 49 -3 3 -6 -3 -7 -14 -1 -28 -17
24 -17 56 0 22 1 22 8 -5 5 -21 8 -24 9 -10 0 12 5 25 10 30 6 6 4 20 -5 35
l-15 25 6 -25 c5 -21 4 -22 -5 -8 -8 13 -8 24 0 37 13 26 13 40 -1 21 -8 -12
-10 -9 -6 13 2 15 1 30 -4 33 -4 2 -6 12 -3 22 5 14 3 14 -10 -2 -14 -17 -16
-17 -16 -4 0 9 5 20 10 23 6 3 10 13 10 21 0 25 -18 6 -25 -26 -10 -45 -29
-38 -20 8 4 24 3 34 -3 27 -6 -5 -12 -21 -15 -35 -2 -14 -4 21 -3 77 1 100 9
138 18 89 3 -16 1 -23 -5 -19 -6 4 -8 0 -4 -11 4 -10 7 -20 7 -22 0 -2 4 -4 9
-4 15 0 3 74 -14 86 -9 7 -16 26 -15 45 0 19 -1 42 -2 51 -1 18 -18 20 -30 3
-11 -15 -9 28 2 45 8 12 10 12 11 -5 0 -11 3 -15 5 -9 7 17 -15 66 -26 59 -6
-3 -7 -18 -4 -32 5 -19 4 -24 -5 -19 -8 6 -10 0 -5 -18 3 -15 2 -26 -4 -26
-20 0 -21 47 -2 87 22 46 24 89 3 71 -7 -6 -13 -16 -13 -22 0 -6 5 -4 10 4 5
8 10 11 10 5 0 -15 -19 -42 -21 -30 -6 30 -4 63 3 59 11 -7 10 10 -1 21 -6 6
-11 37 -13 70 -3 56 -5 60 -25 57 -19 -3 -23 2 -23 23 0 14 -4 24 -9 21 -5 -3
-7 -16 -4 -29 4 -13 2 -19 -3 -14 -11 10 -12 107 -1 100 4 -2 7 1 7 6 0 6 -4
11 -9 11 -4 0 -7 34 -7 77 2 64 -1 79 -18 94 -16 15 -17 21 -6 34 7 8 10 18 6
22 -3 3 -6 1 -6 -6 0 -9 -4 -8 -15 3 -8 8 -15 20 -15 27 0 9 4 8 15 -1 10 -8
15 -9 15 -2 0 6 -6 15 -13 19 -7 4 -17 19 -23 33 -10 21 -11 17 -7 -26 3 -28
1 -54 -4 -57 -5 -3 -9 45 -8 115 0 66 -3 125 -7 131 -4 7 -8 8 -8 2 0 -5 -7 1
-16 13 -20 29 -10 43 12 16 14 -18 15 -18 4 4 -6 12 -8 22 -4 22 3 0 1 7 -6
15 -6 8 -9 19 -5 24 4 7 0 8 -10 5 -12 -5 -13 -10 -6 -17 14 -14 14 -30 1 -22
-15 10 -12 -29 5 -51 20 -27 19 -30 -12 -29 -25 0 -27 4 -30 43 -1 23 2 42 7
42 6 0 10 5 10 11 0 5 -4 7 -10 4 -13 -8 -13 36 0 69 6 17 6 26 0 26 -17 0
-11 18 7 22 15 3 15 5 -2 21 -15 16 -16 20 -4 28 8 5 9 9 2 9 -6 0 -9 9 -6 20
5 19 -5 25 -31 21 -5 -1 -6 4 -3 11 20 48 20 51 9 44 -7 -4 -12 -13 -12 -20 0
-8 -7 -19 -16 -27 -21 -17 -35 4 -29 46 2 17 4 36 4 43 1 7 6 10 11 7 6 -4 8
-16 5 -28 -5 -17 -4 -19 4 -7 8 11 11 12 11 2 0 -7 5 -10 10 -7 8 5 7 11 0 20
-7 8 -9 19 -5 25 3 5 -1 10 -9 10 -9 0 -16 8 -17 18 0 14 -2 14 -9 -3 -6 -14
-8 -5 -8 33 0 28 4 52 8 52 4 0 7 7 7 15 0 8 -4 12 -8 9 -4 -2 -7 8 -7 23 1
69 -1 76 -19 62 -15 -13 -15 -12 -4 8 12 24 5 73 -11 73 -5 0 -7 -4 -3 -9 3
-5 1 -12 -4 -15 -5 -3 -12 1 -15 10 -9 23 -7 36 3 30 12 -8 22 20 14 42 -8 20
-33 47 -33 35 0 -4 7 -17 15 -27 17 -22 20 -40 6 -32 -5 3 -12 -1 -15 -10 -10
-25 -7 -52 6 -56 7 -3 9 -14 4 -31 -7 -29 -26 -28 -26 1 0 15 2 15 10 2 6 -9
10 -10 10 -3 0 6 -4 14 -10 18 -5 3 -8 45 -7 94 2 75 4 86 16 72 8 -10 7 -6
-2 10 -9 14 -17 44 -19 65 -3 37 -4 39 -38 38 l-35 -1 -1 50 c-1 27 2 44 6 37
4 -7 5 -22 3 -34 -4 -17 -3 -18 6 -6 6 8 8 22 5 32 -2 9 0 19 6 23 7 4 10 1 9
-6 -5 -23 12 -79 23 -79 6 0 7 8 3 18 -4 9 -13 39 -20 65 -7 27 -18 54 -25 60
-10 10 -10 7 0 -12 8 -17 9 -26 1 -34 -6 -6 -11 -9 -12 -6 -8 46 -8 63 2 75 6
8 8 21 4 31 -5 10 -9 24 -11 31 -1 8 -6 17 -10 20 -4 4 -5 0 -2 -9 6 -16 -16
-20 -39 -6 -16 10 -22 76 -6 70 9 -4 11 0 7 11 -4 10 -12 15 -19 12 -10 -3
-12 11 -9 61 2 35 6 74 10 85 4 12 2 18 -4 14 -5 -3 -9 6 -9 20 0 34 -3 35
-68 37 -32 1 -52 3 -46 5 6 2 11 8 11 13 0 4 -7 6 -15 3 -8 -4 -15 -1 -15 5 0
6 -5 11 -11 11 -6 0 -8 -9 -4 -21 5 -15 4 -19 -4 -15 -6 4 -10 23 -8 42 3 19
0 37 -5 40 -5 3 -5 10 1 17 6 8 5 18 -4 30 -13 18 -14 17 -20 -2 -3 -12 -11
-18 -17 -15 -6 4 -6 9 -1 13 13 9 9 36 -5 33 -7 -1 -12 4 -12 10 0 14 -76 59
-84 50 -10 -10 -6 -43 4 -37 6 3 10 0 10 -8 0 -7 -6 -14 -13 -14 -9 0 -14 13
-14 39 0 21 4 38 9 38 5 0 3 7 -6 15 -8 8 -20 15 -26 15 -6 0 -8 5 -4 12 4 7
3 8 -4 4 -7 -4 -12 -2 -12 5 0 8 -4 8 -15 -1 -18 -15 -10 -31 10 -23 8 3 15 0
15 -7 0 -6 5 -8 10 -5 6 3 10 2 10 -3 0 -5 -15 -9 -32 -7 -30 2 -33 5 -34 38
-1 24 3 34 10 30 6 -4 5 1 -3 10 -15 18 -26 23 -16 7 13 -21 -7 -8 -35 23 -16
17 -30 26 -30 20 0 -7 3 -13 8 -13 4 0 12 -8 18 -18 10 -15 8 -18 -10 -20 -16
-1 -18 1 -6 8 13 8 8 14 -10 12 -3 0 -7 11 -8 25 -1 15 -4 28 -7 31 -2 2 -33
5 -69 5 -69 2 -75 6 -81 54 -5 33 13 39 25 9 5 -14 14 -26 20 -26 6 0 3 11 -6
24 -8 14 -16 29 -17 33 -1 4 -8 6 -17 5 -8 -2 -12 -1 -8 3 9 9 -33 56 -44 49
-15 -10 -8 -26 9 -20 10 4 14 4 11 0 -4 -3 0 -13 8 -22 13 -12 13 -16 2 -23
-7 -5 -17 -4 -22 1 -12 12 -29 13 -22 2 3 -5 -1 -9 -9 -9 -11 0 -12 4 -4 14 6
7 7 13 2 13 -12 0 -15 48 -2 55 12 8 -7 35 -25 35 -6 0 -4 -5 4 -10 10 -7 11
-10 2 -10 -7 0 -17 11 -22 24 -12 33 -28 41 -79 41 -44 0 -45 0 -46 36 -2 47
-12 61 -33 50 -21 -11 -46 6 -50 34 -2 16 -1 17 7 5 5 -8 12 -12 16 -9 3 3
-28 34 -70 68 -41 34 -75 60 -75 56 0 -3 -9 -1 -20 5 -11 6 -18 15 -15 19 4 8
-37 42 -50 41 -9 0 -5 -15 4 -15 5 0 13 -4 18 -9 4 -5 -4 -6 -19 -2 -17 4 -28
2 -28 -4 0 -5 6 -11 13 -11 17 -2 19 -1 42 6 l20 7 -20 -17 c-19 -16 -19 -16
-1 -13 10 2 20 -3 22 -10 1 -7 0 -8 -2 -4 -3 5 -18 8 -35 6 -18 -2 -28 0 -24
6 3 6 -1 10 -9 10 -19 0 -20 13 -5 42 8 15 8 23 -2 32 -6 6 -9 6 -6 1 3 -6 -9
-10 -28 -11 -24 0 -32 3 -28 13 6 16 15 17 30 2 7 -7 12 -6 17 6 3 9 2 14 -3
10 -5 -3 -18 6 -29 20 -16 20 -21 22 -26 9 -3 -8 -2 -12 3 -9 5 3 13 1 16 -5
4 -6 1 -9 -5 -8 -7 2 -14 -5 -15 -15 -7 -38 -13 -27 -10 17 1 32 -2 50 -13 58
-13 11 -14 11 -8 -1 6 -9 5 -12 -4 -9 -7 2 -15 13 -16 23 -2 11 -8 21 -14 23
-6 2 -21 14 -32 26 -12 11 -18 16 -15 10 4 -6 -8 -10 -31 -11 -31 -1 -33 1
-14 8 12 5 22 10 22 13 0 2 -10 2 -22 -1 -22 -5 -22 -5 -4 9 17 14 17 15 2 21
-11 4 -15 3 -10 -4 3 -6 -3 -5 -15 3 -12 7 -19 18 -17 24 3 7 -5 9 -20 6 -15
-3 -24 0 -24 8 0 7 7 10 18 5 14 -5 15 -4 4 7 -11 11 -16 10 -28 -6 -8 -11
-14 -14 -14 -8 0 15 -36 16 -45 2 -9 -14 -25 -2 -24 18 0 11 3 12 6 5 2 -7 9
-13 15 -13 6 0 8 6 5 14 -3 7 0 19 6 26 9 10 7 11 -10 5 -17 -5 -22 -3 -17 8
4 10 -5 14 -38 13 -41 -1 -43 0 -44 32 -1 20 3 31 10 29 6 -2 12 -9 12 -15 1
-7 3 -15 4 -19 1 -5 2 -11 1 -15 -2 -16 17 -8 23 9 4 10 1 22 -7 28 -8 5 -14
14 -13 20 1 6 -24 9 -56 9 l-57 -1 2 33 c1 17 7 30 12 27 6 -2 2 2 -7 10 -10
8 -15 16 -13 19 13 12 -48 -2 -70 -16 -23 -15 -25 -15 -25 -1 0 9 5 13 11 9 8
-4 8 0 0 15 -14 25 -4 28 15 4 13 -17 14 -17 7 1 -5 15 -3 17 8 10 20 -12 40
-14 33 -3 -3 5 -11 9 -18 9 -8 0 -21 12 -31 26 -14 22 -19 23 -33 12 -13 -11
-15 -11 -10 3 8 20 -35 65 -51 55 -11 -7 -34 28 -24 38 3 3 4 6 1 6 -2 0 -7
-3 -11 -6z m97 -108 c9 12 13 11 25 -8 13 -21 13 -22 -2 -9 -14 11 -18 11 -23
-3 -6 -14 -9 -14 -25 5 -23 27 -25 39 -2 17 14 -15 18 -15 27 -2z m186 -408
c0 -16 -3 -19 -11 -11 -6 6 -8 16 -5 22 11 17 16 13 16 -11z m357 -35 c1 -11
-6 -19 -16 -20 -10 -1 -18 5 -18 12 0 8 4 12 9 9 4 -3 8 0 8 5 0 6 -4 11 -10
11 -5 0 -10 5 -10 10 0 19 36 -7 37 -27z m-317 -130 c0 -13 -3 -13 -15 -3 -8
7 -15 16 -15 21 0 5 7 6 15 3 8 -4 15 -13 15 -21z m586 -106 c10 4 8 9 -9 23
-12 10 -20 21 -17 23 3 3 25 -12 48 -34 24 -21 39 -39 33 -39 -5 0 -13 5 -17
11 -4 8 -9 7 -15 -2 -6 -10 -9 -11 -9 -1 0 6 -5 12 -11 12 -5 0 -8 -4 -5 -9 3
-5 -6 -8 -20 -8 -16 0 -21 2 -14 7 7 4 9 13 5 19 -5 9 -10 8 -18 -4 -10 -13
-12 -11 -14 11 -1 19 1 23 8 13 6 -10 9 -8 9 11 0 24 1 24 16 -7 10 -20 21
-30 30 -26z m-554 -19 c5 -28 4 -29 -9 -13 -15 21 -17 45 -4 45 5 0 11 -14 13
-32z m836 -191 c6 -6 12 -19 14 -27 2 -8 10 -18 18 -22 8 -4 0 -6 -19 -5 -31
2 -35 6 -37 35 -3 33 6 41 24 19z m-591 -232 c9 -25 -2 -27 -22 -5 -17 18 -17
20 -1 20 9 0 19 -7 23 -15z m-28 -29 c18 -22 -2 -26 -23 -5 -16 16 -17 19 -4
19 9 0 21 -6 27 -14z m1141 -175 c0 7 3 9 8 5 7 -7 8 -11 11 -37 1 -8 7 -7 19
2 15 13 16 12 3 -4 -12 -16 -12 -20 1 -34 17 -16 43 -9 32 9 -4 7 -2 8 4 4 19
-11 -7 -43 -35 -43 -19 0 -21 2 -12 13 10 12 8 13 -5 8 -12 -5 -17 -2 -16 7 5
31 -1 49 -14 49 -7 0 -21 11 -30 25 -24 37 -19 44 9 12 14 -15 25 -23 25 -16z
m-953 -198 c2 -5 -1 -21 -7 -34 -10 -23 -10 -23 -5 4 3 17 2 26 -4 23 -5 -3
-11 0 -14 8 -4 9 0 13 11 12 9 -2 17 -7 19 -13z m110 -405 c-3 -8 -6 -5 -6 6
-1 11 2 17 5 13 3 -3 4 -12 1 -19z m53 12 c0 -13 -12 -19 -26 -13 -21 8 -17
19 6 19 11 0 20 -3 20 -6z m-11 -94 c-9 -8 -10 -7 -5 7 3 10 7 28 7 40 2 16 3
14 6 -7 3 -16 -1 -34 -8 -40z m1262 -349 c-8 -8 -11 -7 -11 4 0 20 13 34 18
19 3 -7 -1 -17 -7 -23z m-939 -439 c-8 -8 -9 -4 -5 13 4 13 8 18 11 10 2 -7
-1 -18 -6 -23z m1173 -458 c3 -5 -1 -10 -9 -10 -9 0 -16 5 -16 10 0 6 4 10 9
10 6 0 13 -4 16 -10z m-805 -420 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6
-1 10 4 10 6 0 11 -4 11 -10z m276 -610 c-2 -33 -6 -63 -9 -67 -4 -3 -5 8 -3
25 2 17 -1 33 -5 34 -7 2 10 68 17 68 2 0 1 -27 0 -60z m122 -365 c-3 -3 -9 2
-12 12 -6 14 -5 15 5 6 7 -7 10 -15 7 -18z m782 -85 c0 -5 -4 -10 -10 -10 -5
0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m4 -84 c-11 -28 -19 -17 -20
24 0 39 0 40 13 16 7 -13 10 -32 7 -40z m-668 15 c0 -11 -4 -17 -8 -15 -4 3
-8 12 -8 20 0 8 4 14 8 14 4 0 8 -9 8 -19z m-26 -66 c0 -5 -5 -3 -10 5 -5 8
-10 20 -10 25 0 6 5 3 10 -5 5 -8 10 -19 10 -25z m720 26 c0 -5 -7 -11 -14
-14 -10 -4 -13 -1 -9 9 6 15 23 19 23 5z m212 -735 c7 4 8 2 4 -5 -9 -13 -26
-5 -25 12 0 9 2 8 6 -1 2 -6 10 -9 15 -6z m-585 -35 c1 -24 1 -25 -13 -6 -17
23 -18 32 -1 32 6 0 13 -12 14 -26z m704 -438 c-12 -20 -14 -14 -5 12 4 9 9
14 11 11 3 -2 0 -13 -6 -23z m26 -135 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3
4 -12 1 -19z m-372 -298 c3 -5 -1 -10 -10 -10 -9 0 -13 5 -10 10 3 6 8 10 10
10 2 0 7 -4 10 -10z m464 -68 c-5 -2 -9 -8 -9 -13 0 -6 5 -7 10 -4 6 3 10 -1
10 -10 0 -9 -6 -15 -12 -12 -7 2 -13 17 -13 33 -1 39 7 52 15 28 4 -10 3 -20
-1 -22z m-12 -84 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m28 22
c-3 -5 3 -18 15 -30 15 -15 16 -20 6 -20 -8 0 -18 6 -23 13 -12 20 -14 47 -2
47 5 0 8 -4 4 -10z m52 -242 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1
-19z m-383 -14 c9 -3 16 -14 17 -23 0 -11 3 -9 9 7 5 12 14 22 20 22 6 0 3
-12 -7 -26 -17 -26 -17 -26 -33 -7 -9 11 -22 22 -30 26 -8 3 -10 6 -3 6 6 1
18 -2 27 -5z m392 -19 c-3 -8 -1 -15 4 -15 6 0 10 -9 10 -20 0 -12 -7 -20 -17
-20 -15 0 -16 2 -3 10 10 6 11 10 3 10 -7 0 -13 11 -13 25 0 14 5 25 11 25 6
0 9 -7 5 -15z m-340 -45 c0 -36 -1 -38 -13 -20 -11 16 -11 23 -1 40 7 11 13
20 13 20 1 0 1 -18 1 -40z m-1 -90 c3 -35 -6 -26 -20 19 -5 16 -3 22 5 19 7
-2 14 -19 15 -38z m200 -420 c3 -5 -1 -10 -10 -10 -9 0 -13 5 -10 10 3 6 8 10
10 10 2 0 7 -4 10 -10z m340 -90 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2
10 4 10 3 0 8 -4 11 -10z m-63 -567 c-5 -10 -11 -25 -14 -33 -3 -8 -7 -10 -11
-4 -3 6 1 20 9 32 18 26 28 29 16 5z m268 -358 c9 -24 4 -55 -9 -55 -3 0 -6
18 -6 40 -1 46 2 49 15 15z m30 -64 c0 -5 -7 -11 -14 -14 -10 -4 -13 -1 -9 9
6 15 23 19 23 5z m50 -131 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11
10 2 0 4 -4 4 -10z" />
            <path d="M12256 25161 c-4 -6 -1 -11 6 -11 9 0 9 -3 -2 -10 -9 -6 -10 -10 -3
-10 17 0 23 14 14 29 -6 9 -11 10 -15 2z" />
            <path d="M15815 26140 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z" />
            <path d="M15785 26130 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z" />
            <path d="M13570 26096 c0 -8 4 -17 9 -20 5 -4 7 3 4 14 -6 23 -13 26 -13 6z" />
            <path d="M15801 26100 c0 -26 13 -45 34 -48 19 -2 19 -2 2 9 -10 6 -22 20 -27
32 -5 12 -9 15 -9 7z" />
            <path d="M10195 26090 c-4 -6 -11 -7 -17 -4 -6 4 -9 1 -6 -6 2 -8 11 -16 19
-18 8 -3 11 -1 7 3 -4 5 -3 14 3 22 6 7 9 13 6 13 -3 0 -9 -5 -12 -10z" />
            <path d="M14655 26081 c-7 -12 12 -24 24 -17 5 3 14 0 20 -8 9 -10 7 -17 -6
-27 -15 -12 -16 -12 -10 3 4 12 2 15 -9 11 -15 -5 -20 -22 -6 -24 4 0 17 -2
30 -3 12 -2 22 1 22 6 0 5 5 6 10 3 6 -4 8 -11 5 -16 -4 -5 0 -9 7 -9 11 0 10
-2 0 -9 -11 -7 -10 -9 3 -15 9 -3 13 -11 9 -17 -4 -8 -9 -7 -16 2 -8 11 -9 11
-5 -1 2 -8 10 -14 16 -12 6 1 11 -3 11 -10 0 -9 3 -9 12 0 9 9 7 16 -7 32 -11
12 -14 20 -6 20 7 0 -9 23 -35 50 -48 51 -58 57 -69 41z" />
            <path d="M15874 26031 c11 -12 14 -21 7 -26 -6 -3 -11 -2 -11 4 0 6 -10 16
-22 22 -18 9 -16 4 10 -23 18 -19 30 -37 27 -40 -3 -3 0 -8 7 -11 8 -3 14 2
14 13 0 10 -4 16 -9 13 -5 -3 -6 4 -3 15 4 12 1 26 -9 36 -23 23 -32 20 -11
-3z" />
            <path d="M12732 25970 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z" />
            <path d="M11305 25972 c-6 -4 -11 -16 -13 -27 l-4 -20 17 20 c14 17 16 17 11
3 -3 -10 -1 -18 4 -18 6 0 10 6 10 13 0 20 -15 37 -25 29z" />
            <path d="M13629 25956 c7 -8 14 -27 17 -43 4 -22 3 -26 -7 -15 -18 17 -34 15
-19 -3 6 -8 9 -19 6 -24 -4 -5 -2 -12 3 -15 5 -3 7 -15 4 -25 -3 -10 0 -22 5
-25 6 -4 8 2 5 14 -4 14 -1 20 8 18 19 -4 14 99 -6 118 -18 18 -31 18 -16 0z
m31 -98 c0 -19 -2 -20 -10 -8 -13 19 -13 30 0 30 6 0 10 -10 10 -22z" />
            <path d="M11340 25946 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z" />
            <path d="M13610 25935 c7 -9 15 -13 17 -11 7 7 -7 26 -19 26 -6 0 -6 -6 2 -15z" />
            <path d="M11430 25864 c0 -10 3 -14 6 -11 4 3 16 2 27 -4 14 -8 18 -8 13 0 -4
6 -16 16 -27 21 -15 9 -19 7 -19 -6z" />
            <path d="M11240 25825 c0 -6 5 -16 12 -23 9 -9 8 -12 -7 -12 -19 -1 -19 -1 -2
-14 11 -9 14 -16 7 -19 -6 -3 -6 -5 1 -3 17 3 18 43 3 64 -7 9 -14 12 -14 7z" />
            <path d="M11490 25820 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2
0 -4 -4 -4 -10z" />
            <path d="M14890 25780 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2
0 -4 -4 -4 -10z" />
            <path d="M14973 25707 c-1 -11 -1 -18 0 -16 2 2 8 11 15 20 9 12 9 16 -1 16
-7 0 -14 -9 -14 -20z" />
            <path d="M10358 25664 c-8 -12 26 -54 44 -54 8 0 5 7 -8 18 -12 9 -24 24 -26
32 -2 7 -7 10 -10 4z" />
            <path d="M10380 25661 c0 -6 4 -13 10 -16 6 -3 7 1 4 9 -7 18 -14 21 -14 7z" />
            <path d="M11296 25655 c-3 -8 -1 -15 4 -15 6 0 10 7 10 15 0 8 -2 15 -4 15 -2
0 -6 -7 -10 -15z" />
            <path d="M15055 25625 c5 -40 15 -63 32 -70 15 -5 15 -4 -1 14 -11 12 -14 21
-7 26 6 3 11 2 11 -4 0 -6 10 -16 23 -22 19 -10 19 -8 -5 18 -16 16 -28 34
-28 40 0 6 -6 13 -14 16 -9 4 -12 -2 -11 -18z" />
            <path d="M16215 25582 c27 -28 51 -52 52 -52 7 0 2 10 -14 26 -17 17 -17 17 5
12 30 -8 28 4 -3 19 -18 8 -25 8 -25 0 0 -6 -15 2 -32 19 -18 16 -11 5 17 -24z" />
            <path d="M11330 25575 c-8 -9 -8 -15 -2 -15 12 0 26 19 19 26 -2 2 -10 -2 -17
-11z" />
            <path d="M10414 25557 c1 -13 5 -28 9 -34 3 -5 3 -13 -1 -17 -4 -4 -7 -18 -7
-32 l-1 -24 23 22 c24 23 28 29 16 27 -20 -3 -26 2 -13 11 9 6 11 10 3 10 -7
0 -10 6 -7 14 3 8 0 17 -5 21 -6 3 -9 11 -6 16 4 5 2 9 -4 9 -5 0 -9 -10 -7
-23z" />
            <path d="M15136 25542 c-3 -6 -2 -13 3 -16 4 -3 11 -19 14 -36 4 -16 12 -30
19 -30 9 0 9 3 0 12 -7 7 -12 21 -12 33 0 27 -15 50 -24 37z" />
            <path d="M16280 25540 c0 -17 17 -47 23 -40 2 3 -2 16 -10 30 -7 14 -13 18
-13 10z" />
            <path d="M13715 25509 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z" />
            <path d="M13734 25488 c-10 -30 -12 -60 -7 -75 6 -14 23 -8 23 8 0 6 -4 7 -10
4 -12 -7 -14 8 -3 19 7 7 14 66 8 66 -2 0 -7 -10 -11 -22z" />
            <path d="M16317 25486 c-3 -8 5 -24 19 -37 13 -12 24 -28 24 -35 0 -6 7 -15
16 -18 15 -6 15 -4 1 18 -10 15 -12 26 -6 28 14 5 1 35 -19 43 -14 5 -14 4 1
-13 9 -10 14 -21 11 -24 -9 -10 -41 26 -36 40 6 17 -5 15 -11 -2z" />
            <path d="M11360 25475 c0 -5 5 -17 10 -25 5 -8 10 -10 10 -5 0 6 -5 17 -10 25
-5 8 -10 11 -10 5z" />
            <path d="M15210 25401 c0 -6 4 -13 10 -16 6 -3 7 1 4 9 -7 18 -14 21 -14 7z" />
            <path d="M16453 25332 c-8 -14 -8 -19 1 -19 7 0 13 9 13 19 1 10 1 18 0 18 -1
0 -7 -8 -14 -18z" />
            <path d="M12115 25309 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z" />
            <path d="M15293 25306 c0 -6 18 -11 39 -12 27 -1 36 2 29 9 -14 14 -68 16 -68
3z" />
            <path d="M10496 25294 c-8 -21 -8 -39 1 -30 8 7 15 46 9 46 -2 0 -7 -7 -10
-16z" />
            <path d="M11463 25263 c-10 -3 -9 -12 4 -36 10 -18 16 -36 15 -40 -1 -5 3 -5
9 -1 9 5 10 2 5 -12 -6 -15 -5 -16 5 -7 10 10 9 17 -7 34 -17 19 -18 21 -1 30
9 6 17 15 17 20 0 6 -8 3 -17 -7 -12 -12 -18 -13 -21 -4 -3 7 2 16 9 21 15 9
2 11 -18 2z" />
            <path d="M12184 25263 c4 -8 2 -13 -4 -13 -6 0 -8 -5 -5 -11 4 -6 13 -8 21 -5
8 3 13 0 11 -6 -1 -6 4 -13 11 -15 18 -7 0 28 -24 48 -12 9 -15 9 -10 2z" />
            <path d="M15371 25224 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
            <path d="M13800 25169 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0
-10 -5 -10 -11z" />
            <path d="M13796 25125 c4 -8 10 -15 15 -15 4 0 6 7 3 15 -4 8 -10 15 -15 15
-4 0 -6 -7 -3 -15z" />
            <path d="M12330 25106 c0 -18 27 -46 43 -46 7 0 1 9 -12 19 -13 10 -21 23 -17
30 4 6 2 11 -3 11 -6 0 -11 -6 -11 -14z" />
            <path d="M13806 25080 c7 -28 12 -35 18 -29 2 2 -2 15 -10 29 l-15 25 7 -25z" />
            <path d="M16696 25087 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 -10 9 -11 8 -5 -6z" />
            <path d="M12450 25036 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21
-21 13z" />
            <path d="M12410 25015 c0 -18 19 -40 26 -32 4 4 0 15 -9 24 -9 10 -17 13 -17
8z" />
            <path d="M12490 24996 c0 -19 13 -27 28 -17 8 4 5 11 -8 19 -16 10 -20 10 -20
-2z" />
            <path d="M15617 24968 c4 -7 8 -16 8 -20 0 -13 15 -21 15 -8 0 9 6 7 19 -6 10
-10 16 -25 13 -31 -2 -7 1 -13 6 -13 25 0 19 20 -18 54 -40 36 -58 46 -43 24z" />
            <path d="M10661 24928 c-1 -31 10 -36 29 -13 9 10 9 15 1 15 -6 0 -11 -6 -12
-12 -1 -7 -5 -2 -9 12 l-8 25 -1 -27z" />
            <path d="M13861 24938 c-1 -10 -5 -18 -11 -18 -5 0 -7 -7 -3 -17 5 -14 8 -12
15 7 5 13 7 28 4 34 -2 6 -5 3 -5 -6z" />
            <path d="M16834 24911 c10 -11 13 -22 8 -25 -6 -3 -12 1 -16 9 -3 8 -10 15
-17 15 -6 0 0 -13 13 -29 12 -15 29 -37 36 -47 11 -17 11 -17 2 3 -12 27 -13
36 -1 29 11 -7 2 34 -12 52 -5 6 -14 12 -20 12 -5 0 -2 -9 7 -19z" />
            <path d="M13872 24884 c-7 -8 -11 -20 -9 -27 4 -9 6 -9 6 1 1 6 6 12 12 12 5
0 7 -5 4 -10 -14 -22 -12 -77 2 -77 16 0 17 9 1 25 -8 8 -8 15 1 26 9 10 10
24 4 40 -7 21 -10 22 -21 10z" />
            <path d="M13890 24820 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5
0 -10 -4 -10 -10z" />
            <path d="M10740 24781 c0 -5 7 -15 17 -22 15 -13 15 -12 4 9 -12 23 -21 28
-21 13z" />
            <path d="M15793 24764 c-6 -17 15 -36 31 -27 5 3 15 -2 23 -12 7 -11 13 -14
13 -8 0 15 -25 34 -41 31 -8 -2 -13 5 -13 15 2 21 -5 22 -13 1z" />
            <path d="M16974 24698 c3 -15 26 -23 26 -8 0 6 -4 9 -9 6 -5 -4 -12 -1 -14 4
-3 6 -4 5 -3 -2z" />
            <path d="M15856 24684 c-9 -25 4 -37 19 -17 13 18 11 33 -5 33 -4 0 -11 -7
-14 -16z" />
            <path d="M12926 24636 c10 -8 15 -18 11 -22 -4 -5 -12 -2 -17 6 -5 8 -12 11
-16 7 -4 -3 -1 -14 6 -22 9 -11 16 -13 24 -5 15 15 26 12 19 -7 -5 -12 -2 -15
10 -10 9 3 19 2 22 -3 8 -13 25 -13 25 1 0 5 -4 8 -8 5 -5 -3 -24 10 -42 29
-19 19 -38 35 -44 35 -5 0 -1 -6 10 -14z" />
            <path d="M11741 24560 c6 -16 15 -28 19 -25 5 3 16 -7 24 -22 8 -15 15 -21 15
-13 0 19 -23 50 -38 50 -6 0 -11 9 -11 20 0 11 -4 20 -9 20 -6 0 -6 -12 0 -30z" />
            <path d="M13930 24548 c0 -9 5 -20 10 -23 13 -8 13 5 0 25 -8 13 -10 13 -10
-2z" />
            <path d="M13976 24541 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 -4 11 -6 11 -12
0z" />
            <path d="M17236 24449 c9 -10 21 -16 26 -13 15 10 3 27 -20 29 -21 2 -21 1 -6
-16z" />
            <path d="M13054 24415 c-4 -16 0 -35 10 -50 16 -24 36 -26 36 -4 0 6 -4 8 -9
4 -14 -8 -33 32 -26 55 4 11 4 20 1 20 -3 0 -8 -11 -12 -25z" />
            <path d="M13076 24397 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 -10 9 -11 8 -5 -6z" />
            <path d="M17308 24383 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
            <path d="M17335 24371 c-3 -5 -1 -12 5 -16 5 -3 10 1 10 9 0 18 -6 21 -15 7z" />
            <path d="M13090 24316 c0 -8 4 -17 9 -21 6 -3 9 4 8 15 -1 23 -17 28 -17 6z" />
            <path d="M11877 24296 c-4 -10 -1 -13 9 -9 7 3 14 9 14 14 0 14 -17 10 -23 -5z" />
            <path d="M13071 24300 c-1 -18 16 -46 23 -40 2 3 -2 16 -9 30 -8 15 -14 19
-14 10z" />
            <path d="M10979 24238 c-1 -2 -3 -13 -4 -25 -1 -14 2 -20 11 -16 8 3 14 9 14
15 0 5 -5 6 -12 2 -8 -5 -9 -2 -5 9 5 13 1 25 -4 15z" />
            <path d="M16273 24204 c-6 -17 15 -37 29 -27 5 2 17 -1 26 -9 15 -12 16 -11 3
5 -7 9 -21 16 -30 15 -10 -2 -15 4 -15 15 2 21 -5 22 -13 1z" />
            <path d="M13140 24024 c0 -8 5 -12 10 -9 6 3 10 10 10 16 0 5 -4 9 -10 9 -5 0
-10 -7 -10 -16z" />
            <path d="M12035 23960 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z" />
            <path d="M17650 23910 c0 -5 7 -10 15 -10 8 0 14 -3 13 -7 -4 -15 22 -52 42
-58 11 -4 18 -10 15 -15 -3 -4 2 -10 10 -13 10 -4 12 -1 9 11 -5 14 -3 13 10
-2 9 -11 16 -14 16 -8 0 7 5 12 11 12 6 0 9 -7 6 -14 -4 -10 -1 -13 8 -10 7 3
15 -2 18 -13 5 -17 5 -17 6 0 1 26 -22 46 -58 49 -33 2 -42 15 -36 50 4 21 1
24 -23 23 -15 -1 -35 3 -44 7 -11 5 -18 5 -18 -2z m71 -38 c-9 -3 -9 -7 0 -18
21 -26 3 -14 -22 13 -16 18 -17 22 -5 13 18 -14 19 -13 13 4 -7 16 -5 17 9 5
11 -9 12 -15 5 -17z" />
            <path d="M11146 23812 c-3 -6 -1 -14 4 -17 8 -5 6 -13 -6 -26 -19 -21 -8 -25
17 -6 12 10 13 16 3 35 -6 13 -15 19 -18 14z" />
            <path d="M17760 23786 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21
-21 13z" />
            <path d="M11152 23747 c-9 -10 -9 -21 -2 -38 10 -22 10 -22 5 6 l-6 30 19 -24
c17 -21 20 -21 25 -6 4 9 2 24 -3 33 -9 14 -10 13 -5 -5 8 -29 -3 -30 -13 -2
-7 18 -10 19 -20 6z" />
            <path d="M12096 23733 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z" />
            <path d="M11219 23638 c-1 -2 -2 -19 -3 -39 -2 -28 1 -36 13 -34 18 2 28 25
11 25 -10 0 -9 4 0 15 11 13 10 14 -6 8 -17 -6 -17 -5 -5 10 9 11 10 17 2 17
-6 0 -11 -1 -12 -2z" />
            <path d="M12166 23558 c5 -44 12 -46 12 -4 0 20 -3 36 -8 36 -4 0 -6 -14 -4
-32z" />
            <path d="M13296 23562 c-3 -6 -1 -14 5 -17 14 -9 20 -2 10 14 -6 8 -11 9 -15
3z" />
            <path d="M12214 23532 c4 -12 2 -23 -5 -28 -8 -4 -9 -3 -5 4 4 7 2 12 -4 12
-8 0 -9 -7 -5 -17 4 -10 7 -26 7 -35 0 -10 3 -20 9 -23 5 -4 6 2 2 11 -4 10
-2 15 3 11 6 -3 10 11 11 33 1 22 -3 42 -9 46 -6 4 -8 -1 -4 -14z" />
            <path d="M14187 23500 c3 -11 9 -20 15 -20 5 0 4 9 -2 20 -6 11 -12 20 -14 20
-2 0 -2 -9 1 -20z" />
            <path d="M14196 23461 c-4 -5 -2 -20 3 -33 8 -21 9 -20 9 10 -1 34 -3 38 -12
23z" />
            <path d="M11299 23448 c-6 -18 -4 -50 4 -60 6 -9 7 -8 3 5 -4 12 0 17 12 17
15 1 15 2 -2 11 -15 9 -16 13 -5 20 10 6 11 9 2 9 -7 0 -13 -1 -14 -2z" />
            <path d="M13294 23399 c-3 -6 -1 -16 5 -22 8 -8 11 -5 11 11 0 24 -5 28 -16
11z" />
            <path d="M14221 23364 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
            <path d="M18125 23370 c-7 -12 2 -30 16 -30 5 0 9 5 9 11 0 5 -4 7 -10 4 -5
-3 -10 -2 -10 4 0 5 6 12 13 14 9 4 9 6 0 6 -6 1 -15 -3 -18 -9z" />
            <path d="M12270 23315 c1 -26 2 -28 11 -12 8 14 8 22 0 30 -8 8 -11 3 -11 -18z" />
            <path d="M11370 23260 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5
0 -10 -4 -10 -10z" />
            <path d="M14290 23256 c0 -9 5 -16 10 -16 6 0 10 4 10 9 0 6 -4 13 -10 16 -5
3 -10 -1 -10 -9z" />
            <path d="M13383 23220 c-8 -21 -8 -28 0 -23 6 4 8 0 4 -10 -4 -10 -2 -14 7
-11 7 3 13 11 13 19 0 8 -4 12 -9 9 -5 -3 -6 6 -2 20 9 35 1 33 -13 -4z" />
            <path d="M14262 23180 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z" />
            <path d="M14280 23176 c0 -8 -3 -21 -6 -30 -3 -9 -2 -16 3 -16 5 0 10 14 11
30 1 17 0 30 -3 30 -3 0 -5 -6 -5 -14z" />
            <path d="M12356 23144 c-4 -10 -1 -14 6 -12 15 5 23 28 10 28 -5 0 -13 -7 -16
-16z" />
            <path d="M12357 23113 c-3 -5 -1 -17 4 -28 6 -17 7 -16 6 8 -1 28 -3 32 -10
20z" />
            <path d="M13371 23104 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
            <path d="M17237 23093 c-10 -10 -8 -33 3 -33 5 0 6 7 3 17 -5 12 -3 14 7 8 9
-5 11 -4 6 3 -8 14 -10 14 -19 5z" />
            <path d="M13376 23071 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 -4 11 -6 11 -12
0z" />
            <path d="M21508 23011 c8 -9 31 -16 51 -17 l36 -1 -29 12 c-17 7 -24 14 -16
17 7 2 -3 4 -22 4 -32 0 -34 -2 -20 -15z" />
            <path d="M21619 23020 c-7 -5 -19 -6 -28 -3 -9 2 -3 -3 12 -12 19 -11 36 -14
52 -8 l24 8 -23 11 c-14 6 -30 8 -37 4z m28 -16 c-3 -3 -12 -4 -19 -1 -8 3 -5
6 6 6 11 1 17 -2 13 -5z" />
            <path d="M21678 23023 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
            <path d="M13454 22966 c3 -16 10 -34 16 -40 8 -8 10 -3 4 19 -9 41 -27 59 -20
21z" />
            <path d="M18455 22981 c-3 -5 -2 -12 3 -15 5 -3 9 1 9 9 0 17 -3 19 -12 6z" />
            <path d="M21710 22980 c-8 -5 -10 -10 -5 -10 6 0 17 5 25 10 8 5 11 10 5 10
-5 0 -17 -5 -25 -10z" />
            <path d="M21306 22920 c-18 -32 -7 -41 24 -19 13 9 12 10 -2 5 -11 -3 -15 -1
-12 8 3 7 17 12 32 10 17 -2 20 -1 7 3 -11 3 -22 9 -25 14 -3 5 -14 -5 -24
-21z" />
            <path d="M21762 22944 c4 -3 13 -6 21 -8 8 -1 22 -8 31 -15 12 -10 16 -10 16
-1 0 6 6 10 13 8 6 -3 12 -11 12 -19 0 -8 7 -14 15 -15 24 -1 33 8 19 17 -12
7 -11 9 0 9 11 0 9 4 -5 15 -22 17 -54 20 -54 5 0 -7 -6 -7 -19 0 -21 11 -58
14 -49 4z m113 -14 c3 -5 2 -10 -4 -10 -5 0 -13 5 -16 10 -3 6 -2 10 4 10 5 0
13 -4 16 -10z" />
            <path d="M14350 22894 c-7 -19 -7 -31 1 -40 6 -8 9 -21 5 -29 -8 -22 7 -19 21
4 11 17 9 21 -7 26 -22 7 -23 10 -10 40 8 19 9 19 9 -3 1 -13 5 -21 10 -18 11
7 2 46 -10 46 -5 0 -14 -12 -19 -26z" />
            <path d="M17375 22910 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3
0 -8 -4 -11 -10z" />
            <path d="M11540 22861 c0 -5 7 -11 14 -14 10 -4 13 -1 9 9 -6 15 -23 19 -23 5z" />
            <path d="M21240 22856 c4 -4 16 -4 26 2 16 9 14 10 -8 9 -15 -1 -23 -6 -18
-11z" />
            <path d="M21940 22863 c0 -19 14 -22 35 -7 10 8 14 14 8 14 -7 0 -15 -5 -19
-11 -4 -8 -9 -7 -15 2 -6 10 -9 10 -9 2z" />
            <path d="M17456 22836 c3 -9 11 -16 16 -16 13 0 5 23 -10 28 -7 2 -10 -2 -6
-12z" />
            <path d="M11540 22820 c0 -5 5 -10 11 -10 6 0 19 -10 30 -22 19 -23 19 -23 3
10 -14 30 -44 45 -44 22z" />
            <path d="M22010 22816 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z" />
            <path d="M21150 22778 c0 -4 4 -8 9 -8 6 0 12 4 15 8 3 5 -1 9 -9 9 -8 0 -15
-4 -15 -9z" />
            <path d="M22092 22781 c-11 -7 -10 -9 3 -14 9 -4 14 -3 10 2 -3 5 1 12 7 14 7
3 9 6 3 6 -5 0 -16 -3 -23 -8z" />
            <path d="M22146 22775 c5 -11 2 -13 -10 -9 -9 3 -16 1 -16 -6 0 -6 4 -9 9 -6
5 4 12 0 14 -6 4 -10 6 -10 6 0 1 6 9 12 19 12 13 0 14 -2 4 -9 -10 -6 -9 -11
4 -22 14 -11 16 -11 10 0 -5 8 -4 16 4 21 9 6 4 13 -18 24 -28 14 -31 14 -26
1z" />
            <path d="M13531 22694 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
            <path d="M20987 22703 c9 -3 10 -10 3 -23 -13 -24 0 -32 30 -17 38 19 33 42
-9 43 -20 1 -30 -1 -24 -3z m41 -28 c-3 -3 -9 2 -12 12 -6 14 -5 15 5 6 7 -7
10 -15 7 -18z" />
            <path d="M20966 22634 c-3 -8 -10 -13 -15 -9 -5 3 -12 1 -16 -5 -9 -15 18 -12
40 3 11 9 14 16 8 20 -6 4 -14 0 -17 -9z" />
            <path d="M13543 22578 c-1 -24 2 -36 7 -28 6 10 9 10 13 -1 2 -8 3 -5 1 6 -2
11 -4 30 -4 43 0 40 -15 23 -17 -20z" />
            <path d="M12590 22540 c6 -11 16 -20 22 -20 6 0 3 9 -7 20 -10 11 -20 20 -22
20 -2 0 1 -9 7 -20z" />
            <path d="M13566 22503 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z" />
            <path d="M12605 22500 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z" />
            <path d="M22710 22460 c0 -5 14 -17 30 -25 17 -9 28 -20 25 -25 -3 -6 1 -7 9
-4 9 3 16 12 16 20 0 9 -6 11 -17 7 -16 -6 -16 -5 -4 10 7 9 10 17 5 18 -5 0
-21 2 -36 4 -16 2 -28 0 -28 -5z m50 -10 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4
10 3 6 8 10 11 10 2 0 4 -4 4 -10z" />
            <path d="M13610 22381 c0 -6 4 -13 10 -16 6 -3 7 1 4 9 -7 18 -14 21 -14 7z" />
            <path d="M20570 22382 c0 -5 7 -13 16 -16 8 -3 13 -10 9 -15 -3 -6 3 -9 14 -8
16 1 17 4 8 25 -6 13 -13 19 -15 13 -3 -9 -7 -9 -18 0 -8 7 -14 7 -14 1z" />
            <path d="M22845 22380 c-3 -6 1 -7 9 -4 18 7 21 14 7 14 -6 0 -13 -4 -16 -10z" />
            <path d="M22865 22368 c-11 -12 -14 -18 -7 -14 6 4 12 4 12 -1 0 -4 11 -13 25
-20 14 -6 23 -7 19 -2 -3 5 1 9 8 9 8 1 4 8 -9 19 -12 10 -19 21 -16 25 4 3 2
6 -3 5 -5 0 -18 -10 -29 -21z m40 -18 c3 -6 -1 -7 -9 -4 -18 7 -21 14 -7 14 6
0 13 -4 16 -10z" />
            <path d="M17853 22345 c0 -6 7 -11 16 -10 9 0 16 5 16 10 0 5 -7 10 -16 10 -9
1 -16 -4 -16 -10z" />
            <path d="M20571 22333 c-1 -17 16 -25 25 -12 4 7 3 9 -4 5 -5 -3 -13 0 -15 6
-4 9 -6 10 -6 1z" />
            <path d="M11773 22315 c0 -8 4 -12 9 -9 4 3 8 9 8 15 0 5 -4 9 -8 9 -5 0 -9
-7 -9 -15z" />
            <path d="M22935 22320 c-3 -5 7 -16 23 -24 24 -12 29 -13 29 -1 0 8 -4 12 -8
10 -11 -8 -40 6 -33 16 3 5 3 9 0 9 -3 0 -7 -4 -11 -10z" />
            <path d="M12691 22303 c7 -12 15 -20 18 -17 3 2 -3 12 -13 22 -17 16 -18 16
-5 -5z" />
            <path d="M23009 22308 c-9 -32 -5 -41 26 -47 6 -2 17 -9 26 -17 14 -12 16 -12
16 1 0 8 -6 15 -13 15 -7 0 -15 6 -17 13 -4 9 -6 9 -6 -1 -1 -7 -6 -10 -11 -7
-8 5 -7 11 1 21 10 12 9 13 -6 8 -13 -5 -16 -4 -11 4 4 7 5 12 2 12 -3 0 -6
-1 -7 -2z" />
            <path d="M23095 22250 c-4 -6 -3 -16 3 -22 13 -13 23 4 13 21 -7 10 -10 10
-16 1z" />
            <path d="M12755 22179 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z" />
            <path d="M12774 22135 c4 -52 16 -95 27 -95 12 0 11 3 -6 36 -8 16 -12 37 -9
46 3 10 1 25 -4 35 -8 13 -9 7 -8 -22z" />
            <path d="M18050 22150 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2
0 -4 -4 -4 -10z" />
            <path d="M14526 22107 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 -10 9 -11 8 -5 -6z" />
            <path d="M12825 22050 c-3 -11 -11 -20 -16 -20 -6 0 -7 -6 -2 -12 4 -7 8 -19
9 -26 2 -8 6 -17 10 -20 4 -4 4 1 1 11 -4 10 -2 17 6 17 7 0 13 -11 13 -25 1
-14 -2 -22 -8 -19 -6 4 -4 -4 3 -17 7 -13 12 -28 11 -32 -1 -5 2 -6 7 -3 10 6
8 40 -4 69 -4 10 -3 17 4 17 6 0 11 8 11 18 0 11 -3 13 -8 5 -5 -8 -11 -3 -19
13 -11 23 -10 24 10 18 19 -6 20 -5 8 10 -18 21 -28 20 -36 -4z m15 -30 c0 -5
-4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z" />
            <path d="M19321 21986 c-9 -10 -8 -16 4 -26 19 -16 32 -5 17 13 -7 8 -9 17 -5
20 3 4 4 7 1 7 -3 0 -11 -6 -17 -14z" />
            <path d="M21810 21979 c0 -5 6 -9 13 -9 8 0 7 -4 -3 -10 -10 -6 -11 -10 -2
-10 6 0 12 -5 12 -11 0 -5 -4 -8 -8 -5 -5 3 -6 -9 -3 -27 5 -32 6 -32 9 -4 2
15 8 27 14 27 6 0 6 6 -2 15 -10 12 -10 15 0 15 7 0 18 -7 25 -17 9 -13 14
-13 20 -3 5 9 4 11 -3 6 -7 -4 -12 -3 -12 3 0 5 -13 16 -30 25 -16 8 -30 10
-30 5z" />
            <path d="M13691 21954 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
            <path d="M14560 21965 c0 -5 5 -17 10 -25 5 -8 10 -10 10 -5 0 6 -5 17 -10 25
-5 8 -10 11 -10 5z" />
            <path d="M18173 21949 c-2 -23 3 -25 10 -4 4 8 3 16 -1 19 -4 3 -9 -4 -9 -15z" />
            <path d="M13730 21942 c0 -6 -8 -13 -17 -15 -16 -4 -16 -5 -1 -6 18 -1 34 16
24 26 -3 4 -6 1 -6 -5z" />
            <path d="M11937 21933 c-4 -3 -7 -13 -7 -22 1 -13 3 -13 11 2 11 19 8 33 -4
20z" />
            <path d="M20090 21929 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0
-10 -5 -10 -11z" />
            <path d="M21897 21933 c-4 -3 -17 -7 -29 -8 -19 -1 -24 -10 -31 -51 -4 -27 -5
-78 -3 -114 3 -50 2 -57 -4 -30 -7 29 -8 27 -4 -15 12 -114 8 -1005 -3 -1005
-5 0 -7 170 -5 377 1 208 -1 416 -5 463 -5 52 -8 -107 -8 -403 0 -298 4 -480
9 -467 12 26 26 26 21 0 -2 -11 0 -18 5 -15 5 3 7 17 4 31 -5 25 -3 26 33 21
21 -3 52 -8 68 -11 20 -4 24 -4 11 2 -13 6 4 9 50 10 43 0 62 -2 49 -7 -11 -5
-25 -6 -32 -4 -6 3 -19 1 -28 -5 -12 -6 -6 -8 22 -6 21 1 46 2 56 3 11 1 15 5
11 13 -5 8 -2 9 12 3 10 -4 33 -9 49 -11 l30 -3 -30 8 c-29 8 -28 9 13 10 25
1 42 -4 42 -10 0 -7 8 -9 23 -4 21 7 54 10 162 16 27 1 44 4 37 7 -30 10 2 18
45 12 54 -9 73 -4 73 16 0 10 7 13 24 8 13 -3 27 -1 30 5 7 10 -24 17 -49 11
-5 -1 -26 -4 -45 -5 -19 -2 -48 -8 -63 -14 -34 -13 -37 -13 -37 -2 0 5 17 14
38 20 l37 11 -40 -5 c-27 -3 -35 -1 -25 5 24 15 189 10 205 -6 7 -8 29 -13 47
-13 27 1 29 3 8 6 -44 8 -42 19 4 19 38 -1 43 -3 35 -18 -5 -9 -14 -19 -21
-21 -9 -4 -9 -6 -1 -6 6 -1 18 5 25 13 9 8 33 13 63 11 47 -2 48 -1 22 10 -25
10 -21 11 35 9 35 -2 72 -6 82 -10 15 -6 17 -4 11 6 -6 10 -4 11 11 5 10 -4
33 -9 49 -11 l30 -3 -30 9 c-23 7 -13 8 45 8 141 -2 182 1 174 13 -5 8 4 10
32 6 l39 -6 -40 14 c-25 8 -33 14 -20 15 19 1 19 2 1 9 -19 7 -19 8 0 15 10 4
57 9 104 11 76 4 83 2 66 -11 -19 -14 -19 -14 1 -14 11 0 23 6 26 14 5 11 27
14 106 11 56 -1 101 0 101 4 0 8 64 6 78 -3 7 -4 12 -3 12 1 0 5 9 9 20 10 11
1 18 -2 15 -7 -3 -4 1 -10 8 -13 8 -3 14 1 14 9 0 8 -4 14 -9 14 -5 0 -6 6 -2
13 4 6 4 9 0 5 -5 -4 -17 2 -29 12 -11 11 -23 18 -26 15 -2 -3 4 -12 15 -19
10 -8 16 -18 11 -22 -4 -4 -7 -2 -7 4 0 7 -5 12 -11 12 -5 0 -7 -5 -3 -12 4
-7 3 -8 -5 -4 -6 4 -9 12 -6 17 4 5 1 9 -5 9 -6 0 -8 5 -5 10 3 6 1 10 -5 10
-7 0 -20 10 -30 23 -15 18 -21 20 -32 9 -11 -11 -10 -12 2 -7 11 4 16 -1 16
-15 0 -26 -7 -25 -30 7 -11 14 -14 23 -8 19 7 -4 12 -2 12 3 0 6 -6 11 -14 11
-8 0 -17 7 -20 16 -6 16 -8 16 -24 0 -23 -20 -29 -20 -29 -1 0 8 7 15 16 16 9
0 1 6 -17 13 -18 6 -30 15 -25 20 4 5 -2 4 -13 -2 -18 -9 -25 -8 -35 5 -10 12
-10 15 -1 9 17 -10 15 -1 -4 15 -14 11 -16 10 -12 -11 3 -21 -1 -24 -33 -25
-44 -2 -48 20 -7 34 17 6 27 15 23 21 -5 8 -14 6 -30 -4 -22 -15 -24 -14 -20
4 3 14 -5 25 -28 36 -18 9 -34 20 -37 25 -3 5 -11 -1 -18 -11 -7 -12 -8 -20
-2 -20 5 0 10 6 10 13 0 8 3 9 8 1 4 -6 14 -13 22 -16 8 -3 -14 -5 -49 -4 -35
1 -66 6 -68 11 -2 6 6 21 18 34 11 12 18 25 15 28 -3 4 -6 2 -6 -2 0 -5 -7 0
-16 11 -8 10 -13 14 -10 7 7 -14 -148 -13 -161 1 -5 5 8 5 27 2 l35 -7 -25 20
c-14 11 -20 21 -13 21 6 0 11 9 10 21 -1 18 -3 18 -13 5 -17 -24 -26 -19 -13
7 11 20 11 21 -4 8 -11 -9 -17 -10 -17 -2 0 6 -7 11 -15 11 -8 0 -12 6 -9 14
7 19 -8 27 -26 14 -12 -8 -12 -9 3 -4 11 3 16 2 12 -4 -7 -12 -35 -13 -35 -2
0 4 3 12 8 18 4 5 6 8 3 7 -2 -1 -10 4 -17 12 -8 8 -22 11 -32 8 -9 -3 -14 -2
-10 2 5 5 -6 17 -23 27 -25 15 -34 17 -40 7 -9 -15 -3 -29 14 -29 7 0 6 4 -3
11 -10 7 -11 9 -2 7 8 -2 12 -8 10 -13 -4 -12 -56 -16 -64 -4 -3 5 3 6 13 3
16 -5 16 -4 3 8 -17 14 -20 32 -7 44 4 4 7 -3 7 -16 0 -19 3 -21 12 -12 20 20
-14 44 -65 43 -9 0 -15 4 -12 10 4 5 1 9 -5 9 -6 0 -9 4 -6 8 2 4 -5 9 -16 9
-11 1 -25 8 -31 15 -7 8 -21 21 -32 28 -18 13 -19 13 -9 0 13 -20 12 -42 -3
-47 -8 -3 -11 3 -8 16 2 11 -1 23 -6 26 -5 4 -6 11 -3 17 4 7 2 8 -4 4 -7 -4
-12 -1 -12 6 0 9 -3 9 -10 -2 -5 -8 -22 -16 -37 -17 -19 -2 -24 1 -14 6 8 5
21 7 30 3 22 -7 11 3 -19 18 -14 7 -22 9 -18 5 11 -11 -22 -34 -49 -35 -21 0
-25 13 -10 38 5 7 4 12 -2 12 -6 0 -11 5 -11 10 0 6 9 10 20 10 11 0 20 -4 20
-10 0 -5 10 -10 23 -10 17 0 13 5 -21 25 -35 20 -48 23 -64 14 -18 -10 -19 -9
-7 13 6 13 8 20 4 15 -5 -4 -16 0 -26 9 -17 15 -18 15 -16 -5 1 -12 5 -21 10
-21 4 0 6 -4 3 -8 -8 -13 -71 -13 -90 1 -14 11 -16 19 -9 47 8 31 10 33 15 13
3 -13 2 -23 -3 -23 -5 0 -9 -8 -9 -17 0 -12 3 -14 11 -6 6 6 20 8 32 5 21 -6
21 -6 -3 -13 l-25 -7 26 -1 c35 -1 46 25 13 33 -22 6 -26 12 -23 32 2 14 -26
22 -38 12 -10 -8 -13 -6 -13 5 0 11 -5 14 -17 10 -12 -5 -15 -2 -11 8 4 10 -1
15 -15 17 -17 1 -19 -1 -7 -8 9 -6 10 -10 3 -10 -17 0 -25 17 -12 26 16 9 -30
34 -50 26 -10 -4 -12 -1 -6 9 7 11 5 11 -10 -1 -17 -14 -18 -13 -11 5 4 11 4
16 0 12 -4 -4 -16 0 -26 10 -10 9 -18 12 -18 8 0 -5 -7 0 -15 11 -8 11 -15 14
-15 7 0 -7 -4 -13 -9 -13 -5 0 -8 8 -7 18 0 9 -5 16 -11 15 -7 -2 -13 2 -13 8
0 8 -4 8 -12 2 -7 -6 -29 -10 -48 -10 -19 0 -29 2 -23 4 10 4 9 8 -2 20 -20
20 -19 34 3 27 13 -5 14 -4 3 4 -10 7 -20 6 -33 -1 -17 -11 -17 -12 0 -19 14
-6 14 -7 -5 -7 -16 -1 -21 4 -20 18 0 11 4 18 8 15 4 -2 11 0 14 6 4 6 3 10
-2 9 -22 -4 -43 2 -38 11 7 11 2 13 -8 3z m53 -83 c8 -5 11 -10 5 -10 -5 0
-17 5 -25 10 -8 5 -10 10 -5 10 6 0 17 -5 25 -10z m183 -64 c9 0 18 -5 20 -11
3 -12 -61 -16 -69 -4 -3 5 3 6 13 3 16 -5 15 -3 -2 12 l-20 17 20 -8 c11 -4
28 -8 38 -9z m157 -96 c13 -9 13 -10 0 -9 -8 0 -24 4 -35 9 l-20 9 20 0 c11 0
27 -4 35 -9z m443 -230 c5 -4 4 -11 -2 -14 -6 -4 -11 -2 -11 4 0 6 -5 8 -12 4
-17 -11 -68 -12 -61 -1 3 5 14 6 25 4 14 -4 18 -2 14 9 -5 12 -1 14 17 9 12
-4 26 -11 30 -15z m252 -170 c-3 -5 -11 -10 -16 -10 -6 0 -7 5 -4 10 3 6 11
10 16 10 6 0 7 -4 4 -10z m235 -104 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0
6 5 11 10 11 6 0 10 -2 10 -4z m354 -202 c3 -15 -2 -19 -24 -19 l-29 0 21 22
c23 25 27 25 32 -3z m106 -84 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1
10 4 10 6 0 11 -4 11 -10z m-923 -106 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11
1 17 -2 13 -5z m-242 -34 c15 -6 8 -8 -25 -8 -33 1 -39 3 -25 9 25 9 25 9 50
-1z" />
            <path d="M19385 21900 c-7 -11 -8 -11 23 -14 27 -3 28 0 3 14 -13 7 -22 7 -26
0z" />
            <path d="M23703 21895 c0 -8 4 -12 8 -9 4 2 14 -1 21 -8 8 -7 24 -19 36 -25
18 -11 20 -10 16 2 -4 8 -1 15 5 15 10 0 15 -12 13 -31 0 -3 8 -10 19 -17 11
-7 19 -8 19 -2 0 5 -5 10 -11 10 -7 0 -9 12 -5 34 5 26 3 35 -10 40 -21 8 -34
8 -33 -1 6 -38 -16 -43 -49 -13 -25 24 -29 24 -29 5z m112 -23 c-8 -8 -25 10
-19 20 4 6 9 5 15 -3 4 -7 6 -15 4 -17z" />
            <path d="M21999 21867 c16 -19 41 -23 41 -5 0 6 -3 8 -6 5 -4 -3 -16 -1 -28 5
-19 10 -20 10 -7 -5z" />
            <path d="M21800 21836 c0 -31 6 -34 13 -6 3 11 1 23 -4 26 -5 3 -9 -6 -9 -20z" />
            <path d="M19445 21820 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z" />
            <path d="M19885 21820 c-3 -5 0 -10 6 -12 12 -4 1 -38 -13 -38 -5 0 -6 5 -3
10 3 6 1 10 -5 10 -7 0 -10 -6 -7 -14 6 -16 -16 -40 -26 -29 -4 3 -7 1 -7 -5
0 -7 -5 -12 -11 -12 -5 0 -7 5 -3 11 4 8 0 8 -15 0 -11 -7 -21 -17 -21 -23 0
-7 5 -6 15 2 13 11 14 10 9 -4 -3 -9 -13 -16 -22 -16 -8 0 -13 -3 -9 -6 9 -9
48 6 42 17 -4 5 2 9 11 9 10 0 37 20 61 45 24 25 47 43 52 41 5 -3 13 0 19 7
8 9 1 12 -29 14 -21 0 -41 -3 -44 -7z" />
            <path d="M19553 21720 c2 -24 10 -25 28 -3 10 11 10 14 2 9 -7 -4 -13 -2 -13
3 0 6 -4 11 -9 11 -6 0 -9 -9 -8 -20z" />
            <path d="M21802 21710 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z" />
            <path d="M24050 21701 c0 -6 4 -12 8 -15 5 -3 9 1 9 9 0 8 -4 15 -9 15 -4 0
-8 -4 -8 -9z" />
            <path d="M13790 21678 c0 -9 5 -20 10 -23 13 -8 13 5 0 25 -8 13 -10 13 -10
-2z" />
            <path d="M12036 21665 c-11 -28 24 -69 39 -45 3 5 -4 11 -15 15 -16 5 -18 10
-10 26 6 11 7 19 1 19 -5 0 -11 -7 -15 -15z" />
            <path d="M22370 21671 c0 -5 7 -12 16 -15 14 -5 15 -4 4 9 -14 17 -20 19 -20
6z" />
            <path d="M13012 21640 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z" />
            <path d="M14660 21658 c0 -10 2 -18 4 -18 2 0 11 -3 20 -6 9 -3 16 -1 16 6 0
6 -4 9 -9 5 -6 -3 -14 3 -20 12 -10 17 -10 17 -11 1z" />
            <path d="M24113 21657 c-3 -6 -1 -7 5 -3 5 3 12 1 14 -6 3 -8 6 -7 10 3 7 17
-19 22 -29 6z" />
            <path d="M12984 21630 c-1 -16 3 -27 7 -24 11 6 12 54 2 54 -5 0 -8 -13 -9
-30z" />
            <path d="M13807 21633 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3
3 -9 2 -12 -2z" />
            <path d="M14646 21627 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 -10 9 -11 8 -5 -6z" />
            <path d="M14677 21613 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3
3 -9 2 -12 -2z" />
            <path d="M13805 21600 c-15 -6 -16 -9 -4 -9 9 -1 21 -11 28 -23 9 -18 10 -18
5 -3 -3 11 -7 26 -7 33 0 6 -1 12 -1 11 -1 0 -10 -4 -21 -9z" />
            <path d="M14696 21601 c-3 -4 -10 -21 -16 -37 l-10 -29 20 25 c11 14 20 31 20
38 0 14 -6 16 -14 3z" />
            <path d="M18530 21596 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21
-21 13z" />
            <path d="M24291 21574 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
            <path d="M13790 21562 c0 -11 22 -32 32 -32 5 0 4 9 -2 20 -11 20 -30 28 -30
12z" />
            <path d="M20953 21549 c-9 -9 -9 -16 0 -30 9 -14 8 -21 -2 -31 -12 -11 -13
-10 -8 4 3 9 -1 22 -9 28 -13 11 -18 7 -15 -12 0 -5 -4 -8 -10 -8 -15 0 -69
-39 -69 -51 0 -5 5 -9 11 -9 5 0 8 4 4 10 -3 5 5 9 17 8 36 -1 48 1 48 11 0 5
-8 12 -17 15 -11 3 -7 4 7 3 14 -1 25 -8 25 -16 0 -8 6 -16 14 -19 9 -3 11 0
6 8 -4 7 -3 15 2 19 6 3 13 20 17 36 4 19 3 25 -2 16 -6 -8 -12 -9 -16 -3 -4
6 2 15 11 21 10 6 13 11 8 11 -6 0 -16 -5 -22 -11z m-59 -77 c3 -5 -1 -9 -9
-9 -8 0 -12 4 -9 9 3 4 7 8 9 8 2 0 6 -4 9 -8z" />
            <path d="M22515 21550 c-16 -7 -17 -9 -3 -9 9 -1 20 4 23 9 7 11 7 11 -20 0z" />
            <path d="M12115 21470 c4 -6 11 -8 16 -5 14 9 11 15 -7 15 -8 0 -12 -5 -9 -10z" />
            <path d="M24564 21419 c-4 -7 -3 -9 4 -5 6 3 12 -2 14 -11 2 -12 6 -14 11 -7
4 7 0 17 -7 23 -11 9 -16 9 -22 0z" />
            <path d="M24610 21411 c0 -6 4 -13 10 -16 6 -3 7 1 4 9 -7 18 -14 21 -14 7z" />
            <path d="M13096 21391 c-4 -5 -3 -17 1 -27 4 -12 3 -15 -5 -10 -8 5 -10 2 -6
-9 9 -22 17 -11 19 25 2 31 0 36 -9 21z" />
            <path d="M20973 21335 c0 -33 2 -45 4 -27 2 18 2 45 0 60 -2 15 -4 0 -4 -33z" />
            <path d="M12170 21363 c0 -6 8 -17 18 -24 16 -13 16 -13 2 6 -8 11 -16 22 -17
24 -2 2 -3 0 -3 -6z" />
            <path d="M14742 21284 c-7 -8 -8 -14 -3 -14 10 0 25 19 20 25 -2 1 -10 -3 -17
-11z" />
            <path d="M23096 21265 c4 -9 1 -15 -7 -15 -23 0 8 -23 33 -24 13 -1 28 -5 34
-9 7 -4 15 0 18 9 3 8 2 13 -3 9 -5 -3 -16 0 -23 7 -8 7 -24 19 -36 25 -18 11
-20 10 -16 -2z m39 -25 c3 -6 -1 -7 -9 -4 -18 7 -21 14 -7 14 6 0 13 -4 16
-10z" />
            <path d="M20973 21120 c0 -47 2 -66 4 -42 2 23 2 61 0 85 -2 23 -4 4 -4 -43z" />
            <path d="M23239 21170 c-27 -28 -28 -36 -9 -37 12 -1 17 3 14 12 -3 8 1 12 12
12 20 -2 30 19 12 25 -7 3 -20 -3 -29 -12z" />
            <path d="M25100 21103 c0 -1 14 -9 30 -18 17 -9 30 -11 30 -5 0 5 -7 10 -17
10 -9 0 -14 3 -10 6 11 11 46 -7 40 -22 -3 -7 -1 -14 5 -14 11 0 11 18 -1 37
-6 10 -77 15 -77 6z" />
            <path d="M12290 21070 c-8 -5 -12 -12 -9 -15 4 -3 12 1 19 10 14 17 11 19 -10
5z" />
            <path d="M13219 21050 c14 -12 18 -20 10 -20 -12 0 -12 -2 0 -9 7 -5 11 -14 7
-20 -4 -6 -2 -9 7 -5 23 9 12 44 -19 60 l-29 14 24 -20z" />
            <path d="M13954 21057 c-8 -9 -7 -16 1 -24 10 -9 13 -6 13 13 0 13 -1 24 -2
24 0 -1 -6 -6 -12 -13z" />
            <path d="M23385 21060 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5
0 -13 -4 -16 -10z" />
            <path d="M12330 20980 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2
0 -4 -4 -4 -10z" />
            <path d="M13257 20983 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3
3 -9 2 -12 -2z" />
            <path d="M20200 20950 c-8 -5 -10 -10 -5 -10 6 0 2 -5 -8 -11 -15 -9 -16 -12
-2 -25 13 -14 17 -14 32 2 16 16 16 16 -7 9 -22 -7 -23 -6 -11 8 7 9 17 15 20
12 4 -2 8 2 8 10 0 17 -6 18 -27 5z" />
            <path d="M14820 20931 c0 -6 5 -13 10 -16 6 -3 10 1 10 9 0 9 -4 16 -10 16 -5
0 -10 -4 -10 -9z" />
            <path d="M25496 20885 c-9 -26 -7 -32 5 -12 6 10 9 21 6 23 -2 3 -7 -2 -11
-11z" />
            <path d="M12349 20880 c-12 -8 9 -50 25 -50 6 0 5 5 -2 12 -7 7 -12 19 -12 28
1 12 2 12 11 -2 5 -10 15 -18 21 -18 7 0 7 4 -1 13 -6 8 -9 16 -6 19 8 7 -23
6 -36 -2z" />
            <path d="M23320 20859 c11 -7 3 -9 -30 -6 -25 3 -36 2 -26 0 16 -4 17 -7 5
-14 -14 -10 -4 -9 65 6 23 5 27 8 15 16 -7 5 -21 9 -29 9 -13 0 -13 -1 0 -11z" />
            <path d="M23488 20863 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z" />
            <path d="M23615 20859 c-15 -6 -9 -7 19 -4 22 3 42 8 45 10 8 9 -43 4 -64 -6z" />
            <path d="M13295 20850 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z" />
            <path d="M23560 20850 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5
0 -10 -4 -10 -10z" />
            <path d="M23385 20840 l-40 -7 35 -1 c19 0 44 3 55 8 24 10 7 10 -50 0z" />
            <path d="M23465 20840 c-7 -12 12 -12 40 0 18 8 17 9 -7 9 -15 1 -30 -3 -33
-9z" />
            <path d="M12390 20821 c0 -5 7 -11 15 -15 8 -3 15 -1 15 3 0 5 -7 11 -15 15
-8 3 -15 1 -15 -3z" />
            <path d="M23286 20821 c-3 -4 6 -6 19 -3 14 2 25 6 25 8 0 8 -39 4 -44 -5z" />
            <path d="M14010 20795 c0 -8 2 -15 4 -15 2 0 6 7 10 15 3 8 1 15 -4 15 -6 0
-10 -7 -10 -15z" />
            <path d="M25550 20779 c0 -5 -5 -7 -11 -4 -6 4 0 -9 12 -28 13 -19 27 -33 32
-30 5 3 0 15 -11 27 -11 11 -17 27 -15 34 3 6 2 12 -1 12 -3 0 -6 -5 -6 -11z" />
            <path d="M22848 20773 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
            <path d="M22558 20748 c5 -5 16 -8 23 -6 8 3 3 7 -10 11 -17 4 -21 3 -13 -5z" />
            <path d="M22608 20753 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
            <path d="M14035 20721 c-6 -11 13 -26 22 -17 4 3 1 6 -6 6 -7 0 -9 5 -6 10 3
6 4 10 1 10 -3 0 -7 -4 -11 -9z" />
            <path d="M19886 20703 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z" />
            <path d="M13407 20695 c6 -11 8 -27 6 -35 -2 -9 1 -7 8 4 9 14 8 21 -6 35 -16
15 -17 15 -8 -4z" />
            <path d="M21860 20706 c0 -2 11 -6 25 -8 13 -3 22 -1 19 3 -5 9 -44 13 -44 5z" />
            <path d="M25580 20689 c0 -5 -3 -8 -8 -5 -8 5 -7 -143 2 -151 2 -3 4 26 4 64
0 37 3 76 7 86 4 9 4 17 1 17 -3 0 -6 -5 -6 -11z" />
            <path d="M19839 20668 c-6 -11 -8 -23 -4 -26 3 -3 8 3 11 15 4 14 11 19 25 16
10 -3 19 -3 19 0 0 2 -9 7 -20 10 -14 3 -24 -1 -31 -15z" />
            <path d="M21880 20678 c0 -10 49 -9 75 2 14 6 7 8 -27 7 -27 -1 -48 -5 -48 -9z" />
            <path d="M14906 20607 c13 -42 21 -50 20 -18 0 17 -7 33 -14 36 -10 3 -11 -1
-6 -18z" />
            <path d="M19736 20612 c-3 -5 -17 -9 -32 -9 -14 0 -31 -6 -37 -12 -8 -8 -7
-11 5 -11 9 0 19 4 22 9 3 5 15 7 25 4 18 -4 39 13 28 23 -3 3 -8 1 -11 -4z" />
            <path d="M19398 20603 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z" />
            <path d="M19450 20600 c20 -13 33 -13 25 0 -3 6 -14 10 -23 10 -15 0 -15 -2
-2 -10z" />
            <path d="M13410 20580 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5
0 -10 -4 -10 -10z" />
            <path d="M12497 20573 c-5 -10 -3 -51 4 -57 2 -3 9 6 15 19 7 14 9 24 5 23
-19 -2 -24 3 -11 12 9 6 10 10 3 10 -6 0 -14 -3 -16 -7z" />
            <path d="M20974 20440 c0 -69 1 -97 3 -62 2 34 2 90 0 125 -2 34 -3 6 -3 -63z" />
            <path d="M14100 20436 c0 -2 7 -9 15 -16 13 -11 14 -10 9 4 -5 14 -24 23 -24
12z" />
            <path d="M25547 20423 c3 -7 9 -13 14 -13 5 0 9 6 9 13 0 8 -6 14 -14 14 -8 0
-12 -6 -9 -14z" />
            <path d="M12573 20411 c0 -11 5 -18 9 -15 4 3 5 11 1 19 -7 21 -12 19 -10 -4z" />
            <path d="M13482 20416 c10 -7 14 -17 10 -21 -4 -5 -1 -5 6 -1 10 6 10 11 1 22
-6 8 -17 14 -23 14 -6 0 -3 -7 6 -14z" />
            <path d="M12590 20379 c0 -5 -6 -7 -12 -3 -10 5 -10 4 -1 -6 9 -9 9 -17 0 -30
-9 -15 -5 -25 19 -53 16 -20 36 -36 44 -36 12 -1 13 1 1 8 -7 5 -10 14 -7 20
4 6 4 10 -1 9 -5 -2 -19 7 -31 18 -17 16 -20 24 -11 29 8 5 10 4 5 -3 -4 -7
-2 -12 4 -12 8 0 9 7 5 18 -4 9 -7 25 -7 35 0 9 -1 17 -4 17 -2 0 -4 -5 -4
-11z" />
            <path d="M14120 20339 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0
-10 -5 -10 -11z" />
            <path d="M14986 20307 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 -10 9 -11 8 -5 -6z" />
            <path d="M25443 20301 c-20 -17 -21 -20 -6 -26 10 -3 14 -3 10 1 -4 4 0 16 10
26 21 23 14 23 -14 -1z" />
            <path d="M25470 20295 c-7 -9 -11 -18 -8 -20 3 -3 11 1 18 10 7 9 11 18 8 20
-3 3 -11 -1 -18 -10z" />
            <path d="M13555 20270 c-5 -9 -4 -15 4 -18 9 -3 9 -7 0 -18 -9 -12 -9 -13 4
-9 8 4 17 15 20 26 3 10 1 17 -3 14 -5 -3 -10 0 -12 7 -3 9 -7 8 -13 -2z" />
            <path d="M25311 20220 c-9 -5 -4 -7 13 -7 29 0 32 2 13 10 -8 3 -20 2 -26 -3z" />
            <path d="M14177 20203 c-11 -11 -8 -20 7 -20 8 0 12 6 9 14 -6 15 -7 15 -16 6z" />
            <path d="M12656 20191 c-3 -5 -2 -19 2 -32 7 -19 10 -20 15 -6 4 10 1 17 -6
17 -8 0 -8 3 2 9 8 5 9 11 3 15 -6 3 -13 2 -16 -3z" />
            <path d="M25328 20193 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
            <path d="M13595 20140 c-3 -4 1 -10 7 -12 7 -3 13 -13 13 -24 0 -10 6 -18 13
-16 6 1 12 -2 12 -8 0 -5 -5 -10 -11 -10 -5 0 -7 -5 -4 -10 5 -8 11 -7 20 0
12 10 11 17 -3 45 -18 36 -37 50 -47 35z" />
            <path d="M13640 20144 c0 -6 7 -19 15 -30 8 -10 14 -14 14 -9 0 6 -6 19 -14
29 -8 11 -15 15 -15 10z" />
            <path d="M24500 20110 c0 -5 7 -10 16 -10 8 0 12 5 9 10 -3 6 -10 10 -16 10
-5 0 -9 -4 -9 -10z" />
            <path d="M24408 20103 c12 -2 30 -2 40 0 9 3 -1 5 -23 4 -22 0 -30 -2 -17 -4z" />
            <path d="M24613 20093 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z" />
            <path d="M15086 20045 c8 -37 24 -57 24 -29 0 10 -8 28 -16 39 -15 19 -15 19
-8 -10z" />
            <path d="M24018 20063 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
            <path d="M23918 20053 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
            <path d="M15051 20044 c-1 -6 6 -17 14 -24 13 -11 13 -15 3 -22 -11 -8 -11 -9
0 -6 8 2 13 7 13 11 -1 4 -1 14 -1 23 0 8 -4 13 -9 10 -5 -4 -12 0 -14 6 -4 8
-6 9 -6 2z" />
            <path d="M13641 20024 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
            <path d="M14181 20024 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
            <path d="M23625 20020 c-3 -5 1 -10 10 -10 9 0 13 5 10 10 -3 6 -8 10 -10 10
-2 0 -7 -4 -10 -10z" />
            <path d="M13650 19991 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16
-5 3 -10 2 -10 -4z" />
            <path d="M23135 19968 c-11 -7 -53 -12 -92 -12 -40 -1 -73 -4 -73 -8 0 -5 24
-8 54 -8 30 0 62 -3 72 -7 11 -4 15 -3 10 5 -4 7 6 13 29 18 19 3 35 10 35 15
0 12 -11 11 -35 -3z" />
            <path d="M12736 19953 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z" />
            <path d="M23160 19950 c-12 -8 -11 -10 7 -10 12 0 25 5 28 10 8 13 -15 13 -35
0z" />
            <path d="M22883 19943 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z" />
            <path d="M23308 19933 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z" />
            <path d="M22255 19876 l-30 -13 26 -7 c32 -9 68 -4 94 12 18 12 18 13 -9 7
-15 -3 -42 -8 -60 -11 -27 -4 -25 -2 9 10 23 8 32 14 20 15 -11 0 -33 -6 -50
-13z" />
            <path d="M22170 19871 c-24 -7 -24 -8 7 -12 18 -3 30 -2 27 2 -2 4 2 10 8 12
20 8 -13 6 -42 -2z" />
            <path d="M22095 19860 c4 -6 11 -8 16 -5 14 9 11 15 -7 15 -8 0 -12 -5 -9 -10z" />
            <path d="M22018 19853 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
            <path d="M14259 19843 c-1 -5 -3 -25 -4 -46 -2 -32 0 -36 11 -25 20 19 25 78
8 78 -8 0 -15 -3 -15 -7z m16 -22 c3 -5 1 -12 -5 -16 -5 -3 -10 1 -10 9 0 18
6 21 15 7z" />
            <path d="M14267 19749 c-6 -21 8 -27 16 -6 4 9 8 8 16 -3 8 -12 10 -11 9 6 -2
26 -34 29 -41 3z" />
            <path d="M15135 19730 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z" />
            <path d="M15160 19726 c0 -17 16 -30 23 -19 3 5 -1 14 -9 21 -12 10 -14 10
-14 -2z" />
            <path d="M15153 19675 c0 -14 3 -27 7 -30 4 -2 6 7 4 21 -3 35 -11 41 -11 9z" />
            <path d="M13820 19639 c0 -5 -4 -7 -10 -4 -5 3 -10 2 -10 -3 0 -19 26 -20 27
-1 1 10 0 19 -3 19 -2 0 -4 -5 -4 -11z" />
            <path d="M21812 19580 c0 -33 2 -61 6 -64 8 -4 7 113 -1 120 -2 2 -5 -23 -5
-56z" />
            <path d="M12905 19611 c-3 -5 -2 -12 3 -15 5 -3 9 1 9 9 0 17 -3 19 -12 6z" />
            <path d="M12901 19564 c-12 -14 -12 -17 0 -25 19 -12 29 -11 29 2 0 5 -5 7
-11 3 -7 -4 -10 -1 -7 7 3 8 11 13 17 12 6 -2 11 2 11 7 0 15 -23 11 -39 -6z" />
            <path d="M13880 19505 c0 -6 5 -16 12 -23 9 -9 8 -12 -7 -12 -19 -1 -19 -1 -2
-14 11 -9 14 -16 7 -19 -6 -3 -6 -5 1 -3 17 3 18 43 3 64 -7 9 -14 12 -14 7z" />
            <path d="M21802 19475 c-1 -16 5 -43 13 -60 l13 -30 1 28 c1 15 -3 27 -8 27
-6 0 -12 15 -14 33 l-4 32 -1 -30z" />
            <path d="M12973 19443 c0 -10 5 -21 10 -25 7 -4 6 -12 -2 -22 -15 -18 -7 -36
16 -36 16 0 14 19 -7 75 -11 29 -17 32 -17 8z m27 -63 c0 -5 -4 -10 -10 -10
-5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z" />
            <path d="M15242 19397 c7 -14 7 -22 -2 -27 -9 -6 -9 -9 1 -13 10 -4 11 -6 1
-6 -9 -1 -9 -7 3 -25 8 -13 13 -30 10 -39 -4 -8 -2 -18 3 -21 6 -3 9 11 8 33
0 22 -4 37 -8 34 -13 -7 -9 12 4 25 9 9 9 15 0 24 -8 8 -8 15 -1 24 8 10 6 13
-9 11 -17 -1 -19 -4 -10 -20z" />
            <path d="M21806 19291 c0 -47 2 -97 3 -111 1 -24 2 -24 13 -6 8 15 8 21 0 24
-16 5 -15 27 1 52 10 17 9 22 -2 29 -11 7 -11 15 -3 41 9 24 9 35 -1 44 -9 10
-12 -5 -11 -73z" />
            <path d="M13020 19355 c0 -5 5 -17 10 -25 5 -8 10 -10 10 -5 0 6 -5 17 -10 25
-5 8 -10 11 -10 5z" />
            <path d="M12990 19345 c0 -3 9 -16 21 -28 l21 -22 -13 28 c-11 23 -29 37 -29
22z" />
            <path d="M13935 19330 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3
0 -8 -4 -11 -10z" />
            <path d="M14410 19289 c0 -4 7 -20 15 -35 8 -16 15 -22 15 -13 0 14 -29 61
-30 48z" />
            <path d="M13970 19255 c-8 -9 -8 -15 -2 -15 12 0 26 19 19 26 -2 2 -10 -2 -17
-11z" />
            <path d="M13055 19229 c-11 -17 1 -21 15 -4 8 9 8 15 2 15 -6 0 -14 -5 -17
-11z" />
            <path d="M14440 19195 c0 -8 4 -15 10 -15 5 0 7 7 4 15 -4 8 -8 15 -10 15 -2
0 -4 -7 -4 -15z" />
            <path d="M13090 19155 c-8 -9 -8 -15 -2 -15 12 0 26 19 19 26 -2 2 -10 -2 -17
-11z" />
            <path d="M14000 19155 c0 -5 5 -17 10 -25 5 -8 10 -10 10 -5 0 6 -5 17 -10 25
-5 8 -10 11 -10 5z" />
            <path d="M14450 19150 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5
0 -10 -4 -10 -10z" />
            <path d="M15290 19138 c0 -4 5 -8 11 -8 5 0 7 -5 3 -12 -4 -7 -3 -8 4 -4 17
10 15 33 -3 33 -8 0 -15 -4 -15 -9z" />
            <path d="M21803 19040 c-1 -69 2 -107 7 -95 5 11 7 49 4 85 -3 45 -1 59 5 45
8 -17 10 -16 10 13 1 17 -3 32 -8 32 -5 0 -11 8 -13 18 -2 9 -4 -35 -5 -98z" />
            <path d="M15320 19045 c0 -9 5 -15 11 -13 6 2 11 8 11 13 0 5 -5 11 -11 13 -6
2 -11 -4 -11 -13z" />
            <path d="M13136 19041 c-7 -11 22 -36 31 -28 3 4 -2 10 -11 13 -14 5 -15 9 -4
15 9 6 9 9 1 9 -6 0 -14 -4 -17 -9z" />
            <path d="M20896 19017 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 -10 9 -11 8 -5 -6z" />
            <path d="M15326 19005 c4 -8 10 -12 15 -9 14 8 10 24 -6 24 -9 0 -12 -6 -9
-15z" />
            <path d="M13140 18992 c6 -13 14 -21 18 -18 3 4 -2 14 -12 24 -18 16 -18 16
-6 -6z" />
            <path d="M14492 18970 c1 -16 7 -31 15 -34 9 -4 12 -14 8 -28 -5 -20 -4 -20 5
-6 8 13 7 25 -5 48 -9 17 -13 35 -10 40 3 6 2 10 -4 10 -5 0 -9 -13 -9 -30z" />
            <path d="M14103 18933 c12 -3 16 -11 12 -23 -3 -10 -1 -21 5 -25 6 -3 7 -11 4
-17 -5 -7 -2 -8 6 -3 10 6 12 4 6 -11 -6 -15 -5 -16 5 -7 10 10 9 17 -6 33
-10 11 -15 23 -11 27 4 4 11 1 16 -7 13 -20 12 -3 0 21 -6 11 -20 18 -33 18
-17 -1 -18 -2 -4 -6z" />
            <path d="M14496 18880 c9 -56 15 -70 29 -70 25 0 27 33 2 54 -13 12 -27 28
-30 36 -4 8 -4 -1 -1 -20z m44 -41 c0 -5 -7 -9 -15 -9 -8 0 -15 7 -15 15 0 9
6 12 15 9 8 -4 15 -10 15 -15z" />
            <path d="M21811 18894 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
            <path d="M13210 18860 c0 -5 7 -10 16 -10 8 0 12 5 9 10 -3 6 -10 10 -16 10
-5 0 -9 -4 -9 -10z" />
            <path d="M21801 18809 c-6 -29 -9 -54 -6 -57 3 -3 6 3 6 14 1 11 4 7 8 -11 5
-19 3 -34 -4 -40 -7 -6 -9 -19 -5 -29 6 -18 7 -18 14 5 11 33 13 130 3 116 -5
-7 -5 3 -2 21 4 17 4 32 2 32 -3 0 -10 -23 -16 -51z" />
            <path d="M15380 18792 c0 -6 5 -14 10 -17 6 -4 10 -12 9 -18 -3 -28 2 -48 11
-42 6 4 7 12 3 18 -4 7 -6 19 -5 28 2 9 -4 22 -13 29 -9 8 -15 8 -15 2z" />
            <path d="M13236 18754 c-8 -20 -8 -24 2 -24 4 0 8 9 9 20 2 24 -3 26 -11 4z" />
            <path d="M13295 18669 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z" />
            <path d="M13330 18590 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2
0 -4 -4 -4 -10z" />
            <path d="M14580 18596 c0 -2 7 -9 15 -16 13 -11 14 -10 9 4 -5 14 -24 23 -24
12z" />
            <path d="M14280 18543 c0 -6 5 -15 10 -18 14 -9 2 -27 -13 -19 -7 3 -4 -1 6
-9 11 -9 14 -17 7 -20 -6 -3 -6 -5 1 -3 17 3 18 43 3 64 -8 9 -14 12 -14 5z" />
            <path d="M21670 18515 c0 -9 6 -12 15 -9 8 4 15 7 15 9 0 2 -7 5 -15 9 -9 3
-15 0 -15 -9z" />
            <path d="M21698 18493 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
            <path d="M13380 18420 c0 -5 6 -10 14 -10 8 0 17 -7 20 -16 10 -26 6 -30 -9
-10 -12 16 -15 16 -15 4 0 -8 10 -23 22 -34 19 -17 21 -17 15 -1 -4 9 -2 17 3
17 11 0 -9 40 -25 52 -14 11 -25 10 -25 -2z" />
            <path d="M21458 18386 c-16 -11 -16 -13 3 -27 20 -14 20 -14 -3 -21 -21 -6
-21 -6 5 -7 27 -1 36 12 17 24 -6 4 -8 15 -5 26 7 22 6 23 -17 5z" />
            <path d="M14335 18370 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3
0 -8 -4 -11 -10z" />
            <path d="M21400 18350 c-8 -5 -7 -9 3 -13 9 -3 20 0 26 7 8 9 7 13 -4 13 -8 0
-19 -3 -25 -7z" />
            <path d="M13380 18340 c0 -5 7 -10 16 -10 8 0 12 5 9 10 -3 6 -10 10 -16 10
-5 0 -9 -4 -9 -10z" />
            <path d="M14352 18325 c3 -28 11 -24 13 5 1 11 -2 20 -7 20 -4 0 -7 -11 -6
-25z" />
            <path d="M13450 18311 c0 -6 4 -13 10 -16 6 -3 7 1 4 9 -7 18 -14 21 -14 7z" />
            <path d="M14370 18295 c-8 -9 -8 -15 -2 -15 12 0 26 19 19 26 -2 2 -10 -2 -17
-11z" />
            <path d="M19481 18214 c-25 -13 -36 -50 -18 -61 6 -3 7 -1 3 5 -4 7 -2 12 4
12 7 0 9 7 6 16 -4 11 -1 14 13 9 11 -3 26 1 35 10 20 20 21 32 2 16 -8 -7
-16 -8 -18 -3 -1 4 -14 3 -27 -4z" />
            <path d="M14400 18195 c0 -5 5 -17 10 -25 5 -8 10 -10 10 -5 0 6 -5 17 -10 25
-5 8 -10 11 -10 5z" />
            <path d="M21130 18185 c-7 -9 -8 -15 -2 -15 5 0 12 7 16 15 3 8 4 15 2 15 -2
0 -9 -7 -16 -15z" />
            <path d="M19353 18126 c4 -13 -29 -47 -37 -38 -3 3 2 14 11 24 15 17 15 18 1
13 -9 -4 -21 -20 -27 -37 -14 -39 -14 -48 0 -25 6 9 14 15 18 12 5 -2 16 4 26
15 10 11 22 17 26 14 5 -3 6 3 2 13 -6 15 -4 16 8 6 9 -8 20 -9 29 -3 8 6 11
10 5 11 -52 6 -66 5 -62 -5z" />
            <path d="M14455 18090 c-3 -5 -2 -18 4 -27 7 -14 9 -12 8 10 -2 30 -3 31 -12
17z" />
            <path d="M14730 18085 c0 -5 5 -17 10 -25 5 -8 10 -10 10 -5 0 6 -5 17 -10 25
-5 8 -10 11 -10 5z" />
            <path d="M13534 18059 c-9 -15 -2 -18 20 -9 17 6 18 7 3 13 -9 3 -19 2 -23 -4z" />
            <path d="M19210 18040 c0 -6 5 -10 11 -10 5 0 7 -5 3 -12 -4 -7 -3 -8 4 -4 16
10 15 23 -3 30 -8 3 -15 1 -15 -4z" />
            <path d="M13554 18025 c-10 -8 -14 -15 -8 -15 6 0 17 7 24 15 16 19 9 19 -16
0z" />
            <path d="M14754 17992 c4 -10 -1 -27 -9 -38 -8 -10 -15 -26 -15 -34 0 -9 5 -7
15 8 19 26 28 82 14 82 -6 0 -8 -8 -5 -18z" />
            <path d="M14771 17943 c0 -13 -5 -23 -11 -23 -5 0 -10 -5 -10 -12 0 -8 6 -7
16 4 9 8 14 24 11 34 -4 16 -5 15 -6 -3z" />
            <path d="M15640 17953 c0 -5 7 -18 15 -29 13 -18 14 -18 8 4 -5 22 -23 41 -23
25z" />
            <path d="M13613 17928 c1 -15 6 -23 11 -18 4 4 4 16 -2 26 -9 16 -10 14 -9 -8z" />
            <path d="M14780 17901 c0 -6 4 -13 10 -16 6 -3 7 1 4 9 -7 18 -14 21 -14 7z" />
            <path d="M18900 17900 c20 -13 33 -13 25 0 -3 6 -14 10 -23 10 -15 0 -15 -2
-2 -10z" />
            <path d="M18992 17894 c-14 -16 -5 -18 17 -5 7 5 9 11 5 14 -5 3 -15 -1 -22
-9z" />
            <path d="M13627 17894 c-8 -8 3 -34 15 -34 7 0 6 5 -1 13 -6 8 -8 17 -4 20 3
4 4 7 1 7 -2 0 -7 -3 -11 -6z" />
            <path d="M15628 17873 c6 -34 19 -37 18 -4 -1 15 -6 28 -12 28 -6 0 -9 -11 -6
-24z" />
            <path d="M14740 17879 c0 -5 4 -8 9 -4 6 3 14 -3 20 -12 9 -16 10 -16 11 -2 0
9 -7 20 -16 23 -21 8 -24 8 -24 -5z" />
            <path d="M20690 17860 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z" />
            <path d="M14600 17815 c14 -17 30 -20 30 -4 0 5 -4 7 -10 4 -5 -3 -10 -1 -10
4 0 6 -5 11 -11 11 -8 0 -8 -5 1 -15z" />
            <path d="M15640 17823 c0 -4 5 -15 10 -23 8 -13 10 -13 10 2 0 9 -4 20 -10 23
-5 3 -10 3 -10 -2z" />
            <path d="M18660 17740 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z" />
            <path d="M20335 17700 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z" />
            <path d="M20365 17700 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3
0 -8 -4 -11 -10z" />
            <path d="M20292 17679 c2 -6 -3 -14 -11 -17 -9 -3 -11 0 -6 9 5 7 -7 1 -26
-13 -19 -14 -43 -28 -53 -32 -10 -3 -16 -9 -13 -12 3 -3 19 2 36 11 27 13 31
13 31 1 0 -10 10 -14 31 -14 20 1 26 4 17 9 -10 7 -10 9 0 9 6 0 12 5 12 12 0
6 -7 4 -18 -5 -23 -21 -28 -21 -36 -1 -4 11 -2 15 8 11 18 -7 52 28 36 38 -6
3 -9 1 -8 -6z" />
            <path d="M18564 17659 c-3 -6 -1 -16 5 -22 9 -9 10 -7 5 7 -6 15 -4 17 6 11 9
-5 11 -4 6 3 -9 15 -13 15 -22 1z" />
            <path d="M15742 17620 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z" />
            <path d="M18423 17575 c0 -8 4 -12 9 -9 4 3 8 9 8 15 0 5 -4 9 -8 9 -5 0 -9
-7 -9 -15z" />
            <path d="M18457 17579 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z" />
            <path d="M14695 17490 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z" />
            <path d="M19955 17461 c-3 -6 3 -9 14 -8 11 0 17 5 15 9 -7 11 -22 10 -29 -1z" />
            <path d="M14736 17415 c-3 -9 -1 -26 6 -38 6 -13 13 -17 15 -10 3 7 11 9 19 6
17 -6 19 13 2 20 -10 4 -9 8 2 16 8 5 11 13 7 18 -5 4 -12 1 -18 -7 -7 -12
-11 -13 -18 -2 -6 11 -9 10 -15 -3z m24 -26 c0 -6 -4 -7 -10 -4 -5 3 -10 11
-10 16 0 6 5 7 10 4 6 -3 10 -11 10 -16z" />
            <path d="M13830 17393 c1 -13 29 -59 30 -48 0 21 -13 55 -21 55 -5 0 -9 -3 -9
-7z" />
            <path d="M19807 17385 c3 -8 9 -12 14 -9 12 7 11 11 -5 17 -10 4 -13 1 -9 -8z" />
            <path d="M14760 17350 c-11 -7 -11 -10 -2 -10 7 0 10 -4 7 -10 -3 -5 -1 -10 5
-10 6 0 8 -5 4 -12 -4 -7 -3 -8 4 -4 8 5 9 13 2 25 -5 10 -6 21 -3 25 9 9 0 7
-17 -4z" />
            <path d="M14920 17350 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2
0 -4 -4 -4 -10z" />
            <path d="M17991 17338 c-1 -10 6 -18 15 -18 8 0 12 -5 8 -11 -4 -8 0 -7 15 1
25 13 18 34 -10 27 -10 -3 -20 0 -23 7 -2 6 -5 3 -5 -6z" />
            <path d="M14910 17315 c0 -8 4 -15 10 -15 5 0 7 7 4 15 -4 8 -8 15 -10 15 -2
0 -4 -7 -4 -15z" />
            <path d="M13855 17309 c-11 -16 -1 -19 13 -3 7 8 8 14 3 14 -5 0 -13 -5 -16
-11z" />
            <path d="M14941 17298 c-1 -11 -8 -16 -21 -13 -26 5 -52 -22 -46 -49 3 -12 0
-9 -6 7 -7 15 -16 27 -21 27 -5 0 -6 -8 -2 -17 22 -55 24 -78 10 -100 -18 -26
-9 -32 10 -6 10 14 12 8 10 -33 -3 -73 2 -83 36 -79 27 4 28 3 14 -12 -14 -14
-14 -16 1 -28 9 -6 14 -17 10 -23 -3 -6 -3 -13 2 -16 12 -7 13 36 0 51 -7 9
-7 13 1 13 6 0 11 -4 11 -10 0 -5 5 -10 11 -10 6 0 9 14 7 32 -2 21 2 33 10
34 6 1 16 2 20 3 9 1 -14 108 -27 126 -4 6 -8 24 -9 42 -3 37 -20 89 -21 61z
m30 -181 c-8 -8 -11 -7 -11 4 0 9 3 19 7 23 4 4 9 2 11 -4 3 -7 -1 -17 -7 -23z
m19 -22 c0 -8 -7 -15 -15 -15 -13 0 -14 3 -3 15 16 18 18 18 18 0z" />
            <path d="M17976 17294 c-5 -14 -4 -15 9 -4 17 14 19 20 6 20 -5 0 -12 -7 -15
-16z" />
            <path d="M14818 17233 c15 -16 29 -24 31 -20 4 11 -29 47 -45 47 -7 0 -1 -12
14 -27z" />
            <path d="M19560 17245 c0 -8 4 -15 10 -15 5 0 7 7 4 15 -4 8 -8 15 -10 15 -2
0 -4 -7 -4 -15z" />
            <path d="M17871 17227 c-13 -21 -12 -21 5 -5 10 10 16 20 13 22 -3 3 -11 -5
-18 -17z" />
            <path d="M14813 17207 c10 -23 35 -43 43 -35 1 2 -11 16 -27 32 l-28 29 12
-26z" />
            <path d="M19525 17220 c4 -6 11 -8 16 -5 14 9 11 15 -7 15 -8 0 -12 -5 -9 -10z" />
            <path d="M13930 17151 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16
-5 3 -10 2 -10 -4z" />
            <path d="M14855 17110 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3
0 -8 -4 -11 -10z" />
            <path d="M13950 17080 c0 -13 18 -22 24 -11 3 5 -1 11 -9 15 -8 3 -15 1 -15
-4z" />
            <path d="M19274 17076 c-3 -8 -12 -12 -19 -10 -7 3 -16 1 -19 -4 -8 -13 77 -7
101 7 14 8 9 9 -21 4 -28 -4 -37 -3 -32 5 4 7 5 12 1 12 -3 0 -8 -6 -11 -14z" />
            <path d="M17545 17070 c-3 -5 1 -10 10 -10 9 0 13 -5 10 -11 -4 -6 -13 -8 -19
-6 -7 3 -13 -1 -13 -9 0 -14 3 -13 56 23 32 22 33 33 1 13 -9 -5 -19 -5 -26 1
-8 7 -14 6 -19 -1z" />
            <path d="M14983 17034 c-3 -9 -6 -29 -7 -44 -1 -15 -4 -26 -8 -23 -5 2 -8 -1
-8 -8 0 -7 -8 -22 -17 -33 -10 -12 -14 -18 -9 -14 5 4 17 -8 27 -27 21 -39 23
-49 9 -40 -6 3 -7 -1 -4 -10 6 -14 8 -14 19 2 9 13 10 22 3 30 -8 10 -4 13 16
13 14 0 26 -5 26 -11 0 -5 5 -7 10 -4 6 3 10 1 10 -4 0 -6 -4 -11 -10 -11 -5
0 -10 -10 -10 -22 0 -17 -3 -19 -12 -10 -8 8 -8 15 1 26 7 8 8 17 3 20 -14 9
-22 -33 -12 -60 10 -24 -4 -35 -13 -11 -3 6 -6 2 -6 -11 -1 -14 3 -21 10 -17
6 4 9 1 7 -7 -2 -7 2 -14 7 -15 6 -1 9 8 7 20 -2 18 0 19 8 7 5 -8 10 -10 10
-4 0 5 -5 15 -12 22 -9 9 -9 12 0 12 6 0 16 -9 22 -19 13 -25 13 -34 -1 -25
-8 4 -9 -1 -4 -17 3 -13 3 -19 -2 -13 -6 9 -11 9 -19 0 -8 -9 -7 -15 2 -24 11
-10 18 -4 35 30 19 38 20 44 6 72 -8 17 -13 42 -12 56 2 35 -21 105 -34 102
-9 -2 -9 3 -1 17 7 13 7 28 0 45 -11 29 -28 34 -37 10z m27 -4 c0 -5 -4 -10
-10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m10 -30 c0 -5 -4
-10 -10 -10 -5 0 -10 -8 -11 -17 0 -11 -2 -13 -6 -5 -3 7 -2 19 1 27 6 17 26
20 26 5z m49 -225 c0 -5 -6 1 -14 15 -8 14 -14 30 -14 35 0 6 6 -1 14 -15 8
-14 14 -29 14 -35z" />
            <path d="M14910 16980 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5
0 -10 -4 -10 -10z" />
            <path d="M14010 16963 c0 -6 8 -17 18 -24 16 -13 16 -13 2 6 -8 11 -16 22 -17
24 -2 2 -3 0 -3 -6z" />
            <path d="M14916 16946 c3 -9 11 -16 16 -16 13 0 5 23 -10 28 -7 2 -10 -2 -6
-12z" />
            <path d="M15882 16945 c0 -5 6 -26 13 -45 7 -19 14 -28 14 -20 0 8 -6 29 -14
45 -7 17 -13 26 -13 20z" />
            <path d="M14013 16908 c-2 -16 1 -18 10 -9 7 7 8 14 1 21 -6 6 -10 2 -11 -12z" />
            <path d="M18990 16915 c-7 -8 -19 -15 -26 -15 -8 0 -14 -4 -14 -9 0 -5 9 -7
20 -4 11 3 20 9 20 15 0 5 8 4 19 -1 23 -13 35 -3 20 16 -15 17 -24 17 -39 -2z
m35 -5 c3 -6 -1 -7 -9 -4 -18 7 -21 14 -7 14 6 0 13 -4 16 -10z" />
            <path d="M17125 16799 c-4 -6 -4 -13 -1 -16 3 -4 6 -1 6 6 0 7 5 9 12 5 7 -4
8 -3 4 4 -9 15 -13 15 -21 1z" />
            <path d="M16996 16754 c-19 -18 -21 -34 -4 -34 6 0 8 3 5 7 -4 3 0 15 10 25
20 22 11 24 -11 2z" />
            <path d="M18729 16768 c-7 -23 -4 -34 10 -35 10 -1 17 6 18 18 1 10 -5 19 -13
19 -8 0 -14 -1 -15 -2z" />
            <path d="M15070 16716 c-5 -15 -7 -38 -4 -52 4 -14 2 -24 -5 -24 -6 0 -11 8
-11 18 -1 15 -2 15 -15 -2 -8 -11 -15 -25 -15 -30 0 -7 3 -6 8 1 4 6 8 -15 8
-48 0 -32 -3 -59 -7 -59 -5 0 -5 7 -2 17 4 11 3 14 -5 9 -7 -4 -10 -17 -6 -30
4 -16 0 -25 -11 -29 -14 -6 -14 -9 1 -32 10 -15 12 -25 5 -25 -6 0 -11 5 -11
10 0 6 -6 5 -14 -2 -8 -7 -12 -15 -10 -17 2 -3 -6 -16 -18 -29 -13 -14 -17
-22 -10 -18 6 4 12 3 12 -2 0 -5 -9 -12 -21 -15 -12 -3 -19 -10 -16 -14 3 -5
-8 -18 -24 -28 -16 -11 -27 -26 -24 -33 2 -7 -1 -12 -8 -10 -6 1 -26 -8 -44
-21 -28 -21 -32 -29 -27 -60 3 -25 2 -32 -5 -22 -10 16 -41 24 -41 12 0 -4
-20 -22 -45 -40 -25 -18 -45 -38 -45 -44 0 -7 5 -4 11 6 5 9 15 17 20 17 6 0
8 -4 4 -9 -3 -6 1 -14 10 -18 10 -6 2 -9 -27 -10 -22 -1 -46 -7 -52 -14 -8 -9
-8 -10 1 -5 7 4 11 -5 11 -27 0 -32 -2 -34 -33 -33 -18 1 -31 4 -28 9 2 4 8 5
12 2 5 -2 16 4 26 15 31 34 2 22 -49 -20 -26 -22 -43 -40 -36 -40 6 0 8 -5 5
-11 -4 -6 -14 -8 -22 -5 -24 10 -42 -19 -40 -63 2 -42 -2 -45 -41 -30 -27 10
-76 -16 -68 -37 3 -7 -7 -17 -25 -23 -16 -5 -29 -12 -29 -15 0 -3 7 -3 15 0 8
3 24 -1 36 -10 l22 -15 -22 2 c-11 1 -21 6 -21 11 0 5 -6 1 -14 -9 -8 -11 -12
-25 -9 -31 2 -7 -1 -15 -8 -20 -9 -5 -10 -2 -5 11 6 18 6 18 -12 2 -13 -12
-21 -14 -29 -6 -13 13 -36 2 -28 -12 4 -5 0 -9 -7 -9 -8 0 -9 -3 -3 -8 6 -4
11 -24 10 -46 0 -36 -2 -39 -30 -42 -22 -2 -26 -1 -15 6 13 8 13 10 -1 10 -9
0 -21 -10 -27 -22 -10 -22 -11 -22 -9 -2 2 12 11 33 21 48 14 22 20 25 32 15
11 -9 14 -8 14 5 0 10 -4 14 -10 11 -5 -3 -10 -2 -10 4 0 6 -11 0 -23 -12 -13
-12 -23 -26 -23 -32 1 -5 -5 -9 -12 -7 -8 1 -11 -2 -6 -9 5 -8 2 -10 -8 -6
-12 4 -14 1 -10 -15 4 -15 7 -17 13 -6 6 9 9 8 9 -7 0 -25 -18 -45 -39 -45
-11 0 -10 3 4 14 18 13 17 14 -13 11 -18 -1 -32 -6 -31 -10 0 -4 -5 -12 -13
-17 -10 -7 -9 -8 4 -4 10 3 28 1 40 -4 18 -8 12 -9 -25 -6 -41 4 -51 1 -72
-22 -22 -23 -23 -28 -10 -38 19 -14 19 -21 0 -21 -8 0 -15 4 -15 9 0 4 -5 8
-11 8 -5 0 -8 -4 -4 -9 3 -5 -5 -11 -17 -13 -18 -2 -23 -9 -22 -30 1 -16 -3
-28 -8 -28 -6 0 -8 7 -5 15 4 8 2 17 -2 19 -15 10 -6 -35 9 -44 12 -8 11 -10
-5 -7 -11 1 -26 6 -32 10 -8 5 -13 3 -13 -3 0 -6 -4 -9 -9 -6 -12 8 -43 -29
-34 -39 5 -4 0 -5 -10 -2 -10 4 -16 3 -13 -2 3 -5 -3 -11 -14 -14 -11 -3 -20
-9 -19 -14 1 -4 0 -10 -1 -13 -1 -3 -3 -21 -3 -40 -2 -31 -5 -34 -34 -35 -17
0 -42 -9 -55 -20 -17 -15 -19 -16 -9 -2 8 10 9 17 3 17 -16 0 -23 -16 -16 -34
4 -11 10 -13 18 -7 8 7 12 4 13 -12 2 -14 -1 -17 -6 -9 -5 9 -11 9 -27 -3 -23
-17 -34 -19 -34 -5 0 6 5 9 11 8 6 -2 13 3 14 10 2 7 1 8 -2 2 -3 -7 -10 -7
-21 2 -9 8 -13 8 -9 2 4 -7 0 -15 -9 -18 -9 -4 -12 -11 -7 -18 4 -7 8 -29 10
-47 1 -21 -2 -30 -6 -22 -6 10 -10 10 -16 1 -4 -6 -13 -9 -21 -6 -19 7 -44
-21 -37 -41 4 -10 2 -14 -4 -10 -6 4 -16 0 -23 -8 -6 -7 -19 -11 -27 -8 -12 5
-14 3 -8 -7 5 -9 4 -11 -3 -6 -7 4 -12 2 -12 -4 0 -6 -5 -8 -11 -4 -7 4 0 13
17 25 15 10 32 15 38 12 5 -4 7 1 3 10 -3 9 -1 19 5 23 7 5 8 2 3 -6 -5 -9 -4
-11 4 -6 9 6 9 11 0 22 -14 17 -29 9 -29 -17 0 -10 -5 -19 -11 -19 -5 0 -7 6
-4 13 8 15 -32 -29 -60 -66 -11 -15 -31 -29 -44 -30 -14 -2 -27 -9 -29 -16 -2
-7 -15 -23 -29 -37 -13 -13 -22 -28 -18 -33 8 -13 56 -6 66 10 4 7 10 11 14 8
9 -10 -34 -42 -60 -45 -14 -1 -23 2 -20 7 4 5 1 9 -4 9 -6 0 -11 -4 -11 -8 0
-4 -10 -9 -22 -9 -18 -2 -20 0 -8 7 8 5 10 10 4 10 -20 0 -44 -36 -39 -58 2
-13 0 -20 -6 -17 -5 4 -6 12 -2 18 3 7 -3 3 -15 -7 -12 -11 -22 -15 -22 -11 0
4 -8 1 -19 -9 -13 -12 -18 -28 -15 -59 2 -30 0 -36 -6 -22 -7 17 -9 17 -9 3
-1 -23 -9 -23 -33 -1 -15 13 -19 14 -16 3 2 -8 -2 -14 -8 -15 -19 0 -44 -33
-38 -49 3 -9 2 -16 -4 -16 -6 0 -12 4 -14 9 -1 5 -12 3 -23 -6 -17 -13 -17
-15 -2 -9 11 3 16 2 12 -4 -3 -6 -12 -10 -19 -10 -20 0 -36 -24 -27 -39 11
-17 25 4 15 22 -4 7 -3 8 2 4 5 -5 9 -22 10 -39 1 -21 -1 -26 -7 -17 -6 10 -9
11 -9 2 0 -7 -5 -13 -11 -13 -5 0 -7 5 -3 11 3 6 -8 4 -24 -5 -17 -9 -33 -12
-36 -7 -3 5 8 14 24 21 16 7 27 16 24 21 -3 5 -10 4 -17 -1 -7 -6 -24 -20 -40
-31 -15 -11 -25 -25 -22 -30 4 -5 2 -9 -2 -9 -5 0 -18 -7 -29 -16 -18 -15 -18
-15 3 -5 28 14 54 15 33 1 -8 -5 -11 -12 -8 -16 4 -3 14 1 23 10 21 20 24 20
22 -2 -1 -21 -57 -31 -57 -10 -1 7 -7 2 -14 -11 -7 -13 -12 -36 -10 -52 4 -29
-17 -41 -31 -19 -3 6 -11 10 -16 10 -6 0 -7 -5 -3 -12 4 -7 3 -8 -4 -4 -17 10
-15 23 4 30 10 4 21 0 28 -9 10 -14 12 -14 12 4 0 11 -4 17 -8 15 -4 -3 -8 -1
-8 5 0 5 5 13 10 16 13 8 13 25 -1 25 -5 0 -8 -4 -5 -9 7 -11 -28 -43 -45 -39
-10 2 -13 -10 -11 -50 2 -53 -10 -92 -29 -92 -6 0 -7 4 -4 10 3 6 -1 7 -10 4
-11 -5 -14 -2 -9 10 4 11 1 16 -10 16 -10 0 -13 -6 -10 -15 4 -8 1 -15 -6 -15
-6 0 -9 4 -6 9 4 5 -1 8 -9 7 -15 -2 -44 -34 -45 -49 0 -12 25 15 33 33 4 12
6 12 6 -2 1 -9 -3 -20 -9 -23 -13 -8 -13 -25 -1 -25 5 0 13 9 16 20 4 14 15
20 33 19 l27 -1 -25 -9 c-31 -11 -65 -65 -47 -75 7 -4 9 -4 5 1 -11 12 21 38
38 31 8 -3 13 -2 9 3 -3 5 1 12 7 15 7 3 2 3 -12 1 -20 -5 -18 -2 10 14 19 10
37 17 40 14 3 -3 -1 -8 -7 -10 -7 -3 -13 -9 -13 -14 0 -4 7 -6 16 -2 10 3 14
13 10 25 -4 16 -1 19 15 15 10 -3 17 -1 13 4 -3 5 2 9 11 9 9 0 13 -5 9 -11
-5 -8 -1 -8 14 0 11 6 22 18 26 27 3 9 13 14 21 10 8 -3 19 3 24 12 6 9 11 13
11 9 0 -4 9 -3 20 3 11 6 18 15 14 21 -3 5 -7 25 -8 44 -3 30 -1 33 15 25 26
-14 37 -12 17 4 -15 11 -5 13 63 11 44 0 83 3 86 9 3 5 1 6 -5 2 -6 -3 -13 -2
-17 4 -3 5 2 10 12 10 17 1 17 1 1 11 -10 5 -18 14 -18 19 0 6 8 10 18 10 12
0 13 3 4 8 -7 5 14 9 49 9 36 1 56 -3 48 -7 -7 -5 -17 -6 -21 -4 -4 3 -8 0 -8
-7 0 -9 -3 -9 -14 0 -12 10 -21 9 -43 -4 l-28 -16 28 6 c19 4 26 2 22 -5 -3
-5 -1 -10 4 -10 6 0 11 4 11 8 0 4 10 8 21 9 12 1 29 8 38 16 9 8 26 18 38 21
24 8 38 33 17 33 -7 0 -11 -4 -8 -8 2 -4 1 -10 -4 -13 -4 -2 -8 10 -8 28 0 31
1 32 42 32 23 -1 45 -4 48 -7 4 -4 -4 -5 -17 -4 -15 2 -24 -2 -24 -11 0 -8 7
-14 14 -14 8 0 11 5 8 11 -4 6 0 9 11 7 13 -2 19 3 18 15 -1 9 2 17 7 17 5 0
9 -5 9 -11 0 -5 15 0 33 13 17 13 39 24 47 26 8 2 19 10 23 18 5 8 12 12 16 9
5 -2 7 7 5 20 -2 27 5 32 24 13 9 -9 12 -9 12 0 0 7 -6 12 -12 12 -9 0 -8 4 2
10 23 15 29 12 23 -12 -4 -14 -2 -20 3 -15 5 5 10 13 10 18 3 22 5 23 14 9 6
-10 13 -11 19 -5 7 7 7 16 1 25 -5 8 -10 10 -10 4 0 -6 -7 -11 -15 -11 -8 0
-12 4 -9 9 8 13 -9 9 -30 -7 -23 -17 -36 -19 -36 -5 0 6 8 10 18 11 14 0 14 2
-3 9 -13 5 7 8 55 7 85 0 117 6 139 28 13 13 11 16 -18 26 -24 8 -30 14 -21
23 8 8 13 7 20 -4 9 -13 10 -13 10 0 0 10 7 7 21 -10 11 -14 19 -20 16 -15 -7
14 20 38 28 25 4 -6 17 -4 36 5 17 10 27 20 23 27 -5 8 -2 9 9 5 10 -3 17 -2
17 4 0 6 6 7 13 3 7 -5 7 -1 -3 12 -22 29 -24 30 -17 12 4 -11 2 -18 -6 -18
-7 0 -13 8 -13 18 0 13 9 17 34 18 18 0 29 -2 23 -5 -6 -4 -7 -12 -1 -21 8
-13 10 -12 10 3 0 10 6 17 14 15 14 -3 16 0 16 25 0 8 -3 7 -10 -3 -6 -10 -9
-5 -8 19 l1 32 22 -23 c12 -13 20 -26 18 -31 -6 -10 69 32 87 49 10 10 10 14
2 14 -7 0 -12 -6 -12 -12 0 -10 -2 -10 -8 -1 -6 10 -12 10 -28 -2 -19 -15 -19
-15 1 -9 16 6 18 4 8 -6 -7 -8 -22 -10 -38 -6 -15 4 -24 10 -21 13 4 4 -3 12
-15 18 -18 10 -9 12 49 11 39 -1 73 -4 77 -8 8 -8 105 50 105 63 0 5 -5 9 -11
9 -5 0 -7 -4 -4 -10 3 -5 1 -10 -5 -10 -7 0 -10 7 -6 15 3 8 1 15 -4 15 -6 0
-10 -4 -10 -10 0 -5 -10 -10 -22 -11 l-23 0 23 -9 c12 -5 22 -12 22 -16 0 -3
-7 -3 -16 0 -8 3 -13 2 -10 -3 3 -5 0 -11 -7 -14 -9 -3 -13 7 -13 34 0 27 3
36 10 29 12 -12 29 -13 22 -1 -3 4 19 7 49 6 47 -2 59 2 82 23 24 22 25 26 10
37 -24 17 -22 20 19 23 20 1 33 -2 29 -8 -3 -5 -11 -8 -18 -6 -7 1 -9 1 -4 -1
4 -3 5 -9 2 -13 -3 -5 0 -12 7 -16 7 -4 10 -4 6 0 -3 4 1 16 10 27 12 14 18
15 24 6 5 -9 10 -9 20 2 8 9 9 11 1 7 -6 -3 -15 -1 -19 6 -10 15 -12 78 -3 78
9 0 22 -41 19 -60 -2 -12 2 -12 17 3 11 9 29 17 41 17 11 0 23 4 27 10 3 5 13
7 23 4 13 -5 14 -4 4 4 -9 6 -28 4 -54 -5 -34 -11 -43 -12 -49 -1 -13 20 -11
24 13 25 18 2 20 0 8 -7 -11 -7 -8 -10 15 -10 24 0 27 3 15 10 -11 8 -3 9 28
5 26 -4 42 -2 42 5 0 5 5 10 11 10 5 0 7 -5 3 -11 -4 -8 0 -8 15 0 24 13 28
23 6 15 -8 -4 -15 -3 -15 0 0 4 10 10 23 15 21 8 20 9 -10 15 -59 13 -39 32
32 32 43 0 60 -3 49 -9 -8 -5 -26 -6 -39 -3 -13 3 -26 2 -29 -3 -3 -5 2 -8 12
-7 10 0 16 -5 14 -13 -3 -12 0 -12 12 -1 9 7 16 9 16 6 0 -4 10 2 22 13 11 11
25 18 30 15 4 -3 8 4 8 14 0 24 23 35 41 19 11 -10 11 -9 1 4 -6 9 -8 20 -4
25 4 4 -1 2 -11 -5 -9 -8 -20 -11 -24 -7 -3 3 -1 6 7 6 9 0 10 3 1 14 -9 11
-4 13 29 10 32 -2 40 1 41 14 0 10 3 12 6 5 3 -8 15 -1 33 19 16 17 27 35 24
39 -6 10 67 5 84 -5 7 -4 12 -4 11 1 -4 20 2 24 18 12 14 -12 16 -12 8 0 -6
10 -4 13 6 9 8 -3 25 3 38 13 14 11 18 19 10 19 -11 0 -11 2 1 10 9 6 17 5 22
-3 5 -8 8 -7 8 5 0 13 -8 16 -40 13 -22 -2 -42 0 -45 5 -3 5 26 7 65 6 38 -2
70 1 70 6 0 5 6 6 13 2 7 -5 6 0 -3 11 -14 17 -14 18 9 6 15 -8 21 -9 17 -2
-4 7 1 11 13 11 11 0 23 5 26 11 4 6 17 14 29 19 18 7 16 8 -11 8 -21 -1 -33
-6 -33 -15 0 -13 -41 -19 -53 -7 -18 18 9 29 69 29 36 0 75 5 87 12 12 7 17
12 10 13 -6 0 -10 8 -9 18 3 16 8 18 24 9 4 -2 1 -2 -6 0 -7 1 -10 -2 -7 -7 7
-11 35 -14 35 -3 0 3 -7 14 -16 24 -14 16 -13 17 13 15 15 -2 34 -5 42 -7 7
-2 16 2 19 10 3 10 0 12 -12 8 -19 -8 -23 19 -3 26 9 4 8 8 -3 16 -20 14 -3
13 40 -4 32 -12 28 -19 -10 -16 -10 0 -11 -1 -2 -5 6 -3 9 -11 5 -17 -3 -6 5
-3 18 6 13 10 24 22 24 27 0 6 5 10 10 9 6 -1 9 -6 7 -12 -2 -7 5 -3 15 8 22
23 26 86 5 77 -9 -3 -10 -8 -3 -15 15 -15 14 -30 -1 -21 -8 5 -9 2 -5 -9 3
-10 2 -17 -3 -17 -6 0 -10 6 -10 13 0 8 -4 7 -10 -3 -5 -8 -10 -10 -10 -5 0 6
-5 3 -10 -5 -8 -13 -11 -13 -19 -1 -5 7 -13 11 -18 8 -5 -3 -15 -1 -23 4 -11
7 -7 9 15 8 36 -3 75 9 75 22 0 6 -4 7 -10 4 -6 -4 -10 6 -10 22 0 21 3 24 10
13 5 -8 12 -12 15 -8 3 3 0 12 -7 19 -15 16 -17 57 -3 67 14 10 17 32 5 32 -6
0 -10 -5 -10 -12 0 -6 -3 -9 -6 -6 -3 4 0 16 6 28 9 17 9 21 0 15 -7 -5 -11
-1 -10 11 4 34 0 46 -13 41 -9 -3 -13 8 -13 37 -1 31 1 36 7 21 6 -14 7 -9 3
15 -4 19 -10 36 -14 38 -4 2 -6 13 -3 25 4 17 1 16 -11 -7 -17 -30 -22 -61 -6
-36 5 8 12 11 17 7 4 -4 2 -16 -4 -26 -6 -9 -9 -29 -6 -44 3 -16 1 -27 -6 -27
-6 0 -11 10 -11 23 0 12 -4 28 -9 36 -6 9 -5 24 0 39 6 15 6 28 1 31 -6 4 -6
16 -1 29 5 13 13 20 19 17 5 -3 10 -1 10 6 0 6 -8 9 -20 6 -13 -3 -20 0 -20
10 0 9 6 13 15 9 8 -3 15 -2 15 3 0 5 -7 14 -15 21 -9 7 -15 29 -15 52 0 32
-3 39 -15 34 -8 -3 -15 0 -15 6 0 6 -6 5 -16 -3 -15 -12 -16 -11 -11 6 3 11 9
34 12 50 4 24 3 26 -4 10 -8 -17 -10 -16 -10 13 -1 17 -5 32 -11 32 -18 0 -11
29 10 42 11 7 20 16 20 20 0 13 -28 9 -33 -4 -2 -7 -4 1 -3 17 1 17 5 36 10
44 6 9 5 12 -1 8 -7 -4 -11 6 -12 26 -1 54 -4 64 -17 64 -7 0 -11 -4 -8 -9 3
-4 0 -8 -5 -8 -12 0 -14 20 -4 46 6 16 7 16 15 -2 6 -13 7 -10 2 11 -8 44 -22
53 -34 21z m-34 -259 c-1 -50 -2 -52 -28 -54 -19 0 -25 3 -21 13 4 11 9 12 19
3 14 -12 19 -2 14 30 -2 14 8 61 13 61 2 0 3 -24 3 -53z m95 -119 c-6 -17 -9
-7 -9 37 0 56 1 57 9 23 5 -21 5 -48 0 -60z m-173 -43 l0 -52 -63 0 c-47 0
-61 3 -53 11 6 6 22 12 35 13 14 0 30 6 37 13 8 10 5 11 -14 5 -25 -8 -25 -8
-6 14 15 16 21 18 28 9 5 -9 8 -7 9 7 1 11 1 23 1 26 -1 3 5 6 12 6 9 0 13
-17 14 -52z m-178 -125 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10
2 0 4 -4 4 -10z m-63 -50 c1 -11 -2 -20 -7 -20 -6 0 -7 17 -1 38 3 8 7 -1 8
-18z m490 -102 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m-680
-80 c6 14 33 16 33 2 0 -5 -3 -9 -7 -9 -5 1 -10 0 -13 -1 -3 -1 -17 -3 -32 -4
-22 -2 -25 1 -16 12 9 10 13 11 20 0 8 -10 11 -10 15 0z m-119 -95 c-9 -10
-14 -28 -12 -40 3 -18 0 -22 -16 -20 -11 2 -23 7 -27 12 -5 4 -2 5 5 1 12 -7
16 5 13 33 -2 7 2 10 7 7 6 -3 12 1 16 9 3 8 11 15 17 15 8 0 7 -6 -3 -17z
m-143 -133 c3 -5 12 -7 20 -3 8 3 15 0 15 -6 0 -6 -7 -12 -15 -12 -8 -1 -20
-2 -27 -3 -6 -1 -12 7 -14 17 -2 18 11 23 21 7z m-107 -163 c0 -15 -7 -23 -19
-24 -17 -2 -18 0 -3 23 20 30 22 30 22 1z m700 -59 c-11 -6 -11 -8 0 -8 10 0
10 -3 0 -14 -9 -10 -15 -11 -20 -2 -6 8 -11 7 -18 -4 -5 -8 -10 -13 -11 -10
-8 40 -4 45 28 46 21 0 29 -3 21 -8z m-181 -57 c20 18 15 1 -7 -21 -22 -22
-39 -27 -21 -7 7 9 7 18 1 25 -7 9 -7 9 2 2 7 -6 16 -6 25 1z m-837 -91 c0 -5
-5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m-5 -52 c6 -4
-5 -6 -23 -4 -18 2 -36 7 -40 11 -4 5 1 5 10 2 10 -3 20 -2 24 4 3 6 9 7 13 2
3 -4 11 -11 16 -15z m585 -52 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5
11 10 11 6 0 10 -2 10 -4z m-900 -260 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4
0 6 5 11 10 11 6 0 10 -2 10 -4z m-88 -63 c6 -7 4 -14 -4 -19 -17 -11 -48 -13
-42 -3 2 4 -5 7 -16 6 -15 -1 -19 -7 -15 -22 3 -11 2 -14 -1 -7 -3 6 -11 12
-16 12 -6 0 -8 -3 -5 -7 9 -8 -12 -33 -29 -33 -8 0 -4 10 11 26 14 15 23 29
21 33 -3 4 7 8 20 9 27 2 53 23 55 44 1 7 4 4 6 -7 3 -11 9 -25 15 -32z m203
-152 c19 -8 19 -8 -2 -19 -17 -9 -24 -9 -29 0 -4 7 -3 8 4 4 7 -4 12 -3 12 3
0 5 -6 12 -12 14 -7 3 -8 5 -3 6 6 0 19 -4 30 -8z m-441 -32 c5 -4 -7 -7 -27
-7 -21 0 -34 4 -31 9 3 5 9 7 14 4 5 -3 12 0 15 6 4 5 10 7 14 2 3 -4 10 -11
15 -14z m-158 -126 c-1 -20 -8 -39 -15 -41 -10 -3 -10 0 -2 17 8 14 8 21 1 21
-6 0 -6 7 2 20 17 27 18 26 14 -17z m356 23 c10 -14 10 -26 1 -24 -5 1 -21 -4
-37 -11 -16 -6 -31 -9 -34 -7 -3 3 3 8 12 12 9 3 16 14 16 23 0 20 31 24 42 7z
m33 4 c-3 -5 -10 -10 -16 -10 -5 0 -9 5 -9 10 0 6 7 10 16 10 8 0 12 -4 9 -10z
m-112 -30 c-15 -16 -29 -30 -30 -30 -7 0 13 37 26 47 30 23 33 13 4 -17z
m-443 -154 c-8 -8 -13 -7 -20 4 -12 20 -1 33 16 19 11 -9 12 -15 4 -23z m-27
-146 c4 0 5 5 2 10 -8 13 15 13 35 0 10 -7 11 -10 3 -11 -7 -1 -17 -3 -23 -4
-5 -1 -15 -3 -21 -4 -5 0 -7 -7 -3 -13 6 -10 5 -10 -8 -1 -8 7 -17 10 -21 7
-3 -3 -4 2 -2 13 2 10 4 20 4 23 1 2 6 -1 13 -8 7 -7 16 -12 21 -12z" />
            <path d="M14942 16310 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z" />
            <path d="M14925 16271 c-6 -5 -19 -11 -30 -14 -16 -4 -14 -5 6 -6 16 -1 29 5
33 14 6 17 4 19 -9 6z" />
            <path d="M13400 14910 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5
0 -10 -4 -10 -10z" />
            <path d="M14010 16685 c0 -6 8 -11 19 -11 10 0 17 5 14 11 -2 7 -10 12 -18 12
-8 0 -15 -5 -15 -12z" />
            <path d="M16920 16684 c0 -9 5 -14 12 -12 18 6 21 28 4 28 -9 0 -16 -7 -16
-16z" />
            <path d="M18520 16609 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0
-10 -5 -10 -11z" />
            <path d="M15130 16540 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2
0 -4 -4 -4 -10z" />
            <path d="M16656 16542 c-3 -6 -1 -14 5 -17 14 -9 20 -2 10 14 -6 8 -11 9 -15
3z" />
            <path d="M15150 16474 c0 -7 7 -27 15 -45 8 -19 14 -29 15 -23 0 7 -7 27 -15
45 -8 19 -14 29 -15 23z" />
            <path d="M13758 16463 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
            <path d="M16536 16455 c4 -8 8 -15 10 -15 2 0 4 7 4 15 0 8 -4 15 -10 15 -5 0
-7 -7 -4 -15z" />
            <path d="M18089 16383 c-13 -16 -12 -17 4 -4 9 7 17 15 17 17 0 8 -8 3 -21
-13z" />
            <path d="M15160 16356 c0 -2 7 -6 15 -10 8 -3 15 -1 15 4 0 6 -7 10 -15 10 -8
0 -15 -2 -15 -4z" />
            <path d="M17931 16271 c-9 -16 -7 -18 15 -15 33 3 42 13 24 24 -21 14 -28 12
-39 -9z m29 -1 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11
-4 11 -10z" />
            <path d="M17383 15958 c-2 -16 1 -17 13 -8 18 15 18 27 1 27 -7 0 -14 -9 -14
-19z" />
            <path d="M17405 15940 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3
0 -8 -4 -11 -10z" />
            <path d="M14437 15902 c-17 -19 -14 -32 9 -32 15 0 24 23 15 39 -5 8 -12 6
-24 -7z m23 -6 c0 -2 -7 -7 -16 -10 -8 -3 -12 -2 -9 4 6 10 25 14 25 6z" />
            <path d="M15307 15883 c-4 -3 -7 -13 -7 -22 1 -13 3 -13 11 2 11 19 8 33 -4
20z" />
            <path d="M14283 15767 c-17 -27 -11 -36 13 -20 28 18 29 19 9 26 -8 4 -18 1
-22 -6z" />
            <path d="M12885 15658 c-11 -6 -26 -14 -34 -18 -8 -3 -17 -13 -19 -21 -4 -10
0 -9 15 4 30 27 39 20 12 -10 -20 -22 -17 -21 19 7 53 42 58 66 7 38z" />
            <path d="M15176 15655 c4 -8 10 -12 15 -9 11 6 2 24 -11 24 -5 0 -7 -7 -4 -15z" />
            <path d="M12820 15649 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0
-10 -5 -10 -11z" />
            <path d="M16813 15642 c-8 -5 -7 -11 2 -19 10 -10 14 -9 19 2 6 17 -5 26 -21
17z" />
            <path d="M14210 15630 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5
0 -10 -4 -10 -10z" />
            <path d="M16770 15600 c-12 -30 -37 -44 -26 -15 4 12 2 13 -15 2 -16 -10 -17
-13 -4 -15 14 -3 19 -15 16 -34 0 -5 7 -8 16 -8 13 0 14 3 6 11 -9 9 -6 20 13
45 14 17 21 35 16 38 -5 3 -15 -8 -22 -24z" />
            <path d="M14978 15573 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
            <path d="M16670 15560 c-17 -11 -16 -12 27 -14 18 -1 30 1 27 6 -3 5 -10 6
-15 3 -5 -4 -9 -1 -9 4 0 13 -10 14 -30 1z" />
            <path d="M16614 15522 c4 -7 2 -12 -3 -12 -6 0 -11 -4 -11 -10 0 -15 17 -12
23 5 4 8 1 17 -5 21 -7 4 -8 3 -4 -4z" />
            <path d="M12681 15474 c-31 -26 -40 -44 -20 -44 5 0 7 5 4 10 -3 6 1 10 9 10
9 0 15 6 14 13 -2 6 5 12 14 12 21 0 32 11 20 19 -6 3 -24 -6 -41 -20z" />
            <path d="M16457 15433 c-10 -9 -9 -23 1 -23 5 0 9 7 9 15 0 17 -1 18 -10 8z" />
            <path d="M14721 15423 c-1 -6 4 -15 9 -18 6 -4 7 -12 3 -18 -4 -7 4 -5 17 4
13 9 26 22 28 30 3 11 1 11 -7 -1 -8 -12 -11 -12 -11 -1 0 10 -3 11 -14 2 -11
-9 -15 -9 -19 1 -4 9 -6 9 -6 1z" />
            <path d="M14695 15380 c-10 -11 -16 -22 -13 -25 3 -3 13 4 23 15 10 11 16 22
13 25 -3 3 -13 -4 -23 -15z" />
            <path d="M14613 15333 c0 -8 6 -9 16 -3 9 4 19 7 23 7 3 -1 9 0 12 3 3 3 -7 6
-23 5 -15 0 -28 -5 -28 -12z" />
            <path d="M12497 15315 c-5 -11 -3 -14 5 -9 8 5 9 1 4 -11 -7 -18 -6 -18 14 0
22 20 27 30 9 19 -6 -3 -15 -1 -19 6 -5 8 -9 7 -13 -5z" />
            <path d="M13693 15238 c-17 -19 -15 -19 27 -22 20 -1 26 3 28 20 2 28 -31 29
-55 2z m24 -3 c0 -8 -4 -15 -9 -15 -10 0 -11 14 -1 23 9 10 10 9 10 -8z" />
            <path d="M16120 15236 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21
-21 13z" />
            <path d="M16010 15170 c-13 -8 -12 -10 3 -10 9 0 17 5 17 10 0 12 -1 12 -20 0z" />
            <path d="M14299 15133 c-13 -17 -13 -17 6 -3 11 8 22 16 24 17 2 2 0 3 -6 3
-6 0 -17 -8 -24 -17z" />
            <path d="M13554 15097 c-5 -11 -17 -22 -27 -23 -27 -4 -33 -13 -17 -27 9 -8
11 -8 6 0 -3 6 -2 14 4 18 5 3 10 0 10 -7 0 -8 4 -7 11 4 5 9 16 15 23 12 8
-3 17 0 22 7 5 9 2 10 -12 6 -18 -7 -18 -6 -1 7 13 10 15 15 4 19 -7 3 -17 -4
-23 -16z" />
            <path d="M15867 15082 c-14 -16 -13 -17 15 -18 16 -1 24 2 18 6 -9 6 -9 9 1
12 10 3 9 6 -3 10 -9 4 -23 -1 -31 -10z" />
            <path d="M13369 14943 c-13 -16 -12 -17 4 -4 9 7 17 15 17 17 0 8 -8 3 -21
-13z" />
            <path d="M12040 14915 c-7 -9 -11 -18 -8 -20 3 -3 11 1 18 10 7 9 11 18 8 20
-3 3 -11 -1 -18 -10z" />
            <path d="M15495 14841 c-3 -5 -2 -12 3 -15 5 -3 9 1 9 9 0 17 -3 19 -12 6z" />
            <path d="M13245 14830 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z" />
            <path d="M15452 14823 c6 -7 18 -12 27 -12 12 1 11 2 -3 6 -15 4 -16 7 -5 14
10 6 8 8 -8 6 -17 -1 -19 -4 -11 -14z" />
            <path d="M15414 14816 c-3 -8 -1 -22 4 -32 8 -14 10 -13 14 10 6 29 -9 47 -18
22z" />
            <path d="M15381 14796 c-7 -9 -17 -14 -21 -11 -5 3 -12 0 -16 -6 -4 -7 -3 -9
4 -5 7 4 10 -1 9 -14 -1 -23 -15 -27 -23 -6 -7 18 -30 11 -38 -11 -5 -13 -4
-14 9 -3 12 10 18 10 30 0 15 -12 35 -6 35 12 0 6 7 16 16 23 9 6 15 17 13 24
-4 10 -8 9 -18 -3z" />
            <path d="M15395 14760 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3
0 -8 -4 -11 -10z" />
            <path d="M13085 14689 c-11 -17 1 -21 15 -4 8 9 8 15 2 15 -6 0 -14 -5 -17
-11z" />
            <path d="M13215 14540 c-3 -5 1 -10 9 -10 9 0 16 5 16 10 0 6 -4 10 -9 10 -6
0 -13 -4 -16 -10z" />
            <path d="M14930 14532 c0 -7 -4 -11 -8 -8 -4 2 -14 0 -22 -5 -13 -9 -13 -10 0
-6 8 2 20 -3 26 -11 9 -13 11 -13 16 0 4 8 2 21 -3 29 -7 11 -9 11 -9 1z" />
            <path d="M11620 14521 c0 -6 5 -13 10 -16 6 -3 10 1 10 9 0 9 -4 16 -10 16 -5
0 -10 -4 -10 -9z" />
            <path d="M14805 14459 c-16 -10 -25 -19 -19 -19 7 0 22 9 35 20 30 24 23 24
-16 -1z" />
            <path d="M11553 14465 c4 -5 0 -17 -10 -27 -9 -10 -12 -18 -7 -18 5 0 15 9 21
20 10 15 10 21 0 26 -6 4 -9 3 -4 -1z" />
            <path d="M14840 14419 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0
-10 -5 -10 -11z" />
            <path d="M11466 14375 c-11 -8 -17 -17 -14 -21 4 -3 16 4 27 15 24 24 15 28
-13 6z" />
            <path d="M12980 14380 c12 -8 10 -10 -10 -10 -31 0 -61 -19 -42 -27 6 -2 9
-10 5 -16 -4 -7 8 0 26 16 18 15 40 27 48 27 23 0 7 13 -20 17 -17 2 -19 1 -7
-7z m-20 -24 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10
-2 10 -4z" />
            <path d="M12890 14353 c0 -15 29 -49 30 -35 0 7 -7 20 -15 28 -8 9 -15 11 -15
7z" />
            <path d="M12680 14330 c-8 -5 -12 -10 -8 -10 4 0 -3 -10 -15 -23 -12 -13 -25
-22 -29 -20 -5 2 -8 -1 -8 -7 0 -6 11 -8 28 -4 43 10 47 10 44 -6 -2 -8 0 -11
5 -7 9 10 12 87 4 87 -3 0 -13 -5 -21 -10z m5 -40 c-3 -5 -13 -10 -21 -10 -8
0 -12 5 -9 10 3 6 13 10 21 10 8 0 12 -4 9 -10z" />
            <path d="M14440 14228 c0 -12 3 -17 8 -12 4 5 11 12 15 15 5 3 2 9 -7 12 -12
5 -16 0 -16 -15z" />
            <path d="M12566 14225 c-11 -8 -15 -15 -9 -15 6 0 16 7 23 15 16 19 11 19 -14
0z" />
            <path d="M14406 14225 c-11 -8 -15 -15 -9 -15 6 0 16 7 23 15 16 19 11 19 -14
0z" />
            <path d="M12515 14180 c-11 -13 -12 -19 -3 -28 9 -9 9 -12 -2 -12 -7 0 -11 -2
-9 -5 3 -2 20 -1 37 4 20 6 34 6 38 0 3 -5 2 -8 -3 -7 -18 5 -54 -15 -49 -27
2 -7 -2 -16 -11 -19 -10 -4 -14 -2 -10 8 7 17 -16 39 -29 28 -4 -4 -2 1 5 11
8 10 9 17 3 17 -20 0 -25 -23 -9 -35 17 -12 23 -35 9 -35 -4 0 -13 9 -20 21
-13 20 -14 20 -33 3 -24 -22 -24 -29 1 -31 11 -1 20 2 20 8 0 5 -5 9 -11 9 -5
0 -7 5 -3 12 5 7 11 5 21 -7 19 -27 16 -34 -12 -30 -30 3 -34 -10 -5 -18 10
-3 25 1 31 9 7 8 14 12 17 10 9 -10 62 35 55 46 -4 7 0 9 10 5 9 -3 19 -2 22
4 4 5 12 6 18 2 9 -5 9 -3 0 8 -9 11 -9 19 -2 26 19 19 -11 26 -31 8 -10 -9
-25 -15 -32 -12 -10 4 -9 6 2 6 8 1 20 9 26 19 10 15 8 18 -8 18 -10 0 -25 -7
-33 -16z m75 -14 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0
10 -2 10 -4z" />
            <path d="M14266 14133 c-15 -4 -16 -7 -5 -15 10 -7 8 -8 -9 -5 -15 4 -22 1
-19 -6 3 -7 11 -10 18 -8 8 2 22 4 31 5 9 0 14 4 12 8 -3 4 0 8 6 8 6 0 8 5 5
10 -6 10 -8 11 -39 3z" />
            <path d="M12351 14035 c-32 -35 -40 -52 -19 -39 7 5 8 3 4 -5 -5 -7 -15 -10
-25 -6 -12 5 -13 4 -4 -6 9 -9 19 -9 42 1 32 13 39 24 21 35 -5 3 -10 11 -10
17 0 6 8 4 18 -5 10 -10 23 -17 28 -17 5 0 2 5 -6 11 -12 9 -11 10 7 6 13 -3
26 -1 29 4 3 5 -6 9 -20 9 -14 0 -28 4 -31 10 -3 6 1 7 9 4 9 -3 16 -4 16 -1
0 3 -6 7 -14 10 -8 3 -27 -9 -45 -28z m13 -36 c-3 -5 -10 -7 -15 -3 -5 3 -7
10 -3 15 3 5 10 7 15 3 5 -3 7 -10 3 -15z" />
            <path d="M12280 13960 c-8 -5 -12 -12 -9 -15 4 -3 12 1 19 10 14 17 11 19 -10
5z" />
            <path d="M13703 13799 c-1 -12 3 -16 14 -12 11 4 14 2 9 -5 -4 -7 -2 -12 4
-12 7 0 9 7 6 16 -3 9 -6 19 -6 23 0 16 -26 7 -27 -10z" />
            <path d="M10733 13709 c-2 -23 3 -25 10 -4 4 8 3 16 -1 19 -4 3 -9 -4 -9 -15z" />
            <path d="M10670 13659 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0
-10 -5 -10 -11z" />
            <path d="M10516 13554 c-18 -18 -21 -44 -5 -44 5 0 7 -4 4 -10 -14 -23 5 -7
25 20 12 17 16 30 10 30 -7 0 -14 -9 -17 -21 -4 -15 -8 -17 -14 -8 -5 8 -2 21
8 31 20 22 10 24 -11 2z" />
            <path d="M13100 13456 c0 -9 -5 -16 -12 -16 -6 0 -8 -3 -5 -7 4 -3 -1 -15 -10
-26 -14 -15 -14 -19 -3 -15 19 6 52 66 40 73 -6 4 -10 -1 -10 -9z" />
            <path d="M12935 13360 c3 -5 1 -10 -5 -10 -7 0 -10 -7 -7 -14 5 -11 -1 -14
-24 -11 -32 4 -35 1 -17 -19 10 -10 17 -10 40 2 16 7 26 18 22 23 -3 6 0 16 6
24 10 12 9 15 -4 15 -10 0 -14 -4 -11 -10z" />
            <path d="M10095 13172 c-6 -17 2 -32 19 -32 15 0 54 40 40 41 -5 0 -19 2 -31
3 -12 1 -25 -4 -28 -12z m29 -10 c3 -5 -1 -9 -9 -9 -8 0 -15 4 -15 9 0 4 4 8
9 8 6 0 12 -4 15 -8z" />
            <path d="M12530 13121 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16
-5 3 -10 2 -10 -4z" />
            <path d="M12446 13082 c-2 -4 -1 -14 3 -21 6 -9 11 -10 15 -2 4 6 1 11 -6 11
-9 0 -9 3 2 10 9 6 10 10 3 10 -6 0 -14 -4 -17 -8z" />
            <path d="M12420 13060 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2
0 -4 -4 -4 -10z" />
            <path d="M12488 13053 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
            <path d="M9862 12941 c-11 -7 -11 -9 1 -14 16 -6 27 0 27 15 0 10 -11 10 -28
-1z" />
            <path d="M9739 12813 c-13 -17 -13 -17 6 -3 11 8 22 16 24 17 2 2 0 3 -6 3 -6
0 -17 -8 -24 -17z" />
            <path d="M12010 12822 c0 -5 7 -9 15 -9 8 0 12 4 9 9 -3 4 -9 8 -15 8 -5 0 -9
-4 -9 -8z" />
            <path d="M11960 12790 c-29 -24 -32 -29 -15 -29 11 -1 27 4 35 10 12 8 9 9
-10 4 -24 -6 -24 -6 5 19 17 14 28 25 25 26 -3 0 -21 -13 -40 -30z" />
            <path d="M11875 12741 c-3 -6 3 -9 14 -8 11 0 17 5 15 9 -7 11 -22 10 -29 -1z" />
            <path d="M11620 12589 c0 -5 -5 -7 -10 -4 -6 3 -13 -1 -16 -9 -3 -9 -11 -13
-17 -9 -6 4 -7 1 -2 -7 6 -9 4 -12 -6 -8 -20 8 -85 -47 -68 -57 8 -5 10 -4 5
3 -5 8 1 12 17 12 16 0 36 13 54 33 16 18 35 31 41 29 7 -2 12 3 12 12 0 9 -2
16 -5 16 -3 0 -5 -5 -5 -11z m-65 -58 c-3 -6 -11 -11 -16 -11 -5 0 -4 6 3 14
14 16 24 13 13 -3z" />
            <path d="M11650 12590 c-8 -5 -12 -11 -10 -14 3 -2 14 2 24 10 22 15 10 19
-14 4z" />
            <path d="M11465 12500 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3
0 -8 -4 -11 -10z" />
            <path d="M9324 12458 c-5 -7 -3 -8 6 -3 10 6 12 4 6 -10 -5 -15 -4 -16 9 -5 8
7 12 16 9 21 -7 12 -21 11 -30 -3z" />
            <path d="M9141 12292 c-13 -24 1 -39 17 -19 6 7 7 18 3 26 -7 11 -11 9 -20 -7z" />
            <path d="M10994 12227 l-50 -32 43 -1 c23 0 40 3 37 8 -3 4 -13 8 -22 8 -11 0
-6 8 16 21 17 12 32 23 32 25 0 6 -2 5 -56 -29z" />
            <path d="M9063 12211 c0 -11 5 -18 9 -15 4 3 5 11 1 19 -7 21 -12 19 -10 -4z" />
            <path d="M8910 12119 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0
-10 -5 -10 -11z" />
            <path d="M8887 12091 c-3 -13 -1 -18 9 -14 7 2 10 11 7 19 -7 19 -10 18 -16
-5z" />
            <path d="M10735 12070 c-10 -11 -13 -20 -7 -20 6 0 16 9 22 20 6 11 9 20 7 20
-2 0 -12 -9 -22 -20z" />
            <path d="M10700 12049 c0 -5 -6 -7 -12 -3 -9 5 -9 3 1 -9 15 -19 33 -13 24 8
-6 17 -13 20 -13 4z" />
            <path d="M8768 11983 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
            <path d="M10534 11955 c-4 -8 -11 -12 -17 -8 -6 3 -7 1 -3 -5 11 -19 39 -15
32 4 -5 14 -4 15 9 4 13 -11 14 -10 9 4 -8 20 -23 21 -30 1z" />
            <path d="M8768 11943 c-10 -2 -18 -9 -18 -14 0 -14 16 -11 30 6 7 8 11 14 9
14 -2 -1 -12 -3 -21 -6z" />
            <path d="M8574 11790 c0 -13 4 -16 10 -10 7 7 7 13 0 20 -6 6 -10 3 -10 -10z" />
            <path d="M10113 11713 c-7 -3 -13 -9 -13 -15 0 -7 5 -6 14 1 11 9 15 9 19 -1
2 -7 7 -10 11 -7 9 9 -17 27 -31 22z" />
            <path d="M10054 11675 c-4 -8 -10 -12 -15 -9 -15 9 -10 -3 8 -17 11 -10 14
-10 9 -1 -4 6 -3 12 2 12 5 0 9 7 9 15 0 19 -6 19 -13 0z" />
            <path d="M8430 11635 c-7 -9 -9 -19 -5 -23 4 -4 10 -1 12 6 3 6 9 12 15 12 6
0 7 -7 4 -16 -5 -14 -4 -15 9 -4 9 7 14 13 13 14 -2 1 -10 7 -19 14 -13 10
-19 9 -29 -3z" />
            <path d="M10026 11621 c-3 -4 4 -7 14 -6 27 2 33 10 10 12 -11 1 -22 -2 -24
-6z" />
            <path d="M8426 11518 c-3 -7 -7 -29 -10 -48 -7 -44 9 -19 17 28 6 31 0 45 -7
20z" />
            <path d="M9764 11506 c-12 -6 -18 -18 -16 -26 4 -13 3 -13 -10 -2 -11 9 -18
10 -23 2 -3 -5 4 -14 17 -19 27 -10 78 16 57 29 -6 4 -8 13 -5 19 8 13 8 13
-20 -3z" />
            <path d="M8442 11430 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z" />
            <path d="M9614 11418 c-4 -6 -2 -14 4 -16 7 -2 10 -7 7 -12 -3 -4 5 -6 17 -3
19 4 20 3 8 -6 -9 -7 -10 -11 -3 -11 25 0 23 20 -2 30 -14 5 -23 14 -20 20 3
5 4 10 1 10 -3 0 -8 -5 -12 -12z" />
            <path d="M9640 11420 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5
0 -10 -4 -10 -10z" />
            <path d="M8421 11402 c-1 -7 4 -23 10 -34 9 -18 9 -20 -4 -15 -11 4 -14 2 -10
-9 3 -7 9 -14 13 -14 15 0 18 26 5 55 -7 17 -14 24 -14 17z" />
            <path d="M9590 11403 c0 -7 -5 -12 -11 -10 -6 1 -13 -4 -14 -11 -2 -7 -1 -8 2
-2 3 7 11 7 24 0 21 -12 24 -4 9 20 -6 10 -10 11 -10 3z" />
            <path d="M8430 11306 c0 -20 17 -29 26 -15 5 8 3 9 -6 4 -10 -6 -12 -4 -7 8 3
10 2 17 -3 17 -6 0 -10 -6 -10 -14z" />
            <path d="M8460 11261 c0 -6 4 -13 10 -16 6 -3 7 1 4 9 -7 18 -14 21 -14 7z" />
            <path d="M9233 11169 c-6 -6 -26 -12 -44 -13 -48 -4 -49 -5 -49 -18 0 -9 3 -9
12 0 7 7 19 12 28 12 13 0 13 -1 0 -10 -25 -16 -6 -11 38 10 40 19 52 30 34
30 -4 0 -13 -5 -19 -11z" />
            <path d="M12941 29434 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
            <path d="M14601 27489 c12 -24 26 -31 26 -14 0 8 -5 11 -10 8 -6 -4 -8 0 -5 9
4 8 0 15 -7 15 -10 0 -11 -5 -4 -18z" />
            <path d="M14890 27176 c0 -7 9 -16 20 -19 11 -3 20 -2 20 2 0 3 -9 12 -20 19
-16 10 -20 10 -20 -2z" />
            <path d="M15253 26779 c3 -9 0 -22 -6 -28 -9 -9 -8 -11 6 -6 20 8 21 14 6 35
-9 13 -10 13 -6 -1z" />
            <path d="M15275 26700 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z" />
            <path d="M15652 26301 c-11 -7 -11 -9 0 -14 9 -3 15 1 15 9 0 8 -1 14 -1 14
-1 0 -7 -4 -14 -9z" />
            <path d="M12493 26132 c-9 -6 -6 -11 7 -20 21 -13 26 -6 12 15 -4 7 -13 9 -19
5z" />
            <path d="M12598 26063 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z" />
            <path d="M12608 26043 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
            <path d="M17475 24141 c-3 -5 -2 -12 3 -15 5 -3 9 1 9 9 0 17 -3 19 -12 6z" />
            <path d="M18230 23254 c0 -2 9 -8 20 -14 11 -6 20 -7 20 -2 0 6 -9 12 -20 15
-11 3 -20 3 -20 1z" />
            <path d="M18667 22772 c6 -15 5 -16 -13 -2 -14 11 -16 11 -7 0 13 -14 53 -22
53 -11 0 4 -9 12 -20 18 -17 11 -18 10 -13 -5z" />
            <path d="M18781 22634 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
            <path d="M19070 22301 c0 -6 4 -13 10 -16 6 -3 7 1 4 9 -7 18 -14 21 -14 7z" />
            <path d="M11950 21817 c0 -10 -4 -15 -10 -12 -5 3 -10 1 -10 -4 0 -13 10 -14
27 -3 8 5 9 13 3 22 -8 13 -10 12 -10 -3z" />
            <path d="M19880 17409 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0
-10 -5 -10 -11z" />
            <path d="M19720 17320 c-8 -5 -13 -10 -10 -11 3 0 13 -2 22 -4 11 -2 15 2 11
11 -6 16 -4 16 -23 4z" />
            <path d="M19495 17170 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3
0 -8 -4 -11 -10z" />
            <path d="M13630 16339 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0
-10 -5 -10 -11z" />
            <path d="M17150 15791 c0 -5 7 -12 16 -15 14 -5 15 -4 4 9 -14 17 -20 19 -20
6z" />
            <path d="M12573 15375 c0 -8 4 -12 9 -9 5 3 6 10 3 15 -9 13 -12 11 -12 -6z" />
            <path d="M10970 13966 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z" />
            <path d="M11150 12271 c0 -5 7 -12 16 -15 14 -5 15 -4 4 9 -14 17 -20 19 -20
6z" />
            <path d="M10200 11729 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0
-10 -5 -10 -11z" />
            <path d="M5400 8219 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z" />
            <path d="M5695 8220 c3 -5 11 -10 16 -10 6 0 7 5 4 10 -3 6 -11 10 -16 10 -6
0 -7 -4 -4 -10z" />
            <path d="M5733 8215 c0 -8 4 -15 9 -15 4 0 8 7 8 15 0 8 -4 15 -8 15 -5 0 -9
-7 -9 -15z" />
            <path d="M5766 8204 c-4 -10 -1 -14 6 -12 15 5 23 28 10 28 -5 0 -13 -7 -16
-16z" />
            <path d="M5290 8199 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z" />
            <path d="M5921 8139 c17 -11 46 -12 52 -1 3 4 -11 8 -30 8 -23 1 -30 -1 -22
-7z" />
            <path d="M6127 7887 c-9 -11 -17 -25 -17 -30 0 -6 10 2 21 17 12 15 20 29 17
31 -2 2 -11 -6 -21 -18z" />
            <path d="M6055 7790 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z" />
            <path d="M5966 7628 c3 -4 9 -8 15 -8 5 0 9 4 9 8 0 5 -7 9 -15 9 -8 0 -12 -4
-9 -9z" />
            <path d="M5970 7600 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z" />
            <path d="M5966 7555 c-9 -14 -12 -25 -6 -25 5 0 10 6 10 13 0 9 3 8 9 -2 6 -9
9 -5 9 13 0 32 -2 32 -22 1z" />
            <path d="M5868 7428 c3 -14 1 -19 -6 -14 -6 3 -13 2 -16 -3 -7 -10 19 -23 28
-14 9 10 7 41 -3 47 -5 4 -7 -4 -3 -16z" />
            <path d="M5810 7335 c-11 -13 -10 -14 4 -9 9 3 16 10 16 15 0 13 -6 11 -20 -6z" />
            <path d="M5821 7224 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
            <path d="M5740 7199 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z" />
            <path d="M5701 7114 c-11 -24 -10 -26 3 -14 14 11 16 9 16 -16 0 -16 -4 -24
-8 -19 -4 6 -14 11 -22 13 -10 1 -9 -2 4 -13 25 -19 32 -19 42 3 11 24 13 52
3 47 -4 -3 -12 2 -17 10 -7 13 -10 11 -21 -11z" />
            <path d="M4124 6859 c-3 -6 -3 -13 0 -16 3 -4 6 0 6 8 0 12 2 12 9 0 5 -8 11
-9 15 -3 3 5 -1 12 -9 16 -8 3 -17 1 -21 -5z" />
            <path d="M5573 6834 c4 -7 0 -17 -8 -24 -8 -7 -15 -18 -15 -24 0 -6 5 -4 10 4
6 10 10 11 10 3 0 -7 4 -13 8 -13 11 0 9 47 -2 57 -5 4 -6 3 -3 -3z" />
            <path d="M5563 6754 c1 -21 27 -23 27 -2 0 5 -4 7 -9 4 -5 -4 -11 0 -14 6 -3
7 -4 3 -4 -8z" />
            <path d="M5535 6750 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z" />
            <path d="M3986 6704 c-9 -8 -13 -20 -10 -26 4 -6 1 -9 -7 -6 -8 3 -12 14 -11
24 3 14 1 16 -8 8 -9 -9 -9 -17 0 -34 l12 -22 22 21 c13 12 23 28 23 36 0 19
-2 19 -21 -1z" />
            <path d="M5496 6636 c3 -21 1 -24 -13 -19 -10 3 -15 3 -11 -2 4 -4 3 -14 -3
-22 -17 -19 -1 -16 21 5 18 17 21 44 7 58 -3 4 -4 -5 -1 -20z" />
            <path d="M4575 6631 c-3 -5 -1 -12 5 -16 5 -3 10 1 10 9 0 18 -6 21 -15 7z" />
            <path d="M4558 6603 c-7 -14 -3 -33 6 -33 12 -1 17 28 6 35 -5 3 -10 2 -12 -2z" />
            <path d="M5495 6579 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z" />
            <path d="M4534 6562 c3 -6 -3 -16 -14 -22 -11 -6 -20 -15 -20 -21 0 -6 4 -8 9
-4 5 3 12 1 16 -5 8 -13 -18 -13 -32 1 -17 17 -23 -4 -7 -26 8 -10 14 -13 14
-6 0 7 5 9 10 6 16 -10 -8 -35 -26 -28 -7 3 -12 9 -9 13 3 5 1 11 -3 14 -5 3
-9 -4 -8 -15 0 -11 8 -24 16 -29 16 -10 47 -14 38 -4 -3 3 2 22 12 42 11 22
15 41 9 48 -5 6 -4 15 2 21 8 8 7 13 -2 18 -7 5 -10 4 -5 -3z" />
            <path d="M4420 6445 c-7 -9 -10 -18 -6 -22 3 -3 6 -2 6 4 0 6 10 9 23 6 12 -3
18 -1 12 4 -5 4 -13 12 -16 16 -3 4 -12 1 -19 -8z" />
            <path d="M4475 6421 c8 -13 -12 -24 -27 -14 -30 18 -81 -7 -71 -35 3 -9 3 -14
-1 -10 -10 8 -40 -22 -58 -58 -8 -16 -19 -28 -26 -27 -6 2 -14 -6 -17 -17 -4
-11 -10 -18 -14 -15 -5 2 -20 -13 -35 -35 -14 -22 -32 -40 -39 -40 -8 0 -7 -3
2 -9 12 -8 12 -12 2 -25 -8 -9 -19 -16 -25 -16 -7 0 -25 -13 -40 -29 -21 -22
-25 -31 -14 -37 7 -4 10 -3 5 1 -7 8 30 55 44 55 4 0 3 -16 -1 -36 -5 -20 -7
-44 -5 -53 2 -12 -6 -16 -34 -18 -28 -1 -32 0 -18 8 9 6 22 7 29 3 7 -4 8 -3
4 5 -4 6 -2 18 4 26 7 8 8 15 4 15 -5 0 -15 -9 -22 -20 -14 -23 -34 -27 -26
-5 4 8 1 15 -4 15 -15 0 -24 -18 -13 -25 11 -6 3 -35 -10 -35 -4 0 -6 8 -2 18
3 9 -10 -1 -28 -23 -19 -21 -34 -43 -34 -48 0 -4 -7 -12 -15 -17 -10 -7 -11
-10 -2 -10 6 0 12 -5 12 -11 0 -5 -6 -7 -12 -3 -9 6 -9 3 1 -9 7 -9 11 -23 8
-30 -6 -15 -57 -21 -57 -7 0 10 14 14 40 10 12 -2 12 2 -3 17 -9 11 -14 23
-10 28 5 4 2 5 -5 1 -7 -5 -11 -10 -8 -13 3 -3 -5 -14 -17 -24 -12 -10 -19
-18 -15 -19 4 0 -9 -16 -30 -37 -20 -20 -26 -28 -12 -17 14 10 28 24 32 29 5
6 6 -6 4 -25 -3 -33 -5 -35 -40 -34 -39 0 -39 0 -38 -81 0 -16 -2 -24 -5 -17
-3 6 -10 12 -15 12 -6 0 -8 -3 -5 -7 9 -9 -12 -33 -29 -33 -9 0 -7 7 8 22 15
15 18 23 9 26 -15 5 -40 -26 -41 -50 0 -10 -6 -15 -13 -12 -15 5 -91 -82 -78
-91 6 -3 9 -24 8 -47 -2 -35 -5 -42 -24 -43 -18 -1 -20 2 -13 16 6 9 15 20 22
22 10 4 10 6 1 6 -7 1 -13 6 -13 12 0 5 5 7 10 4 6 -3 10 1 10 9 0 23 -17 20
-25 -4 -3 -11 -10 -20 -14 -20 -4 0 -15 -6 -26 -13 -14 -11 -15 -16 -5 -22 9
-5 11 -4 6 3 -4 7 0 12 10 12 16 0 16 -1 -1 -20 -10 -11 -18 -33 -19 -49 -2
-38 -11 -47 -33 -30 -11 9 -14 10 -9 2 4 -8 2 -13 -7 -13 -10 0 -13 7 -10 19
3 10 12 18 21 16 10 -1 11 0 4 2 -10 4 -10 8 -1 19 6 7 15 11 20 8 5 -3 9 3 9
12 0 15 -4 14 -25 -6 -35 -33 -65 -86 -54 -97 10 -10 1 -53 -11 -53 -10 0 -8
20 4 39 6 9 5 12 -2 7 -5 -3 -12 -1 -14 5 -2 6 -9 -1 -16 -16 -10 -21 -10 -25
0 -19 8 4 10 3 5 -2 -5 -5 -13 -10 -18 -10 -5 -1 -12 -2 -17 -3 -4 0 -7 -18
-7 -38 0 -27 -4 -38 -15 -38 -27 -1 -60 -29 -60 -52 0 -12 -6 -23 -12 -26 -10
-3 -10 -9 0 -26 16 -28 15 -36 -3 -21 -8 7 -16 22 -16 34 -1 17 -2 18 -6 3 -2
-9 -8 -15 -13 -12 -5 3 -12 0 -16 -6 -4 -7 -3 -9 3 -6 6 4 16 0 23 -8 9 -11 9
-17 -2 -28 -7 -7 -19 -12 -26 -9 -6 2 -12 -4 -12 -13 0 -10 -5 -13 -12 -9 -8
5 -8 3 -2 -7 5 -8 9 -24 9 -36 1 -17 -4 -22 -22 -21 -24 2 -30 13 -13 23 6 3
10 0 10 -7 0 -10 3 -9 10 2 12 19 -6 41 -28 34 -9 -3 -3 6 13 21 19 17 23 23
9 16 -35 -17 -48 -38 -29 -48 13 -8 12 -12 -10 -33 -18 -17 -23 -30 -20 -49 5
-25 5 -25 -4 -3 -11 25 -26 28 -38 10 -11 -19 -13 -39 -2 -32 5 3 9 0 9 -6 0
-6 -11 -8 -25 -6 -24 4 -24 3 -20 -40 l4 -44 -35 0 c-25 0 -33 3 -28 13 4 6
-2 4 -14 -7 -17 -14 -19 -23 -11 -37 8 -15 6 -21 -7 -26 -16 -6 -16 -7 2 -14
16 -6 16 -8 2 -8 -24 -1 -32 16 -12 27 15 8 15 10 0 15 -10 4 -14 3 -11 -3 3
-5 -4 -14 -15 -20 -12 -6 -25 -22 -30 -36 -6 -14 -14 -22 -20 -19 -6 4 -7 -1
-3 -11 4 -11 2 -15 -4 -11 -6 4 -28 -16 -49 -43 -21 -28 -41 -50 -45 -50 -7 0
1 -34 16 -72 5 -12 3 -18 -6 -18 -7 0 -10 3 -6 6 3 4 -1 13 -9 21 -11 11 -18
12 -28 4 -7 -6 -15 -8 -18 -5 -3 2 -8 -2 -11 -10 -4 -10 -1 -13 9 -9 22 8 17
-17 -8 -37 -22 -17 -53 -17 -42 1 3 5 9 7 14 4 5 -3 11 -1 15 5 11 17 -21 11
-38 -8 -12 -13 -14 -21 -6 -29 8 -8 8 -13 0 -17 -17 -11 -13 -26 4 -20 8 4 13
9 11 13 -2 3 10 16 25 29 16 13 30 20 30 15 6 -40 5 -53 -7 -53 -17 0 -18 12
-1 30 6 8 2 6 -11 -5 l-23 -19 37 -18 c40 -19 60 -24 31 -7 -17 10 -15 29 4
29 6 0 8 -4 5 -9 -9 -13 31 -20 62 -11 15 5 21 9 13 9 -8 1 -12 4 -9 7 4 3 16
0 28 -6 17 -9 21 -9 15 0 -5 7 -1 11 11 10 30 -4 48 -1 41 7 -10 9 13 33 31
33 13 0 12 -3 -4 -14 -10 -8 -16 -18 -12 -22 4 -4 14 -1 22 5 8 7 20 10 26 6
5 -3 10 -1 10 5 0 7 9 9 23 5 18 -5 19 -4 7 5 -12 9 -8 11 17 6 18 -3 35 -1
38 4 6 10 -61 7 -88 -5 -12 -5 -17 -3 -15 5 5 19 -39 22 -57 4 -9 -9 -18 -14
-21 -12 -2 3 5 13 16 23 23 21 100 32 100 16 0 -6 10 -11 23 -10 21 0 21 1 2
8 -13 5 4 8 45 8 36 0 53 -2 38 -4 -28 -4 -36 -17 -18 -28 6 -3 10 -1 10 5 0
6 10 8 25 4 18 -4 25 -3 23 6 -2 7 3 15 11 18 8 3 11 0 7 -7 -4 -6 -3 -11 3
-11 5 0 12 4 16 10 3 5 12 7 20 3 9 -3 26 2 39 10 13 9 33 13 44 11 11 -3 23
-1 26 4 4 6 -3 8 -16 5 -17 -3 -19 -2 -9 5 11 8 11 10 0 15 -11 4 -11 6 -1 6
7 1 25 -5 40 -13 17 -9 34 -10 47 -5 l20 9 -20 -2 c-11 0 -31 4 -45 11 -23 10
-21 11 15 6 22 -3 47 -8 56 -11 8 -2 24 3 35 12 18 14 18 15 0 6 -16 -8 -80
-1 -91 10 -2 2 37 4 88 5 71 1 85 -1 60 -8 -18 -5 -33 -12 -33 -14 0 -6 50 5
65 14 6 3 17 6 25 7 8 1 25 2 38 3 13 0 20 5 17 12 -4 6 2 9 17 8 12 -1 37 -1
53 0 29 3 28 3 -10 12 -21 5 -45 7 -52 5 -9 -4 -13 3 -13 20 0 24 2 25 55 23
57 -3 77 -12 49 -23 -9 -4 -23 -1 -30 5 -10 8 -21 8 -41 0 l-28 -11 28 5 c19
4 26 2 22 -5 -10 -15 37 -12 61 5 12 8 25 11 30 7 5 -4 2 1 -5 10 -12 16 -10
21 10 38 13 10 20 19 15 20 -5 0 -4 6 1 13 13 15 24 47 17 47 -2 0 -8 -10 -13
-22 -5 -15 -8 7 -7 62 l1 85 38 -2 c26 -1 37 3 38 13 2 22 4 28 17 52 7 12 10
22 6 22 -3 0 0 8 7 17 8 9 9 14 3 10 -7 -4 -15 1 -20 11 -12 27 -14 61 -3 67
5 4 9 -3 9 -14 0 -12 6 -21 13 -21 8 0 7 -3 -3 -10 -8 -5 -11 -12 -7 -17 14
-14 26 19 14 39 -9 14 -9 23 1 39 14 22 10 35 -7 24 -6 -3 -9 -11 -6 -16 4 -5
-1 -9 -9 -9 -22 0 -20 27 2 34 9 3 28 4 42 1 22 -4 25 -1 26 28 0 17 4 41 9
51 5 12 5 17 -1 13 -6 -3 -10 12 -10 38 1 24 3 42 6 40 3 -1 12 13 21 31 19
40 6 47 -14 8 l-14 -26 0 29 c0 28 2 30 46 30 l45 1 -26 -20 c-15 -10 -34 -33
-42 -49 -10 -20 -11 -27 -3 -21 6 5 20 8 30 6 11 -1 16 0 13 3 -3 4 0 13 6 22
8 10 4 9 -10 -3 -12 -11 -22 -14 -22 -8 0 6 12 19 26 29 14 10 26 25 27 33 2
8 6 21 10 28 5 6 4 12 -2 12 -5 0 -12 -6 -14 -12 -4 -10 -6 -10 -6 -1 -1 6 4
15 9 18 6 4 7 13 2 23 -5 9 -9 35 -8 57 1 39 2 40 33 37 44 -4 50 14 48 139
-2 106 -2 107 21 107 21 0 23 -4 17 -27 -6 -24 -5 -25 12 -11 16 13 16 10 -3
-16 -12 -17 -18 -35 -14 -39 4 -5 1 -5 -5 -1 -8 4 -13 1 -13 -8 0 -31 19 -16
40 30 15 36 17 50 9 61 -10 11 -8 12 7 7 10 -4 16 -4 12 1 -4 4 -2 15 5 23 10
13 9 14 -1 8 -18 -11 -7 72 13 94 11 13 12 19 3 28 -15 15 -3 16 22 2 16 -8
18 -8 14 5 -3 8 1 21 10 30 8 9 12 23 9 32 -5 12 -3 14 8 8 11 -7 12 -6 3 6
-12 15 -14 133 -2 145 4 4 6 -25 4 -65 -1 -39 -2 -71 0 -71 2 0 8 10 15 21 8
15 8 19 0 15 -6 -4 -11 -3 -11 3 0 5 8 15 18 20 9 6 12 11 6 11 -6 0 -11 12
-11 28 0 15 -3 34 -6 42 -6 13 -1 16 26 15 29 -1 33 1 29 18 -3 13 -1 18 6 13
8 -5 8 1 1 23 -16 44 -1 141 22 141 4 0 6 -7 3 -15 -4 -9 0 -15 9 -15 10 0 14
-7 11 -22 -4 -21 -4 -21 5 -3 4 11 7 23 4 28 -2 4 3 15 13 24 15 16 15 17 -2
23 -12 5 -14 9 -6 14 7 4 18 4 25 0 6 -4 9 -4 5 1 -4 4 -2 15 5 23 9 13 9 14
0 8 -7 -4 -13 -2 -13 3 0 6 -5 11 -11 11 -5 0 -8 -4 -4 -9z m-78 -43 c2 -17 0
-19 -7 -8 -5 8 -6 18 -4 22 8 12 10 10 11 -14z m-27 -32 c0 -2 -8 -10 -17 -17
-16 -13 -17 -12 -4 4 13 16 21 21 21 13z m25 -17 c-4 -5 -12 -9 -18 -8 -9 0
-8 2 1 6 6 2 9 10 6 15 -4 7 -2 8 5 4 6 -4 9 -12 6 -17z m-75 -74 c0 -8 -2
-15 -4 -15 -2 0 -6 7 -10 15 -3 8 -1 15 4 15 6 0 10 -7 10 -15z m-25 -5 c3 -5
1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m-60 -103 c0
-18 -4 -35 -10 -39 -6 -5 -4 -12 5 -18 13 -9 13 -10 -1 -10 -8 0 -24 7 -35 15
-19 15 -18 16 9 40 26 23 27 30 3 26 -5 -1 -3 5 4 14 17 20 25 11 25 -28z
m-161 -178 c-3 -5 -10 -6 -16 -3 -7 5 -9 1 -5 -9 3 -10 2 -17 -2 -17 -14 0
-20 27 -9 38 12 12 41 4 32 -9z m-40 -35 c5 -14 4 -15 -9 -4 -17 14 -19 20 -6
20 5 0 12 -7 15 -16z m41 -3 c-3 -6 -11 -11 -16 -11 -5 0 -4 6 3 14 14 16 24
13 13 -3z m172 -43 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z
m-427 -263 c-12 -13 -27 -22 -31 -19 -5 3 -17 0 -26 -5 -10 -6 -13 -11 -8 -11
6 0 5 -3 -1 -8 -16 -10 -28 -52 -15 -52 5 0 12 7 14 15 4 11 4 10 3 -2 -2 -22
-66 -27 -66 -5 0 8 -5 10 -12 6 -7 -4 -2 4 12 18 14 16 27 22 31 16 3 -5 9 0
12 11 3 13 1 19 -5 14 -7 -4 -8 -1 -4 6 5 7 14 10 22 7 7 -3 16 -1 19 4 3 5
12 7 19 4 8 -3 20 2 27 10 8 8 18 15 23 16 5 0 -1 -11 -14 -25z m-420 -479 c0
-11 -19 -15 -25 -6 -3 5 1 10 9 10 9 0 16 -2 16 -4z m535 -166 c3 -5 1 -10 -4
-10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m-752 -80 c3 -11 2 -20
-3 -20 -8 0 -20 24 -20 41 0 17 17 1 23 -21z m-225 -295 l2 -45 -44 5 -44 4 4
-47 4 -47 -15 34 c-14 31 -14 38 0 68 9 18 20 33 24 33 4 0 13 11 19 24 18 41
46 24 50 -29z m437 -55 c-27 -12 -43 -12 -25 0 8 5 22 9 30 9 10 0 8 -3 -5 -9z
m-50 -10 c3 -5 -1 -10 -10 -10 -9 0 -13 5 -10 10 3 6 8 10 10 10 2 0 7 -4 10
-10z m-515 -50 c0 -5 -7 -7 -15 -4 -8 4 -12 10 -9 15 6 11 24 2 24 -11z m-85
-68 c-3 -3 -11 0 -18 7 -9 10 -8 11 6 5 10 -3 15 -9 12 -12z m130 -32 c-3 -5
-11 -10 -16 -10 -6 0 -7 5 -4 10 3 6 11 10 16 10 6 0 7 -4 4 -10z" />
            <path d="M4206 6125 c-9 -26 -7 -32 5 -12 6 10 9 21 6 23 -2 3 -7 -2 -11 -11z" />
            <path d="M3705 5550 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z" />
            <path d="M2924 4643 c3 -6 -6 -23 -21 -38 -20 -19 -23 -24 -9 -19 13 5 17 4
12 -4 -4 -7 -1 -12 10 -12 13 0 14 3 5 13 -11 14 -5 27 11 27 5 0 7 -4 3 -9
-3 -5 0 -13 6 -17 15 -9 3 46 -13 61 -8 7 -9 6 -4 -2z" />
            <path d="M3625 6290 c-10 -11 -16 -22 -13 -25 3 -3 13 6 22 20 20 30 16 32 -9
5z" />
            <path d="M4424 6291 c4 -5 1 -11 -5 -13 -7 -2 -6 -8 4 -16 12 -10 17 -10 23
-1 5 8 3 9 -6 4 -9 -5 -10 -2 -5 14 4 13 2 21 -5 21 -6 0 -9 -4 -6 -9z" />
            <path d="M4411 6245 c0 -5 3 -16 8 -23 7 -11 9 -10 14 3 4 9 3 14 -2 10 -5 -3
-12 1 -14 7 -3 7 -6 9 -6 3z" />
            <path d="M5290 6196 c0 -2 7 -9 15 -16 9 -7 15 -8 15 -2 0 5 -7 12 -15 16 -8
3 -15 4 -15 2z" />
            <path d="M4376 6133 c-4 -9 -1 -21 5 -27 9 -8 10 -7 4 3 -4 7 -2 16 5 21 8 5
8 10 2 14 -5 3 -13 -2 -16 -11z" />
            <path d="M3370 6049 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z" />
            <path d="M3336 5963 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z" />
            <path d="M4263 5863 c4 -3 1 -13 -5 -22 -8 -10 -4 -9 10 3 26 23 27 26 5 26
-9 0 -13 -3 -10 -7z" />
            <path d="M3200 5835 c-7 -8 -9 -15 -4 -15 5 0 -4 -9 -21 -20 -25 -17 -26 -19
-7 -17 11 0 19 3 17 5 -2 2 8 14 22 26 16 14 22 25 16 29 -6 4 -16 0 -23 -8z" />
            <path d="M4260 5815 c-7 -9 -8 -15 -2 -15 5 0 12 7 16 15 3 8 4 15 2 15 -2 0
-9 -7 -16 -15z" />
            <path d="M5156 5816 c3 -9 11 -16 16 -16 13 0 5 23 -10 28 -7 2 -10 -2 -6 -12z" />
            <path d="M5134 5792 c-1 -15 1 -21 3 -14 3 6 9 9 15 6 6 -4 5 2 -1 15 -15 27
-16 27 -17 -7z" />
            <path d="M3886 5782 c-3 -5 1 -9 9 -9 8 0 12 4 9 9 -3 4 -7 8 -9 8 -2 0 -6 -4
-9 -8z" />
            <path d="M4181 5732 c-13 -25 -10 -65 4 -51 8 8 15 69 8 69 -1 0 -7 -8 -12
-18z" />
            <path d="M3811 5721 c-7 -5 -10 -14 -7 -20 11 -18 25 -13 23 9 -1 11 -1 20 -2
20 0 0 -6 -4 -14 -9z" />
            <path d="M5095 5701 c-3 -5 -2 -12 3 -15 5 -3 9 1 9 9 0 17 -3 19 -12 6z" />
            <path d="M4173 5612 c0 -12 5 -22 9 -22 11 0 10 12 -2 30 -7 12 -9 10 -7 -8z" />
            <path d="M4090 5500 c0 -5 5 -10 12 -10 9 0 9 -3 0 -12 -7 -7 -12 -17 -12 -22
0 -6 7 -2 15 8 8 11 18 16 22 12 5 -4 -1 -15 -13 -25 -20 -16 -22 -41 -2 -41
5 0 8 6 5 13 -2 6 2 19 10 28 14 17 8 53 -8 43 -5 -3 -9 0 -9 5 0 6 -4 11 -10
11 -5 0 -10 -4 -10 -10z" />
            <path d="M4995 5460 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z" />
            <path d="M4965 5380 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z" />
            <path d="M2680 5255 c7 -8 17 -12 22 -9 6 4 5 10 -3 15 -22 14 -33 10 -19 -6z" />
            <path d="M3404 5238 l-19 -23 23 19 c21 18 27 26 19 26 -2 0 -12 -10 -23 -22z" />
            <path d="M4022 5248 c-7 -7 -12 -17 -12 -22 0 -6 8 -2 17 9 15 20 15 20 10 -3
-6 -27 9 -20 16 8 6 22 -12 27 -31 8z" />
            <path d="M2594 5175 c-4 -8 -9 -13 -13 -10 -9 5 -7 -32 2 -41 3 -4 14 2 24 12
17 17 17 17 1 11 -22 -8 -23 1 -1 25 10 11 12 18 4 18 -6 0 -14 -7 -17 -15z" />
            <path d="M3971 5083 c-6 -14 -9 -28 -6 -31 3 -3 5 0 5 7 0 7 5 9 12 5 7 -5 8
-3 3 6 -4 7 -2 16 5 20 7 4 8 10 3 13 -6 4 -15 -5 -22 -20z" />
            <path d="M3286 5072 c-3 -5 1 -9 9 -9 8 0 15 4 15 9 0 4 -4 8 -9 8 -6 0 -12
-4 -15 -8z" />
            <path d="M2529 5053 c-13 -16 -12 -17 4 -4 9 7 17 15 17 17 0 8 -8 3 -21 -13z" />
            <path d="M4850 5030 c0 -11 0 -23 -1 -27 0 -4 4 -9 10 -11 6 -2 9 10 8 27 -2
34 -17 43 -17 11z" />
            <path d="M3193 4988 c-19 -25 -24 -38 -14 -38 6 0 11 7 11 16 0 8 4 13 8 10 5
-3 9 1 9 9 0 18 -2 18 -14 3z" />
            <path d="M4775 4900 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z" />
            <path d="M3078 4853 c-20 -23 -12 -43 15 -42 18 1 19 2 4 6 -18 5 -18 5 0 18
13 10 15 17 7 25 -7 7 -15 6 -26 -7z m22 -2 c0 -5 -7 -12 -16 -15 -14 -5 -15
-4 -4 9 14 17 20 19 20 6z" />
            <path d="M3850 4861 c0 -5 7 -12 16 -15 14 -5 15 -4 4 9 -14 17 -20 19 -20 6z" />
            <path d="M3890 4861 c0 -6 4 -13 10 -16 6 -3 7 1 4 9 -7 18 -14 21 -14 7z" />
            <path d="M3853 4810 c1 -11 5 -20 10 -20 5 0 4 -6 -2 -13 -8 -10 -7 -14 3 -14
7 0 12 9 11 21 -1 12 -3 27 -4 34 -2 21 -20 13 -18 -8z" />
            <path d="M3880 4819 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z" />
            <path d="M4775 4820 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z" />
            <path d="M4744 4808 c5 -23 18 -48 25 -48 4 0 8 10 8 22 2 18 0 20 -7 8 -8
-13 -10 -13 -10 2 0 9 -4 19 -9 22 -5 4 -8 0 -7 -6z" />
            <path d="M5181 4704 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
            <path d="M3800 4600 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z" />
            <path d="M2020 4540 c8 -5 16 -18 16 -29 1 -11 -2 -18 -7 -15 -4 2 -5 12 -2
22 3 9 2 14 -2 10 -5 -5 -9 -21 -10 -38 -2 -29 -1 -29 17 -14 10 10 18 28 18
40 0 18 4 22 18 17 9 -3 14 -3 10 2 -4 4 -22 9 -40 11 -24 3 -29 1 -18 -6z" />
            <path d="M5221 4534 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
            <path d="M3045 4460 c3 -5 10 -10 16 -10 5 0 9 5 9 10 0 6 -7 10 -16 10 -8 0
-12 -4 -9 -10z" />
            <path d="M2691 4407 c-6 -8 -7 -17 -3 -21 4 -5 -2 -6 -13 -4 -12 2 -19 -2 -18
-9 2 -6 -5 -14 -15 -17 -10 -3 -19 -16 -19 -28 -2 -16 0 -19 6 -9 5 7 11 9 13
5 3 -5 4 -4 2 3 -3 12 27 43 41 43 4 0 0 -9 -9 -19 -10 -10 -13 -21 -9 -24 14
-9 36 9 29 22 -4 6 -3 11 2 11 5 0 9 13 8 30 -1 35 0 34 -15 17z" />
            <path d="M5236 4404 c-3 -8 -1 -16 4 -19 4 -3 11 1 13 7 3 8 6 6 6 -5 1 -10
-5 -20 -13 -23 -9 -3 -12 -13 -8 -27 5 -20 6 -20 18 8 18 41 17 72 -1 72 -8 0
-16 -6 -19 -13z" />
            <path d="M2725 4370 c-3 -6 1 -7 9 -4 18 7 21 14 7 14 -6 0 -13 -4 -16 -10z" />
            <path d="M2740 4354 c-14 -8 -17 -13 -7 -10 10 3 27 6 38 6 10 0 19 5 19 10 0
14 -21 12 -50 -6z" />
            <path d="M5261 4310 c-1 -8 -4 -26 -7 -40 -5 -22 -4 -23 6 -7 6 10 9 28 7 40
-2 12 -5 15 -6 7z" />
            <path d="M5252 4175 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z" />
            <path d="M2014 4148 c3 -41 10 -55 21 -39 3 6 2 11 -4 11 -6 0 -8 6 -5 14 3 8
1 23 -5 33 -8 14 -10 11 -7 -19z" />
            <path d="M5252 4080 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z" />
            <path d="M2010 4085 c0 -10 45 -75 53 -75 4 0 7 7 7 16 0 8 -5 12 -11 8 -8 -4
-9 1 -4 17 4 17 3 20 -3 10 -8 -11 -11 -9 -17 8 -5 18 -25 31 -25 16z" />
            <path d="M2097 3949 c7 -9 10 -19 6 -22 -3 -4 -1 -13 6 -21 9 -11 8 -15 -4
-19 -9 -4 -12 -13 -8 -23 5 -14 8 -13 19 7 7 13 9 28 4 33 -5 5 -6 14 -4 21 3
6 -3 18 -13 26 -16 12 -17 12 -6 -2z" />
            <path d="M5262 3935 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z" />
            <path d="M4632 3888 c17 -6 34 -6 45 1 14 8 8 10 -27 9 -41 -1 -43 -2 -18 -10z" />
            <path d="M4548 3883 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z" />
            <path d="M5254 3872 c-7 -4 -8 -15 -3 -29 11 -30 14 -29 15 7 1 17 1 30 0 30
-1 0 -6 -4 -12 -8z" />
            <path d="M4525 3860 c-3 -5 3 -10 14 -10 12 0 21 5 21 10 0 6 -6 10 -14 10 -8
0 -18 -4 -21 -10z" />
            <path d="M4578 3863 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
            <path d="M5030 3860 c-13 -9 -13 -10 0 -10 8 0 22 5 30 10 13 9 13 10 0 10 -8
0 -22 -5 -30 -10z" />
            <path d="M3466 3758 c-3 -7 -4 -20 -4 -28 0 -8 -3 -26 -7 -40 -6 -24 -6 -25
10 -5 9 12 14 29 10 43 -3 12 -1 22 5 22 5 0 10 5 10 10 0 14 -20 12 -24 -2z" />
            <path d="M3505 3760 c-3 -5 1 -10 9 -10 9 0 16 5 16 10 0 6 -4 10 -9 10 -6 0
-13 -4 -16 -10z" />
            <path d="M4374 3763 c3 -6 2 -14 -4 -18 -5 -3 -10 -11 -10 -16 0 -6 4 -8 9 -4
5 3 12 -3 14 -12 3 -10 4 -3 3 15 -2 17 -6 35 -11 40 -5 4 -5 2 -1 -5z" />
            <path d="M3398 3753 c-33 -5 -37 -28 -6 -36 31 -9 36 1 7 13 -22 9 -21 10 10
7 21 -1 35 3 37 11 1 7 0 8 -3 2 -2 -5 -9 -10 -14 -10 -6 0 -7 5 -4 10 3 6 5
9 3 9 -2 -1 -15 -4 -30 -6z" />
            <path d="M3300 3730 c-13 -8 -13 -10 2 -10 9 0 20 5 23 10 8 13 -5 13 -25 0z" />
            <path d="M3337 3723 c-14 -14 -6 -23 21 -22 17 1 20 3 9 6 -9 2 -15 9 -12 14
7 10 -8 12 -18 2z" />
            <path d="M3176 3707 c-16 -12 -16 -13 2 -13 10 0 19 6 19 13 0 7 -1 13 -2 13
-1 0 -9 -6 -19 -13z" />
            <path d="M3430 3711 c0 -5 -10 -12 -22 -14 -21 -4 -20 -4 3 -6 16 0 29 5 33
14 3 8 1 15 -4 15 -6 0 -10 -4 -10 -9z" />
            <path d="M3120 3700 c-8 -5 -10 -10 -5 -10 6 0 17 5 25 10 8 5 11 10 5 10 -5
0 -17 -5 -25 -10z" />
            <path d="M4353 3704 c3 -4 2 -16 -4 -28 -10 -20 -10 -20 5 -2 19 24 20 36 4
36 -6 0 -8 -3 -5 -6z" />
            <path d="M3480 3674 c0 -14 -4 -23 -10 -19 -15 9 -12 -4 4 -21 13 -12 15 -12
14 3 -1 10 2 28 6 41 5 13 3 22 -3 22 -6 0 -11 -12 -11 -26z" />
            <path d="M2910 3659 c0 -7 -13 -9 -37 -6 -21 3 -29 3 -18 0 l20 -6 -20 -8
c-14 -6 -9 -7 15 -3 41 8 86 34 57 34 -9 0 -17 -5 -17 -11z" />
            <path d="M4359 3649 c-9 -18 -13 -19 -21 -8 -8 12 -9 11 -5 -1 2 -8 10 -15 18
-15 8 0 17 -4 21 -10 4 -5 10 -6 15 -2 4 5 1 12 -7 18 -13 7 -13 10 -1 18 9 6
10 11 3 15 -6 4 -16 -3 -23 -15z" />
            <path d="M2780 3640 c-13 -9 -13 -10 0 -9 8 0 24 4 35 9 l20 9 -20 0 c-11 0
-27 -4 -35 -9z" />
            <path d="M2725 3630 c-3 -6 -16 -8 -28 -5 -13 4 -34 2 -47 -4 -21 -8 -18 -9
20 -7 39 2 105 25 73 26 -6 0 -15 -5 -18 -10z" />
            <path d="M2800 3620 c0 -5 9 -10 21 -10 11 0 17 5 14 10 -3 6 -13 10 -21 10
-8 0 -14 -4 -14 -10z" />
            <path d="M2872 3622 c-6 -4 10 -9 35 -10 25 -1 44 0 42 2 -10 8 -66 14 -77 8z" />
            <path d="M4215 3283 c3 -3 1 -11 -4 -17 -6 -7 -4 -18 4 -29 11 -16 12 -15 10
13 -1 17 -5 33 -9 35 -3 3 -4 2 -1 -2z" />
            <path d="M3292 3170 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z" />
            <path d="M3326 3095 c-9 -26 -7 -32 5 -12 6 10 9 21 6 23 -2 3 -7 -2 -11 -11z" />
            <path d="M3295 3068 c2 -18 4 -46 5 -63 1 -26 2 -27 9 -10 5 11 7 31 6 45 -1
14 -3 33 -4 43 0 9 -5 17 -10 17 -5 0 -7 -15 -6 -32z" />
            <path d="M4123 2988 c0 -7 -1 -26 -2 -43 -1 -23 -3 -27 -11 -15 -5 8 -10 11
-10 5 0 -12 20 -43 20 -31 0 5 7 3 15 -4 10 -9 15 -9 15 -1 0 6 -4 11 -8 11
-5 0 -9 13 -9 30 0 16 5 27 10 23 5 -3 4 4 -2 16 -13 24 -19 27 -18 9z" />
            <path d="M3220 2805 c-11 -13 -10 -14 4 -9 9 3 16 2 16 -4 0 -6 -6 -12 -12
-15 -8 -2 -9 -8 -4 -13 5 -5 11 -3 14 5 4 11 6 11 14 0 6 -10 7 -6 3 11 -10
36 -20 43 -35 25z" />
            <path d="M4061 2754 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
            <path d="M3227 2727 c3 -5 -1 -13 -8 -18 -11 -7 -11 -11 -1 -17 8 -5 10 -16 6
-27 -5 -13 -4 -16 5 -11 8 5 10 18 7 32 -4 13 -3 24 2 24 5 0 9 6 9 13 0 8 -6
14 -13 14 -7 0 -10 -5 -7 -10z" />
            <path d="M4056 2717 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 -10 9 -11 8 -5 -6z" />
            <path d="M4033 2630 c-1 -11 2 -18 6 -15 5 2 11 -5 14 -17 4 -12 9 -19 12 -16
6 5 -21 68 -28 68 -2 0 -3 -9 -4 -20z" />
            <path d="M3210 2631 c0 -6 4 -13 10 -16 6 -3 7 1 4 9 -7 18 -14 21 -14 7z" />
            <path d="M3211 2584 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
            <path d="M3971 2152 c-8 -20 -16 -43 -19 -51 -3 -9 -1 -12 6 -7 12 7 37 85 30
92 -2 2 -10 -13 -17 -34z" />
            <path d="M3920 2094 c0 -5 7 -18 15 -28 8 -11 15 -16 15 -10 0 5 -7 18 -15 28
-8 11 -15 16 -15 10z" />
            <path d="M3619 2070 c-20 -13 -5 -22 21 -12 18 7 20 10 8 14 -8 4 -21 2 -29
-2z" />
            <path d="M3622 2041 c-11 -7 -10 -10 3 -15 23 -8 53 -8 59 2 3 5 -9 7 -27 5
-29 -2 -30 -2 -7 6 21 8 22 9 5 10 -11 0 -26 -3 -33 -8z" />
            <path d="M3703 2043 c4 -3 2 -12 -5 -20 -8 -11 -7 -12 6 -7 18 7 22 34 4 34
-6 0 -8 -3 -5 -7z" />
            <path d="M3770 2020 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z" />
            <path d="M25394 7333 c-18 -15 -18 -15 8 -9 15 3 38 2 53 -4 17 -7 24 -7 20 0
-3 6 -14 10 -23 10 -13 0 -14 2 -3 9 9 6 6 9 -11 9 -13 0 -33 -7 -44 -15z" />
            <path d="M25485 7340 c-3 -5 1 -10 9 -10 9 0 16 5 16 10 0 6 -4 10 -9 10 -6 0
-13 -4 -16 -10z" />
            <path d="M25538 7343 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z" />
            <path d="M25590 7330 c0 -7 -14 -8 -37 -4 -47 8 -41 0 9 -14 32 -9 37 -8 41 9
3 10 1 19 -4 19 -5 0 -9 -5 -9 -10z" />
            <path d="M25370 7301 c0 -17 19 -22 41 -11 11 7 18 14 15 17 -3 3 -12 0 -21
-7 -10 -8 -15 -9 -15 -1 0 6 -4 11 -10 11 -5 0 -10 -4 -10 -9z" />
            <path d="M25647 7295 c3 -8 9 -12 14 -9 12 7 11 11 -5 17 -10 4 -13 1 -9 -8z" />
            <path d="M25315 7260 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z" />
            <path d="M25150 7093 c0 -16 -2 -16 -11 -3 -8 12 -10 11 -7 -5 2 -11 7 -19 13
-18 13 2 27 43 15 43 -6 0 -10 -8 -10 -17z" />
            <path d="M25956 7099 c4 -7 44 0 44 7 0 2 -11 3 -24 2 -14 -1 -23 -5 -20 -9z" />
            <path d="M25138 7038 c15 -15 16 -31 0 -22 -7 5 -8 3 -3 -5 10 -18 27 2 19 22
-3 9 -11 17 -17 17 -7 0 -7 -4 1 -12z" />
            <path d="M25063 6936 c-10 -11 -9 -15 3 -20 9 -3 14 -13 11 -21 -4 -9 -1 -12
8 -8 8 3 13 9 10 13 -3 5 2 16 11 24 8 9 11 16 5 16 -5 0 -13 -4 -16 -10 -3
-5 -11 -10 -17 -10 -7 0 -6 6 2 15 16 19 -1 19 -17 1z" />
            <path d="M25050 6821 c0 -6 4 -13 10 -16 6 -3 7 1 4 9 -7 18 -14 21 -14 7z" />
            <path d="M24977 6673 c-2 -13 0 -26 4 -29 5 -3 9 4 9 15 0 23 16 37 22 19 3
-8 7 -6 11 5 5 13 1 16 -18 15 -16 -2 -25 -10 -28 -25z" />
            <path d="M25001 6644 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
            <path d="M6850 6540 l-25 -8 27 -1 c15 -1 30 4 33 9 7 11 0 11 -35 0z" />
            <path d="M28235 6541 c-9 -16 12 -22 37 -11 21 10 21 10 -5 5 -19 -4 -26 -2
-22 5 3 5 4 10 1 10 -3 0 -7 -4 -11 -9z" />
            <path d="M30654 6541 c8 -12 46 -21 46 -11 0 8 -24 20 -41 20 -6 0 -8 -4 -5
-9z" />
            <path d="M23230 6530 c-13 -9 -13 -10 0 -10 8 0 22 5 30 10 13 9 13 10 0 10
-8 0 -22 -5 -30 -10z" />
            <path d="M30517 6514 c-10 -10 2 -24 19 -24 8 0 13 4 10 9 -4 5 0 12 6 14 7 3
4 6 -8 6 -11 1 -24 -2 -27 -5z" />
            <path d="M23218 6503 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z" />
            <path d="M28195 6500 c-8 -13 5 -13 25 0 13 8 13 10 -2 10 -9 0 -20 -4 -23
-10z" />
            <path d="M6848 6493 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
            <path d="M6980 6490 c20 -13 33 -13 25 0 -3 6 -14 10 -23 10 -15 0 -15 -2 -2
-10z" />
            <path d="M6663 6455 c0 -6 -3 -23 -7 -38 -6 -24 -5 -27 7 -15 8 7 12 20 9 28
-5 12 -3 13 9 3 11 -9 14 -7 14 11 0 13 -6 22 -16 22 -9 0 -16 -5 -16 -11z" />
            <path d="M9425 6460 c-7 -12 45 -39 64 -32 9 3 11 0 6 -8 -6 -9 -4 -11 5 -5
10 6 9 11 -4 21 -10 7 -15 17 -11 23 3 7 0 8 -9 5 -9 -3 -13 -10 -10 -15 6
-11 1 -11 -21 -3 -13 5 -14 8 -3 15 9 6 9 9 1 9 -6 0 -14 -4 -18 -10z" />
            <path d="M13804 6457 c5 -14 2 -17 -12 -13 -13 4 -18 0 -19 -14 -1 -11 3 -20
8 -20 6 0 7 5 4 10 -3 6 1 10 9 10 20 0 27 13 14 30 -8 12 -9 11 -4 -3z" />
            <path d="M14072 6455 c-3 -5 -2 -7 3 -3 4 4 17 3 28 -3 12 -7 17 -7 13 -1 -3
6 0 12 6 15 7 2 0 4 -15 3 -16 0 -31 -5 -35 -11z" />
            <path d="M18598 6463 c6 -2 10 -9 6 -14 -3 -5 2 -9 12 -9 13 0 14 3 4 15 -7 8
-18 15 -24 14 -7 0 -6 -2 2 -6z" />
            <path d="M22984 6451 c0 -11 5 -17 13 -14 10 4 11 9 2 19 -15 18 -15 18 -15
-5z" />
            <path d="M28380 6459 c0 -5 4 -8 9 -5 5 4 12 0 14 -6 4 -10 6 -9 6 1 1 10 7 7
20 -8 11 -13 25 -20 30 -16 16 9 13 28 -3 22 -7 -3 -19 0 -25 6 -15 15 -51 19
-51 6z" />
            <path d="M30430 6460 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z" />
            <path d="M30740 6463 c0 -16 66 -47 84 -40 22 9 11 27 -15 27 -11 0 -18 4 -15
8 3 5 -1 9 -9 9 -11 0 -12 -4 -4 -14 16 -19 -1 -16 -23 4 -10 9 -18 12 -18 6z
m77 -29 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z" />
            <path d="M11870 6450 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5
0 -10 -4 -10 -10z" />
            <path d="M18545 6450 c26 -11 35 -12 35 -2 0 4 -12 8 -27 9 -24 1 -25 1 -8 -7z" />
            <path d="M30330 6456 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z" />
            <path d="M30374 6448 c-4 -7 -3 -8 4 -4 7 4 12 2 12 -4 0 -6 5 -8 10 -5 6 3
10 10 10 16 0 13 -27 11 -36 -3z" />
            <path d="M11818 6443 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z" />
            <path d="M30339 6428 c11 -15 41 -15 41 -1 0 3 -6 3 -14 0 -7 -3 -20 0 -27 6
-11 10 -11 9 0 -5z" />
            <path d="M14196 6421 c-4 -5 3 -7 14 -4 23 6 26 13 6 13 -8 0 -17 -4 -20 -9z" />
            <path d="M13750 6374 c-14 -36 -7 -41 14 -10 10 15 12 27 6 31 -6 3 -15 -6
-20 -21z" />
            <path d="M8894 6370 c0 -11 5 -20 10 -20 6 0 5 -5 -2 -12 -7 -7 -12 -16 -12
-21 0 -5 6 -2 14 5 15 16 16 60 1 65 -6 2 -11 -6 -11 -17z" />
            <path d="M23520 6380 c-8 -5 -10 -10 -5 -10 6 0 17 5 25 10 8 5 11 10 5 10 -5
0 -17 -5 -25 -10z" />
            <path d="M27891 6373 c-6 -10 -11 -12 -11 -5 0 6 -5 12 -11 12 -8 0 -8 -4 1
-15 14 -17 4 -39 -13 -28 -7 4 -6 0 2 -10 13 -16 36 -14 26 3 -3 5 -1 11 5 15
5 3 10 1 10 -6 0 -18 10 -5 15 19 5 28 -11 37 -24 15z" />
            <path d="M28555 6377 c-16 -13 -17 -14 -1 -20 11 -4 15 -3 10 4 -4 8 1 9 17 4
13 -3 20 -3 14 2 -5 5 -13 12 -16 16 -3 4 -14 1 -24 -6z" />
            <path d="M28601 6347 c-8 -12 -10 -19 -6 -15 5 4 14 0 22 -10 13 -15 13 -15 4
5 -8 16 -8 22 1 25 8 3 8 7 2 11 -5 3 -16 -4 -23 -16z" />
            <path d="M18798 6343 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
            <path d="M24970 6326 c0 -9 5 -16 10 -16 6 0 10 4 10 9 0 6 -4 13 -10 16 -5 3
-10 -1 -10 -9z" />
            <path d="M7377 6299 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z" />
            <path d="M8819 6302 c0 -4 -2 -15 -4 -24 -5 -26 8 -29 39 -9 37 25 37 34 -1
37 -18 1 -33 -1 -34 -4z m41 -13 c0 -4 -4 -11 -10 -14 -5 -3 -10 -1 -10 5 0 6
-5 8 -12 4 -6 -4 -8 -3 -5 3 7 11 37 13 37 2z" />
            <path d="M28651 6293 c6 -7 17 -13 23 -12 7 0 6 2 -2 6 -6 2 -10 8 -8 12 3 4
-1 8 -9 8 -11 0 -12 -4 -4 -14z" />
            <path d="M28687 6295 c3 -8 9 -12 14 -9 12 7 11 11 -5 17 -10 4 -13 1 -9 -8z" />
            <path d="M31023 6300 c4 -16 16 -17 57 -6 25 6 25 6 3 -5 -13 -7 -23 -19 -23
-28 1 -14 2 -14 11 1 5 9 16 15 23 12 9 -4 14 0 12 10 -3 24 -11 27 -41 16
-22 -8 -31 -8 -37 2 -7 10 -9 9 -5 -2z" />
            <path d="M18133 6288 c3 -9 1 -19 -6 -22 -9 -3 -9 -5 0 -5 18 -1 27 19 13 32
-10 9 -12 8 -7 -5z" />
            <path d="M18090 6260 c0 -6 7 -13 15 -17 24 -9 27 1 5 15 -13 8 -20 8 -20 2z" />
            <path d="M24970 6258 c0 -4 4 -8 9 -8 6 0 12 4 15 8 3 5 -1 9 -9 9 -8 0 -15
-4 -15 -9z" />
            <path d="M24991 6241 c-11 -7 -11 -9 0 -14 10 -4 9 -6 -3 -6 -10 -1 -18 -5
-18 -10 0 -20 35 -3 36 17 1 12 1 22 0 22 0 0 -7 -4 -15 -9z" />
            <path d="M6426 6215 c-19 -15 -15 -52 5 -40 6 4 8 13 5 20 -2 7 1 18 7 24 15
15 6 13 -17 -4z" />
            <path d="M8735 6221 c-2 -5 4 -11 15 -14 11 -3 20 -2 20 3 0 12 -28 21 -35 11z" />
            <path d="M14605 6219 c4 -5 2 -15 -3 -22 -7 -8 -5 -16 4 -25 8 -7 14 -9 15 -5
0 4 2 18 3 30 1 12 -4 25 -11 27 -8 3 -11 1 -8 -5z" />
            <path d="M16940 6220 c0 -5 7 -6 17 -3 11 4 14 3 9 -5 -5 -8 -1 -10 10 -5 12
4 15 3 10 -5 -5 -8 -1 -10 10 -5 13 5 15 3 9 -7 -5 -9 -4 -11 3 -6 17 10 15
26 -4 26 -8 0 -13 3 -11 8 3 4 -8 8 -24 9 -16 1 -29 -2 -29 -7z" />
            <path d="M18093 6208 c1 -15 6 -23 11 -18 4 4 4 16 -2 26 -9 16 -10 14 -9 -8z" />
            <path d="M19830 6226 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z" />
            <path d="M23715 6220 c-3 -6 1 -7 9 -4 18 7 21 14 7 14 -6 0 -13 -4 -16 -10z" />
            <path d="M23757 6214 c4 -11 3 -15 -5 -10 -6 3 -13 2 -17 -4 -3 -5 1 -10 9
-10 8 0 21 -9 29 -21 11 -15 17 -17 22 -8 5 7 1 17 -8 24 -10 7 -17 17 -17 23
0 6 -4 14 -10 17 -6 4 -7 -1 -3 -11z" />
            <path d="M27750 6210 c-31 -24 -41 -25 -34 -2 5 14 4 15 -5 2 -6 -8 -8 -23 -5
-34 4 -11 2 -16 -4 -12 -12 7 -8 -32 4 -43 3 -3 4 1 3 9 -4 18 29 70 43 70 6
0 4 -8 -3 -17 -13 -16 -12 -17 4 -4 9 8 15 16 12 19 -2 3 3 11 13 18 9 7 13
13 7 13 -5 1 -21 -8 -35 -19z" />
            <path d="M30100 6223 c8 -3 7 -9 -1 -19 -10 -12 -9 -13 5 -8 18 7 17 26 -2 30
-7 1 -8 0 -2 -3z" />
            <path d="M19871 6206 c-15 -8 -20 -16 -12 -20 6 -4 11 -2 11 3 0 6 5 11 11 11
6 0 9 -7 6 -14 -4 -10 -1 -13 9 -9 16 6 19 23 3 23 -5 0 -7 5 -4 10 8 13 5 12
-24 -4z" />
            <path d="M13665 6199 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z" />
            <path d="M23795 6200 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z" />
            <path d="M23816 6175 c-3 -8 -1 -15 4 -15 6 0 10 7 10 15 0 8 -2 15 -4 15 -2
0 -6 -7 -10 -15z" />
            <path d="M7495 6170 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z" />
            <path d="M12366 6151 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 -4 11 -6 11 -12 0z" />
            <path d="M25060 6145 c-10 -13 -9 -15 11 -14 13 0 18 3 11 6 -6 2 -10 9 -6 14
9 15 -2 10 -16 -6z" />
            <path d="M7561 6137 c-9 -11 -8 -17 3 -26 10 -8 16 -9 21 -1 4 6 3 10 -2 9
-20 -3 -27 2 -13 11 8 5 12 12 9 15 -4 3 -11 -1 -18 -8z" />
            <path d="M15526 6142 c-3 -5 1 -9 9 -9 8 0 15 4 15 9 0 4 -4 8 -9 8 -6 0 -12
-4 -15 -8z" />
            <path d="M19053 6138 c-12 -21 -12 -39 0 -35 7 2 11 11 9 21 -2 9 2 16 8 16 6
0 9 -4 6 -9 -7 -10 22 -37 30 -28 6 6 -27 47 -38 47 -4 0 -10 -6 -15 -12z" />
            <path d="M19976 6141 c-3 -5 1 -14 9 -21 8 -7 15 -9 15 -4 0 4 7 -1 16 -12 8
-10 13 -13 9 -6 -3 6 -2 12 2 12 5 0 18 7 29 15 18 15 18 15 0 8 -13 -4 -17
-3 -12 5 5 8 2 10 -9 5 -14 -5 -14 -8 -3 -15 9 -7 8 -8 -7 -5 -11 2 -19 9 -17
15 2 14 -24 16 -32 3z" />
            <path d="M26006 6138 c3 -4 12 -8 20 -8 8 0 13 -4 9 -9 -7 -12 12 -24 25 -16
12 8 -22 42 -43 42 -9 0 -14 -4 -11 -9z" />
            <path d="M14605 6121 c-3 -5 -1 -12 3 -15 5 -3 8 -19 7 -36 -1 -17 2 -33 6
-36 5 -3 8 18 6 46 -1 47 -9 61 -22 41z" />
            <path d="M31255 6121 c-3 -5 -2 -12 3 -15 5 -3 9 1 9 9 0 17 -3 19 -12 6z" />
            <path d="M13621 6106 c-7 -8 -8 -17 -3 -20 6 -3 12 3 15 14 6 24 4 25 -12 6z" />
            <path d="M25055 6110 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z" />
            <path d="M14586 6083 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z" />
            <path d="M20040 6092 c0 -5 14 -18 30 -30 25 -19 30 -20 30 -7 0 9 -4 14 -9
10 -6 -3 -15 4 -21 15 -11 20 -30 28 -30 12z" />
            <path d="M6316 6050 c-19 -39 -6 -39 16 0 9 17 13 30 8 30 -5 0 -15 -13 -24
-30z" />
            <path d="M18020 6059 c-9 -16 -9 -24 0 -33 8 -8 9 -6 4 8 -5 13 -4 17 3 13 6
-4 13 0 17 8 9 24 -12 27 -24 4z" />
            <path d="M14599 6068 c-4 -10 -7 -59 -3 -55 7 7 15 57 9 57 -3 0 -6 -1 -6 -2z" />
            <path d="M15663 6055 c0 -8 3 -13 5 -10 3 3 18 -7 34 -21 26 -26 28 -26 34 -8
4 10 9 22 11 26 2 4 -10 5 -26 2 -23 -5 -32 -2 -37 10 -8 20 -21 21 -21 1z
m67 -35 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11
-10z" />
            <path d="M27633 6058 c-10 -18 -13 -39 -3 -33 8 5 21 45 15 45 -2 0 -7 -6 -12
-12z" />
            <path d="M20110 6052 c0 -15 11 -21 27 -15 12 5 12 7 1 14 -17 11 -28 11 -28
1z" />
            <path d="M19015 6040 c-4 -6 -11 -7 -17 -4 -8 5 5 -20 28 -55 2 -1 2 13 1 33
-2 39 -3 41 -12 26z" />
            <path d="M28995 6028 c1 -13 3 -25 4 -27 2 -7 31 22 31 32 0 6 -7 2 -16 -9
-15 -18 -15 -18 -9 4 4 12 2 22 -3 22 -5 0 -8 -10 -7 -22z" />
            <path d="M15760 5981 c18 -14 28 -17 37 -9 15 12 18 -3 3 -18 -6 -6 -4 -10 8
-10 11 0 17 8 17 22 0 17 -5 21 -25 19 -14 -2 -34 1 -45 7 -14 6 -13 3 5 -11z" />
            <path d="M18998 5978 c-4 -10 -1 -19 8 -22 21 -8 24 -8 24 5 0 5 -4 8 -9 4 -5
-3 -11 3 -13 12 -3 16 -4 17 -10 1z" />
            <path d="M24034 5973 c23 -27 22 -27 29 -8 4 8 1 17 -5 21 -7 4 -8 3 -4 -4 10
-16 -6 -15 -24 1 -8 6 -6 2 4 -10z" />
            <path d="M29054 5981 c4 -5 1 -12 -4 -14 -6 -3 -5 -4 2 -3 15 3 23 26 8 26 -6
0 -9 -4 -6 -9z" />
            <path d="M14534 5965 c3 -23 12 -26 13 -5 1 11 -2 20 -7 20 -4 0 -7 -7 -6 -15z" />
            <path d="M27534 5949 c-5 -8 -3 -9 6 -4 10 6 12 4 7 -8 -3 -10 -2 -17 3 -17
13 0 13 27 1 35 -5 3 -13 0 -17 -6z" />
            <path d="M22497 5929 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z" />
            <path d="M16420 5894 c-10 -11 -4 -24 11 -24 5 0 5 8 2 18 -6 15 -5 16 12 2
10 -8 16 -10 12 -3 -4 6 -1 13 5 16 7 3 3 4 -10 4 -13 -1 -27 -6 -32 -13z" />
            <path d="M19295 5900 c-2 -3 1 -12 6 -20 8 -13 10 -13 19 0 5 8 10 18 10 21 0
8 -30 7 -35 -1z" />
            <path d="M20840 5901 c-8 -6 -18 -7 -22 -5 -4 3 -8 -4 -8 -15 0 -12 5 -21 10
-21 6 0 10 5 10 11 0 7 8 18 18 25 19 15 14 18 -8 5z" />
            <path d="M27536 5895 c4 -8 8 -15 10 -15 2 0 4 7 4 15 0 8 -4 15 -10 15 -5 0
-7 -7 -4 -15z" />
            <path d="M10146 5892 c-3 -5 4 -18 17 -28 21 -19 21 -19 7 0 -8 11 -13 21 -12
23 2 3 1 7 -1 9 -3 3 -8 1 -11 -4z" />
            <path d="M21881 5897 c-1 -5 4 -17 9 -28 8 -13 7 -19 0 -19 -6 0 -3 -11 6 -24
9 -15 21 -22 30 -19 8 3 14 0 14 -6 0 -6 -5 -11 -12 -11 -8 0 -6 -6 4 -16 11
-12 14 -23 8 -38 -9 -24 14 -56 24 -33 3 9 7 8 15 -3 8 -11 11 -12 11 -2 0 7
-6 20 -14 28 -13 12 -15 12 -19 -4 -4 -13 -5 -12 -6 5 -1 29 15 38 28 16 19
-33 11 -8 -13 39 -13 26 -26 44 -30 41 -11 -12 -33 18 -30 42 1 14 -1 23 -6
20 -5 -3 -11 1 -13 7 -3 7 -6 9 -6 5z m74 -117 c3 -5 1 -10 -4 -10 -6 0 -11 5
-11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z" />
            <path d="M24950 5885 c-7 -9 -11 -17 -9 -20 3 -2 10 5 17 15 14 24 10 26 -8 5z" />
            <path d="M24111 5874 c17 -21 20 -42 6 -51 -7 -3 -9 -3 -5 2 9 10 -9 45 -24
45 -6 0 -4 -7 5 -17 8 -10 15 -20 15 -23 -2 -8 26 -50 33 -50 10 0 3 64 -10
88 -6 12 -17 22 -23 22 -6 0 -6 -6 3 -16z" />
            <path d="M28624 5878 c6 -20 -4 -24 -20 -8 -7 7 -17 5 -30 -6 -19 -15 -18 -15
2 -5 15 7 24 7 32 -1 17 -17 34 3 21 22 -9 13 -10 12 -5 -2z" />
            <path d="M7286 5872 c-4 -6 -13 -9 -21 -6 -8 3 -21 -5 -30 -20 -10 -14 -23
-26 -31 -26 -7 0 -16 -5 -20 -12 -4 -6 -3 -8 2 -5 6 3 10 -12 10 -36 -1 -33
-6 -46 -26 -59 -17 -12 -20 -18 -9 -18 8 0 20 5 27 12 9 9 12 9 12 0 0 -17
-25 -25 -55 -18 -17 4 -23 11 -18 19 4 7 0 7 -10 -2 -16 -12 -17 -12 -11 5 7
19 7 19 -14 0 -26 -22 -28 -28 -7 -20 8 4 15 1 15 -6 0 -6 3 -9 8 -7 4 3 7
-11 7 -31 0 -30 -4 -36 -23 -37 -14 -1 -27 6 -33 17 -6 10 -14 16 -20 12 -6
-4 -5 -12 3 -22 11 -13 10 -15 -5 -9 -14 5 -16 3 -9 -11 14 -28 9 -65 -10 -68
-9 -1 -19 5 -22 13 -3 8 -1 11 4 8 15 -9 30 4 23 20 -8 22 -18 18 -38 -11 -12
-18 -13 -24 -4 -19 9 4 9 2 -1 -7 -21 -18 -47 -53 -35 -46 6 4 11 -6 11 -23 1
-16 -2 -26 -7 -23 -5 3 -9 0 -9 -5 0 -6 5 -11 11 -11 8 0 8 -5 -1 -15 -7 -8
-17 -15 -22 -15 -6 0 -5 6 2 14 9 12 8 15 -9 13 -20 -1 -24 -16 -6 -29 6 -4 2
-8 -7 -8 -10 0 -18 -5 -18 -11 0 -5 -4 -8 -9 -5 -4 3 -13 -6 -20 -20 -6 -14
-7 -23 -2 -20 15 10 22 -25 9 -42 -7 -9 -8 -13 -2 -9 7 4 10 -7 10 -32 -1 -31
-5 -38 -20 -37 -25 1 -31 20 -11 39 9 10 11 18 5 22 -5 3 -10 1 -10 -5 0 -6
-4 -9 -9 -5 -5 3 -13 0 -17 -6 -4 -8 -3 -9 4 -5 22 13 12 -13 -17 -46 -15 -18
-26 -39 -23 -47 2 -8 0 -11 -6 -7 -13 8 -23 -19 -15 -41 4 -11 3 -14 -5 -9 -8
5 -10 1 -5 -10 5 -13 3 -15 -7 -9 -9 5 -11 4 -6 -4 4 -6 12 -9 17 -6 5 4 9 2
9 -3 0 -5 7 -9 15 -9 8 0 15 9 15 20 0 16 -3 17 -15 7 -9 -8 -15 -8 -15 -2 0
6 6 13 13 15 9 4 8 8 -3 16 -8 5 -11 13 -7 18 5 4 13 1 18 -7 8 -11 9 -7 6 18
-2 18 0 35 5 38 4 3 7 -25 5 -63 -2 -65 -3 -68 -27 -69 -14 0 -31 5 -39 10
-11 8 -13 8 -8 -1 4 -6 0 -18 -8 -27 -8 -8 -11 -17 -6 -20 5 -3 8 -32 7 -63
l-1 -57 -34 -3 c-37 -3 -53 -17 -44 -40 3 -7 0 -13 -6 -13 -6 0 -8 -7 -5 -15
4 -8 2 -15 -4 -15 -5 0 -13 -7 -16 -16 -5 -14 -4 -15 9 -4 8 7 15 11 16 9 5
-26 3 -56 -5 -52 -5 4 -7 -1 -3 -10 3 -9 2 -19 -3 -22 -6 -4 -10 -16 -11 -28
0 -21 -1 -21 -8 -2 -5 11 -4 25 1 32 5 7 7 16 4 22 -3 5 0 11 6 14 7 2 9 8 5
13 -9 9 -28 -16 -24 -31 2 -5 -5 -18 -15 -29 -10 -11 -18 -25 -17 -30 0 -6 3
-5 6 2 4 10 8 10 19 1 17 -14 18 -30 2 -28 -28 3 -37 -2 -31 -18 5 -11 3 -14
-6 -8 -10 6 -11 3 -2 -13 16 -29 14 -39 -4 -16 -13 18 -14 18 -8 -5 5 -18 3
-22 -7 -16 -8 5 -11 4 -6 -3 3 -6 2 -13 -4 -17 -5 -3 -10 -13 -10 -21 0 -8 5
-12 10 -9 6 4 8 10 5 15 -10 17 22 28 78 26 36 -1 65 3 75 11 15 12 15 12 -2
6 -13 -4 -17 -3 -12 5 4 7 15 12 24 13 10 0 12 3 5 5 -7 3 -21 0 -32 -6 -12
-6 -21 -7 -21 -1 0 5 6 12 13 14 10 4 10 6 0 6 -7 1 -13 6 -13 11 0 7 6 7 18
2 29 -15 82 -22 82 -11 0 6 -4 8 -9 4 -5 -3 -13 -1 -16 5 -4 6 -15 8 -25 5
-10 -3 -21 -1 -25 5 -7 11 37 14 49 3 12 -13 48 -14 40 -1 -5 7 -2 9 8 6 8 -4
19 -3 24 0 14 11 40 47 29 40 -6 -4 -11 5 -12 20 -1 15 1 20 4 12 3 -8 10 -18
15 -21 12 -8 58 37 58 57 0 8 4 13 9 9 5 -3 13 -1 16 5 4 6 1 9 -5 8 -7 -2
-14 5 -15 15 -2 10 3 17 11 17 9 0 13 -4 10 -8 -2 -4 4 -9 15 -9 15 -1 19 3
14 18 -5 16 6 26 18 17 11 -8 34 26 30 43 -4 14 -8 17 -13 9 -4 -7 -7 4 -6 24
0 20 7 41 15 45 11 7 10 10 -5 14 -12 3 -8 4 9 4 15 -1 27 -5 27 -10 0 -5 6
-4 14 2 11 9 16 9 22 -1 4 -7 4 -10 -1 -6 -5 5 -15 -1 -23 -13 -11 -16 -17
-18 -28 -8 -8 6 -18 9 -21 5 -4 -4 -2 -11 6 -15 7 -5 12 -12 12 -16 -1 -5 5
-10 13 -11 9 -1 12 3 9 12 -3 8 -2 14 3 12 5 -2 10 2 12 7 2 6 10 14 17 18 11
6 12 14 5 33 -13 34 -13 37 4 22 8 -7 11 -17 8 -22 -3 -5 0 -6 7 -1 9 5 10 10
2 18 -16 16 -13 31 3 18 11 -10 14 -9 12 3 -1 9 -6 19 -11 23 -4 4 -5 2 0 -5
6 -11 3 -12 -14 -3 -11 7 -21 13 -21 14 0 1 16 3 36 3 38 2 56 17 46 42 -4 11
-2 13 9 7 11 -7 11 -5 -1 10 -14 17 -13 18 2 12 13 -5 16 -1 12 19 -3 16 -1
23 6 19 5 -3 10 -1 10 5 0 7 -6 10 -12 7 -7 -2 -12 -12 -11 -21 0 -10 -2 -15
-5 -11 -4 3 -7 27 -8 53 -1 35 0 39 5 18 5 -26 8 -27 19 -13 9 11 10 17 2 22
-15 9 7 35 29 35 14 0 14 -2 1 -10 -15 -10 -22 -60 -8 -60 4 0 8 5 8 12 0 6 9
31 21 54 15 32 18 48 10 62 -5 10 -7 38 -3 63 5 36 7 39 12 20 3 -14 12 -26
20 -29 13 -3 13 -2 1 6 -8 6 -11 17 -7 28 6 16 9 16 17 4 8 -12 9 -11 5 5 -14
61 -18 67 -29 55 -9 -10 -9 -14 1 -17 19 -7 14 -22 -8 -20 -21 1 -22 27 -1 65
7 13 7 22 0 29 -8 8 1 10 28 9 28 -1 37 3 35 13 -2 8 2 27 7 43 6 16 6 26 1
23 -10 -6 -8 90 3 101 4 4 7 21 8 38 0 27 2 28 9 11 6 -13 7 -2 3 30 -3 28
-10 55 -15 62 -5 7 -5 12 1 15 6 2 7 9 2 17 -6 9 -11 10 -15 3z m2 -22 c-1 -3
2 -16 6 -30 3 -14 2 -45 -3 -70 -8 -39 -9 -41 -10 -13 -1 27 -4 30 -23 25 -23
-5 -23 -5 -4 16 10 12 23 19 27 16 5 -3 9 1 9 9 0 19 -34 49 -45 40 -4 -5 -5
-3 -1 4 6 11 46 14 44 3z m-39 -46 c-6 -8 -18 -14 -27 -14 -13 0 -12 3 4 19
21 21 41 17 23 -5z m-22 -40 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2
13 -5z m-270 -387 c0 -7 -13 -13 -31 -13 l-31 -1 30 13 c17 7 30 13 31 14 0 0
1 -6 1 -13z m244 -111 c-7 -8 -18 -13 -25 -10 -8 3 -20 -2 -29 -10 -26 -26
-32 -18 -8 8 13 14 29 22 36 20 7 -3 16 0 20 6 4 6 9 9 12 6 3 -3 0 -12 -6
-20z m-351 -550 c0 -2 -7 -6 -15 -10 -8 -3 -15 -1 -15 4 0 6 7 10 15 10 8 0
15 -2 15 -4z m-219 -69 c-17 -17 -22 -2 -9 29 11 28 12 29 16 5 2 -13 -1 -28
-7 -34z" />
            <path d="M30935 5860 c-10 -11 -21 -17 -26 -15 -18 12 -37 -25 -33 -67 l4 -43
-15 29 c-8 15 -12 33 -8 39 3 6 1 7 -5 3 -7 -4 -12 -13 -12 -21 0 -7 -15 -26
-32 -40 -18 -15 -27 -24 -21 -20 9 4 11 1 7 -9 -3 -9 -10 -14 -14 -11 -4 3
-13 -2 -20 -10 -9 -10 -9 -15 -1 -15 6 0 11 5 11 12 0 26 21 -10 25 -43 3 -21
1 -29 -4 -21 -4 8 -12 13 -17 12 -4 -1 -11 5 -14 14 -3 11 -9 13 -20 6 -8 -5
-10 -10 -4 -10 7 0 19 -11 28 -25 12 -18 13 -24 4 -23 -7 2 -18 12 -25 23 -7
11 -12 14 -13 8 0 -7 -5 -12 -12 -10 -12 2 -14 -4 -17 -39 0 -8 -5 -13 -10
-10 -11 7 -33 -18 -24 -27 3 -4 1 -7 -6 -7 -7 0 -9 -4 -6 -10 3 -5 2 -10 -2
-10 -5 0 -14 -9 -21 -21 -8 -12 -9 -18 -4 -15 6 3 9 -20 8 -59 0 -39 -5 -64
-11 -61 -11 3 -11 20 0 51 7 20 6 20 -9 1 -9 -11 -16 -28 -16 -39 0 -10 -3
-16 -7 -14 -5 3 -18 1 -31 -4 -17 -7 -21 -13 -14 -26 4 -10 8 -43 8 -73 -1
-44 -4 -55 -18 -58 -12 -2 -18 3 -18 15 0 15 2 16 10 3 6 -9 10 -10 10 -3 0 6
3 20 6 30 5 15 4 15 -5 3 -9 -13 -11 -13 -11 2 0 9 4 28 10 42 7 20 6 24 -5
20 -7 -3 -11 -12 -9 -19 3 -7 -3 -18 -15 -24 -26 -14 -34 -41 -35 -127 -2 -72
-15 -104 -42 -104 -8 0 -14 7 -14 16 0 13 3 14 14 5 11 -9 15 -8 20 5 4 9 4
19 1 22 -3 3 -5 0 -5 -7 0 -7 -4 -9 -10 -6 -5 3 -10 11 -10 16 0 6 5 7 11 3 8
-4 9 1 5 17 l-7 24 16 -25 c12 -18 14 -19 9 -5 -7 22 -7 27 1 70 5 29 5 29 -4
3 -5 -15 -16 -37 -25 -50 -9 -12 -16 -30 -16 -40 0 -10 -5 -18 -11 -18 -5 0
-8 -4 -4 -9 3 -5 1 -12 -5 -16 -6 -4 -8 -11 -5 -16 4 -5 1 -9 -5 -9 -7 0 -8
-5 -3 -12 10 -17 11 -108 1 -102 -5 3 -11 -1 -14 -10 -3 -8 -11 -13 -19 -10
-31 12 -41 -21 -40 -133 1 -99 -1 -109 -17 -109 -13 0 -16 6 -12 18 3 10 0 26
-6 35 -8 15 -9 13 -4 -10 5 -22 3 -26 -9 -21 -9 3 -13 14 -11 27 4 21 4 21 -4
2 -5 -11 -7 -26 -4 -34 3 -8 1 -12 -4 -9 -16 10 -5 -105 11 -121 10 -11 15
-11 15 -3 0 7 -4 12 -10 12 -5 0 -10 4 -10 8 0 5 14 9 31 10 29 1 30 0 13 -13
-11 -8 -14 -15 -7 -15 7 0 20 11 29 25 16 24 43 37 28 13 -8 -12 4 -7 49 25
19 13 25 23 18 30 -6 9 -2 12 16 14 20 1 23 -1 12 -9 -12 -8 -12 -9 1 -5 8 2
14 11 12 20 -1 9 7 21 19 28 19 10 21 9 16 -5 -8 -21 -5 -20 15 3 14 17 14 20
2 25 -10 4 -13 2 -8 -7 4 -6 3 -8 -1 -4 -14 13 -14 34 1 34 7 0 11 -4 8 -9 -3
-4 0 -8 5 -8 6 0 11 5 11 11 0 5 5 7 10 4 11 -7 83 55 75 63 -3 3 6 15 20 27
13 13 21 25 19 28 -3 3 4 11 16 17 16 9 19 15 11 23 -17 17 -13 26 8 22 14 -2
21 4 25 21 4 15 11 22 18 18 7 -5 8 -3 3 6 -5 9 -14 4 -31 -16 -13 -16 -23
-24 -24 -17 0 6 5 15 10 18 6 3 10 11 10 18 0 6 -7 2 -16 -9 -14 -17 -15 -17
-10 -1 4 11 2 16 -5 12 -6 -4 -8 -14 -5 -22 3 -8 1 -24 -5 -35 -9 -16 -8 -19
3 -13 10 6 10 5 2 -4 -6 -7 -24 -14 -40 -16 -15 -1 -30 -8 -32 -14 -2 -6 -9
-11 -15 -11 -6 0 0 11 12 24 13 14 29 22 36 20 15 -6 39 31 30 45 -3 5 -2 16
1 25 4 11 17 14 51 11 37 -3 42 -1 33 10 -9 10 -9 19 -1 32 10 16 11 16 9 1
-2 -9 0 -17 5 -17 4 -1 7 1 7 4 0 3 0 14 0 26 0 11 -4 18 -9 14 -5 -3 -12 -2
-15 3 -2 4 4 9 15 9 10 1 19 -5 19 -13 0 -8 4 -14 9 -14 6 0 8 9 6 20 -2 11 3
24 12 29 9 5 18 15 19 22 2 8 5 24 8 37 3 13 1 20 -5 17 -5 -4 -9 1 -9 9 0 21
17 20 28 0 8 -15 10 -15 15 0 4 10 3 15 -3 11 -6 -3 -10 1 -10 10 0 9 3 14 8
11 4 -2 15 10 25 27 17 32 17 32 -2 10 -27 -32 -35 -28 -15 6 10 17 21 28 25
26 13 -9 17 23 13 114 -2 59 0 90 6 86 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -13
8 -13 25 -1 25 15 0 24 -41 12 -53 -7 -7 -7 -14 0 -24 13 -15 43 -18 34 -3 -4
6 -11 8 -16 5 -13 -9 2 58 18 73 13 13 15 22 27 87 3 17 8 41 11 55 3 16 3 19
-2 8 -5 -9 -9 35 -10 99 -2 100 -1 114 13 109 8 -3 12 -2 9 4 -3 6 -14 10 -23
11 -14 0 -13 2 3 9 15 6 16 9 4 9 -9 1 -24 -8 -34 -19z m-79 -102 c-3 -23 -17
-38 -34 -38 -16 1 -16 1 1 11 9 5 17 17 17 24 0 8 4 15 9 15 5 0 8 -6 7 -12z
m6 -51 c-12 -13 -31 -23 -42 -22 -20 1 -40 14 -24 16 46 4 59 8 62 17 2 7 9
12 15 12 6 0 1 -10 -11 -23z m-122 -98 c0 -4 -3 -9 -7 -11 -5 -1 -17 -19 -27
-38 -17 -30 -17 -33 -3 -21 14 11 17 11 17 -1 0 -8 -10 -14 -25 -14 -19 0 -24
5 -21 21 2 11 9 20 16 20 13 0 31 34 24 46 -2 3 3 6 11 6 8 0 15 -4 15 -8z
m189 -111 c-6 -11 -12 -18 -13 -16 -1 1 -7 10 -14 19 -11 14 -9 17 12 17 22 0
24 -3 15 -20z m-58 -223 c5 -4 -2 -16 -16 -28 -14 -12 -27 -33 -29 -47 -3 -14
-5 -27 -6 -30 -2 -9 -19 34 -19 50 0 9 4 8 9 -5 12 -28 13 5 1 37 -8 22 -6 24
21 26 16 1 33 0 39 -3z m-351 -25 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6
-1 10 4 10 6 0 11 -4 11 -10z m-15 -39 c-9 -16 -25 -3 -18 15 4 10 9 12 15 6
6 -6 7 -15 3 -21z m280 -111 c3 -5 -1 -10 -10 -10 -9 0 -13 5 -10 10 3 6 8 10
10 10 2 0 7 -4 10 -10z m-170 -274 c-10 -7 -19 -24 -20 -37 -1 -13 -8 -23 -14
-21 -6 1 -11 -3 -11 -10 0 -6 -3 -9 -6 -6 -3 4 2 18 11 32 10 14 14 26 10 26
-4 0 -2 7 5 15 7 8 20 15 28 15 13 0 13 -2 -3 -14z m-48 -28 c-3 -8 -6 -5 -6
6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-297 -148 c0 -5 -5 -10 -11 -10 -5 0 -7
5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m84 -26 c5 -12 13 -14 34 -9 21 5 24
4 15 -6 -15 -15 -59 -4 -67 16 -3 8 -1 15 3 15 5 0 12 -7 15 -16z m36 6 c0 -5
-7 -10 -16 -10 -8 0 -12 5 -9 10 3 6 10 10 16 10 5 0 9 -4 9 -10z m-23 -46
c-6 -6 -47 9 -47 17 0 5 11 3 25 -3 14 -6 24 -13 22 -14z" />
            <path d="M30825 5259 c-11 -17 1 -21 15 -4 8 9 8 15 2 15 -6 0 -14 -5 -17 -11z" />
            <path d="M23502 5854 c-12 -8 -20 -20 -17 -25 6 -8 -8 -13 -27 -10 -4 0 -5 -6
-1 -15 3 -9 0 -14 -8 -12 -11 2 -14 -8 -12 -42 1 -36 0 -40 -7 -20 -8 25 -14
13 -11 -25 2 -17 -4 -20 -26 -19 -15 0 -32 -4 -36 -10 -6 -7 -7 -6 -4 4 2 8
10 14 16 13 6 -2 11 1 11 7 0 5 7 7 15 4 8 -4 15 -1 15 5 0 6 -5 11 -12 11 -9
0 -9 3 0 12 7 7 12 16 12 21 0 14 -69 -66 -70 -80 0 -6 -4 -13 -9 -15 -5 -1
-2 -10 7 -18 14 -13 15 -12 8 5 -4 11 -3 16 1 11 4 -4 8 -18 8 -30 1 -19 -4
-22 -34 -22 -28 1 -37 -3 -40 -19 -2 -11 -12 -31 -22 -44 -13 -16 -16 -28 -10
-35 13 -15 -3 -41 -19 -31 -9 6 -10 9 0 15 9 5 9 9 1 14 -10 6 -13 1 -10 -20
0 -3 -8 -12 -18 -21 -10 -8 -12 -12 -6 -8 9 4 11 1 7 -9 -3 -9 -11 -13 -17 -9
-6 4 -7 1 -2 -7 5 -8 10 -46 11 -84 2 -56 1 -65 -7 -41 -5 17 -7 35 -4 42 2 7
0 15 -5 18 -6 4 -10 11 -10 16 0 6 5 7 12 3 7 -5 8 -3 4 5 -15 24 -42 -15 -38
-54 3 -22 0 -35 -8 -35 -7 0 -9 8 -5 23 6 22 6 22 -10 2 -9 -12 -12 -21 -5
-23 5 -2 10 -8 10 -14 0 -6 -6 -8 -13 -5 -8 3 -14 -4 -15 -16 -1 -17 1 -19 8
-7 5 8 10 11 10 5 0 -5 -4 -17 -10 -25 -5 -9 -5 -19 1 -27 7 -8 7 -18 0 -32
-14 -25 -15 -53 -1 -31 7 12 9 11 8 -6 -1 -11 -9 -21 -18 -21 -19 -2 -20 38
-1 60 9 11 9 17 1 22 -6 4 -9 13 -6 21 3 7 0 16 -6 20 -7 4 -8 3 -4 -4 4 -7 3
-12 -2 -12 -5 0 -9 -7 -9 -15 0 -8 4 -12 9 -9 4 3 8 2 8 -1 0 -8 -32 -40 -39
-40 -3 0 -5 -6 -3 -13 2 -8 -1 -12 -6 -9 -4 4 -8 -35 -8 -85 1 -84 -1 -91 -19
-94 -11 -2 -26 -1 -34 1 -11 5 -11 8 1 23 9 9 15 25 15 35 0 21 -1 21 -18 0
-10 -11 -10 -14 -1 -9 19 12 14 -5 -10 -32 -13 -14 -16 -22 -8 -17 8 4 5 -2
-7 -13 -18 -18 -20 -27 -13 -51 5 -17 11 -35 15 -41 3 -5 1 -18 -5 -27 -9 -15
-10 -14 -4 6 4 17 3 21 -6 16 -6 -4 -10 -18 -7 -32 4 -20 1 -24 -13 -20 -27 7
-43 -8 -45 -42 -1 -17 -5 -34 -10 -38 -4 -5 -5 -2 0 6 5 10 4 12 -4 7 -6 -4
-9 -12 -5 -17 3 -6 -1 -18 -9 -27 -21 -23 -47 -101 -40 -120 4 -10 2 -13 -5
-8 -7 4 -12 2 -12 -3 0 -6 4 -11 10 -11 5 0 7 -7 4 -15 -4 -8 -11 -12 -18 -8
-6 3 -8 3 -4 -2 4 -4 2 -15 -5 -23 -10 -13 -9 -14 3 -7 8 6 17 10 20 11 3 0
13 4 23 7 9 4 25 10 35 14 9 3 21 5 26 4 5 -1 4 5 -4 13 -9 12 -10 16 0 16 8
0 10 9 6 28 -4 17 -3 22 2 13 6 -8 12 -9 16 -3 3 5 -1 15 -9 22 -12 10 -16 9
-20 -2 -3 -8 -5 -19 -4 -24 4 -23 -2 -35 -13 -28 -9 5 -9 2 1 -9 9 -12 10 -17
2 -17 -16 0 -26 30 -14 39 5 3 10 13 11 21 0 8 3 31 7 50 5 31 4 33 -7 15 -12
-20 -14 -25 -16 -58 -1 -10 -7 -15 -12 -12 -6 4 -7 11 -3 18 4 6 7 21 8 34 0
13 11 33 24 46 l23 22 -4 -37 c-4 -41 -7 -40 70 -41 25 0 37 -4 30 -9 -10 -6
-8 -8 4 -8 9 0 20 5 23 10 5 8 12 7 23 -2 12 -10 14 -10 8 0 -4 7 -2 12 8 12
8 0 29 13 45 28 24 22 28 31 19 40 -9 9 -8 12 8 13 10 1 23 1 27 0 5 -1 6 3 2
9 -3 5 -1 10 4 10 6 0 10 3 10 8 -3 18 2 26 9 16 4 -6 17 2 35 22 19 23 24 35
16 40 -7 4 -9 3 -5 -4 3 -6 2 -13 -4 -17 -6 -4 -9 9 -8 32 1 32 5 39 22 41 11
0 27 -4 36 -11 8 -6 12 -8 8 -3 -4 5 -1 15 7 22 8 8 11 22 8 32 -6 15 -5 16
12 2 12 -10 16 -10 11 -2 -4 7 -2 19 4 27 7 8 9 15 5 15 -4 0 -2 7 5 15 7 8
16 12 21 10 4 -3 13 4 19 15 6 11 9 20 8 21 -21 6 -45 3 -44 -5 1 -6 -3 -11
-9 -11 -5 0 -9 5 -9 12 1 6 -5 9 -12 6 -8 -3 -14 0 -14 6 0 6 19 9 48 8 42 -3
48 -1 50 17 1 14 -1 17 -7 8 -5 -8 -8 -2 -8 17 0 31 21 49 33 29 4 -6 3 -8 -4
-4 -6 3 -13 2 -17 -4 -3 -5 -1 -10 4 -10 6 0 14 0 18 0 5 0 9 11 10 24 1 14 7
35 14 48 11 21 11 22 -4 9 -10 -7 -17 -21 -17 -30 0 -10 -8 -17 -20 -17 -16 0
-19 6 -15 35 2 18 7 37 11 40 4 3 4 -7 0 -22 -5 -22 -4 -28 6 -24 7 2 12 13
11 24 -1 12 3 20 10 20 41 -1 45 3 44 41 -2 30 1 36 15 34 9 -1 20 3 24 10 4
7 3 10 -1 5 -5 -4 -15 5 -22 20 -10 23 -12 24 -7 4 4 -16 3 -21 -5 -17 -6 4
-11 20 -11 35 0 24 4 27 29 27 21 0 27 -4 24 -14 -5 -11 -7 -11 -14 0 -5 7 -9
8 -9 2 0 -5 7 -19 16 -30 8 -10 13 -13 9 -7 -3 7 0 25 8 40 18 36 37 94 29 89
-11 -6 -8 71 2 81 5 5 6 2 1 -6 -5 -9 -4 -12 3 -8 5 4 8 17 4 29 -3 13 -1 19
5 15 11 -7 28 22 18 31 -3 3 -5 0 -5 -7 0 -7 -4 -9 -10 -6 -5 3 -10 13 -10 21
0 9 5 12 12 8 7 -5 8 -3 3 5 -11 18 -23 1 -27 -39 -2 -19 -6 20 -9 86 -3 94
-1 120 8 117 8 -3 11 -15 7 -32 -5 -25 -4 -24 9 4 l14 30 -1 -42 c0 -23 -3
-37 -7 -32 -8 14 -19 -15 -18 -49 0 -24 1 -25 8 -7 5 11 6 26 3 33 -5 15 19
12 31 -3 4 -5 5 4 1 20 -4 17 -7 32 -6 35 0 23 3 30 12 25 13 -8 13 5 0 25 -5
8 -10 10 -10 3 0 -14 -23 -11 -44 6 -10 9 -14 22 -11 35 5 17 4 18 -5 6 -9
-13 -11 -12 -8 4 2 14 12 20 31 21 27 2 64 25 40 25 -7 0 -15 -5 -18 -10 -5
-8 -11 -8 -19 -1 -9 7 -19 6 -34 -5z m-2 -79 c-14 -17 -57 -16 -57 1 0 7 4 11
8 9 3 -3 12 2 19 10 10 12 16 13 26 4 10 -8 11 -15 4 -24z m-224 -285 c0 -27
-3 -50 -8 -50 -4 0 -8 5 -8 11 0 8 -4 9 -13 0 -14 -11 -27 -5 -27 13 0 6 4 5
9 -3 7 -10 12 -8 24 10 11 15 13 27 7 34 -7 9 4 35 15 35 1 0 2 -22 1 -50z
m-106 -142 c0 -13 3 -32 6 -43 4 -16 0 -21 -18 -22 -16 -2 -19 0 -9 6 9 6 12
21 9 45 -2 20 0 36 4 36 4 0 8 -10 8 -22z m-90 -197 c0 -10 -22 -31 -33 -31
-5 0 -4 7 3 15 6 8 9 19 5 24 -3 7 0 8 9 5 9 -3 16 -9 16 -13z m273 -146 c-3
-9 -8 -14 -10 -11 -3 3 -2 9 2 15 9 16 15 13 8 -4z m-43 -27 c0 -6 -7 -5 -15
2 -8 7 -15 17 -15 22 0 6 7 5 15 -2 8 -7 15 -17 15 -22z m-23 -30 c-3 -8 -6
-5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-329 -49 c-3 -8 -7 -3 -11 10 -4
17 -3 21 5 13 5 -5 8 -16 6 -23z m225 -115 c-3 -8 -9 -12 -13 -9 -5 3 -17 -6
-27 -20 l-18 -25 -2 26 c0 17 2 22 7 14 5 -8 9 -9 14 -1 4 6 5 14 3 19 -3 4 5
8 18 8 16 1 22 -3 18 -12z m-90 -78 c6 -14 -27 -56 -43 -56 -16 0 -11 32 8 51
21 22 28 23 35 5z m-266 -143 c-4 -3 0 -11 8 -17 13 -9 11 -13 -10 -22 -23
-11 -24 -10 -14 7 5 10 7 19 4 19 -4 0 -3 5 0 10 3 6 9 10 12 10 4 0 3 -3 0
-7z" />
            <path d="M23475 5791 c-3 -5 -1 -12 5 -16 5 -3 10 1 10 9 0 18 -6 21 -15 7z" />
            <path d="M23063 4693 c-7 -3 -13 -11 -13 -20 0 -12 2 -12 16 2 18 17 16 25 -3
18z" />
            <path d="M29152 5831 c22 -29 38 -39 38 -23 -1 13 -25 38 -43 45 -16 6 -15 2
5 -22z" />
            <path d="M9570 5840 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z" />
            <path d="M10196 5827 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 -10 9 -11 8 -5 -6z" />
            <path d="M23570 5815 c0 -14 4 -25 9 -25 4 0 6 11 3 25 -2 14 -6 25 -8 25 -2
0 -4 -11 -4 -25z" />
            <path d="M19261 5814 c6 -7 16 -11 21 -8 11 7 3 17 -18 20 -12 2 -13 -1 -3
-12z" />
            <path d="M25546 5805 c2 -11 4 -23 4 -26 0 -4 12 -6 26 -6 17 0 22 2 13 8 -7
4 -17 5 -23 2 -5 -4 -7 1 -3 11 4 9 3 14 -2 11 -5 -3 -11 0 -14 7 -2 7 -3 4
-1 -7z" />
            <path d="M31524 5818 c-4 -6 -3 -8 3 -5 6 4 13 -6 17 -24 3 -17 10 -28 16 -24
6 4 4 17 -5 36 -17 32 -21 34 -31 17z" />
            <path d="M17490 5810 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z" />
            <path d="M23530 5802 c0 -12 3 -12 15 -2 8 7 15 13 15 15 0 1 -7 2 -15 2 -8 0
-15 -7 -15 -15z" />
            <path d="M30970 5811 c0 -6 4 -13 10 -16 6 -3 7 1 4 9 -7 18 -14 21 -14 7z" />
            <path d="M18875 5790 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z" />
            <path d="M24900 5790 c-8 -5 -12 -12 -9 -15 4 -3 12 1 19 10 14 17 11 19 -10
5z" />
            <path d="M31570 5790 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5
0 -10 -4 -10 -10z" />
            <path d="M7163 5775 c-3 -9 -3 -19 1 -22 3 -4 -2 -15 -11 -25 -16 -18 -16 -18
0 -12 9 3 18 11 19 18 0 6 2 16 2 21 1 6 5 16 9 23 5 7 4 12 -3 12 -6 0 -13
-7 -17 -15z" />
            <path d="M29820 5775 c-7 -9 -8 -15 -2 -15 5 0 12 7 16 15 3 8 4 15 2 15 -2 0
-9 -7 -16 -15z" />
            <path d="M30976 5765 c-3 -9 -6 -24 -5 -33 0 -9 5 -4 10 12 9 32 6 48 -5 21z" />
            <path d="M19210 5761 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5
3 -10 2 -10 -4z" />
            <path d="M7126 5744 c-17 -12 -17 -14 -4 -14 9 0 20 5 23 11 11 17 2 18 -19 3z" />
            <path d="M31571 5751 c-8 -5 -9 -11 -3 -15 5 -3 12 1 16 9 6 17 6 18 -13 6z" />
            <path d="M10270 5739 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0
-10 -5 -10 -11z" />
            <path d="M14740 5749 c0 -15 28 -60 33 -52 3 5 -3 20 -14 33 -10 14 -19 22
-19 19z" />
            <path d="M14776 5741 c-4 -5 -2 -12 3 -15 5 -4 12 -2 15 3 4 5 2 12 -3 15 -5
4 -12 2 -15 -3z" />
            <path d="M20377 5743 c-10 -9 -9 -33 1 -33 4 0 8 9 9 20 2 21 0 24 -10 13z" />
            <path d="M10294 5714 c-4 -14 -2 -30 3 -35 5 -5 9 5 9 26 0 42 -3 44 -12 9z" />
            <path d="M28420 5715 c-8 -10 -8 -15 1 -21 8 -4 9 -3 5 4 -4 7 -3 12 3 12 5 0
11 -5 13 -11 2 -7 8 -10 14 -8 5 2 2 12 -7 21 -15 14 -20 14 -29 3z" />
            <path d="M18837 5706 c-4 -10 -3 -15 3 -11 6 3 10 -2 10 -12 0 -15 2 -16 10
-3 12 19 13 33 1 26 -5 -4 -11 -1 -13 6 -3 7 -7 5 -11 -6z" />
            <path d="M24210 5710 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5
0 -10 -4 -10 -10z" />
            <path d="M10266 5697 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 -10 9 -11 8 -5 -6z" />
            <path d="M25486 5702 c2 -3 -2 -12 -8 -21 -7 -9 -8 -13 -4 -9 5 4 15 0 22 -10
13 -16 13 -15 11 6 -2 12 -8 26 -14 31 -5 5 -8 6 -7 3z" />
            <path d="M27419 5693 c-13 -16 -12 -17 4 -4 9 7 17 15 17 17 0 8 -8 3 -21 -13z" />
            <path d="M20365 5684 c9 -9 14 -23 11 -30 -3 -7 -1 -16 4 -19 11 -7 13 9 4 43
-4 12 -13 22 -21 22 -12 0 -12 -3 2 -16z" />
            <path d="M24186 5691 c-4 -5 -2 -12 3 -15 5 -4 12 -2 15 3 4 5 2 12 -3 15 -5
4 -12 2 -15 -3z" />
            <path d="M29238 5668 c3 -14 7 -16 12 -7 6 8 10 4 13 -12 4 -23 4 -23 6 2 1
18 -5 28 -18 32 -14 3 -17 0 -13 -15z" />
            <path d="M12684 5661 c20 -22 26 -25 26 -13 0 4 -10 13 -22 21 -23 14 -23 14
-4 -8z" />
            <path d="M6010 5660 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z" />
            <path d="M6040 5665 c0 -4 8 -14 18 -22 16 -14 16 -14 -2 -9 -13 5 -17 2 -13
-9 4 -8 -1 -18 -10 -23 -13 -8 -13 -10 3 -16 10 -4 15 -4 11 0 -4 4 2 17 13
29 l20 22 -20 18 c-11 10 -20 15 -20 10z" />
            <path d="M7065 5642 c5 -27 15 -42 15 -22 0 10 3 10 15 0 9 -7 15 -8 15 -2 0
5 -7 12 -15 16 -8 3 -15 10 -15 16 0 6 5 8 10 5 6 -3 10 -2 10 4 0 5 -9 9 -20
8 -15 -1 -19 -7 -15 -25z" />
            <path d="M10276 5655 c4 -8 8 -15 10 -15 2 0 4 7 4 15 0 8 -4 15 -10 15 -5 0
-7 -7 -4 -15z" />
            <path d="M10850 5644 c-14 -36 -7 -41 14 -10 10 15 12 27 6 31 -6 3 -15 -6
-20 -21z" />
            <path d="M18826 5654 c-3 -8 -11 -12 -17 -8 -8 4 -9 3 -5 -4 4 -7 2 -12 -4
-12 -6 0 -9 -4 -6 -9 4 -5 0 -12 -6 -14 -10 -4 -10 -6 -1 -6 15 -1 53 39 53
56 0 18 -7 16 -14 -3z" />
            <path d="M24201 5656 c-8 -10 -9 -16 -1 -21 5 -3 10 0 11 7 0 7 4 4 9 -7 6
-13 9 -15 9 -5 0 8 -3 21 -8 28 -6 10 -10 10 -20 -2z" />
            <path d="M30775 5659 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z" />
            <path d="M9403 5641 c-10 -16 -9 -19 4 -17 9 2 17 10 19 19 3 23 -8 21 -23 -2z" />
            <path d="M15897 5654 c-3 -3 -3 -13 0 -22 4 -10 2 -13 -5 -8 -7 4 -12 2 -12
-4 0 -21 25 -4 26 18 1 24 1 25 -9 16z" />
            <path d="M18845 5641 c-8 -27 -4 -33 12 -16 6 6 7 18 3 25 -6 9 -10 7 -15 -9z" />
            <path d="M31634 5625 c9 -20 12 -43 9 -53 -5 -13 -3 -14 6 -6 10 9 10 20 0 53
-7 22 -16 41 -21 41 -4 0 -2 -16 6 -35z" />
            <path d="M23560 5640 c-8 -5 -11 -12 -7 -16 4 -4 13 -2 19 4 15 15 7 24 -12
12z" />
            <path d="M31604 5634 c5 -10 9 -24 9 -31 0 -7 4 -10 8 -8 9 6 0 43 -14 52 -7
4 -8 0 -3 -13z" />
            <path d="M6010 5631 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5
3 -10 2 -10 -4z" />
            <path d="M17853 5620 c2 -11 6 -22 10 -25 4 -3 5 -2 2 2 -3 3 0 12 5 18 8 9 6
14 -5 18 -10 5 -14 1 -12 -13z" />
            <path d="M23306 5632 c-3 -5 1 -9 9 -9 8 0 12 4 9 9 -3 4 -7 8 -9 8 -2 0 -6
-4 -9 -8z" />
            <path d="M14651 5614 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
            <path d="M11782 5595 c-19 -20 -21 -28 -12 -45 12 -22 1 -27 -19 -7 -9 7 -11
7 -8 1 4 -7 0 -14 -8 -18 -9 -3 -12 -12 -8 -22 5 -13 3 -15 -7 -9 -8 5 -11 4
-7 -2 4 -6 0 -13 -8 -17 -9 -3 -12 -12 -8 -23 3 -10 2 -15 -2 -11 -5 5 -13 -2
-20 -15 -8 -15 -8 -24 -1 -29 7 -5 4 -14 -8 -25 -18 -17 -18 -17 -13 0 9 24 0
22 -13 -4 -16 -29 -20 -59 -9 -59 6 0 7 -4 4 -10 -10 -17 8 -11 34 11 20 16
22 22 11 32 -10 10 -11 9 -5 -2 6 -11 4 -13 -9 -8 -10 4 -20 7 -24 7 -3 0 3 6
14 14 16 13 21 13 36 0 11 -10 18 -12 23 -5 3 6 14 11 25 11 20 0 34 38 23 66
-4 12 -3 15 5 10 7 -4 12 -2 12 3 0 6 -4 11 -8 11 -4 0 -8 9 -8 20 0 11 4 20
8 20 4 0 8 -5 8 -12 0 -6 3 -8 7 -5 3 4 -2 16 -13 28 -19 21 -19 21 1 18 17
-4 19 1 18 34 -1 20 2 35 6 33 4 -3 7 4 7 15 0 10 0 19 -1 19 0 0 -10 -11 -23
-25z m3 -25 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11
-10z m-31 -77 c-1 -21 -3 -40 -3 -43 -2 -13 -40 -7 -45 6 -3 8 -2 13 2 11 12
-7 43 31 31 39 -9 5 -4 13 16 24 0 0 0 -17 -1 -37z m-94 -174 c0 -14 -17 -10
-23 5 -4 10 -1 13 9 9 7 -3 14 -9 14 -14z" />
            <path d="M11727 5466 c-4 -10 -1 -13 8 -9 8 3 12 9 9 14 -7 12 -11 11 -17 -5z" />
            <path d="M24200 5609 c0 -9 3 -19 7 -22 3 -4 6 -35 6 -70 l-1 -62 8 60 c6 44
4 67 -6 85 -8 16 -13 19 -14 9z" />
            <path d="M29251 5604 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
            <path d="M9369 5584 c-18 -19 -19 -23 -6 -18 16 6 22 -4 18 -28 0 -5 6 -8 14
-8 8 0 15 5 15 10 0 6 -5 10 -12 10 -7 0 -6 6 4 18 25 29 27 38 8 39 -10 1
-28 -10 -41 -23z m35 1 c-6 -16 -24 -21 -24 -7 0 11 11 22 21 22 5 0 6 -7 3
-15z" />
            <path d="M14356 5601 c-3 -5 1 -11 9 -15 8 -3 15 -11 15 -17 0 -8 -4 -8 -15 1
-25 21 -27 5 -2 -18 l23 -22 -2 28 c-1 15 -3 29 -4 32 -1 3 -2 8 -1 13 1 10
-17 9 -23 -2z" />
            <path d="M10822 5584 c-7 -8 -8 -14 -3 -14 10 0 25 19 20 25 -2 1 -10 -3 -17
-11z" />
            <path d="M8380 5578 c0 -4 4 -8 9 -8 6 0 12 4 15 8 3 5 -1 9 -9 9 -8 0 -15 -4
-15 -9z" />
            <path d="M16194 5575 c-4 -8 -9 -13 -13 -11 -3 2 -7 -9 -7 -25 -1 -16 3 -26 8
-23 5 3 7 12 4 19 -6 16 17 38 28 28 4 -4 4 0 0 10 -8 21 -13 21 -20 2z" />
            <path d="M20587 5575 c-10 -7 -17 -16 -17 -21 0 -4 5 -3 12 4 17 17 33 15 18
-3 -10 -12 -10 -15 1 -15 11 0 11 -2 -1 -10 -8 -5 -11 -12 -7 -17 11 -11 26
17 19 35 -4 11 -1 14 11 9 22 -8 21 -5 -1 14 -15 13 -22 14 -35 4z" />
            <path d="M20638 5583 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
            <path d="M22280 5575 c0 -8 4 -15 10 -15 5 0 7 7 4 15 -4 8 -8 15 -10 15 -2 0
-4 -7 -4 -15z" />
            <path d="M25774 5571 c2 -14 10 -25 20 -27 13 -2 14 1 7 10 -6 7 -7 17 -3 21
4 5 2 5 -5 2 -6 -4 -14 -1 -17 5 -2 7 -4 2 -2 -11z" />
            <path d="M6017 5569 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z" />
            <path d="M28597 5567 c-3 -9 -2 -17 3 -17 6 0 10 -10 10 -22 1 -22 1 -22 11
-4 7 12 8 21 2 25 -6 3 -12 13 -15 20 -3 12 -6 12 -11 -2z" />
            <path d="M31622 5550 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z" />
            <path d="M7930 5526 c0 -19 5 -38 10 -41 7 -4 7 -13 0 -26 -8 -15 -7 -19 4
-19 9 0 16 16 21 51 4 27 4 53 1 56 -5 6 -10 -30 -7 -50 1 -5 -4 -5 -10 -2 -6
4 -9 20 -5 36 3 16 1 29 -4 29 -6 0 -10 -15 -10 -34z" />
            <path d="M31643 5510 c0 -25 2 -35 4 -22 2 12 2 32 0 45 -2 12 -4 2 -4 -23z" />
            <path d="M20295 5529 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z" />
            <path d="M25402 5510 c-8 -13 -8 -20 -2 -20 6 0 13 7 17 15 9 24 -1 27 -15 5z" />
            <path d="M18778 5495 c-3 -14 -1 -25 3 -25 5 0 9 11 9 25 0 14 -2 25 -4 25 -2
0 -6 -11 -8 -25z" />
            <path d="M13136 5498 c3 -5 37 -7 75 -4 41 2 67 1 63 -5 -3 -5 5 -9 17 -9 18
0 20 3 11 12 -14 14 -174 20 -166 6z" />
            <path d="M14573 5495 c0 -8 3 -13 6 -10 3 3 11 -5 18 -17 9 -16 12 -18 13 -7
0 9 -8 23 -18 32 -16 15 -19 15 -19 2z" />
            <path d="M14600 5506 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21 -21
13z" />
            <path d="M22255 5499 c-11 -16 -1 -19 13 -3 7 8 8 14 3 14 -5 0 -13 -5 -16
-11z" />
            <path d="M26610 5501 c0 -6 -7 -8 -15 -5 -11 5 -13 2 -9 -10 5 -14 9 -14 23
-4 9 7 17 13 19 14 2 1 -1 5 -7 9 -6 3 -11 2 -11 -4z" />
            <path d="M31617 5490 c3 -11 7 -20 9 -20 2 0 4 9 4 20 0 11 -4 20 -9 20 -5 0
-7 -9 -4 -20z" />
            <path d="M5960 5473 c-15 -20 -20 -36 -16 -56 4 -17 2 -26 -4 -22 -6 3 -10 1
-10 -4 0 -18 20 -12 26 7 3 9 11 30 19 45 8 15 10 25 5 22 -6 -3 -10 -2 -10 4
0 5 8 11 18 13 11 2 13 6 5 11 -7 4 -20 -4 -33 -20z m3 -28 c-3 -9 -8 -14 -10
-11 -3 3 -2 9 2 15 9 16 15 13 8 -4z" />
            <path d="M7245 5490 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z" />
            <path d="M21815 5491 c-3 -5 -2 -12 3 -15 5 -3 9 1 9 9 0 17 -3 19 -12 6z" />
            <path d="M28600 5485 c0 -8 4 -15 10 -15 5 0 7 7 4 15 -4 8 -8 15 -10 15 -2 0
-4 -7 -4 -15z" />
            <path d="M7262 5469 c-9 -14 -10 -24 -2 -37 8 -13 10 -13 9 -2 -1 8 1 23 6 33
11 25 2 30 -13 6z" />
            <path d="M9620 5475 c8 -11 15 -21 16 -23 1 -2 5 5 8 15 4 9 4 14 0 10 -4 -4
-14 -1 -23 6 -14 11 -15 10 -1 -8z" />
            <path d="M30942 5470 c-15 -25 -15 -40 0 -40 6 0 8 3 5 6 -3 4 -2 15 4 26 14
26 7 32 -9 8z" />
            <path d="M18735 5469 c-4 -6 -4 -13 -1 -16 3 -4 6 0 6 8 0 11 2 11 10 -1 8
-13 10 -12 10 3 0 19 -15 23 -25 6z" />
            <path d="M27299 5463 c-13 -16 -12 -17 4 -4 9 7 17 15 17 17 0 8 -8 3 -21 -13z" />
            <path d="M29611 5465 c-1 -21 16 -45 31 -45 4 0 5 7 2 15 -4 8 -10 13 -14 10
-5 -3 -11 5 -13 17 -4 21 -4 21 -6 3z" />
            <path d="M6925 5460 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z" />
            <path d="M14579 5463 c-1 -5 -1 -11 0 -16 1 -4 -17 -19 -39 -35 -40 -28 -46
-42 -29 -77 8 -18 9 -18 8 5 -2 41 0 50 12 50 6 0 8 -9 3 -22 -3 -13 -5 -21
-3 -18 2 3 13 15 24 28 19 23 19 23 -1 16 -19 -7 -19 -7 0 14 10 12 22 20 25
17 4 -2 8 7 9 21 2 21 -6 36 -9 17z" />
            <path d="M30591 5428 c-33 -45 -39 -62 -18 -55 6 2 12 10 12 18 0 8 9 22 19
32 17 15 31 47 21 47 -2 0 -17 -19 -34 -42z" />
            <path d="M9612 5440 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z" />
            <path d="M17803 5444 c-3 -8 -11 -13 -17 -11 -6 3 -12 -6 -12 -19 -2 -20 1
-21 17 -11 10 6 16 13 14 15 -2 3 0 13 6 23 5 11 7 19 4 19 -3 0 -8 -7 -12
-16z" />
            <path d="M7953 5436 c-13 -6 -23 -16 -23 -22 0 -16 40 -5 47 13 7 19 2 21 -24
9z" />
            <path d="M28587 5420 c-16 -19 -13 -55 4 -44 5 3 7 9 4 14 -3 5 -1 19 5 30 14
25 9 26 -13 0z" />
            <path d="M9626 5415 c-3 -9 -6 -24 -5 -33 0 -9 5 -4 10 12 9 32 6 48 -5 21z" />
            <path d="M15791 5421 c-9 -6 -10 -11 -2 -15 6 -4 11 -1 11 6 1 8 6 6 15 -6 8
-11 15 -15 15 -9 0 17 -25 33 -39 24z" />
            <path d="M12750 5398 c0 -6 3 -8 7 -5 3 4 12 2 19 -4 8 -7 14 -6 20 2 4 7 3 9
-4 5 -6 -3 -13 -2 -17 4 -8 14 -25 13 -25 -2z" />
            <path d="M14265 5390 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3
0 -8 -4 -11 -10z" />
            <path d="M28215 5390 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3
0 -8 -4 -11 -10z" />
            <path d="M29610 5390 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z" />
            <path d="M30895 5370 c1 -11 6 -25 12 -31 9 -9 11 5 4 44 -3 17 -18 5 -16 -13z" />
            <path d="M8250 5368 c0 -6 3 -8 7 -5 3 4 12 -1 20 -11 13 -15 13 -15 3 6 -11
23 -30 30 -30 10z" />
            <path d="M25326 5348 l-18 -33 21 25 c18 22 26 40 18 40 -1 0 -11 -15 -21 -32z" />
            <path d="M29256 5363 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z" />
            <path d="M14255 5361 c3 -2 0 -10 -6 -18 -6 -7 -7 -13 -2 -13 5 0 16 9 25 19
16 18 15 20 -2 18 -11 -1 -17 -3 -15 -6z" />
            <path d="M18694 5357 c-6 -7 -9 -18 -6 -25 2 -6 -1 -12 -8 -12 -6 0 -8 -4 -5
-10 9 -15 30 9 31 36 2 28 2 27 -12 11z" />
            <path d="M28220 5357 c0 -9 -8 -17 -17 -18 -11 0 -13 -3 -5 -6 6 -2 12 -9 12
-15 0 -6 -7 -8 -15 -5 -8 4 -17 1 -21 -6 -4 -7 -4 -9 1 -5 4 4 14 3 21 -3 9
-7 15 -1 24 26 7 20 9 39 6 42 -3 4 -6 -1 -6 -10z" />
            <path d="M25873 5345 c-3 -9 -3 -18 -1 -21 3 -3 8 4 11 16 6 23 -1 27 -10 5z" />
            <path d="M8256 5337 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 -10 9 -11 8 -5 -6z" />
            <path d="M11735 5340 c3 -5 10 -10 16 -10 5 0 9 5 9 10 0 6 -7 10 -16 10 -8 0
-12 -4 -9 -10z" />
            <path d="M18921 5331 c-6 -10 -7 -21 -4 -25 3 -3 1 -6 -5 -6 -7 0 -12 -4 -12
-8 0 -5 7 -9 14 -9 8 0 15 3 14 6 -2 22 4 31 15 25 9 -6 9 -5 0 8 -7 8 -10 18
-6 21 3 4 3 7 0 7 -3 0 -11 -8 -16 -19z" />
            <path d="M24191 5343 c-1 -6 -4 -20 -7 -30 -5 -17 -5 -17 6 0 6 10 9 23 6 30
-3 9 -5 9 -5 0z" />
            <path d="M24206 5328 c-9 -35 -7 -71 5 -88 8 -11 9 -8 4 13 -3 16 -2 27 2 24
4 -3 9 13 10 34 3 42 -11 53 -21 17z" />
            <path d="M7211 5335 c0 -5 3 -16 8 -23 7 -11 9 -10 14 3 4 9 3 14 -2 10 -5 -3
-12 1 -14 7 -3 7 -6 9 -6 3z" />
            <path d="M22180 5329 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0
-10 -5 -10 -11z" />
            <path d="M11700 5315 c-9 -11 -10 -29 -1 -80 8 -47 9 -71 1 -85 -7 -14 -8 -26
-1 -38 9 -14 11 -11 12 18 1 22 3 27 6 15 3 -11 9 -24 14 -29 5 -5 4 6 -1 25
-13 45 -13 62 2 53 7 -5 8 -3 3 6 -4 7 -2 16 5 20 7 4 10 14 8 21 -3 9 -10 4
-20 -15 l-15 -29 -6 48 c-6 38 -3 52 10 67 9 10 11 18 6 18 -6 0 -16 -7 -23
-15z" />
            <path d="M20410 5320 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z" />
            <path d="M29255 5313 c4 -13 2 -23 -3 -23 -5 0 -9 -6 -9 -12 0 -7 -4 -20 -7
-29 -4 -9 2 -23 15 -35 21 -19 21 -19 17 1 -2 11 -10 19 -16 18 -15 -4 -15 -2
4 35 12 24 13 36 4 49 -10 16 -10 15 -5 -4z" />
            <path d="M29544 5322 c-14 -10 -12 -60 3 -55 9 4 10 10 2 24 -6 12 -6 19 0 19
5 0 12 -6 14 -12 3 -8 6 -7 6 4 1 20 -11 29 -25 20z" />
            <path d="M30890 5321 c0 -6 4 -13 10 -16 6 -3 7 1 4 9 -7 18 -14 21 -14 7z" />
            <path d="M5906 5305 c-9 -26 -7 -32 5 -12 6 10 9 21 6 23 -2 3 -7 -2 -11 -11z" />
            <path d="M11732 5308 c-7 -7 -11 -23 -11 -37 1 -18 3 -20 6 -8 2 9 9 15 14 12
5 -3 6 1 3 9 -3 9 -1 16 5 16 6 0 11 5 11 10 0 14 -13 13 -28 -2z" />
            <path d="M16011 5289 c-13 -17 -29 -28 -36 -25 -9 3 -11 -2 -8 -15 8 -30 19
-31 37 -4 9 14 13 25 10 25 -4 0 1 9 11 20 10 11 16 22 13 25 -2 3 -15 -9 -27
-26z" />
            <path d="M17300 5305 c-8 -9 -8 -15 -2 -15 12 0 26 19 19 26 -2 2 -10 -2 -17
-11z" />
            <path d="M21727 5306 c-3 -8 0 -16 7 -19 6 -3 13 4 13 14 2 22 -12 25 -20 5z" />
            <path d="M24650 5310 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5
0 -10 -4 -10 -10z" />
            <path d="M6855 5300 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z" />
            <path d="M11603 5284 c-3 -11 -11 -22 -17 -26 -8 -5 -7 -8 1 -8 7 0 11 -20 9
-60 -2 -59 -9 -80 -23 -66 -4 4 -1 11 6 15 11 7 11 10 0 15 -11 5 -11 7 -1 12
6 3 12 10 12 16 0 6 -7 4 -17 -3 -15 -12 -16 -12 -10 4 3 9 3 17 -1 17 -4 0
-13 -15 -20 -32 -10 -28 -9 -30 2 -15 11 14 15 15 19 4 7 -20 -5 -35 -25 -31
-20 3 -20 3 -21 -103 l-1 -72 -21 26 c-13 14 -25 21 -29 15 -3 -5 1 -12 9 -16
22 -8 18 -24 -12 -52 -15 -14 -19 -22 -10 -18 10 3 17 1 17 -5 0 -6 7 -11 16
-11 12 0 14 4 6 13 -5 6 -7 20 -3 29 9 25 33 6 29 -23 -2 -19 -9 -24 -33 -24
-16 0 -35 -3 -42 -8 -6 -4 -13 -4 -16 0 -2 5 -2 1 1 -7 2 -8 5 -35 7 -60 1
-25 1 -35 -2 -22 -2 12 -9 20 -14 17 -5 -4 -2 -21 6 -42 8 -20 13 -38 10 -41
-2 -3 -9 7 -15 22 -5 15 -14 25 -19 22 -5 -4 -12 0 -14 6 -3 7 -3 -2 0 -19 3
-21 1 -33 -5 -33 -6 0 -9 8 -7 18 3 9 -1 4 -9 -13 -8 -16 -12 -33 -9 -37 2 -5
-1 -8 -8 -8 -7 0 -8 -4 -2 -12 5 -7 9 -51 8 -98 0 -83 0 -85 -25 -86 -14 -1
-31 3 -39 10 -11 8 -13 8 -8 -1 4 -6 3 -15 -3 -18 -6 -4 -8 -16 -4 -27 4 -14
2 -18 -5 -14 -12 8 -15 -6 -4 -18 9 -8 12 -82 6 -113 -3 -13 -11 -23 -19 -23
-8 0 -14 -9 -14 -20 0 -13 -7 -20 -20 -20 -21 0 -22 -2 -24 -97 0 -28 -4 -55
-8 -59 -12 -11 -10 -26 2 -19 6 3 10 -1 10 -9 0 -9 -4 -16 -10 -16 -5 0 -10
-5 -10 -11 0 -5 4 -8 9 -5 5 3 9 -6 9 -19 0 -14 -5 -25 -11 -25 -6 0 -8 3 -4
6 3 4 -1 13 -9 22 -10 9 -13 24 -10 41 l6 26 -14 -25 c-11 -19 -11 -27 -3 -33
9 -5 6 -12 -7 -22 -11 -8 -17 -18 -13 -21 4 -4 1 -14 -6 -22 -10 -13 -9 -15 6
-9 11 4 17 2 17 -8 0 -8 -6 -12 -14 -9 -19 7 -22 -25 -19 -191 1 -90 -1 -135
-7 -125 -6 9 -10 10 -10 3 0 -7 -12 -10 -30 -8 -31 4 -30 6 -34 -57 -1 -9 -4
-19 -8 -23 -4 -4 -3 -18 2 -31 12 -31 2 -84 -16 -84 -8 0 -14 -4 -14 -10 0 -5
7 -7 15 -4 8 4 15 2 16 -2 6 -64 6 -106 -1 -89 -9 21 -12 17 -15 -25 -2 -14
-3 -29 -4 -34 0 -5 -8 -3 -16 4 -8 7 -15 8 -15 3 0 -5 8 -16 19 -25 17 -15 18
-20 5 -69 -17 -63 -18 -82 -4 -74 6 4 10 -6 10 -22 0 -21 -3 -24 -9 -14 -8 12
-12 10 -22 -12 -7 -15 -9 -30 -4 -35 4 -4 10 -1 12 6 4 10 9 8 20 -5 13 -16
13 -8 8 73 -4 51 -2 94 2 97 5 3 10 27 12 54 1 26 4 50 7 52 8 8 -3 -306 -11
-319 -5 -8 -4 -11 2 -7 16 10 10 -127 -6 -147 -12 -15 -12 -17 0 -14 8 2 13
-2 12 -8 -1 -6 13 -10 32 -10 18 0 31 4 28 9 -2 4 -13 5 -22 2 -11 -3 -24 2
-32 12 -12 15 -12 16 1 7 8 -5 24 -10 35 -10 11 -1 29 -4 41 -7 28 -7 45 5 34
23 -8 13 -10 13 -10 -1 0 -9 -7 -16 -15 -16 -11 0 -12 4 -4 14 6 7 9 24 5 37
-4 17 -1 26 10 30 22 9 31 7 16 -2 -7 -5 -10 -15 -7 -23 6 -17 67 -17 73 0 2
6 8 9 13 6 5 -4 6 2 2 11 -4 12 -3 15 5 10 8 -5 10 -1 5 10 -5 13 -3 16 10 11
9 -3 19 -1 23 5 4 7 3 8 -4 4 -7 -4 -12 -2 -12 3 0 6 5 12 10 12 6 1 16 2 23
3 6 1 11 12 9 25 -1 12 3 30 8 39 9 14 10 13 5 -5 -3 -12 -1 -24 5 -28 5 -3
10 1 10 9 0 9 5 16 12 16 6 0 1 11 -12 25 -13 14 -22 25 -19 26 50 9 48 7 52
49 2 30 6 34 37 38 19 2 33 2 30 0 -3 -2 -14 -10 -24 -18 -18 -13 -19 -13 -11
0 6 11 5 12 -4 4 -6 -6 -13 -21 -16 -34 -2 -12 -12 -31 -21 -41 -23 -25 -10
-37 15 -15 11 10 17 25 14 34 -4 9 -2 13 5 8 6 -3 14 -1 18 5 5 8 3 9 -6 4 -8
-5 -11 -3 -8 6 3 8 11 13 17 11 7 -1 10 2 7 7 -3 5 1 11 9 15 8 3 15 10 15 16
0 5 7 18 15 27 8 10 15 22 14 27 0 5 5 13 13 17 7 5 8 9 2 9 -7 0 -9 11 -6 25
5 27 -12 35 -22 9 -9 -23 -7 -31 4 -14 7 12 9 10 7 -7 0 -12 -5 -19 -9 -17 -4
3 -8 1 -8 -5 0 -5 4 -11 9 -13 5 -1 4 -8 -3 -14 -9 -9 -13 0 -13 37 -1 27 3
52 9 55 7 4 6 9 -2 14 -7 5 1 7 19 6 18 0 31 -6 31 -13 0 -7 4 -13 10 -13 13
0 32 38 22 44 -12 7 -15 106 -3 106 5 0 7 -10 3 -22 -4 -17 -2 -20 11 -15 11
4 14 2 10 -8 -3 -8 -8 -13 -12 -10 -10 6 -7 -33 4 -42 4 -5 5 -3 2 4 -4 6 0
19 9 27 8 9 12 16 7 16 -4 0 -1 5 7 10 11 7 11 10 2 10 -7 0 -10 5 -7 10 4 6
11 7 17 4 7 -5 8 -2 3 6 -5 8 -1 19 11 28 18 14 18 14 1 8 -10 -3 -21 -6 -23
-6 -2 0 -4 -6 -4 -14 0 -7 -7 -19 -15 -26 -18 -15 -20 1 -2 19 6 8 7 11 2 8
-14 -7 -42 10 -32 19 4 4 22 9 40 10 54 3 58 4 52 13 -6 11 -17 107 -11 100
13 -15 26 -50 17 -45 -13 8 -13 -1 -1 -29 l9 -20 1 20 c0 11 12 38 25 60 14
22 25 45 25 50 0 6 -11 -7 -24 -29 -19 -33 -24 -36 -29 -20 -4 11 -11 29 -16
41 -8 18 -7 21 10 20 16 -1 19 -6 14 -26 -6 -22 -4 -23 10 -11 9 7 14 19 11
27 -3 7 -1 13 5 13 15 0 39 27 31 35 -10 10 -11 75 -2 75 5 0 11 -6 13 -12 4
-10 6 -10 6 0 1 7 5 10 11 7 7 -4 7 3 0 20 -12 32 -12 35 -1 35 5 0 11 -8 14
-17 3 -14 8 -9 19 16 9 19 13 42 10 50 -3 9 -1 12 6 7 16 -10 15 6 0 23 -10
11 -10 13 0 7 13 -8 17 6 5 18 -5 4 -8 37 -9 72 0 37 3 59 8 52 6 -10 10 -10
18 2 6 9 10 10 10 3 0 -6 -5 -15 -11 -19 -6 -3 -9 -15 -6 -25 3 -11 0 -19 -5
-19 -7 0 -6 -5 1 -13 6 -8 8 -16 5 -20 -3 -3 -1 -14 5 -24 9 -17 10 -17 10 2
-1 11 2 33 5 48 4 19 3 26 -4 22 -17 -10 -11 14 8 35 18 20 19 20 17 -7 -2
-19 -1 -21 2 -5 2 12 9 22 15 22 6 0 8 3 5 7 -4 3 -2 12 4 20 9 9 8 13 0 13
-7 0 -6 9 5 24 12 19 13 29 4 43 -10 17 -11 17 -6 -2 3 -11 2 -26 -3 -33 -5
-8 -8 15 -8 57 1 38 5 67 9 65 5 -3 6 -15 2 -27 -5 -18 -4 -20 6 -10 10 10 10
18 0 43 -10 23 -10 34 -2 42 7 7 15 8 19 4 4 -4 1 -11 -6 -15 -9 -6 -10 -11
-2 -15 6 -4 11 -2 11 4 0 8 6 8 20 0 24 -13 26 -7 3 16 -16 16 -16 16 7 9 14
-4 22 -4 18 0 -4 5 -3 14 3 22 9 10 8 13 -1 13 -10 0 -10 3 0 15 9 11 9 15 1
15 -7 0 -9 5 -6 10 4 6 11 7 17 4 6 -4 8 -2 4 4 -3 5 -1 12 6 14 6 2 8 10 4
17 -6 9 -11 9 -19 -4 -10 -13 -12 -10 -15 17 -3 40 17 118 30 118 6 0 8 -6 5
-13 -3 -8 1 -14 8 -14 8 0 11 7 7 16 -5 14 -3 14 11 2 15 -12 17 -11 18 10 1
13 2 36 3 50 0 14 7 33 15 42 7 10 9 17 3 17 -6 0 -12 -5 -14 -12 -2 -6 -4 13
-4 43 1 35 7 65 19 82 9 14 17 36 17 47 0 19 -4 20 -57 14 -32 -3 -61 -8 -66
-11 -10 -6 1 -23 16 -23 7 0 6 4 -3 10 -11 7 -4 10 27 10 23 0 50 3 60 6 14 5
15 4 4 -4 -8 -6 -11 -16 -8 -24 3 -8 1 -21 -4 -29 -6 -10 -9 -7 -9 14 0 17 -5
27 -15 27 -8 0 -15 -4 -15 -10 0 -5 -28 -8 -63 -7 -61 2 -63 2 -62 28 0 15 4
25 10 22 5 -3 3 2 -6 12 -10 9 -32 18 -50 19 -30 1 -34 5 -35 33 -1 20 3 30 9
26 6 -3 7 -1 3 5 -4 7 -15 12 -24 13 -14 0 -13 2 3 9 17 7 17 9 3 9 -10 1 -24
13 -32 29 -14 25 -15 26 -16 5 0 -13 5 -23 10 -23 6 0 10 -6 10 -14 0 -9 -9
-14 -20 -13 -11 1 -16 5 -12 9 11 11 2 69 -9 62 -5 -3 -9 5 -9 17 0 22 0 22
22 3 20 -18 21 -18 15 -1 -4 10 -7 21 -7 23 0 2 -6 4 -13 4 -15 0 -20 37 -6
45 5 4 9 16 8 28 0 21 -1 21 -9 2 -5 -12 -8 21 -8 85 1 135 0 142 -24 129 -14
-7 -24 -7 -34 1 -11 10 -15 8 -21 -6z m84 -376 c-3 -8 -6 -5 -6 6 -1 11 2 17
5 13 3 -3 4 -12 1 -19z m-280 -170 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4
-12 1 -19z m-141 -495 c-10 -10 -19 5 -10 18 6 11 8 11 12 0 2 -7 1 -15 -2
-18z m-67 -310 c0 -16 -1 -16 -9 2 -7 17 -9 17 -9 3 -1 -10 -6 -18 -12 -18 -6
0 -9 9 -6 20 3 11 1 18 -4 14 -10 -6 -13 29 -3 39 10 9 44 -38 43 -60z m494
-193 c-3 -12 -8 -19 -11 -16 -5 6 5 36 12 36 2 0 2 -9 -1 -20z m14 -22 c-3 -8
-6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-400 -695 c0 -10 -6 -27 -14
-38 -12 -18 -13 -17 -11 17 3 37 24 55 25 21z m73 13 c0 -2 -8 -10 -17 -17
-16 -13 -17 -12 -4 4 13 16 21 21 21 13z m-86 -92 c2 -6 -2 -11 -10 -11 -8 0
-14 4 -14 9 0 4 -4 8 -10 8 -5 0 -9 -3 -9 -7 4 -26 -3 -34 -23 -29 -13 3 -17
3 -10 0 16 -8 15 -29 -3 -44 -8 -7 -13 -15 -10 -19 2 -4 -9 -5 -25 -3 -25 4
-27 6 -15 18 16 17 29 19 19 3 -4 -7 -1 -10 7 -7 9 3 14 19 13 41 0 21 5 37
10 37 6 0 6 4 -1 8 -7 4 7 8 32 8 25 0 46 -5 49 -12z" />
            <path d="M15721 5278 c-1 -9 4 -20 9 -23 6 -4 8 -16 4 -28 -3 -12 -3 -19 1
-15 10 10 14 69 4 63 -4 -3 -10 1 -12 7 -4 8 -6 7 -6 -4z" />
            <path d="M24182 5270 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z" />
            <path d="M24665 5280 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3
0 -8 -4 -11 -10z" />
            <path d="M8181 5249 c-7 -12 -10 -23 -8 -26 3 -3 11 5 17 17 15 28 8 35 -9 9z" />
            <path d="M15935 5260 c-3 -5 1 -20 9 -32 20 -28 20 -50 1 -24 -8 11 -15 16
-15 10 0 -5 6 -17 13 -26 12 -15 14 -15 25 3 7 11 8 19 2 19 -5 0 -9 3 -9 8 4
21 -2 32 -13 26 -8 -5 -9 -2 -5 9 7 19 2 23 -8 7z" />
            <path d="M18900 5260 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z" />
            <path d="M20411 5265 c0 -5 3 -16 8 -23 7 -11 9 -10 14 3 4 9 3 14 -2 10 -5
-3 -12 1 -14 7 -3 7 -6 9 -6 3z" />
            <path d="M23451 5254 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
            <path d="M25318 5258 c-4 -10 -1 -19 8 -22 21 -8 24 -8 24 5 0 5 -4 8 -9 4 -5
-3 -11 3 -13 12 -3 16 -4 17 -10 1z" />
            <path d="M31581 5254 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
            <path d="M8206 5244 c-9 -23 -8 -26 6 -18 8 5 9 2 4 -11 -5 -15 -4 -16 9 -5
12 10 13 15 4 20 -8 5 -6 11 7 19 18 10 18 10 -2 11 -12 0 -25 -7 -28 -16z" />
            <path d="M9550 5250 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z" />
            <path d="M9577 5238 c-9 -31 -2 -33 11 -3 6 14 8 25 3 25 -5 0 -11 -10 -14
-22z" />
            <path d="M14418 5244 c6 -12 9 -31 7 -43 -5 -26 6 -27 21 -3 10 15 7 23 -14
43 -23 21 -24 22 -14 3z" />
            <path d="M29546 5252 c-3 -5 1 -9 9 -9 8 0 12 4 9 9 -3 4 -7 8 -9 8 -2 0 -6
-4 -9 -8z" />
            <path d="M24170 5232 c0 -5 7 -9 15 -9 8 0 15 4 15 9 0 4 -7 8 -15 8 -8 0 -15
-4 -15 -8z" />
            <path d="M21698 5223 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
            <path d="M28534 5219 c-3 -6 -1 -15 5 -20 6 -5 9 -6 6 -3 -3 4 0 13 6 21 8 9
8 13 1 13 -6 0 -14 -5 -18 -11z" />
            <path d="M29533 5208 c1 -10 4 -18 8 -18 4 0 9 8 11 18 3 11 0 19 -8 19 -7 0
-12 -9 -11 -19z" />
            <path d="M8190 5196 c0 -17 16 -30 23 -19 3 5 -1 14 -9 21 -12 10 -14 10 -14
-2z" />
            <path d="M17250 5200 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z" />
            <path d="M10585 5180 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z" />
            <path d="M18615 5180 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z" />
            <path d="M18818 5171 c3 -9 7 -11 9 -4 3 7 10 10 18 7 7 -3 15 -1 18 4 3 4 -7
8 -23 8 -21 0 -27 -3 -22 -15z" />
            <path d="M27150 5181 c0 -5 7 -12 16 -15 14 -5 15 -4 4 9 -14 17 -20 19 -20 6z" />
            <path d="M7886 5166 c3 -9 2 -16 -3 -16 -5 0 -10 -7 -10 -16 0 -14 2 -14 14 2
16 21 16 31 3 39 -6 3 -7 -1 -4 -9z" />
            <path d="M17238 5153 c6 -2 12 -9 12 -14 0 -6 -5 -7 -12 -3 -7 4 -8 3 -4 -4 7
-12 36 -5 36 8 0 8 -26 21 -38 19 -4 0 -1 -3 6 -6z" />
            <path d="M22090 5140 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5
0 -10 -4 -10 -10z" />
            <path d="M15647 5126 c-4 -10 -1 -13 9 -9 7 3 14 9 14 14 0 14 -17 10 -23 -5z" />
            <path d="M18612 5123 c4 -14 -13 -53 -23 -53 -6 0 -5 8 1 21 7 11 10 23 7 25
-9 9 -25 -44 -16 -57 7 -11 11 -10 23 6 20 27 26 54 15 61 -5 3 -8 2 -7 -3z" />
            <path d="M27133 5099 c-2 -23 3 -25 10 -4 4 8 3 16 -1 19 -4 3 -9 -4 -9 -15z" />
            <path d="M15643 5086 c-13 -13 -23 -30 -23 -38 0 -8 -5 -19 -12 -26 -9 -9 -9
-12 0 -12 15 0 31 31 24 50 -5 13 -3 13 12 1 13 -11 14 -16 4 -22 -7 -5 -9 -9
-4 -9 5 0 1 -10 -10 -21 -14 -16 -15 -21 -4 -17 22 7 40 49 30 68 -5 9 -4 23
1 33 13 23 8 21 -18 -7z" />
            <path d="M15861 5094 c1 -29 -6 -51 -20 -68 -7 -8 -10 -19 -7 -25 3 -5 -1 -11
-9 -15 -8 -3 -15 -11 -15 -17 0 -7 8 -5 19 5 11 10 17 24 14 32 -3 9 0 14 8
12 8 -2 13 3 12 10 -2 6 3 12 10 12 8 0 7 4 -3 10 -13 8 -12 10 3 10 10 0 16
4 13 9 -3 5 1 11 9 15 8 3 15 11 15 17 0 8 -4 8 -13 0 -10 -8 -17 -7 -25 1 -8
8 -11 6 -11 -8z" />
            <path d="M17690 5099 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0
-10 -5 -10 -11z" />
            <path d="M21636 5093 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z" />
            <path d="M29473 5080 c-7 -18 -7 -32 -2 -36 8 -5 11 3 8 22 0 4 4 10 10 13 5
4 7 12 3 18 -5 8 -12 2 -19 -17z" />
            <path d="M11716 5075 c-4 -16 -13 -33 -19 -37 -6 -4 -9 -10 -5 -13 10 -10 40
42 36 62 -3 13 -6 10 -12 -12z" />
            <path d="M18734 5063 c0 -36 10 -33 19 8 5 17 2 26 -6 26 -8 0 -13 -13 -13
-34z" />
            <path d="M23006 5092 c-6 -10 -2 -39 6 -47 3 -3 4 -20 2 -38 -3 -23 -1 -28 6
-16 9 16 9 37 -2 54 -4 6 -3 17 3 25 5 8 9 18 9 23 0 10 -18 9 -24 -1z" />
            <path d="M10215 5065 c0 -13 2 -26 5 -29 3 -3 6 5 5 19 0 13 -2 26 -5 29 -3 3
-6 -5 -5 -19z" />
            <path d="M24117 5059 c-13 -15 -15 -24 -7 -37 8 -12 10 -12 7 -2 -2 8 2 23 11
33 8 9 13 19 11 22 -3 2 -13 -5 -22 -16z" />
            <path d="M29175 5061 c3 -5 1 -12 -4 -16 -6 -3 -8 -12 -5 -19 3 -8 1 -17 -5
-21 -6 -3 -11 -14 -11 -23 0 -11 6 -6 16 13 21 39 27 75 14 75 -6 0 -9 -4 -5
-9z" />
            <path d="M31560 5060 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5
0 -10 -4 -10 -10z" />
            <path d="M11485 5040 c-3 -11 0 -29 8 -41 15 -20 15 -20 13 4 0 14 -4 23 -8
20 -13 -7 -9 12 4 25 9 9 9 12 1 12 -7 0 -14 -9 -18 -20z" />
            <path d="M28056 5051 c-4 -5 -3 -17 1 -27 5 -14 3 -16 -12 -10 -11 4 -16 4
-12 0 4 -4 2 -13 -4 -21 -16 -19 -1 -16 19 4 9 9 17 27 17 40 1 25 -1 28 -9
14z" />
            <path d="M24137 5043 c-4 -3 -7 -13 -7 -22 1 -13 3 -13 11 2 11 19 8 33 -4 20z" />
            <path d="M11723 5029 c-8 -8 -7 -18 2 -36 8 -14 11 -31 9 -38 -3 -6 3 -18 12
-26 12 -10 15 -10 10 -1 -7 12 -17 59 -19 95 -1 20 0 20 -14 6z" />
            <path d="M9459 5019 c-1 -8 -2 -29 -4 -48 -2 -22 0 -29 6 -20 5 8 6 22 3 32
-3 9 -1 17 5 17 6 0 11 -7 11 -15 0 -8 4 -15 9 -15 19 0 17 16 -6 39 -16 17
-23 20 -24 10z" />
            <path d="M22040 5015 c0 -8 4 -15 10 -15 5 0 7 7 4 15 -4 8 -8 15 -10 15 -2 0
-4 -7 -4 -15z" />
            <path d="M27091 5004 c-6 -15 -9 -29 -6 -32 3 -3 5 0 5 7 0 7 3 10 8 8 4 -3 8
6 8 19 2 30 0 30 -15 -2z" />
            <path d="M10214 5004 c-4 -10 -9 -22 -11 -26 -2 -5 0 -8 6 -8 11 0 26 39 17
47 -3 3 -9 -3 -12 -13z" />
            <path d="M10513 5001 c-13 -11 -23 -27 -23 -37 0 -16 1 -16 15 2 14 18 14 17
4 -11 -7 -20 -7 -27 0 -20 6 6 12 22 13 35 2 14 10 30 18 37 8 7 11 12 5 12
-5 0 -20 -8 -32 -18z" />
            <path d="M15804 5002 c-16 -11 -34 -48 -27 -56 13 -12 14 -48 1 -40 -7 5 -8 3
-3 -6 6 -10 11 -9 21 5 7 10 11 21 9 26 -3 4 -2 9 2 12 5 2 2 2 -4 1 -16 -4
-12 28 6 50 14 17 13 20 -5 8z" />
            <path d="M25746 4995 c-9 -26 -7 -32 5 -12 6 10 9 21 6 23 -2 3 -7 -2 -11 -11z" />
            <path d="M29450 4999 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0
-10 -5 -10 -11z" />
            <path d="M6679 4953 c-28 -57 -34 -73 -20 -53 6 8 11 11 11 5 1 -20 20 21 21
45 l1 25 8 -25 c7 -22 8 -20 7 13 0 20 -1 37 -3 37 -1 0 -12 -21 -25 -47z" />
            <path d="M17624 4988 c-12 -20 -12 -70 1 -95 l12 -24 6 23 c4 12 4 24 2 27 -3
2 -6 -2 -6 -10 -1 -8 -5 0 -8 16 -4 17 -4 40 1 53 8 24 4 30 -8 10z" />
            <path d="M24080 4989 c0 -15 18 -19 23 -6 3 8 -1 14 -9 14 -8 0 -14 -4 -14 -8z" />
            <path d="M25174 4991 c4 -6 2 -19 -6 -28 -7 -9 -13 -30 -14 -47 l-2 -31 19 24
c23 28 25 49 4 31 -11 -9 -15 -9 -15 -1 0 6 7 14 15 17 18 7 20 30 3 40 -7 4
-8 3 -4 -5z" />
            <path d="M31495 4990 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z" />
            <path d="M13215 4979 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z" />
            <path d="M30353 4947 c-21 -42 -21 -43 -1 -44 17 -2 19 2 13 24 -5 23 -4 25 9
14 11 -9 16 -10 16 -2 0 6 -4 11 -10 11 -5 0 -10 5 -10 11 0 5 4 7 10 4 6 -3
7 1 4 9 -3 9 -7 16 -8 16 -1 0 -11 -19 -23 -43z" />
            <path d="M18533 4969 c3 -9 1 -21 -4 -26 -7 -7 -6 -12 4 -16 10 -4 13 2 11 20
-1 14 -6 28 -10 31 -4 4 -5 0 -1 -9z" />
            <path d="M11452 4950 c-7 -11 -8 -20 -3 -20 9 0 24 30 18 36 -1 2 -8 -6 -15
-16z" />
            <path d="M31501 4954 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
            <path d="M9476 4944 c-9 -23 -2 -28 13 -10 7 8 8 17 3 20 -6 3 -13 -1 -16 -10z" />
            <path d="M31475 4941 c4 -15 3 -21 -3 -17 -5 3 -12 0 -15 -7 -3 -8 1 -14 9
-14 8 0 14 5 14 10 0 5 7 4 15 -3 22 -18 18 -2 -6 28 -21 26 -21 26 -14 3z" />
            <path d="M13215 4940 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3
0 -8 -4 -11 -10z" />
            <path d="M15570 4932 c0 -10 -5 -23 -12 -30 -9 -9 -9 -12 1 -12 7 0 9 -5 6
-10 -4 -6 -10 -8 -15 -5 -4 3 -10 -1 -13 -9 -7 -18 22 -14 34 5 6 10 9 6 10
-16 1 -25 3 -26 6 -7 3 13 -1 29 -7 37 -8 10 -8 19 -1 34 14 25 14 31 1 31 -5
0 -10 -8 -10 -18z" />
            <path d="M18700 4934 c0 -8 5 -12 10 -9 6 4 8 11 5 16 -9 14 -15 11 -15 -7z" />
            <path d="M22013 4920 c0 -23 3 -28 9 -16 5 9 5 23 0 32 -6 12 -9 7 -9 -16z" />
            <path d="M6995 4930 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z" />
            <path d="M9450 4918 c0 -9 7 -22 17 -29 15 -13 15 -12 3 11 -17 34 -20 36 -20
18z" />
            <path d="M18650 4921 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5
3 -10 2 -10 -4z" />
            <path d="M26603 4923 c4 -3 2 -12 -4 -19 -9 -10 -7 -15 5 -20 9 -3 13 -10 10
-15 -3 -5 0 -9 5 -9 14 0 14 17 1 25 -6 4 -8 15 -5 26 4 12 2 19 -6 19 -7 0
-9 -3 -6 -7z" />
            <path d="M5690 4911 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5
3 -10 2 -10 -4z" />
            <path d="M6690 4904 c0 -8 5 -12 10 -9 6 4 8 11 5 16 -9 14 -15 11 -15 -7z" />
            <path d="M21574 4905 c-4 -8 -10 -12 -15 -9 -5 3 -8 2 -7 -3 1 -4 -3 -18 -10
-31 -8 -14 -10 -28 -4 -39 8 -14 11 -13 22 8 12 22 12 23 -3 10 -26 -20 -20 0
8 33 24 28 32 46 20 46 -3 0 -8 -7 -11 -15z" />
            <path d="M12775 4900 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3
0 -8 -4 -11 -10z" />
            <path d="M28416 4893 c-3 -7 -2 -13 3 -13 5 0 14 6 20 13 8 10 7 14 -4 14 -8
0 -16 -6 -19 -14z" />
            <path d="M25695 4890 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z" />
            <path d="M18669 4873 c-13 -16 -12 -17 4 -4 16 13 21 21 13 21 -2 0 -10 -8
-17 -17z" />
            <path d="M22916 4865 c-13 -14 -13 -45 1 -45 3 0 3 7 -1 15 -3 8 -1 15 3 15 5
0 11 7 15 15 7 19 1 19 -18 0z" />
            <path d="M14174 4851 c0 -11 2 -18 5 -16 10 10 29 -18 24 -33 -7 -18 -5 -17
17 12 11 15 12 18 1 12 -10 -7 -12 -4 -7 12 4 11 2 23 -3 27 -7 3 -8 0 -5 -9
8 -21 -5 -21 -20 0 -10 13 -12 13 -12 -5z" />
            <path d="M17610 4863 c0 -4 5 -15 10 -23 8 -13 10 -13 10 2 0 9 -4 20 -10 23
-5 3 -10 3 -10 -2z" />
            <path d="M23224 4854 c1 -8 4 -15 7 -16 2 -2 3 -1 2 1 -2 2 2 9 7 16 7 8 5 12
-4 12 -8 0 -13 -6 -12 -13z" />
            <path d="M24460 4860 c0 -5 7 -10 16 -10 8 0 12 5 9 10 -3 6 -10 10 -16 10 -5
0 -9 -4 -9 -10z" />
            <path d="M27975 4850 c4 -13 2 -20 -5 -18 -7 2 -15 -3 -18 -11 -3 -9 0 -11 9
-6 8 5 3 -5 -11 -21 -22 -25 -22 -26 -2 -10 12 11 22 14 22 8 0 -6 -4 -14 -10
-17 -5 -3 -10 -11 -10 -18 0 -6 7 -2 15 9 11 14 14 15 9 3 -3 -10 -2 -19 3
-22 5 -3 9 24 10 59 1 36 -3 64 -9 64 -5 0 -6 -9 -3 -20z" />
            <path d="M10074 4838 l-19 -23 23 19 c12 11 22 21 22 23 0 8 -8 2 -26 -19z" />
            <path d="M11416 4852 c2 -2 -2 -14 -8 -26 -10 -20 -10 -20 8 -4 12 12 15 21 8
27 -6 5 -9 6 -8 3z" />
            <path d="M9006 4835 c-10 -8 -25 -26 -33 -41 -14 -28 -14 -28 7 -9 27 24 26 7
-2 -22 -12 -13 -16 -23 -10 -23 19 0 43 45 36 65 -5 12 -4 16 3 12 6 -4 14 0
17 9 9 23 4 25 -18 9z" />
            <path d="M11770 4842 c0 -5 7 -9 15 -9 8 0 12 4 9 9 -3 4 -9 8 -15 8 -5 0 -9
-4 -9 -8z" />
            <path d="M29390 4839 c0 -5 -4 -7 -10 -4 -14 9 -12 -11 3 -23 9 -8 12 -4 13
14 1 13 0 24 -2 24 -2 0 -4 -5 -4 -11z" />
            <path d="M15561 4824 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
            <path d="M24466 4823 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z" />
            <path d="M29035 4831 c-6 -11 9 -23 19 -14 9 9 7 23 -3 23 -6 0 -12 -4 -16 -9z" />
            <path d="M29096 4823 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z" />
            <path d="M31415 4830 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3
0 -8 -4 -11 -10z" />
            <path d="M5700 4830 c-1 -3 -3 -18 -5 -35 -3 -24 -2 -27 6 -12 6 10 8 25 6 35
-3 9 -6 15 -7 12z" />
            <path d="M7730 4816 c0 -8 5 -18 10 -21 6 -3 10 1 10 9 0 8 -4 18 -10 21 -5 3
-10 -1 -10 -9z" />
            <path d="M10414 4806 c6 -35 22 -61 30 -48 3 5 -1 15 -9 22 -7 6 -12 17 -9 25
3 7 0 16 -6 20 -7 4 -9 -2 -6 -19z" />
            <path d="M14055 4820 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z" />
            <path d="M14175 4811 c-3 -5 -1 -12 5 -16 5 -3 10 1 10 9 0 18 -6 21 -15 7z" />
            <path d="M29015 4801 c-3 -5 -1 -12 5 -16 5 -3 10 1 10 9 0 18 -6 21 -15 7z" />
            <path d="M10033 4778 c-33 -37 -33 -44 -2 -46 28 -2 39 11 19 23 -7 4 -7 13 0
26 14 25 8 24 -17 -3z m12 -28 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10
4 10 3 0 8 -4 11 -10z" />
            <path d="M10445 4790 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z" />
            <path d="M31397 4785 c-10 -7 -14 -16 -9 -19 5 -3 15 1 22 9 17 21 9 27 -13
10z" />
            <path d="M14061 4774 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
            <path d="M20114 4772 c19 -22 32 -30 22 -13 -4 5 -1 11 6 13 9 3 9 6 0 12 -7
4 -12 2 -12 -5 0 -8 -5 -8 -17 2 -15 11 -14 10 1 -9z" />
            <path d="M20684 4775 c-4 -8 -11 -12 -17 -8 -5 3 -7 1 -4 -4 4 -6 0 -16 -8
-23 -8 -7 -15 -18 -15 -24 0 -6 5 -3 11 7 6 9 15 15 20 11 5 -3 8 -2 7 3 -1 4
3 18 10 31 7 12 10 22 7 22 -3 0 -8 -7 -11 -15z" />
            <path d="M25632 4762 c-13 -13 -20 -27 -17 -30 4 -4 15 4 25 18 12 15 20 19
20 11 0 -8 3 -12 6 -8 3 3 2 12 -3 19 -6 10 -13 8 -31 -10z" />
            <path d="M29370 4779 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0
-10 -5 -10 -11z" />
            <path d="M30281 4771 c-8 -14 -7 -20 4 -25 8 -3 15 -10 15 -16 0 -8 -7 -7 -22
0 -20 10 -21 9 -8 -2 8 -8 20 -20 26 -27 9 -10 12 -6 12 16 0 15 -5 34 -10 41
-7 8 -7 12 1 12 6 0 11 5 11 10 0 17 -19 11 -29 -9z" />
            <path d="M14030 4771 c0 -6 5 -13 10 -16 17 -10 11 -25 -7 -18 -14 5 -13 1 4
-16 13 -12 26 -33 29 -47 5 -19 9 -22 15 -12 6 9 9 9 9 1 0 -7 7 -10 18 -7 14
5 15 4 2 -5 -12 -8 -12 -13 -2 -23 9 -9 12 -9 13 5 2 25 4 31 11 42 4 6 3 17
-1 24 -6 9 -10 7 -14 -8 -6 -25 -20 -27 -41 -5 -18 17 -22 39 -6 29 6 -3 1 10
-11 29 -21 37 -29 44 -29 27z" />
            <path d="M14675 4763 c-5 -10 -7 -22 -6 -27 1 -5 -10 -21 -25 -37 -14 -15 -23
-32 -20 -38 10 -15 23 -14 29 3 4 11 1 13 -11 10 -12 -4 -10 0 5 13 12 10 28
33 34 51 12 35 7 55 -6 25z" />
            <path d="M28335 4770 c-3 -5 -1 -11 4 -15 6 -3 8 -12 5 -19 -3 -7 1 -18 9 -24
11 -9 16 -6 26 15 8 18 9 29 2 36 -14 14 -40 18 -46 7z m39 -15 c3 -8 1 -15
-4 -15 -6 0 -10 7 -10 15 0 8 2 15 4 15 2 0 6 -7 10 -15z" />
            <path d="M11871 4756 l-24 -13 26 0 c15 0 24 3 22 7 -3 5 1 11 7 13 7 3 9 6 3
6 -5 0 -21 -6 -34 -13z" />
            <path d="M12053 4755 c0 -8 4 -15 9 -15 4 0 8 7 8 15 0 8 -4 15 -8 15 -5 0 -9
-7 -9 -15z" />
            <path d="M12702 4750 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z" />
            <path d="M16226 4751 c-3 -9 -9 -22 -12 -30 -4 -8 -2 -12 6 -9 6 2 11 8 11 13
0 6 7 17 15 26 12 14 12 16 0 16 -8 0 -17 -7 -20 -16z" />
            <path d="M20099 4748 c0 -7 -2 -21 -4 -31 -2 -11 0 -16 3 -12 4 4 7 12 7 18 1
7 7 11 15 9 11 -3 11 0 0 12 -15 19 -20 20 -21 4z" />
            <path d="M21957 4753 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z" />
            <path d="M23954 4723 c-16 -21 -28 -44 -26 -51 2 -7 -1 -11 -5 -10 -4 2 -9 0
-10 -4 -5 -14 7 -9 33 11 15 12 24 27 21 35 -3 7 1 23 9 35 26 36 8 23 -22
-16z m1 -32 c-3 -6 -11 -11 -16 -11 -5 0 -4 6 3 14 14 16 24 13 13 -3z" />
            <path d="M25084 4718 c14 -56 25 -71 21 -29 -1 17 -9 40 -17 53 -15 21 -15 20
-4 -24z" />
            <path d="M27926 4747 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 -10 9 -11 8 -5 -6z" />
            <path d="M18451 4731 c-8 -15 -8 -22 1 -27 7 -4 10 -4 6 1 -4 4 -3 14 4 22 6
7 8 16 5 19 -3 3 -10 -3 -16 -15z" />
            <path d="M26977 4743 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z" />
            <path d="M17052 4712 c-11 -25 -11 -25 4 -10 9 9 15 21 13 27 -2 6 -10 -2 -17
-17z" />
            <path d="M19086 4723 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z" />
            <path d="M21931 4724 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
            <path d="M25632 4713 c-23 -28 -22 -45 1 -22 12 13 30 49 24 49 -2 0 -13 -12
-25 -27z" />
            <path d="M12040 4705 c-11 -21 -11 -27 0 -38 10 -9 11 -9 6 0 -4 7 -2 22 4 34
7 11 10 23 7 25 -2 2 -10 -7 -17 -21z" />
            <path d="M14010 4704 c0 -8 4 -13 9 -10 14 9 22 -24 11 -42 -9 -16 -9 -16 -10
0 0 9 -5 20 -12 24 -7 4 -8 3 -4 -4 4 -6 -1 -23 -10 -37 -11 -17 -12 -25 -4
-25 7 0 4 -11 -8 -27 -11 -16 -25 -46 -31 -68 l-11 -40 26 30 c14 16 23 37 21
46 -3 9 -1 23 4 30 7 11 9 9 10 -6 1 -11 1 -27 0 -35 -1 -12 1 -12 12 2 16 20
57 21 50 0 -5 -11 -7 -11 -14 0 -7 11 -11 10 -19 -2 -5 -8 -9 -24 -8 -35 1
-16 1 -16 5 3 3 15 10 22 19 18 9 -3 21 9 34 36 22 46 25 68 9 68 -5 0 -8 4
-4 9 3 6 -6 8 -20 5 -14 -2 -25 0 -25 6 0 5 4 10 10 10 14 0 -9 48 -26 54 -8
3 -14 -1 -14 -10z m-38 -176 c-8 -8 -9 -4 -5 13 4 13 8 18 11 10 2 -7 -1 -18
-6 -23z" />
            <path d="M12691 4684 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
            <path d="M16194 4685 c-4 -8 -11 -12 -18 -8 -6 3 -8 2 -3 -2 4 -5 17 -10 29
-12 16 -3 18 -1 9 10 -6 8 -8 17 -4 20 3 4 3 7 -1 7 -3 0 -9 -7 -12 -15z" />
            <path d="M20611 4679 c-13 -12 -17 -26 -14 -40 4 -14 2 -20 -4 -16 -12 8 -49
-30 -40 -40 4 -3 0 -17 -9 -31 -9 -13 -11 -21 -5 -18 6 4 11 3 11 -2 0 -17
-13 -22 -28 -9 -13 9 -14 9 -8 0 4 -7 2 -13 -3 -13 -6 0 -11 -7 -11 -15 0 -8
-3 -15 -8 -15 -4 0 -13 -9 -20 -21 -9 -14 -9 -19 -2 -14 8 5 11 1 9 -11 -2
-11 0 -24 4 -31 5 -7 -1 -21 -13 -33 -11 -11 -20 -16 -20 -11 0 6 4 13 10 16
5 3 9 17 8 30 -2 26 -12 32 -23 15 -3 -5 -1 -10 4 -10 6 0 11 -5 11 -11 0 -5
-5 -7 -12 -3 -6 4 -8 3 -5 -4 10 -15 -3 -42 -17 -36 -7 3 -13 -2 -13 -10 0
-16 18 -17 29 -1 5 6 8 5 8 -3 0 -7 11 -1 25 14 14 15 25 30 25 34 0 4 12 8
26 8 18 0 22 -4 15 -11 -13 -13 -14 -27 -2 -27 10 0 26 57 22 78 -1 6 3 12 8
12 6 0 4 15 -4 34 -8 19 -11 37 -6 40 5 3 11 -4 14 -15 7 -28 24 -16 29 20 2
17 1 28 -4 25 -4 -3 -8 -17 -9 -32 l0 -27 -10 24 c-6 16 -6 28 0 34 12 12 2
39 -11 31 -6 -4 -9 -1 -6 6 7 22 26 22 33 1 3 -12 10 -21 15 -21 13 0 21 33
10 44 -16 16 -12 30 4 16 13 -10 14 -7 10 15 -3 15 -1 24 5 21 7 -5 21 14 21
28 0 10 -33 -1 -49 -15z m19 -4 c-6 -8 -9 -19 -5 -24 4 -7 0 -8 -10 -5 -14 6
-14 8 1 25 20 22 32 26 14 4z m-10 -76 c0 -5 -4 -9 -10 -9 -5 0 -10 7 -10 16
0 8 5 12 10 9 6 -3 10 -10 10 -16z m-40 -33 c0 -2 -8 -10 -17 -17 -16 -13 -17
-12 -4 4 13 16 21 21 21 13z m-33 -69 c-2 -6 -10 -11 -19 -13 -10 -1 -15 3
-12 10 6 15 36 17 31 3z" />
            <path d="M18440 4678 c0 -9 -6 -18 -12 -21 -9 -3 -7 -9 7 -20 19 -15 19 -16 1
-35 -10 -12 -15 -26 -10 -33 5 -8 2 -10 -8 -6 -20 7 -30 -36 -17 -70 6 -17 5
-21 -6 -17 -8 3 -11 13 -8 22 4 13 3 14 -6 6 -13 -12 -4 -47 10 -38 5 3 9 -1
9 -8 0 -8 5 -6 11 5 7 12 17 16 31 11 14 -4 18 -2 14 5 -4 6 8 25 26 41 18 17
28 30 23 30 -6 0 -4 4 4 9 12 8 12 12 2 25 -14 17 -31 13 -31 -7 0 -8 3 -7 9
2 7 10 9 7 8 -12 -3 -35 -24 -16 -33 29 -3 19 -10 37 -15 40 -5 3 -9 10 -9 16
0 6 7 5 17 -3 15 -13 15 -12 3 11 -17 34 -20 36 -20 18z m15 -99 c11 -17 -1
-21 -15 -4 -8 9 -8 15 -2 15 6 0 14 -5 17 -11z" />
            <path d="M9375 4669 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z" />
            <path d="M13137 4673 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z" />
            <path d="M19050 4670 c0 -19 -3 -25 -18 -36 -8 -6 -12 -15 -9 -21 3 -5 -5 -22
-19 -39 -14 -16 -28 -41 -31 -56 -3 -16 -10 -25 -16 -21 -6 3 -7 1 -3 -6 4 -6
11 -9 16 -6 5 3 11 1 15 -5 3 -5 -3 -10 -13 -10 -29 0 -46 -24 -33 -49 14 -26
14 -28 -4 -28 -9 0 -14 6 -11 13 3 8 0 16 -6 20 -7 4 -8 3 -4 -4 4 -7 3 -12
-2 -12 -6 0 -8 -9 -5 -20 6 -22 -14 -53 -28 -44 -5 3 -9 2 -8 -3 3 -19 -3 -33
-27 -62 -14 -17 -21 -31 -15 -31 6 0 11 -7 11 -16 0 -10 -6 -14 -15 -11 -8 4
-17 1 -21 -5 -4 -7 -3 -8 4 -4 7 4 12 0 12 -10 0 -16 1 -16 18 -1 20 18 25 47
9 47 -15 0 1 14 18 16 15 2 15 -10 0 -62 -4 -12 -2 -25 3 -28 6 -4 8 2 5 14
-9 34 6 22 16 -12 8 -30 9 -31 11 -8 1 23 2 24 11 5 8 -17 8 -15 4 8 -3 15 -1
27 4 27 13 0 31 57 25 75 -11 27 -24 16 -18 -14 5 -21 1 -37 -14 -55 -19 -24
-20 -25 -25 -6 -3 11 -1 18 3 15 15 -9 24 19 25 80 1 70 13 101 27 66 7 -20
11 -21 24 -10 19 16 41 54 25 45 -12 -8 -15 7 -3 18 4 4 7 18 7 29 0 12 6 21
13 20 6 -2 11 0 10 5 -1 4 4 21 11 37 7 17 14 35 15 40 1 6 5 16 10 23 4 7 4
12 -1 11 -21 -4 -20 3 1 26 26 27 40 65 25 65 -5 0 -9 -9 -9 -21 0 -11 -4 -18
-10 -14 -5 3 -7 12 -3 20 3 8 2 15 -1 15 -3 0 -6 -4 -6 -10z m5 -40 c-3 -5
-12 -10 -18 -10 -7 0 -6 4 3 10 19 12 23 12 15 0z m-36 -112 c-11 -21 -22 -38
-25 -38 -3 0 -2 5 1 10 3 6 2 10 -3 10 -6 0 -8 9 -5 20 3 11 11 20 19 20 7 0
16 4 19 10 4 6 -1 7 -10 3 -15 -5 -15 -3 -4 16 23 36 30 -8 8 -51z m-29 -78
c0 -5 -10 -6 -21 -3 -20 6 -21 5 -6 -18 16 -24 7 -63 -10 -45 -4 5 -2 11 4 13
9 3 9 10 -3 28 -9 13 -13 28 -10 34 7 10 46 3 46 -9z m-87 -154 l-6 -49 -13
29 c-7 16 -13 23 -13 15 -1 -8 -3 -12 -6 -9 -10 10 7 38 24 39 12 0 12 2 2 6
-11 4 -11 7 0 14 18 11 18 11 12 -45z" />
            <path d="M12010 4661 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5
3 -10 2 -10 -4z" />
            <path d="M31894 4644 c-9 -14 -22 -23 -28 -21 -15 6 -18 -30 -4 -39 6 -4 8 -2
4 4 -3 6 2 17 11 24 18 13 47 58 38 58 -2 0 -12 -11 -21 -26z" />
            <path d="M16155 4649 c-4 -6 -4 -13 0 -17 3 -3 10 0 15 8 6 8 10 11 10 5 0
-13 -16 -37 -24 -36 -3 0 -12 -2 -21 -5 -13 -5 -13 -8 0 -20 18 -19 8 -46 -15
-39 -18 6 -40 -14 -44 -40 -1 -9 -7 -20 -12 -23 -5 -4 2 -18 16 -32 14 -14 22
-21 18 -15 -5 5 -8 16 -8 24 0 10 6 7 19 -8 11 -13 17 -30 14 -39 -4 -14 -3
-14 5 -3 6 8 8 21 4 29 -4 11 -2 13 8 7 10 -6 12 -4 7 9 -5 11 1 32 14 52 11
18 18 38 14 44 -3 5 -1 10 5 10 8 0 7 6 -1 22 -10 18 -9 20 5 15 9 -4 16 -2
16 2 0 5 -5 11 -12 13 -7 3 -8 10 -4 17 7 11 10 11 18 0 7 -11 8 -9 6 5 -3 21
-43 32 -53 15z m0 -49 c-3 -5 -2 -10 4 -10 5 0 9 -13 9 -30 -1 -16 -4 -30 -8
-30 -5 0 -11 -12 -15 -27 -5 -22 -8 -24 -16 -12 -8 10 -9 6 -4 -15 5 -26 4
-27 -9 -13 -10 9 -13 23 -9 33 4 12 3 15 -5 10 -17 -10 -15 9 3 24 12 10 15
10 15 -4 0 -9 4 -16 8 -16 13 0 30 57 21 71 -7 12 -3 29 7 29 3 0 2 -4 -1 -10z
m-65 -120 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4
-10z" />
            <path d="M25615 4650 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3
0 -8 -4 -11 -10z" />
            <path d="M14596 4623 c-11 -14 -14 -23 -8 -19 17 10 15 -9 -2 -24 -8 -6 -12
-18 -9 -26 4 -12 -6 -18 -28 -16 -3 1 -5 -9 -5 -21 1 -13 -4 -21 -12 -19 -8 1
-11 -2 -8 -6 3 -5 -3 -17 -14 -27 -22 -20 -26 -50 -8 -61 7 -4 8 -3 4 4 -5 8
-2 10 8 6 9 -3 13 -11 9 -17 -3 -6 -1 -7 6 -3 6 4 9 11 6 16 -7 12 6 41 16 34
5 -3 6 3 2 13 -3 10 -3 15 2 11 4 -4 16 3 26 16 23 29 25 43 4 26 -8 -7 -15
-8 -15 -2 0 15 26 33 35 24 5 -4 5 -1 1 6 -5 8 -1 21 9 32 14 15 14 22 5 41
-11 19 -10 21 5 15 15 -5 16 -4 5 9 -15 18 -11 20 -34 -12z m14 -48 c-7 -8
-16 -15 -21 -15 -5 0 -1 9 8 20 9 11 19 17 21 15 3 -3 -1 -11 -8 -20z m-70
-117 c-16 -18 -32 -29 -36 -26 -8 8 47 69 57 63 4 -3 -5 -20 -21 -37z" />
            <path d="M19895 4640 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3
0 -8 -4 -11 -10z" />
            <path d="M29323 4624 c-4 -12 -8 -12 -13 -4 -5 9 -10 6 -15 -9 -4 -13 -4 -25
-1 -28 3 -4 6 1 6 10 0 10 6 14 14 11 8 -3 16 3 19 15 6 25 -1 28 -10 5z" />
            <path d="M11326 4621 c-4 -6 -2 -11 3 -11 6 0 11 -4 11 -10 0 -5 -7 -10 -17
-10 -9 0 -14 -3 -10 -6 3 -4 -1 -20 -9 -36 -11 -21 -12 -28 -3 -22 9 5 10 2 6
-10 -5 -14 -3 -17 8 -12 8 3 15 1 15 -5 0 -6 5 -7 10 -4 6 4 2 13 -10 23 -18
16 -19 19 -5 42 26 45 28 51 17 62 -6 6 -12 6 -16 -1z" />
            <path d="M12010 4620 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5
0 -10 -4 -10 -10z" />
            <path d="M22910 4620 c0 -5 5 -10 12 -10 7 0 6 -6 -4 -17 -41 -48 -27 -64 22
-28 23 17 28 25 17 25 -15 0 -22 11 -18 33 0 4 -6 7 -14 7 -8 0 -15 -4 -15
-10z m30 -40 c0 -5 -9 -14 -20 -20 -19 -10 -20 -10 -8 9 13 22 28 28 28 11z" />
            <path d="M22995 4610 c-10 -11 -13 -20 -8 -20 13 0 38 29 31 35 -3 3 -13 -4
-23 -15z" />
            <path d="M30218 4613 c-2 -10 -1 -29 3 -43 5 -20 6 -16 7 18 0 45 -3 52 -10
25z" />
            <path d="M17012 4615 c4 -4 3 -14 -3 -21 -7 -9 -7 -19 0 -33 15 -27 15 -28
-16 -46 -16 -9 -20 -14 -11 -11 10 3 20 1 23 -4 12 -19 20 -9 20 24 0 19 -3
38 -7 41 -4 4 -3 16 2 26 7 12 6 20 -2 25 -7 4 -10 4 -6 -1z" />
            <path d="M24999 4578 c-11 -21 -11 -22 5 -9 14 12 16 12 10 -3 -3 -9 -2 -16 3
-16 12 0 14 38 3 45 -5 3 -14 -5 -21 -17z" />
            <path d="M28305 4589 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z" />
            <path d="M5617 4576 c-3 -8 -2 -18 3 -21 13 -8 20 9 11 24 -6 10 -10 9 -14 -3z" />
            <path d="M10362 4565 c-26 -30 -26 -31 -8 -38 10 -4 13 -1 9 9 -3 7 1 14 8 14
7 0 9 3 6 7 -4 3 -2 12 4 20 21 24 3 14 -19 -12z" />
            <path d="M26520 4580 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5
0 -10 -4 -10 -10z" />
            <path d="M11337 4563 c-10 -10 -8 -33 2 -33 6 0 9 9 8 20 -1 23 -1 22 -10 13z" />
            <path d="M6590 4546 c0 -2 7 -6 15 -10 8 -3 15 -1 15 4 0 6 -7 10 -15 10 -8 0
-15 -2 -15 -4z" />
            <path d="M12671 4535 c0 -12 4 -26 10 -32 14 -14 4 -47 -11 -38 -5 3 -10 1
-10 -5 0 -7 7 -9 16 -6 9 3 18 6 20 6 8 0 4 36 -5 46 -5 5 -11 18 -14 29 -5
20 -5 20 -6 0z" />
            <path d="M16985 4540 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3
0 -8 -4 -11 -10z" />
            <path d="M21415 4540 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3
0 -8 -4 -11 -10z" />
            <path d="M25550 4537 c0 -10 -4 -16 -8 -13 -11 6 -10 -90 1 -100 4 -5 5 -1 2
8 -4 10 3 29 15 46 21 28 21 32 6 53 -13 20 -16 21 -16 6z m20 -27 c0 -5 -4
-10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-10 -24 c0
-3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z" />
            <path d="M31820 4534 c0 -8 5 -12 10 -9 6 4 8 11 5 16 -9 14 -15 11 -15 -7z" />
            <path d="M6550 4530 c8 -5 22 -10 30 -10 13 0 13 1 0 10 -8 5 -22 10 -30 10
-13 0 -13 -1 0 -10z" />
            <path d="M7960 4529 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z" />
            <path d="M15535 4530 c3 -5 11 -10 16 -10 6 0 7 5 4 10 -3 6 -11 10 -16 10 -6
0 -7 -4 -4 -10z" />
            <path d="M28260 4531 c0 -6 4 -13 10 -16 6 -3 7 1 4 9 -7 18 -14 21 -14 7z" />
            <path d="M30204 4525 c-4 -8 -11 -12 -17 -8 -5 3 -7 1 -3 -5 3 -6 19 -8 33 -6
36 7 42 14 12 14 -19 0 -21 2 -9 10 10 7 11 10 2 10 -7 0 -15 -7 -18 -15z" />
            <path d="M28751 4514 c-20 -23 -9 -25 18 -3 13 11 17 19 10 19 -8 -1 -20 -8
-28 -16z" />
            <path d="M13978 4495 c-13 -17 -16 -29 -9 -47 7 -17 10 -19 10 -5 1 21 10 22
26 0 10 -13 11 -11 7 16 -3 23 -7 29 -13 20 -7 -11 -9 -10 -9 4 0 9 5 17 10
17 6 0 10 5 10 10 0 17 -14 11 -32 -15z" />
            <path d="M15410 4510 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z" />
            <path d="M7951 4493 c12 -14 12 -16 -2 -11 -12 5 -15 -2 -15 -32 1 -21 4 -41
9 -44 4 -3 5 -3 2 0 -2 4 2 22 10 41 14 34 11 63 -7 63 -6 0 -4 -8 3 -17z" />
            <path d="M8859 4508 c0 -2 -3 -14 -5 -28 -2 -13 -9 -27 -15 -31 -8 -5 -8 -8 0
-13 6 -4 11 -12 11 -17 0 -6 -5 -7 -11 -3 -6 3 -15 0 -20 -7 -12 -20 -11 -31
2 -23 7 4 10 1 7 -7 -3 -8 -11 -13 -17 -11 -7 1 -9 -3 -5 -9 3 -6 0 -15 -7
-20 -12 -7 -11 -9 1 -9 21 0 42 37 35 60 -5 15 -2 18 14 15 23 -4 26 2 12 25
-6 8 -7 19 -3 25 9 13 14 55 7 55 -3 0 -5 -1 -6 -2z" />
            <path d="M15545 4502 c3 -3 0 -11 -6 -18 -7 -9 -6 -14 3 -20 7 -4 10 -3 5 1
-4 5 0 16 8 25 13 15 13 17 0 17 -8 0 -13 -3 -10 -5z" />
            <path d="M23770 4466 c-7 -14 -17 -23 -21 -20 -4 2 -11 -1 -15 -8 -4 -7 -4
-10 1 -6 4 4 14 3 22 -3 10 -9 13 -8 13 1 0 7 8 19 18 26 12 9 14 16 6 24 -7
7 -14 3 -24 -14z" />
            <path d="M14021 4464 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
            <path d="M27810 4465 c-10 -12 -10 -15 0 -15 9 0 9 -4 -1 -17 -12 -14 -12 -16
1 -11 8 2 15 17 15 31 1 32 1 31 -15 12z" />
            <path d="M9218 4452 c-4 -12 0 -26 8 -33 11 -9 14 -9 14 0 0 7 4 10 9 6 6 -3
15 5 21 17 8 19 8 20 -2 7 -10 -15 -13 -15 -28 4 l-17 20 -5 -21z m22 -12 c0
-5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z" />
            <path d="M13932 4448 c-6 -15 -9 -37 -7 -49 3 -13 -1 -26 -7 -30 -6 -4 -12
-29 -13 -56 0 -26 -3 -52 -5 -58 -3 -5 0 -11 5 -12 6 -1 9 5 7 13 -2 8 3 13
10 12 7 -2 14 7 16 20 2 12 8 22 14 22 7 0 6 5 -2 14 -6 8 -18 12 -26 9 -20
-7 -17 4 6 29 15 17 17 24 8 30 -10 6 -9 8 3 8 26 0 30 -12 12 -32 -15 -16
-15 -21 -3 -28 17 -11 31 6 22 28 -4 11 -2 13 9 7 11 -7 11 -5 -1 9 -8 11 -20
35 -26 55 l-11 36 -11 -27z m18 -28 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0
6 5 10 10 10 6 0 10 -4 10 -10z m-18 -125 c-2 -14 -6 -25 -8 -25 -2 0 -4 11
-4 25 0 14 4 25 9 25 4 0 6 -11 3 -25z" />
            <path d="M18428 4460 c6 -8 12 -17 12 -20 0 -13 -56 -4 -68 10 -8 8 -11 9 -7
3 3 -7 2 -21 -3 -30 -6 -12 -4 -27 5 -43 14 -23 14 -24 10 -2 -6 25 8 30 17 7
3 -8 11 -15 17 -15 8 0 6 7 -4 18 -10 10 -17 24 -17 31 0 7 9 3 20 -10 10 -13
19 -18 20 -11 0 6 -4 12 -10 12 -5 0 -10 5 -10 11 0 6 7 9 15 5 9 -3 19 3 26
17 7 12 8 19 4 15 -5 -4 -15 -2 -24 4 -13 10 -14 10 -3 -2z m-57 -53 c-10 -9
-11 -8 -5 6 3 10 9 15 12 12 3 -3 0 -11 -7 -18z" />
            <path d="M24281 4457 c-1 -21 21 -18 27 4 3 12 2 12 -6 0 -8 -11 -10 -11 -15
0 -4 10 -6 8 -6 -4z" />
            <path d="M29215 4460 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z" />
            <path d="M13067 4430 c3 -23 1 -30 -4 -22 -7 9 -10 3 -10 -18 0 -25 2 -28 10
-15 14 26 27 76 17 70 -4 -3 -11 1 -13 7 -3 7 -3 -3 0 -22z" />
            <path d="M16026 4435 c-3 -8 -1 -15 4 -15 13 0 22 18 11 24 -5 3 -11 -1 -15
-9z" />
            <path d="M23795 4440 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3
0 -8 -4 -11 -10z" />
            <path d="M24250 4425 c0 -14 2 -25 4 -25 2 0 6 11 8 25 3 14 1 25 -3 25 -5 0
-9 -11 -9 -25z" />
            <path d="M11936 4424 c-3 -9 -4 -19 -1 -22 3 -3 5 1 5 8 0 9 5 7 14 -6 8 -10
18 -16 22 -11 4 4 2 7 -4 7 -7 0 -12 5 -12 11 0 5 4 8 9 5 5 -3 11 1 15 9 6
17 -9 20 -20 4 -5 -8 -9 -8 -14 1 -5 8 -9 7 -14 -6z" />
            <path d="M29261 4423 c-9 -16 -12 -16 -21 -3 -15 23 -12 -4 5 -36 10 -20 15
-23 15 -11 0 26 1 30 11 50 5 9 8 17 5 17 -3 0 -10 -8 -15 -17z" />
            <path d="M14555 4420 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3
0 -8 -4 -11 -10z" />
            <path d="M13980 4411 c0 -6 4 -13 10 -16 6 -3 7 1 4 9 -7 18 -14 21 -14 7z" />
            <path d="M11251 4394 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
            <path d="M16933 4391 c-15 -24 -17 -43 -3 -21 7 11 10 11 10 2 0 -7 -9 -22
-20 -34 -11 -12 -20 -27 -20 -32 0 -6 5 -4 11 4 8 12 9 11 6 -5 -2 -11 -8 -19
-14 -18 -6 1 -9 -6 -5 -19 5 -19 6 -19 17 -5 7 9 15 14 18 10 4 -3 2 8 -2 25
-7 22 -5 34 6 45 8 8 13 26 11 41 -3 26 -3 26 -15 7z" />
            <path d="M28174 4394 c2 -10 8 -20 14 -22 17 -5 15 13 -2 27 -12 10 -14 9 -12
-5z" />
            <path d="M14540 4385 c-10 -12 -16 -13 -26 -4 -19 16 -18 1 1 -18 9 -9 13 -24
10 -37 -5 -18 -5 -19 5 -3 6 9 8 22 5 28 -4 6 1 16 11 24 11 7 16 16 13 19 -3
4 -12 0 -19 -9z" />
            <path d="M24931 4386 c-9 -10 -8 -15 2 -19 8 -3 13 3 13 14 0 23 0 23 -15 5z" />
            <path d="M11930 4380 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5
0 -10 -4 -10 -10z" />
            <path d="M18336 4374 c-4 -9 -4 -19 -1 -22 3 -3 5 1 5 8 0 9 5 7 15 -6 13 -18
14 -18 8 4 -7 29 -19 36 -27 16z" />
            <path d="M18886 4373 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z" />
            <path d="M19830 4381 c0 -6 4 -13 10 -16 6 -3 7 1 4 9 -7 18 -14 21 -14 7z" />
            <path d="M31700 4368 c0 -12 3 -18 7 -14 11 10 12 36 2 36 -5 0 -9 -10 -9 -22z" />
            <path d="M7415 4369 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z" />
            <path d="M31085 4360 c3 -5 11 -10 16 -10 6 0 7 5 4 10 -3 6 -11 10 -16 10 -6
0 -7 -4 -4 -10z" />
            <path d="M14499 4347 c-3 -35 -4 -39 -22 -52 -10 -7 -14 -16 -8 -19 5 -4 12
-2 16 4 4 6 10 8 15 5 5 -3 12 0 16 6 5 8 3 9 -5 5 -15 -10 -11 25 4 36 6 5 5
12 -3 20 -9 9 -12 9 -13 -5z" />
            <path d="M15979 4358 c-4 -10 -5 -107 0 -103 5 6 9 105 4 105 -2 0 -4 -1 -4
-2z" />
            <path d="M18390 4349 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0
-10 -5 -10 -11z" />
            <path d="M19905 4349 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z" />
            <path d="M31970 4355 c0 -5 5 -17 10 -25 5 -8 10 -10 10 -5 0 6 -5 17 -10 25
-5 8 -10 11 -10 5z" />
            <path d="M18930 4345 c1 -6 7 -21 14 -34 l13 -24 11 33 c10 26 9 30 -1 21 -10
-8 -17 -7 -25 1 -7 7 -12 8 -12 3z" />
            <path d="M20474 4338 c-4 -7 -3 -8 4 -4 7 4 12 1 12 -9 0 -9 4 -14 10 -10 10
6 2 35 -10 35 -5 0 -12 -5 -16 -12z" />
            <path d="M11232 4336 c4 -5 1 -24 -7 -42 -8 -19 -10 -34 -6 -34 5 0 11 9 14
21 3 12 10 18 15 15 5 -3 12 4 15 15 3 12 3 18 0 15 -4 -3 -13 0 -22 6 -9 7
-13 9 -9 4z" />
            <path d="M28548 4333 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z" />
            <path d="M15333 4323 c4 -3 2 -12 -4 -20 -7 -8 -8 -13 -1 -13 5 0 8 -9 5 -21
-4 -16 -8 -17 -14 -7 -7 10 -9 10 -9 1 0 -23 -2 -28 -13 -43 -6 -8 -2 -6 11 4
l22 19 0 -29 c0 -16 -4 -24 -8 -17 -5 8 -12 8 -26 -3 -11 -8 -16 -14 -12 -14
5 0 3 -7 -4 -15 -6 -8 -9 -19 -5 -25 4 -6 -1 -21 -12 -32 -16 -19 -16 -21 -1
-15 10 3 20 1 24 -5 4 -6 3 -8 -4 -4 -6 3 -14 2 -17 -4 -4 -6 -1 -9 7 -8 14 3
15 -11 4 -52 -6 -21 -3 -26 13 -27 12 -1 21 3 21 8 0 6 -5 7 -11 4 -19 -12 -4
77 18 99 11 12 25 31 32 44 11 20 15 21 29 10 9 -7 14 -8 9 -3 -4 4 1 18 10
29 18 21 18 21 -4 3 -14 -13 -23 -15 -28 -7 -8 13 -35 -6 -35 -25 0 -15 -27
-28 -41 -19 -17 10 -9 22 11 17 12 -3 20 0 20 7 0 7 -7 10 -14 7 -10 -4 -13
-1 -9 9 6 17 23 19 23 2 1 -7 7 -2 14 12 8 14 11 31 6 40 -4 8 -4 24 0 35 4
11 5 27 3 35 -4 12 -3 12 5 2 5 -8 8 -24 6 -38 -1 -13 2 -26 6 -29 5 -3 7 -12
4 -19 -3 -8 -1 -17 5 -21 6 -3 14 1 17 10 4 9 12 14 18 12 7 -3 13 4 13 15 1
11 10 26 20 34 16 12 16 13 1 14 -9 0 -19 -6 -21 -12 -4 -9 -18 0 -41 25 -20
20 -39 37 -43 37 -3 0 -3 -3 0 -7z m47 -47 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1
-10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m22 -28 c3 -7 -3 -21 -13 -30 -16 -16
-17 -15 -14 10 2 15 4 28 4 30 3 7 20 0 23 -10z" />
            <path d="M18350 4320 c17 -11 47 -14 36 -3 -3 4 -17 8 -29 10 -17 2 -19 1 -7
-7z" />
            <path d="M13887 4306 c-4 -10 -5 -21 -2 -24 9 -9 17 6 13 25 -3 17 -4 17 -11
-1z" />
            <path d="M18317 4306 c-4 -10 0 -13 13 -11 11 2 24 -3 28 -12 8 -14 10 -14 15
1 4 9 3 15 -1 14 -4 -1 -17 3 -29 10 -17 9 -22 9 -26 -2z" />
            <path d="M28180 4310 c-21 -13 -7 -22 42 -26 40 -3 42 -3 18 9 -14 7 -26 9
-28 5 -7 -15 -24 -8 -18 7 6 17 6 18 -14 5z" />
            <path d="M25458 4298 c6 -6 20 -13 29 -14 16 -2 16 -1 -1 12 -24 17 -45 19
-28 2z" />
            <path d="M26815 4300 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3
0 -8 -4 -11 -10z" />
            <path d="M7899 4298 c-7 -24 -7 -48 0 -44 12 8 21 46 10 46 -5 0 -9 -1 -10 -2z" />
            <path d="M24886 4284 c-3 -8 -2 -13 3 -9 5 3 12 -1 14 -7 4 -8 6 -7 6 4 1 23
-15 32 -23 12z" />
            <path d="M28278 4293 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
            <path d="M31621 4291 c-10 -6 -10 -10 -1 -16 13 -8 24 -1 19 14 -3 6 -10 7
-18 2z" />
            <path d="M26834 4277 c-15 -18 -25 -47 -14 -47 5 0 15 14 24 30 15 30 10 39
-10 17z" />
            <path d="M18320 4270 c-19 -4 -21 -7 -9 -12 9 -3 22 -2 30 2 19 13 8 18 -21
10z" />
            <path d="M28265 4270 c-8 -13 5 -13 25 0 13 8 13 10 -2 10 -9 0 -20 -4 -23
-10z" />
            <path d="M7286 4262 c-3 -5 1 -9 9 -9 8 0 12 4 9 9 -3 4 -7 8 -9 8 -2 0 -6 -4
-9 -8z" />
            <path d="M26855 4259 c-11 -17 1 -21 15 -4 8 9 8 15 2 15 -6 0 -14 -5 -17 -11z" />
            <path d="M5536 4245 c-3 -9 -6 -24 -5 -33 0 -9 5 -4 10 12 9 32 6 48 -5 21z" />
            <path d="M17373 4235 c0 -8 4 -15 9 -15 4 0 8 4 8 9 0 6 -4 12 -8 15 -5 3 -9
-1 -9 -9z" />
            <path d="M18311 4228 c5 -10 15 -18 21 -18 6 0 8 3 5 7 -4 3 -3 11 2 17 6 7 2
11 -14 11 -19 0 -21 -3 -14 -17z" />
            <path d="M6398 4229 c-3 -8 1 -19 8 -26 14 -15 44 -17 44 -4 0 11 -30 36 -30
25 0 -5 -4 -3 -9 5 -7 11 -9 11 -13 0z" />
            <path d="M30094 4232 c4 -7 1 -12 -6 -12 -7 0 -18 -11 -24 -25 -11 -23 -11
-23 11 -9 28 18 38 41 23 50 -7 4 -8 3 -4 -4z" />
            <path d="M7887 4223 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z" />
            <path d="M8752 4210 c-18 -30 -14 -36 8 -12 21 22 25 32 12 32 -4 0 -13 -9
-20 -20z" />
            <path d="M12602 4210 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z" />
            <path d="M13878 4218 c17 -17 15 -33 -3 -18 -13 11 -15 8 -14 -21 0 -28 2 -31
9 -14 5 11 9 16 10 10 0 -5 7 1 15 15 10 19 10 27 2 32 -19 12 -32 9 -19 -4z" />
            <path d="M26837 4223 c-10 -9 -9 -23 1 -23 5 0 9 7 9 15 0 17 -1 18 -10 8z" />
            <path d="M12987 4193 c-3 -16 -1 -29 4 -31 5 -2 4 -12 -1 -23 -8 -14 -7 -19 3
-19 10 0 13 11 10 40 -3 31 -1 40 12 40 8 0 15 5 15 10 0 6 -8 10 -19 10 -12
0 -20 -9 -24 -27z" />
            <path d="M24855 4200 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3
0 -8 -4 -11 -10z" />
            <path d="M27715 4198 c5 -11 2 -18 -6 -18 -7 0 -9 -3 -6 -6 3 -4 2 -16 -4 -27
-8 -14 -8 -18 1 -12 19 12 32 57 20 69 -8 8 -9 6 -5 -6z" />
            <path d="M30095 4190 c-16 -18 -15 -18 9 -16 34 4 37 8 21 23 -10 9 -17 8 -30
-7z" />
            <path d="M8777 4193 c-10 -9 -9 -41 2 -47 4 -3 8 8 7 24 -1 17 -1 30 -2 30 0
0 -4 -3 -7 -7z" />
            <path d="M16040 4178 c0 -19 2 -20 10 -8 13 19 13 30 0 30 -5 0 -10 -10 -10
-22z" />
            <path d="M18290 4178 c3 -39 1 -48 -15 -48 -8 0 -15 -5 -15 -11 0 -5 5 -7 10
-4 16 10 11 -8 -10 -35 -22 -28 -27 -49 -7 -28 26 28 55 97 50 119 -4 15 -3
20 5 15 7 -4 12 -2 12 3 0 6 -7 11 -16 11 -10 0 -15 -8 -14 -22z" />
            <path d="M11884 4165 c7 -37 14 -42 14 -10 0 14 -5 28 -10 31 -5 3 -6 -7 -4
-21z" />
            <path d="M19706 4154 c-9 -20 -14 -39 -11 -42 2 -3 10 9 16 27 9 24 14 29 24
21 11 -9 12 -7 8 9 -8 30 -17 26 -37 -15z" />
            <path d="M21780 4179 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0
-10 -5 -10 -11z" />
            <path d="M12584 4145 c-17 -41 -17 -45 -4 -45 6 0 10 9 10 20 0 11 5 20 11 20
6 0 9 -6 6 -13 -3 -8 -1 -20 4 -28 6 -10 9 -7 9 13 0 15 -4 29 -10 33 -5 3 -7
12 -3 20 11 32 -9 13 -23 -20z" />
            <path d="M16017 4160 c-8 -30 3 -43 21 -24 7 8 8 14 3 14 -5 0 -11 8 -13 18
-3 13 -5 11 -11 -8z" />
            <path d="M18767 4147 c-3 -8 1 -14 9 -14 16 0 19 13 5 21 -5 3 -11 0 -14 -7z" />
            <path d="M23389 4148 c-11 -8 -10 -9 4 -4 9 3 17 1 17 -4 0 -6 5 -10 10 -10 6
0 10 4 10 9 0 14 -26 20 -41 9z" />
            <path d="M30059 4128 c4 -28 6 -29 9 -9 2 13 0 27 -5 32 -6 6 -7 -3 -4 -23z" />
            <path d="M5480 4124 c-11 -20 -12 -27 -1 -33 10 -6 9 -11 -3 -24 -18 -17 -20
-27 -8 -27 5 0 17 17 27 38 28 53 29 60 11 37 -16 -19 -16 -19 -10 8 8 34 1
35 -16 1z" />
            <path d="M20421 4140 c-1 -8 -4 -26 -7 -40 -5 -19 -4 -22 5 -14 7 6 11 24 8
40 -2 16 -5 22 -6 14z" />
            <path d="M6521 4121 c-7 -13 -10 -25 -7 -29 3 -3 6 0 6 6 0 7 8 12 18 12 14 0
15 -2 2 -10 -17 -11 -10 -13 14 -4 9 3 16 11 16 17 0 6 -3 7 -7 4 -3 -4 -12 1
-18 10 -11 15 -13 15 -24 -6z" />
            <path d="M11866 4132 c-3 -6 -1 -14 5 -17 14 -9 20 -2 10 14 -6 8 -11 9 -15 3z" />
            <path d="M16840 4133 c0 -15 19 -37 26 -31 3 4 -1 14 -10 23 -9 9 -16 13 -16
8z" />
            <path d="M17290 4130 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z" />
            <path d="M30763 4128 c4 -11 2 -18 -5 -18 -6 0 -9 -3 -5 -6 3 -3 13 -2 22 3
15 9 15 11 -1 24 -16 12 -17 12 -11 -3z" />
            <path d="M7080 4126 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z" />
            <path d="M23325 4120 c-4 -6 -11 -7 -17 -4 -7 4 -8 2 -4 -4 7 -12 36 -5 36 9
0 12 -7 11 -15 -1z" />
            <path d="M24095 4120 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z" />
            <path d="M26750 4120 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z" />
            <path d="M30725 4120 c-16 -7 -17 -9 -3 -9 9 -1 20 4 23 9 7 11 7 11 -20 0z" />
            <path d="M15338 4103 c-21 -26 -27 -48 -17 -64 5 -8 9 -9 10 -4 4 47 8 57 25
70 10 8 14 15 8 15 -6 0 -18 -8 -26 -17z" />
            <path d="M20440 4111 c0 -5 -3 -16 -6 -25 -4 -10 -1 -16 8 -16 16 0 22 37 7
45 -5 4 -9 2 -9 -4z" />
            <path d="M24840 4104 c0 -8 5 -12 10 -9 6 3 10 10 10 16 0 5 -4 9 -10 9 -5 0
-10 -7 -10 -16z" />
            <path d="M30202 4105 c-7 -8 -9 -15 -4 -15 5 0 15 7 22 15 16 19 -1 19 -18 0z" />
            <path d="M6352 4090 c-10 -16 -10 -20 1 -20 7 0 13 9 14 20 1 11 1 20 0 20 -1
0 -8 -9 -15 -20z" />
            <path d="M6587 4099 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z" />
            <path d="M11165 4099 c-11 -17 1 -21 15 -4 8 9 8 15 2 15 -6 0 -14 -5 -17 -11z" />
            <path d="M16010 4105 c0 -5 5 -17 10 -25 5 -8 10 -10 10 -5 0 6 -5 17 -10 25
-5 8 -10 11 -10 5z" />
            <path d="M22735 4100 c-3 -5 1 -10 9 -10 9 0 16 5 16 10 0 6 -4 10 -9 10 -6 0
-13 -4 -16 -10z" />
            <path d="M19693 4085 c-10 -10 -9 -16 4 -27 15 -11 16 -11 10 6 -5 14 -3 17 8
13 8 -4 17 -2 20 2 3 5 -2 12 -11 15 -19 7 -17 8 -31 -9z" />
            <path d="M31880 4092 c0 -6 5 -12 11 -14 6 -2 -2 -16 -17 -31 -14 -15 -22 -27
-15 -27 13 0 51 36 51 49 0 5 -7 14 -15 21 -9 8 -15 8 -15 2z" />
            <path d="M6381 4074 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
            <path d="M16833 4078 c-20 -26 -26 -41 -15 -34 7 4 12 2 12 -3 0 -6 -5 -11
-12 -11 -6 0 -8 -3 -4 -7 9 -9 57 47 50 58 -7 13 -20 11 -31 -3z m15 -12 c-7
-8 -15 -12 -17 -11 -5 6 10 25 20 25 5 0 4 -6 -3 -14z" />
            <path d="M25400 4069 c0 -11 5 -17 10 -14 6 3 10 13 10 21 0 8 -4 14 -10 14
-5 0 -10 -9 -10 -21z" />
            <path d="M6654 4063 c3 -17 33 -31 71 -31 15 0 13 3 -10 14 -22 11 -24 14 -7
10 13 -2 20 0 16 6 -3 5 -16 7 -27 4 -12 -3 -26 -1 -33 5 -9 7 -12 5 -10 -8z" />
            <path d="M18726 4064 c-5 -14 -4 -15 9 -4 17 14 19 20 6 20 -5 0 -12 -7 -15
-16z" />
            <path d="M20249 4063 c-13 -16 -12 -17 4 -4 16 13 21 21 13 21 -2 0 -10 -8
-17 -17z" />
            <path d="M21697 4060 c-3 -11 -3 -20 -1 -20 2 0 8 9 14 20 6 11 7 20 2 20 -6
0 -12 -9 -15 -20z" />
            <path d="M22610 4071 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5
3 -10 2 -10 -4z" />
            <path d="M6846 4049 c-11 -10 -18 -20 -15 -23 3 -3 14 2 24 12 13 12 30 16 52
14 18 -2 33 -2 33 1 0 3 -16 7 -36 9 -25 3 -43 -1 -58 -13z" />
            <path d="M17223 4044 c0 -29 23 -43 40 -24 10 10 7 12 -11 7 -19 -4 -22 -2
-17 13 3 10 2 21 -3 24 -5 3 -9 -6 -9 -20z" />
            <path d="M22956 4062 c-9 -9 15 -32 33 -32 9 0 23 5 31 11 12 8 10 9 -9 4 -14
-5 -21 -3 -17 3 6 10 -29 24 -38 14z" />
            <path d="M30025 4061 c-3 -5 -1 -12 5 -16 5 -3 10 1 10 9 0 18 -6 21 -15 7z" />
            <path d="M6361 4044 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
            <path d="M6750 4050 c0 -5 7 -10 16 -10 8 0 12 5 9 10 -3 6 -10 10 -16 10 -5
0 -9 -4 -9 -10z" />
            <path d="M6808 4053 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
            <path d="M15260 4044 c0 -10 -6 -14 -14 -11 -10 4 -13 1 -9 -9 3 -7 1 -14 -5
-14 -5 0 -12 -9 -15 -21 -5 -17 0 -14 24 12 26 29 38 59 24 59 -3 0 -5 -7 -5
-16z" />
            <path d="M19677 4046 c5 -14 3 -17 -7 -11 -9 5 -11 4 -6 -4 4 -6 2 -19 -6 -28
-9 -12 -9 -15 0 -9 20 12 14 -19 -8 -39 -16 -14 -17 -20 -8 -27 10 -6 10 -10
-2 -17 -8 -6 -12 -12 -10 -15 3 -2 14 2 25 10 15 11 17 17 7 23 -10 6 -9 13 6
31 16 20 19 21 25 7 4 -12 1 -17 -11 -17 -10 0 -13 -3 -7 -8 16 -11 12 -30 -9
-45 -12 -9 -17 -20 -13 -31 4 -9 2 -16 -4 -16 -6 0 -7 -4 -4 -10 3 -5 11 -10
17 -10 6 0 8 3 5 7 -4 3 -1 13 6 21 9 13 9 14 0 8 -22 -12 -14 12 7 27 12 8
20 18 19 23 -4 22 2 35 13 28 6 -4 8 -3 4 4 -3 6 -2 13 4 17 5 3 10 12 10 18
0 7 -8 3 -16 -8 -15 -19 -16 -19 -10 11 5 23 2 37 -14 55 -16 19 -19 20 -13 5z
m20 -53 c-11 -10 -26 8 -20 23 5 15 7 14 15 -1 6 -9 7 -19 5 -22z" />
            <path d="M8696 4043 c1 -3 -4 -20 -12 -38 -14 -34 -10 -65 8 -65 6 0 5 6 -2
15 -9 11 -9 21 4 46 9 18 12 36 8 40 -5 3 -8 4 -6 2z" />
            <path d="M6877 4033 c-12 -12 -7 -23 11 -23 14 0 14 2 3 9 -11 7 -10 10 5 14
14 3 15 5 3 6 -9 0 -19 -2 -22 -6z" />
            <path d="M20431 4024 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
            <path d="M22890 4020 c0 -5 10 -10 23 -10 18 0 19 2 7 10 -19 13 -30 13 -30 0z" />
            <path d="M27650 4014 c0 -8 5 -12 10 -9 6 3 10 10 10 16 0 5 -4 9 -10 9 -5 0
-10 -7 -10 -16z" />
            <path d="M22597 3983 c4 -22 2 -25 -6 -13 -6 8 -11 11 -11 5 0 -12 20 -43 20
-31 0 5 7 3 15 -4 10 -9 15 -9 15 -1 0 6 -5 11 -11 11 -6 0 -8 8 -4 19 3 10
-1 23 -8 30 -12 9 -13 6 -10 -16z" />
            <path d="M26271 3984 c-10 -23 -10 -26 2 -16 8 7 17 9 20 5 10 -9 8 9 -1 25
-6 9 -12 5 -21 -14z" />
            <path d="M15250 3995 c-1 -5 -2 -19 -1 -30 2 -15 0 -17 -7 -6 -8 10 -13 8 -25
-10 -17 -23 -11 -40 18 -61 18 -13 28 -8 15 7 -10 12 -10 21 0 44 6 16 10 38
7 48 -3 10 -6 14 -7 8z m-10 -54 c0 -5 -7 -11 -14 -14 -10 -4 -13 -1 -9 9 6
15 23 19 23 5z" />
            <path d="M17220 3990 c-11 -7 -12 -12 -4 -20 9 -9 14 -9 18 -1 4 6 4 11 -1 11
-4 0 -1 5 7 10 8 5 11 10 5 10 -5 0 -17 -5 -25 -10z" />
            <path d="M14259 3988 c0 -2 -2 -14 -5 -27 -4 -21 -3 -22 13 -10 15 12 16 12
10 -5 -6 -16 -5 -17 7 -7 13 11 13 15 -2 32 -16 18 -21 22 -23 17z" />
            <path d="M23930 3980 c0 -5 9 -14 20 -20 16 -8 18 -8 14 4 -6 17 -34 30 -34
16z" />
            <path d="M24773 3978 c3 -10 2 -20 -2 -23 -10 -6 -33 -65 -25 -65 2 0 9 12 15
27 8 22 12 24 19 13 7 -10 8 -5 5 19 -2 18 -7 36 -11 40 -4 3 -4 -2 -1 -11z" />
            <path d="M27637 3971 c-4 -17 -3 -21 5 -13 5 5 8 16 6 23 -3 8 -7 3 -11 -10z" />
            <path d="M10056 3963 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z" />
            <path d="M15268 3955 c-3 -14 -1 -28 3 -31 9 -5 13 9 10 35 -2 7 2 10 8 6 6
-3 11 -1 11 4 0 22 -28 10 -32 -14z" />
            <path d="M12533 3940 c-7 -10 -10 -26 -7 -35 10 -26 21 -17 22 20 2 41 1 41
-15 15z" />
            <path d="M18672 3945 c-9 -10 -9 -15 -2 -15 7 0 10 -6 8 -12 -3 -7 -11 -13
-18 -13 -7 0 -15 -7 -17 -15 -4 -13 -3 -13 6 0 9 13 11 13 11 -2 0 -10 -7 -18
-15 -18 -8 0 -15 -8 -15 -17 0 -15 2 -16 10 -3 5 8 10 11 10 5 0 -5 7 1 17 14
15 23 17 23 30 6 8 -12 13 -14 13 -6 0 8 -8 20 -17 27 -13 10 -14 13 -4 14 11
0 11 3 0 17 -12 15 -12 16 4 10 12 -5 17 -3 15 5 -6 18 -21 19 -36 3z" />
            <path d="M15930 3940 c0 -5 4 -10 9 -10 5 0 13 -9 16 -21 4 -11 9 -18 13 -15
7 8 -19 56 -30 56 -4 0 -8 -4 -8 -10z" />
            <path d="M23935 3940 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z" />
            <path d="M29981 3934 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
            <path d="M8671 3914 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
            <path d="M8690 3920 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z" />
            <path d="M27646 3902 c5 -27 4 -32 -12 -32 -15 0 -16 2 -5 9 8 5 10 14 6 21
-6 10 -10 9 -16 -3 -12 -21 -1 -46 19 -43 20 3 29 53 13 69 -8 8 -10 3 -5 -21z" />
            <path d="M15250 3910 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z" />
            <path d="M15933 3887 c1 -26 4 -32 13 -23 8 8 9 17 2 31 -16 30 -16 29 -15 -8z" />
            <path d="M23925 3910 c-4 -6 -14 -9 -22 -8 -9 2 -17 -3 -19 -10 -1 -7 0 -8 2
-3 3 6 11 8 18 5 7 -3 19 2 26 11 7 8 10 15 7 15 -3 0 -9 -5 -12 -10z" />
            <path d="M12940 3899 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0
-10 -5 -10 -11z" />
            <path d="M14337 3903 c-4 -3 -7 -19 -7 -34 0 -35 22 -30 39 9 l13 27 -23 -20
-22 -20 5 23 c6 23 5 26 -5 15z" />
            <path d="M15187 3891 c-3 -12 1 -18 11 -17 8 0 17 -5 19 -12 3 -7 -1 -11 -9
-10 -14 3 -14 -5 -3 -34 4 -11 3 -18 -4 -18 -6 0 -11 8 -12 18 0 13 -2 14 -6
2 -3 -8 -12 -23 -21 -32 -10 -12 -11 -18 -4 -18 7 0 12 -5 12 -11 0 -5 -5 -7
-12 -3 -7 4 -8 3 -4 -4 4 -7 2 -12 -4 -12 -6 0 -9 -4 -5 -9 3 -5 1 -12 -5 -16
-5 -3 -10 -11 -10 -16 0 -6 4 -8 9 -4 5 3 12 1 15 -4 3 -5 0 -11 -8 -14 -7 -3
-18 -19 -24 -37 -7 -18 -7 -28 -2 -25 6 4 10 2 10 -4 0 -5 -6 -12 -12 -14 -8
-4 -7 -6 3 -6 9 -1 19 8 22 19 3 11 1 18 -4 14 -5 -3 -9 2 -9 11 0 12 5 15 16
11 14 -5 15 -4 5 8 -9 11 -8 15 5 20 13 5 14 9 4 21 -9 11 -9 18 0 32 10 16
11 16 6 1 -3 -10 0 -20 6 -24 7 -4 8 -3 4 4 -4 7 -2 12 4 12 6 0 8 5 4 12 -4
7 -9 22 -10 34 -2 17 2 22 17 19 11 -2 23 4 29 16 6 10 8 19 4 19 -3 0 -2 6 4
13 8 9 5 21 -10 45 -23 37 -24 38 -31 13z" />
            <path d="M25333 3894 c-4 -12 -7 -13 -14 -3 -12 20 -22 4 -26 -38 -2 -27 3
-42 17 -55 11 -11 20 -15 20 -10 0 5 -7 15 -15 22 -8 7 -15 17 -15 22 0 7 5 6
13 -1 16 -13 37 4 36 29 0 8 -4 5 -10 -9 -5 -13 -12 -22 -14 -19 -9 9 15 59
25 53 6 -3 7 1 4 9 -8 20 -13 20 -21 0z m-13 -30 c0 -8 -4 -12 -10 -9 -5 3
-10 10 -10 16 0 5 5 9 10 9 6 0 10 -7 10 -16z" />
            <path d="M6290 3879 c-7 -11 -9 -23 -6 -26 3 -3 -1 -18 -9 -34 -8 -15 -15 -34
-15 -41 1 -7 7 1 15 17 7 17 14 26 15 20 1 -5 5 -1 9 10 5 11 4 23 -1 26 -6 4
-6 14 1 28 7 11 9 21 7 21 -3 0 -10 -9 -16 -21z" />
            <path d="M23853 3873 c0 -45 -3 -52 -23 -48 -13 3 -18 0 -14 -7 5 -7 -2 -9
-20 -4 -16 4 -26 2 -26 -4 0 -6 4 -9 9 -6 5 4 11 0 14 -6 3 -10 7 -10 20 1 10
7 17 10 17 6 0 -4 6 -2 14 4 18 15 30 61 18 73 -6 6 -9 3 -9 -9z" />
            <path d="M12525 3870 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z" />
            <path d="M12500 3861 c0 -6 1 -12 2 -13 1 -2 -4 -14 -11 -28 l-12 -25 19 24
c19 23 20 23 25 5 4 -17 5 -17 6 -1 0 10 -6 25 -14 33 -8 9 -15 11 -15 5z" />
            <path d="M14377 3863 c-11 -11 -8 -33 5 -33 6 0 8 3 5 7 -4 3 -2 12 4 20 11
13 -2 19 -14 6z" />
            <path d="M16754 3846 c-3 -14 -1 -33 5 -43 8 -14 9 -11 4 17 -6 33 -5 34 12
20 17 -14 18 -14 6 8 -15 28 -19 28 -27 -2z" />
            <path d="M18815 3859 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z" />
            <path d="M11097 3831 c-4 -17 -3 -21 5 -13 5 5 8 16 6 23 -3 8 -7 3 -11 -10z" />
            <path d="M26578 3843 c10 -4 10 -8 0 -21 -12 -15 -11 -16 4 -3 19 14 15 32 -5
30 -8 0 -8 -2 1 -6z" />
            <path d="M18615 3833 c3 -3 -5 -22 -17 -42 -12 -20 -18 -33 -13 -29 4 4 15 3
25 -2 15 -8 17 -4 16 30 -1 21 -5 42 -9 45 -4 3 -5 2 -2 -2z m5 -53 c0 -5 -5
-10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z" />
            <path d="M19605 3821 c4 -14 3 -21 -2 -18 -5 4 -10 1 -11 -6 -2 -22 -7 -42
-15 -54 -5 -7 -4 -13 2 -13 5 0 12 6 15 13 3 6 3 2 1 -10 -3 -12 -1 -24 5 -28
10 -6 14 7 11 34 -2 7 2 10 8 6 7 -4 8 0 4 10 -3 10 -1 20 4 24 6 3 2 18 -9
36 -16 25 -18 26 -13 6z m8 -49 c-3 -12 -9 -19 -14 -16 -5 3 -3 14 3 24 15 24
19 21 11 -8z" />
            <path d="M19630 3822 c9 -19 30 -23 30 -5 0 4 -3 3 -7 0 -3 -4 -12 1 -20 11
-13 15 -13 15 -3 -6z" />
            <path d="M20360 3810 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5
0 -10 -4 -10 -10z" />
            <path d="M26580 3800 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z" />
            <path d="M6276 3768 c-8 -41 7 -56 22 -23 9 19 9 24 1 19 -8 -4 -9 0 -4 15 3
11 2 21 -3 21 -5 0 -12 -14 -16 -32z" />
            <path d="M11084 3789 c-4 -7 -3 -9 4 -5 5 3 13 0 15 -6 4 -9 6 -10 6 -1 1 17
-16 25 -25 12z" />
            <path d="M15658 3763 l-17 -38 19 24 c17 19 27 51 17 51 -2 0 -10 -17 -19 -37z" />
            <path d="M16736 3785 c4 -8 8 -15 10 -15 2 0 4 7 4 15 0 8 -4 15 -10 15 -5 0
-7 -7 -4 -15z" />
            <path d="M12530 3780 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5
0 -10 -4 -10 -10z" />
            <path d="M20135 3780 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3
0 -8 -4 -11 -10z" />
            <path d="M25300 3778 c0 -9 5 -20 10 -23 13 -8 13 5 0 25 -8 13 -10 13 -10 -2z" />
            <path d="M8619 3778 c-3 -11 -11 -46 -13 -58 -1 -9 -5 -20 -9 -27 -4 -6 -1
-13 6 -16 8 -3 14 4 14 17 1 11 5 36 8 54 3 17 3 32 0 32 -3 0 -5 -1 -6 -2z" />
            <path d="M12902 3749 c-15 -24 -15 -26 3 -26 9 0 14 6 11 13 -3 7 0 14 7 17 7
2 7 6 1 10 -5 3 -15 -3 -22 -14z" />
            <path d="M15191 3754 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
            <path d="M21151 3754 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
            <path d="M22530 3750 c-8 -6 -11 -10 -6 -10 5 0 3 -6 -3 -13 -6 -8 -8 -17 -4
-20 3 -4 12 5 20 20 15 30 14 36 -7 23z" />
            <path d="M7487 3735 c-4 -8 -12 -14 -18 -13 -6 2 -13 -5 -14 -14 -2 -10 0 -18
3 -18 10 1 45 51 39 56 -2 3 -7 -2 -10 -11z" />
            <path d="M11076 3724 c-4 -14 -4 -41 0 -60 4 -22 2 -34 -5 -34 -6 0 -11 -4
-11 -10 0 -18 19 -10 24 10 17 57 18 65 7 50 -9 -11 -10 -4 -5 28 7 46 0 57
-10 16z" />
            <path d="M11102 3725 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z" />
            <path d="M21455 3722 c0 -28 19 -40 31 -21 5 9 2 10 -10 6 -14 -6 -17 -3 -12
9 3 8 6 18 6 21 0 3 6 0 14 -6 10 -8 16 -9 21 -1 4 6 3 10 -2 9 -4 -1 -10 0
-13 1 -27 10 -36 5 -35 -18z" />
            <path d="M23700 3741 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5
3 -10 2 -10 -4z" />
            <path d="M25290 3741 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5
3 -10 2 -10 -4z" />
            <path d="M26509 3739 c-13 -8 -5 -39 10 -39 5 0 13 5 17 12 4 7 3 8 -4 4 -7
-4 -12 -1 -12 8 0 10 6 13 15 10 8 -4 15 -2 15 3 0 11 -24 13 -41 2z" />
            <path d="M9784 3719 c-14 -16 -14 -18 6 -24 21 -7 67 21 55 33 -12 13 -46 7
-61 -9z m36 -8 c0 -4 -7 -8 -15 -8 -16 0 -20 9 -9 20 8 8 24 -1 24 -12z" />
            <path d="M15897 3702 c-10 -10 -14 -22 -10 -26 4 -4 1 -11 -7 -16 -9 -6 -10
-10 -3 -10 11 0 47 60 40 67 -2 2 -11 -5 -20 -15z" />
            <path d="M5407 3669 c-10 -22 -22 -37 -26 -35 -4 3 -8 -8 -8 -25 0 -19 3 -25
9 -17 5 9 8 7 8 -7 0 -12 -5 -26 -12 -33 -9 -9 -9 -12 0 -12 14 0 27 44 18 59
-7 10 11 50 27 62 5 4 7 15 5 26 -2 17 -7 13 -21 -18z" />
            <path d="M12896 3683 c10 -40 14 -41 14 -5 0 18 -5 32 -10 32 -6 0 -8 -11 -4
-27z" />
            <path d="M19560 3700 c0 -5 7 -10 16 -10 8 0 12 -5 9 -10 -4 -6 -11 -7 -17 -4
-6 4 -6 -1 1 -14 7 -14 8 -23 1 -27 -5 -3 -10 2 -11 12 0 16 -1 16 -8 -2 -5
-12 -4 -23 3 -27 14 -9 36 19 30 37 -3 7 -1 16 5 19 14 9 2 36 -15 36 -8 0
-14 -4 -14 -10z" />
            <path d="M20057 3693 c-4 -3 -7 -15 -7 -25 0 -12 -4 -16 -12 -12 -7 4 -8 3 -4
-4 11 -18 31 -4 31 23 0 14 0 25 -1 25 0 0 -4 -3 -7 -7z" />
            <path d="M14111 3674 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
            <path d="M15656 3677 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 -10 9 -11 8 -5 -6z" />
            <path d="M11693 3657 c-1 -11 -1 -18 0 -16 2 2 8 11 15 20 9 12 9 16 -1 16 -7
0 -14 -9 -14 -20z" />
            <path d="M8591 3654 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
            <path d="M16690 3646 c-15 -39 -12 -46 6 -13 9 16 13 31 10 34 -3 3 -10 -6
-16 -21z" />
            <path d="M16988 3652 c17 -19 32 -19 32 -1 0 6 -4 8 -9 4 -5 -3 -12 -1 -16 5
-3 5 -10 10 -16 10 -5 0 -1 -8 9 -18z" />
            <path d="M18756 3655 c-3 -9 -6 -22 -5 -28 0 -7 5 -1 10 12 5 13 8 26 5 28 -2
2 -6 -3 -10 -12z" />
            <path d="M22500 3650 c-6 -11 -7 -20 -2 -20 6 0 12 9 15 20 3 11 3 20 1 20 -2
0 -8 -9 -14 -20z" />
            <path d="M23627 3652 c-10 -10 -17 -23 -17 -28 0 -4 7 2 16 14 8 12 20 22 26
22 6 0 3 -9 -6 -19 -12 -13 -14 -21 -6 -26 6 -4 14 -4 17 -1 4 3 1 6 -6 6 -7
0 -9 5 -6 10 4 6 10 8 15 5 9 -6 21 20 13 29 -11 11 -30 6 -46 -12z" />
            <path d="M25215 3660 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z" />
            <path d="M28698 3663 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
            <path d="M8616 3645 c-3 -8 -1 -15 4 -15 6 0 10 7 10 15 0 8 -2 15 -4 15 -2 0
-6 -7 -10 -15z" />
            <path d="M11101 3644 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
            <path d="M18525 3649 c-4 -6 -4 -13 -1 -16 3 -3 1 -12 -6 -20 -6 -7 -8 -16 -5
-20 10 -10 -13 -44 -25 -37 -7 4 -8 3 -4 -5 4 -6 11 -9 16 -6 4 3 10 2 13 -2
2 -5 2 -2 1 5 -5 17 7 15 20 -4 10 -13 12 -13 12 5 0 11 -3 18 -6 16 -3 -2 -5
16 -6 40 0 24 4 42 8 39 4 -3 8 0 8 5 0 14 -17 14 -25 0z" />
            <path d="M25240 3650 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5
0 -10 -4 -10 -10z" />
            <path d="M15595 3630 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z" />
            <path d="M15870 3611 c-12 -17 -19 -39 -17 -49 3 -12 5 -10 6 7 1 17 5 22 17
17 11 -4 15 -2 11 8 -3 7 0 19 6 25 6 6 8 13 5 16 -3 4 -16 -8 -28 -24z" />
            <path d="M25261 3623 c-9 -16 -12 -16 -21 -3 -16 25 -12 -10 5 -40 11 -20 15
-21 16 -7 2 26 4 31 13 45 4 7 6 16 3 18 -3 3 -10 -3 -16 -13z" />
            <path d="M25211 3612 c0 -12 6 -33 14 -46 14 -22 14 -25 0 -40 -9 -9 -12 -16
-7 -16 5 0 15 7 22 15 10 12 9 19 -3 38 -8 13 -17 34 -20 48 -4 23 -4 23 -6 1z" />
            <path d="M8581 3610 c0 -8 4 -24 9 -35 l9 -20 0 20 c0 11 -4 27 -9 35 -9 13
-10 13 -9 0z" />
            <path d="M27545 3610 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3
0 -8 -4 -11 -10z" />
            <path d="M12419 3591 c-31 -35 -32 -42 -5 -29 14 6 26 19 26 30 0 21 0 21 -21
-1z" />
            <path d="M26445 3600 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z" />
            <path d="M29897 3603 c-10 -9 -9 -33 1 -33 4 0 8 9 8 20 0 23 0 22 -9 13z" />
            <path d="M19555 3590 c-4 -6 -11 -8 -16 -5 -5 3 -6 -1 -3 -9 3 -9 2 -16 -2
-16 -5 0 -9 -11 -9 -24 0 -13 3 -22 8 -19 4 2 7 12 7 21 0 10 7 28 16 40 8 12
13 22 10 22 -3 0 -8 -5 -11 -10z" />
            <path d="M7302 3584 c4 -4 3 -15 -3 -26 -9 -18 -8 -18 10 -8 25 13 27 26 4 34
-10 4 -14 4 -11 0z" />
            <path d="M18735 3580 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3
0 -8 -4 -11 -10z" />
            <path d="M27550 3583 c0 -4 5 -15 10 -23 8 -13 10 -13 10 2 0 9 -4 20 -10 23
-5 3 -10 3 -10 -2z" />
            <path d="M8566 3563 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z" />
            <path d="M15110 3570 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5
0 -10 -4 -10 -10z" />
            <path d="M16670 3570 c0 -8 5 -22 10 -30 9 -13 10 -13 10 0 0 8 -5 22 -10 30
-9 13 -10 13 -10 0z" />
            <path d="M20287 3573 c-3 -5 -1 -17 4 -28 4 -11 8 -15 8 -8 1 6 4 18 7 27 6
16 -11 23 -19 9z" />
            <path d="M14052 3544 c-7 -8 -8 -14 -3 -14 10 0 25 19 20 25 -2 1 -10 -3 -17
-11z" />
            <path d="M15095 3549 c4 -5 2 -16 -5 -24 -8 -10 -8 -15 2 -21 7 -4 10 -3 5 1
-4 5 0 16 8 25 14 16 14 18 0 24 -10 3 -14 2 -10 -5z" />
            <path d="M29883 3528 c0 -41 11 -51 20 -18 3 11 1 18 -3 15 -5 -3 -10 5 -13
17 -2 13 -4 6 -4 -14z" />
            <path d="M21086 3515 c-4 -20 -5 -40 -2 -43 3 -3 6 4 6 14 0 11 4 24 10 30 5
5 5 15 1 23 -6 9 -11 1 -15 -24z" />
            <path d="M26130 3540 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5
0 -10 -4 -10 -10z" />
            <path d="M5395 3527 c-4 -10 -5 -21 -1 -24 10 -10 18 4 13 24 -4 17 -4 17 -12
0z" />
            <path d="M24614 3498 c2 -9 -2 -22 -10 -28 -17 -15 -19 -40 -2 -40 6 0 8 3 5
7 -4 3 0 14 9 24 13 14 13 21 4 36 -9 15 -11 15 -6 1z" />
            <path d="M14255 3490 c-3 -5 1 -10 9 -10 9 0 16 5 16 10 0 6 -4 10 -9 10 -6 0
-13 -4 -16 -10z" />
            <path d="M25220 3480 c-6 -11 -7 -20 -2 -20 6 0 12 9 15 20 3 11 3 20 1 20 -2
0 -8 -9 -14 -20z" />
            <path d="M16844 3482 c-6 -4 -16 -17 -23 -30 -10 -18 -9 -23 4 -28 8 -3 12
-11 8 -17 -3 -6 -1 -7 6 -3 8 5 9 12 2 19 -17 21 -16 24 3 46 20 21 20 26 0
13z" />
            <path d="M11021 3473 c-1 -6 -4 -20 -7 -30 -5 -17 -5 -17 6 0 6 10 9 23 6 30
-3 9 -5 9 -5 0z" />
            <path d="M12365 3470 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z" />
            <path d="M29866 3463 c-2 -10 -8 -33 -11 -52 -6 -28 -5 -32 6 -21 8 7 14 30
14 51 1 43 -1 48 -9 22z" />
            <path d="M31562 3471 c-7 -4 -20 -21 -28 -37 -13 -27 -12 -27 16 6 16 19 30
29 30 23 0 -7 -4 -13 -10 -13 -5 0 -10 -5 -10 -12 0 -6 -7 -20 -17 -30 -9 -10
-11 -18 -6 -18 16 0 56 71 46 81 -5 5 -14 5 -21 0z" />
            <path d="M16850 3450 c-6 -11 -7 -20 -3 -20 5 0 14 9 21 20 7 11 8 20 2 20 -5
0 -14 -9 -20 -20z" />
            <path d="M19500 3459 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0
-10 -5 -10 -11z" />
            <path d="M14270 3444 c0 -8 -4 -12 -10 -9 -14 9 -12 -9 2 -23 10 -10 10 -15
-2 -22 -8 -5 -11 -12 -7 -16 4 -4 10 -5 13 -1 11 11 22 87 13 87 -5 0 -9 -7
-9 -16z" />
            <path d="M15071 3444 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
            <path d="M6185 3441 c-3 -5 -1 -12 5 -16 5 -3 10 1 10 9 0 18 -6 21 -15 7z" />
            <path d="M21070 3430 c0 -11 4 -20 8 -20 4 0 8 9 8 20 0 11 -4 20 -8 20 -4 0
-8 -9 -8 -20z" />
            <path d="M24621 3434 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
            <path d="M12346 3427 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 -10 9 -11 8 -5 -6z" />
            <path d="M19493 3425 c-3 -9 -3 -18 -1 -21 3 -3 8 4 11 16 6 23 -1 27 -10 5z" />
            <path d="M30823 3423 c-20 -7 -15 -23 5 -16 15 6 15 5 3 -10 -12 -15 -12 -17
2 -11 9 3 15 14 14 25 -1 20 -4 21 -24 12z" />
            <path d="M5391 3404 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
            <path d="M18682 3408 c2 -6 10 -14 16 -16 7 -2 10 2 6 12 -7 18 -28 22 -22 4z" />
            <path d="M7146 3401 c-4 -5 -2 -12 3 -15 5 -4 12 -2 15 3 4 5 2 12 -3 15 -5 4
-12 2 -15 -3z" />
            <path d="M12337 3393 c-12 -12 -7 -22 8 -17 8 4 15 10 15 15 0 11 -14 12 -23
2z" />
            <path d="M9467 3375 c-16 -11 -16 -13 -2 -19 9 -3 14 -2 10 3 -3 5 1 11 7 14
7 2 11 7 8 10 -3 3 -13 0 -23 -8z" />
            <path d="M13967 3376 c-4 -10 -3 -15 3 -11 6 3 10 2 10 -4 0 -5 -8 -15 -17
-20 -10 -6 -13 -11 -7 -11 20 0 36 28 26 46 -8 15 -9 15 -15 0z" />
            <path d="M10998 3350 c-3 -17 -1 -33 4 -36 4 -3 8 11 8 30 0 44 -6 47 -12 6z" />
            <path d="M19885 3360 c-3 -11 -3 -24 0 -30 8 -13 25 -3 25 13 0 7 -4 6 -10 -3
-8 -13 -10 -13 -10 2 0 9 5 18 12 20 9 3 9 6 1 11 -7 4 -14 -1 -18 -13z" />
            <path d="M20250 3363 c0 -13 5 -23 10 -23 13 0 13 11 0 30 -8 12 -10 11 -10
-7z" />
            <path d="M8536 3357 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 -10 9 -11 8 -5 -6z" />
            <path d="M12845 3360 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3
0 -8 -4 -11 -10z" />
            <path d="M14250 3351 c0 -6 4 -13 10 -16 6 -3 7 1 4 9 -7 18 -14 21 -14 7z" />
            <path d="M31481 3348 c-1 -20 14 -24 25 -6 4 6 3 8 -4 4 -5 -3 -13 0 -15 6 -3
8 -6 6 -6 -4z" />
            <path d="M7075 3341 c-3 -5 0 -13 6 -17 7 -4 9 -3 5 4 -3 5 0 13 6 15 9 4 10
6 1 6 -6 1 -14 -3 -18 -8z" />
            <path d="M9383 3338 c-12 -21 -11 -32 3 -46 9 -8 14 -9 14 -2 0 6 5 8 10 5 6
-4 10 -3 9 2 -1 4 -1 15 0 23 1 13 2 13 11 0 5 -8 10 -10 10 -4 0 6 -8 18 -17
25 -9 8 -14 9 -10 3 3 -6 0 -20 -8 -30 -14 -18 -15 -17 -11 9 5 29 2 34 -11
15z" />
            <path d="M15798 3311 c11 -24 20 -35 24 -28 11 17 10 29 -2 22 -5 -3 -10 3
-10 15 0 12 4 19 9 15 5 -3 12 -1 16 5 4 6 -6 10 -24 10 l-30 0 17 -39z" />
            <path d="M8523 3326 c1 -11 9 -21 17 -23 10 -3 11 -2 4 4 -6 4 -14 15 -17 23
-5 11 -6 9 -4 -4z" />
            <path d="M15771 3308 c-1 -16 4 -28 9 -28 12 0 12 8 0 35 -8 18 -9 17 -9 -7z" />
            <path d="M22444 3307 c2 -16 -2 -34 -9 -40 -6 -7 -15 -26 -18 -42 -7 -28 -6
-29 8 -11 8 10 12 24 8 29 -4 6 0 8 9 4 18 -6 31 25 18 40 -5 4 -11 17 -14 28
-3 11 -4 8 -2 -8z" />
            <path d="M27493 3313 c3 -13 1 -23 -5 -23 -5 0 -6 -5 -2 -11 5 -8 10 -6 16 5
6 10 5 22 -2 34 -10 15 -11 14 -7 -5z" />
            <path d="M12292 3305 c-7 -8 -8 -15 -3 -15 6 0 8 -7 5 -15 -4 -8 -2 -15 3 -15
4 0 9 14 9 30 1 17 1 30 0 30 0 -1 -7 -7 -14 -15z" />
            <path d="M12828 3282 c-9 -22 -16 -54 -16 -72 1 -30 2 -31 15 -15 16 22 17 39
3 30 -14 -8 -1 47 13 56 5 4 7 14 5 23 -2 11 -9 4 -20 -22z" />
            <path d="M13936 3305 c-3 -8 -1 -15 3 -15 5 0 11 7 15 15 3 8 1 15 -3 15 -5 0
-11 -7 -15 -15z" />
            <path d="M18373 3306 c-9 -11 -9 -15 0 -19 7 -3 14 3 14 14 2 22 2 22 -14 5z" />
            <path d="M14251 3294 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
            <path d="M10115 3290 c-3 -5 -3 -10 2 -10 4 0 0 -5 -10 -11 -18 -10 -18 -11 0
-25 17 -13 16 -14 -7 -11 -19 1 -24 -2 -22 -17 2 -13 -1 -16 -10 -10 -10 6 -9
3 1 -10 18 -22 36 1 18 24 -11 13 -10 13 6 1 23 -18 29 -11 32 39 2 41 1 47
-10 30z" />
            <path d="M9369 3288 c-5 -18 -6 -38 -1 -34 7 8 12 36 6 36 -2 0 -4 -1 -5 -2z" />
            <path d="M8525 3270 c3 -5 10 -10 16 -10 5 0 9 5 9 10 0 6 -7 10 -16 10 -8 0
-12 -4 -9 -10z" />
            <path d="M18350 3268 c0 -6 3 -8 7 -5 3 4 12 2 20 -4 8 -7 13 -8 13 -1 0 5 -7
13 -16 16 -22 8 -24 8 -24 -6z" />
            <path d="M21036 3264 c-20 -20 -20 -23 2 -19 9 1 16 8 14 15 -1 6 2 9 7 6 5
-4 12 -2 16 4 10 16 -22 12 -39 -6z" />
            <path d="M21075 3255 c-11 -13 -25 -24 -31 -25 -17 0 -37 -46 -29 -69 3 -12 2
-21 -4 -21 -5 0 -9 -13 -9 -29 1 -27 1 -28 19 -10 11 11 18 21 17 22 -2 2 3
11 10 20 9 10 10 17 3 22 -12 7 13 65 27 65 11 0 33 39 24 44 -4 2 -17 -6 -27
-19z m-35 -65 c-8 -15 -9 -25 -2 -29 7 -4 3 -16 -9 -31 -17 -21 -19 -22 -10
-3 6 12 8 28 5 36 -6 16 14 63 23 54 2 -3 -1 -15 -7 -27z" />
            <path d="M24530 3271 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5
3 -10 2 -10 -4z" />
            <path d="M15407 3256 c-4 -10 -1 -13 9 -9 7 3 14 9 14 14 0 14 -17 10 -23 -5z" />
            <path d="M23215 3244 c0 -25 10 -23 17 4 3 10 0 19 -6 19 -6 0 -11 -10 -11
-23z" />
            <path d="M8514 3245 c2 -11 8 -31 11 -44 5 -16 4 -21 -4 -17 -6 4 -11 4 -12
-1 0 -4 -2 -28 -3 -53 -3 -30 -1 -39 4 -25 8 18 9 18 9 -5 1 -14 -6 -38 -14
-54 -8 -15 -15 -33 -14 -40 0 -6 4 -2 9 9 7 19 8 19 15 1 5 -10 3 -21 -2 -25
-11 -7 -12 -81 0 -74 4 2 7 19 8 36 0 27 2 29 9 12 7 -16 9 -17 9 -3 1 9 4 25
7 35 5 15 4 16 -5 3 -8 -11 -10 2 -7 52 1 37 1 70 -2 73 -11 10 -10 35 0 35
12 0 2 66 -13 90 -8 12 -9 11 -5 -5z" />
            <path d="M29056 3244 c-4 -9 -4 -19 -1 -22 2 -3 7 3 11 12 4 9 4 19 1 22 -2 3
-7 -3 -11 -12z" />
            <path d="M27491 3233 c0 -13 -6 -23 -13 -23 -9 0 -9 -3 0 -12 16 -16 24 -5 19
29 -3 21 -4 23 -6 6z" />
            <path d="M5372 3225 c0 -11 3 -29 8 -40 11 -25 11 1 0 35 -6 20 -8 21 -8 5z" />
            <path d="M26055 3230 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3
0 -8 -4 -11 -10z" />
            <path d="M31403 3225 c0 -8 4 -12 9 -9 5 3 6 10 3 15 -9 13 -12 11 -12 -6z" />
            <path d="M7704 3198 c-16 -17 -24 -28 -17 -24 6 3 13 0 16 -6 6 -17 20 -3 22
20 0 9 4 23 9 30 13 21 1 13 -30 -20z" />
            <path d="M22435 3199 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z" />
            <path d="M24509 3200 c-1 -8 -3 -25 -4 -37 -1 -13 2 -23 7 -23 5 0 6 10 3 21
-5 15 -4 19 4 15 14 -9 14 4 1 24 -9 13 -10 13 -11 0z" />
            <path d="M10121 3184 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
            <path d="M10977 3181 c-2 -10 0 -25 5 -32 8 -11 9 -11 5 1 -2 8 0 23 5 33 5
10 5 17 0 17 -6 0 -12 -9 -15 -19z" />
            <path d="M10052 3178 c2 -7 -3 -12 -10 -10 -10 2 -10 -2 -2 -18 13 -24 22 -27
13 -3 -4 11 -3 14 5 9 7 -4 12 -1 12 8 0 8 -5 18 -10 21 -6 4 -9 1 -8 -7z" />
            <path d="M13877 3176 c-4 -10 -5 -22 -1 -27 3 -5 1 -9 -3 -9 -5 0 -9 -12 -8
-27 0 -18 -5 -27 -13 -25 -8 1 -11 -2 -7 -8 3 -5 1 -10 -6 -10 -10 0 -10 -3
-1 -12 9 -9 15 -8 27 8 8 10 15 26 15 34 3 35 10 55 19 58 6 2 11 8 11 13 0 6
-4 8 -9 5 -5 -4 -11 -1 -13 6 -3 7 -7 5 -11 -6z" />
            <path d="M30546 3171 c-16 -9 -32 -23 -34 -31 -4 -11 0 -11 15 3 31 27 39 20
12 -10 l-24 -28 27 24 c15 13 26 29 25 35 -1 6 4 10 11 8 8 -1 11 2 7 8 -3 5
-7 10 -8 9 -1 0 -15 -8 -31 -18z" />
            <path d="M31351 3161 c-12 -16 -21 -37 -21 -46 0 -9 -5 -13 -11 -9 -7 4 -10 1
-7 -6 2 -7 11 -14 20 -16 11 -1 14 5 12 27 -3 28 16 53 24 31 6 -20 18 -13 19
11 0 12 -1 16 -4 9 -3 -6 -9 -9 -14 -6 -6 3 -5 11 2 20 6 8 9 14 6 14 -3 0
-15 -13 -26 -29z" />
            <path d="M6141 3164 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
            <path d="M10099 3155 c0 -16 -2 -44 -5 -62 -2 -18 0 -34 5 -38 11 -6 19 102 9
118 -4 7 -8 -1 -9 -18z" />
            <path d="M15366 3165 c-3 -8 -1 -15 4 -15 6 0 10 7 10 15 0 8 -2 15 -4 15 -2
0 -6 -7 -10 -15z" />
            <path d="M27477 3162 c1 -8 3 -16 3 -18 0 -2 5 -4 10 -4 6 0 10 7 10 16 0 8
-4 13 -9 10 -5 -4 -11 -3 -14 1 -2 5 -2 2 0 -5z" />
            <path d="M30500 3169 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0
-10 -5 -10 -11z" />
            <path d="M31403 3155 c-3 -9 -3 -19 1 -22 3 -4 6 2 7 13 0 18 1 18 9 -1 8 -19
9 -19 9 3 1 25 -17 30 -26 7z" />
            <path d="M18650 3144 c0 -8 5 -12 10 -9 6 3 10 10 10 16 0 5 -4 9 -10 9 -5 0
-10 -7 -10 -16z" />
            <path d="M12206 3131 c-4 -5 -3 -17 1 -27 4 -11 3 -15 -5 -10 -6 3 -13 2 -16
-3 -4 -5 -2 -12 3 -15 12 -8 26 13 26 42 1 24 -1 27 -9 13z" />
            <path d="M17232 3117 c-24 -30 -32 -56 -20 -63 7 -5 8 -2 3 7 -6 11 -5 12 6 2
18 -17 30 -16 23 1 -3 8 -1 24 5 35 12 22 -1 38 -17 18z m8 -17 c-6 -11 -13
-20 -16 -20 -2 0 0 9 6 20 6 11 13 20 16 20 2 0 0 -9 -6 -20z" />
            <path d="M26683 3118 c-20 -26 -22 -35 -7 -35 9 0 13 6 9 16 -4 11 -3 13 4 6
5 -5 11 -21 14 -35 3 -14 4 -6 3 17 -1 45 -7 52 -23 31z" />
            <path d="M29814 3099 c-1 -15 3 -26 7 -23 11 6 12 41 2 47 -5 3 -8 -8 -9 -24z" />
            <path d="M10121 3104 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
            <path d="M27486 3105 c-3 -9 -10 -13 -16 -10 -5 3 -10 1 -10 -4 0 -6 4 -11 9
-11 12 0 35 29 29 36 -3 3 -8 -2 -12 -11z" />
            <path d="M9170 3102 c0 -6 -7 -9 -15 -6 -11 5 -13 2 -9 -10 3 -9 1 -16 -5 -16
-6 0 -11 -6 -11 -12 0 -7 12 0 27 14 15 15 24 31 20 34 -4 4 -7 2 -7 -4z" />
            <path d="M18280 3090 c0 -5 5 -10 10 -10 6 0 10 -5 10 -11 0 -6 -9 -8 -22 -4
-23 7 -23 7 -3 -9 32 -24 36 -19 26 37 -2 11 -21 9 -21 -3z" />
            <path d="M6740 3079 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z" />
            <path d="M21636 3075 c-3 -8 0 -17 6 -21 6 -4 8 -3 4 4 -3 5 -1 12 6 14 8 3 9
6 1 11 -6 4 -13 0 -17 -8z" />
            <path d="M8491 3064 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
            <path d="M14201 3048 c-11 -18 -11 -20 3 -15 12 5 14 1 9 -23 -3 -17 -1 -30 4
-30 9 0 10 77 1 85 -2 2 -10 -5 -17 -17z" />
            <path d="M20200 3054 c1 -5 7 -20 15 -34 13 -24 14 -24 15 -4 0 12 -7 27 -15
34 -8 7 -15 9 -15 4z" />
            <path d="M27472 3040 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z" />
            <path d="M17203 3039 c-21 -8 -28 -20 -24 -41 0 -5 -4 -8 -10 -8 -5 0 -7 -5
-4 -11 10 -15 34 21 27 40 -4 10 -1 13 11 8 10 -4 17 -1 17 6 0 8 3 8 9 -2 14
-22 1 -52 -20 -46 -16 5 -17 0 -13 -46 5 -64 14 -77 14 -21 0 22 3 43 8 45 4
3 6 -20 5 -51 l-1 -57 7 60 c4 33 11 74 15 91 8 36 -5 46 -41 33z" />
            <path d="M9018 3004 c-21 -13 -38 -30 -38 -36 0 -6 10 -2 23 10 25 23 43 30
31 11 -4 -8 0 -8 15 0 25 14 28 23 4 14 -16 -6 -16 -5 -4 10 17 22 14 21 -31
-9z" />
            <path d="M9946 3014 c-4 -9 -4 -19 -1 -22 2 -3 7 3 11 12 4 9 4 19 1 22 -2 3
-7 -3 -11 -12z" />
            <path d="M19726 3014 c-5 -14 -4 -15 9 -4 17 14 19 20 6 20 -5 0 -12 -7 -15
-16z" />
            <path d="M22900 3023 c0 -5 5 -15 10 -23 8 -12 10 -11 10 8 0 12 -4 22 -10 22
-5 0 -10 -3 -10 -7z" />
            <path d="M22971 3021 c-9 -6 -10 -11 -2 -15 6 -4 11 -2 11 4 0 6 5 8 12 4 7
-4 8 -3 4 4 -8 14 -7 14 -25 3z" />
            <path d="M27959 3017 c11 -14 5 -27 -11 -27 -5 0 -7 4 -3 9 3 5 1 12 -4 15 -4
3 -8 -12 -7 -33 1 -43 13 -44 59 -4 14 12 27 19 30 17 2 -2 10 2 17 11 7 8 9
15 4 16 -5 0 -17 2 -26 4 -10 2 -20 -1 -24 -6 -3 -5 -10 -9 -15 -9 -6 0 -7 5
-4 10 3 6 -1 10 -11 10 -13 0 -14 -3 -5 -13z m27 -33 c-14 -15 -29 -25 -32
-22 -3 3 7 18 22 32 36 33 45 24 10 -10z" />
            <path d="M20186 2989 c-8 -42 -8 -59 2 -59 4 0 9 20 10 45 2 52 -3 58 -12 14z" />
            <path d="M29822 3000 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z" />
            <path d="M5391 2994 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
            <path d="M6653 2994 c0 -14 23 -12 39 4 9 9 6 12 -13 10 -14 -1 -26 -7 -26
-14z" />
            <path d="M24450 2998 c0 -9 5 -20 10 -23 13 -8 13 5 0 25 -8 13 -10 13 -10 -2z" />
            <path d="M13810 2985 c7 -9 15 -13 17 -11 7 7 -7 26 -19 26 -6 0 -6 -6 2 -15z" />
            <path d="M19735 2991 c-3 -6 -12 -8 -18 -5 -7 4 -9 3 -4 -1 4 -5 1 -18 -7 -30
-14 -22 -14 -22 9 -11 13 6 22 13 19 15 -2 2 1 10 8 18 6 7 8 16 5 19 -3 3 -8
0 -12 -5z" />
            <path d="M10978 2972 c-4 -16 -2 -25 8 -29 8 -3 14 -16 14 -28 0 -13 6 -26 13
-28 8 -4 6 -6 -4 -6 -9 -1 -21 9 -28 22 -9 16 -10 17 -5 2 4 -11 6 -28 6 -38
0 -10 7 -21 16 -24 9 -4 20 -10 23 -15 3 -5 5 9 5 31 -1 22 -4 43 -9 47 -4 4
-7 18 -7 31 0 13 -4 22 -9 18 -5 -3 -11 5 -13 17 l-3 23 -7 -23z m42 -106 c0
-2 -7 -6 -15 -10 -8 -3 -15 -1 -15 4 0 6 7 10 15 10 8 0 15 -2 15 -4z" />
            <path d="M14191 2974 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
            <path d="M22925 2979 c-11 -17 1 -21 15 -4 8 9 8 15 2 15 -6 0 -14 -5 -17 -11z" />
            <path d="M13803 2964 c5 -7 2 -14 -5 -17 -10 -4 -11 -7 0 -18 10 -11 12 -11
12 2 0 12 2 12 10 -1 5 -8 10 -10 10 -4 0 7 -8 20 -17 31 -10 10 -14 14 -10 7z" />
            <path d="M24442 2965 c4 -4 3 -14 -4 -22 -6 -7 -8 -16 -5 -19 3 -3 10 3 16 15
8 15 8 22 -1 27 -7 4 -10 4 -6 -1z" />
            <path d="M29070 2945 c-10 -20 -11 -28 -2 -37 7 -7 12 -8 12 -4 0 5 7 3 15 -4
10 -9 15 -9 15 -1 0 6 -5 11 -11 11 -5 0 -7 5 -3 12 5 7 3 8 -6 3 -10 -6 -11
-2 -6 19 8 33 3 33 -14 1z" />
            <path d="M14176 2942 c-2 -4 4 -8 14 -8 10 0 16 4 14 8 -3 4 -9 8 -14 8 -5 0
-11 -4 -14 -8z" />
            <path d="M27861 2933 c-12 -13 -11 -14 8 -8 15 4 21 2 21 -9 0 -9 -7 -13 -18
-11 -12 2 -18 -3 -18 -17 0 -15 3 -16 11 -6 5 7 17 14 25 16 8 2 21 9 28 16
11 10 10 15 -5 27 -16 13 -17 12 -5 -3 8 -10 9 -19 3 -22 -5 -4 -12 3 -16 14
-8 24 -15 25 -34 3z" />
            <path d="M24452 2919 c-7 -11 -11 -23 -9 -25 7 -7 29 24 25 36 -2 6 -9 1 -16
-11z" />
            <path d="M27471 2930 c0 -8 4 -24 9 -35 l9 -20 0 20 c0 11 -4 27 -9 35 -9 13
-10 13 -9 0z" />
            <path d="M6539 2911 c-14 -11 -20 -23 -15 -28 5 -4 11 -2 13 5 3 6 11 12 19
12 8 0 14 7 14 15 0 19 -3 19 -31 -4z" />
            <path d="M13770 2921 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5
3 -10 2 -10 -4z" />
            <path d="M15781 2923 c-1 -6 -4 -20 -7 -30 -5 -17 -5 -17 6 0 6 10 9 23 6 30
-3 9 -5 9 -5 0z" />
            <path d="M20212 2895 c2 -21 9 -40 17 -43 10 -3 11 0 5 9 -5 8 -10 25 -12 39
-5 41 -13 37 -10 -5z" />
            <path d="M7493 2909 c-6 -6 -9 -18 -6 -27 5 -10 2 -13 -6 -8 -8 5 -19 -1 -29
-15 -9 -13 -15 -26 -14 -27 2 -2 -5 -10 -15 -18 -10 -7 -13 -14 -6 -14 6 0 14
4 17 8 3 5 19 8 35 7 17 -1 28 2 25 7 -3 5 0 15 6 23 10 11 10 15 1 15 -7 0
-9 4 -6 10 7 11 16 50 11 50 -1 0 -7 -5 -13 -11z m-15 -75 c-24 -24 -44 -7
-22 17 12 13 21 16 28 9 7 -7 5 -15 -6 -26z" />
            <path d="M7460 2845 c-7 -9 -8 -15 -2 -15 5 0 12 7 16 15 3 8 4 15 2 15 -2 0
-9 -7 -16 -15z" />
            <path d="M14191 2917 c-2 -15 30 -67 40 -67 8 0 8 4 0 13 -6 8 -7 18 -3 22 4
5 1 5 -7 0 -10 -6 -12 -2 -6 16 4 17 3 20 -3 10 -8 -11 -10 -11 -15 0 -3 8 -6
10 -6 6z" />
            <path d="M21568 2900 c-14 -21 -34 -28 -22 -7 4 7 3 9 -2 4 -5 -5 -9 -12 -9
-15 0 -4 -11 -22 -24 -40 -13 -18 -21 -36 -17 -39 7 -7 36 16 36 28 0 5 9 18
20 29 18 18 20 18 29 2 10 -16 10 -16 11 1 0 9 -4 17 -10 17 -5 0 -10 5 -10
11 0 5 4 8 8 5 5 -3 9 1 9 9 0 20 -4 19 -19 -5z" />
            <path d="M31172 2900 c-7 -11 -12 -28 -12 -37 1 -10 7 -3 16 16 16 38 14 50
-4 21z" />
            <path d="M12086 2902 c-3 -5 1 -9 9 -9 8 0 15 4 15 9 0 4 -4 8 -9 8 -6 0 -12
-4 -15 -8z" />
            <path d="M7777 2893 c-3 -7 -3 -16 1 -50 1 -7 9 -10 17 -7 12 5 12 9 0 28 -8
13 -12 26 -8 29 3 4 4 7 0 7 -3 0 -8 -3 -10 -7z" />
            <path d="M14176 2880 c6 -21 47 -70 58 -70 2 0 6 7 9 14 4 10 1 13 -8 10 -8
-3 -24 10 -40 33 -21 29 -25 32 -19 13z" />
            <path d="M15244 2892 c3 -5 0 -12 -6 -15 -7 -2 -10 -7 -7 -11 3 -4 12 0 18 8
9 11 9 16 0 22 -7 4 -9 3 -5 -4z" />
            <path d="M18195 2878 c4 -40 6 -43 14 -23 9 21 4 45 -9 45 -4 0 -6 -10 -5 -22z" />
            <path d="M26750 2885 c0 -5 5 -17 10 -25 5 -8 10 -10 10 -5 0 6 -5 17 -10 25
-5 8 -10 11 -10 5z" />
            <path d="M15790 2871 c0 -6 4 -13 10 -16 6 -3 7 1 4 9 -7 18 -14 21 -14 7z" />
            <path d="M6177 2849 c-3 -12 0 -22 9 -26 8 -3 12 -9 9 -13 -3 -4 2 -13 10 -20
23 -19 19 6 -4 29 -13 13 -16 22 -9 29 6 6 6 12 0 16 -5 3 -12 -3 -15 -15z" />
            <path d="M8800 2863 c0 -1 8 -7 18 -12 14 -7 19 -6 19 4 0 7 -9 13 -19 12 -10
-1 -18 -2 -18 -4z" />
            <path d="M15774 2854 c3 -8 6 -23 6 -31 0 -12 3 -13 14 -4 11 9 15 9 19 -1 2
-7 7 -11 10 -7 4 3 -7 17 -24 32 -19 16 -29 21 -25 11z" />
            <path d="M17090 2860 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z" />
            <path d="M31135 2860 c-9 -15 4 -30 23 -29 16 1 16 2 0 6 -10 2 -15 10 -11 19
6 16 -3 19 -12 4z" />
            <path d="M10173 2827 c1 -24 4 -32 9 -23 4 8 4 25 -1 38 -8 20 -9 18 -8 -15z" />
            <path d="M18656 2852 c-2 -4 -1 -14 4 -22 7 -11 9 -9 7 8 -1 24 -3 26 -11 14z" />
            <path d="M8730 2839 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z" />
            <path d="M8766 2842 c-11 -18 9 -28 32 -16 20 10 21 12 7 18 -22 8 -33 8 -39
-2z" />
            <path d="M15216 2835 c-3 -8 -2 -14 2 -14 4 1 9 0 12 -1 14 -5 40 -8 40 -3 0
7 -25 28 -37 30 -6 1 -14 -4 -17 -12z" />
            <path d="M21555 2840 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3
0 -8 -4 -11 -10z" />
            <path d="M28760 2846 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z" />
            <path d="M14170 2826 c0 -9 5 -16 10 -16 6 0 10 4 10 9 0 6 -4 13 -10 16 -5 3
-10 -1 -10 -9z" />
            <path d="M17073 2825 c-7 -8 -13 -17 -13 -21 0 -4 -8 -16 -17 -26 -16 -18 -16
-18 0 -12 16 6 22 -4 18 -28 0 -5 6 -8 14 -8 8 0 15 5 15 10 0 6 -5 10 -12 10
-6 0 -4 8 6 19 10 11 15 24 12 30 -4 5 -2 12 4 16 5 3 10 10 10 16 0 14 -23
10 -37 -6z m20 -7 c-4 -7 -8 -19 -9 -26 -3 -18 -12 -27 -20 -19 -7 6 22 57 32
57 3 0 2 -6 -3 -12z" />
            <path d="M23695 2830 c-3 -5 -3 -10 2 -10 4 0 -3 -7 -15 -16 -23 -16 -32 -41
-9 -28 8 5 8 3 -2 -9 -7 -9 -17 -15 -20 -12 -4 2 -8 -2 -8 -9 0 -7 6 -13 13
-13 7 0 11 4 9 8 -3 4 2 9 10 13 10 3 15 -1 15 -11 0 -14 2 -15 13 -4 10 11
10 14 0 18 -7 3 -13 14 -13 26 0 16 3 18 9 8 6 -9 11 -10 15 -2 4 6 2 11 -5
11 -9 0 -9 3 0 14 8 9 8 16 2 20 -5 4 -12 2 -16 -4z" />
            <path d="M27719 2827 c6 -8 7 -18 3 -22 -4 -5 -1 -5 6 -1 7 4 10 11 7 16 -3 4
1 11 7 13 7 3 2 6 -11 6 -18 1 -21 -2 -12 -12z" />
            <path d="M11165 2820 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z" />
            <path d="M27696 2811 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 -4 11 -6 11 -12 0z" />
            <path d="M7770 2791 c0 -25 6 -27 13 -6 4 8 2 17 -3 20 -6 4 -10 -3 -10 -14z" />
            <path d="M19636 2802 c-3 -5 1 -12 9 -16 8 -3 15 -10 15 -16 0 -7 -7 -7 -22 1
-23 11 -23 11 1 -15 14 -14 25 -26 26 -26 0 0 1 10 0 23 -1 29 -20 63 -29 49z" />
            <path d="M7413 2771 c0 -11 5 -18 9 -15 4 3 5 11 1 19 -7 21 -12 19 -10 -4z" />
            <path d="M29932 2781 c-9 -5 1 -8 27 -8 23 0 43 2 45 6 6 9 -57 11 -72 2z" />
            <path d="M18137 2760 c-3 -11 -1 -20 4 -20 5 0 9 9 9 20 0 11 -2 20 -4 20 -2
0 -6 -9 -9 -20z" />
            <path d="M29150 2765 c-15 -18 -8 -28 20 -30 18 -2 19 0 5 15 -8 9 -12 20 -8
23 3 4 4 7 1 7 -3 0 -11 -7 -18 -15z" />
            <path d="M7790 2760 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z" />
            <path d="M9725 2760 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z" />
            <path d="M12819 2763 c-6 -32 -5 -53 1 -53 4 0 7 14 7 30 0 27 -5 41 -8 23z" />
            <path d="M14260 2760 c-11 -7 -11 -10 -2 -10 7 0 10 -4 7 -10 -9 -15 4 -12 20
4 22 22 3 34 -25 16z" />
            <path d="M10200 2751 c0 -10 19 -25 25 -20 1 2 -3 10 -11 17 -8 7 -14 8 -14 3z" />
            <path d="M17015 2742 c-3 -3 -5 -14 -5 -25 0 -11 -3 -17 -7 -14 -12 12 -32
-27 -21 -40 16 -20 30 -15 23 6 -4 13 0 23 12 30 10 6 14 11 9 11 -5 0 -2 8 7
18 15 16 15 19 2 19 -8 0 -17 -3 -20 -5z" />
            <path d="M18660 2740 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z" />
            <path d="M18119 2715 c-3 -28 -4 -33 -11 -41 -5 -4 -8 -11 -8 -16 0 -17 16 -7
31 20 13 23 13 30 2 40 -10 10 -13 10 -14 -3z" />
            <path d="M7865 2712 c-15 -11 -17 -38 -2 -50 10 -8 16 -6 25 9 10 16 10 20 -3
25 -8 3 -12 10 -9 15 6 11 3 11 -11 1z m25 -26 c0 -11 -19 -15 -25 -6 -3 5 1
10 9 10 9 0 16 -2 16 -4z" />
            <path d="M21773 2700 c-2 -21 32 -46 41 -32 4 7 -28 52 -37 52 -2 0 -3 -9 -4
-20z m28 -17 c13 -16 12 -17 -3 -4 -10 7 -18 15 -18 17 0 8 8 3 21 -13z" />
            <path d="M23696 2698 c-8 -32 -7 -58 2 -58 4 0 8 18 9 40 1 45 -2 50 -11 18z" />
            <path d="M28665 2710 c-30 -11 -49 -36 -35 -45 7 -4 14 2 17 14 3 12 9 21 14
21 5 0 6 -8 3 -17 -5 -15 -3 -15 10 1 9 11 16 23 16 28 0 9 1 9 -25 -2z" />
            <path d="M11974 2695 c3 -21 16 -31 16 -12 0 9 -4 18 -9 21 -5 4 -8 -1 -7 -9z" />
            <path d="M15170 2700 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z" />
            <path d="M24368 2692 c-3 -10 1 -27 9 -37 12 -17 13 -16 7 15 -8 43 -10 46
-16 22z" />
            <path d="M26835 2700 c3 -5 11 -10 16 -10 6 0 7 5 4 10 -3 6 -11 10 -16 10 -6
0 -7 -4 -4 -10z" />
            <path d="M31001 2696 c-10 -12 -8 -13 8 -9 12 3 18 9 15 14 -7 12 -10 11 -23
-5z" />
            <path d="M26434 2670 c0 -13 4 -16 10 -10 7 7 7 13 0 20 -6 6 -10 3 -10 -10z" />
            <path d="M29229 2653 c0 -4 -2 -15 -4 -23 -12 -44 -10 -53 15 -55 28 -3 53 12
44 26 -3 5 -13 5 -23 -1 -18 -9 -31 -4 -31 12 0 5 4 7 9 3 5 -3 13 -1 16 5 4
6 3 10 -2 9 -19 -3 -27 2 -14 10 9 6 10 11 2 15 -6 4 -11 4 -12 -1z m46 -63
c-3 -5 -12 -10 -18 -10 -7 0 -6 4 3 10 19 12 23 12 15 0z" />
            <path d="M18090 2625 c-8 -9 -8 -15 -2 -15 12 0 26 19 19 26 -2 2 -10 -2 -17
-11z" />
            <path d="M7232 2598 c-16 -16 -15 -28 3 -28 8 0 15 5 15 11 0 5 6 7 13 3 8 -5
8 -2 -2 9 -16 20 -14 20 -29 5z" />
            <path d="M16911 2586 c-9 -10 -8 -15 2 -19 8 -3 13 3 13 14 0 23 0 23 -15 5z" />
            <path d="M24361 2590 c-8 -60 -8 -80 0 -80 5 0 9 11 9 25 0 14 5 25 10 25 6 0
10 5 10 11 0 5 -4 8 -9 4 -5 -3 -12 3 -14 12 -3 13 -5 14 -6 3z" />
            <path d="M28580 2591 c0 -5 7 -11 14 -14 10 -4 13 -1 9 9 -6 15 -23 19 -23 5z" />
            <path d="M18070 2580 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5
0 -10 -4 -10 -10z" />
            <path d="M12894 2560 c0 -11 6 -15 17 -13 10 3 15 11 13 17 -7 19 -30 15 -30
-4z" />
            <path d="M5625 2549 c4 -5 1 -16 -5 -24 -9 -11 -9 -15 -1 -15 6 0 11 5 11 10
0 6 9 2 21 -9 11 -10 30 -22 41 -24 19 -5 19 -5 -2 18 -13 13 -28 23 -34 22
-6 -1 -10 3 -9 10 1 6 -4 14 -13 17 -9 3 -12 2 -9 -5z" />
            <path d="M24335 2540 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3
0 -8 -4 -11 -10z" />
            <path d="M28524 2538 c3 -10 1 -18 -4 -18 -6 0 -10 5 -10 12 0 6 -9 3 -20 -7
-27 -24 -25 -32 5 -32 24 0 24 1 8 13 -16 13 -15 14 10 8 29 -7 34 1 16 26 -9
13 -10 12 -5 -2z" />
            <path d="M12890 2505 c0 -8 2 -15 4 -15 2 0 6 7 10 15 3 8 1 15 -4 15 -6 0
-10 -7 -10 -15z" />
            <path d="M19542 2510 c6 -19 38 -26 38 -9 0 7 -3 9 -7 6 -3 -4 -13 -1 -21 6
-13 10 -14 9 -10 -3z" />
            <path d="M13650 2500 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5
0 -10 -4 -10 -10z" />
            <path d="M15080 2506 c0 -2 7 -9 15 -16 13 -11 14 -10 9 4 -5 14 -24 23 -24
12z" />
            <path d="M20575 2472 c2 -11 9 -29 16 -40 10 -16 16 -18 31 -9 13 9 21 8 30
-1 6 -6 17 -12 22 -12 6 0 0 7 -12 16 -13 9 -32 14 -43 11 -21 -6 -26 8 -6 17
6 3 3 4 -8 3 -11 -1 -20 3 -20 10 0 6 -3 15 -7 19 -4 3 -6 -2 -3 -14z" />
            <path d="M28436 2465 c-11 -8 -14 -15 -7 -15 7 0 9 -5 6 -11 -4 -6 2 -5 14 3
12 7 17 14 11 16 -6 2 -8 8 -4 13 7 12 2 11 -20 -6z" />
            <path d="M21243 2453 c-4 -10 -14 -23 -22 -30 -14 -11 -14 -12 1 -13 11 0 20
10 24 26 5 19 9 22 15 12 6 -9 9 -9 9 -1 0 20 -20 25 -27 6z" />
            <path d="M23393 2463 c7 -11 -20 -28 -36 -24 -5 1 -6 -3 -3 -8 4 -5 0 -11 -6
-14 -7 -2 9 -4 35 -4 27 0 43 3 37 7 -9 5 -9 9 -1 14 6 4 11 12 11 19 0 6 -7
1 -16 -11 -8 -12 -20 -22 -26 -22 -6 0 -3 9 6 19 13 14 14 21 5 27 -7 4 -10 3
-6 -3z" />
            <path d="M26283 2441 c-1 -14 3 -18 13 -14 11 4 12 9 3 19 -15 18 -14 18 -16
-5z" />
            <path d="M7085 2441 c-3 -5 0 -13 6 -17 7 -4 9 -3 5 4 -3 5 0 13 6 15 9 4 10
6 1 6 -6 1 -14 -3 -18 -8z" />
            <path d="M27050 2439 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0
-10 -5 -10 -11z" />
            <path d="M29420 2444 c0 -13 18 -39 24 -34 2 3 -2 14 -10 24 -8 11 -14 16 -14
10z" />
            <path d="M14610 2430 c0 -5 7 -7 15 -4 8 4 15 8 15 10 0 2 -7 4 -15 4 -8 0
-15 -4 -15 -10z" />
            <path d="M29396 2425 c-3 -9 0 -15 9 -15 16 0 20 16 6 24 -5 3 -11 -1 -15 -9z" />
            <path d="M14657 2419 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z" />
            <path d="M13576 2395 c-9 -26 -7 -32 5 -12 6 10 9 21 6 23 -2 3 -7 -2 -11 -11z" />
            <path d="M16760 2406 c0 -2 6 -9 14 -15 10 -9 15 -8 19 2 3 8 -3 13 -14 14
-10 1 -19 0 -19 -1z" />
            <path d="M25379 2393 c-13 -16 -12 -17 4 -4 16 13 21 21 13 21 -2 0 -10 -8
-17 -17z" />
            <path d="M29455 2390 c-3 -6 1 -7 9 -4 18 7 21 14 7 14 -6 0 -13 -4 -16 -10z" />
            <path d="M28337 2372 c-13 -14 -14 -21 -5 -30 18 -18 28 -15 28 9 0 18 2 19
15 9 19 -16 20 -3 1 16 -18 18 -20 18 -39 -4z m23 4 c0 -2 -8 -10 -17 -17 -16
-13 -17 -12 -4 4 13 16 21 21 21 13z" />
            <path d="M16726 2363 c-25 -13 -27 -17 -12 -25 17 -10 40 -2 31 12 -3 4 1 10
9 13 10 4 12 -1 9 -16 -5 -22 -5 -22 10 -2 11 15 12 22 4 27 -15 9 -15 9 -51
-9z" />
            <path d="M17473 2364 c-3 -9 0 -20 7 -24 17 -10 42 -1 34 12 -4 6 -10 5 -15
-3 -7 -12 -9 -11 -10 1 -1 8 -1 18 0 23 2 13 -10 7 -16 -9z" />
            <path d="M25383 2372 c-8 -5 -7 -12 4 -27 15 -20 15 -20 10 2 -3 15 -1 20 9
16 8 -3 11 -9 8 -15 -3 -5 3 -12 15 -15 12 -3 22 -9 22 -14 1 -5 3 -13 4 -19
1 -5 3 -15 4 -22 1 -7 9 -4 21 7 19 17 19 30 1 30 -4 0 -22 15 -40 33 -34 34
-39 36 -58 24z" />
            <path d="M16337 2359 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z" />
            <path d="M20735 2360 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z" />
            <path d="M21159 2368 c-6 -21 -5 -37 2 -32 5 3 7 9 4 14 -3 5 3 11 12 13 17 4
17 5 1 6 -10 0 -18 0 -19 -1z" />
            <path d="M19186 2352 c5 -4 12 -11 15 -15 3 -5 9 -2 12 5 3 9 -3 14 -15 15
-11 1 -16 -1 -12 -5z" />
            <path d="M20771 2344 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
            <path d="M19145 2340 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z" />
            <path d="M19225 2340 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3
0 -8 -4 -11 -10z" />
            <path d="M20796 2342 c-3 -5 1 -9 9 -9 8 0 12 4 9 9 -3 4 -7 8 -9 8 -2 0 -6
-4 -9 -8z" />
            <path d="M5889 2316 c8 -9 11 -20 7 -23 -4 -4 -11 -1 -16 7 -5 8 -12 11 -16 7
-4 -3 -1 -14 6 -22 9 -11 16 -13 24 -5 5 5 17 10 26 10 13 0 13 -1 0 -10 -12
-8 -13 -11 -2 -22 8 -7 13 -8 12 -3 -4 21 1 30 9 16 5 -7 11 -9 14 -5 2 5 -14
22 -37 38 -22 16 -35 22 -27 12z" />
            <path d="M13581 2314 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
            <path d="M29550 2308 c0 -8 7 -21 15 -28 19 -16 19 -1 0 24 -12 16 -15 17 -15
4z" />
            <path d="M16430 2290 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z" />
            <path d="M20960 2290 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z" />
            <path d="M11596 2271 c-13 -14 -14 -21 -5 -27 8 -4 10 -3 5 5 -4 7 2 14 15 19
13 5 18 12 12 15 -6 4 -18 -2 -27 -12z" />
            <path d="M25512 2280 c2 -7 -3 -15 -11 -18 -8 -3 -11 0 -7 7 11 17 0 13 -15
-6 -13 -15 -11 -16 14 -11 19 4 30 0 37 -11 6 -10 10 -11 10 -3 0 6 4 12 9 12
5 0 7 -9 4 -20 -3 -11 -9 -17 -14 -14 -5 3 -9 0 -9 -6 0 -7 7 -9 17 -6 9 4 20
1 25 -6 6 -8 7 -4 2 12 -6 22 -5 23 9 11 19 -15 18 -33 -3 -43 -11 -6 -10 -7
5 -5 11 2 19 8 18 13 -1 6 4 11 12 11 7 0 11 -6 9 -12 -3 -6 2 -12 11 -12 21
0 13 20 -14 33 -12 6 -34 22 -49 36 -48 43 -64 53 -60 38z" />
            <path d="M23177 2267 c-3 -7 3 -13 14 -13 13 0 15 3 7 13 -6 7 -12 13 -13 13
-2 0 -5 -6 -8 -13z" />
            <path d="M23956 2267 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 -10 9 -11 8 -5 -6z" />
            <path d="M16420 2260 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z" />
            <path d="M19314 2261 c3 -5 23 -9 43 -9 29 0 33 2 18 9 -28 11 -67 11 -61 0z" />
            <path d="M27280 2260 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z" />
            <path d="M6019 2237 c11 -14 11 -17 0 -17 -8 -1 -1 -8 14 -16 16 -9 36 -13 44
-10 13 5 14 4 3 -9 -10 -12 -10 -15 3 -15 9 0 14 7 13 18 -2 9 -7 17 -12 17
-5 0 -25 11 -44 25 -21 14 -30 17 -21 7z" />
            <path d="M17611 2234 c16 -19 18 -46 5 -56 -5 -3 2 -5 15 -5 19 2 25 8 25 24
0 12 -4 19 -8 17 -5 -3 -11 1 -14 10 -3 8 -13 17 -21 20 -14 6 -14 4 -2 -10z
m34 -34 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z" />
            <path d="M23108 2208 c-21 -21 -34 -38 -29 -38 14 0 85 66 75 71 -5 3 -26 -12
-46 -33z" />
            <path d="M10694 2231 c5 -8 4 -16 -4 -21 -7 -5 -8 -10 -2 -14 5 -3 15 0 21 8
9 10 7 17 -6 27 -14 12 -15 12 -9 0z" />
            <path d="M29695 2230 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3
0 -8 -4 -11 -10z" />
            <path d="M29706 2214 c-20 -14 -20 -14 0 -15 18 0 18 -1 -1 -9 -15 -7 -16 -9
-4 -9 9 -1 22 6 29 14 19 22 0 37 -24 19z" />
            <path d="M10727 2206 c-4 -9 -3 -15 2 -11 5 3 12 1 16 -5 8 -13 35 -13 35 0 0
5 -7 6 -17 3 -12 -5 -14 -3 -8 7 5 8 4 11 -3 6 -5 -3 -12 -1 -14 6 -3 7 -7 5
-11 -6z" />
            <path d="M9183 2204 c4 -4 1 -14 -6 -23 -11 -14 -10 -15 8 -1 11 8 21 15 23
16 2 1 -5 5 -15 8 -9 4 -14 4 -10 0z" />
            <path d="M6833 2184 c0 -9 5 -10 19 -3 10 5 18 12 18 14 0 2 -8 3 -18 2 -10 0
-19 -7 -19 -13z" />
            <path d="M17674 2183 c2 -9 8 -19 15 -21 10 -3 10 0 2 17 -14 25 -20 27 -17 4z" />
            <path d="M22345 2186 c-19 -13 -18 -14 6 -13 17 1 25 7 22 15 -6 15 -5 15 -28
-2z" />
            <path d="M24302 2175 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z" />
            <path d="M6110 2186 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z" />
            <path d="M22390 2184 c0 -9 32 -33 44 -34 5 0 -1 9 -14 20 -25 21 -30 24 -30
14z" />
            <path d="M30465 2170 c6 -10 4 -12 -8 -7 -10 4 -16 2 -14 -5 1 -6 -4 -13 -12
-16 -9 -3 -11 0 -6 9 6 11 5 11 -5 2 -14 -13 -4 -37 11 -27 5 3 16 0 24 -6 11
-9 15 -9 15 -1 0 6 -5 11 -12 11 -6 0 -4 8 6 19 13 14 14 21 5 27 -8 5 -10 3
-4 -6z" />
            <path d="M11398 2143 c-16 -8 -28 -19 -28 -25 0 -6 -8 -8 -18 -6 -11 3 -30 -1
-43 -8 -23 -13 -22 -13 16 -11 22 1 44 7 48 12 6 6 10 6 14 -2 7 -18 24 -16
17 2 -4 8 -1 15 6 15 6 0 9 4 6 9 -4 5 0 11 6 14 7 2 11 6 8 9 -3 2 -17 -2
-32 -9z" />
            <path d="M22450 2152 c0 -17 21 -25 31 -13 5 6 6 9 3 6 -4 -3 -13 0 -21 6 -9
8 -13 8 -13 1z" />
            <path d="M26024 2145 c3 -8 -2 -19 -12 -24 -15 -9 -15 -10 -1 -11 9 0 22 7 29
15 9 11 9 15 1 15 -7 0 -9 5 -6 10 3 6 1 10 -5 10 -7 0 -10 -7 -6 -15z" />
            <path d="M6236 2132 c-3 -5 1 -9 9 -9 8 0 12 4 9 9 -3 4 -7 8 -9 8 -2 0 -6 -4
-9 -8z" />
            <path d="M9053 2133 c-10 -4 -10 -7 0 -18 8 -9 16 -10 25 -3 23 20 8 32 -25
21z" />
            <path d="M10924 2132 c3 -6 1 -12 -6 -14 -17 -6 0 -28 20 -27 11 0 12 3 5 6
-7 2 -13 9 -13 15 0 6 7 8 15 4 22 -8 18 2 -6 15 -13 6 -19 7 -15 1z" />
            <path d="M22430 2130 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z" />
            <path d="M25690 2134 c0 -8 32 -33 43 -34 4 0 -2 9 -13 20 -21 21 -30 25 -30
14z" />
            <path d="M25725 2130 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z" />
            <path d="M27574 2126 c19 -16 19 -16 1 -16 -15 0 -16 -3 -7 -12 10 -10 15 -10
21 1 6 9 18 11 36 7 17 -4 24 -3 20 4 -3 5 -15 10 -26 10 -11 0 -30 5 -42 11
-21 10 -21 10 -3 -5z" />
            <path d="M9091 2107 c-13 -21 -12 -21 5 -5 10 10 16 20 13 22 -3 3 -11 -5 -18
-17z" />
            <path d="M8614 2108 c-7 -11 18 -10 36 2 11 7 10 9 -8 7 -13 -1 -26 -5 -28 -9z" />
            <path d="M10973 2105 c0 -10 6 -14 14 -10 8 3 20 5 26 5 15 0 -14 20 -29 20
-6 0 -11 -7 -11 -15z" />
            <path d="M17730 2105 c-10 -12 -10 -15 3 -15 8 0 17 7 21 15 3 8 2 15 -3 15
-5 0 -14 -7 -21 -15z" />
            <path d="M13380 2100 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z" />
            <path d="M13480 2100 c0 -5 7 -7 15 -4 8 4 15 8 15 10 0 2 -7 4 -15 4 -8 0
-15 -4 -15 -10z" />
            <path d="M25765 2100 c-13 -6 -15 -9 -5 -9 8 0 22 4 30 9 18 12 2 12 -25 0z" />
            <path d="M27960 2100 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z" />
            <path d="M27997 2099 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z" />
            <path d="M30020 2106 c0 -2 9 -6 20 -9 11 -3 18 -1 14 4 -5 9 -34 13 -34 5z" />
            <path d="M8718 2093 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
            <path d="M25818 2093 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
            <path d="M25965 6589 c-9 -14 -2 -20 14 -10 8 6 9 11 3 15 -6 3 -14 1 -17 -5z" />
            <path d="M18184 6458 c12 -7 21 -7 28 0 6 6 0 10 -18 10 -23 0 -24 -2 -10 -10z" />
            <path d="M11670 6449 c0 -4 -10 -10 -22 -12 -21 -4 -20 -4 3 -6 15 0 32 5 38
12 8 10 7 14 -4 14 -8 0 -15 -4 -15 -8z" />
            <path d="M12275 6260 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z" />
            <path d="M19536 6212 c-3 -5 1 -9 9 -9 8 0 15 4 15 9 0 4 -4 8 -9 8 -6 0 -12
-4 -15 -8z" />
            <path d="M22675 6219 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z" />
            <path d="M22598 6143 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
            <path d="M22570 6124 c0 -8 5 -12 10 -9 6 4 8 11 5 16 -9 14 -15 11 -15 -7z" />
            <path d="M25950 6226 c0 -3 9 -10 20 -16 11 -6 20 -8 20 -6 0 3 -9 10 -20 16
-11 6 -20 8 -20 6z" />
            <path d="M28845 6180 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z" />
            <path d="M17160 6136 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z" />
            <path d="M12455 6050 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z" />
            <path d="M24990 6059 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0
-10 -5 -10 -11z" />
            <path d="M29095 5960 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3
0 -8 -4 -11 -10z" />
            <path d="M8500 5809 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z" />
            <path d="M15960 5815 c7 -9 15 -13 17 -11 7 7 -7 26 -19 26 -6 0 -6 -6 2 -15z" />
            <path d="M10810 5659 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0
-10 -5 -10 -11z" />
            <path d="M25695 5631 c-3 -5 0 -13 6 -17 8 -5 9 -3 4 6 -6 10 -4 12 9 7 10 -4
15 -3 11 3 -8 13 -22 13 -30 1z" />
            <path d="M13380 5564 c0 -8 5 -12 10 -9 6 3 10 10 10 16 0 5 -4 9 -10 9 -5 0
-10 -7 -10 -16z" />
            <path d="M16096 5493 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z" />
            <path d="M14206 5165 c-9 -26 -7 -32 5 -12 6 10 9 21 6 23 -2 3 -7 -2 -11 -11z" />
            <path d="M20333 5159 c-2 -23 3 -25 10 -4 4 8 3 16 -1 19 -4 3 -9 -4 -9 -15z" />
            <path d="M13035 4980 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3
0 -8 -4 -11 -10z" />
            <path d="M5531 4374 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
            <path d="M31556 4218 c3 -5 10 -6 15 -3 13 9 11 12 -6 12 -8 0 -12 -4 -9 -9z" />
            <path d="M31371 3994 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
            <path d="M28950 3910 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z" />
            <path d="M28890 3892 c0 -5 7 -15 15 -22 8 -7 15 -8 15 -2 0 5 -7 15 -15 22
-8 7 -15 8 -15 2z" />
            <path d="M31810 3865 c-11 -13 -10 -14 4 -9 9 3 16 10 16 15 0 13 -6 11 -20
-6z" />
            <path d="M26336 3495 c4 -8 11 -15 16 -15 6 0 5 6 -2 15 -7 8 -14 15 -16 15
-2 0 -1 -7 2 -15z" />
            <path d="M28530 3480 c0 -5 7 -7 15 -4 8 4 15 8 15 10 0 2 -7 4 -15 4 -8 0
-15 -4 -15 -10z" />
            <path d="M25131 3304 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
            <path d="M28329 3273 c-13 -16 -12 -17 4 -4 16 13 21 21 13 21 -2 0 -10 -8
-17 -17z" />
            <path d="M9893 2925 c0 -8 4 -12 9 -9 5 3 6 10 3 15 -9 13 -12 11 -12 -6z" />
            <path d="M21691 2754 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
            <path d="M9724 2711 c7 -11 -28 -43 -37 -34 -4 3 -7 1 -7 -5 0 -18 25 -14 43
6 19 21 22 42 6 42 -5 0 -8 -4 -5 -9z" />
            <path d="M7321 2664 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
            <path d="M5550 2622 c0 -7 5 -12 10 -12 6 0 10 -5 10 -11 0 -5 -4 -8 -9 -5 -5
3 -11 0 -14 -7 -3 -7 5 -13 21 -14 16 -1 20 2 12 7 -9 6 -9 9 0 12 7 2 4 10
-9 22 -12 11 -21 14 -21 8z" />
            <path d="M9637 2592 c-16 -18 -16 -19 0 -17 20 1 26 10 21 26 -2 6 -11 2 -21
-9z" />
            <path d="M25293 2436 c0 -17 3 -23 7 -16 6 9 9 9 12 -1 3 -8 8 -11 12 -8 4 3
-2 17 -12 30 l-19 23 0 -28z" />
            <path d="M18970 2440 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5
0 -10 -4 -10 -10z" />
            <path d="M23305 2361 c-3 -5 2 -11 10 -15 8 -3 17 -1 20 3 3 5 -2 11 -10 15
-8 3 -17 1 -20 -3z" />
            <path d="M5770 2360 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z" />
            <path d="M23000 2100 c-8 -5 -10 -10 -5 -10 6 0 17 5 25 10 8 5 11 10 5 10 -5
0 -17 -5 -25 -10z" />
            <path d="M6750 2111 c0 -5 7 -12 16 -15 14 -5 15 -4 4 9 -14 17 -20 19 -20 6z" />
          </g>
        </svg>
      </Box>
    );

    if (disabledLink) {
      return logo;
    }

    return (
      <Link component={RouterLink} to="/" sx={{ display: 'contents' }}>
        {logo}
      </Link>
    );
  }
);

export default Logo;
